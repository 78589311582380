<i18n src="./deny-i18n.yaml"></i18n>
<template>
    <AppErrorContainer
        errorTitle="403"
        :errorMsg="$t('error-msg')"
        :wIconSrc="require('@/assets/icons/pages/403.svg')"
    />
</template>
<script>
export default {
    name: "ViewErrorDeny"
};
</script>
