import { axiosTasks } from "@/api/axios-instances";
import store from "@/store";
const state = {
    all: []
};

const mutations = {
    setAll(state, payload) {
        state.all = payload;
    },
    clearAll(state) {
        state.all = [];
    },
    clearStore(state) {
        state.all = [];
    }
};

const getters = {
    allTasks(state) {
        return state.all;
    }
};

const actions = {
    createByProcessType: (_ctx, data) => {
        const taskProcess = data.process;
        data.registrationOffice =
        store.getters["settings/getRegistrationOffice"];
        return new Promise((resolve, reject) => {
            if (!taskProcess) reject("Task process is missing");
            axiosTasks
                .post(`/${taskProcess}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAllFromUser: () => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get("/getAllFromUser")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    completeByProcessType: (_ctx, data) => {
        const taskProcess = data.process;
        return new Promise((resolve, reject) => {
            if (!taskProcess) reject("Task process is missing");
            axiosTasks
                .put(`/complete/${taskProcess}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchDataByDocId: (_ctx, docId) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/doc/${docId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchDataByReferenceId: (_ctx, referenceId) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/ref/${referenceId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    removeTaskFromDbByDocId: (_ctx, docId) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .delete(`/removeFromDbByDocId/${docId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
