var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"readonly":_vm.readonlyModus,"disabled":_vm.disabled}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('app-dev-container',{staticClass:"my-6",scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('base-btn',{attrs:{"icon":"","color":"warning"},on:{"click":_vm.setFakeData}},[_c('v-icon',[_vm._v("mdi-bug")])],1),_c('base-btn',{attrs:{"icon":"","color":"yellow"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('base-btn',{attrs:{"icon":"","color":"info"},on:{"click":_vm.validate}},[_c('v-icon',[_vm._v("mdi-alert-circle-check-outline")])],1),_vm._v(" IsValid: "+_vm._s(_vm.isValid())+" required: "+_vm._s(_vm.required)+" ")]},proxy:true}])}),_c('v-row',[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","md":"6"}},[_c('base-text-field',{attrs:{"error-messages":_vm.companyNameErrors,"counter":_vm.required
                            ? _vm.$v.address.companyName.$params.maxLength.max
                            : false,"label":_vm.$t('company-name-label'),"disabled":_vm.disableAllFields,"required":_vm.isRequiredField(_vm.$v.address.companyName)},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})],1),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","md":"6"}},[_c('base-text-field',{attrs:{"disabled":_vm.disableAllFields,"error-messages":_vm.departmentErrors,"counter":_vm.required
                            ? _vm.$v.address.department.$params.maxLength.max
                            : false,"label":_vm.$t('department-label'),"required":_vm.isRequiredField(_vm.$v.address.department)},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{staticClass:"pl-3",attrs:{"label":_vm.$t('additional-address-lines'),"disabled":_vm.disableAllFields,"value":""},model:{value:(_vm.additionalAddressLines),callback:function ($$v) {_vm.additionalAddressLines=$$v},expression:"additionalAddressLines"}})],1)],1),(_vm.address.additionalAddressLines)?_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('address-line-1-label'),"disabled":_vm.disableAllFields,"error-messages":_vm.addressLine1Errors,"counter":_vm.required
                            ? _vm.$v.address.addressLine1.$params.maxLength.max
                            : false,"required":_vm.isRequiredField(_vm.$v.address.addressLine1)},model:{value:(_vm.addressLine1),callback:function ($$v) {_vm.addressLine1=$$v},expression:"addressLine1"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('address-line-2-label'),"disabled":_vm.disableAllFields,"counter":_vm.required
                            ? _vm.$v.address.addressLine2.$params.maxLength.max
                            : false,"error-messages":_vm.addressLine2Errors,"required":_vm.isRequiredField(_vm.$v.address.addressLine2)},model:{value:(_vm.addressLine2),callback:function ($$v) {_vm.addressLine2=$$v},expression:"addressLine2"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"10"}},[_c('base-text-field',{attrs:{"error-messages":_vm.streetErrors,"counter":_vm.required
                            ? _vm.$v.address.street.$params.maxLength.max
                            : false,"label":_vm.$t('street-label'),"required":_vm.isRequiredField(_vm.$v.address.street),"disabled":_vm.disableAllFields},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"2"}},[_c('base-text-field',{attrs:{"label":_vm.$t('house-nr-label'),"error-messages":_vm.houseNrErrors,"disabled":_vm.disableAllFields,"counter":_vm.required
                            ? _vm.$v.address.houseNr.$params.maxLength.max
                            : false,"required":_vm.isRequiredField(_vm.$v.address.houseNr)},model:{value:(_vm.houseNr),callback:function ($$v) {_vm.houseNr=$$v},expression:"houseNr"}})],1)],1)],1),_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[(_vm.isLocationSearchAvailable(_vm.country))?_c('w-location-search',{ref:"wLocationSearch",attrs:{"country":_vm.country,"disabled":_vm.disableAllFields,"readonly":_vm.readonlyModus,"error-messages":_vm.locationSearchErrors,"required":_vm.isRequiredField(_vm.$v.address.zipCode) ||
                _vm.isRequiredField(_vm.$v.address.city) ||
                _vm.isRequiredField(_vm.$v.address.canton)},on:{"reset":_vm.onWLocationSearchReset},model:{value:(_vm.locationSearch),callback:function ($$v) {_vm.locationSearch=$$v},expression:"locationSearch"}}):_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('base-text-field',{attrs:{"disabled":_vm.disableAllFields,"error-messages":_vm.zipCodeErrors,"counter":_vm.required
                            ? _vm.$v.address.zipCode.$params.maxLength.max
                            : false,"label":_vm.$t('zip-code-label'),"required":_vm.isRequiredField(_vm.$v.address.zipCode)},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"9"}},[_c('base-text-field',{attrs:{"disabled":_vm.disableAllFields,"error-messages":_vm.cityErrors,"counter":_vm.required
                            ? _vm.$v.address.city.$params.maxLength.max
                            : false,"label":_vm.$t('city-label'),"required":_vm.isRequiredField(_vm.$v.address.city)},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1)],1),_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[(_vm.hasPoBox)?_c('v-row',{staticClass:"pl-2",attrs:{"dense":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('po-box-label'),"color":"primary"},model:{value:(_vm.poBox),callback:function ($$v) {_vm.poBox=$$v},expression:"poBox"}})],1):_vm._e(),(_vm.address.poBox)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3","sm":"6"}},[_c('base-text-field',{attrs:{"error-messages":_vm.poBoxNrErrors,"type":"number","label":_vm.$t('po-box-nr-label'),"required":_vm.isRequiredField(_vm.$v.address.poBoxNr)},model:{value:(_vm.poBoxNr),callback:function ($$v) {_vm.poBoxNr=$$v},expression:"poBoxNr"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3","sm":"6"}},[_c('base-text-field',{attrs:{"error-messages":_vm.poBoxZipCodeErrors,"counter":_vm.required
                            ? _vm.$v.address.poBoxZipCode.$params.maxLength.max
                            : false,"type":"number","label":_vm.$t('po-box-zip-code-label'),"required":_vm.isRequiredField(_vm.$v.address.poBoxZipCode)},model:{value:(_vm.poBoxZipCode),callback:function ($$v) {_vm.poBoxZipCode=$$v},expression:"poBoxZipCode"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('base-text-field',{attrs:{"error-messages":_vm.poBoxCityErrors,"counter":_vm.required
                            ? _vm.$v.address.poBoxCity.$params.maxLength.max
                            : false,"label":_vm.$t('po-box-city-label'),"required":_vm.isRequiredField(_vm.$v.address.poBoxCity)},model:{value:(_vm.poBoxCity),callback:function ($$v) {_vm.poBoxCity=$$v},expression:"poBoxCity"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }