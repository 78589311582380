<i18n src="./BaseDialog-i18n.yaml"></i18n>
<template>
    <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        persistent
        scrollable
        :maxWidth="maxWidth"
        v-on="$listeners"
        v-bind="$attrs"
        ref="baseDialog"
    >
        <v-card
            ref="baseDialogCard"
            flat
            class="mx-auto"
            :height="height"
            :max-height="maxHeight"
            :min-height="minHeight"
            :min-width="minWidth"
            :max-width="maxWidth"
        >
            <v-toolbar
                :color="$vuetify.theme.isDark ? '' : 'cardHeaderGrey'"
                class="regOfficePrimary--text"
                flat
            >
                <v-icon v-html="icon" color="regOfficePrimary"></v-icon>
                <v-toolbar-title class="pl-2">
                    <slot name="toolbar-title">
                        <span v-html="title"></span>
                    </slot>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <slot name="toolbar-items"></slot>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                @click="close"
                                color="regOfficePrimary"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="closeIconText">
                            {{ closeIconText }}
                        </span>
                        <span v-else>
                            {{ $t("close") }}
                        </span>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider v-if="$slots.content || $scopedSlots.content" />
            <v-card-text
                class="pa-1 pt-0 mt-0 fill-height"
                ref="baseDialogCardText"
            >
                <slot name="content"> </slot>
            </v-card-text>
            <v-divider
                class="pb-1"
                v-if="$slots.actions || $scopedSlots.actions"
            />
            <v-card-actions class="mb-1" ref="baseDialogCardActions">
                <slot name="actions"> </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "BaseDialog",

    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        maxWidth: {
            type: [String, Number],
            default: "1024"
        },
        height: {
            type: [String, Number],
            default: undefined
        },
        maxHeight: {
            type: [String, Number],
            default: undefined
        },
        minHeight: {
            type: [String, Number],
            default: undefined
        },
        minWidth: {
            type: [String, Number],
            default: 300
        },
        closeIconText: {
            type: String,
            default: undefined
        }
    },

    methods: {
        close() {
            this.$emit("close");
            this.$emit("input", false);
        }
    },

    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set() {
                this.close();
            }
        }
    }
};
</script>
