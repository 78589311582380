var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseListItem',{attrs:{"title":_vm.$t('title-device-details'),"expandable":_vm.expandable},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',{staticClass:"text-subtitle-2 primary--text"},[_c('li',[_c('DisplayDeviceDetailsInline',{attrs:{"show-copy-btn":_vm.showCopyBtn,"manufacturerName":_vm.task.manufacturer &&
                        _vm.task.manufacturer instanceof Object
                            ? _vm.task.manufacturer.name
                            : _vm.task.manufacturer,"manufacturerDepartment":_vm.task.manufacturer &&
                        _vm.task.manufacturer instanceof Object
                            ? _vm.task.manufacturer.department
                            : undefined,"factoryNumber":_vm.task.factoryNumber}})],1),_c('li',[_c('DisplayDeviceDetailsInline',{attrs:{"show-copy-btn":_vm.showCopyBtn,"constructionYear":_vm.task.constructionYear,"inOperationYear":_vm.task.inOperationYear}})],1),(_vm.task.process && _vm.task.objectType)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$translateKebablizerLower(
                            ("task-process-type-" + (_vm.task.process))
                        ),"valueText":_vm.$t(("task-object-type-" + (_vm.task.objectType)))}})],1):_vm._e(),(_vm.task.process === _vm.PROCESS_TYPES.REGISTRATION)?[_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-working-title'),"valueText":_vm.task.workingTitle}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.task.workingTitle}}):_vm._e()],1)]:_vm._e(),(_vm.task.process === _vm.PROCESS_TYPES.DEREGISTRATION)?[_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('equipment-id-label')},scopedSlots:_vm._u([{key:"valueText",fn:function(){return [(_vm.task.sapEquipmentNr)?[(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.task.sapEquipmentNr}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.task.sapEquipmentNr))])]:[_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t("not-recorded")))])]]},proxy:true}],null,false,2908938482)})],1)]:_vm._e()],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }