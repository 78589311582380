<i18n src="./summary-actively-heated-i18n.yaml"></i18n>
<template>
    <BaseListItem
        v-if="task.inspectionType"
        :title="$t('title-actively-heated')"
        :expandable="expandable"
    >
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li v-if="task.inspectionType === INSPECTION_TYPES.SVTI">
                    {{ $t("inspection-type-svti") }}
                </li>
                <li v-else>
                    <i18n
                        path="inspection-type-specialist"
                        tag="span"
                        v-if="specialistCompanyName"
                    >
                        <template #specialistCompany>
                            <b>{{ specialistCompanyName }}</b>
                        </template>
                    </i18n>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import { INSPECTION_TYPES } from "@/data/globals.js";
import mixin from "../mixin";
export default {
    name: "SummaryActivelyHeated",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    data: () => ({
        INSPECTION_TYPES
    }),
    computed: {
        specialistCompanyName() {
            return this.task?.specialistCompany?.name;
        }
    }
};
</script>
