<i18n src="./message-text-i18n.yaml"></i18n>
<template>
    <v-card flat outlined v-bind="$attrs" width="100%">
        <v-card-text v-if="text">
            <v-layout>
                <v-layout>
                    <v-flex absolute>
                        <div
                            class="text-body-1"
                            v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br />')"
                        ></div>
                        <v-divider />
                        <div
                            class="pt-2 text-caption font-weight-thin"
                            v-if="createdBy && timestamp"
                        >
                            {{
                                $t("caption-create", {
                                    userName: createdBy,
                                    timestamp: $moment
                                        .utc(timestamp)
                                        .local()
                                        .from($moment())
                                })
                            }}
                        </div>
                    </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
                <slot name="actions"></slot>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "MessageText",
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        text: {
            type: String,
            default: null
        },
        createdBy: {
            type: String,
            default: null
        },
        timestamp: {
            type: String,
            default: null
        }
    }
};
</script>
