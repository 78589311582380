<i18n src="./addresses-menu-i18n.yaml"></i18n>
<template>
    <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="item in items"
                :key="item.id"
                @click="$emit(item.emit)"
            >
                <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
const MENU_TYPES = {
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE"
};
export default {
    name: "AddressesMenu",
    computed: {
        items() {
            return [
                {
                    id: MENU_TYPES.EDIT,
                    title: this.$t("action-edit"),
                    icon: "mdi-pencil",
                    emit: "click:edit"
                },
                {
                    id: MENU_TYPES.DELETE,
                    title: this.$t("action-delete"),
                    icon: "mdi-delete",
                    emit: "click:delete"
                }
            ];
        }
    }
};
</script>
