<i18n src="./card-address-book-i18n.yaml"></i18n>
<template>
    <base-card class="fill-height d-flex" v-bind="$attrs" v-on="$listeners">
        <template v-if="multimediaView === 'grid'">
            <v-row dense no-gutters>
                <v-col cols="12" v-if="getAddressTitleById(address.id)">
                    <div class="mt-2 ml-2 primary--text font-weight-medium title">
                        <v-icon>
                            {{ getAddressIconById(address.id) }}
                        </v-icon>
                        {{ getAddressTitleById(address.id) }}
                    </div>
                    <v-divider class="mx-2 mb-2" />
                </v-col>
                <v-col cols="12" class="pt-2" style="height: 100%;">
                    <DisplayAddress :address="address" />
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-divider class="pb-2" />
            <v-list-item>
                <v-list-item-content>
                    <div v-if="getAddressTitleById(address.id)">
                        <v-icon>{{ getAddressIconById(address.id) }}</v-icon>
                        {{ getAddressTitleById(address.id) }}
                    </div>
                    <v-divider v-if="getAddressTitleById(address.id)" class="mb-2" />
                    <DisplayAddressInline :address="address" />
                </v-list-item-content>
            </v-list-item>
        </template>
    </base-card>
</template>
<script>
import { ADDRESS_IDS } from "@/data/globals.js";
export default {
    name: "CardAddressBook",
    components: {
        DisplayAddress: () =>
            import("@/components/general/display-address.vue"),
        DisplayAddressInline: () =>
            import("@/components/general/display-address-inline.vue")
    },
    props: {
        address: {
            type: Object,
            default: () => {}
        },
        multimediaView: {
            type: String,
            default: "grid",
            validator: (value) => {
                const validTypes = ["grid", "list"];
                return validTypes.indexOf(value) !== -1;
            }
        }
    },
    methods: {
        getAddressIconById(addressId) {
            switch (addressId) {
                case ADDRESS_IDS.OPERATOR:
                    return "mdi-account-hard-hat";
                case ADDRESS_IDS.BILL:
                    return "mdi-currency-usd-circle";
                case ADDRESS_IDS.OWNER:
                    return "mdi-home-map-marker";
                default:
                    return null;
            }
        },
        getAddressTitleById(addressId) {
            switch (addressId) {
                case ADDRESS_IDS.OPERATOR:
                    return this.$t("title-operator");
                case ADDRESS_IDS.BILL:
                    return this.$t("title-bill");
                case ADDRESS_IDS.OWNER:
                    return this.$t("title-owner");
                default:
                    return null;
            }
        }
    }
};
</script>
