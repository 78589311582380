import { BaseBackoffice } from "./";
export class BackofficeFields extends BaseBackoffice {
    constructor() {
        super();
        this.duplicateDecision = null;
        this.lockedUntil = null;
        this.objectCategory = null;
        this.objectCode = null;
        this.objectStatus = null;
        this.regionCode = null;
        this.reminder = null;
        this.reportingToSuva = null;
        this.sizeClass = null;
        this.subCircleCode = null;
    }
}
