<template>
    <v-menu bottom offset-y tile>
        <template v-slot:activator="{ on }">
            <span v-if="items.length > 0" class="split-btn">
                <base-btn
                    v-bind="$attrs"
                    :color="color"
                    class="split-btn main-btn"
                    @click="$emit('click')"
                    ><slot
                /></base-btn>
                <base-btn
                    v-on="on"
                    v-bind="$attrs"
                    :color="color"
                    class="actions-btn"
                    ><v-icon>mdi-menu-down</v-icon></base-btn
                >
            </span>
            <base-btn
                v-else
                v-bind="$attrs"
                :color="color"
                @click="$emit('click')"
                ><slot />
            </base-btn>
        </template>

        <v-list dense flat>
            <slot name="list-content">
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="$emit('list:click', item)"
                >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
            </slot>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    name: "AppBtnSplit",
    props: {
        items: {
            type: Array,
            default: () => []
        },
        color: {
            type: String,
            default: "primary"
        }
    }
};
</script>
<style src="./app-btn-split.styl" lang="stylus" scoped></style>
