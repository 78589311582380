<template>
    <base-btn icon @click.stop="onOpenFaq">
        <v-icon color="grey lighten-1">mdi-information-outline</v-icon>
    </base-btn>
</template>

<script>
export default {
    name: "FaqOpenBtn",
    props: {
        searchId: {
            type: String,
            required: true
        }
    },
    methods: {
        onOpenFaq() {
            this.$root.$faqDialog.open(this.searchId);
        }
    }
};
</script>
