export class TaskDialog {
    constructor() {
        this.messages = [];
        this.step = null;
        this.state = 0;
        this.reminder = null;
        this.createdBy = null;
        this.changedBy = null;
    }
}
