import { ATTACHMENTS_TYPES } from "@/data/globals.js";

// q: question text
// p: paragraph
// d: description is a simple text phrase
// t: inside p is a simple text phrase
// u: unordered list
// o: ordered list
// s: search key
// tags: tag

export const GOTCHAS = [
    {
        q: 'faq-ambulant-object-question',
        d: 'faq-ambulant-object-description',
        p: [
            {
                t: 'faq-ambulant-object-paragraph-text-1'
            },{
                t: 'faq-ambulant-object-paragraph-text-2'
            }
        ],
        s: 'ambulantObject',
        tags: ['faq-tag-technically']
    }, {
        q: 'faq-quick-release-question',
        p: [
            {
                t: 'faq-quick-release-paragraph-text-1'
            },{
                t: 'faq-quick-release-paragraph-text-2'
            }
        ],
        s: 'quickRelease',
        i:[
            {
                path: "faq/quick_release_01.png"
            }, {
                path: "faq/quick_release_02.png"
            }, {
                path: "faq/quick_release_03.png"
            }
        ],
        tags: ['faq-tag-general']
    }, {
        q: 'faq-load-change-assessment-necessary-question',
        p: [
            {
                t: 'faq-load-change-assessment-necessary-paragraph-text-1'
            },{
                t: 'faq-load-change-assessment-necessary-paragraph-text-2'
            },{
                t: 'faq-load-change-assessment-necessary-paragraph-text-3',
                u: [
                    'faq-load-change-assessment-necessary-paragraph-text-3-list-1',
                    'faq-load-change-assessment-necessary-paragraph-text-3-list-2'
                ],
            },{
                t: 'faq-load-change-assessment-necessary-paragraph-text-4'
            }
        ],
        s: 'loadChangeAssessmentNecessary',
        tags: ['faq-tag-general', 'faq-tag-load-change']
    }, {
        q: 'faq-load-change-max-question',
        d: 'faq-load-change-max-description',
        s: 'loadChangeMax',
        tags: ['faq-tag-general', 'faq-tag-load-change']
    }, {
        q: 'faq-load-change-year-question',
        d: 'faq-load-change-year-description',
        s: 'loadChangeYear',
        tags: ['faq-tag-general', 'faq-tag-load-change']
    }, {
        q: 'faq-load-change-duration-question',
        d: 'faq-load-change-duration-description',
        s: 'loadChangeDuration',
        tags: ['faq-tag-general', 'faq-tag-load-change']
    }, {
        q: 'faq-overheating-vulnerable-question',
        d: 'faq-overheating-vulnerable-description',
        s: 'overheatingVulnerable',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-cooling-material-properties-change-question',
        d: 'faq-cooling-material-properties-change-description',
        s: 'coolingMaterialPropertiesChange',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-autonomous-device-question',
        p: [
            {
                t: 'faq-autonomous-device-paragraph-text-1'
            },{
                t: 'faq-autonomous-device-paragraph-text-2'
            },{
                t: 'faq-autonomous-device-paragraph-text-3',
            }
        ],
        s: 'autonomousDevice',
        tags: ['faq-tag-general', 'faq-tag-load-change']
    }, {
        q: 'faq-bob-items-question',
        p: [
            {
                t: 'faq-bob-items-paragraph-text-1',
                u: [
                    'faq-bob-items-paragraph-text-1-ulist-1',
                    'faq-bob-items-paragraph-text-1-ulist-2'
                ]
            },{
                t: 'faq-autonomous-device-paragraph-text-2',
                u: [
                    'faq-bob-items-paragraph-text-2-ulist-1',
                    'faq-bob-items-paragraph-text-2-ulist-2',
                    'faq-bob-items-paragraph-text-2-ulist-3',
                    'faq-bob-items-paragraph-text-2-ulist-4'
                ]
            }
        ],
        s: 'bobItems',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-specific-designation-question',
        d: 'faq-specific-designation-description',
        s: 'specificDesignation',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-inventory-number-question',
        d: 'faq-inventory-number-description',
        s: 'inventoryNumber',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-equipment-description-question',
        d: 'faq-equipment-description-description',
        s: 'equipmentDescription',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-pos-location-question',
        d: 'faq-pos-location-description',
        s: 'posLocation',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-construction-number-question',
        d: 'faq-construction-number-description',
        s: 'constructionNumber',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-order-reference-number-question',
        d: 'faq-order-reference-number-description',
        s: 'orderReferenceNumber',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-equipment-contact-person-question',
        d: 'faq-equipment-contact-person-description',
        s: 'equipmentContactPerson',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-chambers-definition-question',
        d: 'faq-chambers-definition-description',
        s: 'chambersDefinition',
        tags: ['faq-tag-general', 'faq-tag-chamber'],
        i: [
            {
                path: "faq/chamber_definition.png"
            }
        ]
    }, {
        q: 'faq-chambers-description-question',
        d: 'faq-chambers-description-description',
        s: 'chambersDescripton',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-volume-question',
        d: 'faq-chambers-volume-description',
        s: 'chambersVolume',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-last-min-concession-pressure-question',
        d: 'faq-chambers-last-min-concession-pressure-description',
        s: 'chambersLastMinConcessionPressure',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-last-max-concession-pressure-question',
        d: 'faq-chambers-last-max-concession-pressure-description',
        s: 'chambersLastMaxConcessionPressure',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-last-min-concession-temperature-question',
        d: 'faq-chambers-last-min-concession-temperature-description',
        s: 'chambersLastMinConcessionTemperature',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-last-max-concession-temperature-question',
        d: 'faq-chambers-last-max-concession-temperature-description',
        s: 'chambersLastMaxConcessionTemperature',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-last-min-pressure-question',
        d: 'faq-chambers-last-min-pressure-description',
        s: 'chambersLastMinPressure',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-last-max-pressure-question',
        d: 'faq-chambers-last-max-pressure-description',
        s: 'chambersLastMaxPressure',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-test-pressure-question',
        d: 'faq-chambers-test-pressure-description',
        s: 'chambersTestPressure',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-test-medium-question',
        d: 'faq-chambers-test-medium-description',
        s: 'chambersTestMedium',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-material-code-question',
        d: 'faq-chambers-material-code-description',
        s: 'chambersMaterialCode',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-coverage-not-available-question',
        d: 'faq-chambers-coverage-not-available-description',
        s: 'chambersCoverageNotAvailable',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-chambers-coverage-pressure-question',
        d: 'faq-chambers-coverage-pressure-description',
        s: 'chambersCoveragePressure',
        tags: ['faq-tag-chamber']
    }, {
        q: 'faq-operator-address-type-question',
        d: 'faq-operator-address-type-description',
        s: 'operatorAddressType',
        tags: ['faq-tag-general']
    }, {
        q: 'faq-uid-search-question',
        p: [
            {
                t: 'faq-uid-search-paragraph-text-1'
            },{
                t: 'faq-uid-search-paragraph-text-2'
            },{
                t: 'faq-uid-search-paragraph-text-3',
            }
        ],
        s: 'uidSearch',
        tags: ['faq-tag-uid-search']
    }, {
        q: 'faq-attachments-drawing-question',
        d: 'faq-attachments-drawing-description',
        s: `attachments${ATTACHMENTS_TYPES.DRAWING}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-assembly-certificate-question',
        p: [
            {
                t: 'faq-attachments-assembly-certificate-paragraph-text-1'
            },{
                t: 'faq-attachments-assembly-certificate-paragraph-text-2'
            },{
                t: 'faq-attachments-assembly-certificate-paragraph-text-3',
                u: [
                    'faq-attachments-assembly-certificate-paragraph-text-3-ulist-1',
                    'faq-attachments-assembly-certificate-paragraph-text-3-ulist-2',
                    'faq-attachments-assembly-certificate-paragraph-text-3-ulist-3',
                ]
            },{
                t: 'faq-attachments-assembly-certificate-paragraph-text-4'
            },{
                t: 'faq-attachments-assembly-certificate-paragraph-text-5'
            },{
                t: 'faq-attachments-assembly-certificate-paragraph-text-6'
            }
        ],
        s: `attachments${ATTACHMENTS_TYPES.ASSEMBLY_CERTIFICATE}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-type-examination-question',
        p: [
            {
                t: 'faq-attachments-type-examination-paragraph-text-1',
            },{
                t: 'faq-attachments-type-examination-paragraph-text-2'
            },{
                t: 'faq-attachments-type-examination-paragraph-text-3'
            }
        ],
        s: `attachments${ATTACHMENTS_TYPES.TYPE_EXAMINATION}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-declaration-of-conformity-and-certificates-question',
        d: 'faq-attachments-declaration-of-conformity-and-certificates-description',
        p: [
            {
                t: 'faq-attachments-declaration-of-conformity-and-certificates-paragraph-text-1',
            },{
                t: 'faq-attachments-declaration-of-conformity-and-certificates-paragraph-text-2'
            },{
                t: 'faq-attachments-declaration-of-conformity-and-certificates-paragraph-text-3'
            },{
                t: 'faq-attachments-declaration-of-conformity-and-certificates-paragraph-text-4'
            }
        ],
        s: `attachments${ATTACHMENTS_TYPES.DECLARATION_OF_CONFORMITY_AND_CERTIFICATES}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-operating-and-maintenance-instructions-question',
        d: 'faq-attachments-operating-and-maintenance-instructions-description',
        p: [
            {
                t: 'faq-attachments-operating-and-maintenance-instructions-paragraph-text-1',
            },{
                t: 'faq-attachments-operating-and-maintenance-instructions-paragraph-text-2'
            }
        ],
        s: `attachments${ATTACHMENTS_TYPES.OPERATING_AND_MAINTENANCE_INSTRUCTIONS}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-safety-valve-setting-certificate-question',
        d: 'faq-attachments-safety-valve-setting-certificate-description',
        p: [
            {
                t: 'faq-attachments-safety-valve-setting-certificate-paragraph-text-1',
                u: [
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-1',
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-2',
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-3',
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-4',
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-5',
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-6',
                    'faq-attachments-safety-valve-setting-certificate-paragraph-text-1-ulist-7'
                ]
            },{
                t: 'faq-attachments-safety-valve-setting-certificate-paragraph-text-2'
            }
        ],
        s: `attachments${ATTACHMENTS_TYPES.SAFETY_VALVE_SETTING_CERTIFICATE}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-additional-question',
        d: 'faq-attachments-additional-description',
        s: `attachments${ATTACHMENTS_TYPES.ADDITIONAL}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-attachments-inspection-plan-question',
        d: 'faq-attachments-inspection-plan-description',
        s: `attachments${ATTACHMENTS_TYPES.INSPECTION_PLAN}`,
        tags: ['faq-tag-attachments']
    }, {
        q: 'faq-inspection-question',
        d: 'faq-inspection-description',
        s: 'inspectionType',
        tags: ['faq-tag-inspection']
    }, {
        q: 'faq-addressbook-question',
        d: 'faq-addressbook-description',
        s: 'addressBook',
        tags: ['faq-tag-addressbook']
    }
];
