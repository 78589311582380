<i18n src="./dropzone-uploader-i18n.yaml"></i18n>
<template>
    <div id="myDropzone" class="dropzone" ref="dropzoneElement">
        <div class="uploadIconContainer">
            <v-icon class="iconUploadFiles ml-4 mb-4" color="info" size="90">
                mdi-upload
            </v-icon>
        </div>
    </div>
</template>
<script>
import "dropzone/dist/dropzone.css";
import Dropzone from "dropzone";
import _ from "lodash";
Dropzone.autoDiscover = false;
export default {
    name: "DropzoneUploader",
    props: {
        url: {
            type: String,
            default: null
        },
        acceptedFiles: {
            type: String,
            default: ".png, .jpg, .jpeg, .pdf" //set the accepted files for the upload container
        },
        params: {
            type: Object,
            default: () => {}
        },
        textInnerUploadContainer: {
            type: String,
            default: null
        },
        maxNumberUploadFiles: {
            type: Number,
            default: 100 //set maximal files number
        },
        maxFileSize: {
            type: Number,
            default: 20 // MB
        },
        minWidthDropzone: {
            type: Number,
            default: null
        },
        minHeightDropzone: {
            type: Number,
            default: null
        },
        chunkSize: {
            type: Number,
            default: 800000
        },
        category: {
            type: String,
            default: null
        },
        docId: {
            type: String,
            default: null
        },
        dictDefaultMessage: {
            type: String,
            default: "Drop files here or click to upload."
        }
    },
    data() {
        return {
            dropzoneOptions: this.setDefaultOptions()
        };
    },
    methods: {
        getApiUrl() {
            return this.url
                ? this.url
                : this.$axiosBinary.defaults.baseURL + "/";
        },
        setDefaultOptions() {
            let me = this;
            return {
                url: this.getApiUrl(),
                dictRemoveFile: "<span class='mdi mdi-delete'></span>",
                clickable: ".uploadIconContainer", //Set click event for the upload button
                dictDefaultMessage: this.dictDefaultMessage, //set a default mensage to appear on the upload container
                autoProcessQueue: false, //for upload files with a button
                maxFilesize: this.maxFileSize, //set maximal file size in MB for upload
                maxFiles: this.maxNumberUploadFiles, //set maximal files number
                addRemoveLinks: true, //Add a link for each file, to remove the file from upload container
                parallelUploads: 1, //Sets how many uploads you can make at the same time
                chunking: true,
                forceChunking: true,
                chunkSize: this.chunkSize,
                parallelChunkUploads: false,
                params: function (files, xhr, chunk) {
                    let retPrams = {};
                    retPrams.docId = me.docId;
                    retPrams.category = me.category;
                    retPrams.filename = chunk.file.name;
                    retPrams.filetype = chunk.file.type;
                    retPrams.dzuuid = chunk.file.upload.uuid;
                    retPrams.dzchunkindex = chunk.index;
                    retPrams.dzchunksize = me.chunkSize;
                    retPrams.dzchunkbyteoffset = chunk.index * me.chunkSize;
                    retPrams.dztotalfilesize = chunk.file.size;
                    retPrams.dztotalchunkcount =
                        chunk.file.upload.totalChunkCount;
                    retPrams = _.assignIn(me.params, retPrams);

                    return retPrams;
                },
                dictCancelUpload: null,
                dictUploadCanceled: null,
                dictCancelUploadConfirmation: null,
                previewTemplate:
                    '<div class="dz-preview dz-file-preview">' +
                    '<div class="dz-image"><img data-dz-thumbnail/>' +
                    "<i class='mdi mdi-pdf'></i>" +
                    "</div>\n" +
                    "<div class='dz-details'>\n" +
                    '<div class="dz-size">' +
                    "<span data-dz-size></span>" +
                    "</div>\n" +
                    '<div class="dz-filename">' +
                    "<span data-dz-name></span>" +
                    "</div>\n" +
                    "</div>\n" +
                    '<div class="dz-progress">' +
                    '<span class="dz-upload" data-dz-uploadprogress></span>' +
                    "</div>\n" +
                    '<div class="dz-error-message">' +
                    "<span data-dz-errormessage></span>" +
                    "</div>\n" +
                    '<div class="dz-success-mark">\n' +
                    "<i class='mdi mdi-check-circle'></i>\n" +
                    "</div>\n" +
                    '<div class="dz-error-mark">\n' +
                    "<i class='mdi mdi-close-circle'></i>\n" +
                    "</div>\n" +
                    "</div>",
                _FileSize: 0,
                acceptedFiles: this.acceptedFiles, //set the accepted files for the upload container
                // Translate error to show the user
                dictResponseError: this.$t("dict-response-error", {
                    statusCode: "{{statusCode}}"
                }),
                dictFileTooBig: this.$t("dict-file-too-big", {
                    filesize: "{{filesize}}",
                    maxFilesize: "{{maxFilesize}}"
                }),
                dictInvalidFileType: this.$t("dict-invalid-file-type", {
                    statusCode: "{{statusCode}}"
                }),
                dictMaxFilesExceeded: this.$t("dict-max-files-exceeded", {
                    statusCode: "{{statusCode}}"
                })
            };
        },
        createDropzone() {
            var me = this;

            this.dropzone = new Dropzone(
                this.$refs.dropzoneElement,
                this.dropzoneOptions
            );

            this.dropzone.on("addedfile", function (file) {
                me.$emit("wdropzone-file-added", file);
            });

            this.dropzone.on("addedfiles", function (files) {
                me.$emit("wdropzone-files-added", files);
            });
            this.dropzone.on("removedfile", function (file) {
                me.$emit("wdropzone-removed-file", file);
            });
            this.dropzone.on("success", function (file, response) {
                me.$emit("wdropzone-success", file, response);
            });
            this.dropzone.on("successmultiple", function (file, response) {
                me.$emit("wdropzone-success-multiple", file, response);
            });
            this.dropzone.on("error", function (file, message, xhr) {
                me.$emit("wdropzone-error", file, message, xhr);
                if (!file.accepted) {
                    me.dropzone.removeFile(file);
                }
            });
            this.dropzone.on("errormultiple", function (files, message, xhr) {
                me.$emit("wdropzone-error-multiple", files, message, xhr);
            });
            this.dropzone.on("sending", function (file, xhr, formData) {
                me.$emit("wdropzone-sending", file, xhr, formData);
            });
            this.dropzone.on("sendingmultiple", function (file, xhr, formData) {
                me.$emit("wdropzone-sending-multiple", file, xhr, formData);
            });
            this.dropzone.on("complete", function (file) {
                me.$emit("wdropzone-complete", file);
            });
            this.dropzone.on("completemultiple", function (files) {
                me.$emit("wdropzone-complete-multiple", files);
            });
            this.dropzone.on("canceled", function (file) {
                me.$emit("wdropzone-canceled", file);
            });
            this.dropzone.on("canceledmultiple", function (files) {
                me.$emit("wdropzone-canceled-multiple", files);
            });
            this.dropzone.on("maxfilesreached", function (files) {
                me.$emit("wdropzone-max-files-reached", files);
            });
            this.dropzone.on("maxfilesexceeded", function (file) {
                me.$emit("wdropzone-max-files-exceeded", file);
            });
            this.dropzone.on("processing", function (file) {
                me.$emit("wdropzone-processing", file);
            });
            this.dropzone.on("processingmultiple", function (files) {
                me.$emit("wdropzone-processing-multiple", files);
            });
            this.dropzone.on(
                "uploadprogress",
                function (file, progress, bytesSent) {
                    me.$emit(
                        "wdropzone-upload-progress",
                        file,
                        progress,
                        bytesSent
                    );
                }
            );
            this.dropzone.on(
                "totaluploadprogress",
                function (totaluploadprogress, totalBytes, totalBytesSent) {
                    me.$emit(
                        "wdropzone-total-upload-progress",
                        totaluploadprogress,
                        totalBytes,
                        totalBytesSent
                    );
                }
            );
            this.dropzone.on("reset", function () {
                me.$emit("wdropzone-reset");
            });
            this.dropzone.on("queuecomplete", function () {
                me.$emit("wdropzone-queue-complete");
            });
            this.dropzone.on("drop", function (event) {
                me.$emit("wdropzone-drop", event);
            });
            this.dropzone.on("dragstart", function (event) {
                me.$emit("wdropzone-drag-start", event);
            });
            this.dropzone.on("dragend", function (event) {
                me.$emit("wdropzone-drag-end", event);
            });
            this.dropzone.on("dragenter", function (event) {
                me.$emit("wdropzone-drag-enter", event);
            });
            this.dropzone.on("dragover", function (event) {
                me.$emit("wdropzone-drag-over", event);
            });
            this.dropzone.on("dragleave", function (event) {
                me.$emit("wdropzone-drag-leave", event);
            });
            this.dropzone.on("thumbnail", function (file, thumb) {
                me.$emit("wdropzone-thumbnail", file, thumb);
            });
            me.$emit("wdropzone-mounted");
        },
        processQueue() {
            this.dropzone.processQueue();
        },
        reset(force = false) {
            this.dropzone.removeAllFiles(force);
        },
        removeFile(file) {
            this.dropzone.removeFile(file);
        }
    },
    mounted() {
        if (this.$isServer && this.hasBeenMounted) {
            return;
        }
        this.hasBeenMounted = true;
        this.createDropzone();
    }
};
</script>
<style src="./dropzone-uploader.scss" lang="scss"></style>
