import { axiosAttributes } from "@/api/axios-instances";
const state = {
    salutations: [],
    cantons: []
};

const mutations = {
    setAttribute(state, { key, attributes }) {
        if (key && attributes) {
            state[key] = attributes;
        }
    }
};

const getters = {
    salutations(state) {
        return state.salutations;
    },
    cantons(state) {
        return state.cantons;
    }
};
// getStoredAttributesByKey will save/cache all promises here
// so that just 1 promise for each key will be dispatched
// when cached will return every time the same promise
// if promise result is rejected, then the key will be deleted from
// runningPromises object, so than next time when is called,
// the promise can be dispatched again

const runningPromises = {};
const actions = {
    getStoredAttributesByKey: ({ commit, dispatch }, _key) => {
        if (!runningPromises[_key]) {
            runningPromises[_key] = new Promise((resolve, reject) => {
                dispatch("fetchAttributesByKey", _key)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            let attributes = response.data.data;
                            commit("setAttribute", {
                                key: _key,
                                attributes: attributes
                            });
                            resolve(attributes);
                        }
                        resolve([]);
                    })
                    .catch((error) => {
                        delete runningPromises[_key];
                        reject(error);
                    });
            });
        }
        return runningPromises[_key];
    },
    fetchAttributesByKey: (_ctx, _key) => {
        return new Promise((resolve, reject) => {
            axiosAttributes
                .get(`/${_key}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchSalutationsAttributes: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosAttributes
                .get("/getSalutations")
                .then((response) => {
                    let attributes = response.data.data;
                    commit("setAttribute", {
                        key: "salutations",
                        attributes: attributes
                    });
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
