<i18n src="./WAccountSearch-i18n.yaml"></i18n>
<template>
    <v-text-field
        v-model="search"
        @click:clear="reset"
        v-bind="$attrs"
        :clearable="!readonly"
        v-on="$listeners"
        append-icon="mdi-magnify"
        :label="label ? label : $t('label')"
        single-line
        hide-details
        :loading="isLoading"
        filled
    ></v-text-field>
</template>
<script>
import _ from "lodash";
export default {
    name: "WAccountSearch",
    props: {
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        filters: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchValue: null,
            selectedLocation: null,
            numberOfHits: 0,
            search: null,
            page: 0,
            limit: 25,
            searchMinLength: 2,
            isLoading: false
        };
    },
    created() {
        this.fetchData = _.debounce(function (query) {
            if (query && query.length < this.searchMinLength) {
                this.isLoading = false;
                return;
            }
            this.isLoading = true;
            let params = {};
            params.query = query;
            params.start = this.page;
            params.limit = this.limit;
            params.filters = this.filters;
            return new Promise((resolve, reject) => {
                this.$axiosAccountSettings
                    .get("/search", { params })
                    .then((response) => {
                        let resStatus = response.status;
                        if (resStatus === 200) {
                            let resData = response.data;
                            let itemsData = resData.data;
                            this.$emit("items:changed", itemsData.items);
                        }
                        this.isLoading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(this.$t("error-occured"));
                        this.isLoading = false;
                        reject(error);
                    });
            });
        }, 500);
    },
    methods: {
        reset() {
            this.searchValue = null;
            this.search = null;
            this.resetSearch();
            this.$emit("reset");
        },
        resetSearch() {
            this.selectedLocation = null;
            this.numberOfHits = 0;
        }
    },
    watch: {
        search(val) {
            if (!val || val.length < this.searchMinLength) {
                this.loader().hide();
                if (!this.selectedLocation) {
                    this.resetSearch();
                }
            } else {
                val = val.trim();

                this.page = 0;
                this.numberOfHits = 0;
                this.isLoading = true;
                this.searchValue = val;
                this.fetchData(val);
            }
        },
        value: {
            handler: function (val) {
                if (val && typeof val === "object" && val !== null) {
                    if (!val.zipCode && !val.city) {
                        if (this.selectedLocation) {
                            this.reset();
                        }
                        return;
                    }
                    if (val.zipCode && val.city) {
                        this.locationsList.push(val);
                        this.searchValue = val.zipCode + " " + val.city;
                        this.selectedLocation = this.locationsList[0];
                    }
                } else {
                    if (!val) {
                        this.reset();
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
