<i18n src="./WCantonField-i18n.yaml"></i18n>
<template>
    <base-autocomplete
        :items="sortedCantonItems"
        :clearable="!readonly"
        :label="$t('canton-label')"
        :disabled="disabled"
        v-model="canton"
        v-bind="$attrs"
        v-on="$listeners"
        single-line
        :filter="filterItems"
        item-value="key"
        item-key="key"
        :item-text="(item) => item.key"
    >
        <template v-slot:item="{ item }">
            <template>
                <v-list-item-avatar>
                    <v-img
                        :src="
                            require('@/assets/canton/' +
                                item.key.toLowerCase() +
                                '.png')
                        "
                    ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title
                        v-html="item.value[$i18n.locale]"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="item.key"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </template>
    </base-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
    name: "WCantonField",

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        filterItems(item, queryText) {
            const cantonKey = item.key.toLowerCase();
            const cantonText = item.value[this.$i18n.locale].toLowerCase();
            const searchText = queryText.toLowerCase();

            return (
                cantonKey.indexOf(searchText) > -1 ||
                cantonText.indexOf(searchText) > -1
            );
        }
    },

    computed: {
        cantonItems() {
            return _.clone(this.cantons);
        },
        ...mapGetters({
            cantons: "attributes/cantons"
        }),
        canton: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        },
        sortedCantonItems: function () {
            //sort all canton by name with actual selected language
            let compare = (a, b) => {
                if (a.value[this.$i18n.locale] < b.value[this.$i18n.locale])
                    return -1;
                if (a.value[this.$i18n.locale] > b.value[this.$i18n.locale])
                    return 1;
                return 0;
            };
            let cantons = this.cantonItems.slice(0).sort(compare);

            return cantons.filter((item) => !item.key.includes("FL"));
        }
    },

    created() {
        let cantonKey = "cantons";
        this.getStoredAttributesByKey(cantonKey);
    }
};
</script>
