import { Base } from "./Base";
export class BaseDeregistration extends Base {
    constructor() {
        super();
        this.operatorName = null;
        this.sapEquipmentNr = null;
        this.reason = null;
        this.targetDate = null;
        this.newAddress = {};
    }
}
