<i18n src="./table-i18n.yaml"></i18n>
<template>
    <div>
        <GlobalTable
            :items="specialistCompanies"
            :options.sync="tableOptions"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            @click:action="onActionClick"
            :funcShowAction="showAction"
            :funcRowClasses="rowClasses"
            :search="search"
            @options:change="
                (options) => {
                    Object.assign(tableOptions, options);
                }
            "
            :btnAddText="$t('btn-add-company')"
            @click:add="onAdd"
            @click:reload="fetchAllCompanies"
            @input:search="search = $event"
            :isLoading="isLoading"
            :sort-by="['deletedState', 'active', 'name']"
            :sort-desc="[false, true, false]"
            multi-sort
        >
            <template v-slot:item.active="{ item }">
                <div class="pt-2">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <!-- item is deleted  -->
                            <v-icon
                                v-on="on"
                                v-if="item.deletedState > 0"
                                color="red"
                                >mdi-delete</v-icon
                            >
                            <!-- item is enable  -->
                            <v-icon
                                v-on="on"
                                v-else-if="item.active"
                                color="green"
                                >mdi-check-circle</v-icon
                            >
                            <!-- item is disabled -->
                            <v-icon v-on="on" v-else color="grey"
                                >mdi-close-circle</v-icon
                            >
                        </template>
                        <span>
                            <div v-if="item.deletedState > 0">
                                {{ $t("status-deleted") }}
                            </div>
                            <div v-else-if="item.active">
                                {{ $t("status-enabled") }}
                            </div>
                            <div v-else>
                                {{ $t("status-disabled") }}
                            </div>
                        </span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.country="{ item }">
                <div class="pt-3" v-if="item.country">
                    <country-flag :country="item.country" />
                </div>
            </template>
        </GlobalTable>
        <DialogAddress
            :value="dialogAddress"
            @input="dialogAddress = $event"
            :requiredFields="formAddressRequiredFields"
            :title="computedTitle"
            :address="address"
            @save="onSaveAddress"
            @address:input="
                (newAddress) => {
                    address = newAddress;
                }
            "
            @address:reset="onResetAddress"
        />
    </div>
</template>
<script>
import GlobalTable from "@/components/general/table.vue";
import { mapState } from "vuex";
import DialogAddress from "@/components/general/dialog-address.vue";
import { CompanyAddress } from "@/models/tasks";

const ActionTypes = {
    EDIT: "EDIT",
    DISABLE: "DISABLE",
    ENABLE: "ENABLE",
    DELETE: "DELETE",
    VIEW_HISTORY: "VIEW_HISTORY"
};

export default {
    name: "SpecialistsCompaniesTable",
    components: {
        GlobalTable,
        DialogAddress
    },
    data: () => ({
        tableOptions: {
            itemsPerPage: 10
        },
        actions: [],
        search: "",
        dialogAddress: false,
        formAddressRequiredFields: ["companyName", "country"],
        address: {},
        editedIndex: -1
    }),
    computed: {
        ...mapState("specialistsCompanies", {
            specialistCompanies: "companies"
        }),
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        computedTitle() {
            if (this.address.docId) {
                return this.$t("dialog-company-title-edit");
            } else {
                return this.$t("dialog-company-title-new");
            }
        },
        headers() {
            return [
                {
                    text: this.$t("header-status"),
                    value: "active",
                    sortable: true,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-name"),
                    align: "top",
                    sortable: false,
                    value: "name",
                    isMobile: true
                },
                {
                    text: this.$t("header-country"),
                    value: "country",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        },
        actionsItems() {
            return [
                {
                    title: this.$t("action-edit"),
                    icon: "mdi-pencil",
                    action: ActionTypes.EDIT
                },
                {
                    title: this.$t("action-disable"),
                    icon: "mdi-close-circle",
                    action: ActionTypes.DISABLE
                },
                {
                    title: this.$t("action-enable"),
                    icon: "mdi-check-circle",
                    action: ActionTypes.ENABLE
                },
                {
                    title: this.$t("action-delete"),
                    icon: "mdi-delete",
                    action: ActionTypes.DELETE
                }
            ];
        }
    },
    methods: {
        onActionClick({ action, item }) {
            if (item.id) {
                item.docId = item.id;
            }
            this.selectedRegistration = item;
            switch (action) {
                case ActionTypes.EDIT:
                    this.onEdit(item);
                    break;
                case ActionTypes.DISABLE:
                    this.onDisable(item);
                    break;
                case ActionTypes.ENABLE:
                    this.onEnable(item);
                    break;
                case ActionTypes.DELETE:
                    this.onDelete(item);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        fetchAllCompanies() {
            this.loader().show(this.$t("loading-msg-get-data"));
            this.$store
                .dispatch("specialistsCompanies/fetchAllSpecialistCompanies")
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        showAction(item, actionItem) {
            const itemDeleted = item.deletedState > 0;
            const itemActive = item.active;
            const action = actionItem.action;
            if (itemDeleted) {
                return false;
            }
            if (action === "enable" && itemActive) {
                return false;
            }
            if ((action === "disable" || action === "view") && !itemActive) {
                return false;
            }
            return true;
        },
        onDisable(item) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-on-disable-title", {
                        companyName: item.name
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-on-disable-msg", {
                    companyName: item.name
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.loader().show();
                    let data = {};
                    data.docId = item.docId;
                    data.active = false;
                    return this.dispatchUpdateCompany(data);
                }
            });
        },
        onEnable(item) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-on-enable-title", {
                        companyName: item.name
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-on-enable-msg", {
                    companyName: item.name
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.loader().show();
                    let data = {};
                    data.docId = item.docId;
                    data.active = true;
                    return this.dispatchUpdateCompany(data);
                }
            });
        },
        onDelete(item) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-on-delete-title", {
                        companyName: item.name
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-on-delete-msg", {
                    companyName: item.name
                }),
                type: "reason"
            };
            this.$root.$msgBox.open(msgOptions).then(({ action, reason }) => {
                if (action === "agree" && reason) {
                    this.loader().show();
                    let data = {};
                    data.docId = item.docId;
                    data.reason = reason;
                    this.$store
                        .dispatch(
                            "specialistsCompanies/deleteSpecialistCompany",
                            data
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                this.$toast.success(
                                    this.$t("on-update-msg-success")
                                );
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t("on-update-msg-error"));
                            console.log(error);
                            this.loader().hide();
                        });
                }
            });
        },
        onAdd() {
            this.onResetAddress();
            this.dialogAddress = true;
        },
        onEdit(item) {
            const docId = item.docId;
            if (!docId) return;
            this.$store
                .dispatch("specialistsCompanies/getSpecialistCompany", docId)
                .then((response) => {
                    if (response.status === 200) {
                        const resData = response.data;
                        const companyData = resData.data;
                        this.address = Object.assign(
                            new CompanyAddress(),
                            companyData.address
                        );
                        this.address.companyName = companyData.name;
                        this.address.department = companyData.department;
                        this.address.docId = companyData.docId;
                        this.dialogAddress = true;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("error-msg"));
                    console.log(error);
                    this.loader().hide();
                });
        },
        dispatchUpdateCompany(company) {
            if (!company) return;
            this.$store
                .dispatch(
                    "specialistsCompanies/updateSpecialistCompany",
                    company
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("on-update-msg-success"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("on-update-msg-error"));
                    console.log(error);
                    this.loader().hide();
                });
        },
        rowClasses(item) {
            let classes = [];
            if (item.deletedState > 0) {
                classes.push("disabled");
            }
            return classes;
        },
        onSaveAddress(address) {
            if (!address) return;
            let company = {};
            company.address = address;
            company.name = address.companyName;
            company.department = address.department;
            if (this.address.docId) {
                company.docId = this.address.docId;
                this.$store
                    .dispatch(
                        "specialistsCompanies/updateSpecialistCompany",
                        company
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.success(
                                this.$t("on-update-msg-success", {
                                    company: company.name
                                })
                            );
                        }
                        this.dialogAddress = false;
                        this.onResetAddress();
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        const response = error.response;
                        const resData = response.data;
                        const errorCode = resData.code || 0;
                        switch (errorCode) {
                            case 1: {
                                const companyData = resData.data;
                                this.$toast.error(
                                    this.$t("error-msg-already-exists", {
                                        company: companyData.name
                                    })
                                );
                                break;
                            }
                            default: {
                                this.$toast.error(this.$t("error-msg"));
                                break;
                            }
                        }
                    });
            } else {
                this.$store
                    .dispatch(
                        "specialistsCompanies/addSpecialistCompany",
                        company
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.success(
                                this.$t("on-add-msg-success", {
                                    company: company.name
                                })
                            );
                        }
                        this.dialogAddress = false;
                        this.onResetAddress();
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        const response = error.response;
                        const resData = response.data;
                        const errorCode = resData.code || 0;
                        switch (errorCode) {
                            case 1: {
                                const companyData = resData.data;
                                this.$toast.error(
                                    this.$t("error-msg-already-exists", {
                                        company: companyData.name
                                    })
                                );
                                break;
                            }
                            default: {
                                this.$toast.error(this.$t("error-msg"));
                                break;
                            }
                        }
                        this.loader().hide();
                    });
            }
        },
        onResetAddress() {
            this.address = new CompanyAddress();
        }
    },
    mounted() {
        this.fetchAllCompanies();
    },
    watch: {
        specialistCompanies: {
            handler: function (companies) {
                if (companies.length > 10) {
                    this.tableOptions.itemsPerPage = 15;
                }
            },
            immediate: true
        }
    }
};
</script>
