import { render, staticRenderFns } from "./main-facts-list.vue?vue&type=template&id=200d51ef&scoped=true&"
import script from "./main-facts-list.vue?vue&type=script&lang=js&"
export * from "./main-facts-list.vue?vue&type=script&lang=js&"
import style0 from "./main-facts-list.styl?vue&type=style&index=0&id=200d51ef&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200d51ef",
  null
  
)

/* custom blocks */
import block0 from "./main-facts-list-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fhome%2Fmain-facts-list.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports