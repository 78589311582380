var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.showAllRegistrationsItems)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.allRegistrationsItems),function(item,idx){return _c('v-col',{key:idx,staticClass:"pa-1",attrs:{"cols":"auto"}},[_c('w-cascader',{attrs:{"text":item.text,"items":item.values},on:{"click":function($event){return _vm.$toast.info($event)},"select":function($event){return _vm.$emit('change', $event)}}})],1)}),1):_vm._e(),(_vm.show)?_vm._l((_vm.reasons),function(reason){return _c('v-row',{key:reason.value,staticClass:"my-6",attrs:{"align":"center","no-gutters":""}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('base-btn',_vm._g(_vm._b({attrs:{"color":"info","text":"","small":""}},'base-btn',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" "+_vm._s(_vm.$t( ("step-" + _vm.step + "-reason-" + (reason.value) + "-label") ))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("copy-values")))])])]}}],null,true)},[_c('v-list',[_vm._l((_vm.languages),function(langItem,idx){return [(
                                _vm.messages[langItem.toLowerCase()] &&
                                _vm.messages[langItem.toLowerCase()][
                                    ("step-" + _vm.step + "-reason-" + (reason.value) + "-text")
                                ]
                            )?_c('v-list-item',{key:idx,on:{"click":function($event){_vm.$emit(
                                    'change',
                                    _vm.messages[langItem.toLowerCase()][
                                        ("step-" + _vm.step + "-reason-" + (reason.value) + "-text")
                                    ]
                                )}}},[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(_vm._s(langItem))])],1):_vm._e()]})],2)],1)],1)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }