<i18n src="./multimedia-file-card-i18n.yaml"></i18n>
<template>
    <v-card
        v-if="file"
        :key="file.fileName"
        max-height="175"
        class="ma-2 card-outter"
        width="120"
    >
        <v-card-text
            v-if="file.fileType === 'pdf'"
            style="cursor: pointer"
            class="pa-0 ma-1"
            hover
            @click="$emit('card-text-click')"
        >
            <div class="cls-thumb">
                <v-icon class="ma-4" color="primary" size="60">
                    mdi-file-pdf
                </v-icon>
                <v-row align="center" justify="center">
                    <v-icon class="mr-1">
                        {{ getCardStateIconByState(file.taskState) }}
                    </v-icon>
                    <span
                        class="text-truncate d-inline-block"
                        style="max-width: 70px"
                    >
                        {{ file.originalFileName }}
                    </span>
                </v-row>
            </div>
        </v-card-text>
        <v-card-text v-else class="pa-0 ma-0">
            <v-img
                :src="getFileThumbUrl(file)"
                :lazy-src="getFileThumbUrl(file)"
                v-pswp="getFileUrl(file)"
                class="align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                aspect-ratio="1"
                contain
                width="100%"
                max-height="100"
            >
            </v-img>
            <v-row align="center" justify="center">
                <v-col>
                    <v-icon class="ml-1 mr-1">
                        {{ getCardStateIconByState(file.taskState) }}
                    </v-icon>
                    <span
                        class="align-center text-truncate d-inline-block"
                        style="max-width: 80px"
                    >
                        {{ file.originalFileName }}
                    </span>
                </v-col>
            </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="card-actions">
            <slot name="cardActions">
                <base-btn
                    v-if="deletable && !readonlyModus && !showOptionsMenu"
                    icon
                    small
                    @click="onActionClick('delete', file)"
                >
                    <v-icon>mdi-delete</v-icon>
                </base-btn>
                <v-spacer
                    v-if="deletable && !readonlyModus && !showOptionsMenu"
                ></v-spacer>
                <base-btn
                    v-if="!showOptionsMenu"
                    icon
                    @click="onActionClick('info', file)"
                >
                    <v-icon>mdi-information-outline</v-icon>
                </base-btn>
                <v-spacer
                    v-if="deletable && !readonlyModus && !showOptionsMenu"
                ></v-spacer>
                <base-btn
                    v-if="!showOptionsMenu"
                    icon
                    @click="onActionClick('download', file)"
                >
                    <v-icon>mdi-download</v-icon>
                </base-btn>
                <v-spacer v-if="showOptionsMenu"></v-spacer>
                <v-menu
                    v-if="showOptionsMenu"
                    offset-y
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    close-on-content-click
                >
                    <template v-slot:activator="{ on }">
                        <base-btn v-on="on" icon>
                            <v-icon color="info">
                                mdi-reorder-horizontal
                            </v-icon>
                        </base-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in fileOptions"
                            :key="index"
                            @click="onActionClick(item.id, file)"
                            ripple
                        >
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                                $t(`file-option-${item.id}`)
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </slot>
            <slot name="additionalActions"></slot>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "MultimediaFileCard",
    props: {
        file: {
            type: Object,
            defualt: () => {}
        },
        showOptionsMenu: {
            type: Boolean,
            defualt: false
        },
        deletable: {
            type: Boolean,
            defualt: false
        },
        readonlyModus: {
            type: Boolean,
            defualt: false
        }
    },
    data() {
        return {
            apiBaseURL: this.$axiosBinary.defaults.baseURL,
            fileOptions: [
                { id: "info", icon: "mdi-information-outline" },
                { id: "delete", icon: "mdi-delete" },
                { id: "download", icon: "mdi-download" }
            ]
        };
    },
    methods: {
        getCardStateIconByState(state) {
            if (state === 40 || state === 45) {
                return "mdi-desktop-mac";
            } else {
                return "mdi-devices";
            }
        },
        getFileUrl(file) {
            let url = this.apiBaseURL;
            if (file.url) {
                url += file.url;
            }
            return url;
        },
        getFileThumbUrl(file) {
            let url = this.apiBaseURL;
            if (file.thumbnail) {
                if (file.thumbnail.url) {
                    url += file.url;
                }
            }
            return url;
        },
        onActionClick(action, file) {
            switch (action) {
                case "info":
                    this.$emit("action-info", file);
                    break;
                case "delete":
                    this.$emit("action-delete", file);
                    break;
                case "download":
                    this.$emit("action-download", file);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        }
    }
};
</script>
<style src="./multimedia-file-card.styl" lang="stylus" scoped></style>
