<i18n src="./content-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <AddressCopyOperator
            ref="addressCopyOperator"
            @change:field="onFieldChange"
            @change:address-fields="onAddressFieldsChange"
            @reset="onReset"
            @clear:address="onClearAddress"
            @clear="$emit('clear', $event)"
            @change:address="onChangeAddress"
            @click:copy-address="onClickCopyAddress"
            typeOfAddress="billing"
            :address="value"
            :task="task"
            :readonlyModus="readonlyModus"
            :allowedCopyItems="allowedCopyItems"
            :allowedSameAsItems="allowedSameAsItems"
        >
            <template v-slot v-if="addressType">
                <StepContentTitle
                    :title="$t('additional-billing-information-title')"
                />
                <v-row class="px-2">
                    <v-col cols="12" md="6">
                        <!-- E-Mail -->
                        <base-text-field
                            :readonly="readonlyModus"
                            v-model="electronicInvoicingEmail"
                            :label="$t('electronic-invoicing-email-label')"
                            :error-messages="electronicInvoicingEmailErrors"
                            @input="
                                $v.electronicInvoicingEmail.$dirty
                                    ? $v.electronicInvoicingEmail.$touch()
                                    : null
                            "
                            :required="
                                isRequiredField($v.electronicInvoicingEmail)
                            "
                            :counter="
                                $v.electronicInvoicingEmail.$params.maxLength.max
                            "
                        ></base-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <!-- orderReferenceNumber -->
                        <base-text-field
                            v-model="orderReferenceNumber"
                            :label="$t('order-reference-number-label')"
                            :error-messages="orderReferenceNumberErrors"
                            append-icon="mdi-information-outline"
                            @click:append="showFaq('orderReferenceNumber')"
                            :required="isRequiredField($v.orderReferenceNumber)"
                            :counter="
                                $v.orderReferenceNumber.$params.maxLength.max
                            "
                        ></base-text-field>
                    </v-col>
                </v-row>
            </template>
        </AddressCopyOperator>
    </v-container>
</template>
<script>
import { email, maxLength } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
import { ADDRESS_COPY_TYPES } from "@/data/globals.js";
import { SAME_AS_TYPES } from "@/components/tasks/edit/common/components/same-as-types.js";
export default {
    name: "StepAddressBillContent",
    components: {
        AddressCopyOperator: () =>
            import(
                "@/components/tasks/edit/common/components/address-copy-operator.vue"
            ),
        StepContentTitle: () =>
            import(
                "@/components/tasks/edit/common/components/step-content-title.vue"
            )
    },

    mixins: [validateMixin],

    props: {
        value: {
            type: Object,
            default: () => {}
        },
        billingAdditionalInfos: {
            type: Object,
            default: () => {}
        },
        task: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },

    validations() {
        return {
            electronicInvoicingEmail: {
                email,
                maxLength: maxLength(100)
            },
            orderReferenceNumber: {
                maxLength: maxLength(30)
            }
        };
    },

    data: () => ({
        allowedCopyItems: [ADDRESS_COPY_TYPES.COPY_ADDRESS_OPERATOR],
        allowedSameAsItems: [
            SAME_AS_TYPES.SAME_AS_OPERATOR,
            SAME_AS_TYPES.CREATE_NEW_ADDRESS
        ]
    }),

    computed: {
        addressType() {
            return this.value?.addressType;
        },
        electronicInvoicingEmail: {
            get() {
                return this.billingAdditionalInfos?.electronicInvoicingEmail ||
                       this.task.billingAddress?.electronicInvoicingEmail;
            },
            set(value) {
                this.onAdditionalInfosFieldChange({
                    field: "electronicInvoicingEmail",
                    value
                });
            }
        },
        orderReferenceNumber: {
            get() {
                return this.billingAdditionalInfos?.orderReferenceNumber ||
                       this.task.billingAddress?.orderReferenceNumber;
            },
            set(value) {
                this.onAdditionalInfosFieldChange({
                    field: "orderReferenceNumber",
                    value
                });
            }
        },
        electronicInvoicingEmailErrors() {
            const errors = [];
            if (!this.$v.electronicInvoicingEmail.$dirty) {
                return errors;
            }
            if (!this.$v.electronicInvoicingEmail.email) {
                errors.push(this.$t("field-invalid-email"));
            }
            return errors;
        },
        orderReferenceNumberErrors() {
            const errors = [];
            if (!this.$v.orderReferenceNumber.$dirty) {
                return errors;
            }
            if (!this.$v.orderReferenceNumber.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.orderReferenceNumber.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },

    methods: {
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        onClickCopyAddress(action) {
            this.onChangeAddress(action.address);
        },
        onChangeAddress(address) {
            this.$emit("change:address", address);
        },
        onFieldChange({ field, value }) {
            if (!field) return;
            this.$emit("change:address-field", {
                address: "billingAddress",
                field,
                value
            });
        },
        onAdditionalInfosFieldChange({ field, value }) {
            if (!field) return;
            this.$emit("change:billing-additional-infos-field", {
                field,
                value
            });
        },
        onReset() {
            this.$emit("clear:address", { field: "billingAddress" });
        },
        onClearAddress({ field, address }) {
            if (!field) return;
            this.$emit("clear:address", { field, address });
        },
        onAddressFieldsChange(address) {
            if (this.electronicInvoicingEmail) {
                address.electronicInvoicingEmail =
                    this.electronicInvoicingEmail;
            }
            this.$emit("change-address", {
                field: "billingAddress",
                value: address
            });
        },

        isValid() {
            if (!this.$v.$dirty) return null;
            if (this.$v.$anyError) return false;
            if (this.$refs.addressCopyOperator) {
                return this.$refs.addressCopyOperator.isValid();
            }
            return true;
        },
        validate() {
            this.$v.$touch();
            if (this.$refs.addressCopyOperator) {
                this.$refs.addressCopyOperator.validate();
            }
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                if (this.$refs.addressCopyOperator) {
                    this.$refs.addressCopyOperator.scrollToFirstError();
                }
            });
        }
    }
};
</script>
