<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <form-location
            ref="formLocation"
            @form-change="onFormLocationChange"
            @reset="onResetAddress"
            :address="locationAddress"
            :readonlyModus="readonlyModus"
        >
        </form-location>
        <form-customer-specific-designation
            ref="formCustomerSpecificDesignation"
            @form-change="onFormCustomerSpecificDesignationChange"
            @reset="onResetCustomerSpecificDesignation"
            :customerSpecificDesignation="customerSpecificDesignation"
            :readonlyModus="readonlyModus"
        >
        </form-customer-specific-designation>
        <form-contact-person
            ref="formContactPerson"
            @form-change="onFormContactPersonChange"
            @reset="onResetContactPerson"
            :contactPerson="locationContactPerson"
            :readonlyModus="readonlyModus"
        >
        </form-contact-person>
    </v-container>
</template>
<script>
export default {
    name: "StepPressureDeviceLocationContent",
    components: {
        FormLocation: () =>
            import("@/components/tasks/edit/common/forms/form-location.vue"),
        FormContactPerson: () =>
            import(
                "@/components/tasks/edit/common/forms/form-contact-person.vue"
            ),
        FormCustomerSpecificDesignation: () =>
            import(
                "@/components/tasks/edit/common/forms/form-customer-specific-designation.vue"
            )
    },

    props: {
        locationAddress: {
            type: Object,
            default: () => {}
        },
        locationContactPerson: {
            type: Object,
            default: () => {}
        },
        customerSpecificDesignation: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        validate() {
            this.$refs.formContactPerson.validate();
            this.$refs.formLocation.validate();
            this.$refs.formCustomerSpecificDesignation.validate();
        },
        scrollToFirstError() {
            this.$refs.formContactPerson.scrollToFirstError();
            this.$refs.formLocation.scrollToFirstError();
            this.$refs.formCustomerSpecificDesignation.scrollToFirstError();
        },
        onResetEquipmentLocation({ field, value }) {
            this.$emit("reset:equipment-location", { field, value });
        },
        onResetContactPerson() {
            this.$emit("reset:contact-person");
        },
        onResetCustomerSpecificDesignation() {
            this.$emit("reset:customer-specific-designation");
        },
        onResetAddress() {
            this.$emit("reset:address");
        },
        onFormLocationChange(formData) {
            this.$emit("change:address", formData);
        },
        onFormCustomerSpecificDesignationChange(formData) {
            this.$emit("change:customer-specific-designation", formData);
        },
        onFormContactPersonChange(formData) {
            this.$emit("change:contact-person", formData);
        },
        isValid() {
            let isValid = true;
            const formContactPersonValid =
                this.$refs.formContactPerson.isValid();
            if (!formContactPersonValid) {
                isValid = false;
            }
            const formLocationValid = this.$refs.formLocation.isValid();
            if (!formLocationValid) {
                isValid = false;
            }
            const formCustomerSpecificDesignationValid =
                this.$refs.formCustomerSpecificDesignation.isValid();
            if (!formCustomerSpecificDesignationValid) {
                isValid = false;
            }
            if (
                formContactPersonValid === null &&
                formContactPersonValid === null &&
                formCustomerSpecificDesignationValid === null
            ) {
                return null;
            }
            return isValid;
        }
    }
};
</script>
