<i18n src="./dialog-sap-export-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="close"
        :title="defaultTitle"
        width="700"
        icon="mdi-office-building"
    >
        <template v-slot:content class="mt-4">
            <v-container>
                <base-alert type="warning" dense>
                    {{ $t("msg-alert") }}
                </base-alert>
                <p class="text-body-1">
                    <i18n path="msg-text" tag="span">
                        <template #taskReferenceId>
                            <strong>{{ task.referenceId }}</strong>
                        </template>
                    </i18n>
                </p>
                <v-row dense no-gutters>
                    <v-col cols="6" md="4">
                        <v-checkbox
                            v-model="checkboxDryRun"
                            :label="$t('checkbox-simulate-dry-run-label')"
                        >
                        </v-checkbox>
                    </v-col>
                    <v-col
                        cols="12"
                        v-if="!checkboxDryRun && developerMode"
                        class="py-4"
                    >
                        <ExpandableCard
                            outlined
                            :title="$t('checkbox-simulation-label')"
                        >
                            <template v-slot:content>
                                <v-row dense no-gutters>
                                    <v-col
                                        cols="6"
                                        md="4"
                                        v-for="checkbox in checkboxesSapErrors"
                                        :key="checkbox.key"
                                    >
                                        <v-checkbox
                                            v-model="checkbox.value"
                                            :label="$t(checkbox.label)"
                                            :disabled="
                                                canErrorItemBeDisabled(checkbox)
                                            "
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="6" md="4">
                                        <v-checkbox
                                            v-model="
                                                checkboxSimulateStateComplete
                                            "
                                            :label="
                                                $t(
                                                    'checkbox-simulate-state-complete-label'
                                                )
                                            "
                                        >
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </template>
                        </ExpandableCard>
                    </v-col>
                </v-row>
                <v-form>
                    <v-row>
                        <v-col cols="12" md="6">
                            <base-text-field
                                v-model="formModel.username"
                                :error-messages="usernameErrors"
                                :label="$t('username-label')"
                                :required="
                                    isRequiredField($v.formModel.username)
                                "
                            >
                            </base-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <base-text-field
                                v-model="formModel.password"
                                :error-messages="passwordErrors"
                                :label="$t('password-label')"
                                :type="showPassword ? 'text' : 'password'"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :required="
                                    isRequiredField($v.formModel.password)
                                "
                                @click:append="showPassword = !showPassword"
                            >
                            </base-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn type="primary" @click="save">
                <v-icon>mdi-save</v-icon>
                {{ $t("btn-save") }}
            </base-btn>
        </template></base-dialog
    >
</template>

<script>
import validateMixin from "@/mixins/validate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import ExpandableCard from "@/components/general/cards/expandable-card.vue";
export default {
    name: "DialogSapExport",
    mixins: [validateMixin],
    components: {
        ExpandableCard
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        task: {
            type: Object,
            default: () => {}
        }
    },
    validations: {
        formModel: {
            username: { required },
            password: { required }
        }
    },
    data: () => ({
        dialog: false,
        showPassword: false,
        formModel: {
            username: null,
            password: null
        },
        checkboxesSapErrors: [
            {
                key: "simulate400",
                value: false,
                label: "checkbox-simulate-400-label"
            },
            {
                key: "simulate500",
                value: false,
                label: "checkbox-simulate-500-label"
            },
            {
                key: "simulate502",
                value: false,
                label: "checkbox-simulate-502-label"
            },
            {
                key: "simulate504",
                value: false,
                label: "checkbox-simulate-504-label"
            }
        ],
        checkboxSimulateStateComplete: false,
        checkboxDryRun: false
    }),
    computed: {
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        defaultTitle() {
            if (this.title) {
                return this.title;
            }
            return this.$t("title");
        },
        usernameErrors() {
            const errors = [];
            if (!this.$v.formModel.username.$dirty) {
                return errors;
            }
            if (!this.$v.formModel.username.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.formModel.password.$dirty) {
                return errors;
            }
            if (!this.$v.formModel.password.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        checkboxesSapErrorsEnabled() {
            return this.checkboxesSapErrors.filter(
                (item) => item.value === true
            );
        },
        isAnySapErrorEnabled() {
            return this.checkboxesSapErrorsEnabled.length !== 0;
        },
        isSimulation() {
            return this.checkboxDryRun || this.checkboxesSapErrorsEnabled.length > 0;
        }
    },
    methods: {
        canErrorItemBeDisabled(item) {
            if (this.isAnySapErrorEnabled) {
                return this.checkboxesSapErrorsEnabled.some(
                    (itemEnabled) => itemEnabled.value !== item.value
                );
            }
            return false;
        },
        close() {
            this.dialog = false;
            this.$emit("input", false);
        },
        save() {
            this.validate();
            if (!this.isValid()){
                return this.$toast.error(this.$t("has-errors-msg"));
            }
            this.loader().show(this.$t("loading-msg"));
            let data = {
                ...this.formModel,
                taskDocId: this.task.docId
            };
            if (this.isSimulation) {
                let simulation = {};
                if (this.checkboxDryRun) {
                    simulation.dryRun = true;
                } else {
                    this.checkboxesSapErrorsEnabled.forEach((item) => {
                        simulation[item.key] = item.value;
                    });
                    if (this.checkboxSimulateStateComplete) {
                        simulation.state = true;
                    }
                }
                data.simulation = simulation;
            }
            this.$store
                .dispatch("backoffice/exportTaskToSap", data)
                .then(response => {
                    if (response.status === 200) {
                        if (this.isSimulation) {
                            this.$toast.success(
                                this.$t("msg-response-sucess-simulation")
                        );
                        } else {
                            this.$toast.success(this.$t("msg-response-sucess"));
                            this.$store.commit(
                                "backoffice/removeRegistrationFromList",
                                {
                                    list: "allReadyForSapExport",
                                    docId: this.task.docId
                                }
                            );
                            this.$store.commit(
                                "backoffice/removeRegistrationFromList",
                                {
                                    list: "boReadyForSapExport",
                                    docId: this.task.docId
                                }
                            );
                            this.close();
                            this.$emit("save:success");
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    const errorCode = error?.response?.data?.code;
                    switch (errorCode) {
                        case 3:
                            this.$toast.error(
                                this.$t(
                                    "msg-response-error-sap-invalid-or-missing-field"
                                )
                            );
                            break;
                        case 4:
                            this.$toast.error(
                                this.$t("msg-response-error-sap-unauthorized")
                            );
                            break;
                        case 5:
                            this.$toast.error(
                                this.$t("msg-response-error-sap-bad-request")
                            );
                            break;
                        case 6:
                            this.$toast.error(
                                this.$t(
                                    "msg-response-error-sap-connection-unavailable"
                                )
                            );
                            break;
                        case 7:
                            this.$toast.error(
                                this.$t("msg-response-error-sap-communication")
                            );
                            break;
                        case 8:
                            this.$toast.error(
                                this.$t("msg-response-error-sap-general-error")
                            );
                            break;
                        default:
                            this.$toast.error(
                                this.$t("msg-response-error-export")
                            );
                            break;
                    }
                    this.loader().hide();
                    this.$emit("save:error");
                });
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>

<style>
</style>
