<i18n src="./WUidField-i18n.yaml"></i18n>
<template>
    <div>
        <base-text-field
            v-if="country === 'CH'"
            v-model="model"
            v-mask="computedMask"
            :prefix="computedPrefix"
            :clearable="!readonly"
            v-on:="$listeners"
            v-bind="$props"
            v-disabled-icon-focus
            :label="$t('label')"
            :placeholder="$t('placeholder-country-ch', {example: numberToUid(123456789)})"
        ></base-text-field>
        <base-text-field
            v-else
            v-model="model"
            :prefix="computedPrefix"
            :clearable="!readonly"
            :label="$t('label')"
            :placeholder="$t('placeholder-country-row')"
            v-on:="$listeners"
            v-bind="$props"
            v-disabled-icon-focus
        ></base-text-field>
    </div>
</template>

<script>
import { VueMaskFilter } from "v-mask";
export default {
    name: "WUidField",
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        prefix: {
            type: String,
            default: "CHE"
        },
        mask: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        country: {
            type: String,
            default: null
        }
    },
    data: () => ({
        model: null
    }),
    computed: {
        computedPrefix() {
            if (this.country === "CH") {
                return this.prefix ? `${this.prefix}-` : null;
            } else {
                return null;
            }
        },
        computedMask() {
            if (this.mask) return this.mask;
            if (this.country === "CH") {
                return "###.###.###";
            }
            return null;
        }
    },
    methods: {
        getOutputValue(value) {
            if (this.country === "CH") {
                return {
                    uid: value ? this.uidToNumber(value) : null,
                    uidFormatted:
                        value && this.prefix
                            ? `${this.prefix}-${this.numberToUid(value)}`
                            : null,
                    uidCategory: this.prefix ? this.prefix : null
                };
            } else {
                return {
                    uid: value,
                    uidFormatted: value,
                    uidCategory: null
                };
            }
        },
        uidToNumber(value) {
            let uid = value.split(".").join("");
            let formattedUid = Number(uid);
            return formattedUid;
        },
        numberToUid(value) {
            return VueMaskFilter(value, this.computedMask);
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                if (newValue) {
                    if (this.country === "CH") {
                        this.model = this.numberToUid(newValue);
                    } else {
                        this.model = newValue;
                    }
                }
            },
            immediate: true
        },
        model: {
            handler: function (newValue) {
                this.$emit("change", this.getOutputValue(newValue));
            }
        }
    }
};
</script>
