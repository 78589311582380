<script>
// Utilities
import marked from "marked";
import _ from "lodash";

marked.setOptions({
    headerIds: false
});

export default {
    name: "Markdown",
    props: {
        code: {
            type: [Array, String, Object],
            default: ""
        },
        source: {
            type: String,
            default: ""
        },
        tag: {
            type: String,
            default: "div"
        }
    },
    data() {
        return {
            timeout: null
        };
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {
        clearTimeout(this.timeout);
    },
    methods: {
        init() {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(
                _.bind(() => {
                    const links = this.$el.querySelectorAll(
                        'a.v-markdown--link[href^="#"]'
                    );

                    Array.prototype.forEach.call(links, (el) => {
                        el.addEventListener("click", this.onLinkClick);
                    });
                }, this),
                300
            );
        },
        onLinkClick(e) {
            e.preventDefault();
            this.$router.push(e.target.getAttribute("href"));
        }
    },
    render(h) {
        let code = this.code || this.source;

        if (!this.code) {
            if ((this.$slots.default || []).length > 0) {
                code = this.$slots.default[0].text.trim();
            }

            if (code.indexOf(".") > -1) {
                code = this.$t(code);
            }
        }

        let wantsList = false;
        let type = "o";
        if (_.isObject(code)) {
            wantsList = "o" in code || "u" in code ? true : false;
            type = "o" in code ? "o" : "u";
        }

        if (wantsList)
            code = code[type]
                .map(
                    _.bind((c, i) => {
                        let idx = String(i + 1) + ".";
                        let l = type === "o" ? idx : "-";
                        return `${l} ${c}\n`;
                    }, type)
                )
                .join("");

        if (typeof code !== "string") {
            console.log(`Invalid type ${typeof code}, expected string`, code);
            code = "";
        }

        const innerHTML = marked(code);

        return h(this.tag, {
            staticClass: "v-markdown",
            class: {
                "mb-6": wantsList
            },
            domProps: {
                innerHTML
            }
        });
    }
};
</script>
<style src="./markdown.styl" lang="stylus" scoped></style>
