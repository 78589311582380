import { CompanyAddress } from '@/models/tasks';
import _ from 'lodash';
import colors from "vuetify/es5/util/colors";
import { defaultLightColors } from "@/utils/colors";
import {
    ADDRESS_TYPES,
    TaskDialogStatus,
    AVAILABLE_LOCATION_SEARCH_COUNTRIES
} from "@/data/globals.js";

const getStepAndMsgIndexOrNull = (reviewVerification, msgId) => {
    let ret_step = null;
    let ret_msg_idx = null;
    Object.keys(reviewVerification).forEach((step) => {
        if (!('dialog' in reviewVerification[step])) {
            return;
        }
        const dialog = reviewVerification[step].dialog;
        if (!('messages' in dialog)) {
            return;
        }
        const messages = reviewVerification[step].dialog.messages;
        const msg_idx = messages.findIndex(msg => msg.id === msgId);
        if (msg_idx != -1) {
            ret_step = step;
            ret_msg_idx = msg_idx;
        }
    });
    if (ret_step != null && ret_msg_idx != null) {
        return [ret_step, ret_msg_idx];
    }
    return null;
};

const getDialogAndMsgIndexOrNull = (dialogs, messageId) => {
    let dialogIdx = -1;
    let msgIdx = -1;

    dialogs.forEach((dialog, idxDialog) => {
        if (dialog.messages && dialog.messages.length > 0) {
            dialog.messages.forEach((msg, idxMsg) => {
                if (msg.id === messageId) {
                    dialogIdx = idxDialog
                    msgIdx = idxMsg
                }
            });
        }
    });
    if (dialogIdx !== -1 && msgIdx !== -1) {
        return [dialogIdx, msgIdx];
    }
    return null;
};

//returns on:
//- pos0: totalAttachments
//- pos1: photosCount
//- pos2: documentsCount
const getAttachmentsCounters = (attachments) => {
    let attachmentsCount = 0;
    let photosCount = 0;
    let documentsCount = 0;
    if (Object.keys(attachments).length > 0) {
        for(let index in attachments) {
            let attach = attachments[index];
            let all = [];
            if ('photos' in attach) {
                photosCount += attach['photos'].length;
                attach['photos'].forEach((photo) => {
                    all.push(photo)
                });
            }
            if ('documents' in attach) {
                documentsCount += attach['documents'].length;
                attach['documents'].forEach((doc) => {
                    all.push(doc)
                });
            }
            attachmentsCount += all.length
        }
    }
    return [attachmentsCount, photosCount, documentsCount];
};

const getAttachmentsCountersByCategory = (attachments) => {
    let ret = {};
    if (Object.keys(attachments).length > 0) {
        for(let category in attachments) {
            let photosCount = 0;
            let documentsCount = 0;
            ret[category] = {};
            let attach = attachments[category];
            let all = [];
            if ('photos' in attach) {
                photosCount += attach['photos'].length;
                attach['photos'].forEach((photo) => {
                    all.push(photo)
                });
            }
            ret[category]['photos'] = photosCount;
            if ('documents' in attach) {
                documentsCount += attach['documents'].length;
                attach['documents'].forEach((doc) => {
                    all.push(doc)
                });
            }
            ret[category]['documents'] = documentsCount;
        }
    }
    return ret;
};

const getGoogleMapsLinkByAddress = (address) => {
    let link = {};
    link.url = "https://www.google.com/maps/search/?api=1&query=";
    link.id = "googleMaps";
    let item = {};
    item.street_address_1 = address.street;
    item.street_address_2 = address.houseNr;
    item.city = address.city;
    item.postal_code = address.zipCode;
    const urlSuffix = item["latitude_longitude"]
    ? item["latitude_longitude"]
    : item["street_address_1"] +
          ", " +
          item["street_address_2"] +
          ", " +
          item["city"] +
          ", " +
          item["postal_code"];

    link.url = link.url + urlSuffix;
    return link;
};

const isActivelyHeated = (registration) => {
    let ret = false;
    if (!registration) return ret;
    const overheatingVulnerable = registration.overheatingVulnerable;
    const coolingMaterialPropertiesChange = registration.coolingMaterialPropertiesChange;
    if (overheatingVulnerable == true && coolingMaterialPropertiesChange == true) {
        ret = true;
    }
    return ret;
};

const getFormattedEquipmentLocationAddressOrNull = (equipmentLocation) => {
    if (!equipmentLocation) return null;
    const address = Object.assign(
        new CompanyAddress(),
        equipmentLocation.address,
        equipmentLocation.contactPerson)
    if (checkIfAddressIsEmpty(address)) return null;
    return address;
};

const checkIfAddressIsEmpty = (address) => {
    if (!address) return true;
    if (!address.addressType) return true;
    if (address.addressType === ADDRESS_TYPES.COMPANY && !address.companyName) return true;
    if (address.addressType === ADDRESS_TYPES.PRIVATE && !address.firstName) return true;
    if (address.addressType !== ADDRESS_TYPES.PRIVATE || address.addressType !== ADDRESS_TYPES.COMPANY) return null;
    return false
};

const diffsOf2Objects = (objA, objB, ignoreNullEmptyCheck=false, ignoreKeys=[]) => {
    // compare 2 dicts and return the diff
    let result = {}
    let keys = _.keys(objA);
    _.forEach(objB, ((value, key) => {
        if (!(key in keys)){
            keys.push(key)
        }
    }));
    _.forEach(keys, ((key) => {
        if (ignoreKeys.includes(key)) return;
        let ignoreKeysSub = []
        _.forEach(ignoreKeys, ((ignoreKey) => {
            if (ignoreKey.startsWith(key + '.')){
                ignoreKeysSub.push(ignoreKey[key.length + 1])
            }
        }));
        if (!(key in objA)){
            // Key does not exists in A. This means it has been added.
            result[key] = [null, objB[key]]
            return;
        }
        if (!(key in objB)) {
            //  Key does not exists in B. This means it has been removed.
            result[key] = [objA[key], null]
            return;
        }
        let valueA = objA[key]
        let valueB = objB[key]
        if (typeof(valueA) != typeof(valueB)) {
            // Types are differnt
            if ((valueA instanceof String) && (valueB instanceof String)) {
                // Compare unicode with string.
                if (valueA === valueB) {
                    return;
                }
                if (ignoreNullEmptyCheck){
                    result[key] = [valueA, valueB]
                }
            }
            if (!ignoreNullEmptyCheck){
                result[key] = [valueA, valueB]
            }
        }
        else if (valueA instanceof Object){
            let tmp = diffsOf2Objects(valueA, valueB, false, ignoreKeysSub)

            if (tmp instanceof Object){
                if (Object.keys(tmp).length === 0) {
                    return
                }
            }
            // There are some differences.
            return result[key] = tmp
        }
        else if (valueA instanceof Array) {
            let listObjA = _.extend({}, _.map(valueA));
            let listObjB = _.extend({}, _.map(valueB));
            let tmp = diffsOf2Objects(listObjA, listObjB, false,ignoreKeysSub)
            if (tmp){
                result[key] = tmp
            }
        }
        else {
            if (valueA != valueB){
                // Key exists in B, but has a different value.
                result[key] = [valueA, valueB]
            }
        }
    }));
    return result
};

const getColorByKey = (key, colorization = "base") => {
    if (key) {
        if (colors[key]) {
            if (colors[key][colorization]) {
                return colors[key][colorization];
            } else if (colors[key].base) {
                return colors[key].base;
            }
        }
        if (defaultLightColors[key]){
            return defaultLightColors[key]
        }
    }
    return "#E91E63";
};

const downloadAttachmentsAsZipByResponse = (response) => {
    if (!response?.data) return;
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = null;
    if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
        if (fileNameMatch.length === 3) fileName = fileNameMatch[1];
    }
    if (!fileName) return;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    return true
};
const checkIfVerificationStepHasAnOpenDialog = (reviewVerification) => {
    if (!('dialog' in reviewVerification)) {
        return false;
    }
    if (!('messages' in reviewVerification.dialog)) {
        return false;
    }
    if (reviewVerification?.dialog?.messages?.length === 0) {
        return false;
    }
    if (reviewVerification?.dialog?.state === TaskDialogStatus.TASK_DIALOG_CONFIRMED) {
        return false;
    }
    return true
};

const assignObjOnlyIfKeysExists = (objA, objB) => {
    return _.assign(objA, _.pick(objB, _.keys(objA)));
};

const isValidAddressCompany = (address) => {
    return (
        address?.addressType === ADDRESS_TYPES.COMPANY &&
        address?.companyName
    );
};

const isValidAddressPrivateCompany = (address) => {
    return (
        address?.addressType === ADDRESS_TYPES.PRIVATE &&
        address?.salutation &&
        address?.firstName &&
        address?.familyName
    );
};

const isValidAddress = (address) => {
    if (!address) return false;
    return address.street !== null && address.zipCode !== null && address.city !== null
};

const isAddressRecorded = (address) => {
    if (address && address instanceof Object) {
        if (
            (isValidAddressCompany(address) ||
                isValidAddressPrivateCompany(address)) &&
            isValidAddress(address)
        ) {
            return true;
        }
    }
    return false;
};

const isComminicationFieldEmpty = (data) => {
        let empty = false;
        if (!data) return;
        const faxNr = data.fax || null;
        const phoneNr = data.phone || null;
        const email = data.email || null;
        if (
            !email &&
            (phoneNr === "" || !phoneNr) &&
            (faxNr === "" || !faxNr)
        ) {
            empty = true;
        }
      return empty;
};

const isMobileBreakpoint = (me) => {
        return me.$vuetify?.breakpoint?.mobile;
};

const isLocationSearchAvailable = (country) => {
    return AVAILABLE_LOCATION_SEARCH_COUNTRIES.includes(country);
};

export default {
    getStepAndMsgIndexOrNull,
    getDialogAndMsgIndexOrNull,
    getAttachmentsCounters,
    getAttachmentsCountersByCategory,
    getGoogleMapsLinkByAddress,
    isActivelyHeated,
    getFormattedEquipmentLocationAddressOrNull,
    checkIfAddressIsEmpty,
    diffsOf2Objects,
    getColorByKey,
    downloadAttachmentsAsZipByResponse,
    checkIfVerificationStepHasAnOpenDialog,
    assignObjOnlyIfKeysExists,
    isValidAddressCompany,
    isValidAddressPrivateCompany,
    isValidAddress,
    isAddressRecorded,
    isComminicationFieldEmpty,
    isMobileBreakpoint,
    isLocationSearchAvailable
};
