import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib"; // import componentes for global snackbar
import VuetifyToast from "vuetify-toast-snackbar";
import { defaultLightColors, defaultDarkColors } from "@/utils/colors";

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});

//import locales
import de from "vuetify/es5/locale/de";
import fr from "vuetify/es5/locale/fr";
import it from "vuetify/es5/locale/it";
import en from "vuetify/es5/locale/en";

const opts = {
    lang: {
        locales: { de, fr, it, en },
        current: "de"
    },
    icons: {
        iconfont: "mdi" // default - only for display purposes
    },
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: defaultDarkColors.primary,
                secondary: defaultDarkColors.secondary,
                backgroundOutter: defaultDarkColors.backgroundOutter,
                backgroundInner: defaultDarkColors.backgroundInner,
                accent: defaultDarkColors.accent,
                success: defaultDarkColors.success,
                info: defaultDarkColors.info,
                warning: defaultDarkColors.warning,
                error: defaultDarkColors.error,
                regOfficePrimary: defaultDarkColors.primary,
                regOfficeSecondary: defaultDarkColors.secondary,
                regOfficeLink: defaultDarkColors.primary,
                regOfficeHeader: defaultDarkColors.secondary,
                regOfficeSubheader: defaultDarkColors.secondary,
                regOfficeTitle: defaultDarkColors.primary,
                regOfficeBtnPrimary: defaultDarkColors.primary,
                regOfficeBtnSecondary: defaultDarkColors.secondary
            },
            light: {
                primary: defaultLightColors.primary,
                secondary: defaultLightColors.secondary,
                backgroundOutter: defaultLightColors.backgroundOutter,
                backgroundInner: defaultLightColors.backgroundInner,
                accent: defaultLightColors.accent,
                success: defaultLightColors.success,
                info: defaultLightColors.info,
                warning: defaultLightColors.warning,
                error: defaultLightColors.error,
                regOfficePrimary: defaultLightColors.primary,
                regOfficeSecondary: defaultLightColors.secondary,
                regOfficeLink: defaultDarkColors.secondary,
                regOfficeHeader: defaultDarkColors.secondary,
                regOfficeSubheader: defaultDarkColors.secondary,
                regOfficeTitle: defaultDarkColors.primary,
                regOfficeBtnPrimary: defaultDarkColors.secondary,
                regOfficeBtnSecondary: defaultDarkColors.primary
            }
        }
    },
    customVariables: ["~/styles/variables.scss"],
    treeShake: true,
    options: {
        customProperties: true
    },
    breakpoint: {
        mobileBreakpoint: 1024
    }
};

const vueObj = new Vuetify(opts);

Vue.use(VuetifyToast, {
    x: "center",
    y: "top",
    color: "mdi-information", // default
    icon: "mdi-information",
    iconColor: "", // default
    classes: ["body-2"],
    timeout: 3000, // default
    dismissable: true, // default
    multiLine: false, // default
    vertical: false, // default
    queueable: false, // default
    showClose: true, // default
    closeText: "", // default
    closeIcon: "mdi-close", // default
    closeColor: "", // default
    slot: [], //default
    property: "$toast", // default
    $vuetify: vueObj.framework
});

export default vueObj;
