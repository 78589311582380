var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":"","ma-0":""},on:{"click":function($event){return _vm.$emit('click:container')}}},[_c('v-card',{staticClass:"pa-2",attrs:{"flat":"","color":"transparent"}},[(
                (_vm.attachments && Object.keys(_vm.attachments).length == 0) ||
                !_vm.attachments
            )?_c('v-container',{attrs:{"fluid":"","fill-height":""}},[(!_vm.readonlyModus)?_c('base-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("into-text"))+" ")]):_vm._e(),_c('v-layout',{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c('v-flex',{attrs:{"row":"","align-center":""}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 60 : 100}},[_vm._v(" mdi-image-search ")])],1),_c('v-flex',{staticClass:"pt-5",attrs:{"row":"","align-center":""}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-h6'
                                : 'text-h4'},[_vm._v(" "+_vm._s(_vm.$t("no-attachments-title"))+" ")])]),_c('v-flex',{staticClass:"pt-5",attrs:{"row":"","align-center":""}},[(!_vm.readonlyModus)?_c('div',{staticClass:"text-h6",class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'text-body-1'
                                : 'text-h4'},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("attachments-max-size-mobile", { maxFileSize: _vm.maxFileSize }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("attachments-max-size", { maxFileSize: _vm.maxFileSize }))+" ")])]):_vm._e()]),_c('v-flex',{staticClass:"mt-6",attrs:{"row":"","align-center":""}},[(!_vm.readonlyModus)?_c('BtnUploadFiles',{attrs:{"disabled":_vm.readonlyModus,"categories":_vm.categories},on:{"click-item":_vm.onCategoryItemClick}}):_vm._e()],1)],1)],1):_c('div',[_c('v-row',[_c('v-col',[(!_vm.readonlyModus)?_c('BtnUploadFiles',{attrs:{"disabled":_vm.readonlyModus,"categories":_vm.categories},on:{"click-item":_vm.onCategoryItemClick}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"align":"end"}},[_c('base-btn',{attrs:{"color":"primary"},on:{"click":_vm.getAllAttachments}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Neu laden")],1)],1)],1),(_vm.attachments)?_c('multimedia-viewer',{attrs:{"deletable":!_vm.readonlyModus,"attachments":_vm.attachments,"thumbnailWidth":"100px","thumbnailMaxWidth":"100px","thumbnailHeight":"100px","docId":_vm.taskDocId},on:{"attachment-deleted":_vm.deleteAttachment,"delete-multiple-attachments":_vm.deleteMultipleAttachment}}):_vm._e()],1),(_vm.fileCategory)?_c('modal-file-uploader',{attrs:{"value":_vm.showModalFileUploader,"docId":_vm.taskDocId,"fileCategory":_vm.fileCategory,"maxFileSize":_vm.maxFileSize},on:{"input":function($event){_vm.showModalFileUploader = $event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }