<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./addresses-i18n.yaml"></i18n>
<template>
    <v-card flat>
        <BaseSubheader class="px-4">
            {{ $t("title-my-addresses") }}
        </BaseSubheader>
        <v-list two-line dense class="py-0">
            <v-list-item>
                <v-list-item-content>
                    <SettingsTitle class="pa-0">
                        <template v-slot:title>
                            <v-icon>mdi-account-hard-hat</v-icon>
                            {{ $t("title-operator") }}
                        </template>
                        <v-btn
                            v-if="!isAddressRecorded(addressOperator)"
                            color="info"
                            class="ml-2"
                            icon
                            @click="onAddAddress(addressOperator)"
                            ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                        <AddressesMenu
                            v-if="isAddressRecorded(addressOperator)"
                            @click:edit="onEditAddress(addressOperator)"
                            @click:delete="onDeleteAddress(addressOperator)"
                        />
                    </SettingsTitle>
                    <DisplayAddressInline
                        v-if="isAddressRecorded(addressOperator)"
                        :address="addressOperator"
                    />
                    <app-text-not-recorded v-else />
                </v-list-item-content>
            </v-list-item>
            <v-divider class="pb-2" />
            <v-list-item>
                <v-list-item-content>
                    <SettingsTitle class="pa-0">
                        <template v-slot:title>
                            <v-icon>mdi-currency-usd-circle</v-icon>
                            {{ $t("title-bill") }}
                        </template>
                        <v-btn
                            v-if="!isAddressRecorded(addressBill)"
                            color="info"
                            class="ml-2"
                            icon
                            @click="onAddAddress(addressBill)"
                            ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                        <AddressesMenu
                            v-if="isAddressRecorded(addressBill)"
                            @click:edit="onEditAddress(addressBill)"
                            @click:delete="onDeleteAddress(addressBill)"
                        />
                    </SettingsTitle>
                    <DisplayAddressInline
                        v-if="isAddressRecorded(addressBill)"
                        :address="addressBill"
                    />
                    <app-text-not-recorded v-else />
                </v-list-item-content>
            </v-list-item>
            <v-divider class="pb-2" />
            <v-list-item>
                <v-list-item-content>
                    <SettingsTitle class="pa-0">
                        <template v-slot:title>
                            <v-icon>mdi-home-map-marker</v-icon>
                            {{ $t("title-owner") }}
                        </template>
                        <v-btn
                            v-if="!isAddressRecorded(addressOwner)"
                            color="info"
                            class="ml-2"
                            icon
                            @click="onAddAddress(addressOwner)"
                            ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                        <AddressesMenu
                            v-if="isAddressRecorded(addressOwner)"
                            @click:edit="onEditAddress(addressOwner)"
                            @click:delete="onDeleteAddress(addressOwner)"
                        />
                    </SettingsTitle>
                    <DisplayAddressInline
                        v-if="isAddressRecorded(addressOwner)"
                        :address="addressOwner"
                    />
                    <app-text-not-recorded v-else />
                </v-list-item-content>
            </v-list-item>
            <v-divider class="pb-2" />
        </v-list>
        <ExpandableCard class="pa-0 ma-0 py-10" expand>
            <template v-slot:title>
                <BaseSubheader>
                    {{ $t("title-saved-addresses") }}
                    <FaqOpenBtn searchId="addressBook" />
                </BaseSubheader>
            </template>
            <template v-slot:content>
                <app-dev-container>
                    <template v-slot:content>
                        @Data:
                        <tree-view
                            :data="computedAddresses"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                    </template>
                </app-dev-container>
                <v-container fluid class="pa-0 ma-0">
                    <v-card-title class="px-1">
                        <v-spacer></v-spacer>
                        <base-btn type="add" @click="onAddAddress({})">{{
                            $t("btn-add")
                        }}</base-btn>
                    </v-card-title>
                    <v-list two-line dense class="px-0">
                        <template v-for="(address, index) in computedAddresses">
                            <v-list-item :key="address.id" class="px-1">
                                <v-list-item-content>
                                    <DisplayAddressInline :address="address" />
                                </v-list-item-content>
                                <v-list-item-action>
                                    <AddressesMenu
                                        v-if="isAddressRecorded(address)"
                                        @click:edit="onEditAddress(address)"
                                        @click:delete="onDeleteAddress(address)"
                                    />
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                                v-if="index < computedAddresses.length - 1"
                                :key="address.id + index"
                            ></v-divider>
                        </template>
                    </v-list>
                </v-container>
            </template>
        </ExpandableCard>
        <ModalAddressForm
            v-model="modalAddressForm"
            :title="modalAddressFormTitle"
            :address="addressToEdit"
            @save="onSaveAddress"
        ></ModalAddressForm>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { ADDRESS_TYPES, ADDRESS_IDS } from "@/data/globals.js";
export default {
    name: "ListAddresses",
    components: {
        SettingsTitle: () =>
            import("@/components/user-dashboard/settings-title.vue"),
        DisplayAddressInline: () =>
            import("@/components/general/display-address-inline.vue"),
        ModalAddressForm: () =>
            import("@/components/general/modal-address-form.vue"),
        ExpandableCard: () =>
            import("@/components/general/cards/expandable-card.vue"),
        AddressesMenu: () =>
            import("@/components/user-dashboard/addresses-menu.vue"),
        FaqOpenBtn: () => import("@/components/general/faq/faq-open-btn.vue")
    },
    data: () => ({
        selected: [2],
        modalAddressForm: false,
        addressToEdit: {},
        modalAddressFormTitle: null
    }),
    computed: {
        ...mapState("auth", ["addresses"]),
        ...mapGetters("auth", [
            "addressOperator",
            "addressBill",
            "addressOwner"
        ]),
        computedAddresses() {
            const filterIds = [
                ADDRESS_IDS.OPERATOR,
                ADDRESS_IDS.BILL,
                ADDRESS_IDS.OWNER
            ];
            return this.addresses
                .slice(0)
                .filter((address) => !filterIds.includes(address.id));
        }
    },
    methods: {
        onClearAddressToEdit() {
            this.addressToEdit = {};
        },
        onAddAddress(address) {
            this.onClearAddressToEdit();
            const addressId = address.id;
            let addressTypeId =
                this._getAddressTypeIdByAdressIdTranslated(addressId);
            this.modalAddressFormTitle = this.$t("title-address-form-new", {
                addressTypeId: addressTypeId
            });
            this.addressToEdit = Object.assign({}, address);
            this.modalAddressForm = true;
        },
        onEditAddress(address) {
            const addressId = address.id;
            let addressTypeId =
                this._getAddressTypeIdByAdressIdTranslated(addressId);
            this.modalAddressFormTitle = this.$t("title-address-form-edit", {
                addressTypeId: addressTypeId
            });
            this.addressToEdit = Object.assign({}, address);
            this.modalAddressForm = true;
        },
        _getAddressTypeIdByAdressIdTranslated(addressId) {
            let addressTypeId = null;
            switch (addressId) {
                case ADDRESS_IDS.OPERATOR:
                    addressTypeId = this.$t("title-operator");
                    break;
                case ADDRESS_IDS.BILL:
                    addressTypeId = this.$t("title-bill");
                    break;
                case ADDRESS_IDS.OWNER:
                    addressTypeId = this.$t("title-owner");
                    break;
                default:
                    addressTypeId = this.$t("title-address");
                    break;
            }
            return addressTypeId;
        },
        onDeleteAddress(address) {
            const addressType = address.addressType;
            const addressId = address.id;
            let addressTypeId =
                this._getAddressTypeIdByAdressIdTranslated(addressId);
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-address-title", {
                        addressTypeId: addressTypeId
                    }),
                    icon: "mdi-alert"
                },
                message:
                    addressType === ADDRESS_TYPES.COMPANY
                        ? this.$t("msg-box-delete-address-text-company", {
                              companyName: address.companyName
                          })
                        : this.$t("msg-box-delete-address-text-private", {
                              firstName: address.firstName,
                              familyName: address.familyName
                          })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.loader().show();
                    this.$store
                        .dispatch("auth/deleteAddressById", addressId)
                        .then(() => {
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.loader().hide();
                        });
                }
            });
        },
        onSaveAddress(address) {
            this.loader().show();
            this.$store
                .dispatch("auth/addOrUpdateAddress", address)
                .then(() => {
                    this.modalAddressForm = false;
                    this.onClearAddressToEdit();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        fetchAddresses() {
            this.loader().show();
            this.$store
                .dispatch("auth/fetchAllAddresses")
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        isAddressRecorded(address) {
            return address.id && address.addressType;
        }
    },
    mounted() {
        this.fetchAddresses();
    }
};
</script>
