<template>
  <SectionBase
    id="devel-suva-api"
    title="Suva API: PartnerFinder"
    subtitle="Test Suva API PartnerFinder"
  >
    <v-row align="center">
      <v-col cols="12">
        <base-select
          v-model="partnerNumberSearchUid"
          :items="sampleUidItems"
          item-text="text"
          item-value="value"
          label="Firma"
          persistent-hint
          single-line
        >
          <template v-slot:selection="{ item }">
            <v-icon left v-if="item.status === 200" color="success"
              >mdi-check-circle</v-icon
            >
            <v-icon left v-if="item.status === 400" color="error"
              >mdi-close-circle</v-icon
            >
            <v-icon left v-if="item.status === 404" color="info"
              >mdi-magnify-close</v-icon
            >
            {{ item.text }}
          </template>
          <template v-slot:item="{ item }">
            <v-icon left v-if="item.status === 200" color="success"
              >mdi-check-circle</v-icon
            >
            <v-icon left v-if="item.status === 400" color="error"
              >mdi-close-circle</v-icon
            >
            <v-icon left v-if="item.status === 404" color="info"
              >mdi-magnify-close</v-icon
            >
            {{ item.text }}
          </template>
        </base-select>
      </v-col>
      <v-col cols="6">
        <span>
          <v-icon left color="success">mdi-check-circle</v-icon>
          Found (200)
        </span>
        <span>
          <v-icon left color="error">mdi-close-circle</v-icon>
          Error (400)
        </span>
        <span>
          <v-icon left color="info">mdi-magnify-close</v-icon>
          Not Found (404)
        </span>
      </v-col>
      <v-col cols="6">
        <base-btn color="info" @click="setRandomUid">
          <v-icon left>mdi-dice-multiple</v-icon> setRandomUid</base-btn
        >
      </v-col>
    </v-row>
    <hr class="my-10" />
    <v-row align="center">
      <v-col cols="12">
        <SuvaPartnerFinder
          :uid="partnerNumberSearchUid"
          :suvaPartnerFinder="partnerNumberSearchUidResult"
          @change="partnerNumberSearchUidResult = $event"
        >
        </SuvaPartnerFinder>
      </v-col>
    </v-row>
  </SectionBase>
</template>

<script>
export default {
  name: "SectionSuvaApi",
  components: {
    SectionBase: () => import("./base.vue"),
    SuvaPartnerFinder: () =>
      import("@/components/tasks/common/suva-partner-finder.vue"),
  },
  data: () => ({
    partnerNumberSearchUid: "473810356",
    partnerNumberSearchUidResult: null,
    sampleUidItems: [
      {
        text: "Si - Wa S. Walter",
        value: "473810356",
        status: 200,
      },
      {
        text: "Was.ch",
        value: "101813302",
        status: 404,
      },
      {
        text: "SVTI Services AG",
        value: "101949232",
        status: 200,
      },
      {
        text: "SVTI Schweizerischer Verein für technische Inspektionen",
        value: "107825203",
        status: 400,
      },
      {
        text:
          "Patronale Stiftung des SVTI Schweizerischer Verein für technische Inspektionen",
        value: "109740954",
        status: 404,
      },
      {
        text:
          "Pensionskasse des SVTI, Schweizerischer Verein für technische Inspektionen",
        value: "109740724",
        status: 404,
      },
      {
        text: "Dedola Plattenbeläge Giovanni Dedola",
        value: "441061567",
        status: 200,
      },
      {
        text: "Restaurant Krone, R. + G. Baumann & Co.",
        value: "107089378",
        status: 404,
      },
    ],
  }),
  computed: {
    sampleUidItemsList() {
      return Object.keys(this.sampleUidItems).map(
        (key) => this.sampleUidItems[key].value
      );
    },
  },
  methods: {
    setRandomUid() {
      this.partnerNumberSearchUid = this.sampleUidItemsList[
        Math.floor(Math.random() * this.sampleUidItemsList.length)
      ];
    },
  },
};
</script>

<style>
</style>
