<i18n src="./summary-equipment-location-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-equipment-location')" :expandable="expandable">
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li v-if="formattedEquipmentLocationAddress">
                    <DisplayAddressInline
                        :address="formattedEquipmentLocationAddress"
                    >
                    </DisplayAddressInline>
                </li>
                <li v-else>
                    <span class="font-italic">{{
                        $t("not-recorded-yet")
                    }}</span>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import Helpers from "@/utils/helpers";
import mixin from "../mixin";
export default {
    name: "SummaryEquipmentLocation",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue"),
        DisplayAddressInline: () =>
            import("@/components/general/display-address-inline.vue")
    },
    computed: {
        formattedEquipmentLocationAddress() {
            return Helpers.getFormattedEquipmentLocationAddressOrNull(
                this.task.equipmentLocation
            );
        }
    }
};
</script>
