<template>
    <v-card
        :outlined="outlined"
        :rounded="rounded"
        flat
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </v-card>
</template>
<script>
export default {
    name: "BaseCard",
    props: {
        outlined: {
            type: Boolean,
            default: true
        },
        rounded: {
            type: Boolean,
            default: true
        }
    }
};
</script>
