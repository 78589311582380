<i18n       src="./modal-movable-dialog-i18n.yaml"></i18n>
<template>
    <v-dialog
        v-model="dialog"
        ref="dialog"
        :width="dialogWidth"
        persistent
        :max-width="maxWidth"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        scrollable
        transition="dialog-bottom-transition"
        :content-class="fullheight ? 'w-fullscreen' : undefined"
        :hide-overlay="drag"
        overlay-opacity="0.5"
    >
        <v-card
            flat
            class="mx-auto"
            v-on:mousedown="onMouseDown"
            v-on:mouseup="onMouseUp"
            v-on:mousemove="onMouseMove"
            ref="card"
        >
            <v-toolbar
                :color="$vuetify.theme.isDark ? '' : 'cardHeaderGrey'"
                class="regOfficePrimary--text"
                :class="cardTitleCls"
                max-height="65"
                :style="drag ? { cursor: 'move' } : { cursor: 'default' }"
                flat
                ref="toolbar"
            >
                <v-icon v-html="toolbarIcon" color="regOfficePrimary"></v-icon>
                <v-toolbar-title
                    class="pl-2"
                    v-html="toolbarTitle"
                ></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                        v-if="buttonPin && !$vuetify.breakpoint.xsOnly"
                        @click="setDrag"
                        icon
                        color="regOfficePrimary"
                    >
                        <v-icon
                            v-html="drag ? 'mdi-pin' : 'mdi-pin-off'"
                        ></v-icon>
                    </v-btn>
                    <v-btn icon @click="closeDialog" color="regOfficePrimary">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text
                ref="cardText"
                :class="clsCardContent"
                height="100%"
                class="pa-1 pt-0 mt-0"
            >
                <slot name="content"> </slot>
            </v-card-text>
            <v-card-actions
                ref="cardActions"
                :class="clsCardActions"
                class="pb-6"
            >
                <slot name="actions"> </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "ModalMovableDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        toolbarTitle: {
            type: String,
            default: null
        },
        toolbarIcon: {
            type: String,
            default: null
        },
        clsCardContent: {
            type: String,
            default: "pa-2"
        },
        clsCardActions: {
            type: String,
            default: null
        },
        buttonPin: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: null
        },
        maxWidth: {
            type: String,
            default: "800"
        },
        fullheight: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        dialog: false,
        d: {},
        dragEnabled: true,
        drag: false
    }),

    computed: {
        dialogWidth() {
            if (this.width && this.width) {
                return this.width;
            }
            return this.$vuetify.breakpoint.width / 2 > 500
                ? this.$vuetify.breakpoint.width / 2
                : 500;
        },
        cardTitleCls() {
            let cls = "";
            if (this.$vuetify.theme.isDark) cls += " cardHeaderGrey";
            if (this.fullheight) cls += " w-toolbar";
            return cls;
        }
    },

    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        onMouseDown(e) {
            if (this.drag) e.preventDefault();
            this.d = {};
            const closestDialog = e.target.closest(
                ".v-dialog.v-dialog--active"
            );
            if (
                e.button === 0 &&
                closestDialog != null &&
                e.target.classList.contains("v-toolbar__content")
            ) {
                // element which can be used to move element
                this.d.el = closestDialog; // element which should be moved
                this.d.mouseStartX = e.clientX;
                this.d.mouseStartY = e.clientY;
                this.d.elStartX = this.d.el.getBoundingClientRect().left;
                this.d.elStartY = this.d.el.getBoundingClientRect().top;
                this.d.el.style.position = "fixed";
                this.d.el.style.margin = 0;
                this.d.oldTransition = this.d.el.style.transition;
                this.d.el.style.transition = "none";
            }
        },
        onMouseUp() {
            if (this.d.el === undefined) return;
            this.d.el.style.transition = this.d.oldTransition;
            this.d.el = undefined;
        },
        onMouseMove(e) {
            if (this.d.el === undefined) return;
            if (this.drag) {
                this.d.el.style.left =
                    Math.min(
                        Math.max(
                            this.d.elStartX + e.clientX - this.d.mouseStartX,
                            0
                        ),
                        window.innerWidth -
                            this.d.el.getBoundingClientRect().width
                    ) + "px";
                this.d.el.style.top =
                    Math.min(
                        Math.max(
                            this.d.elStartY + e.clientY - this.d.mouseStartY,
                            0
                        ),
                        window.innerHeight -
                            this.d.el.getBoundingClientRect().height
                    ) + "px";
            }
        },
        moveDialog() {
            // make vuetify dialogs movable
            setInterval(() => {
                if (this.drag && this.dragEnabled) {
                    // prevent out of bounds
                    const dialog = document.querySelector(
                        ".v-dialog.v-dialog--active"
                    );
                    if (dialog === null) return;
                    dialog.style.left =
                        Math.min(
                            parseInt(dialog.style.left),
                            window.innerWidth -
                                dialog.getBoundingClientRect().width
                        ) + "px";
                    dialog.style.top =
                        Math.min(
                            parseInt(dialog.style.top),
                            window.innerHeight -
                                dialog.getBoundingClientRect().height
                        ) + "px";
                }
            }, 100);
        },
        setDrag() {
            if (this.dragEnabled) {
                this.drag = !this.drag;
            }
        },
        mouseOut(event) {
            if (!this.dragEnabled && this.dragging) {
                this.moveDialog(event);
            }
        }
    },

    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
                if (newValue) {
                    this.moveDialog();
                }
            },
            immediate: true
        }
    }
};
</script>
