<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./table-i18n.yaml"></i18n>
<template>
    <div>
        <GlobalTable
            :items="accounts"
            :options.sync="tableOptions"
            :headers="headers"
            :actions="filteredActions"
            :title="$t('title')"
            @click:action="onActionClick"
            :funcShowAction="showAction"
            :funcRowClasses="rowClasses"
            @options:change="
                (options) => {
                    Object.assign(tableOptions, options);
                }
            "
            @input:search="search = $event"
            :search="search"
            @click:reload="fetchAllAccounts"
            :isLoading="isGlobalLoading"
            hideBtnAdd
            :sort-by="['fullName', 'email']"
            :sort-desc="[false, true]"
            multi-sort
        >
            <template v-slot:top>
                <v-row class="pa-2">
                    <v-col cols="12" :md="8" :xl="4">
                        <base-text-field
                            v-if="searchByRole"
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('search-field-label')"
                            single-line
                            hide-details
                        ></base-text-field>
                        <w-account-search
                            v-else
                            v-model="accountSearch"
                            @items:changed="onAccountSearchItemsChanged"
                            :filters="filters"
                        />
                    </v-col>
                    <v-col cols="8" :sm="3" :md="2" :lg="3">
                        <base-btn
                            icon
                            @click="
                                switchAdvancedSearch = !switchAdvancedSearch
                            "
                        >
                            <v-icon
                                v-html="
                                    switchAdvancedSearch
                                        ? 'mdi-filter-off'
                                        : 'mdi-filter-menu'
                                "
                            >
                            </v-icon>
                        </base-btn>
                        <base-btn icon @click="dialog = true">
                            <v-icon
                                v-html="
                                    dialog
                                        ? 'mdi-filter-off'
                                        : 'mdi-filter-plus'
                                "
                            >
                            </v-icon>
                        </base-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col align="end">
                        <base-btn icon class="ml-2" @click="fetchAllAccounts"
                            ><v-icon>mdi-reload</v-icon>
                        </base-btn>
                    </v-col>
                </v-row>
                <v-expand-transition v-if="switchAdvancedSearch">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <base-select
                                    :items="roles"
                                    clearable
                                    v-model="searchByRole"
                                    :label="$t('search-by-role-label')"
                                    @change="onSearchByRoleChange"
                                    :item-text="
                                        (item) =>
                                            $t(`account-role-${item.value}`)
                                    "
                                    single-line
                                >
                                </base-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-expand-transition>
            </template>
            <template v-slot:item.roles="{ item }">
                <div class="pt-3" v-if="item.roles">
                    <template v-for="(role, roleIdx) in item.roles">
                        <v-icon
                            v-if="rolesIcons[role]"
                            :key="roleIdx"
                            :color="rolesIcons[role].color"
                            >{{ rolesIcons[role].icon }}</v-icon
                        >
                    </template>
                </div>
            </template>
            <template v-slot:item.lang="{ item }">
                <div class="pt-3" v-if="item.lang">
                    <country-flag :country="item.lang" />
                </div>
            </template>
        </GlobalTable>
        <GlobalOverlay
            v-if="showLoader"
            :isLoading="isLoading"
            :loadingMsg="loadingMsg"
        />
        <BaseDialog
            v-model="dialogFormUser"
            :title="$t('title-dialog-form-user-account')"
            icon="mdi-pencil"
        >
            <template v-slot:content>
                <v-container grid-list-md text-xs-center>
                    <FormUserAccount :userData="editUser" />
                </v-container>
            </template>
            <template v-slot:actions>
                <v-spacer />
                <base-btn type="save" class="ml-2" @click="onEditAccountSave">
                    {{ $t("btn-edit-account-save") }}
                </base-btn>
            </template>
        </BaseDialog>
        <DialogSearchFilters
            v-if="dialog"
            v-model="dialog"
            :title="$t('title-dialog-filter')"
            max-width="600"
            icon="mdi-filter-plus"
            :filterRoles="rolesFilter"
            @filter-roles:change="(roles) => (rolesFilter = roles)"
        >
        </DialogSearchFilters>
    </div>
</template>
<script>
import GlobalTable from "@/components/general/table.vue";
import { mapState, mapGetters } from "vuex";
import { getIconAndColorByRole } from "@/utils/defaults.js";
import { ACCOUNT_ROLE_LIST } from "@/data/globals.js";
import GlobalOverlay from "@/components/general/global-overlay.vue";
import DialogSearchFilters from "@/components/admin/users/dialog-search-filters.vue";
import FormUserAccount from "@/components/admin/users/form-user-account.vue";
import _ from "lodash";

const ActionTypes = {
    EDIT: "EDIT",
    DISABLE: "DISABLE",
    ENABLE: "ENABLE",
    DELETE: "DELETE",
    VIEW_HISTORY: "VIEW_HISTORY"
};

export default {
    name: "AccountsTable",
    components: {
        GlobalTable,
        GlobalOverlay,
        DialogSearchFilters,
        FormUserAccount
    },
    data: () => ({
        tableOptions: {
            itemsPerPage: 10
        },
        actions: [],
        search: null,
        editedIndex: -1,
        accountSearch: null,
        searchByRole: null,
        isLoading: false,
        loadingMsg: null,
        switchAdvancedSearch: false,
        dialog: false,
        rolesFilter: [],
        editUser: {},
        editUserSnapshot: {},
        dialogFormUser: false
    }),
    methods: {
        showLoader(msg) {
            this.loadingMsg = msg;
            this.isLoading = true;
        },
        hideLoader() {
            this.loadingMsg = null;
            this.isLoading = false;
        },
        onSearchByRoleChange(value) {
            if (!value) {
                this.search = null;
                return this.fetchAllAccounts();
            }
            let params = {};
            params.role = this.searchByRole;
            params.start = this.page;
            params.limit = this.limit;
            this.showLoader(this.$t("loading-msg-start-search"));
            this.$axiosAccountSettings
                .get("/searchByRole", { params })
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        let resData = response.data;
                        let itemsData = resData.data;
                        this.onAccountSearchItemsChanged(itemsData.items);
                    }
                    this.hideLoader();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-occured"));
                    this.hideLoader();
                });
        },
        onActionClick({ action, item }) {
            this.selectedRegistration = item;
            switch (action) {
                case ActionTypes.EDIT:
                    this.onEdit(item);
                    break;
                case ActionTypes.DISABLE:
                    this.onDisable(item);
                    break;
                case ActionTypes.ENABLE:
                    this.onEnable(item);
                    break;
                case ActionTypes.DELETE:
                    this.onDelete(item);
                    break;
                case ActionTypes.VIEW_HISTORY:
                    this.onViewHistory(item);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        fetchAllAccounts() {
            this.loader().show(this.$t("loading-msg-get-data"));
            this.$store
                .dispatch("accounts/fetchAllAccounts")
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        showAction(item) {
            const itemDeleted = item.deletedState > 0;
            if (itemDeleted) {
                return false;
            }
            return true;
        },
        onDelete(item) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-on-delete-title", {
                        companyName: item.name
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-on-delete-msg", {
                    companyName: item.name
                }),
                type: "reason"
            };
            this.$root.$msgBox.open(msgOptions).then(({ action, reason }) => {
                if (action === "agree" && reason) {
                    this.loader().show();
                    let data = {};
                    data.docId = item.docId;
                    data.reason = reason;
                    this.$store
                        .dispatch("accounts/deleteCompany", data)
                        .then((response) => {
                            if (response.status === 200) {
                                this.$toast.success(
                                    this.$t("on-update-msg-success")
                                );
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t("on-update-msg-error"));
                            console.log(error);
                            this.loader().hide();
                        });
                }
            });
        },
        onEdit(item) {
            const accountDocId = item.accountDocId;
            if (!accountDocId) return;
            this.$store
                .dispatch("accounts/getUserAccount", accountDocId)
                .then((response) => {
                    if (response.status === 200) {
                        const resData = response.data;
                        const accountData = resData.data;
                        this.editUser = _.cloneDeep(accountData);
                        this.editUserSnapshot = _.cloneDeep(accountData);
                        this.dialogFormUser = true;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("on-update-msg-error"));
                    console.log(error);
                    this.loader().hide();
                });
        },
        onEditAccountSave() {
            const accountDocId = this.editUser.accountDocId;
            if (!accountDocId) return;
            const accountFullname =
                this.editUser.firstname + " " + this.editUser.familyname;
            this.$store
                .dispatch("accounts/updateUserAccount", this.editUser)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-edit-user-account-save-success", {
                                accountFullname: accountFullname
                            })
                        );
                        this.dialogFormUser = false;
                        this.editUser = {};
                        this.editUserSnapshot = {};
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(
                        this.$t("msg-edit-user-account-save-error", {
                            accountFullname: accountFullname
                        })
                    );
                    console.log(error);
                    this.loader().hide();
                });
        },
        rowClasses(item) {
            let classes = [];
            if (item.deletedState > 0) {
                classes.push("disabled");
            }
            return classes;
        },
        onAccountSearchItemsChanged(items) {
            if (items) {
                this.$store.commit("accounts/setAccounts", items);
            }
        },
        onViewHistory(item) {
            this.$root.$historyModal.show(item.accountDocId);
        }
    },
    mounted() {
        this.fetchAllAccounts();
    },
    computed: {
        ...mapState("accounts", {
            accounts: "accounts"
        }),
        ...mapState("settings", {
            isGlobalLoading: "showLoader",
            developerMode: "developerMode"
        }),
        ...mapGetters("auth", ["isUserAdmin"]),
        roles() {
            return ACCOUNT_ROLE_LIST.map((role) => ({ value: role }));
        },
        headers() {
            return [
                {
                    text: this.$t("header-fullname"),
                    value: "fullName",
                    sortable: true,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-email"),
                    align: "top",
                    sortable: false,
                    value: "email",
                    isMobile: true
                },
                {
                    text: this.$t("header-roles"),
                    align: "top",
                    sortable: false,
                    value: "roles",
                    isMobile: true
                },
                {
                    text: this.$t("header-lang"),
                    align: "top",
                    sortable: false,
                    value: "lang",
                    isMobile: true
                }
            ];
        },
        filteredActions() {
            return this.actionsItems.filter((action) => {
                let show = true;
                if (action.isDevel && !this.developerMode) {
                    show = false;
                }
                if (action.isAdmin && !this.isUserAdmin) {
                    show = false;
                }
                return show;
            });
        },
        actionsItems() {
            return [
                {
                    title: this.$t("action-edit"),
                    icon: "mdi-pencil",
                    action: ActionTypes.EDIT
                },
                {
                    title: this.$t("action-view-history"),
                    icon: "mdi-history",
                    action: ActionTypes.VIEW_HISTORY
                }
            ];
        },
        rolesIcons() {
            let settings = {};
            _.forEach(ACCOUNT_ROLE_LIST, (role) => {
                settings[role] = getIconAndColorByRole(role);
            });
            return settings;
        },
        filters() {
            let filters = [];
            if (this.rolesFilter.length > 0) {
                filters.push({
                    field: "roles",
                    value: this.rolesFilter
                });
            }
            return filters;
        }
    },
    watch: {
        accounts: {
            handler: function (companies) {
                if (companies.length > 10) {
                    this.tableOptions.itemsPerPage = 15;
                }
            },
            immediate: true
        }
    }
};
</script>
