<i18n src="./summary-data-backoffice-i18n.yaml"></i18n>
<template>
    <BaseListItem
        :title="$t('title-data-backoffice')"
        :expandable="expandable"
        v-if="backoffice"
    >
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li v-if="backofficeFullName">
                    {{ $t("fullname-label") }}:
                    <span class="font-weight-bold">
                        {{ backofficeFullName }}
                    </span>
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="backofficeFullName"
                    />
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
export default {
    name: "SummaryDataBackoffice",

    components: {
        BaseListItem: () => import("./base-list-item.vue"),
        CopyToClipboard: () =>
            import("@/components/general/utlis/copy-to-clipboard.vue")
    },
    props: {
        backoffice: {
            type: Object,
            default: () => {},
            required: true
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        },
        expandable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        backofficeFullName() {
            if (!this.backoffice) return;
            return `${this.backoffice?.firstName} ${this.backoffice?.familyName}`;
        }
    }
};
</script>
