import { render, staticRenderFns } from "./btn-download-zip.vue?vue&type=template&id=149dc0b6&"
import script from "./btn-download-zip.vue?vue&type=script&lang=js&"
export * from "./btn-download-zip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./btn-download-zip-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fgeneral%2Futlis%2Fbtn-download-zip.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports