<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./stepper-i18n.yaml"></i18n>
<template>
    <v-stepper
        v-model="step"
        vertical
        v-scroll="onScroll"
        class="elevation-0 stepper"
        :non-linear="registration.state >= TaskStatus.TASK_REGISTRANT_COMPLETED"
        v-if="registration.docId"
        @change="goToStep"
    >
        <app-dev-container color="info lighten-3" expanded>
            <template v-slot:content>
                <tree-view
                    :data="Object.assign({}, registration)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <v-select
                    :items="devStepsItemsList"
                    label="Jump Step"
                    v-model="step"
                    solo
                ></v-select>
            </template>
        </app-dev-container>
        <RegistrationBasicData
            :registration="registration"
            :expandCard="expandBasicDataCard"
            @click:btn-expand="$emit('change:expand-basic-data-card')"
        />
        <!-- ########################### STEP 01 #############################  -->
        <!-- ####################### Objectdetails ###########################  -->
        <v-stepper-step
            :color="getStepColor(1)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(1)"
            :complete="isStepComplete(1)"
            :rules="[() => isStepValid(1)]"
            :edit-icon="stepEditIcon"
            step="1"
            ref="step1"
        >
            {{ $t("step-01-title") }}
            <small v-if="!isStepValid(1)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="1"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepObjectPropertiesContent
                ref="step01Content"
                :readonlyModus="readonlyModus"
                :registration="registration"
                v-on:field-change="onFieldChange($event, true)"
            >
            </StepObjectPropertiesContent>
            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        @click="validateStep01"
                        btnIcon="mdi-arrow-down"
                        type="primary"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 02 #############################  -->
        <!-- ########################## Location #############################  -->
        <v-stepper-step
            :color="getStepColor(2)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(2)"
            :complete="isStepComplete(2)"
            :rules="[() => isStepValid(2)]"
            :edit-icon="stepEditIcon"
            step="2"
            ref="step2"
            class="sticky"
        >
            {{ $t("step-02-title") }}
            <small v-if="!isStepValid(2)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="2"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepPressureDeviceLocationContent
                ref="step02Content"
                :readonlyModus="readonlyModus"
                :locationAddress="locationAddress"
                :locationContactPerson="locationContactPerson"
                :customerSpecificDesignation="customerSpecificDesignation"
                @reset:equipment-location="
                    onResetEquipmentLocation($event, true)
                "
                @reset:address="onResetEquipmentLocationAddress(true)"
                @reset:contact-person="
                    onResetEquipmentLocationContactPerson(true)
                "
                @reset:customer-specific-designation="
                    onResetCustomerSpecificDesignation(true)
                "
                @change:equipment-location-field="
                    onMergeRegistrationField($event, true)
                "
                @change:customer-specific-designation="
                    onMergeRegistrationField(
                        { field: 'customerSpecificDesignation', value: $event },
                        true
                    )
                "
                @change:contact-person="
                    onUpdateEquipmentLocationContactPerson($event, true)
                "
                @change:address="onUpdateEquipmentLocationAddress($event, true)"
            >
            </StepPressureDeviceLocationContent>
            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(1)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep02"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 03 #############################  -->
        <!-- ########################## Chambers #############################  -->
        <v-stepper-step
            :color="getStepColor(3)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(3)"
            :complete="isStepComplete(3)"
            :rules="[() => isStepValid(3)]"
            :edit-icon="stepEditIcon"
            step="3"
            ref="step3"
        >
            {{ $t("step-03-title") }}
            <small v-if="!isStepValid(3)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="3"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepChambersContent
                ref="step03Content"
                :readonlyModus="readonlyModus"
                :registration="registration"
                v-on:remove-chamber-by-index="
                    onRemoveChmaberByIndex($event, true)
                "
                v-on:change-chamber="onChangeChamber($event, true)"
            >
            </StepChambersContent>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(2)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep03"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 04 #############################  -->
        <!-- ########################## Operator #############################  -->
        <v-stepper-step
            :color="getStepColor(4)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(4)"
            :complete="isStepComplete(4)"
            :rules="[() => isStepValid(4)]"
            :edit-icon="stepEditIcon"
            step="4"
            ref="step4"
        >
            {{ $t("step-04-title") }}
            <small v-if="!isStepValid(4)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="4"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepAddressOperatorContent
                ref="step04Content"
                :readonlyModus="readonlyModus"
                :value="operatorAddress"
                :task="registration"
                @clear="onClearAddress"
                @change:address-field="onChangeAddressField($event, true)"
                @clear:address="onClearAddress($event, true)"
                @change:address="
                    (address) =>
                        onMergeRegistrationAddressValuesByFieldName(
                            { field: 'operatorAddress', value: address },
                            true
                        )
                "
            >
            </StepAddressOperatorContent>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(3)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep04"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 05 #############################  -->
        <!-- ##################### Invoice Recipient #########################  -->
        <v-stepper-step
            :color="getStepColor(5)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(5)"
            :complete="isStepComplete(5)"
            :rules="[() => isStepValid(5)]"
            :edit-icon="stepEditIcon"
            step="5"
            ref="step5"
        >
            {{ $t("step-05-title") }}
            <small v-if="!isStepValid(5)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="5"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepAddressBillContent
                ref="step05Content"
                :readonlyModus="readonlyModus"
                :value="billingAddress"
                :task="registration"
                :billingAdditionalInfos="billingAdditionalInfos"
                @change:address-field="onChangeAddressField($event, true)"
                @clear="onClearAddress({ field: 'billingAddress' })"
                @clear:address="onClearAddress($event, true)"
                @change:address="
                    (address) =>
                        onMergeRegistrationAddressValuesByFieldName(
                            { field: 'billingAddress', value: address },
                            true
                        )
                "
                @change:billing-additional-infos-field="
                    onChangeBillingAdditionalInfosField($event, true)
                "
            >
            </StepAddressBillContent>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(4)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep05"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 06 #############################  -->
        <!-- ########################### Owner ###############################  -->
        <v-stepper-step
            :color="getStepColor(6)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(6)"
            :complete="isStepComplete(6)"
            :rules="[() => isStepValid(6)]"
            :edit-icon="stepEditIcon"
            step="6"
            ref="step6"
        >
            {{ $t("step-06-title") }}
            <small v-if="!isStepValid(6)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="6"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepAddressOwnerContent
                ref="step06Content"
                :readonlyModus="readonlyModus"
                :value="ownerAddress"
                :task="registration"
                @change:address-field="onChangeAddressField($event, true)"
                @clear="onClearAddress({ field: 'ownerAddress' })"
                @clear:address="onClearAddress($event, true)"
                @change:address="
                    (address) =>
                        onMergeRegistrationAddressValuesByFieldName(
                            { field: 'ownerAddress', value: address },
                            true
                        )
                "
            >
            </StepAddressOwnerContent>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(5)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep06"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 07 #############################  -->
        <!-- ###################### Fotos/Documents ##########################  -->
        <v-stepper-step
            :color="getStepColor(7)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(7)"
            :complete="isStepComplete(7)"
            :edit-icon="stepEditIcon"
            step="7"
            ref="step7"
        >
            {{ $t("step-07-title") }}
        </v-stepper-step>

        <v-stepper-content
            step="7"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepUploadContent
                ref="step07Content"
                :readonlyModus="readonlyModus"
                :taskDocId="registration.docId"
                :attachments="registration.attachments"
                :attachmentsStatesRange="[
                    TaskStatus.TASK_REGISTRANT_CREATED,
                    TaskStatus.TASK_REGISTRANT_COMPLETED
                ]"
                @update="onFieldChange({ field: 'attachments', value: $event })"
            >
            </StepUploadContent>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(6)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep07"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
        <!-- ########################### STEP 08 #############################  -->
        <!-- ########### Inspektion in Eigenverantwortung ####################  -->
        <v-stepper-step
            v-if="!isActivelyHeated"
            :color="getStepColor(8)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :editable="isStepEditable(8)"
            :complete="isStepComplete(8)"
            :rules="[() => isStepValid(8)]"
            :edit-icon="stepEditIcon"
            step="8"
            ref="step8"
        >
            {{ $t("step-08-title") }}
            <small v-if="!isStepValid(8)" class="pt-1">{{
                $t("step-has-errors")
            }}</small>
        </v-stepper-step>

        <v-stepper-content
            step="8"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
            v-if="!isActivelyHeated"
        >
            <StepActivelyHeatedContent
                ref="step08Content"
                :readonlyModus="readonlyModus"
                :registration="registration"
                v-on:field-change="onFieldChange($event, true)"
                @attachments:change="
                    onFieldChange({ field: 'attachments', value: $event })
                "
                :attachmentsStatesRange="[
                    TaskStatus.TASK_REGISTRANT_CREATED,
                    TaskStatus.TASK_REGISTRANT_COMPLETED
                ]"
            >
            </StepActivelyHeatedContent>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(7)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep08"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 09 #############################  -->
        <!-- ########################### Summary #############################  -->
        <v-stepper-step
            :color="getStepColor(9)"
            :class="$vuetify.breakpoint.mobile ? 'px-0' : null"
            :complete="
                registration.state >= TaskStatus.TASK_REGISTRANT_COMPLETED
            "
            :editable="isStepEditable(9)"
            :edit-icon="stepEditIcon"
            :step="isActivelyHeated ? 8 : 9"
            ref="step9"
        >
            {{ $t("step-09-title") }}
        </v-stepper-step>

        <v-stepper-content
            :step="isActivelyHeated ? 8 : 9"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepSummaryContent
                ref="step09Content"
                class="px-3"
                :registrationData="registration"
            >
            </StepSummaryContent>
            <v-row
                class="px-3 pt-3"
                v-if="registration.state < TaskStatus.TASK_REGISTRANT_COMPLETED"
            >
                <v-col>
                    <base-textarea
                        v-model="registrantNote"
                        :label="$t('registrant-note-label')"
                        :placeholder="$t('registrant-note-placeholder')"
                        :readonly="
                            registration.state >=
                            TaskStatus.TASK_REGISTRANT_COMPLETED
                        "
                        persistent-hint
                        w-type="note"
                        :clearable="
                            registration.state <
                            TaskStatus.TASK_REGISTRANT_COMPLETED
                        "
                        no-resize
                        :counter="$v.registrantNote.$params.maxLength.max"
                        :error-messages="registrantNoteErrors"
                        :required="isRequiredField($v.registrantNote)"
                    />
                </v-col>
            </v-row>

            <v-row v-if="!hideStepButtons" class="px-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        class="mr-2"
                        @click="isActivelyHeated ? goToStep(7) : goToStep(8)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        v-if="registration && !readonlyModus"
                        type="save"
                        @click="validateStep09"
                    >
                        {{ $t("btn-complete") }}
                    </base-btn>
                    <base-btn
                        v-if="registration && readonlyModus"
                        type="save"
                        @click="modalStepperCopyTask = true"
                    >
                        {{ $t("btn-task-copy") }}
                    </base-btn>
                </v-col>
            </v-row>
            <ModalTaskComplete
                v-model="modalTaskComplete"
                :mailEncrypted="mail"
                @click:complete="createTaskRegistration"
            />
            <ModalStepperCopyTask
                v-model="modalStepperCopyTask"
                :task="registration"
                @task:created="onTaskRegistrationCopyCreated"
            />
            <ModalTaskCompleteFurtherActions
                v-model="modalTaskCompleteFurtherActions"
                @click:close="goToRegistranDashboard"
                @click:task-create="modalCreateTaskRegistrationEquipment = true"
                @click:task-copy="modalStepperCopyTask = true"
            />
            <DialogRegisterEquipment
                v-model="modalCreateTaskRegistrationEquipment"
            />
        </v-stepper-content>
    </v-stepper>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapMutations, mapActions } from "vuex";
import validateMixin from "@/mixins/validate";
import { maxLength } from "vuelidate/lib/validators";
import { TaskStatus } from "@/data/globals.js";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
import StepperPressureEquipmentMixin from "@/components/tasks/edit/common/mixins/stepper-pressure-equipment.js";

export default {
    name: "RegistrantStepperPressureEquipment",
    mixins: [validateMixin, StepperPressureEquipmentMixin],
    validations: {
        registrantNote: {
            maxLength: maxLength(500)
        }
    },
    components: {
        ModalTaskComplete: () =>
            import(
                "@/components/tasks/edit/registrant/components/modal-task-complete.vue"
            ),
        ModalStepperCopyTask: () =>
            import(
                "@/components/tasks/edit/registrant/components/modal-stepper-copy-task.vue"
            ),
        ModalTaskCompleteFurtherActions: () =>
            import(
                "@/components/tasks/edit/registrant/components/modal-task-complete-further-actions.vue"
            ),
        DialogRegisterEquipment: () =>
            import("@/components/tasks/create/dialog-register-equipment.vue")
    },

    props: {
        hideStepButtons: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedCompany: {},
            modalTaskComplete: false,
            modalCreateTaskRegistrationEquipment: false,
            TaskStatus,
            modalStepperCopyTask: false,
            modalTaskCompleteFurtherActions: false
        };
    },

    computed: {
        ...mapGetters({
            readonlyModus: "registration/readonlyModus",
            referenceId: "registration/referenceId",
            registration: "registration/registration",
            docId: "registration/docId",
            locationAddress: "registration/locationAddress",
            locationContactPerson: "registration/locationContactPerson",
            customerSpecificDesignation:
                "registration/customerSpecificDesignation",
            billingAdditionalInfos: "registration/billingAdditionalInfos",
            taskState: "registration/taskState",
            isAutoSaving: "registration/isAutoSaving"
        }),
        registrantNote: {
            get() {
                return this.registration?.registrantNote;
            },
            set: _.debounce(function (value) {
                this.onFieldChange({ field: "registrantNote", value }, true);
            }, 1000)
        },
        stepEditIcon() {
            return this.taskState >= TaskStatus.TASK_REGISTRANT_COMPLETED
                ? "mdi-check"
                : "mdi-pencil";
        },
        registrantNoteErrors() {
            const errors = [];
            if (!this.$v.registrantNote.$dirty) {
                return errors;
            }
            if (!this.$v.registrantNote.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.registrantNote.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },

    methods: {
        ...mapMutations("registration", [
            MUTATION_TYPES.CLEAR_REGISTRATION,
            MUTATION_TYPES.UPDATE_REGISTRATION_FIELD,
            MUTATION_TYPES.MERGE_REGISTRATION_FIELDS,
            MUTATION_TYPES.CLEAR_REGISTRATION_ADDRESS,
            MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS_FIELD,
            MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS,
            MUTATION_TYPES.UPDATE_CHAMBERS,
            MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON,
            MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_ADDRESS,
            MUTATION_TYPES.REMOVE_CHAMBER_BY_INDEX,
            MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_ADDRESS,
            MUTATION_TYPES.RESET_CUSTOMER_SPECIFIC_DESIGNATION,
            MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_CONTACT_PERSON,
            MUTATION_TYPES.ENABLE_READONLY_MODUS
        ]),
        ...mapActions("registration", [
            ACTION_TYPES.UPDATE_SCROLL_POSITION,
            ACTION_TYPES.COMPLETE,
            ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION,
            ACTION_TYPES.AUTO_SAVE_REGISTRATION
        ]),
        onItemSelectChange(item) {
            if (item.value) {
                this.selectedCompany = item;
            } else {
                this.selectedCompany = {};
            }
        },
        isStepValid(step) {
            if (this.readonlyModus || this.step < step) {
                return true;
            }
            const stepContent = `step0${step}Content`;
            const ref = this.$refs[stepContent];
            if (!ref) {
                return true;
            }
            const isValid = ref.isValid();
            switch (isValid) {
                case null: // validation not started
                    return true;
                case false: // step is invalid
                    return false;
                default:
                    // step is valid (isValid === true)
                    return true;
            }
        },
        validateStep01(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 1) return;
            this.$refs.step01Content.validate();
            if (this.$refs.step01Content.isValid()) {
                this.goToStep(2);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.$refs.step01Content.scrollToFirstError();
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep02(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 2) return;
            this.$refs.step02Content.validate();
            if (this.$refs.step02Content.isValid()) {
                this.goToStep(3);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.$refs.step02Content.scrollToFirstError();
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep03(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 3) return;
            this.$refs.step03Content.validate();
            if (this.$refs.step03Content.isValid()) {
                this.goToStep(4);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep04(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 4) return;
            this.$refs.step04Content.validate();
            if (this.$refs.step04Content.isValid()) {
                this.goToStep(5);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.$refs.step04Content.scrollToFirstError();
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep05(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 5) return;
            this.$refs.step05Content.validate();
            if (this.$refs.step05Content.isValid()) {
                this.goToStep(6);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.$refs.step05Content.scrollToFirstError();
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep06(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 6) return;
            this.$refs.step06Content.validate();
            if (this.$refs.step06Content.isValid()) {
                this.goToStep(7);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.$refs.step06Content.scrollToFirstError();
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep07(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 7) return;
            this.goToStep(8);
                if (!layz){
                    this.updateRegistration();
                }
        },
        validateStep08(layz=false) {
            if (this.readonlyModus) {
                this.goToStep(this.step + 1);
                return;
            }
            if (this.step < 8) return;
            if (!this.$refs.step08Content) return;
            this.$refs.step08Content.validate();
            if (this.$refs.step08Content.isValid()) {
                this.goToStep(9);
                if (!layz){
                    this.updateRegistration();
                }
            } else {
                this.$refs.step08Content.scrollToFirstError();
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateAllSteps(layz=false) {
            this.validateStep01(layz);
            this.validateStep02(layz);
            this.validateStep03(layz);
            this.validateStep04(layz);
            this.validateStep05(layz);
            this.validateStep06(layz);
            this.validateStep07(layz);
            this.validateStep08(layz);
        },
        showToastMsgAutoSaving() {
            if (this.isAutoSaving) {
                this.$toast.warning(this.$t("task-is-saving-warning"));
            }
        },
        validateStep09() {
            if (this.isAutoSaving) {
                this.showToastMsgAutoSaving();
                return;
            }
            if (this.readonlyModus) {
                return;
            } else {
                if (this.step < 8) return;
                this.validate();
                if (!this.isValid()) {
                    return this.$toast.error(this.$t("step-has-errors"));
                }
                this.loader().show(this.$t("validate-all-steps-msg"));
                this.validateAllSteps(true);
                if (!this.areAllStepsValid()) {
                    this.loader().hide();
                    return;
                }
                this.loader().hide();
                this.modalTaskComplete = true;
            }
        },
        createTaskRegistration() {
            if (this.isAutoSaving) {
                this.showToastMsgAutoSaving();
                return;
            }
            this.saveTaskRegistration().then((response) => {
                const resData = response.data.data;
                const taskState = resData.state;
                this.showToastMsg("success", this.$t("save-success-msg"));
                if (taskState) {
                    this.onFieldChange({
                        field: "state",
                        value: Number(resData.state)
                    });
                }
                this.modalTaskCompleteFurtherActions = true;
                this.modalTaskComplete = false;
            });
        },
        createTaskRegistrationCopy() {
            if (this.isAutoSaving) {
                this.showToastMsgAutoSaving();
                return;
            }
            this.saveTaskRegistration().then((response) => {
                const resData = response.data.data;
                const taskState = resData.state;
                this.showToastMsg("success", this.$t("save-success-msg"));
                // set TASK state
                if (taskState) {
                    this.onFieldChange({
                        field: "state",
                        value: Number(resData.state)
                    });
                }
                this.modalTaskCompleteFurtherActions = true;
                this.modalTaskComplete = false;
            });
        },
        onTaskRegistrationCopyCreated() {
            this.modalTaskCompleteFurtherActions = false;
            this.modalStepperCopyTask = false;
            this.$vuetify.goTo(0);
        },
        saveTaskRegistration() {
            return new Promise((resolve, reject) => {
                this.loader().show(
                    this.$t("is-loading-msg-complete-registration-with-email")
                );
                let registration = _.cloneDeep(this.registration);
                registration.registrationOffice = this.registrationOffice;
                let data = registration;

                this[ACTION_TYPES.COMPLETE](data)
                    .then((response) => {
                        this.loader().hide();
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        const resData = error.response.data;
                        const errorCode = resData.code;
                        const errorData = resData.data;
                        if (this.$te(`error-code-${errorCode}`)) {
                            //CHECK IF TRANSLATE EXISTS
                            if (errorCode === 5) {
                                if (errorData.addressType) {
                                    this.$toast.error(
                                        this.$t("error-code-5", {
                                            addressType: this.$t(
                                                `address-type-${errorData.addressType}`
                                            )
                                        })
                                    );
                                }
                            } else if (errorCode === 7) {
                                //field is missing
                                const missingField = errorData.missingField;
                                if (missingField) {
                                    if (
                                        this.$te(
                                            `registration-field-${missingField}`
                                        )
                                    ) {
                                        this.$toast.error(
                                            this.$t("error-code-7", {
                                                missingField: this.$t(
                                                    `registration-field-${missingField}`
                                                )
                                            })
                                        );
                                    } else {
                                        this.$toast.error(
                                            this.$t(
                                                `error-code-${errorCode}`,
                                                3000
                                            )
                                        );
                                    }
                                }
                            } else if (errorCode === 8) {
                                const missingField = errorData.missingField;
                                const chamberId = errorData.chamberId;
                                if (missingField) {
                                    if (
                                        this.$te(
                                            `registration-field-chambers-${missingField}`
                                        )
                                    ) {
                                        this.$toast.error(
                                            this.$t("error-code-8", {
                                                missingField: this.$t(
                                                    `registration-field-chambers-${missingField}`
                                                ),
                                                chamberId: chamberId
                                            })
                                        );
                                    } else {
                                        this.$toast.error(
                                            this.$t(
                                                `error-code-${errorCode}`,
                                                3000
                                            )
                                        );
                                    }
                                }
                            } else {
                                this.$toast.error(
                                    this.$t(`error-code-${errorCode}`, 3000)
                                );
                            }
                        } else {
                            //ELSE WILL DISPLAY AN GENERAL ERROR MESSAGE
                            this.$toast.error(this.$t("error-code-0"));
                        }
                        this.loader().hide();
                        reject(error);
                    });
            });
        },
        clearRegistrationsStore() {
            this.$store.commit("registrations/clearOpen");
            this[MUTATION_TYPES.CLEAR_REGISTRATION]();
        },
        showToastMsg(type, msg) {
            return this.$toast[type](msg);
        },
        save() {
            let data = this.registration;
            this[ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION](data);
        },
        async updateRegistration() {
            try {
                this.$store.commit(
                    "settings/registrationTimestampStatus",
                    "loading"
                );
                await this.save();
            } catch (error) {
                this.errorMessage = JSON.stringify(error);
                this.$store.commit(
                    "settings/registrationTimestampStatus",
                    "error"
                );
            }
        },
        debounceUpdateRegistration: _.debounce(function () {
            this.updateRegistration();
        }, 5000),
        onDataChanged() {
            this.$store.commit(
                "settings/registrationTimestampStatus",
                "loading"
            );
            this.debounceUpdateRegistration();
        },
        close() {
            this.clearRegistrationsStore();
            this.$router.go(-1);
        },
        goToRegistranDashboard() {
            this.clearRegistrationsStore();
            this.$router.push({
                name: "user-dashboard"
            });
        },
        getStepColor(step) {
            if (this.taskState > TaskStatus.TASK_REGISTRANT_COMPLETED) {
                return "success";
            }
            if (this.step > step) {
                return "success";
            }
            return this.theme.isDark ? "secondary" : "primary";
        },
        onFieldChange({ field, value }, autoSave = false) {
            if (!this.readonlyModus) {
                if (autoSave) {
                    this.autoSave(field, value);
                } else {
                    this[MUTATION_TYPES.UPDATE_REGISTRATION_FIELD]({
                        field,
                        value
                    });
                }
            }
        },
        isStepEditable(step) {
            if (this.taskState > TaskStatus.TASK_REGISTRANT_IN_PROCESS) {
                return true;
            }
            if (this.step > step) {
                return true;
            }
            return false;
        },
        isStepComplete(step) {
            if (this.step > step) {
                return true;
            }
            if (this.taskState > TaskStatus.TASK_REGISTRANT_COMPLETED) {
                return true;
            }
            return false;
        }
    },
    watch: {
        taskState: {
            handler: function (newValue) {
                if (
                    newValue >= TaskStatus.TASK_REGISTRANT_COMPLETED ||
                    !newValue
                ) {
                    this[MUTATION_TYPES.ENABLE_READONLY_MODUS]();
                }
                if (newValue) {
                    if (
                        newValue >= TaskStatus.TASK_REGISTRANT_COMPLETED ||
                        !newValue
                    ) {
                        this.step = 1;
                    } else {
                        this.step = this.registration.step;
                    }
                    if (newValue < TaskStatus.TASK_REGISTRANT_COMPLETED) {
                        if (this.registration?.scrollPosition != null) {
                            this.scrollToPositionTop(
                                this.registration.scrollPosition
                            );
                        }
                    }
                }
            },
            immediate: true
        },
        referenceId: {
            handler: function () {
                this.modalTaskCompleteFurtherActions = false;
            },
            immediate: true
        }
    }
};
</script>
