<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./view-i18n.yaml"></i18n>
<template>
    <v-container pa-0 ma-0 fluid>
        <v-row align="center" justify="center">
            <template
                v-if="(!task || Object.keys(task).length === 0) && isLoading"
            >
                <v-col cols="12" sm="6">
                    <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </template>
            <v-col cols="12" md="10">
                <app-dev-container>
                    <template v-slot:content>
                        <tree-view
                            :data="Object.assign({}, task)"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                    </template>
                </app-dev-container>
                <TaskCompleteView :task="task" show-copy-btn />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import * as ACTION_TYPES from "@/store/types/action-types";
import { RegistrationBackoffice } from "@/models";
export default {
    components: {
        TaskCompleteView: () =>
            import("@/components/tasks/common/task-complete-view.vue")
    },

    props: {
        referenceId: {
            type: String,
            default: null
        }
    },

    data: () => ({
        isEditing: true,
        task: new RegistrationBackoffice()
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        })
    },
    methods: {
        ...mapActions("insurer", [ACTION_TYPES.FETCH_REGISTRATION_DATA]),
        getData() {
            if (!this.referenceId) {
                return;
            }
            this.loader().show();
            let data = {};
            data.referenceId = this.referenceId;
            this[ACTION_TYPES.FETCH_REGISTRATION_DATA](data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        this.task = resData.data;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus = error.response.status;
                    if (resStatus === 404) {
                        this.$router.push("/404");
                    } else if (resStatus === 403) {
                        this.$router.push("/403");
                    } else {
                        this.$router.push("/500");
                    }
                    this.loader().hide();
                });
        }
    },

    mounted() {
        if (this.referenceId) {
            this.getData();
        } else {
            if (this.isAuth) {
                this.$router.push({ name: "insurer-dashboard" });
            } else {
                this.$router.push("/");
            }
        }
    }
};
</script>
