<i18n       src="@/lang/defaults-i18n.yaml"></i18n>
<i18n       src="./registration-basic-data-i18n.yaml"></i18n>
<template>
    <v-card tile outlined flat>
        <v-card-title>
            {{ $t("card-title") }}
            <v-spacer />
            <v-btn
                v-if="showLock && !readonlyLock"
                icon
                color="info"
                @click="dialogBasicData = true"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <btn-lock
                v-if="showLock && expandRegistrationDataCard"
                v-model="readonlyLock"
                :absolute="false"
                @click="onClickBtnLock"
                :disabled="disabledLock"
            >
            </btn-lock>
            <v-btn icon @click="$emit('click:btn-expand')">
                <v-icon>{{
                    expandRegistrationDataCard
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-expand-transition v-if="expandRegistrationDataCard">
            <v-card-text>
                <KeyValueList
                    :data="registrationDataList"
                    class="pb-0 mb-0"
                    v-on:icon-key-click="$emit('icon-key-click', $event)"
                    v-on:icon-value-click="$emit('icon-value-click', $event)"
                />
                <v-list
                    dense
                    class="pt-0 mt-0"
                    v-if="attachmentsTotalCounter > 0"
                >
                    <v-list-item
                        v-if="
                            registration.state >
                            TaskStatus.TASK_REGISTRANT_COMPLETED
                        "
                    >
                        <template v-if="$vuetify.breakpoint.xsOnly">
                            <v-list-item-content>
                                <v-list-item-title
                                    class="font-weight-bold text-body-2"
                                >
                                    <v-btn
                                        icon
                                        @click="dialogAttachments = true"
                                    >
                                        <v-icon small>mdi-file-multiple</v-icon>
                                    </v-btn>
                                    {{ $t("attachments-details") }}
                                </v-list-item-title>
                                <div class="text-subtitle-2">
                                    <li
                                        v-for="(
                                            attachments, category
                                        ) in attachmentsCountersByCategory"
                                        :key="category"
                                        class="text-body-2"
                                    >
                                        {{
                                            $t(
                                                `attachment-category-${category}`
                                            )
                                        }}
                                        (<span v-if="attachments.photos > 0">
                                            <v-icon x-small
                                                >mdi-file-image</v-icon
                                            >
                                            {{
                                                $tc(
                                                    "photos-count",
                                                    attachments.photos,
                                                    {
                                                        count: attachments.photos
                                                    }
                                                )
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                attachments.photos &&
                                                attachments.documents
                                            "
                                        >
                                            ,
                                        </span>
                                        <span v-if="attachments.documents > 0">
                                            <v-icon x-small
                                                >mdi-file-multiple</v-icon
                                            >
                                            {{
                                                $tc(
                                                    "documents-count",
                                                    attachments.documents,
                                                    {
                                                        count: attachments.documents
                                                    }
                                                )
                                            }}) </span
                                        >)
                                    </li>
                                </div>
                            </v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content>
                                <div>
                                    <v-btn
                                        icon
                                        @click="dialogAttachments = true"
                                    >
                                        <v-icon small>mdi-file-multiple</v-icon>
                                    </v-btn>
                                    {{ $t("attachments-details") }}
                                </div>
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                <li
                                    v-for="(
                                        attachments, category
                                    ) in attachmentsCountersByCategory"
                                    :key="category"
                                    class="text-body-2"
                                >
                                    {{ $t(`attachment-category-${category}`) }}
                                    (<span v-if="attachments.photos > 0">
                                        <v-icon x-small>mdi-file-image</v-icon>
                                        {{
                                            $tc(
                                                "photos-count",
                                                attachments.photos,
                                                { count: attachments.photos }
                                            )
                                        }}
                                    </span>
                                    <span
                                        v-if="
                                            attachments.photos &&
                                            attachments.documents
                                        "
                                    >
                                        ,
                                    </span>
                                    <span v-if="attachments.documents > 0">
                                        <v-icon x-small
                                            >mdi-file-multiple</v-icon
                                        >
                                        {{
                                            $tc(
                                                "documents-count",
                                                attachments.documents,
                                                { count: attachments.documents }
                                            )
                                        }}) </span
                                    >)
                                </li>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list>
                <slot name="addons" />
                <v-row>
                    <v-col>
                        <DialogBasicData
                            :value="dialogBasicData"
                            @input="dialogBasicData = $event"
                            :registration="registration"
                            @complete="complete"
                        >
                        </DialogBasicData>
                    </v-col>
                    <v-col vif="attachmentsTotalCounter > 0">
                        <DialogAttachments
                            :value="dialogAttachments"
                            @input="dialogAttachments = $event"
                            :readonlyModus="!showLock"
                            :attachments="registration.attachments"
                            :taskDocId="registration.docId"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>
<script>
import DialogBasicData from "@/components/tasks/edit/common/components/dialog-basic-data.vue";
import BtnLock from "@/components/tasks/common/btn-lock.vue";
import Helpers from "@/utils/helpers";
import DialogAttachments from "@/components/general/dialog-attachments.vue";
import { TaskStatus } from "@/data/globals";
import KeyValueList from "@/components/general/key-value-list.vue";
import _ from "lodash";
export default {
    name: "RegistrationBasicData",
    components: {
        DialogBasicData,
        BtnLock,
        DialogAttachments,
        KeyValueList
    },
    props: {
        registration: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        showLock: {
            type: Boolean,
            default: false
        },
        expandCard: {
            type: Boolean,
            default: false
        },
        disabledLock: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expandRegistrationDataCard: false,
            readonlyLock: true,
            dialogBasicData: false,
            dialogAttachments: false,
            TaskStatus: TaskStatus
        };
    },
    methods: {
        disableForm() {
            if (this.showLock && this.readonlyLock) {
                return true;
            } else {
                return this.disabled;
            }
        },
        complete(formData) {
            Object.keys(formData).forEach((key) => {
                this.$emit("field-change", {
                    field: key,
                    value: formData[key]
                });
            });
            this.dialogBasicData = false;
        },
        onClickBtnLock() {
            this.readonlyLock = !this.readonlyLock;
            this.$emit("click:btn-lock", this.readonlyLock);
        }
    },
    computed: {
        registrationDataList() {
            let list = [];
            if (!this.registrationData) return list;
            _.forEach(this.registrationData, (value, key) => {
                let valueText = null;
                let valueIcon = {};
                let keyIcon = {};
                valueText = this.$te(value) ? this.$t(value) : value;
                if (
                    !this.readonly &&
                    this.registration.state >
                        TaskStatus.TASK_REGISTRANT_COMPLETED &&
                    this.registration.isBackoffice === true &&
                    key === "objectType"
                ) {
                    keyIcon = {
                        value: "mdi-content-duplicate",
                        action: "object-type"
                    };
                }
                let obj = {
                    key: {
                        text: this.$t(key)
                    },
                    value: {
                        text: valueText
                    }
                };
                if (!_.isEmpty(valueIcon)) {
                    obj.value.icon = valueIcon;
                }
                if (!_.isEmpty(keyIcon)) {
                    obj.key.icon = keyIcon;
                }
                if (key && value) {
                    list.push(obj);
                }
            });
            return list;
        },
        registrationData() {
            if (!this.registration) {
                return {};
            }
            let data = {};
            data.role = this.registration.role;
            data.objectType = this.registration.objectType;
            data.process = this.registration.process;
            data.workingTitle = this.registration.workingTitle;
            data.manufacturer = this.registration.manufacturer?.name;
            data.factoryNumber = this.registration.factoryNumber;
            data.intendedUse = this.registration.intendedUse;
            data.constructionYear = this.registration.constructionYear;
            data.inOperationYear = this.registration.inOperationYear;
            return data;
        },
        attachmentsCounters() {
            if (!this.registration || !this.registration.attachments)
                return [0, 0, 0];
            return Helpers.getAttachmentsCounters(
                this.registration.attachments
            );
        },
        attachmentsCountersByCategory() {
            if (!this.registration || !this.registration.attachments) return {};
            return Helpers.getAttachmentsCountersByCategory(
                this.registration.attachments
            );
        },
        attachmentsTotalCounter() {
            return this.attachmentsCounters[0].length;
        }
    },
    watch: {
        expandCard: {
            handler: function (val) {
                // watch it
                this.expandRegistrationDataCard = val;
            },
            immediate: true
        }
    }
};
</script>
