<i18n src="./display-address-i18n.yaml"></i18n>
<template>
    <div v-if="address">
        <div class="subtitle-1">
            <div class="ml-3">
                <div v-if="showSameAsOperator && address.sameAddressAsOperator">
                    {{ $t("same-address-as-operator") }}
                </div>
                <div v-if="address.uidFormatted">
                    {{ address.uidFormatted }}
                </div>
                <div v-if="address.addressType === ADDRESS_TYPES.COMPANY">
                    <div>
                        {{ address.companyName }}
                    </div>
                    <div>
                        {{ address.department }}
                    </div>
                </div>
                <div v-else>
                    <div>
                        {{ address.firstName }}
                        {{ address.familyName }}
                    </div>
                </div>
                <div v-if="address.additionalAddressLines">
                    <div v-if="address.addressLine1">
                        {{ $t("additional-address-lines-prefix") }} {{ address.addressLine1 }}
                    </div>
                    <div v-if="address.addressLine2">
                        {{ address.addressLine2 }}
                    </div>
                </div>
            </div>
            <div v-if="address.street">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <div>
                            <v-btn
                                icon
                                @click="
                                    link = openGoogleMap(address);
                                    dialogExternalLink = true;
                                "
                            >
                                <v-icon small v-on="on">
                                    mdi-google-maps
                                </v-icon>
                            </v-btn>
                            <span class="subtitle-1">
                                {{ address.street }}
                                <span v-if="address.houseNr">
                                    {{ address.houseNr }}
                                </span>
                            </span>
                        </div>
                    </template>
                    <div>{{ $t("open-google-maps") }}</div>
                </v-tooltip>
            </div>
            <div class="ml-10">
                {{ address.country }}-{{ address.zipCode }} {{ address.city }}
                <span v-if="address.canton">({{ address.canton }})</span>
            </div>
        </div>
        <div class="subtitle-1 pt-2" v-if="!isComminicationIsEmpty(address.communication)">
            <v-tooltip right v-if="address.communication.phone">
                <template v-slot:activator="{ on }">
                    <div>
                        <v-btn
                            icon
                            :href="'tel:' + address.communication.phone"
                        >
                            <v-icon small v-on="on"> mdi-phone </v-icon>
                        </v-btn>
                        {{ address.communication.phone }}
                    </div>
                </template>
                <div>{{ $t("communication-phone") }}</div>
            </v-tooltip>
            <v-tooltip right v-if="address.communication.fax">
                <template v-slot:activator="{ on }">
                    <div>
                        <v-icon class="pa-2" small v-on="on"> mdi-fax </v-icon>
                        {{ address.communication.fax }}
                    </div>
                </template>
                <div>{{ $t("communication-fax") }}</div>
            </v-tooltip>
            <v-tooltip right v-if="address.communication.email">
                <div>{{ $t("communication-email") }}</div>
                <template v-slot:activator="{ on }">
                    <div>
                        <v-btn
                            icon
                            :href="'mailto:' + address.communication.email"
                        >
                            <v-icon small v-on="on"> mdi-email </v-icon>
                        </v-btn>
                        {{ address.communication.email }}
                    </div>
                </template>
            </v-tooltip>
        </div>
        <DialogExternalLink
            class="py-3"
            v-if="dialogExternalLink && link"
            :value="dialogExternalLink"
            @input="dialogExternalLink = $event"
            :link="link.url"
        >
        </DialogExternalLink>
    </div>
</template>
<script>
import DialogExternalLink from "@/components/general/dialog-external-link.vue";
import Helpers from "@/utils/helpers";
import { ADDRESS_TYPES } from "@/data/globals.js";
export default {
    name: "DisplayAddress",
    components: {
        DialogExternalLink
    },
    props: {
        address: {
            type: Object,
            default: () => {}
        },
        showSameAsOperator: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialogExternalLink: false,
        link: null,
        ADDRESS_TYPES: ADDRESS_TYPES
    }),
    methods: {
        openGoogleMap(address) {
            return Helpers.getGoogleMapsLinkByAddress(address);
        },
        isComminicationIsEmpty(data) {
            return Helpers.isComminicationFieldEmpty(data);
        }
    }
};
</script>
