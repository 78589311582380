<i18n src="./expansion-panel-info-i18n.yaml"></i18n>
<template>
    <v-expansion-panels accordion hover v-model="panelId">
        <v-expansion-panel
            v-for="(panel, i) in panels"
            :key="i"
            @change="onPanelClick(panel)"
        >
            <v-expansion-panel-header>{{
                panel.title
            }}</v-expansion-panel-header>
            <v-expansion-panel-content
                :id="panel.id"
                v-if="panel.content"
                class="pa-4"
            >
                <template v-if="panel.content">
                    <div v-for="text in panel.content" :key="text.t">
                        <p v-if="text.t" v-html="text.t"></p>
                        <Markdown
                            v-if="text.u"
                            :code="getAsArray('u', text.u)"
                        />
                    </div>
                </template>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import Markdown from "@/components/general/markdown.vue";
import _ from "lodash";
export default {
    name: "ExpansionPanelsInfo",
    components: {
        Markdown
    },
    data: () => ({
        panelId: null
    }),
    computed: {
        panels() {
            return [
                {
                    id: "panel-1",
                    title: this.$t("panel-1-title"),
                    content: [
                        {
                            t: this.$t("panel-1-content-1-text-1")
                        }
                    ]
                },
                {
                    id: "panel-2",
                    title: this.$t("panel-2-title"),
                    content: [
                        {
                            t: this.$t("panel-2-content-1-text-1")
                        }
                    ]
                },
                {
                    id: "panel-3",
                    title: this.$t("panel-3-title"),
                    content: [
                        {
                            t: this.$t("panel-2-content-1-text-1"),
                            u: [
                                this.$t("panel-3-content-1-u-1"),
                                this.$t("panel-3-content-1-u-2"),
                                this.$t("panel-3-content-1-u-3")
                            ]
                        },
                        {
                            t: this.$t("panel-3-content-1-text-2")
                        }
                    ]
                }
            ];
        }
    },
    methods: {
        getAsArray(key, arrayObj) {
            //sample : ('u', ['baa'. foo'])
            let ret = {};
            if (key) {
                ret[key] = _.toArray(arrayObj);
            }

            return ret;
        },
        onPanelClick(panel) {
            if (!panel.id) return;
            setTimeout(() => {
                this.$vuetify.goTo(`#${panel.id}`);
            }, 100);
        }
    }
};
</script>
