import { axiosTasks } from "@/api/axios-instances";
import { DeregistrationBackoffice } from "@/models";
import { TaskStatus } from "@/data/globals";
import * as ACTION_TYPES from "../types/action-types";
import * as MUTATION_TYPES from "../types/mutation-types";
import { PROCESS_TYPES } from "@/data/globals";
import store from "@/store";

const initialState = () => ({
    ...new DeregistrationBackoffice(),
    state: null,
    lang: null,
    registrationOffice: null,
    readonlyModus: false
});

const state = initialState();
const mutations = {
    [MUTATION_TYPES.SET_DEREGISTRATION](state, payload) {
        const deregistration = Object.assign(
            new DeregistrationBackoffice(),
            payload
        );
        Object.assign(state, deregistration);
    },
    [MUTATION_TYPES.UPDATE_DEREGISTRATION](state, { field, value }) {
        if (state.state > TaskStatus.TASK_CONFIRMED) return;
        state[field] = value;
    }
};

const getters = {
    readonlyModus(state) {
        return state.readonlyModus;
    },
    docId(state) {
        return state.docId;
    },
    referenceId(state) {
        return state.referenceId;
    },
    deregistration(state) {
        let deregistration = Object.assign(
            new DeregistrationBackoffice(),
            state
        );
        return deregistration;
    },
    deregistrationToSave(state) {
        let deregistration = Object.assign(
            new DeregistrationBackoffice(),
            state
        );
        deregistration.registrationOffice =
            store.getters["settings/getRegistrationOffice"];
        return deregistration;
    }
};

const actions = {
    [ACTION_TYPES.FETCH_TASK_DATA_BY_REFERENCE_ID]: (ctx, referenceId) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/ref/${referenceId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        const task = resData;
                        switch (task.process) {
                            case PROCESS_TYPES.DEREGISTRATION:
                                ctx.commit(
                                    MUTATION_TYPES.SET_DEREGISTRATION,
                                    resData
                                );
                                break;
                            default:
                                break;
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
