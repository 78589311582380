export const TaskActionTypes = {
    VIEW: "VIEW",
    VIEW_CHECK: "VIEW_CHECK",
    DATA: "DATA",
    LOCK_FOR_REVIEW: "LOCK_FOR_REVIEW",
    UNLOCK_TASK: "UNLOCK_TASK",
    FORCE_TAKE_OVER: "FORCE_TAKE_OVER",
    DELETE: "DELETE",
    REMOVE_FROM_DB: "REMOVE_FROM_DB",
    SAP_EXPORT: "SAP_EXPORT",
    SEND_REMINDER_MAIL: "SEND_REMINDER_MAIL",
    VIEW_HISTORY: "VIEW_HISTORY",
    DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP: "DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP"
};
