<template>
    <v-app id="inspire" class="app">
        <div class="admin">
            <AppNavigationDrawer
                v-on:navigation-item-click="onNavigationItemClick"
                :value="drawer.show"
                @input="drawer.show = $event"
                :right="false"
            >
                <template v-slot:navigation-content>
                    <div class="ps-navigation-content">
                        <div
                            class="shadow-bottom"
                            :class="{ 'd-block': shallShadowBottom }"
                        />

                        <PerfectScrollbar
                            class="ps-navigation-content-items"
                            :options="perfectScrollbarOptions"
                            @ps-scroll-y="
                                (evt) => {
                                    shallShadowBottom =
                                        evt.srcElement.scrollTop > 0;
                                }
                            "
                        >
                            <NavigationContent />
                        </PerfectScrollbar>
                    </div>
                </template>
            </AppNavigationDrawer>
            <AppToolbar
                ref="appToolbar"
                @menu-icon-click="onMenuIconClick"
                :drawer="drawer.show"
                showMenuBtn
                appMenuPosition="left"
                hideLogo
            >
            </AppToolbar>
            <v-main class="grey lighten-4 pt-0">
                <v-toolbar dense class="elevation-0">
                    <BreadCrumb />
                    <v-spacer />
                    <div class="mt-2 mr-5">
                        <base-btn icon color="primary" @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                        </base-btn>
                        <base-btn
                            v-if="showReloadBtn"
                            icon
                            @click="onReloadBtnClick"
                        >
                            <v-icon>mdi-reload</v-icon>
                        </base-btn>
                    </div>
                </v-toolbar>
                <AppWrapper :contentSize="contentSize">
                    <div class="page_wrapper">
                        <router-view
                            @change:content-size="
                                (value) => onSetContentSize(value)
                            "
                            @reset:content-size="onResetContentSize"
                            ref="routerView"
                        />
                    </div>
                    <!-- Go to top -->
                    <btn-scroll-top maxContentSize="100%" />
                </AppWrapper>
            </v-main>
        </div>
    </v-app>
</template>
<script>
import AppToolbar from "../components/app-toolbar.vue";
import AppNavigationDrawer from "../components/app-navigation-drawer.vue";
import BtnScrollTop from "../components/btn-scroll-top.vue";
import BreadCrumb from "../components/BreadCrumb.vue";
import NavigationContent from "../components/navigation-content.vue";
const DEFAULT_CONTENT_SIZE = "l";
export default {
    name: "AppFullscreenLayout",
    components: {
        AppToolbar,
        AppNavigationDrawer,
        BtnScrollTop,
        BreadCrumb,
        NavigationContent
    },
    data() {
        return {
            drawer: {
                show: true
            },
            menu: false,
            showTitle: false,
            contentSize: DEFAULT_CONTENT_SIZE,
            showReloadBtn: false,
            shallShadowBottom: null,
            perfectScrollbarOptions: {
                maxScrollbarLength: 80,
                wheelPropagation: false,
                minScrollbarLength: 20,
                suppressScrollX: true
            }
        };
    },
    methods: {
        onMenuIconClick() {
            this.drawer.show = !this.drawer.show;
        },
        onNavigationItemClick() {
            this.menu = false;
            this.drawer.show = false;
        },
        onResetContentSize() {
            this.contentSize = DEFAULT_CONTENT_SIZE;
        },
        onSetContentSize(newSize) {
            if (newSize) {
                this.contentSize = newSize;
            }
        },
        hasRouterViewChildComponentReload() {
            const routerViewRef = this.$refs.routerView;
            if (routerViewRef) {
                const routerViewRefMethods = routerViewRef.$options?.methods;
                const methodsKeys = Object.keys(routerViewRefMethods);
                if (methodsKeys.includes("reload")) {
                    return true;
                }
            }
            return false;
        },
        onReloadBtnClick() {
            if (!this.hasRouterViewChildComponentReload()) return;
            this.$refs.routerView?.reload();
        }
    },
    created() {
        this.$nextTick(() => {
            this.showReloadBtn = this.hasRouterViewChildComponentReload();
        });
    },
    watch: {
        $route() {
            this.$nextTick(() => {
                this.showReloadBtn = this.hasRouterViewChildComponentReload();
            });
        }
    }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style src="./base.sass" lang="sass" scoped></style>
