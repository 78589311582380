<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./modal-stepper-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="close"
        :title="$t('dialog-title')"
        width="700"
        ref="modalDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        icon="mdi-file-edit"
    >
        <template v-slot:activator="{ on }">
            <base-btn
                color="info"
                dark
                v-on="on"
                height="60"
                block
                tile
                depressed
            >
                <span v-if="$vuetify.breakpoint.smAndDown">
                    {{ $t("register-new-equipment-mv") }}
                </span>
                <span v-else>
                    {{ $t("register-new-equipment") }}
                </span>
            </base-btn>
        </template>
        <template v-slot:content>
            <v-stepper
                v-model="step"
                :vertical="$vuetify.breakpoint.mobile"
                class="elevation-0"
            >
                <app-dev-container>
                    <template v-slot:content>
                        @Address:
                        <tree-view
                            :data="Object.assign({}, registration)"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                        <base-btn
                            tile
                            depressed
                            color="warning"
                            @click="setFakeData"
                        >
                            <v-icon>mdi-bug</v-icon>
                            Set Dummy Data
                        </base-btn>
                        <base-btn icon color="warning" @click="clearTask">
                            <v-icon>mdi-times</v-icon>
                        </base-btn>
                    </template>
                </app-dev-container>
                <template v-if="$vuetify.breakpoint.mobile">
                    <v-stepper-step
                        :editable="step > 1"
                        :complete="step > 1"
                        class="px-0"
                        step="1"
                    >
                        {{ $t("equipment-data") }}
                    </v-stepper-step>
                    <v-stepper-content step="1" class="px-0 mx-3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentEquipmentData
                                    ref="contentEquipmentData"
                                    v-bind="registration"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-step
                        :editable="step > 2"
                        :complete="step > 2"
                        class="px-0"
                        step="2"
                    >
                        {{ $t("object-details") }}
                    </v-stepper-step>

                    <v-stepper-content step="2" class="px-0 mx-3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentObjectDetails
                                    ref="contentObjectDetails"
                                    v-bind="registration"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :editable="step == 3" class="px-0" step="3">
                        {{ $t("deregistration-details") }}
                    </v-stepper-step>
                    <v-stepper-content step="3" class="px-0 mx-3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentDeregistrationDetails
                                    ref="contentDeregistrationDetails"
                                    v-bind="registration"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </template>
                <!-- HORIZONTAL -->
                <template v-else>
                    <v-stepper-header>
                        <v-stepper-step
                            :editable="step > 1"
                            :complete="step > 1"
                            step="1"
                            >{{ $t("equipment-data") }}</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step
                            :editable="step > 2"
                            :complete="step > 2"
                            step="2"
                            >{{ $t("object-details") }}</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step step="3">{{
                            $t("deregistration-details")
                        }}</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card flat>
                                <v-card-text class="pa-0 ma-0">
                                    <ContentEquipmentData
                                        ref="contentEquipmentData"
                                        v-bind="registration"
                                        @value:change="onFieldValueChange"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card flat>
                                <v-card-text class="pa-0 ma-0">
                                    <ContentObjectDetails
                                        ref="contentObjectDetails"
                                        v-bind="registration"
                                        @value:change="onFieldValueChange"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-card flat>
                                <v-card-text class="pa-0 ma-0">
                                    <ContentDeregistrationDetails
                                        ref="contentDeregistrationDetails"
                                        v-bind="registration"
                                        @value:change="onFieldValueChange"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </template>
            </v-stepper>
        </template>
        <template v-slot:actions>
            <base-btn
                v-if="step > 1"
                type="secondary"
                @click="step--"
                :btnIcon="vertical ? 'mdi-arrow-up' : 'mdi-arrow-left'"
            >
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-back") }}
                </span>
            </base-btn>
            <v-spacer />
            <base-btn
                v-if="step !== 3"
                @click="submit"
                type="primary"
                :btnIcon="vertical ? 'mdi-arrow-down' : 'mdi-arrow-right'"
            >
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-continue") }}
                </span>
            </base-btn>
            <base-btn v-if="step === 3" type="save" @click="submit">
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-create") }}
                </span>
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
import { BaseDeregistration as RegistrationDeregistration } from "@/models/tasks";
import { ROLE_TYPES, OBJECT_TYPES, PROCESS_TYPES } from "@/data/globals.js";
import ContentEquipmentData from "./content-equipment-data.vue";
import ContentDeregistrationDetails from "./content-deregistration-details.vue";
import ContentObjectDetails from "./content-object-details.vue";
export default {
    components: {
        ContentEquipmentData,
        ContentDeregistrationDetails,
        ContentObjectDetails
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            registration: new RegistrationDeregistration(),
            step: 1,
            vertical: false,
            dialog: false
        };
    },
    methods: {
        close(close) {
            this.$emit("input", close);
        },
        onFieldValueChange({ field, value }) {
            if (!field) return;
            this.registration[field] = value;
        },
        reset() {
            this.step = 1;
            this.clearTask();
        },
        clearTask() {
            this.registration = new RegistrationDeregistration();
            delete this.registration.referenceId;
            delete this.registration.step;
            delete this.registration.docId;
            this.registration.process = PROCESS_TYPES.DEREGISTRATION;
        },

        setFakeData() {
            this.step = 3;
            this.registration.role = ROLE_TYPES.OPERATOR;
            this.registration.process = PROCESS_TYPES.DEREGISTRATION;
            this.registration.objectType = OBJECT_TYPES.PRESSURE_EQUIPMENT;
            this.registration.operatorName = "WAS.ch gmbH";
            this.registration.factoryNumber = "238832424-34234";
            this.registration.constructionYear = 2017;
            this.registration.sapEquipmentNr = "1234567";
            this.registration.reason = "SCRAPPED";
            this.registration.targetDate = "2021-12-15";
            this.registration.newAddress = {};
            this.registration.registrantNote = "Blah blah blah blha";
        },
        getScrollOptions() {
            let baseDialog = this.$refs.modalDialog;
            let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
            return {
                container: baseDialogCardTextRef
            };
        },
        submit() {
            let scrollOptions = this.getScrollOptions();
            if (this.step == 1) {
                this.$refs.contentEquipmentData.validate();
                if (this.$refs.contentEquipmentData.isValid()) {
                    this.step = 2;
                    this.$refs.contentEquipmentData.reset();
                } else {
                    this.$refs.contentEquipmentData.scrollToFirstError(
                        scrollOptions
                    );
                }
                return;
            }
            if (this.step == 2) {
                this.$refs.contentObjectDetails.validate();
                if (this.$refs.contentObjectDetails.isValid()) {
                    this.step = 3;
                    this.$refs.contentObjectDetails.reset();
                } else {
                    this.$refs.contentObjectDetails.scrollToFirstError(
                        scrollOptions
                    );
                }
                return;
            }
            if (this.step == 3) {
                this.$refs.contentDeregistrationDetails.validate();
                if (this.$refs.contentDeregistrationDetails.isValid()) {
                    this.loader().show();
                    let data = this.registration;
                    this.$store
                        .dispatch("tasks/createByProcessType", data)
                        .then((response) => {
                            let status = response.status;
                            if (status === 200) {
                                this.$toast.success(
                                    this.$t("submit-response-msg-success")
                                );
                                this.onComplete();
                                this.$refs.contentDeregistrationDetails.reset();
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.error(
                                this.$t("submit-response-msg-error")
                            );
                            this.loader().hide();
                        });
                } else {
                    this.$refs.contentDeregistrationDetails.scrollToFirstError(
                        scrollOptions
                    );
                }
            }
        },
        onComplete() {
            this.close();
            this.$nextTick(() => {
                this.reset();
            });
        }
    },
    watch: {
        "$vuetify.breakpoint.mobile"() {
            this.step = 2;
            requestAnimationFrame(() => (this.step = 1)); // Workarounds
        },
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    },

    created() {
        this.clearTask();
    }
};
</script>
