<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./dialog-complete-registration-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="
            backoffice.duplicateDecision
                ? $t('title', {
                      type: $t(
                          `duplicate-decision-${backoffice.duplicateDecision}`
                      )
                  })
                : null
        "
        max-width="600"
        icon="mdi-alert"
        ref="baseDialog"
    >
        <template v-slot:content>
            <v-card-title
                class="text-h6 font-weight-regular justify-space-between"
            >
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="primary lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
            </v-card-title>

            <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12" xs="6">
                                <WSapEquipmentNr
                                    v-model="sapEquipmentNr"
                                    :disabled="readonlyModus"
                                    :error-messages="sapEquipmentNrErrors"
                                    :counter="
                                        $v.sapEquipmentNr.$params.maxLength.max
                                    "
                                    :label="$t('sap-equipment-nr-label')"
                                    :required="
                                        isRequiredField($v.sapEquipmentNr)
                                    "
                                ></WSapEquipmentNr>
                            </v-col>
                            <v-col cols="12" xs="6">
                                <SuvaPartnerFinder
                                    v-if="uid"
                                    :uid="uid"
                                    :suvaPartnerFinder="suvaPartnerFinder"
                                    @change="suvaPartnerFinder = $event"
                                >
                                </SuvaPartnerFinder>
                                <base-alert v-else type="info" dense>
                                    {{ $t("info-msg-no-uid") }}
                                </base-alert>
                            </v-col>
                            <v-col cols="12">
                                <!-- reportingToSuva -->
                                <base-radio-group
                                    v-model="reportingToSuva"
                                    :required="
                                        isRequiredField($v.reportingToSuva)
                                    "
                                    :label="$t('reporting-to-suva-label')"
                                    :error-messages="reportingToSuvaErrors"
                                    dense
                                >
                                    <v-radio
                                        :label="$t('reporting-to-suva-yes')"
                                        :value="true"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('reporting-to-suva-no')"
                                        :value="false"
                                    ></v-radio>
                                </base-radio-group>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <base-alert type="info">
                                    <i18n
                                        path="total-chambers-volume"
                                        tag="span"
                                    >
                                        <template #volume>
                                            <b>{{ totalChambersVolume }}</b>
                                        </template>
                                    </i18n>
                                </base-alert>
                            </v-col>
                            <v-col cols="12">
                                <InsurerAlertMsg
                                    v-if="registrationData.insurer"
                                    :insurer="registrationData.insurer"
                                />
                            </v-col>
                            <!-- Objekt-Status -->
                            <v-col cols="12">
                                <base-autocomplete
                                    v-model="objectStatus"
                                    :items="sortedObjectStatusItems"
                                    :label="$t('object-status-label')"
                                    item-value="key"
                                    clearable
                                    :filter="customFilter"
                                    :error-messages="objectStatusErrors"
                                    :required="isRequiredField($v.objectStatus)"
                                >
                                    <template v-slot:item="{ item }">
                                        <span v-if="item.value[$i18n.locale]">
                                            <b>{{ item.key }}</b
                                            >:
                                            {{ item.value[$i18n.locale] }}
                                        </span>
                                        <span v-else>
                                            <b>{{ item.key }}</b
                                            >: {{ item.value.de }}
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <!-- use vuetify class to break the text if is too large  -->
                                        <div
                                            class="
                                                v-select__selection
                                                v-select__selection--comma
                                            "
                                        >
                                            <span
                                                v-if="item.value[$i18n.locale]"
                                            >
                                                <b>{{ item.key }}</b
                                                >:
                                                {{ item.value[$i18n.locale] }}
                                            </span>
                                            <span v-else>
                                                <b>{{ item.key }}</b
                                                >: {{ item.value.de }}
                                            </span>
                                        </div>
                                    </template>
                                </base-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="objectStatus">
                                <base-autocomplete
                                    :items="sortedObjectCodeItems"
                                    v-model="objectCode"
                                    :label="$t('object-code-label')"
                                    item-value="key"
                                    clearable
                                    :filter="customFilter"
                                    :error-messages="objectCodeErrors"
                                    :required="isRequiredField($v.objectCode)"
                                    :item-text="
                                        (item) =>
                                            item.value[$i18n.locale]
                                                ? item.value[$i18n.locale]
                                                : item.value.de
                                    "
                                >
                                </base-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="objectStatus && objectCode">
                                <base-select
                                    :items="objectCategoryItems"
                                    v-model="objectCategory"
                                    :label="$t('object-category-label')"
                                    clearable
                                    :required="
                                        isRequiredField($v.objectCategory)
                                    "
                                    @change="onObjectCategoryChange"
                                    :error-messages="objectCategoryErrors"
                                    :item-text="
                                        (item) =>
                                            $t(
                                                `task-object-category-${FILTERS.kebabizeLower(
                                                    item.value
                                                )}`
                                            )
                                    "
                                >
                                </base-select>
                            </v-col>
                            <!-- Grössenklasse: 01-12 -->
                            <v-col
                                cols="12"
                                v-if="
                                    objectCategory && objectStatus && objectCode
                                "
                            >
                                <base-autocomplete
                                    v-model="sizeClass"
                                    :items="sizeClassItems"
                                    :label="$t('size-class-label')"
                                    item-value="id"
                                    clearable
                                    :filter="customFilter"
                                    :error-messages="sizeClassErrors"
                                    :required="isRequiredField($v.sizeClass)"
                                >
                                    <template v-slot:item="{ item }">
                                        <i18n
                                            path="size-class-text-00"
                                            tag="span"
                                            v-if="
                                                (item.range[0] === 0) &&
                                                item.range[1]
                                            "
                                        >
                                            <template #value>
                                                <b>{{ item.id }}</b>
                                            </template>
                                            <template #range2>
                                                <b>{{ item.range[1] }}</b>
                                            </template>
                                        </i18n>
                                        <i18n
                                            path="size-class-text-01"
                                            tag="span"
                                            v-if="
                                                item.range[0] &&
                                                item.range[1]
                                            "
                                        >
                                            <template #value>
                                                <b>{{ item.id }}</b>
                                            </template>
                                            <template #range1>
                                                <b>{{ item.range[0] }}</b>
                                            </template>
                                            <template #range2>
                                                <b>{{ item.range[1] }}</b>
                                            </template>
                                        </i18n>
                                        <i18n
                                            path="size-class-text-02"
                                            tag="span"
                                            v-if="
                                                item.range[0] &&
                                                !item.range[1]
                                            "
                                        >
                                            <template #value>
                                                <b>{{ item.id }}</b>
                                            </template>
                                            <template #range1>
                                                <b>{{ item.range[0] }}</b>
                                            </template>
                                        </i18n>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <!-- use vuetify class to break the text if is too large  -->
                                        <div
                                            class="
                                                v-select__selection
                                                v-select__selection--comma
                                            "
                                        >
                                            <i18n
                                                path="size-class-text-00"
                                                tag="span"
                                                v-if="
                                                    (item.range[0] === 0) &&
                                                    item.range[1]
                                                "
                                            >
                                                <template #value>
                                                    <b>{{ item.id }}</b>
                                                </template>
                                                <template #range2>
                                                    <b>{{ item.range[1] }}</b>
                                                </template>
                                            </i18n>
                                            <i18n
                                                path="size-class-text-01"
                                                tag="span"
                                                v-if="
                                                    item.range[0] &&
                                                    item.range[1]
                                                "
                                            >
                                                <template #value>
                                                    <b>{{ item.id }}</b>
                                                </template>
                                                <template #range1>
                                                    <b>{{ item.range[0] }}</b>
                                                </template>
                                                <template #range2>
                                                    <b>{{ item.range[1] }}</b>
                                                </template>
                                            </i18n>
                                            <i18n
                                                path="size-class-text-02"
                                                tag="span"
                                                v-if="
                                                    item.range[0] &&
                                                    !item.range[1]
                                                "
                                            >
                                                <template #value>
                                                    <b>{{ item.id }}</b>
                                                </template>
                                                <template #range1>
                                                    <b>{{ item.range[0] }}</b>
                                                </template>
                                            </i18n>
                                        </div>
                                    </template>
                                </base-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                    <v-card-text>
                        <base-alert
                            v-if="step === 3"
                            type="warning"
                            dense
                            class="mt-2"
                        >
                            {{ $t("warning-msg") }}
                        </base-alert>
                        <v-row dense>
                            <!-- Inspektionskreis -->
                            <v-col cols="12" :sm="6">
                                <base-autocomplete
                                    v-model="regionCode"
                                    :items="regionCodeItems"
                                    :label="$t('region-code-label')"
                                    clearable
                                    :error-messages="regionCodeErrors"
                                    :required="isRequiredField($v.regionCode)"
                                    :item-text="(item) => item.value"
                                    :hint="
                                        $t('region-code-hint', {
                                            minValue: '01',
                                            maxValue:
                                                $v.regionCode.$params.maxValue
                                                    .max
                                        })
                                    "
                                >
                                </base-autocomplete>
                            </v-col>
                            <!-- Inspektions-Sub-Kreis -->
                            <v-col cols="12" :sm="6">
                                <base-text-field
                                    v-model="subCircleCode"
                                    :label="$t('sub-circle-code-label')"
                                    clearable
                                    :error-messages="subCircleCodeErrors"
                                    :required="
                                        isRequiredField($v.subCircleCode)
                                    "
                                    :hint="
                                        $t('sub-circle-code-hint', {
                                            minValue:
                                                $v.subCircleCode.$params
                                                    .minValue.min,
                                            maxValue:
                                                $v.subCircleCode.$params
                                                    .maxValue.max
                                        })
                                    "
                                >
                                </base-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-window-item>
            </v-window>
        </template>
        <template v-slot:actions>
            <base-btn v-if="step === 1" type="close" @click="closeDialog">
                {{ $t("btn-close") }}
            </base-btn>
            <base-btn v-if="step > 1" type="secondary" @click="step--">
                {{ $t("btn-back") }}
            </base-btn>
            <v-spacer></v-spacer>
            <base-btn
                v-if="step !== 3"
                type="primary"
                @click="incrementStepIfValid"
            >
                {{ $t("btn-continue") }}
            </base-btn>
            <base-btn
                v-if="!readonlyModus && step === 3"
                type="save"
                @click="onComplete"
                >{{ $t("btn-complete") }}</base-btn
            >
        </template>
    </base-dialog>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import {
    required,
    minLength,
    maxLength,
    maxValue,
    minValue,
    numeric
} from "vuelidate/lib/validators";
import { sortAttributesAlphabetically } from "@/utils";
import _ from "lodash";
import SuvaPartnerFinder from "@/components/tasks/common/suva-partner-finder.vue";
import validateMixin from "@/mixins/validate";
import { FILTERS } from "@/filters";
import {
    OBJECT_CATEGORIES,
    SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY
} from "@/data/globals.js";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
import * as ACTION_TYPES from "@/store/types/action-types";
export default {
    name: "DialogCompleteRegistration",

    components: {
        SuvaPartnerFinder,
        InsurerAlertMsg: () =>
            import("@/components/tasks/common/insurer-alert-msg.vue")
    },

    mixins: [validateMixin],

    props: {
        value: {
            value: Boolean,
            default: false
        },
        registrationData: {
            value: Object,
            default: () => {}
        },
        backoffice: {
            value: Object,
            default: () => {}
        },
        readonlyModus: {
            value: Boolean,
            default: false
        }
    },

    validations() {
        return {
            reportingToSuva: { required },
            objectStatus: { required },
            objectCode: { required },
            objectCategory: { required },
            sizeClass: { required },
            regionCode: {
                minLength: minLength(2),
                maxValue: maxValue(Number(this.maxRegionCode))
            },
            subCircleCode: {
                minValue: minValue(1),
                maxValue: maxValue(99999),
                numeric
            },
            sapEquipmentNr: {
                required,
                minLength: minLength(7),
                maxLength: maxLength(7)
            },
            step1: ["reportingToSuva", "sapEquipmentNr"],
            step2: [
                "objectStatus",
                "objectCode",
                "objectCategory",
                "sizeClass"
            ],
            step3: ["regionCode", "subCircleCode"]
        };
    },

    data: () => ({
        maxRegionCode: 33,
        FILTERS,
        dialog: false,
        objectStatusItems: [],
        objectCodeItems: [],
        objectCategoryItems: [
            {
                value: OBJECT_CATEGORIES.STEAM_AND_HW_BOILERS
            },
            {
                value: OBJECT_CATEGORIES.ELECTRIC_BOILER
            },
            {
                value: OBJECT_CATEGORIES.PRESSURE_TANK
            }
        ],
        heatingSurfaceOptions: {
            minimumValue: "0"
        },
        step: 1,
        subCircleCodeItems: [],
        sizeClassItems: []
    }),

    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        ...mapMutations("backofficeRegistration", [
            MUTATION_TYPES.UPDATE_BACKOFFICE_FIELD,
            MUTATION_TYPES.UPDATE_REGISTRATION_FIELD
        ]),
        ...mapActions("backofficeRegistration", [
            ACTION_TYPES.AUTO_SAVE_REGISTRATION
        ]),
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        onFieldChange(field, value) {
            this[MUTATION_TYPES.UPDATE_REGISTRATION_FIELD]({
                field,
                value
            });
            this[ACTION_TYPES.AUTO_SAVE_REGISTRATION]();
        },
        onFieldBackofficeChange(field, value) {
            this[MUTATION_TYPES.UPDATE_BACKOFFICE_FIELD]({
                field,
                value
            });
            this[ACTION_TYPES.AUTO_SAVE_REGISTRATION]();
        },
        scrollToFirstErrorWithScrollOptions() {
            let baseDialog = this.$refs.baseDialog;
            let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
            let scrollOptions = {
                container: baseDialogCardTextRef
            };
            this.scrollToFirstError(scrollOptions);
        },
        onComplete() {
            this.validate();
            if (this.isValid()) {
                this.$emit("complete");
            } else {
                this.scrollToFirstErrorWithScrollOptions();
                this.$toast.error(this.$t("has-errors-msg"));
            }
        },
        customFilter(item, queryText) {
            const locale = this.$i18n.locale || "de";
            const itemValue = item.value[locale]
                ? item.value[locale]
                : item.value["de"];
            const itemKey = item.key.toLowerCase();
            const itemValueText = itemValue.toLowerCase();
            const searchText = queryText.toLowerCase();
            return (
                itemValueText.indexOf(searchText) > -1 ||
                itemKey.indexOf(searchText) > -1
            );
        },
        fetchObjectCodeItems() {
            let objectCodeKey = "objektcode";
            this.loader().show();
            this.getStoredAttributesByKey(objectCodeKey)
                .then((items) => {
                    this.objectCodeItems = items;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("object-code")
                        })
                    );
                });
        },
        fetchObjectStatusItems() {
            let objectStatusKey = "objektstatus";
            this.loader().show();
            this.getStoredAttributesByKey(objectStatusKey)
                .then((items) => {
                    this.objectStatusItems = items;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("object-status")
                        })
                    );
                });
        },
        onObjectCategoryChange(value) {
            if (value) {
                this.setSizeClassItemsByObjectCategory(value);
                this.setSizeClassValueByRangeValue(this.totalChambersVolume);
            } else {
                this.sizeClass = null;
            }
        },
        setSizeClassValueByRangeValue(value) {
            if (_.isNaN(value) || _.isNil(value) || value === "") {
                return;
            }
            value = _.toNumber(value);

            for (let i = 0; i < this.sizeClassItems.length; i++) {
                let item = this.sizeClassItems[i];
                let range = item.range;
                if (range) {
                    let inRange = false;
                    if (range.length === 2) {
                        if (value >= range[0] && value <= range[1]) {
                            inRange = true;
                        }
                    }
                    if (range.length === 1) {
                        if (value > range[0]) {
                            inRange = true;
                        }
                    }
                    if (inRange) {
                        return (this.sizeClass = item.id);
                    }
                }
            }
        },
        validateStep(step) {
            this.$v[`step${step}`].$touch();
        },
        isStepValid(step) {
            const validateObj = this.$v[`step${step}`];
            if (!validateObj.$anyDirty) {
                return false;
            } else {
                if (validateObj.$anyError) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        isStepValidLazy(step) {
            const validateObj = this.$v[`step${step}`];
            if (validateObj.$invalid) {
                return false;
            } else {
                return true;
            }
        },
        incrementStepIfValid() {
            this.validateStep(this.step);
            if (this.isStepValid(this.step)) {
                return this.step++;
            }
            this.$toast.error(this.$t("has-errors-msg"));
        },
        checkIsNeedToJumpSteps() {
            for (let step = 1; step < 3; step++) {
                const isValid = this.isStepValidLazy(step);
                if (!isValid) break;
                this.step++;
            }
        },
        setSizeClassItemsByObjectCategory(objectCategory) {
            if (objectCategory) {
                this.sizeClassItems =
                    SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY(objectCategory);
            }
        }
    },

    computed: {
        regionCodeItems() {
            let regionCodes = [];
            for (
                let regionCode = 1;
                regionCode <= this.maxRegionCode;
                regionCode++
            ) {
                let code = regionCode < 10 ? "0" + regionCode : regionCode;
                regionCodes.push({
                    value: String(code)
                });
            }
            return regionCodes;
        },
        currentTitle() {
            switch (this.step) {
                case 1:
                    return this.$t("title-step-1");
                case 2:
                    return this.$t("title-step-2");
                default:
                    return this.$t("title-step-3");
            }
        },
        totalChambersVolume() {
            let volume = 0;
            if (!this.registrationData) return parseFloat(volume).toFixed(2);
            let chambers = this.registrationData.chambers || [];
            if (chambers.length > 0) {
                chambers.forEach((value) => {
                    volume = volume + parseFloat(value.volume);
                });
                return parseFloat(volume).toFixed(2);
            } else {
                return parseFloat(volume).toFixed(2);
            }
        },
        reportingToSuva: {
            get() {
                return this.backoffice?.reportingToSuva;
            },
            set(value) {
                this.onFieldBackofficeChange("reportingToSuva", value);
            }
        },
        sapEquipmentNr: {
            get() {
                return this.backoffice?.sapEquipmentNr;
            },
            set(value) {
                this.onFieldBackofficeChange("sapEquipmentNr", value);
            }
        },
        objectStatus: {
            get() {
                return this.backoffice?.objectStatus;
            },
            set(value) {
                this.onFieldBackofficeChange("objectStatus", value);
            }
        },
        sizeClass: {
            get() {
                return this.backoffice?.sizeClass;
            },
            set(value) {
                this.onFieldBackofficeChange("sizeClass", value);
            }
        },
        objectCode: {
            get() {
                return this.backoffice?.objectCode;
            },
            set(value) {
                this.onFieldBackofficeChange("objectCode", value);
            }
        },
        objectCategory: {
            get() {
                return this.backoffice?.objectCategory;
            },
            set(value) {
                this.onFieldBackofficeChange("objectCategory", value);
            }
        },
        suvaPartnerFinder: {
            get() {
                return this.registrationData?.suvaPartnerFinder;
            },
            set(value) {
                this.onFieldChange("suvaPartnerFinder", value);
            }
        },
        regionCode: {
            get() {
                return this.backoffice?.regionCode;
            },
            set(value) {
                this.onFieldBackofficeChange("regionCode", value);
            }
        },
        subCircleCode: {
            get() {
                return this.backoffice?.subCircleCode;
            },
            set(value) {
                this.onFieldBackofficeChange("subCircleCode", value);
            }
        },
        reportingToSuvaErrors() {
            const errors = [];
            if (!this.$v.reportingToSuva.$dirty) {
                return errors;
            }
            if (!this.$v.reportingToSuva.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        sapEquipmentNrErrors() {
            const errors = [];
            if (!this.$v.sapEquipmentNr.$dirty) {
                return errors;
            }
            if (!this.$v.sapEquipmentNr.required) {
                errors.push(this.$t("field-required"));
            }
            if (
                !this.$v.sapEquipmentNr.minLength ||
                !this.$v.sapEquipmentNr.maxLength
            ) {
                errors.push(
                    this.$t("field-equipment-id-length", {
                        length: this.sapEquipmentNr.length
                    })
                );
            }
            return errors;
        },
        objectStatusErrors() {
            const errors = [];
            if (!this.$v.objectStatus.$dirty) {
                return errors;
            }
            if (!this.$v.objectStatus.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        objectCodeErrors() {
            const errors = [];
            if (!this.$v.objectCode.$dirty) {
                return errors;
            }
            if (!this.$v.objectCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        objectCategoryErrors() {
            const errors = [];
            if (!this.$v.objectCategory.$dirty) {
                return errors;
            }
            if (!this.$v.objectCategory.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        sizeClassErrors() {
            const errors = [];
            if (!this.$v.sizeClass.$dirty) {
                return errors;
            }
            if (!this.$v.sizeClass.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        regionCodeErrors() {
            const errors = [];
            if (!this.$v.regionCode.$dirty) {
                return errors;
            }
            if (!this.$v.regionCode.minLength) {
                errors.push(
                    this.$t("field-min-length", {
                        length: this.regionCode.length
                    })
                );
            }
            if (!this.$v.regionCode.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.regionCode.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        subCircleCodeErrors() {
            const errors = [];
            if (!this.$v.subCircleCode.$dirty) {
                return errors;
            }
            if (!this.$v.subCircleCode.numeric) {
                errors.push(this.$t("field-only-numerics"));
            }
            if (!this.$v.subCircleCode.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.subCircleCode.$params.minValue.min
                    })
                );
            }
            if (!this.$v.subCircleCode.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.subCircleCode.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        sortedObjectStatusItems() {
            if (this.objectStatusItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            // [1]- IBB
            // [2]- IIE
            // [3]- NNU
            // [..]- rest
            let ret = [];
            const sorted = sortAttributesAlphabetically(
                this.objectStatusItems,
                locale
            );
            const ibb = _.find(this.objectStatusItems, ["key", "IBB"]);
            if (ibb) {
                ret.push(ibb);
            }
            const iie = _.find(this.objectStatusItems, ["key", "IIE"]);
            if (iie) {
                ret.push(iie);
            }
            const nnu = _.find(this.objectStatusItems, ["key", "NNU"]);
            if (nnu) {
                ret.push(nnu);
            }
            return _.union(ret, sorted);
        },
        sortedObjectCodeItems() {
            if (this.objectCodeItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(this.objectCodeItems, locale);
        },
        uid() {
            if (!this.registrationData?.operatorAddress?.uid) return null;
            return this.registrationData.operatorAddress.uid;
        }
    },

    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
                this.fetchObjectCodeItems();
                this.fetchObjectStatusItems();
                if (newValue) {
                    this.checkIsNeedToJumpSteps();
                }
            },
            immediate: true
        },
        objectCategory: {
            handler: function (newValue) {
                if (newValue) {
                    this.setSizeClassItemsByObjectCategory(newValue);
                }
            },
            immediate: true
        }
    }
};
</script>
