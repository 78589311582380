import { render, staticRenderFns } from "./messages-card.vue?vue&type=template&id=76942984&scoped=true&"
import script from "./messages-card.vue?vue&type=script&lang=js&"
export * from "./messages-card.vue?vue&type=script&lang=js&"
import style0 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=0&lang=css&"
import style1 from "./messages-card.styl?vue&type=style&index=1&id=76942984&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76942984",
  null
  
)

/* custom blocks */
import block0 from "./messages-card-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fdialogs%2Fcomponents%2Fmessages-card.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardText,VDivider,VExpandTransition,VSwitch})
