<template>
    <v-card outlined class="mx-auto">
        <div class="overline pl-2" v-if="overtitle">
            {{ overtitle }}
        </div>
        <v-card-text class="py-0 my-0">
            <v-text-field v-model="color" hide-detals class="ma-0 pa-0" solo>
                <template v-slot:append>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-color-picker
                            value="#7417BE"
                            v-model="color"
                            hide-canvas
                            hide-inputs
                            show-swatches
                            class="mx-auto"
                        >
                        </v-color-picker>
                    </v-menu>
                </template>
            </v-text-field>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "ColorPicker",
    props: ["value", "overtitle"],
    data: () => ({
        menu: false
    }),
    computed: {
        color: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        swatchStyle() {
            const { color, menu } = this;
            return {
                backgroundColor: color,
                cursor: "pointer",
                height: "30px",
                width: "30px",
                borderRadius: menu ? "50%" : "4px",
                border: "1px solid black",
                transition: "border-radius 200ms ease-in-out"
            };
        }
    },
    watch: {
        value(val) {
            this.color = val;
        }
    }
};
</script>
