<i18n src="./navigation-content-i18n.yaml"></i18n>
<template>
    <v-container class="pa-0 ma-0">
        <v-row v-if="!isLoading">
            <v-col cols="12">
                <v-list expand flat>
                    <template v-for="item in menuItems">
                        <!--group with subitems-->
                        <v-list-group
                            :key="item.title"
                            :group="item.group"
                            no-action
                            :value="item.meta.open"
                            v-if="item.items && isShowAllowed(item.meta)"
                        >
                            <template v-slot:prependIcon>
                                <v-badge
                                    v-if="
                                        item.meta &&
                                        item.meta.badge &&
                                        (item.icon || item.svgIcon)
                                    "
                                    :content="
                                        getBadgeValueById(item.meta.badgeId)
                                    "
                                    color="green"
                                >
                                    <v-icon
                                        v-if="item.icon"
                                        v-html="item.icon"
                                        color="regOfficePrimary"
                                    ></v-icon>
                                    <w-icon
                                        v-if="item.svgIcon"
                                        :data="
                                            require(`@/assets/icons/${item.svgIcon}`)
                                        "
                                        :color="
                                            item.svgColor
                                                ? item.svgColor
                                                : 'regOfficePrimary'
                                        "
                                    ></w-icon>
                                </v-badge>
                                <v-icon
                                    v-else
                                    color="regOfficePrimary"
                                    v-html="item.icon"
                                ></v-icon>
                            </template>
                            <template v-slot:activator>
                                <v-list-item class="pl-0">
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            $t(item.title)
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-for="subItem in item.items">
                                <!-- v-else -->
                                <v-list-item
                                    :key="subItem.title"
                                    :to="genChildTarget(item, subItem)"
                                    :disabled="subItem.disabled"
                                    @click="onNavigationItemClick"
                                    ripple
                                >
                                    <v-list-item-icon>
                                        <v-badge
                                            v-if="
                                                subItem.meta &&
                                                subItem.meta.badge &&
                                                (subItem.icon ||
                                                    subItem.svgIcon)
                                            "
                                            :content="
                                                getBadgeValueById(
                                                    subItem.meta.badgeId
                                                )
                                            "
                                            color="green"
                                        >
                                            <v-icon
                                                v-html="subItem.icon"
                                                color="regOfficePrimary"
                                            ></v-icon>
                                            <w-icon
                                                v-if="subItem.svgIcon"
                                                :data="
                                                    require(`@/assets/icons/${subItem.svgIcon}`)
                                                "
                                                :color="
                                                    subItem.svgColor
                                                        ? subItem.svgColor
                                                        : 'regOfficePrimary'
                                                "
                                                width="20"
                                                height="20"
                                            ></w-icon>
                                        </v-badge>
                                        <v-icon
                                            v-else-if="subItem.icon"
                                            v-html="subItem.icon"
                                            color="regOfficePrimary"
                                        ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span>{{ $t(subItem.title) }}</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="subItem.action">
                                        <v-icon color="regOfficePrimary">{{
                                            subItem.action
                                        }}</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list-group>
                        <v-subheader
                            v-else-if="
                                (item.header &&
                                    item.meta &&
                                    isShowAllowed(item.meta)) ||
                                (item.header && !item.meta)
                            "
                            :key="item.name"
                        >
                            {{ $t(item.header) }}
                        </v-subheader>
                        <v-divider
                            v-else-if="
                                (item.divider &&
                                    item.meta &&
                                    isShowAllowed(item.meta)) ||
                                (item.divider && !item.meta)
                            "
                            :key="item.name"
                        >
                        </v-divider>
                        <!-- LINKS -->
                        <v-list-group
                            v-else-if="item.links"
                            :key="item.name"
                            no-action
                            :value="false"
                            color="regOfficePrimary"
                        >
                            <template v-slot:prependIcon>
                                <v-icon
                                    v-html="item.icon"
                                    color="regOfficePrimary"
                                ></v-icon>
                            </template>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        $t(item.title)
                                    }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item
                                v-for="(link, linkId) in item.links"
                                :key="linkId"
                                ripple
                                link
                                @click="
                                    onOpenLink(link[currentLang.toUpperCase()])
                                "
                            >
                                <v-list-item-icon>
                                    <v-icon color="regOfficePrimary"
                                        >mdi-open-in-new</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        $t(linkId)
                                    }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <!-- DIALOGS -->
                        <v-list-item
                            v-else-if="
                                item.meta &&
                                item.meta.isDialog &&
                                isShowAllowed(item.meta)
                            "
                            :key="item.name"
                            ripple
                            link
                            :disabled="item.disabled"
                            @click="onOpenDialog(item.name)"
                        >
                            <v-list-item-action v-if="item.icon">
                                <DevBadge
                                    v-if="
                                        item.meta &&
                                        item.meta.isDeveloperMode &&
                                        developerMode
                                    "
                                    :icon="item.icon"
                                />
                                <v-icon v-else color="regOfficePrimary">{{
                                    item.icon
                                }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t(item.title)
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!--top-level link-->
                        <!-- v-else -->
                        <v-list-item
                            v-else-if="
                                (item.meta && isShowAllowed(item.meta)) ||
                                !item.meta
                            "
                            :to="!item.href ? { name: item.name } : null"
                            :disabled="item.disabled"
                            :key="item.title"
                            ripple
                            link
                            @click="onNavigationItemClick"
                        >
                            <v-list-item-action v-if="item.icon">
                                <DevBadge
                                    v-if="
                                        item.meta &&
                                        item.meta.isDeveloperMode &&
                                        developerMode
                                    "
                                    :icon="item.icon"
                                />
                                <v-icon v-else color="regOfficePrimary">{{
                                    item.icon
                                }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t(item.title)
                                }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.subAction">
                                <v-icon color="regOfficePrimary">{{
                                    item.subAction
                                }}</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
        </v-row>
        <v-row v-else no-gutters>
            <v-col v-for="item in 5" :key="item" cols="12">
                <v-sheet class="pa-1">
                    <v-skeleton-loader
                        class="mx-auto"
                        max-width="300"
                        height="100"
                        type="list-item-avatar"
                    ></v-skeleton-loader>
                </v-sheet>
            </v-col>
        </v-row>
        <DialogExternalLink v-model="dialogExternalLink" :link="clickedLink" />
        <DialogAboutApp v-model="dialogInfo" />
        <ModalSettings v-model="dialogSettings" />
        <DialogImpressum v-model="dialogImpressum" />
    </v-container>
</template>
<script>
import DialogExternalLink from "@/components/general/dialog-external-link.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { publicRoute, protectedRoute } from "@/router/config";
import DialogAboutApp from "@/components/general/dialog-about-app.vue";
import ModalSettings from "@/components/general/modal-settings.vue";
import DialogImpressum from "@/components/general/dialog-impressum.vue";
import menu from "@/utils/menu.js";
import { ACCOUNT_ROLE } from "@/data/globals.js";
import _ from "lodash";
import * as ACTION_TYPES from "@/store/types/action-types";
export default {
    name: "AppNavigationContent",
    components: {
        DialogExternalLink,
        DialogAboutApp,
        ModalSettings,
        DialogImpressum,
        DevBadge: () => import("./dev-badge.vue")
    },
    data: () => ({
        item: 1,
        dialogExternalLink: false,
        dialogInfo: false,
        dialogSettings: false,
        dialogImpressum: false,
        clickedLink: null,
        menuItems: menu,
        isLoading: false
    }),
    computed: {
        ...mapGetters({
            isAuth: "auth/isAuthenticated",
            developerMode: "settings/developerMode",
            isUserDevolper: "auth/isUserDevolper",
            isUserBackoffice: "auth/isUserBackoffice",
            isUserInsurer: "auth/isUserInsurer"
        }),
        ...mapState("auth", {
            userRoles: "roles"
        }),
        navigationItems() {
            return publicRoute.concat(protectedRoute);
        },
        registrationsListPendingPool() {
            return (
                this.$store.state.backoffice.counters
                    .registrationsPendingPool || 0
            );
        },
        registrationsListNewReplies() {
            return (
                this.$store.state.backoffice.counters.registrationsNewReplies ||
                0
            );
        },
        registrationsListOverdueRegistrations() {
            return (
                this.$store.state.backoffice.counters
                    .registrationsOverdueRegistrations || 0
            );
        },
        registrationsListOverdueQuestions() {
            return (
                this.$store.state.backoffice.counters
                    .registrationsOverdueQuestions || 0
            );
        },
        registrationsListOpenRegistrations() {
            return (
                this.$store.state.backoffice.counters
                    .registrationsOpenRegistrations || 0
            );
        },
        boTasksReadyForSapExport() {
            return (
                this.$store.state.backoffice.counters
                    .boTasksReadyForSapExport || 0
            );
        },
        allTasksReadyForSapExport() {
            return (
                this.$store.state.backoffice.counters
                    .allTasksReadyForSapExport || 0
            );
        },
        totalCountAllRegistrationsLists() {
            return (
                this.registrationsListPendingPool +
                    this.registrationsListOverdueRegistrations || 0
            );
        },
        totalCountMyRegistrationsLists() {
            return (
                this.registrationsListNewReplies +
                    this.registrationsListOverdueQuestions +
                    this.registrationsListOpenRegistrations || 0
            );
        },
        tasksTotalCount() {
            return this.$store.state.backoffice.counters.tasksTotalCount || 0;
        },
        isBackofficeRoute() {
            return this.$route.meta.isBackoffice || false;
        },
        currentLang() {
            return this.$root.$i18n.locale || "de";
        },
        totalCountAllInsurerTasksLists() {
            return this.approvalRequiredPoolTasks;
        },
        approvalRequiredPoolTasks() {
            return (
                this.$store.state.insurer?.counters
                    ?.approvalRequiredPoolTasks || 0
            );
        }
    },
    methods: {
        ...mapActions("insurer", [ACTION_TYPES.FETCH_DASHBOARD_COUNTERS_DATA]),
        onNavigationItemClick() {
            this.$emit("navigation-item-click");
        },
        onOpenLink(link) {
            this.clickedLink = link;
            this.dialogExternalLink = true;
            this.onNavigationItemClick();
        },
        genChildTarget(item, subItem) {
            if (subItem.href) return;
            if (subItem.component) {
                return {
                    name: subItem.component
                };
            }
            if (subItem.path) {
                return {
                    name: subItem.path
                };
            }
            if (subItem.name) {
                return {
                    name: subItem.name
                };
            }
            return { name: `${item.group}/${subItem.name}` };
        },
        onOpenDialog(dialogName) {
            if (dialogName === "dialogFaq") {
                this.$root.$faqDialog.open();
                return;
            }
            this[dialogName] = true;
            this.onNavigationItemClick();
        },
        getBadgeValueById(badgeId) {
            return String(this[badgeId]);
        },
        fetchBoCountersData() {
            this.isLoading = true;
            this.loader().show();
            this.$store
                .dispatch("backoffice/fetchCountersData")
                .then(() => {
                    this.loader().hide();
                    this.isLoading = false;
                })
                .catch(() => {
                    this.loader().hide();
                    this.isLoading = false;
                });
        },
        fetchInsurerCountersData() {
            this.isLoading = true;
            this.loader().show();
            this[ACTION_TYPES.FETCH_DASHBOARD_COUNTERS_DATA]()
                .then(() => {
                    this.loader().hide();
                    this.isLoading = false;
                })
                .catch(() => {
                    this.loader().hide();
                    this.isLoading = false;
                });
        },
        hasUserRoles(roles) {
            if (!roles) return false;
            return roles.every((role) => this.userRoles.includes(role));
        },
        isShowAllowed(meta) {
            return (
                (meta.requiresAuth &&
                    this.isAuth &&
                    ((_.isArray(meta.requiresRoles) &&
                        this.hasUserRoles(meta.requiresRoles)) ||
                        !meta.requiresRoles) &&
                    ((meta.isDeveloperMode && this.developerMode) ||
                        !meta.isDeveloperMode) &&
                    ((_.isArray(meta.onlyForRoles) &&
                        this.onlyForRoles(meta.onlyForRoles)) ||
                        !meta.onlyForRoles)) ||
                (!meta.requiresAuth && !this.isAuth) ||
                (!meta.requiresAuth && this.isAuth)
            );
        },
        onlyForRoles(roles) {
            if (!roles || !_.isArray(roles)) return false;
            let show = false;
            roles.forEach((role) => {
                if (this.userRoles.includes(role)) {
                    if (role === ACCOUNT_ROLE.REGISTRANT) {
                        if (!this.isBackofficeRoute) {
                            show = true;
                            return;
                        }
                    }
                    if (role === ACCOUNT_ROLE.BACKOFFICE) {
                        if (this.isBackofficeRoute) {
                            show = true;
                            return;
                        }
                    }
                }
            });
            return show;
        }
    },
    mounted() {
        if (this.isUserBackoffice) {
            this.fetchBoCountersData();
        }
        if (this.isUserInsurer) {
            this.fetchInsurerCountersData();
        }
    }
};
</script>
