<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-object-details-i18n.yaml"></i18n>
<template>
    <v-container fluid py-0 my-0>
        <v-row dense>
            <v-col cols="12" class="mb-3">
                <!-- Objektdetails erfassen -->
                <span class="subtitle-1">
                    {{ $t("object-details-info") }}
                </span>
            </v-col>
            <v-col cols="12">
                <base-text-field
                    v-model="computedOperatorName"
                    :error-messages="operatorNameErrors"
                    :counter="$v.operatorName.$params.maxLength.max"
                    :label="$t('operator-name-label')"
                    :required="isRequiredField($v.operatorName)"
                ></base-text-field>
            </v-col>
            <v-col cols="12" md="8">
                <base-text-field
                    v-model="computedFactoryNumber"
                    :error-messages="factoryNumberErrors"
                    :counter="$v.factoryNumber.$params.maxLength.max"
                    :label="$t('factory-number-label')"
                    :placeholder="$t('factory-number-placeholder')"
                    :required="isRequiredField($v.factoryNumber)"
                ></base-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <base-select
                    v-model="computedConstructionYear"
                    :items="constructionYearItems"
                    :error-messages="constructionYearErrors"
                    :label="$t('construction-year-label')"
                    :placeholder="$t('construction-year-placeholder')"
                    :required="isRequiredField($v.constructionYear)"
                >
                    <template v-slot:item="{ item }">
                        <span v-if="constructionYearItems.indexOf(item) === 0"
                            >{{ item }} ({{ $t("this-year") }})</span
                        >
                        <span
                            v-else-if="
                                constructionYearItems.indexOf(item) === 1
                            "
                            >{{ item }} ({{ $t("last-year") }})</span
                        >
                        <span
                            v-else-if="
                                constructionYearItems.indexOf(item) === 2
                            "
                            >{{ item }} ({{ $t("two-years-ago") }})</span
                        >
                        <span v-else>{{ item }}</span>
                    </template>
                </base-select>
            </v-col>
            <v-col cols="12">
                <WSapEquipmentNr
                    v-model="computedEquipmentId"
                    :error-messages="sapEquipmentNrErrors"
                    :counter="$v.sapEquipmentNr.$params.maxLength.max"
                    :label="$t('equipment-id-label')"
                    :placeholder="$t('equipment-id-placeholder')"
                    :required="isRequiredField($v.sapEquipmentNr)"
                ></WSapEquipmentNr>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import validateMixin from "@/mixins/validate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
    name: "ContentObjectDetails",
    mixins: [validateMixin],
    props: {
        operatorName: {
            type: String
        },
        factoryNumber: {
            type: String
        },
        constructionYear: {
            type: [String, Number]
        },
        sapEquipmentNr: {
            type: String
        }
    },
    validations: {
        operatorName: { required, maxLength: maxLength(50) },
        factoryNumber: {
            maxLength: maxLength(30),
            required
        },
        constructionYear: { required },
        sapEquipmentNr: { maxLength: maxLength(7) }
    },
    computed: {
        computedOperatorName: {
            get() {
                return this.operatorName;
            },
            set(value) {
                this.onFieldChange("operatorName", value);
            }
        },
        computedFactoryNumber: {
            get() {
                return this.factoryNumber;
            },
            set(value) {
                this.onFieldChange("factoryNumber", value);
            }
        },
        computedConstructionYear: {
            get() {
                return this.constructionYear;
            },
            set(value) {
                this.onFieldChange("constructionYear", value);
            }
        },
        computedEquipmentId: {
            get() {
                return this.sapEquipmentNr;
            },
            set(value) {
                this.onFieldChange("sapEquipmentNr", value);
            }
        },
        constructionYearItems() {
            let currentYear = new Date().getFullYear();
            let years = [];
            let startYear = currentYear - 150;
            for (var i = startYear; i <= currentYear; i++) {
                years.push(startYear++);
            }
            return years.reverse();
        },
        operatorNameErrors() {
            const errors = [];
            if (!this.$v.operatorName.$dirty) {
                return errors;
            }
            if (!this.$v.operatorName.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        factoryNumberErrors() {
            const errors = [];
            if (!this.$v.factoryNumber.$dirty) {
                return errors;
            }
            if (!this.$v.factoryNumber.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.factoryNumber.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.factoryNumber.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        constructionYearErrors() {
            const errors = [];
            if (!this.$v.constructionYear.$dirty) {
                return errors;
            }
            if (!this.$v.constructionYear.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        sapEquipmentNrErrors() {
            const errors = [];
            if (!this.$v.sapEquipmentNr.$dirty) {
                return errors;
            }
            return errors;
        }
    },
    methods: {
        onFieldChange(field, value) {
            this.$emit("value:change", { field, value });
        },
        reset() {
            this.$v.$reset();
        }
    },
    watch: {
        constructionYearMenu(val) {
            val &&
                this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
        }
    }
};
</script>
