import Helpers from "@/utils/helpers";
export default {
    components: {
        BaseListItem: () => import("../base-list-item.vue"),
        DisplayAddressInline: () =>
            import("@/components/general/display-address-inline.vue")
    },
    methods: {
        checkIfAddressIsEmpty(address) {
            return Helpers.checkIfAddressIsEmpty(address);
        },
        isSameAddressAsBilling(address) {
            return address.sameAddressAsBilling;
        },
        isSameAddressAsOperator(address) {
            return address.sameAddressAsOperator;
        },
        isAddressEmptyOrNotSameAsOperator(address) {
            const isEmpty = this.checkIfAddressIsEmpty(address);
            const sameAddressAsOperator = this.isSameAddressAsOperator(address);
            return isEmpty && sameAddressAsOperator;
        }
    }
};
