<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./multimedia-viewer-i18n.yaml"></i18n>
<template>
    <div>
        <v-container
            no-gutters
            fluid
            v-if="
                selectedFilesForDelete.length > 0 &&
                developerMode &&
                !readonlyModus
            "
        >
            <v-row>
                <v-col>
                    <!-- Component interpolation:  see -> https://kazupon.github.io/vue-i18n/guide/interpolation.html#basic-usage  -->
                    <i18n path="select-multiple-files-text" tag="span">
                        <template v-slot:filesLength>
                            <b class="error--text"
                                >{{
                                    $tc(
                                        "files",
                                        selectedFilesForDelete.length,
                                        { count: selectedFilesForDelete.length }
                                    )
                                }}
                            </b>
                        </template>
                    </i18n>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <base-btn
                        color="error"
                        v-if="selectedFilesForDelete.length > 0"
                        @click="onBtnDeleteMultipleClick"
                    >
                        <v-icon>mdi-delete</v-icon>
                        {{ $t("select-multiple-files-btn") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container
            fluid
            fill-height
            no-gutters
            class="pl-0 ml-0 pt-0 mt-0"
            v-if="attachmentsCount > 0"
        >
            <v-row no-gutters justify="end">
                <BtnDownloadZip
                    v-if="showBtnDownloadAsZip && docId"
                    class="ml-4"
                    :taskDocId="docId"
                />
                <v-spacer />
                <v-btn-toggle v-model="toggleMultimediaView" mandatory group>
                    <v-btn value="list" small>
                        <v-icon>mdi-format-list-bulleted-square</v-icon>
                    </v-btn>
                    <v-btn value="grid" small>
                        <v-icon>mdi-view-grid</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            <attachments-list
                v-if="toggleMultimediaView === 'list'"
                :attachments="attachments"
                :readonlyModus="readonlyModus"
                :deletable="deletable"
                @click-info="onShowModalFileInfos($event)"
                @click-download="onDowloadFile($event)"
                @click-pdf="openModalPdf(apiBaseURL + $event.url)"
                @click-delete="onBtnDeleteAttachmentClick"
            >
            </attachments-list>

            <v-card
                v-for="(category, idxCat) in attachments"
                :key="idxCat"
                flat
                color="transparent"
            >
                <template v-if="toggleMultimediaView === 'grid'">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{
                                $t(`attachment-category-${idxCat}`)
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-if="category.photos">
                                    <v-icon small>mdi-file-image</v-icon>
                                    {{ $t("photos") }} ({{
                                        category.photos.length
                                    }}x)
                                </span>
                                <span v-if="category.documents">
                                    <v-icon small>mdi-file-multiple</v-icon>
                                    {{ $t("documents") }} ({{
                                        category.documents.length
                                    }}x)
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <Photoswipe bubble>
                        <v-container fluid>
                            <v-row dense>
                                <template v-for="photo in category.photos">
                                    <MultimediaFileCard
                                        :key="photo.fileName"
                                        :file="photo"
                                        :showOptionsMenu="showOptionsMenu"
                                        :readonlyModus="readonlyModus"
                                        :deletable="deletable"
                                        @action-info="
                                            onShowModalFileInfos(photo)
                                        "
                                        @action-delete="
                                            onBtnDeleteAttachmentClick(photo)
                                        "
                                        @action-download="onDowloadFile(photo)"
                                    >
                                        <template
                                            v-slot:additionalActions
                                            v-if="
                                                developerMode &&
                                                deletable &&
                                                !readonlyModus
                                            "
                                        >
                                            <v-spacer />
                                            <v-checkbox
                                                v-if="
                                                    developerMode &&
                                                    deletable &&
                                                    !readonlyModus
                                                "
                                                class="ml-2 ma-0 pa-0"
                                                v-model="selectedFilesForDelete"
                                                hide-details
                                                multiple
                                                :value="photo"
                                            >
                                            </v-checkbox>
                                        </template>
                                    </MultimediaFileCard>
                                </template>
                                <template v-for="pdf in category.documents">
                                    <MultimediaFileCard
                                        :key="pdf.fileName"
                                        :file="pdf"
                                        :showOptionsMenu="showOptionsMenu"
                                        :readonlyModus="readonlyModus"
                                        :deletable="deletable"
                                        @action-info="onShowModalFileInfos(pdf)"
                                        @action-delete="
                                            onBtnDeleteAttachmentClick(pdf)
                                        "
                                        @action-download="onDowloadFile(pdf)"
                                        @card-text-click="
                                            openModalPdf(apiBaseURL + pdf.url)
                                        "
                                    >
                                        <template
                                            v-slot:additionalActions
                                            v-if="
                                                developerMode &&
                                                deletable &&
                                                !readonlyModus
                                            "
                                        >
                                            <v-spacer></v-spacer>
                                            <v-checkbox
                                                class="ma-0 pa-0"
                                                v-model="selectedFilesForDelete"
                                                hide-details
                                                multiple
                                                :value="pdf"
                                            >
                                            </v-checkbox>
                                        </template>
                                    </MultimediaFileCard>
                                </template>
                            </v-row>
                        </v-container>
                    </Photoswipe>
                </template>
            </v-card>

            <modal-file-infos
                :value="showModalFileInfos"
                v-on:close="showModalFileInfos = false"
                :file="fileInfos"
            >
            </modal-file-infos>
        </v-container>
    </div>
</template>
<script>

import ModalFileInfos from "./modal-file-infos.vue";
import MultimediaFileCard from "@/components/general/multimedia-file-card.vue";
import { mapGetters } from "vuex";
import AttachmentsList from "@/components/general/lists/attachments-list.vue";
import BtnDownloadZip from "@/components/general/utlis/btn-download-zip.vue";
import Helpers from "@/utils/helpers";
export default {
    name: "MultimediaViewer",
    components: {
        ModalFileInfos,
        MultimediaFileCard,
        AttachmentsList,
        BtnDownloadZip
    },
    props: {
        attachments: {
            type: Object,
            default: () => null
        },
        docId: {
            type: String,
            default: null
        },
        deletable: {
            type: Boolean,
            default: false
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        showBtnDownloadAsZip: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        attachmentObj: {},
        pdfUrl: "",
        showModalFileUploader: false,
        showModalFileInfos: false,
        fileInfos: {},
        selectedFilesForDelete: [],
        toggleMultimediaView: "grid"
    }),
    methods: {
        getAttachmentsCounters(attachments) {
            return Helpers.getAttachmentsCounters(attachments);
        },
        openModalPdf(pdf) {
            this.pdfUrl = pdf;
            if (this.pdfUrl) {
                window.open(this.pdfUrl);
                return false;
            }
        },
        onCloseModalFileUploader() {
            this.$emit("close-modal-file-uploader");
            this.showModalFileUploader = false;
        },
        onBtnDeleteAttachmentClick(attachment) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-attachment-title"),
                    icon: "mdi-alert"
                },
                buttons: [
                    {
                        name: "cancel",
                        text: this.$t("msg-box-btn-cancel"),
                        position: 2,
                        type: "secondary"
                    },
                    {
                        name: "agree",
                        text: this.$t("msg-box-btn-agree"),
                        position: 1,
                        type: "primary"
                    }
                ],
                message: this.$t("msg-box-delete-attachment-text", {
                    attachmentName: attachment.originalFullFileName
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("attachment-deleted", attachment);
                }
            });
        },
        onShowModalFileInfos(attachment) {
            this.fileInfos = attachment;
            this.showModalFileInfos = true;
        },
        onBtnDeleteMultipleClick() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-multiple-title"),
                    icon: "mdi-alert"
                },
                buttons: [
                    {
                        name: "cancel",
                        text: this.$t("msg-box-btn-cancel"),
                        position: 2,
                        type: "secondary"
                    },
                    {
                        name: "agree",
                        text: this.$t("msg-box-btn-agree"),
                        position: 1,
                        type: "primary"
                    }
                ],
                message: this.$t("msg-box-delete-multiple-text", {
                    filesText: this.$tc(
                        "files",
                        this.selectedFilesForDelete.length,
                        {
                            count: this.selectedFilesForDelete.length
                        }
                    )
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit(
                        "delete-multiple-attachments",
                        this.selectedFilesForDelete
                    );
                    this.selectedFilesForDelete = [];
                }
            });
        },
        onDowloadFile(file) {
            let url = this.$axiosBinary.defaults.baseURL + file.url;
            let filename = null;
            if (file.taskDocId) {
                filename = file.taskDocId;
            }
            if (file.originalFullFileName) {
                if (filename) {
                    filename += "-";
                    filename += file.originalFullFileName;
                } else {
                    filename = file.originalFullFileName;
                }
            }
            if (file.fileType === "pdf") {
                var link = document.createElement("a");
                link.href = url;
                link.download = filename;
                link.dispatchEvent(new MouseEvent("click"));
                return;
            }
            fetch(url).then(function (t) {
                return t.blob().then((b) => {
                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", filename);
                    a.click();
                });
            });
        }
    },
    computed: {
        attachmentsCount() {
            const counters =
                this.getAttachmentsCounters(this.attachments) || [];
            if (counters.length) {
                return counters[0];
            }
            return [];
        },
        apiBaseURL() {
            return this.$axiosBinary.defaults.baseURL;
        },
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        showOptionsMenu() {
            if (this.deletable && !this.readonlyModus && this.developerMode) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
