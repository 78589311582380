<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-deregistration-details-i18n.yaml"></i18n>
<template>
    <v-container fluid py-0 my-0>
        <v-row dense>
            <v-col cols="12">
                <span class="subtitle-1">
                    {{ $t("deregistration-details-info") }}
                </span>
            </v-col>
            <v-col cols="12">
                <base-select
                    :items="deregistrationReasons"
                    v-model="computedReason"
                    :label="$t('reason-label')"
                    item-value="value"
                    item-key="key"
                    :error-messages="reasonErrors"
                    :required="isRequiredField($v.reason)"
                    clearable
                    dense
                    :item-text="
                        (item) => $t(`task-deregistration-reason-${item.value}`)
                    "
                >
                    <template v-slot:append>
                        {{ $t("reason-label") }}
                    </template>
                </base-select>
            </v-col>

            <template v-if="computedReason">
                <template v-if="needNewAddress">
                    <v-col cols="12">
                        <v-checkbox
                            v-model="newAddressNotKnown"
                            :label="
                                computedReason ===
                                DEREGISTRATION_REASONS.LOCATION_CHANGED
                                    ? $t(
                                          'checkbox-new-address-not-known-location'
                                      )
                                    : $t('checkbox-new-address-not-known-sold')
                            "
                            @change="onNewAddressNotKnownChange"
                            dense
                        ></v-checkbox>
                    </v-col>
                    <template v-if="!newAddressNotKnown">
                        <v-col cols="12">
                            <WCountrySelect
                                ref="country"
                                :preferred-countries="[
                                    'CH',
                                    'LI',
                                    'DE',
                                    'FR',
                                    'IT',
                                    'AT'
                                ]"
                                v-model="computedCountry"
                                :label="
                                    computedReason ===
                                    DEREGISTRATION_REASONS.LOCATION_CHANGED
                                        ? $t(
                                              'new-address-country-location-changed-label'
                                          )
                                        : $t('new-address-country-sold-label')
                                "
                                @click:clear="computedCountry = null"
                                :error-messages="countryErrors"
                                :required="
                                    isRequiredField($v.newAddress.country)
                                "
                            ></WCountrySelect>
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="
                                computedNewAddress.country &&
                                ['CH', 'LI'].includes(
                                    computedNewAddress.country
                                )
                            "
                        >
                            <base-alert type="info" dense>
                                {{ $t("msg-info-new-address-ch-li") }}
                            </base-alert>
                        </v-col>
                    </template>
                </template>
                <v-col cols="12">
                    <base-date-picker
                        v-model="computedTargetDate"
                        :label="$t('target-date-label')"
                        :errorMessages="targetDateErrors"
                        :required="isRequiredField($v.targetDate)"
                        inputReadonly
                        :type="targetDateDayUnknown ? 'month' : 'date'"
                    >
                        <template v-slot:content>
                            <v-container>
                                <v-checkbox
                                    class="ml-2 ma-0 pa-0"
                                    v-model="targetDateDayUnknown"
                                    hide-details
                                    :label="$t('label-target-date-day-unknown')"
                                >
                                </v-checkbox>
                            </v-container>
                        </template>
                    </base-date-picker>
                </v-col>
                <v-col cols="12">
                    <base-textarea
                        v-model="computedRegistrantNote"
                        :label="$t('registrant-note-label')"
                        :error-messages="registrantNoteErrors"
                        :counter="$v.registrantNote.$params.maxLength.max"
                        :required="isRequiredField($v.registrantNote)"
                        clearable
                        no-resize
                        rows="5"
                    />
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import { DEREGISTRATION_REASONS } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { CompanyAddress } from "@/models/tasks";
import _ from "lodash";
export default {
    name: "ContentDeregistrationDetails",
    mixins: [validateMixin],
    components: {
        WCountrySelect: () => import("@/components/fields/WCountrySelect.vue")
    },
    props: {
        reason: {
            type: String
        },
        registrantNote: {
            type: String
        },
        targetDate: {
            type: String
        },
        newAddress: {
            type: Object
        }
    },
    validations: {
        reason: {
            required
        },
        registrantNote: {
            maxLength: maxLength(500)
        },
        targetDate: {
            required
        },
        newAddress: {
            country: {
                required: requiredIf(function () {
                    return this.needNewAddress && !this.newAddressNotKnown;
                })
            }
        }
    },
    data: () => ({
        modalUidSearch: false,
        DEREGISTRATION_REASONS,
        newAddressNotKnown: false,
        defaultAddressModel: new CompanyAddress(),
        targetDateDayUnknown: false
    }),

    computed: {
        needNewAddress() {
            return (
                this.computedReason ===
                    DEREGISTRATION_REASONS.LOCATION_CHANGED ||
                this.computedReason === DEREGISTRATION_REASONS.SOLD
            );
        },
        deregistrationReasons() {
            return [
                {
                    key: "scrapped",
                    value: DEREGISTRATION_REASONS.SCRAPPED
                },
                {
                    key: "disassembledOnWarehouse",
                    value: DEREGISTRATION_REASONS.DISASSEMBLED_ON_WAREHOUSE
                },
                {
                    key: "disassembledLeavedOnLocation",
                    value: DEREGISTRATION_REASONS.DISASSEMBLED_LEAVED_ON_LOCATION
                },
                {
                    key: "sold",
                    value: DEREGISTRATION_REASONS.SOLD
                },
                {
                    key: "locationChanged",
                    value: DEREGISTRATION_REASONS.LOCATION_CHANGED
                }
            ];
        },
        computedReason: {
            get() {
                return this.reason;
            },
            set(value) {
                this.onFieldChange("reason", value);
            }
        },
        computedRegistrantNote: {
            get() {
                return this.registrantNote;
            },
            set(value) {
                this.onFieldChange("registrantNote", value);
            }
        },
        computedTargetDate: {
            get() {
                return this.targetDate;
            },
            set(value) {
                this.onFieldChange("targetDate", value);
            }
        },
        computedCountry: {
            get() {
                return this.computedNewAddress?.country;
            },
            set(value) {
                this.computedNewAddress = _.set(
                    this.computedNewAddress,
                    "country",
                    value
                );
            }
        },
        computedNewAddress: {
            get() {
                if (
                    this.newAddress ||
                    Object.keys(this.newAddress).length === 0
                ) {
                    return Object.assign(
                        this.defaultAddressModel,
                        this.newAddress
                    );
                }
                return this.newAddress;
            },
            set(value) {
                this.onFieldChange("newAddress", value);
            }
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.reason.$dirty) {
                return errors;
            }
            if (!this.$v.reason.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        registrantNoteErrors() {
            const errors = [];
            if (!this.$v.registrantNote.$dirty) {
                return errors;
            }
            if (!this.$v.registrantNote.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.registrantNote.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        targetDateErrors() {
            const errors = [];
            if (!this.$v.targetDate.$dirty) {
                return errors;
            }
            if (!this.$v.targetDate.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        countryErrors() {
            const errors = [];
            if (!this.$v.newAddress.country.$dirty) {
                return errors;
            }
            if (!this.$v.newAddress.country.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    methods: {
        clearAddress() {
            this.computedNewAddress = new CompanyAddress();
        },
        onFieldChange(field, value) {
            this.$emit("value:change", { field, value });
        },
        onNewAddressNotKnownChange() {
            this.clearAddress();
        },
        reset() {
            this.$v.$reset();
        }
    }
};
</script>
