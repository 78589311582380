<i18n src="./BaseDatePicker-i18n.yaml"></i18n>
<template>
  <v-dialog
    ref="modal"
    v-model="modal"
    :return-value.sync="model"
    persistent
    width="320px"
  >
    <template v-slot:activator="{ attrs }">
      <base-text-field
        v-model="dateModel"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        v-mask="inputMask"
        @input="inputReadonly ? undefined : onDateModelInput"
        v-bind="attrs"
        clearable
        :placeholder="placeholder"
        persistent-placeholder
        :error-messages="errorMessages"
        :required="required"
        @click:clear="onClear"
        @click:prepend-inner="!readonly ? (modal = true) : undefined"
        :readonly="inputReadonly || readonly"
        @click="inputReadonly ? (modal = true) : undefined"
      ></base-text-field>
    </template>
    <v-card flat v-if="modal">
      <v-card-text class="pa-0">
        <v-date-picker
          width="100%"
          v-model="model"
          scrollable
          v-bind="$attrs"
          tile
          color="primary"
          :type="type"
        >
        </v-date-picker>
        <div class="pb-2 text-center">
          <span>{{ formattedDate }}</span>
        </div>
        <slot name="content"/>
      </v-card-text>
      <v-card-actions>
        <base-btn type="close" @click="onCancel">
          {{ $t("modal_btn_cancel") }}
        </base-btn>
        <v-spacer />
        <base-btn type="save" :disabled="isBtnDisabled" @click="onSave">
          {{ $t("modal_btn_save") }}
        </base-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { date as ValidateDate } from "@/utils/validations";
const DatePickerTypes = {
  DATE: "date",
  MONTH: "month"
}
export default {
  name: "BaseDatePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: "DD.MM.YYYY",
    },
    inputReadonly: {
      type: Boolean,
      default: false,
    },
    type: {
      default: "date",
      validator: (value) => {
        const validTypes = [DatePickerTypes.DATE, DatePickerTypes.MONTH];
        return validTypes.indexOf(value) !== -1;
      }
    }
  },
  data: () => ({
    modal: false,
    model: new Date().toISOString().substr(0, 10),
    dateModel: null,
    DEFAULT_MODEL_FORMAT: "YYYY-MM-DD",
  }),
  computed: {
    dateFormatSeparator() {
      return this.getDateFormatSeparator(this.dateFormat);
    },
    defaultModelFormatSeparator() {
      return this.getDateFormatSeparator(this.DEFAULT_MODEL_FORMAT);
    },
    placeholder() {
      let today = new Date().toISOString().substr(0, 10);
      let date = this.$moment(today).format(this.dateFormat);
      return this.$t("example_date", {
        example: date,
      });
    },
    inputMask() {
      let datePositionsWithoutSeparator = this.dateFormat.split(
        this.dateFormatSeparator
      );
      const firstDateBlock = datePositionsWithoutSeparator[0];
      const firstDateBlockChar = firstDateBlock[0];
      const secondDateBlock = datePositionsWithoutSeparator[1];
      const secondDateBlockChar = secondDateBlock[0];
      const thirdDateBlock = datePositionsWithoutSeparator[2];
      const thirdDateBlockChar = thirdDateBlock[0];
      let mask = "";
      mask += firstDateBlock.replaceAll(firstDateBlockChar, "#");
      mask += this.dateFormatSeparator;
      mask += secondDateBlock.replaceAll(secondDateBlockChar, "#");
      mask += this.dateFormatSeparator;
      mask += thirdDateBlock.replaceAll(thirdDateBlockChar, "#");
      return mask;
    },

    formattedDate() {
      const modelFormatted =  this.model ? this.$moment(this.model).format(this.dateFormat) : "";
      if (modelFormatted && this.type === DatePickerTypes.MONTH){
        return modelFormatted.slice(3);
      } else {
        return modelFormatted
      }
    },

    isBtnDisabled() {
      if (this.model) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getDateFormatSeparator(format) {
      return format ? format.replace(/[A-Za-z0-9]+/g, "")[0] : null;
    },
    checkIfDateIsValid(date) {
      if (!date) return false;
      return ValidateDate(date);
    },
    onCancel() {
      this.model = null;
      this.modal = false;
    },
    onSave() {
      this.$refs.modal.save(this.model);
      this.dateModel = this.formattedDate;
      this.emitValue(this.model);
    },
    onClear() {
      this.model = null;
      this.dateModel = null;
      this.emitValue(null);
    },
    getDateFormatted(date) {
      return date ? this.$moment(date).format(this.dateFormat) : "";
    },
    getModelDateFormatted(date) {
      return date ? this.$moment(date).format(this.DEFAULT_MODEL_FORMAT) : "";
    },
    parseDateToModel(date) {
      let dateMoment = this.$moment(date, this.dateFormat);
      let formatted = dateMoment.format(this.DEFAULT_MODEL_FORMAT);
      return formatted;
    },
    setModelDate(date) {
      if (!date) return;
      const dateSeparator = this.getDateFormatSeparator(date);
      if (dateSeparator && this.type === DatePickerTypes.MONTH){
        date = `${date}${dateSeparator}01`
      }
      switch (dateSeparator) {
        case this.dateFormatSeparator:
          this.model = this.$moment(date, this.dateFormat).format(
            this.DEFAULT_MODEL_FORMAT
          );
          break;
        case this.defaultModelFormatSeparator:
          this.model = this.$moment(date, this.DEFAULT_MODEL_FORMAT).format(
            this.DEFAULT_MODEL_FORMAT
          );
          break;

        default:
          break;
      }
    },
    emitValue(value) {
      this.$emit("input", value);
    },
    onDateModelInput(value) {
      if (!value) return this.onClear();
      let isValid = this.checkIfDateIsValid(value);
      if (isValid) {
        const date = this.parseDateToModel(value);
        if (date) {
          this.emitValue(date);
        }
      } else {
        this.emitValue(null);
      }
    },
  },
  watch: {
    value: {
      handler: function (value) {
        if (value) {
          let isValid = this.checkIfDateIsValid(value);
          if (isValid) {
            this.setModelDate(value);
            this.dateModel = this.formattedDate;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
