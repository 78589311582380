export const NumberBlockWithoutDecimalFields = [
    [{
        display: '7',
        value: '7',
        parts: 1
    }, {
        display: '8',
        value: '8',
        parts: 1
    }, {
        display: '9',
        value: '9',
        parts: 1
    }, {
        // Backspace
        iconCls: 'mdi-arrow-left-bold',
        fn: (value) => {
            return value.substring(0, value.length - 1);
        },
        parts: 1
    }],
    [{
        display: '4',
        value: '4',
        parts: 1
    }, {
        display: '5',
        value: '5',
        parts: 1
    }, {
        display: '6',
        value: '6',
        parts: 1
    }, {
        display: 'C',
        fn: () => {
            return '';
        },
        parts: 1
    }],
    [{
        display: '1',
        value: '1',
        parts: 1
    }, {
        display: '2',
        value: '2',
        parts: 1
    }, {
        display: '3',
        value: '3',
        parts: 1
    }, {
        display: '-',
        value: '-',
        parts: 1
    }],
    [{
        display: '0',
        value: '0',
        parts: 3
    }]
]
