var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-container',{staticClass:"pt-10",attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"px-3 pt-3 pb-3"},[_vm._l(([2].slice(0, 2)),function(n,idx){return _c('v-skeleton-loader',{key:idx,staticClass:"mx-auto mb-6",attrs:{"type":"list-item-avatar, divider"}})}),_c('v-skeleton-loader',{staticClass:"mx-auto mb-6",attrs:{"type":"text"}}),_vm._l(([4].slice(0, 4)),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"mx-auto mb-1",attrs:{"type":"heading, list-item-avatar-three-line, actions"}})})],2)],1):_c('v-container',{staticClass:"pt-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-6"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("title")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(
                    _vm.registrationData.state >
                    _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                )?_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t("msg-registration-already-completed"))+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"pb-2"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('RegistrationListItem',{attrs:{"registration":_vm.registrationDataItem},on:{"click":function($event){_vm.dialogRegistrationView = true}}})],1),(
                    _vm.openedDialogs.length === 0 &&
                    _vm.newDialogs.length === 0 &&
                    _vm.registrationData.state ==
                        _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                )?_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('base-alert',{attrs:{"type":"warning"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('alert-msg-1'))}})])],1),(
                        _vm.openedDialogs.length === 0 &&
                        _vm.newDialogs.length === 0 &&
                        _vm.registrationData.state ==
                            _vm.TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES
                    )?_c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('base-alert',{attrs:{"type":"info"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('alert-msg-2'))}})])],1):_vm._e(),(
                        (_vm.openedDialogs.length != 0 ||
                            _vm.newDialogs.length != 0) &&
                        _vm.registrationData.state !=
                            _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                    )?_c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('base-alert',{attrs:{"type":"warning"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                                _vm.$t('alert-msg-3', {
                                    questionTrans: _vm.$tc(
                                        'question-trans',
                                        _vm.openedDialogs.length +
                                            _vm.newDialogs.length
                                    ),
                                    countDialogs:
                                        _vm.openedDialogs.length +
                                        _vm.newDialogs.length
                                })
                            )}})])],1):_vm._e(),(_vm.registrationData.state >= 60)?_c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('base-alert',{attrs:{"type":"success"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('alert-msg-4'))}})])],1):_vm._e()],1):_vm._e(),(
                    _vm.openDialogsItems.length > 0 &&
                    _vm.registrationData.state <=
                        _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                )?_c('v-col',{attrs:{"cols":"12"}},[_c('registration-dialog-card',{attrs:{"dialogs":_vm.openDialogsItems,"registrationData":_vm.registrationData,"title":_vm.$t('title-open-questions'),"subtitle":_vm.$t('title-open-questions-sub')},on:{"click":_vm.onDialogItemClick}})],1):_vm._e(),(
                    _vm.registrationData.state ==
                    _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                )?_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('base-btn',{attrs:{"block":"","color":"info","disabled":_vm.openDialogsItems.length !==
                            _vm.repliedDialogItems.length},on:{"click":_vm.onConfirmAndAssingToBackoffice}},[(
                                _vm.openDialogsItems.length !==
                                _vm.repliedDialogItems.length
                            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-open-dialogs", { counter: _vm.repliedDialogItems.length, total: _vm.openDialogsItems.length }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-assign-to-backoffice"))+" ")])])],1)],1):_vm._e(),(_vm.acceptedDialogsItems.length > 0)?_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-10",attrs:{"flat":"","outlined":"","width":"100%"}},[_c('v-expansion-panels',{attrs:{"flat":"","focusable":"","hover":""}},[_c('v-expansion-panel',{attrs:{"tile":""}},[_c('v-expansion-panel-header',[_c('div',[_c('v-icon',[_vm._v("mdi-history")]),_vm._v(" "+_vm._s(_vm.$t("dialog-history-title"))+" ")],1)]),_c('v-expansion-panel-content',{staticClass:"pa-0 ma-0"},[(_vm.acceptedDialogsItems.length > 0)?_c('v-list',{staticClass:"pa-0",attrs:{"dense":"","subheader":""}},[(
                                            _vm.acceptedDialogsItems.length > 0
                                        )?_vm._l((_vm.acceptedDialogsItems),function(dialogItem){return _c('v-list-item',{key:dialogItem.id,attrs:{"two-line":""},on:{"click":function($event){_vm.selectedDialog = dialogItem;
                                                _vm.registrationDialogModal = true;}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[(
                                                        dialogItem.state ==
                                                        _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED
                                                    )?_c('w-icon',{attrs:{"data":require('@/assets/icons/w-message-check.svg'),"color":_vm.getDialogItemColorByState(
                                                            dialogItem.state
                                                        ),"width":_vm.dialogIconWidth}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t( "dialog-closed-caption", { userName: dialogItem.closedBy, timestamp: _vm.$moment .utc( dialogItem.closedTimestamp ) .local() .from( _vm.$moment() ) } ))+" ")]),_c('v-list-item-title',{staticClass:"text-body-1"},[(
                                                            dialogItem.messages
                                                                .slice(-1)
                                                                .pop()
                                                                .reply &&
                                                            dialogItem.messages
                                                                .slice(-1)
                                                                .pop().reply
                                                                .text
                                                        )?_c('div',[_vm._v(" "+_vm._s(_vm.$t( "registrant" ))+": "+_vm._s(dialogItem.messages .slice(-1) .pop().reply .text)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t( "backoffice" ))+": "+_vm._s(dialogItem.messages .slice(-1) .pop() .question .text)+" ")])])],1)],1)}):_vm._e()],2):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)],1),_c('RegistrationDialogModal',{ref:"registrationDialogModal",attrs:{"value":_vm.registrationDialogModal,"registrationDialogData":_vm.selectedDialog,"registrationData":_vm.registrationData,"step":_vm.selectedDialog.step,"requireChambers":_vm.selectedDialog.requireChambers,"requireAttachments":_vm.selectedDialog.requireAttachments},on:{"input":function($event){_vm.registrationDialogModal = $event},"add-reply":_vm.onAddReplyToMessageDialog,"edit":_vm.onRegistrationDialogMsgEdit,"delete":_vm.onRegistrationDialogMsgDelete,"attachment:add":_vm.onAddAttachmentToMsg,"attachment:delete":_vm.onDeleteAttachmentsFromMsg,"attachment:delete-multiple":_vm.onDeleteMultipleAttachmentsFromMsg}}),(_vm.dialogRegistrationView)?_c('DialogRegistrationView',{attrs:{"value":_vm.dialogRegistrationView,"max-width":"450","referenceId":_vm.registrationData.referenceId},on:{"input":function($event){_vm.dialogRegistrationView = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }