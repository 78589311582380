<template>
    <div
        class="messaging-item flex-row"
        v-if="message"
        :key="message.timestamp"
    >
        <v-avatar class="mr-5" :color="color ? color : defaultColor" size="40">
            <span>{{ getNameInitials(message.createdBy) }}</span>
        </v-avatar>
        <MessageText
            :color="color ? color : defaultColor"
            :text="message.text"
            :createdBy="message.createdBy"
            :timestamp="message.timestamp"
            :showEditIcon="showEditIcon"
            :disableEditIcon="disableEditIcon"
            :showDeleteIcon="showDeleteIcon"
            :disableDeleteIcon="disableDeleteIcon"
            @edit="$emit('edit', $event)"
            @delete="$emit('delete', $event)"
        >
            <template v-slot:actions>
                <slot name="additional-actions"></slot>
                <base-btn
                    v-if="showDeleteIcon"
                    :disabled="disableDeleteIcon"
                    icon
                    color="error"
                    @click="$emit('delete')"
                >
                    <v-icon>mdi-delete</v-icon>
                </base-btn>
                <base-btn
                    v-if="showEditIcon"
                    :disabled="disableEditIcon"
                    icon
                    color="info"
                    @click="$emit('edit')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </base-btn>
            </template>
        </MessageText>
    </div>
</template>
<script>
import MessageText from "./message-text.vue";
import Utils from "@/utils";
export default {
    name: "MessageCard",
    components: {
        MessageText
    },
    props: {
        message: {
            type: Object,
            default: () => {}
        },
        accountDocId: {
            type: String,
            default: null
        },
        showEditIcon: {
            type: Boolean,
            default: false
        },
        disableEditIcon: {
            type: Boolean,
            default: false
        },
        showDeleteIcon: {
            type: Boolean,
            default: false
        },
        disableDeleteIcon: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: null
        }
    },
    methods: {
        getNameInitials(name) {
            return Utils.getNameInitials(name);
        }
    },
    computed: {
        defaultColor() {
            if (!this.message || !this.accountDocId) return "grey lighten-2";
            return this.message.createdById === this.accountDocId
                ? "grey lighten-2"
                : "light-blue lighten-4";
        }
    }
};
</script>
<style src="./message-card.styl" lang="stylus" scoped></style>
