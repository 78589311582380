<i18n src="./view-i18n.yaml"></i18n>
<template>
    <div>
        <base-header>
            <v-icon>mdi-office-building</v-icon>
            {{ $t("title") }}
        </base-header>
        <v-row dense>
            <v-col cols="12">
                <base-subheader>{{
                    $t("group-title-data-management")
                }}</base-subheader>
            </v-col>
            <v-col cols="12">
                <SpecialistsCompaniesTable />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import SpecialistsCompaniesTable from "./table.vue";
export default {
    name: "SpecialistsCompaniesView",
    components: {
        SpecialistsCompaniesTable
    }
};
</script>
