<template>
    <div
        class="center font-weight-medium font-weight-medium mb-3"
        :class="computedCls"
        v-bind="$attrs"
        v-on="$listeners"
        style="word-break: break-word"
    >
        <slot />
    </div>
</template>
<script>
export default {
    name: "BaseHeader",

    props: {
        color: {
            type: String,
            default: "regOfficeHeader"
        }
    },

    computed: {
        computedCls() {
            let cls = this.$vuetify.breakpoint.smAndDown
                ? "text-h5"
                : "text-h4";
            let color = `${this.color}--text`;
            cls += " " + color;
            return cls;
        }
    }
};
</script>
