import { render, staticRenderFns } from "./logs-counter-pie-chart.vue?vue&type=template&id=00356956&"
import script from "./logs-counter-pie-chart.vue?vue&type=script&lang=js&"
export * from "./logs-counter-pie-chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./logs-counter-pie-chart-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fdevel%2Fwidgets%2Fchart%2Flogs-counter-pie-chart.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSheet,VSkeletonLoader})
