<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./dashboard-view-i18n.yaml"></i18n>
<template>
    <v-container class="grow d-flex flex-column flex-nowrap">
        <v-row dense>
            <v-col>
                <base-header>
                    <v-icon>mdi-view-dashboard</v-icon>
                    {{ $t("title") }}
                </base-header>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <base-subheader>{{
                    $t("groups-base-data-title")
                }}</base-subheader>
            </v-col>
            <v-col>
                <PanelGroup :items="groups" :isLoading="isLoading" auto-flex />
            </v-col>
        </v-row>
        <v-row class="grow pt-5" dense>
            <v-col cols="12">
                <base-subheader>{{
                    $t("groups-informations-title")
                }}</base-subheader>
            </v-col>
            <template v-if="totalRegistrationsCounter > 0">
                <v-col cols="12" lg="4" md="6">
                    <pie-chart
                        class="fill-height"
                        :title="$t('registration-office-counter-chart-title')"
                        :subtitle="
                            $t('registration-office-counter-chart-subtitle')
                        "
                        :data="registrationOfficesCounterFormatted"
                        :chart-color="[color.yellow.base, color.cyan.base]"
                        :isLoading="isLoading"
                    >
                        <template v-slot:title-extra>
                            <count-to
                                :start-val="0"
                                :end-val="totalRegistrationsCounter"
                                :duration="3200"
                                class="pl-2 font-weight-bold"
                            />
                        </template>
                    </pie-chart>
                </v-col>
                <v-col cols="12" lg="4" md="6">
                    <pie-chart
                        class="fill-height"
                        :title="
                            $t('registration-object-types-counter-chart-title')
                        "
                        :subtitle="
                            $t(
                                'registration-object-types-counter-chart-subtitle'
                            )
                        "
                        :data="registrationsObjectTypesCounterFormatted"
                        :chart-color="[
                            color.teal.base,
                            color.lime.base,
                            color.orange.base
                        ]"
                        :isLoading="isLoading"
                    >
                        <template v-slot:title-extra>
                            <count-to
                                :start-val="0"
                                :end-val="totalRegistrationsObjectTypesCounter"
                                :duration="3200"
                                class="pl-2 font-weight-bold"
                            />
                        </template>
                    </pie-chart>
                </v-col>
                <v-col cols="12" lg="4" md="6">
                    <pie-chart
                        class="fill-height"
                        :title="$t('task-process-types-counter-chart-title')"
                        :subtitle="
                            $t('task-process-types-counter-chart-subtitle')
                        "
                        :data="registrationsProcessTypesCounterFormatted"
                        :chart-color="[
                            color.brown.base,
                            color.amber.base,
                            color.cyan.base
                        ]"
                        :isLoading="isLoading"
                    >
                        <template v-slot:title-extra>
                            <count-to
                                :start-val="0"
                                :end-val="totalRegistrationsProcessTypesCounter"
                                :duration="3200"
                                class="pl-2 font-weight-bold"
                            />
                        </template>
                    </pie-chart>
                </v-col>
            </template>
            <template v-else>
                <v-col :cols="12" :lg="4" :md="6" :sm="12">
                    <base-alert type="info">
                        <div class="font-weight-bold">
                            {{ $t("alert-msg-not-tasks-recorded") }}
                        </div>
                    </base-alert>
                </v-col>
            </template>
        </v-row>
        <v-row class="grow pt-5" dense>
            <v-col cols="12">
                <base-subheader>{{
                    $t("groups-monitoring-title")
                }}</base-subheader>
            </v-col>
            <v-col :cols="12" :l="6" v-if="totalRegistrationsCounter > 0">
                <CalendarChartTaskRegistration
                    class="fill-height"
                    :isLoading="isLoading"
                />
            </v-col>
        </v-row>
        <v-row class="pt-10" dense>
            <v-col cols="12">
                <base-subheader>{{
                    $t("groups-tasks-process-title")
                }}</base-subheader>
            </v-col>
            <v-col>
                <PanelGroup
                    :items="groupsRegistrationsProcess"
                    :isLoading="isLoading"
                    auto-flex
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from "vuex";
import MaterialColors from "vuetify/es5/util/colors";
import _ from "lodash";
export default {
    name: "AdminDashboardView",
    components: {
        PanelGroup: () => import("@/components/general/PanelGroup.vue"),
        CountTo: () => import("vue-count-to"),
        PieChart: () => import("@/components/widgets/chart/pie-chart.vue"),
        CalendarChartTaskRegistration: () =>
            import("@/components/tasks/widgets/chart/calendar-chart-task-registration.vue")
    },
    data: () => ({
        color: MaterialColors
    }),
    computed: {
        ...mapState("admin", {
            specialistCompaniesCounter: "companiesCounter",
            accountsCounter: "accountsCounter",
            manufacturersCounter: "manufacturersCounter",
            registrationOfficesCounter: "registrationOfficesCounter",
            registrationsProcessCounters: "registrationsProcessCounters",
            registrationsObjectTypesCounter: "registrationsObjectTypesCounter",
            registrationsProcessTypesCounter:
                "registrationsProcessTypesCounter",
            registrationsCreatedCounter: "registrationsCreatedCounter",
            registrationsCompletedCounter: "registrationsCompletedCounter"
        }),
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        groups() {
            return [
                {
                    items: [
                        {
                            title: this.$t("specialists-companies-title"),
                            icon: "mdi-office-building",
                            color: "blue",
                            action: {
                                path: {
                                    name: "admin-specialists-companies-view"
                                }
                            },
                            data: this.specialistCompaniesCounter
                        },
                        {
                            title: this.$t("accounts-counter-title"),
                            icon: "mdi-account-multiple",
                            color: "deep-orange",
                            action: {
                                path: { name: "admin-users-view" }
                            },
                            data: this.accountsCounter
                        },
                        {
                            title: this.$t("manufacturers-counter-title"),
                            icon: "mdi-office-building",
                            color: "lime",
                            action: {
                                path: {
                                    name: "admin-manufacturers-companies-view"
                                }
                            },
                            data: this.manufacturersCounter
                        }
                    ]
                }
            ];
        },
        groupsRegistrationsProcess() {
            return [
                {
                    items: [
                        {
                            title: this.$t("tasks-process-registrant-title"),
                            icon: "mdi-account",
                            color: "blue",
                            data: this.registrationsProcessCounters.registrant
                        },
                        {
                            title: this.$t("tasks-process-bo-title"),
                            icon: "mdi-account-hard-hat",
                            color: "deep-orange",
                            data: this.registrationsProcessCounters.backoffice
                        },
                        {
                            title: this.$t("tasks-process-completed-title"),
                            icon: "mdi-check-circle",
                            color: "success",
                            data: this.registrationsProcessCounters.completed
                        },
                        {
                            title: this.$t("tasks-process-deleted-title"),
                            icon: "mdi-delete-circle",
                            color: "error",
                            data: this.registrationsProcessCounters.deleted
                        }
                    ]
                }
            ];
        },
        registrationOfficesCounterFormatted() {
            let data = [];
            _.forEach(this.registrationOfficesCounter, (value) => {
                data.push({
                    [this.$t("registration-office-label")]:
                        value.registrationOffice,
                    value: value.counter
                });
            });
            return data;
        },
        totalRegistrationsCounter() {
            let counter = 0;
            _.forEach(this.registrationOfficesCounter, (value) => {
                if (value.counter) {
                    counter += value.counter;
                }
            });
            return counter;
        },
        registrationsObjectTypesCounterFormatted() {
            let data = [];
            _.forEach(this.registrationsObjectTypesCounter, (value) => {
                data.push({
                    [this.$t("task-object-type-label")]: this.$t(
                        `task-object-type-${value.type}`
                    ),
                    value: value.counter
                });
            });
            return data;
        },
        totalRegistrationsObjectTypesCounter() {
            let counter = 0;
            _.forEach(this.registrationsObjectTypesCounter, (value) => {
                if (value.counter) {
                    counter += value.counter;
                }
            });
            return counter;
        },
        registrationsProcessTypesCounterFormatted() {
            let data = [];
            _.forEach(this.registrationsProcessTypesCounter, (value) => {
                data.push({
                    [this.$t("task-process-types-label")]:
                        this.$translateKebablizerLower(
                            `task-process-type-${value.type}`
                        ),
                    value: value.counter
                });
            });
            return data;
        },
        totalRegistrationsProcessTypesCounter() {
            let counter = 0;
            _.forEach(this.registrationsProcessTypesCounter, (value) => {
                if (value.counter) {
                    counter += value.counter;
                }
            });
            return counter;
        }
    },
    methods: {
        reload() {
            this.fetchData();
        },
        fetchData() {
            this.loader().show(this.$t("loading-msg-get-data"));
            let axiosPromises = [];
            axiosPromises.push(
                this.$store.dispatch("admin/fetchSpecialistCompaniesCounter")
            );
            axiosPromises.push(
                this.$store.dispatch("admin/fetchAccountsCounter")
            );
            axiosPromises.push(
                this.$store.dispatch("admin/fetchManufacturersCounter")
            );
            axiosPromises.push(
                this.$store.dispatch("admin/fetchRegistrationOfficesCounter")
            );
            axiosPromises.push(
                this.$store.dispatch("admin/fetchRegistrationsProcessCounters")
            );
            axiosPromises.push(
                this.$store.dispatch(
                    "admin/fetchRegistrationsObjectTypesCounter"
                )
            );
            axiosPromises.push(
                this.$store.dispatch(
                    "admin/fetchRegistrationsProcessTypesCounter"
                )
            );
            Promise.all(axiosPromises)
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.error(error);
                    this.loader().hide();
                    return;
                });
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>
