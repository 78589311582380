<i18n       src="./validate-token-i18n.yaml"></i18n>
<template>
    <div>
        <v-container fill-height fluid v-if="tokenValidated === null">
            <v-row justify="center" align="center">
                <v-col>
                    <v-card
                        flat
                        class="align-center justify-center mx-auto"
                        max-width="520"
                    >
                        <v-card-title class="text-h5">{{
                            $t("title")
                        }}</v-card-title>
                        <v-card-text class="pa-0 ma-0">
                            <v-list flat disabled no-gutters>
                                <v-list-item-group color="primary">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-email</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ decryptedMail }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <v-row no-gutters>
                                <v-col>
                                    <v-card flat min-width="300">
                                        <v-container
                                            fluid
                                            no-gutters
                                            fill-height
                                            class="pa-0 ma-0"
                                        >
                                            <v-row>
                                                <v-col
                                                    v-for="photo in validationImages"
                                                    :key="photo.fileName"
                                                    class="d-flex child-flex"
                                                    cols="4"
                                                >
                                                    <v-card
                                                        flat
                                                        tile
                                                        class="d-flex"
                                                    >
                                                        <v-card-text>
                                                            <v-img
                                                                :src="
                                                                    'data:image/jpeg;base64,' +
                                                                    photo.data
                                                                "
                                                                :lazy-src="
                                                                    'data:image/jpeg;base64,' +
                                                                    photo.data
                                                                "
                                                                class="
                                                                    grey
                                                                    lighten-2
                                                                "
                                                                aspect-ratio="1"
                                                            >
                                                                <template
                                                                    v-slot:placeholder
                                                                >
                                                                    <v-row
                                                                        class="
                                                                            fill-height
                                                                            ma-0
                                                                        "
                                                                        align="center"
                                                                        justify="center"
                                                                    >
                                                                        <v-progress-circular
                                                                            indeterminate
                                                                            color="grey lighten-5"
                                                                        ></v-progress-circular>
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                            <div
                                                                class="
                                                                    mb-6
                                                                    text-center
                                                                "
                                                            >
                                                                <span
                                                                    v-text="
                                                                        photo
                                                                            .title[
                                                                            $i18n
                                                                                .locale
                                                                        ]
                                                                    "
                                                                ></span>
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col md="6">
                                    <base-btn
                                        color="green"
                                        block
                                        class="secondary--text"
                                        @click="validateToken"
                                        >{{ $t("yes") }}</base-btn
                                    >
                                </v-col>
                                <v-col md="6">
                                    <base-btn
                                        color="red"
                                        class="secondary--text"
                                        block
                                        @click="disableToken"
                                        >{{ $t("no") }}</base-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        <v-container>
                            <div class="caption">
                                {{ $t("text", { supportMail: supportMail }) }}
                            </div>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fill-height fluid v-if="tokenValidated === true">
            <v-row justify="center" align="center">
                <v-col>
                    <v-card class="mx-auto" max-width="500" outlined>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                    <v-icon color="green"
                                        >mdi-check-circle</v-icon
                                    >
                                    {{ $t("connection-confirm") }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="isAuth">
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">{{
                                    $t("create-or-mutate-registration")
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                    $t("create-or-mutate-registration")
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">{{
                                    $t("create-or-mutate-registration")
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="!isAuth">
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">{{
                                    $t("close-window")
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions
                            v-if="
                                $cookies.isKey('authtoken') &&
                                $cookies.isKey('mail') &&
                                isAuth
                            "
                        >
                            <v-spacer></v-spacer>
                            <base-btn @click="goHome">{{
                                $t("btn-continue")
                            }}</base-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fill-height fluid v-if="tokenValidated === false">
            <v-row justify="center" align="center">
                <v-col>
                    <v-card class="mx-auto" max-width="500" outlined>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                    <v-icon color="red"
                                        >mdi-close-circle</v-icon
                                    >
                                    {{ $t("connection-refuse") }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">{{
                                    $t("thanks")
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">{{
                                    $t("close-window")
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            tokenValidated: null,
            tokenValid: null,
            validationImages: [],
            noAutoAuthorize: false,
            mail: null,
            token: null,
            query: {}
        };
    },
    methods: {
        goHome() {
            if (this.isAuth) {
                this.$router.push({ name: "user-dashboard" });
            } else {
                this.$router.replace("/");
            }
            this.clear();
        },
        closeTab() {
            window.close();
        },
        clear() {
            this.tokenValidated = null;
            this.tokenValid = null;
            this.validationImages = [];
            this.noAutoAuthorize = false;
            this.mail = null;
            this.token = null;
        },
        getTokenInfos() {
            this.loader().show();
            let data = {};
            data.token = this.token;
            data.mail = this.mail;
            this.$axiosAuth
                .get("/getTokenInfo", {
                    params: { mail: data.mail, token: data.token }
                })
                .then((response) => {
                    let status = response.status;
                    let resData = response.data;
                    if (status === 200) {
                        this.tokenValid = true;
                        this.validationImages = resData.images;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.tokenValid = false;
                    this.loader().hide();
                    this.$router.push({ path: "/token-invalid" });
                });
        },
        validateToken() {
            this.loader().show();
            let data = {};
            data.token = this.token;
            data.mail = this.mail;
            this.$store
                .dispatch("auth/validateToken", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.tokenValidated = true;
                        if (
                            this.$cookies.isKey("authtoken") &&
                            this.$cookies.isKey("mail")
                        ) {
                            return this.$store
                                .dispatch("auth/autoLogin")
                                .then(() => {
                                    return this.$store.dispatch(
                                        "auth/getAccountSettings"
                                    );
                                })
                                .then(() => {
                                    this.$router.push(this.computedPath);
                                })
                                .catch((error) => {
                                    if (error.response.status === 404) {
                                        this.$router.push({
                                            name: "account-settings-user"
                                        });
                                    }
                                });
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$router.push({ path: "/token-invalid" });
                });
        },
        disableToken() {
            this.loader().show();
            let data = {};
            data.token = this.token;
            data.mail = this.mail;
            this.$store
                .dispatch("auth/disableToken", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.tokenValidated = false;
                        if (
                            this.$cookies.isKey("mail") ||
                            this.$cookies.isKey("authtoken")
                        ) {
                            this.$cookies.remove("mail");
                            this.$cookies.remove("authtoken");
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        }
    },

    computed: {
        ...mapGetters("settings", {
            developerMode: "developerMode",
            supportMail: "supportMail"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        decryptedMail() {
            if (this.mail) {
                return atob(this.mail);
            }
            return null;
        },
        computedPath() {
            if (!this.query || !this.query.redirect) {
                return { name: "user-dashboard" };
            }
            const path = this.query.redirect;
            delete this.query.redirect;
            delete this.query.mail;
            delete this.query.token;
            return { path: path, query: this.query };
        }
    },

    created() {
        this.token = this.$route.query.token;
        this.mail = this.$route.query.mail;
        this.query = this.$route.query;
        if (this.$route.query.noAutoAuthorize) {
            this.noAutoAuthorize = this.$route.query.noAutoAuthorize;
        }
        if (this.noAutoAuthorize) {
            this.getTokenInfos();
            return;
        }
        if (this.$cookies.isKey("authtoken") && this.$cookies.isKey("mail")) {
            if (
                this.$cookies.get("authtoken") == this.token &&
                this.$cookies.get("mail") == this.mail
            ) {
                this.validateToken();
            } else {
                this.getTokenInfos();
            }
        } else {
            this.getTokenInfos();
        }
    }
};
</script>
