var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"disabled":_vm.disableAllFields}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('base-select',{attrs:{"items":_vm.salutations,"label":_vm.$t('salutation-label'),"item-value":"key","error-messages":_vm.salutationErrors,"disabled":"","required":_vm.isRequiredField(_vm.$v.userData.salutation),"item-text":function (item) { return _vm.$t(item.value.title[_vm.$i18n.locale]); }},model:{value:(_vm.userData.salutation),callback:function ($$v) {_vm.$set(_vm.userData, "salutation", $$v)},expression:"userData.salutation"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('base-text-field',{attrs:{"error-messages":_vm.firstNameErrors,"counter":_vm.$v.userData.firstname.$params.maxLength.max,"label":_vm.$t('firstname-name-label'),"disabled":"","required":_vm.isRequiredField(_vm.$v.userData.firstname)},model:{value:(_vm.userData.firstname),callback:function ($$v) {_vm.$set(_vm.userData, "firstname", $$v)},expression:"userData.firstname"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('base-text-field',{attrs:{"disabled":"","error-messages":_vm.familyNameErrors,"counter":_vm.$v.userData.familyname.$params.maxLength.max,"label":_vm.$t('familyname-label'),"required":_vm.isRequiredField(_vm.$v.userData.familyname)},model:{value:(_vm.userData.familyname),callback:function ($$v) {_vm.$set(_vm.userData, "familyname", $$v)},expression:"userData.familyname"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-select',{attrs:{"items":_vm.computedRolesItems,"label":_vm.$t('roles-label'),"item-value":"value","error-messages":_vm.rolesErrors,"multiple":"","menu-props":{ maxHeight: '400' },"required":_vm.isRequiredField(_vm.$v.userData.roles)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconAndColorByRole(item.value).color},domProps:{"innerHTML":_vm._s(_vm.getIconAndColorByRole(item.value).icon)}}),_vm._v(" "+_vm._s(_vm.$t(("account-role-" + (item.value))))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconAndColorByRole(item.value).color},domProps:{"innerHTML":_vm._s(_vm.getIconAndColorByRole(item.value).icon)}})]}}]),model:{value:(_vm.userData.roles),callback:function ($$v) {_vm.$set(_vm.userData, "roles", $$v)},expression:"userData.roles"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-select',{attrs:{"items":_vm.computedLangItems,"filled":"","label":_vm.$t('lang-label'),"item-value":"value","error-messages":_vm.langErrors,"multiple":"","disabled":"","menu-props":{ maxHeight: '400' },"required":_vm.isRequiredField(_vm.$v.userData.lang)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.value)?_c('span',[_c('country-flag',{attrs:{"size":"small","country":item.value}}),_vm._v(" "+_vm._s(_vm.$t(("lang-" + (item.value.toUpperCase()))))+" ")],1):_vm._e()]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value)?_c('span',[_c('country-flag',{attrs:{"size":"small","country":item.value}}),_vm._v(" "+_vm._s(_vm.$t(("lang-" + (item.value.toUpperCase()))))+" ")],1):_vm._e()]}}]),model:{value:(_vm.userData.lang),callback:function ($$v) {_vm.$set(_vm.userData, "lang", $$v)},expression:"userData.lang"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }