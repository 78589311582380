<i18n src="./logs-counter-pie-chart-i18n.yaml"></i18n>
<template>
    <app-card-title-actions
        :subtitle="$t('subtitle')"
        :actions="cardActions"
        :loading="isLoading"
        @click:reload="getLogsMonthCounters"
        @click:show-logs="$router.push({ name: 'devel-logs' })"
    >
        <template v-slot:title>
            <i18n path="title" tag="span">
                <template #counter>
                    <count-to
                        :start-val="0"
                        :end-val="errorLevelCountersTotal"
                        :duration="3200"
                        class="pl-2 font-weight-bold"
                    />
                </template>
            </i18n>
        </template>
        <template v-slot:content>
            <e-chart
                v-if="errorLevelCountersDataFormatted"
                :path-option="[
                    ['dataset.source', errorLevelCountersDataFormatted],
                    [
                        'color',
                        [color.yellow.base, color.orange.base, color.red.base]
                    ],
                    ['legend.orient', 'horizontal'],
                    ['legend.y', 'bottom'],
                    ['xAxis.show', false],
                    ['yAxis.show', false],
                    ['series[0].type', 'pie'],
                    ['series[0].avoidLabelOverlap', true],
                    ['series[0].radius', ['50%', '70%']]
                ]"
                height="350px"
                width="100%"
            ></e-chart>
            <v-sheet class="pa-1" v-else>
                <v-skeleton-loader
                    class="mx-auto"
                    width="100%"
                    height="100%"
                    type="list-item-avatar, image"
                ></v-skeleton-loader>
            </v-sheet>
        </template>
    </app-card-title-actions>
</template>

<script>
import Material from "vuetify/es5/util/colors";
import _ from "lodash";
export default {
    name: "LogsCounterPieChart",
    components: {
        EChart: () => import("@/components/devel/chart/echart"),
        CountTo: () => import("vue-count-to")
    },
    data: () => ({
        color: Material,
        countersData: null,
        logsTotalCount: 0,
        isLoading: false
    }),

    methods: {
        getLogsMonthCounters() {
            this.isLoading = true;
            this.loader().show();
            this.$axiosLogsApi
                .get("/chartDataTotal/")
                .then((response) => {
                    const resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data;
                        this.countersData = resData.data;
                    }
                    this.loader().hide();
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.isLoading = false;
                });
        }
    },

    computed: {
        cardActions() {
            return [
                {
                    id: "showLogs",
                    emit: "click:show-logs",
                    icon: "mdi-eye",
                    title: this.$t("action-show-logs")
                },
                {
                    id: "reload",
                    emit: "click:reload",
                    icon: "mdi-reload",
                    title: this.$t("action-reload")
                }
            ];
        },
        errorLevelCountersDataFormatted() {
            if (!this.countersData) return;
            let logsMonthData = {};
            _.forEach(this.countersData, (log) => {
                _.forEach(log, (logValue, logLevel) => {
                    if (logsMonthData[logLevel]) {
                        logsMonthData[logLevel] =
                            logsMonthData[logLevel] + logValue.count;
                    } else {
                        logsMonthData[logLevel] = logValue.count;
                    }
                });
            });
            let logData = [];
            _.forEach(logsMonthData, (logCount, logLevel) => {
                logData.push({
                    logLevel: this.$t(`error-level-${logLevel}`),
                    value: logCount
                });
            });
            return logData;
        },
        errorLevelCountersTotal() {
            if (!this.countersData) return;
            let count = 0;
            _.forEach(this.errorLevelCountersDataFormatted, (log) => {
                if (log.value) {
                    count = count + log.value;
                }
            });
            return count;
        }
    },

    mounted() {
        this.getLogsMonthCounters();
    }
};
</script>
