import { render, staticRenderFns } from "./summary-equipment-location.vue?vue&type=template&id=776f7458&"
import script from "./summary-equipment-location.vue?vue&type=script&lang=js&"
export * from "./summary-equipment-location.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./summary-equipment-location-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fcommon%2Fsummary%2Fregistration%2Fsummary-equipment-location.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports