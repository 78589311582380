<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./attachments-list-i18n.yaml"></i18n>
<template>
    <v-list subheader two-line width="100%" class="pt-2">
        <template v-for="(category, idxCat) in attachments">
            <div :key="idxCat">
                <v-divider :key="idxCat" />
                <v-list-item-title>
                    <div
                        class="pl-3"
                        :class="
                            $vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5'
                        "
                    >
                        {{ $t(`attachment-category-${idxCat}`) }}
                    </div>
                </v-list-item-title>
                <v-subheader>
                    <span v-if="category.photos">
                        <v-icon small>mdi-file-image</v-icon>
                        {{ $t("photos") }} ({{ category.photos.length }}x)
                    </span>
                    <span
                        v-if="category.documents"
                        :class="category.photos ? 'pl-2' : undefined"
                    >
                        <v-icon small>mdi-file-multiple</v-icon>
                        {{ $t("documents") }} ({{ category.documents.length }}x)
                    </span>
                </v-subheader>

                <Photoswipe bubble>
                    <template v-for="photo in category.photos">
                        <v-list-item :key="photo.fileName">
                            <v-list-item-avatar tile>
                                <v-img
                                    :src="getFileThumbUrl(photo)"
                                    :lazy-src="getFileThumbUrl(photo)"
                                    v-pswp="getFileUrl(photo)"
                                    class="align-end"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                                    aspect-ratio="1"
                                    contain
                                    width="100%"
                                    max-height="100"
                                >
                                </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon class="mr-1">
                                        {{
                                            getCardStateIconByState(
                                                photo.taskState
                                            )
                                        }}
                                    </v-icon>
                                    {{ photo.originalFullFileName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <file-uploader
                                        v-if="photo.uploader"
                                        :firstName="photo.uploader.firstName"
                                        :familyName="photo.uploader.familyName"
                                        :fileState="photo.taskState"
                                    />
                                    <base-date-time
                                        :timestamp="photo.uploader.timestamp"
                                    />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row no-gutters>
                                    <v-btn icon v-if="deletable">
                                        <v-icon
                                            color="error"
                                            @click="onDeleteIconClick(photo)"
                                            >mdi-delete</v-icon
                                        >
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon
                                            color="info"
                                            @click="onInfoIconClick(photo)"
                                            >mdi-information-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon
                                            color="primary"
                                            @click="onDownloadIconClick(photo)"
                                            >mdi-download</v-icon
                                        >
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </Photoswipe>
                <template v-for="pdf in category.documents">
                    <v-list-item :key="pdf.fileName">
                        <v-list-item-avatar>
                            <v-btn icon @click="onPdfIconClick(pdf)">
                                <v-icon>mdi-file-pdf</v-icon>
                            </v-btn>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="mr-1">
                                    {{ getCardStateIconByState(pdf.taskState) }}
                                </v-icon>
                                {{ pdf.originalFullFileName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <file-uploader
                                    v-if="pdf.uploader"
                                    :firstName="pdf.uploader.firstName"
                                    :familyName="pdf.uploader.familyName"
                                    :fileState="pdf.taskState"
                                />
                                <base-date-time
                                    :timestamp="pdf.uploader.timestamp"
                                />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row no-gutters>
                                <v-btn icon v-if="deletable">
                                    <v-icon
                                        color="error"
                                        @click="onDeleteIconClick(pdf)"
                                        >mdi-delete</v-icon
                                    >
                                </v-btn>
                                <v-btn icon>
                                    <v-icon
                                        color="info"
                                        @click="onInfoIconClick(pdf)"
                                        >mdi-information-outline</v-icon
                                    >
                                </v-btn>
                                <v-btn icon>
                                    <v-icon
                                        color="primary"
                                        @click="onDownloadIconClick(pdf)"
                                        >mdi-download</v-icon
                                    >
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </div>
        </template>
    </v-list>
</template>
<script>
import FileUploader from "@/components/general/utlis/file-uploader.vue";
import { TaskStatus } from "@/data/globals";
export default {
    name: "AttachmentsList",
    components: {
        FileUploader
    },
    props: {
        attachments: {
            type: Object,
            default: () => {}
        },
        deletable: {
            type: Boolean,
            default: false
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getCardStateIconByState(state) {
            if (
                state === TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS ||
                state ===
                    TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES
            ) {
                return "mdi-desktop-mac";
            } else {
                return "mdi-devices";
            }
        },
        getFileUrl(file) {
            if (!file) return;
            let url = this.apiBaseURL;
            if (file.url) {
                url += file.url;
            }
            return url;
        },
        getFileThumbUrl(file) {
            if (!file) return;
            let url = this.apiBaseURL;
            if (file.thumbnail) {
                if (file.thumbnail.url) {
                    url += file.url;
                }
            }
            return url;
        },
        onInfoIconClick(file) {
            this.$emit("click-info", file);
        },
        onDownloadIconClick(file) {
            this.$emit("click-download", file);
        },
        onDeleteIconClick(file) {
            this.$emit("click-delete", file);
        },
        onPdfIconClick(file) {
            this.$emit("click-pdf", file);
        }
    },
    computed: {
        apiBaseURL() {
            return this.$axiosBinary.defaults.baseURL;
        }
    }
};
</script>
