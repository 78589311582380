<i18n src="./summary-address-owner-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-owner-address')" :expandable="expandable">
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li>
                    <DisplayAddressInline
                        v-if="
                            !checkIfAddressIsEmpty(task.ownerAddress) ||
                            isSameAddressAsOperator(task.ownerAddress) ||
                            isSameAddressAsBilling(task.ownerAddress)
                        "
                        :showSameAsOperator="isSameAddressAsOperator(task.ownerAddress)"
                        :showSameAsBilling="isSameAddressAsBilling(task.ownerAddress)"
                        :address="task.ownerAddress"
                    >
                    </DisplayAddressInline>
                    <span v-else class="font-italic">{{
                        $t("not-recorded-yet")
                    }}</span>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import mixin from "../mixin";
import mixinSameAsOperator from './mixin-same-as-operator'
export default {
    name: "SummaryAddressOwner",
    mixins: [mixin, mixinSameAsOperator]
};
</script>
