<template>
  <SectionBase id="devel-mail-templates" title="Mail Templates">
    <v-row align="center">
      <v-col cols="6">
        <base-text-field v-model="mailTemplateMails" label="Mails"></base-text-field>
      </v-col>
      <v-col cols="6" v-if="mailTemplate.value != 'contactForm'">
        <base-text-field
          v-model="mailTemplateDocId"
          label="Task DocID"
        ></base-text-field>
      </v-col>
      <v-col cols="6">
        <base-select
          v-model="mailTemplate"
          :hint="`${mailTemplate.text}, ${mailTemplate.value}`"
          :items="mailTemplates"
          item-text="text"
          item-value="value"
          label="Template"
          persistent-hint
          return-object
          single-line
        ></base-select>
      </v-col>
      <v-col cols="6" v-if="mailTemplate.value == 'contactForm'">
        <base-select
          v-model="registrationOffice"
          :items="registrationOffices"
          item-text="text"
          item-value="value"
          label="Registration Office"
          persistent-hint
          single-line
        ></base-select>
      </v-col>
    </v-row>
    <template v-slot:card-actions>
      <v-spacer></v-spacer>
      <base-btn tile depressed color="info" @click="testMailTemplate">
        <v-icon class="mr-2">mdi-email-send</v-icon>
        Send test mail
      </base-btn>
    </template>
  </SectionBase>
</template>

<script>
export default {
  name: "SectionMailTemplates",
  components: { SectionBase: () => import("./base.vue") },
  data: () => ({
    mailTemplateDocId: "47026ff8-2456-4a5e-a0df-6dc951cb23b1",
    mailTemplate: {
      text: "Meldung Abschluss",
      value: "registrationComplete",
    },
    mailTemplates: [
      { text: "Meldung Abschluss", value: "registrationComplete" },
      { text: "Kontaktform", value: "contactForm" },
      { text: "Meldung Dialog", value: "registrationDialog" },
      { text: "Meldung Dialog Reminder", value: "registrationDialogReminder" },
      { text: "Versicher neue Meldung", value: "insurerNewTask" },
    ],
    mailTemplateMails: ["coelho@was.ch"],
    registrationOffice: 'SVTI',
    registrationOffices: [
      { text: 'SVTI', value: 'SVTI' },
      { text: 'SUVA', value: 'SUVA' }
    ],
  }),
  methods: {
    testMailTemplate() {
      if (!this.mailTemplate) {
        return this.$toast.error("Mail template need to be selected first");
      }
      if (!this.mailTemplateDocId) {
        return this.$toast.error("Task DocID is missing");
      }
      // const mails = this.mailTemplateMails.split(',');
      const mails = this.mailTemplateMails;
      this.loader().show();
      this.loadingMsg = "SEND TEST TEMPLATE MAIL";
      let data = {
        docId: this.mailTemplateDocId,
        template: this.mailTemplate.value,
        mails: mails,
        registrationOffice: this.registrationOffice,
        lang : this.$i18n.locale
      };
      this.$axiosMail
        .post("/testMailTemplates", data)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("MAIL SENT");
          }
          this.loader().hide();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Fehler: " + error);
          this.loader().hide();
        });
    },

    sendTestMail() {
      this.loader().show();
      this.loadingMsg = "SEND TEST MAIL";
      this.$axiosTasks
        .get("/mail/sendTestMail")
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("MAIL SENT");
          }
          this.loader().hide();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Fehler: " + error);
          this.loader().hide();
        });
    },
  },
};
</script>
