var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","outlined":"","flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("card-title"))+" "),_c('v-spacer'),(_vm.showLock && !_vm.readonlyLock)?_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.dialogBasicData = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.showLock && _vm.expandRegistrationDataCard)?_c('btn-lock',{attrs:{"absolute":false,"disabled":_vm.disabledLock},on:{"click":_vm.onClickBtnLock},model:{value:(_vm.readonlyLock),callback:function ($$v) {_vm.readonlyLock=$$v},expression:"readonlyLock"}}):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:btn-expand')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.expandRegistrationDataCard ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),(_vm.expandRegistrationDataCard)?_c('v-expand-transition',[_c('v-card-text',[_c('KeyValueList',{staticClass:"pb-0 mb-0",attrs:{"data":_vm.registrationDataList},on:{"icon-key-click":function($event){return _vm.$emit('icon-key-click', $event)},"icon-value-click":function($event){return _vm.$emit('icon-value-click', $event)}}}),(_vm.attachmentsTotalCounter > 0)?_c('v-list',{staticClass:"pt-0 mt-0",attrs:{"dense":""}},[(
                        _vm.registration.state >
                        _vm.TaskStatus.TASK_REGISTRANT_COMPLETED
                    )?_c('v-list-item',[(_vm.$vuetify.breakpoint.xsOnly)?[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold text-body-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogAttachments = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-multiple")])],1),_vm._v(" "+_vm._s(_vm.$t("attachments-details"))+" ")],1),_c('div',{staticClass:"text-subtitle-2"},_vm._l((_vm.attachmentsCountersByCategory),function(attachments,category){return _c('li',{key:category,staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t( ("attachment-category-" + category) ))+" ("),(attachments.photos > 0)?_c('span',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-file-image")]),_vm._v(" "+_vm._s(_vm.$tc( "photos-count", attachments.photos, { count: attachments.photos } ))+" ")],1):_vm._e(),(
                                            attachments.photos &&
                                            attachments.documents
                                        )?_c('span',[_vm._v(" , ")]):_vm._e(),(attachments.documents > 0)?_c('span',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-file-multiple")]),_vm._v(" "+_vm._s(_vm.$tc( "documents-count", attachments.documents, { count: attachments.documents } ))+") ")],1):_vm._e(),_vm._v(") ")])}),0)],1)]:[_c('v-list-item-content',[_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogAttachments = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-multiple")])],1),_vm._v(" "+_vm._s(_vm.$t("attachments-details"))+" ")],1)]),_c('v-list-item-content',{staticClass:"align-end"},_vm._l((_vm.attachmentsCountersByCategory),function(attachments,category){return _c('li',{key:category,staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t(("attachment-category-" + category)))+" ("),(attachments.photos > 0)?_c('span',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-file-image")]),_vm._v(" "+_vm._s(_vm.$tc( "photos-count", attachments.photos, { count: attachments.photos } ))+" ")],1):_vm._e(),(
                                        attachments.photos &&
                                        attachments.documents
                                    )?_c('span',[_vm._v(" , ")]):_vm._e(),(attachments.documents > 0)?_c('span',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-file-multiple")]),_vm._v(" "+_vm._s(_vm.$tc( "documents-count", attachments.documents, { count: attachments.documents } ))+") ")],1):_vm._e(),_vm._v(") ")])}),0)]],2):_vm._e()],1):_vm._e(),_vm._t("addons"),_c('v-row',[_c('v-col',[_c('DialogBasicData',{attrs:{"value":_vm.dialogBasicData,"registration":_vm.registration},on:{"input":function($event){_vm.dialogBasicData = $event},"complete":_vm.complete}})],1),_c('v-col',{attrs:{"vif":"attachmentsTotalCounter > 0"}},[_c('DialogAttachments',{attrs:{"value":_vm.dialogAttachments,"readonlyModus":!_vm.showLock,"attachments":_vm.registration.attachments,"taskDocId":_vm.registration.docId},on:{"input":function($event){_vm.dialogAttachments = $event}}})],1)],1)],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }