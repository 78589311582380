<i18n src="./content-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <AddressCopyOperator
            ref="addressCopyOperator"
            @change:field="onFieldChange"
            @change:address-fields="onAddressFieldsChange"
            @reset="onReset"
            @clear:address="onClearAddress"
            @clear="$emit('clear', $event)"
            @change:address="onChangeAddress"
            @click:copy-address="onClickCopyAddress"
            typeOfAddress="owner"
            :address="value"
            :task="task"
            :readonlyModus="readonlyModus"
            :allowedCopyItems="allowedCopyItems"
            :allowedSameAsItems="allowedSameAsItems"
        ></AddressCopyOperator>
    </v-container>
</template>
<script>
import validateMixin from "@/mixins/validate";
import { ADDRESS_COPY_TYPES } from "@/data/globals.js";
import { SAME_AS_TYPES } from "@/components/tasks/edit/common/components/same-as-types.js";
export default {
    name: "StepAddressOwnerContent",
    components: {
        AddressCopyOperator: () =>
            import(
                "@/components/tasks/edit/common/components/address-copy-operator.vue"
            )
    },
    mixins: [validateMixin],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        task: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        allowedCopyItems: [
            ADDRESS_COPY_TYPES.COPY_ADDRESS_OPERATOR,
            ADDRESS_COPY_TYPES.COPY_ADDRESS_BILL
        ],
        allowedSameAsItems: [
            SAME_AS_TYPES.SAME_AS_OPERATOR,
            SAME_AS_TYPES.SAME_AS_BILLING,
            SAME_AS_TYPES.CREATE_NEW_ADDRESS,
        ]
    }),

    methods: {
        onFieldChange({ field, value }) {
            if (!field) return;
            this.$emit("change:address-field", {
                address: "ownerAddress",
                field,
                value
            });
        },
        onChangeAddress(address) {
            this.$emit("change:address", address);
        },
        onClickCopyAddress(action) {
            this.onChangeAddress(action.address);
        },
        onReset() {
            this.$emit("clear:address", { field: "ownerAddress" });
        },
        onClearAddress({ field, address }) {
            if (!field) return;
            this.$emit("clear:address", { field, address });
        },
        onAddressFieldsChange(address) {
            this.$emit("change:address", {
                field: "ownerAddress",
                value: address
            });
        },
        isValid() {
            const addressCopyOperatorValid =
                this.$refs.addressCopyOperator.isValid();
            if (addressCopyOperatorValid === null) {
                return null;
            }
            return addressCopyOperatorValid;
        },
        validate() {
            if (this.$refs.addressCopyOperator) {
                return this.$refs.addressCopyOperator.validate();
            }
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                if (this.$refs.addressCopyOperator) {
                    this.$refs.addressCopyOperator.scrollToFirstError();
                }
            });
        }
    }
};
</script>
