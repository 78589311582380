<i18n src="./form-address-company-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, address)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @validate:
                <tree-view
                    :data="Object.assign({}, $v)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-times</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <v-row dense>
            <v-col cols="12" md="6">
                <base-text-field
                    :value="address.companyName"
                    @input="emitOnFieldInput('companyName', $event)"
                    :label="$t('company-name-label')"
                    :required="isRequiredField($v.address.companyName)"
                    :error-messages="companyNameErrors"
                    :counter="
                        isRequiredField($v.address.companyName)
                            ? $v.address.companyName.$params.maxLength
                                  .max
                            : false
                    "
                ></base-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <base-text-field
                    :value="address.department"
                    @input="emitOnFieldInput('department', $event)"
                    :label="$t('department-label')"
                    :required="isRequiredField($v.address.department)"
                    :error-messages="departmentErrors"
                    :counter="
                        isRequiredField($v.address.department)
                            ? $v.address.department.$params.maxLength
                                  .max
                            : false
                    "
                ></base-text-field>
            </v-col>
            <v-col cols="12">
                <WUidField
                    @change="emitUidOnChange"
                    :value="address.uid"
                    :country="country"
                    :readonly="readonly"
                    :disabled="disabled"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import validateMixin from "@/mixins/validate";
import { required, maxLength } from "vuelidate/lib/validators";
import mixin from "./mixin";
import { CompanyAddress } from "@/models/tasks";
export default {
    name: "FormAddressCompany",
    mixins: [validateMixin, mixin],
    components: {
        WUidField: () => import("@/components/fields/WUidField.vue")
    },
    props: {
        value: {
            type: Object,
            default: () => new CompanyAddress()
        },
        country: {
            type: String,
            default: null
        }
    },
    validations() {
        return {
            address: {
                companyName: {
                    required,
                    maxLength: maxLength(40)
                },
                department: {
                    maxLength: maxLength(40)
                }
            }
        };
    },
    data: () => ({
        address: {}
    }),
    computed: {
        companyNameErrors() {
            const errors = [];
            if (!this.$v.address.companyName.$dirty) {
                return errors;
            }
            if (!this.$v.address.companyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.companyName.$params
                                .maxLength.max
                    })
                );
            }
            if (!this.$v.address.companyName.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        departmentErrors() {
            const errors = [];
            if (!this.$v.address.department.$dirty) {
                return errors;
            }
            if (!this.$v.address.department.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.department.$params.maxLength
                                .max
                    })
                );
            }
            return errors;
        }
    },
    methods: {
        emitUidOnChange(value) {
            this.emitOnFieldInput("uid", value.uid);
            this.emitOnFieldInput("uidCategory", value.uidCategory);
            this.emitOnFieldInput("uidFormatted", value.uidFormatted);
        }
    },
    watch: {
        value: {
            handler: function (value) {
                if (value) {
                    this.address = Object.assign({}, value);
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
