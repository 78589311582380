<i18n src="./summary-data-registrant-i18n.yaml"></i18n>
<template>
    <BaseListItem
        :title="$t('title-data-registrant')"
        :expandable="expandable"
        v-if="registrant"
    >
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li v-if="registrantFullName">
                    {{ $t("fullname-label") }}:
                    <span class="font-weight-bold">
                        {{ registrantFullName }}
                    </span>
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="registrantFullName"
                    />
                </li>
                <li v-if="registrantEmail">
                    {{ $t("email-label") }}:
                    <span class="font-weight-bold">
                        {{ registrantEmail }}
                    </span>
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="registrantEmail"
                    />
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
export default {
    name: "SummaryDataRegistrant",

    components: {
        BaseListItem: () => import("./base-list-item.vue"),
        CopyToClipboard: () =>
            import("@/components/general/utlis/copy-to-clipboard.vue")
    },
    props: {
        registrant: {
            type: Object,
            default: () => {},
            required: true
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        },
        expandable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        registrantFullName() {
            if (!this.registrant) return;
            return `${this.registrant?.firstName} ${this.registrant?.familyName}`;
        },
        registrantEmail() {
            return this.registrant?.email;
        }
    }
};
</script>
