import { Address } from './Address';
import { Communication } from './Communication';
import { PoBox } from './PoBox';
import { aggregation } from '@/utils/aggregation';
import { ADDRESS_TYPES } from "@/data/globals.js";

export class CompanyAddress extends aggregation(Address, PoBox) {
    constructor() {
        super();
        this.companyName = null;
        this.department = null;
        this.uid = null;
        this.uidCategory = null;
        this.uidFormatted = null;
        this.addressType = ADDRESS_TYPES.COMPANY;
        this.communication = new Communication();
    }
}
