<i18n src="./table-i18n.yaml"></i18n>
<template>
    <div>
        <v-skeleton-loader
            v-if="isLoading"
            class="mx-auto"
            max-width="100%"
            heigth="500"
            type="card"
        >
        </v-skeleton-loader>
        <base-card-text v-else>
            <template v-slot:content>
                <v-data-table
                    v-bind="$attrs"
                    :headers="computedHeaders"
                    :items="items"
                    :item-class="computedItemClasses"
                    :options="computedOptions"
                    v-on="$listeners"
                >
                    <template v-slot:top>
                        <v-toolbar flat :extended="!hideSearch">
                            <v-row>
                                <v-col align="end">
                                    <slot name="toolbar-actions">
                                        <slot name="toolbar-actions-additional">
                                            <template
                                                v-for="action in toolbarActions"
                                            >
                                                <v-tooltip
                                                    right
                                                    v-if="action.title"
                                                    :key="action.id"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on
                                                        }"
                                                    >
                                                        <v-btn
                                                            v-if="action.emit"
                                                            v-on="on"
                                                            icon
                                                            :color="
                                                                action.color
                                                            "
                                                            @click="
                                                                onClickAction(
                                                                    action
                                                                )
                                                            "
                                                            ><v-icon>{{
                                                                action.icon
                                                            }}</v-icon>
                                                        </v-btn>
                                                        <v-icon
                                                            v-else
                                                            :key="action.id"
                                                            :color="
                                                                action.color
                                                            "
                                                            v-on="on"
                                                        >
                                                            {{ action.icon }}
                                                        </v-icon>
                                                    </template>
                                                    {{ action.title }}
                                                </v-tooltip>
                                                <template v-else>
                                                    <v-btn
                                                        v-if="action.emit"
                                                        :key="action.id"
                                                        icon
                                                        :color="action.color"
                                                        @click="
                                                            onClickAction(
                                                                action
                                                            )
                                                        "
                                                        ><v-icon>{{
                                                            action.icon
                                                        }}</v-icon>
                                                    </v-btn>
                                                    <v-icon
                                                        v-else
                                                        :key="action.id"
                                                        :color="action.color"
                                                    >
                                                        {{ action.icon }}
                                                    </v-icon>
                                                </template>
                                            </template>
                                        </slot>

                                        <base-btn
                                            v-if="!hideBtnAdd"
                                            :icon="showAddBtnAsIcon"
                                            @click="$emit('click:add')"
                                            ><v-icon :left="!showAddBtnAsIcon"
                                                >mdi-plus</v-icon
                                            >
                                            <span v-if="!showAddBtnAsIcon">{{
                                                btnAddText
                                            }}</span>
                                        </base-btn>
                                        <base-btn
                                            v-if="!hideBtnReload"
                                            icon
                                            class="ml-2"
                                            @click="$emit('click:reload')"
                                            ><v-icon>mdi-reload</v-icon>
                                        </base-btn>
                                    </slot>
                                </v-col>
                            </v-row>
                            <template v-slot:extension v-if="!hideSearch">
                                <v-row no-gutters>
                                    <v-col cols="12" :sm="6">
                                        <v-row no-gutters>
                                            <base-text-field
                                                :value="searchModel"
                                                @input="
                                                    $emit(
                                                        'input:search',
                                                        $event
                                                    )
                                                "
                                                append-icon="mdi-magnify"
                                                :label="
                                                    searchFieldLabel
                                                        ? searchFieldLabel
                                                        : $t(
                                                              'search-field-label'
                                                          )
                                                "
                                                single-line
                                                hide-details
                                            ></base-text-field>
                                            <v-divider
                                                class="mx-4"
                                                inset
                                                vertical
                                            ></v-divider>
                                        </v-row>
                                    </v-col>
                                    <v-spacer />
                                </v-row>
                            </template>
                        </v-toolbar>
                    </template>
                    <!-- this allow to overwrite all slots -->
                    <template
                        v-for="(slot, name) in $scopedSlots"
                        v-slot:[name]="item"
                    >
                        <slot :name="name" v-bind="item"></slot>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <slot name="actions" :item="item">
                            <template>
                                <v-menu
                                    transition="slide-y-transition"
                                    bottom
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <base-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="
                                                actions.length === 0 ||
                                                computedItemClasses(
                                                    item
                                                ).includes('disabled')
                                            "
                                        >
                                            <v-icon color="info">
                                                mdi-reorder-horizontal
                                            </v-icon>
                                        </base-btn>
                                    </template>
                                    <v-list>
                                        <template
                                            v-for="(actionItem, i) in actions"
                                        >
                                            <v-list-item
                                                v-if="
                                                    funcShowAction(
                                                        item,
                                                        actionItem
                                                    )
                                                "
                                                :key="i"
                                                @click="
                                                    onMenuActionClick(
                                                        actionItem.action,
                                                        item
                                                    )
                                                "
                                            >
                                                <v-list-item-icon class="mr-2">
                                                    <v-icon
                                                        v-text="actionItem.icon"
                                                    ></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    {{ $t(actionItem.title) }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </template>
                        </slot>
                    </template>
                    <template v-slot:no-data>
                        <slot name="no-data">
                            <span>
                                <p class="pt-2 blue--text subheading">
                                    <v-icon medium class="blue--text">
                                        mdi-information
                                    </v-icon>
                                    <span v-if="noDataText">
                                        {{ noDataText }}
                                    </span>
                                    <span v-else>
                                        {{ $t("no-data-msg") }}
                                    </span>
                                </p>
                            </span>
                        </slot>
                    </template>
                </v-data-table>
            </template>
        </base-card-text>
    </div>
</template>
<script>
export default {
    name: "GlobalTable",
    props: {
        items: {
            type: Array,
            default: () => []
        },
        actions: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: "title"
        },
        headers: {
            type: Array,
            default: () => []
        },
        funcShowAction: {
            type: Function,
            default: () => true
        },
        options: {
            type: Object,
            default: () => {}
        },
        itemClasses: {
            type: Array,
            default: () => []
        },
        funcRowClasses: {
            type: Function,
            default: () => []
        },
        searchFieldLabel: {
            type: String,
            default: null
        },
        btnAddText: {
            type: String,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        hideBtnAdd: {
            type: Boolean,
            default: false
        },
        hideBtnReload: {
            type: Boolean,
            default: false
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        toolbarActions: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        defaultOptions: {
            rowsPerPage: -1
        },
        searchModel: null
    }),
    computed: {
        defaultHeaders() {
            return [
                {
                    text: this.$t("header-actions"),
                    value: "actions",
                    sortable: false,
                    align: "right",
                    isMobile: true
                }
            ];
        },
        computedHeaders() {
            return [...this.headers, ...this.defaultHeaders];
        },
        isNotEmpty() {
            return this.items && this.items.length > 0;
        },
        computedOptions: {
            get() {
                return Object.assign(this.defaultOptions, this.options);
            },
            set(value) {
                this.$emit("options:change", value);
            }
        },
        showAddBtnAsIcon() {
            return (
                (!this.btnAddText && this.$vuetify.breakpoint.smAndUp) ||
                (this.btnAddText && this.$vuetify.breakpoint.xsOnly)
            );
        },
        noDataText() {
            return this.$attrs["no-data-text"];
        }
    },
    methods: {
        computedItemClasses(item) {
            return [...this.itemClasses, ...this.funcRowClasses(item)];
        },
        onClickAction(action) {
            if (action.emit) {
                this.$emit(action.emit);
            }
        },
        onMenuActionClick(action, item) {
            this.$emit("click:action", { action, item });
        }
    }
};
</script>
<style src="./table.sass" lang="sass"></style>
