<template >
    <span class="cls-external-link regOfficeLink--text">
        <a @click="dialogExternalLink = true" class="regOfficeLink--text">
            <span class="text-decoration-none text-justify">
                {{ text }}
            </span>
            <v-icon class="cls-external-link-icon regOfficeLink--text" small>
                mdi-open-in-new
            </v-icon>
        </a>
        <DialogExternalLink
            v-if="dialogExternalLink && link"
            :value="dialogExternalLink"
            @input="dialogExternalLink = $event"
            :link="link"
        >
        </DialogExternalLink>
    </span>
</template>
<script>
import DialogExternalLink from "@/components/general/dialog-external-link.vue";
export default {
    name: "BaseExternalLink",

    components: {
        DialogExternalLink
    },

    props: {
        link: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        }
    },

    data: () => ({
        dialogExternalLink: false
    })
};
</script>
<style src="./BaseExternalLink.styl" scoped lang="stylus"></style>
