<template>
  <SectionBase
    id="devel-util-compare-objects"
    title="Compare 2 Objects"
    subtitle="Test Function that compare 2 objects"
  >
    <v-row>
      <v-col v-for="(obj, key) in objects" :key="key">
        <v-card height="100%">
          <v-card-text>
            <tree-view
              :data="Object.assign({}, obj)"
              :options="{ maxDepth: 1, rootObjectKey: key, modifiable: true }"
            ></tree-view>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="resCompare2Objects">
      <v-col>
        <div v-for="(field, idx) in resCompare2Objects" :key="idx">
          <div v-if="isObject(field)">
            -{{ idx }}:
            <div v-for="(fieldObj, idxObj) in field" :key="idxObj">
              <div v-if="isObject(fieldObj)">
                --{{ idxObj }}:
                <div
                  v-for="(fieldObjObj, idxObjObj) in fieldObj"
                  :key="idxObjObj"
                >
                  <span v-if="isObject(fieldObj)">
                    ---{{ idxObjObj }}: {{ fieldObjObj }}
                  </span>
                </div>
              </div>
              <div v-else>--{{ idxObj }}: {{ fieldObj }}</div>
            </div>
          </div>
          <div v-else>-{{ idx }}: {{ field }}</div>
        </div>
        <base-btn icon color="error" @click="resCompare2Objects = null">
          <v-icon>mdi-close</v-icon>
        </base-btn>
      </v-col>
    </v-row>
    <template v-slot:card-actions>
      <v-row>
        <v-col xs="12">
          <base-btn
            class="mr-2"
            color="info"
            block
            @click="compare2Objects(objects.obj1, objects.obj2)"
          >
            <v-icon left>mdi-compare</v-icon>
            Obj1 + Obj2
          </base-btn>
        </v-col>
        <v-col xs="12">
          <base-btn
            class="mr-2"
            block
            color="warning"
            @click="compare2Objects(objects.obj3, objects.obj4)"
          >
            <v-icon left>mdi-compare</v-icon>
            Obj3 + Obj4
          </base-btn>
        </v-col>
        <v-col xs="12">
          <base-btn
            @click="compare2Objects(objects.obj5, objects.obj6)"
            block
          >
            <v-icon left>mdi-compare</v-icon>
            Obj5 + Obj6
          </base-btn>
        </v-col>
      </v-row>
    </template>
  </SectionBase>
</template>

<script>
import Helpers from "@/utils/helpers";
export default {
  name: "SectionUtilCompareObjects",
  components: { SectionBase: () => import("./base.vue") },
  data: () => ({
    resCompare2Objects: null,
    objects: {
      obj1: {
        name: "Bob",
        age: 20,
        orders: {
          "001": {
            amount: 20.93,
            products: ["ps4"],
          },
          "002": {
            amount: 20.9,
            products: ["ps4", "ps5"],
          },
        },
      },
      obj2: {
        name: "Bob",
        age: 21,
        orders: {
          "001": {
            amount: 20.94,
            products: ["ps4", "ps5"],
          },
        },
      },
      obj3: {
        name: "Bob",
        age: 20,
      },
      obj4: {
        name: "Alice",
        age: 20,
      },
      obj5: {
        name: "Bob",
        age: 20,
        colors: ["black", "blue"],
      },
      obj6: {
        name: "Alice",
        age: 20,
        colors: ["black", "blue", "red"],
      },
    },
  }),
  methods: {
    compare2Objects(obj1, obj2) {
      this.resCompare2Objects = Helpers.diffsOf2Objects(obj1, obj2);
    },
    isObject(obj) {
      return obj instanceof Object;
    },
  },
};
</script>
