<template>
    <v-alert
        v-bind="$attrs"
        :type="type"
        v-on="$listeners"
        border="left"
        outlined
    >
        <span v-if="text" v-html="text" />
        <slot v-else />
    </v-alert>
</template>
<script>
export default {
    name: "BaseAlert",
    props: {
        type: String,
        text: String
    }
};
</script>
