<i18n src="./modal-task-complete-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="value"
        :title="computedTitle"
        max-width="450"
        icon="mdi-alert"
        @close="$emit('input', $event)"
    >
        <template v-slot:content>
            <v-container fill-height fluid class="px-5 text-body-1">
                <div
                    v-html="
                        $t('complete-registration-text', {
                            email: decryptedMail
                        })
                    "
                ></div>
                <div
                    v-html="$t('complete-registration-next-steps')"
                    class="font-weight-bold"
                ></div>
                :
                <base-list-ordered
                    :items="completeDialogListNumericItems"
                ></base-list-ordered>
            </v-container>
        </template>
        <template v-slot:actions>
            <slot name="actions">
                <v-spacer></v-spacer>
                <base-btn @click="onClickComplete" class="mr-4" type="save">
                    {{ $t("btn-complete-now") }}
                </base-btn>
            </slot>
        </template>
    </base-dialog>
</template>

<script>
export default {
    name: "ModalTaskComplete",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        mailEncrypted: {
            type: String,
            default: null
        }
    },
    computed: {
        computedTitle(){
            if (this.title) return this.title;
            return this.$t('complete-registration-title')
        },
        decryptedMail() {
            if (this.mail) {
                return atob(this.mail);
            } else {
                return null;
            }
        },
        completeDialogListNumericItems() {
            return [
                this.$t("complete-registration-next-steps-01"),
                this.$t("complete-registration-next-steps-02"),
                this.$t("complete-registration-next-steps-03", {
                    email: this.decryptedMail
                }),
                this.$t("complete-registration-next-steps-04")
            ];
        }
    },
    methods: {
        onClickComplete() {
            this.$emit("click:complete");
        }
    }
};
</script>
