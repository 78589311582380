<i18n src="./main-facts-list-i18n.yaml"></i18n>
<template>
    <div
        class="pa-3 cls-main-facts"
        :class="
            theme.isDark ? 'backgroundInner' : 'grey lighten-3 primary--text'
        "
    >
        <base-title
            :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0' : 'pl-2'"
        >
            {{ $t("info-title") }}
        </base-title>
        <ul class="a-list a-list--unordered">
            <li class="a-list__item cls-main-facts__item">
                {{ $t("info-text-01") }}
            </li>
            <li class="a-list__item cls-main-facts__item">
                <i18n path="info-text-02" tag="div">
                    <template v-slot:link>
                        <base-external-link
                            :link="svtiLink"
                            :text="$t('here')"
                        />
                    </template>
                </i18n>
            </li>
            <li class="a-list__item cls-main-facts__item">
                {{ $t("info-text-03") }}
            </li>
            <li class="a-list__item cls-main-facts__item">
                <i18n path="addition-info-federation" tag="div">
                    <template v-slot:link>
                        <base-external-link
                            :link="dgvvLink"
                            :text="$t('federation')"
                        />
                    </template>
                </i18n>
            </li>
        </ul>
    </div>
</template>
<script>
import { EXTERNAL_LINKS } from "@/data/globals.js";
export default {
    name: "MainFactsList",
    inject: ["theme"],
    computed: {
        currentLang() {
            return this.$root.$i18n.locale || "de";
        },
        svtiLink() {
            return EXTERNAL_LINKS.svti[this.currentLang.toUpperCase()];
        },
        dgvvLink() {
            return EXTERNAL_LINKS.dgvv[this.currentLang.toUpperCase()];
        }
    }
};
</script>
<style src="./main-facts-list.styl" scoped lang="stylus"></style>
