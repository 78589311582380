<template>
    <v-btn
        style="z-index: 3"
        :absolute="absolute"
        depressed
        :color="color"
        right
        small
        icon
        v-bind="$attrs"
        v-on="$listeners"
    >
        <v-icon v-if="value">mdi-lock</v-icon>
        <v-icon v-else>mdi-lock-open-variant</v-icon>
    </v-btn>
</template>
<script>
export default {
    name: "BtnLock",
    props: {
        value: {
            type: Boolean,
            default: true
        },
        absolute: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: "primary"
        }
    }
};
</script>
