<i18n       src="./content-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <StepContentTitle :title="$t('title')">
            <template v-slot:title>
                {{ $t("title") }}
                <base-btn
                    class="pl-3"
                    icon
                    @click="showFaq('chambersDefinition')"
                >
                    <v-icon left>mdi-information-outline</v-icon>
                </base-btn>
            </template>
        </StepContentTitle>
        <v-row>
            <v-col>
                <div v-html="$t('chamber-definition')"></div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <base-alert type="info">
                    <div v-html="$t('chamber-definition-alert')"></div>
                </base-alert>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <div>
                    <span
                        class="pl-0"
                        v-html="
                            $t('subtitle', {
                                maxChambers: maxChambers,
                                chamber: $tc('chamber', maxChambers)
                            })
                        "
                    >
                    </span>
                    <br />
                    <span
                        class="pl-0"
                        v-if="chambers.length > 0"
                        v-html="
                            $t('subtitle-volume', {
                                chambersLength: chambers.length,
                                totalChambersVolume: getTotalChambersVolume(),
                                chamber: $tc('chamber', chambers.length)
                            })
                        "
                    >
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-container pl-0 pt-4 fluid grid-list-md fill-height>
            <v-layout row wrap>
                <v-flex
                    xs12
                    sm6
                    :class="chamberCardCls"
                    v-for="(item, index) in chambers"
                    :key="index"
                >
                    <ChamberCard
                        :chamberIndex="index"
                        :chamberData="item"
                        v-on:remove-chamber="removeChamberByIndex"
                        v-on:edit-chamber="onEditChamber"
                        :readonlyModus="readonlyModus"
                    >
                    </ChamberCard>
                </v-flex>
                <v-flex
                    xs12
                    sm6
                    :class="chamberCardCls"
                    v-if="chambers.length < 4 && !readonlyModus"
                >
                    <v-card
                        height="100%"
                        class="mx-auto"
                        max-width="360"
                        min-height="140"
                        flat
                        outlined
                        tile
                        :color="chamberColor"
                        @click.native="btnAddChamberClick"
                        ripple
                        style="cursor: pointer"
                    >
                        <v-container fluid class="pa-0 ma-0">
                            <v-layout align-center justify-center>
                                <v-icon class="pt-2" color="white" size="80">
                                    mdi-plus
                                </v-icon>
                            </v-layout>
                            <v-layout align-center justify-center>
                                <span class="white--text title">{{
                                    $t("btn-add-chamber")
                                }}</span>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-row v-if="dialog">
            <v-col>
                <base-dialog
                    :value="dialog"
                    @input="dialog = $event"
                    :title="
                        editedIndex === -1
                            ? $t('title-add-chamber', { chamberNr: chamberId })
                            : $t('title-edit-chamber', { chamberNr: chamberId })
                    "
                    max-width="1024"
                    :fullscreen="$vuetify.breakpoint.xsOnly"
                    ref="dialog"
                    @keydown.ctrl.83.prevent.stop="onBtnSaveChamberClick"
                >
                    <template v-slot:content>
                        <form-chamber
                            class="pa-2 pt-4"
                            ref="formChamber"
                            :chamberId="chamberId"
                            :readonlyModus="readonlyModus"
                            :activelyHeated="activelyHeated"
                        >
                        </form-chamber>
                    </template>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <base-btn @click="close" type="secondary">
                            {{ $t("btn-cancel") }}
                        </base-btn>
                        <base-btn
                            v-if="!readonlyModus"
                            :disabled="readonlyModus"
                            @click="onBtnSaveChamberClick"
                            type="primary"
                        >
                            {{ $t("btn-save") }}
                        </base-btn>
                    </template>
                </base-dialog>
            </v-col>
        </v-row>
        <v-row v-if="dialogCompare && isBackoffice">
            <v-col>
                <dialog-chamber-fields-diff
                    v-if="dialogCompare && isBackoffice"
                    :value="dialogCompare"
                    @input="dialogCompare = $event"
                    :title="
                        editedIndex === -1
                            ? $t('title-add-chamber', { chamberNr: chamberId })
                            : $t('title-edit-chamber', { chamberNr: chamberId })
                    "
                    :readonlyModus="readonlyModus"
                    :chamberId="chamberId"
                    :originalData="originalData"
                    :diffData="diffData"
                    @save="onChamberSave"
                >
                </dialog-chamber-fields-diff>
            </v-col>
        </v-row>
        <app-dev-container>
            <template v-slot:content>
                <tree-view
                    :data="chambers"
                    :options="{ maxDepth: 1 }"
                ></tree-view>
            </template>
        </app-dev-container>
    </v-container>
</template>
<script>
import ChamberCard from "@/components/tasks/edit/common/components/chamber-card.vue";
import FormChamber from "@/components/tasks/edit/common/forms/form-chamber.vue";
import _ from "lodash";
const MAX_CHAMBERS = 4; //defines how much chambers the user cann add
import DialogChamberFieldsDiff from "@/components/tasks/edit/backoffice/components/dialog-chamber-fields-diff.vue";
import Helpers from "@/utils/helpers";
import StepContentTitle from "@/components/tasks/edit/common/components/step-content-title.vue";
export default {
    name: "StepChambersContent",
    components: {
        ChamberCard,
        FormChamber,
        DialogChamberFieldsDiff,
        StepContentTitle
    },

    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        isBackoffice: {
            type: Boolean,
            default: false
        },
        registration: {
            type: Object,
            default: () => {},
            required: true
        },
        registrationToCompare: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        dialog: false,
        editedIndex: -1,
        chamberId: 1,
        maxChambers: MAX_CHAMBERS,
        validationStarted: false,
        dialogCompare: false
    }),

    methods: {
        onChamberChange(chamber) {
            this.$emit("change-chamber", chamber);
        },
        onRemoveChamberByIndex(index) {
            this.$emit("remove-chamber-by-index", index);
        },
        validate() {
            this.validationStarted = true;
        },
        isValid() {
            if (!this.validationStarted) {
                return null;
            }
            if (this.chambers.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        reset() {
            this.$refs.formChamber.reset();
        },
        btnAddChamberClick() {
            this.editedIndex = -1;
            if (this.chambers.length < MAX_CHAMBERS) {
                this.chamberId = this.chambers.length + 1;
                this.dialog = true;
            }
        },
        onChamberSave(chamber) {
            return this.$emit("change-chamber", chamber);
        },
        scrollToFirstErrorWithScrollOptions() {
            let baseDialogCardTextRef =
                this.$refs.dialog.$refs.baseDialogCardText;
            let scrollOptions = {
                container: baseDialogCardTextRef
            };
            this.$refs.formChamber.scrollToFirstError(scrollOptions);
        },
        onBtnSaveChamberClick() {
            if (this.readonlyModus) {
                this.close();
                return;
            }
            this.$refs.formChamber.validate();
            if (!this.$refs.formChamber.isValid()) {
                this.scrollToFirstErrorWithScrollOptions();
                return;
            }
            const data = this.$refs.formChamber.getData();
            this.onChamberSave(data);
            this.close();
        },
        close() {
            this.dialog = false;
            this.$refs.formChamber.reset();
            this.editedIndex = -1;
        },
        removeChamberByIndex(index) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-chamber-title"),
                    icon: "mdi-alert"
                },
                buttons: [
                    {
                        name: "cancel",
                        text: this.$t("msg-box-btn-cancel"),
                        position: 2,
                        type: "secondary"
                    },
                    {
                        name: "agree",
                        text: this.$t("msg-box-btn-agree"),
                        position: 1,
                        type: "primary"
                    }
                ],
                message: this.$t("msg-box-delete-chamber-text", {
                    chamberNr: index + 1
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("remove-chamber-by-index", index);
                }
            });
        },
        onEditChamber(item) {
            this.editedIndex = this.chambers.indexOf(item);
            this.editedItem = _.assign({}, item);
            this.chamberId = this.chambers.indexOf(item) + 1;
            if (this.isBackoffice && this.chamberToCompareExists) {
                this.dialogCompare = true;
            } else {
                this.dialog = true;
                this.$nextTick(() => {
                    this.$refs.formChamber.setData(item);
                });
            }
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        getTotalChambersVolume() {
            let volume = 0.0;
            if (this.chambers.length > 0) {
                this.chambers.forEach((value) => {
                    volume = volume + parseFloat(value.volume);
                });
                return parseFloat(volume).toFixed(2);
            } else {
                return 0.0;
            }
        },
        getChamberForCompare(registration, chamberIdx) {
            if (chamberIdx === -1) return {};
            if (!registration) return {};
            let chamber = {};
            if (registration.chambers) {
                chamber = registration.chambers[chamberIdx];
            }
            if (chamber) {
                return {
                    chamber: chamber,
                    chamberId: chamberIdx,
                    activelyHeated: Helpers.isActivelyHeated(registration)
                };
            }
            return {};
        }
    },

    computed: {
        chamberCardCls() {
            const chambersLength = this.chambers.length;
            if (chambersLength <= 1) {
                return "md6";
            }
            if (this.isBackoffice) {
                if (chambersLength === 2) {
                    if (this.readonlyModus) return "md6";
                    return "md4";
                }
                if (chambersLength === 3) {
                    if (this.readonlyModus) return "md4";
                    return "md6";
                }
                return "md6";
            } else {
                if (chambersLength <= 2) {
                    return "md4";
                }
                return "md3";
            }
        },
        activelyHeated() {
            return Helpers.isActivelyHeated(this.registration);
        },
        chambers() {
            return this.registration.chambers;
        },
        chamberColor() {
            let color = null;
            if (this.isValid()) {
                color = "primary secondary--text";
            } else if (!this.isValid() && this.validationStarted) {
                color = "error";
            } else {
                color = "primary secondary--text";
            }
            return color;
        },
        originalData() {
            const idx = this.editedIndex;
            return this.getChamberForCompare(this.registrationToCompare, idx);
        },
        diffData() {
            const idx = this.editedIndex;
            return this.getChamberForCompare(this.registration, idx);
        },
        chamberToCompareExists() {
            if (!this.originalData) return false;
            if (!this.originalData.chamber) return false;
            return true;
        }
    }
};
</script>
