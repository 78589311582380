var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"title":_vm.$t('file-infos-title'),"width":"450","icon":"mdi-information-outline"},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.file)?_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"my-1",attrs:{"align":"center","justify":"center"}},[_c('Photoswipe',{attrs:{"bubble":""}},[(_vm.file.fileType == 'img')?_c('v-card',{attrs:{"tile":"","max-height":"150px"}},[_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-img',{directives:[{name:"pswp",rawName:"v-pswp",value:(
                                    _vm.$axiosBinary.defaults.baseURL + _vm.file.url
                                ),expression:"\n                                    $axiosBinary.defaults.baseURL + file.url\n                                "}],staticClass:"align-end",attrs:{"src":_vm.$axiosBinary.defaults.baseURL +
                                    _vm.file.thumbnail.url,"lazy-src":_vm.$axiosBinary.defaults.baseURL +
                                    _vm.file.thumbnail.url,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)","aspect-ratio":"1","contain":"","height":"100","max-height":"100","min-width":"100"},on:{"error":function($event){return _vm.$toast.error('Error loading image')}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)})],1)],1):_vm._e()],1),(_vm.file.fileType == 'pdf')?_c('v-icon',{staticClass:"ma-4",attrs:{"size":"60"}},[_vm._v(" mdi-file-pdf ")]):_vm._e()],1),(_vm.file)?_c('v-container',[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-divider'),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[(_vm.file.originalFullFileName)?_c('v-list-item',[_c('v-list-item-content',[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(_vm.$t("file-name"))+" ")],1)]),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v(_vm._s(_vm.file.originalFullFileName))])],1):_vm._e(),(_vm.file.fileSize && _vm.file.fileType)?_c('v-list-item',[_c('v-list-item-content',[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-move-resize-variant")]),_vm._v(" "+_vm._s(_vm.$t("file-type"))+", "+_vm._s(_vm.$t("file-size"))+" ")],1)]),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v(_vm._s(_vm.file.fileType == "img" ? _vm.$t("img") : _vm.$t("pdf"))+", "+_vm._s(_vm.formatBytes(_vm.file.fileSize)))])],1):_vm._e(),(_vm.file.uploader && _vm.file.taskState)?_c('v-list-item',[_c('v-list-item-content',[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$t("file-uploader"))+" ")],1)]),_c('v-list-item-content',{staticClass:"align-end"},[(_vm.file.taskState)?_c('span',[_vm._v(" "+_vm._s(_vm.file.uploader.firstName)+" "+_vm._s(_vm.file.uploader.familyName)+" "),(
                                                _vm.file.taskState === 20 ||
                                                _vm.file.taskState === 50
                                            )?_c('span',[_vm._v("("+_vm._s(_vm.$t("registrant"))+")")]):(_vm.file.taskState)?_c('span',[_vm._v("("+_vm._s(_vm.$t("backoffice"))+")")]):_vm._e()]):_vm._e()])],1):_vm._e(),(_vm.file.uploader)?_c('v-list-item',[_c('v-list-item-content',[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.$t("file-uploader-timestamp"))+" ")],1)]),_c('v-list-item-content',{staticClass:"align-end"},[_c('span',[_vm._v("am "+_vm._s(_vm.$moment .utc( _vm.file.uploader.timestamp ) .local() .format("DD.MM.YYYY HH:mm")))])])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1):_vm._e()]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }