<i18n src="./registration-dialog-modal-edit-i18n.yaml"></i18n>
<template>
    <v-dialog :value="dialog" persistent max-width="800">
        <v-card flat outlined>
            <v-toolbar
                :color="$vuetify.theme.isDark ? '' : 'cardHeaderGrey'"
                class="primary--text"
                flat
            >
                <v-icon>mdi-comment-question</v-icon>
                <v-toolbar-title class="pl-2">{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <base-btn icon @click="$emit('cancel')">
                        <v-icon>mdi-close</v-icon>
                    </base-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pa-6">
                <base-textarea
                    :value="text"
                    @input="$emit('text-change', $event)"
                    :label="label"
                    :placeholder="placeholder"
                    clearable
                    autofocus
                    no-resize
                    :counter="$v.text.$params.maxLength.max"
                    :error-messages="textErrors"
                    :required="isRequiredField($v.text)"
                />
            </v-card-text>
            <v-card-actions class="px-6">
                <v-spacer></v-spacer>
                <base-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    outlined
                    @click="$emit('cancel')"
                >
                    <v-icon class="mr-1">mdi-close</v-icon>
                    {{ $t("btn-cancel") }}
                </base-btn>
                <base-btn @click="onSaveAndClose">
                    <v-icon class="mr-1">mdi-content-save</v-icon>
                    {{ $t("btn-save-and-close") }}
                </base-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
export default {
    name: "RegistrationDialogModalEdit",
    mixins: [validateMixin],
    validations: {
        text: {
            required,
            maxLength: maxLength(500)
        }
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    computed: {
        textErrors() {
            const errors = [];
            if (!this.$v.text.$dirty) {
                return errors;
            }
            if (!this.$v.text.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.text.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.text.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },
    methods: {
        onSaveAndClose() {
            this.validate();
            if (this.$v.$invalid) {
                this.$toast.error(this.$t("has-errors-msg"));
            } else {
                this.$emit("save");
            }
        }
    }
};
</script>
