<template>
    <v-container class="px-2" @click="$emit('click:container')">
        <form-equipment-details
            ref="formEquipmentDetails"
            v-on:field-change="onFieldChange"
            :registrationData.sync="registration"
            :readonlyModus="readonlyModus"
            v-bind="$attrs"
            v-on="$listeners"
        >
        </form-equipment-details>
    </v-container>
</template>
<script>
import FormEquipmentDetails from "@/components/tasks/edit/common/forms/form-equipment-details.vue";
export default {
    name: "StepObjectPropertiesContent",
    props: {
        registration: {
            type: Object,
            default: () => {},
            required: true
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FormEquipmentDetails
    },

    methods: {
        onFieldChange({ field, value }) {
            if (!this.readonlyModus) {
                this.$emit("field-change", { field, value });
            }
        },
        validate() {
            return this.$refs.formEquipmentDetails.validate();
        },
        reset() {
            this.$refs.formEquipmentDetails.reset();
        },
        isValid() {
            return this.$refs.formEquipmentDetails.isValid();
        },
        scrollToFirstError() {
            return this.$refs.formEquipmentDetails.scrollToFirstError();
        }
    }
};
</script>
