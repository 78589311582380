<template>
    <AppWrapperContainer v-bind="$attrs">
        <v-row>
            <v-col>
                <slot />
            </v-col>
        </v-row>
    </AppWrapperContainer>
</template>
<script>
import AppWrapperContainer from "./app-wrapper-container.vue";
export default {
    name: "AppWrapper",
    components: {
        AppWrapperContainer
    }
};
</script>
