<i18n src="./content-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <base-card tile>
        <BackofficeBaseList
            class="flat"
            :items="tasks"
            :headers="headers"
            :actions="actions"
            :title="$t('title')"
            @reloadDataClick="fetchData"
            :funcShowAction="showAction"
            item-key="docId"
            @task:force-take-over="onTaskForceTakeOver"
            @task:lock-for-review="onTaskLockForReview"
            @task:unlock="onTaskUnlock"
            @task:delete="onTaskDelete"
            @task:remove-from-db="onTaskRemoveFromDB"
        >
            <template v-slot:prepend>
                <v-toolbar flat>
                    <v-text-field
                        v-model="searchField"
                        solo
                        flat
                        :prepend-icon="
                            showFilter
                                ? 'mdi-filter-variant-plus'
                                : 'mdi-filter-variant'
                        "
                        append-icon="mdi-magnify"
                        :placeholder="$t('search-field-hint')"
                        :label="$t('search-field-label')"
                        hide-details
                        clearable
                        @keyup.enter="handleApplyFilter"
                        @click:append="handleApplyFilter"
                        @click:prepend="showFilter = !showFilter"
                        :loading="isLoader"
                        @click:clear="handleClear"
                    >
                        <template v-slot:progress>
                            <v-progress-linear
                                v-show="isLoader"
                                color="info"
                                indeterminate
                                absolute
                                height="3"
                            ></v-progress-linear>
                        </template>
                    </v-text-field>
                    <v-btn
                        icon
                        @click="handleRefreshItem"
                        v-if="searchField && tasks.length > 0"
                    >
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider />
                <v-card
                    v-show="showFilter"
                    flat
                    class="grey lighten-4"
                    outlined
                >
                    <v-card-text>
                        <v-row>
                            <v-col
                                v-for="item in filterItems"
                                :key="item.value"
                                xs="6"
                            >
                                <v-checkbox
                                    v-model="filters"
                                    :label="item.text"
                                    :value="item.value"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="developerMode">
                            <v-col>
                                <v-btn color="warning" @click="reindex">
                                    <v-icon>mdi-database</v-icon>
                                    Reindex All
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="info" @click="fetchAllTasks">
                                    <v-icon class="mr-1">mdi-database</v-icon>
                                    GET ALL
                                </v-btn>
                                <v-spacer />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <base-btn type="close" @click="handleResetFilter">{{
                            $t("btn-filter-reset")
                        }}</base-btn>
                        <base-btn type="success" @click="handleApplyFilter">{{
                            $t("btn-filter-apply")
                        }}</base-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </BackofficeBaseList>
        <DialogViewData
            v-if="dataDialog"
            :docId="selectedRegistration.docId"
            :value="dataDialog"
            @input="dataDialog = $event"
        />
    </base-card>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import taskList from "@/mixins/backoffice/taskList";
import { TaskActionTypes } from "@/data/task-action-types";
import BackofficeBaseList from "@/components/backoffice/components/base-list.vue";
import { TaskStatus } from "@/data/globals";

export default {
    name: "BackofficeSearchContent",
    mixins: [taskList],
    components: {
        BackofficeBaseList
    },
    data() {
        return {
            dataDialog: false,
            tasks: [],
            selectedRegistration: {},
            options: {},
            actionsItems: [
                TaskActionTypes.VIEW,
                TaskActionTypes.VIEW_CHECK,
                TaskActionTypes.DATA,
                TaskActionTypes.LOCK_FOR_REVIEW,
                TaskActionTypes.UNLOCK_TASK,
                TaskActionTypes.FORCE_TAKE_OVER,
                TaskActionTypes.DELETE,
                TaskActionTypes.REMOVE_FROM_DB,
                TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP,
                TaskActionTypes.VIEW_HISTORY
            ],
            filters: [],
            searchField: null,
            searchMinLength: 2,
            isLoader: false,
            page: 0,
            limit: 15,
            numberOfHits: null,
            showFilter: false
        };
    },

    methods: {
        fetchAllTasks() {
            this.loader().show(this.$t("loader-msg-fetch-all-tasks"));
            this.$axiosBackoffice
                .get("/")
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data;
                        const result = resData.data;
                        this.numberOfHits = result.total;
                        this.tasks = result.items;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-occured"));
                    this.loader().hide();
                });
        },
        searchTasks(data) {
            if (
                !data ||
                !data.query ||
                data.query.length < this.searchMinLength
            ) {
                this.isLoader = false;
                return;
            }
            this.isLoader = true;
            this.$axiosBackoffice
                .get("/searchTasks", { params: data })
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data.data;
                        let itemsData = resData.items;
                        this.numberOfHits = resData.total;
                        this.tasks.push(...itemsData);
                    }
                    this.isLoader = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-occured"));
                    this.isLoader = false;
                });
        },
        onTaskLockForReview(task) {
            this.loader().show(this.$t("loader-msg-lock-for-review"));
            let data = {};
            data.docId = task.docId;
            this.$axiosBackoffice
                .put(`/lockForReview/${task.docId}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("lock-for-review-msg-success")
                        );
                        this.$store.commit(
                            "backoffice/removeRegistrationFromList",
                            {
                                list: "pendingPoolRegistrations",
                                docId: task.docId
                            }
                        );
                    }
                    this.handleRefreshItem();
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("lock-for-review-msg-error"));
                    console.log(error);
                    this.loader().hide();
                });
        },
        onTaskRemoveFromDB(task) {
            this.loader().show(this.$t("loader-msg-remove-task"));
            this.$store
                .dispatch("backoffice/removeTaskFromDbByDocId", task.docId)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-remove-task-from-db-success")
                        );
                    }
                    this.handleRefreshItem();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-remove-task-from-db-error"));
                    this.loader().hide();
                });
        },

        onTaskDelete(task, result) {
            this.loader().show(this.$t("loader-msg-delete-task"));
            const data = {
                docId: task.docId,
                reason: result.reason
            };
            this.$store
                .dispatch("backoffice/deletePendingPoolRegistration", data)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-delete-task-success"));
                    }
                    this.handleRefreshItem();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-delete-task-error"));
                    this.loader().hide();
                });
        },
        onTaskUnlock(task) {
            this.loader().show(this.$t("loader-msg-unlock-task"));
            const taskDocId = task.docId;
            this.$store
                .dispatch("backoffice/registrationUnlockForReview", taskDocId)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-unlock-success"));
                        this.$store.commit(
                            "backoffice/removeRegistrationFromList",
                            {
                                list: "openReplies",
                                docId: task.docId
                            }
                        );
                    }
                    this.handleRefreshItem();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-unlock-error"));
                    this.loader().hide();
                });
        },
        onTaskForceTakeOver(task) {
            this.loader().show(this.$t("loader-msg-force-take-over"));
            const taskDocId = task.docId;
            this.$store
                .dispatch("backoffice/registrationForceTakeOver", taskDocId)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-force-take-over-success")
                        );
                    }
                    this.handleRefreshItem();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    const errorCode = error?.response?.data?.code;
                    switch (errorCode) {
                        case 1:
                            this.$toast.error(
                                this.$t("msg-force-take-over-error-1")
                            );
                            break;
                        case 2:
                            this.$toast.error(
                                this.$t("msg-force-take-over-error-2")
                            );
                            break;
                        case 3:
                            this.$toast.error(
                                this.$t("msg-force-take-over-error-3")
                            );
                            break;
                        default:
                            this.$toast.error(
                                this.$t("msg-force-take-over-error")
                            );
                            break;
                    }
                    this.loader().hide();
                });
        },
        reindex() {
            this.loader().show(this.$t("loader-msg-tasks-reindex"));
            this.$axiosBackoffice
                .get("/reindexTasksSearch")
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-tasks-reindexed"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error("Fehler: " + error);
                    this.loader().hide();
                });
        },
        reset() {
            this.tasks = [];
            this.numberOfHits = null;
            this.page = 0;
            this.limit = 15;
        },
        startSearch(val) {
            if (val) {
                val = val.trim();
            }
            this.tasks = [];
            if (!val || val.length < this.searchMinLength) {
                if (this.tasks.length > 0) {
                    this.tasks = [];
                }
            } else {
                this.isLoader = true;
                this.page = 0;
                this.fetchData();
            }
        },
        showAction(item, actionItem) {
            const itemState = item.state;
            const action = actionItem.action;
            let boAccountDocId = item?.boEmployee?.docId;

            if (item.deletedState > 0) return false;
            if (
                action === TaskActionTypes.DELETE ||
                action === TaskActionTypes.REMOVE_FROM_DB ||
                action === TaskActionTypes.VIEW_CHECK
            ) {
                if (itemState >= TaskStatus.TASK_CONFIRMED) return false;
            }
            if (action === TaskActionTypes.VIEW) {
                if (boAccountDocId === this.accountDocId) {
                    if (itemState > TaskStatus.TASK_CONFIRMED) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            if (
                action === TaskActionTypes.VIEW_CHECK ||
                action === TaskActionTypes.UNLOCK_TASK
            ) {
                if (boAccountDocId !== this.accountDocId) {
                    return false;
                }
            }
            if (action === TaskActionTypes.LOCK_FOR_REVIEW) {
                if (boAccountDocId) {
                    return false;
                }
                if (
                    itemState < TaskStatus.TASK_REGISTRANT_COMPLETED ||
                    itemState >= TaskStatus.TASK_CONFIRMED
                ) {
                    return false;
                }
            }
            if (action === TaskActionTypes.FORCE_TAKE_OVER) {
                if (boAccountDocId === this.accountDocId || !boAccountDocId) {
                    return false;
                }
            }
            if (
                action === TaskActionTypes.UNLOCK_TASK ||
                action === TaskActionTypes.FORCE_TAKE_OVER
            ) {
                if (
                    itemState <
                        TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS ||
                    itemState >= TaskStatus.TASK_CONFIRMED
                ) {
                    return false;
                }
            }
            return true;
        },
        handleClear() {
            this.reset();
        },
        handleRefreshItem() {
            this.startSearch(this.searchField);
        },
        // filter
        handleResetFilter() {
            this.filters = [];
        },
        handleApplyFilter() {
            this.showFilter = false;
            this.startSearch(this.searchField);
        }
    },

    computed: {
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        ...mapState("auth", ["accountDocId"]),
        actions() {
            return this.actionsItems.filter(
                (action) =>
                    (action.isDevel && this.developerMode) || !action.isDevel
            );
        },
        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-registrant"),
                    value: "registrant",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-employee"),
                    value: "boEmployee",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-dates"),
                    value: "boDates",
                    sortable: true,
                    align: "left",
                    isMobile: false
                }
            ];
        },
        computedTitle() {
            return this.$t("title") + " (" + this.tasks.length + ")";
        },
        filterItems() {
            let items = [
                { text: this.$t("filter-deleted"), value: "deleted" },
                { text: this.$t("filter-completed"), value: "completed" }
            ];
            return items;
        }
    },

    watch: {
        computedTitle: {
            handler: function (value) {
                if (value) {
                    this.$store.commit("backoffice/setTitle", value);
                }
            },
            immediate: true
        },
        searchField(val) {
            this.startSearch(val);
        }
    },

    destroyed() {
        this.$store.commit("backoffice/setDefaulTitle");
    },

    created() {
        this.filterField = "state";
        this.fetchData = _.debounce(() => {
            let data = {
                query: this.searchField,
                start: this.page,
                limit: this.limit,
                filters: this.filters
            };
            this.searchTasks(data);
        }, 1000);
    }
};
</script>
