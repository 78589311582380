<i18n src="./address-i18n.yaml"></i18n>
<template>
    <v-container fluid class="pa-0 ma-0" ref="addressContainer">
        <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, computedAddress)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-check-circle</v-icon>
                </base-btn>
                <base-btn icon color="error" @click="reset">
                    <v-icon>mdi-lock-reset</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="setFakeCompanyData">
                    <v-icon>mdi-office-building</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="setFakePrivateData">
                    <v-icon>mdi-account</v-icon>
                </base-btn>
                <base-btn icon color="error" @click="clear">
                    <v-icon>mdi-delete</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <v-row dense>
            <v-col cols="12">
                <base-card-text dense :outlined="outlined" class="px-2">
                    <template v-slot:content>
                        <FormAddressBase
                            ref="formAddressBase"
                            :value="computedAddress"
                            :readonly="readonly"
                            :disabled="disabled"
                            @input:field="emitOnFieldInput"
                            @change:company-search="onChangeCompanySearch"
                            @change:address-type="setAddressByType"
                        />
                    </template>
                </base-card-text>
            </v-col>
            <v-col
                cols="12"
                v-show="computedAddress.addressType && computedAddress.country"
            >
                <base-card-text dense :outlined="outlined" class="px-2">
                    <template v-slot:content>
                        <FormAddressPrivate
                            v-show="computedAddress.addressType === 'PRIVATE'"
                            ref="formAddressPrivate"
                            :value="computedAddress"
                            :readonly="readonly"
                            :disabled="disabled"
                            @input:field="emitOnFieldInput"
                            @input="emitOnChangeAddress($event)"
                        />

                        <FormAddressCompany
                            v-show="computedAddress.addressType === 'COMPANY'"
                            ref="formAddressCompany"
                            :value="computedAddress"
                            :readonly="readonly"
                            :disabled="disabled"
                            :country="computedAddress.country"
                            @input:field="emitOnFieldInput"
                            @input="emitOnChangeAddress($event)"
                        />

                        <FormAddress
                            ref="formAddress"
                            :value="computedAddress"
                            :readonly="readonly"
                            :disabled="disabled"
                            :country="computedAddress.country"
                            @input:field="emitOnFieldInput"
                            @input="emitOnChangeAddress($event)"
                        />
                    </template>
                </base-card-text>
            </v-col>
            <v-col
                cols="12"
                v-show="computedAddress.addressType && computedAddress.country"
            >
                <base-card-text dense :outlined="outlined" class="px-2">
                    <template v-slot:content>
                        <v-row dense>
                            <v-col cols="12">
                                <v-switch
                                    v-model="switchCommunication"
                                    :readonly="readonly"
                                    :disabled="disabled"
                                    class="ml-3 py-0"
                                    :label="$t('switch-communication-label')"
                                >
                                </v-switch>
                            </v-col>
                        </v-row>
                        <FormAddressCommunication
                            v-show="switchCommunication"
                            ref="formAddressCommunication"
                            :value="computedAddress.communication"
                            :readonly="readonly"
                            :disabled="disabled"
                            @input:field="onInputCommunicationField"
                            required
                        />
                    </template>
                </base-card-text>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {
    CompanyAddress,
    PrivateAddress,
    Communication,
    Address,
    BaseAddress
} from "@/models/tasks";
import { ADDRESS_TYPES } from "@/data/globals.js";
import _ from "lodash";
import mixin from "./mixin";
import validateMixin from "@/mixins/validate";
import Helpers from "@/utils/helpers";
export default {
    name: "Address",
    mixins: [validateMixin, mixin],
    components: {
        FormAddress: () => import("./form-address.vue"),
        FormAddressBase: () => import("./form-address-base.vue"),
        FormAddressCompany: () => import("./form-address-company.vue"),
        FormAddressPrivate: () => import("./form-address-private.vue"),
        FormAddressCommunication: () =>
            import("./form-address-communication.vue")
    },
    props: {
        value: {
            type: Object,
            default: () => new Address()
        },
        outlined: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        ADDRESS_TYPES,
        switchCommunication: false,
        address: new Address()
    }),
    methods: {
        emitOnFieldInput({ field, value }) {
            this.mergeAddress({ [field]: value });
            this.$emit("input:field", { field, value });
        },
        emitOnChangeAddress(address) {
            this.$emit("change", address);
        },
        mergeAddress(data) {
            this.address = Object.assign(this.address, data);
        },
        checkIfComminicationIsEmpty(data) {
            return Helpers.isComminicationFieldEmpty(data);
        },
        onChangeCompanySearch(company) {
            if (company) {
                let address = this.getAddressModelByType(ADDRESS_TYPES.COMPANY);
                company.companyName = company.name;

                address = Helpers.assignObjOnlyIfKeysExists(address, company);
                this.mergeAddress(address);
                this.emitOnChangeAddress(address);
            }
        },
        getAddressModelByType(type) {
            let address = {};
            if (type === ADDRESS_TYPES.COMPANY) {
                address = Helpers.assignObjOnlyIfKeysExists(
                    new CompanyAddress(),
                    this.computedAddress
                );
            } else {
                address = Helpers.assignObjOnlyIfKeysExists(
                    new PrivateAddress(),
                    this.computedAddress
                );
            }
            return address;
        },
        setAddressByType(type) {
            let address = this.getAddressModelByType(type);
            address.addressType = type;
            this.emitOnChangeAddress(address);
            this.mergeAddress(address);
        },
        onInputCommunicationField({ field, value }) {
            this.emitOnFieldInput({
                field: `communication.${field}`,
                value: value
            });
        },
        validate() {
            this.$refs.formAddressBase?.validate();
            if (
                this.computedAddress?.addressType &&
                this.computedAddress?.country
            ) {
                if (
                    this.computedAddress.addressType === ADDRESS_TYPES.COMPANY
                ) {
                    this.$refs.formAddressCompany?.validate();
                } else {
                    this.$refs.formAddressPrivate?.validate();
                }
                if (this.switchCommunication) {
                    this.$refs.formAddressCommunication?.validate();
                }
                this.$refs.formAddress?.validate();
            }
        },
        isValid(scrollOptions) {
            let isValid = null;
            isValid = this.$refs?.formAddressBase?.isValid(scrollOptions);
            if (isValid === null) return null;
            if (
                this.computedAddress?.addressType &&
                this.computedAddress?.country
            ) {
                if (
                    this.computedAddress.addressType === ADDRESS_TYPES.COMPANY
                ) {
                    isValid =
                        this.$refs?.formAddressCompany?.isValid(scrollOptions);
                    if (isValid === false) {
                        this.$refs?.formAddressCompany?.scrollToFirstError(
                            scrollOptions
                        );
                        return false;
                    }
                } else {
                    isValid =
                        this.$refs?.formAddressPrivate?.isValid(scrollOptions);
                    if (isValid === false) {
                        this.$refs?.formAddressPrivate?.scrollToFirstError(
                            scrollOptions
                        );
                        return false;
                    }
                }

                isValid = this.$refs?.formAddress?.isValid(scrollOptions);
                if (isValid === false) {
                    this.$refs?.formAddress?.scrollToFirstError(scrollOptions);
                    return false;
                }
                if (this.switchCommunication) {
                    isValid =
                        this.$refs?.formAddressCommunication?.isValid(
                            scrollOptions
                        );
                    if (isValid === false) {
                        this.$refs?.formAddressCommunication?.scrollToFirstError(
                            scrollOptions
                        );
                        return false;
                    }
                }
            }
            return isValid;
        },
        reset() {
            this.$refs.formAddressBase.reset();
            if (
                this.computedAddress?.addressType &&
                this.computedAddress?.country
            ) {
                if (
                    this.computedAddress.addressType === ADDRESS_TYPES.COMPANY
                ) {
                    this.$refs.formAddressCompany.reset();
                } else {
                    this.$refs.formAddressPrivate.reset();
                }
                this.$refs.formAddress.reset();
                this.$refs.formAddressCommunication.reset();
            }
        },
        clear() {
            this.address = new Address();
            this.$emit("clear");
        },
        setFakeCompanyData() {
            let address = new CompanyAddress();
            address.poBox = false;
            address.poBoxNr = null;
            address.poBoxZipCode = null;
            address.poBoxCity = null;
            address.companyName = "WAS.ch Webapplications & Services GmbH";
            address.department = null;
            address.uid = 275152675;
            address.uidCategory = "CHE";
            address.uidFormatted = "CHE-101.813.302";
            address.sameAddressAsOperator = false;
            address = _.merge(address, this.getFakeAddressData());
            this.emitOnChangeAddress(address);
        },
        setFakePrivateData() {
            let address = new PrivateAddress();
            address.salutation = "0002";
            address.firstName = "Joe";
            address.familyName = "Doe";
            address = _.merge(address, this.getFakeAddressData());
            this.emitOnChangeAddress(address);
        },
        getFakeAddressData() {
            let address = new BaseAddress();
            address.additionalAddressLines = false;
            address.addressLine1 = null;
            address.addressLine2 = null;
            address.street = "Fürstenlandstrasse";
            address.houseNr = "99";
            address.canton = "SG";
            address.zipCode = 9014;
            address.city = "St. Gallen";
            address.country = "CH";
            address.communication = this.getFakeCommunicationData();
            return address;
        },
        getFakeCommunicationData() {
            let communication = new Communication();
            communication.phone = "+41 79 875 77 00";
            communication.email = "joe.doe@was.ch";
            communication.fax = null;
            return communication;
        }
    },
    mounted() {
        if (this.value) {
            const isComminicationIsEmpty = this.checkIfComminicationIsEmpty(
                this.value?.communication
            );
            if (!isComminicationIsEmpty) {
                this.switchCommunication = true;
            } else {
                this.switchCommunication = false;
            }
        }
    },

    watch: {
        value: {
            handler: function (value) {
                if (value) {
                    this.reset();
                    const isComminicationIsEmpty =
                        this.checkIfComminicationIsEmpty(value.communication);
                    if (!isComminicationIsEmpty) {
                        this.switchCommunication = true;
                    } else {
                        this.switchCommunication = false;
                    }
                    this.mergeAddress(value);
                } else {
                    this.switchCommunication = false;
                }
            },
            deep: true
        },
        address: {
            handler: function (value) {
                this.$emit("input", value);
            },
            deep: true
        }
    }
};
</script>
