var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialog),expression:"dialog"}],attrs:{"title":_vm.$t('title'),"icon":"mdi-history"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-card',{attrs:{"flat":"","fluid":"","width":"100%"}},[_c('v-card-text',[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" @items: "),_c('tree-view',{attrs:{"data":_vm.items,"options":{ maxDepth: 1 }}})]},proxy:true}])}),(_vm.items.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","loading":_vm.isLoading,"sort-by":_vm.dataTableOptions.sortBy,"sort-desc":_vm.dataTableOptions.sortDesc,"dense":""},on:{"click:row":_vm.onRowItemClick,"update:sortBy":function($event){return _vm.$set(_vm.dataTableOptions, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.dataTableOptions, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.dataTableOptions, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.dataTableOptions, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category)?_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.getIconAndColorByHistoryItem( item ).icon))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t( ("category-" + (_vm.FILTERS.kebabizeLower( item.category ))) ))+" ")])],1):_vm._e()]}},{key:"item.userFirstName",fn:function(ref){
var item = ref.item;
return [(item.userFirstName)?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.itemUserFullName(item))+" ")],1):_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog")]),_vm._v(" SYSTEM ")],1)]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [(item.code)?_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.FILTERS.kebabizeLower(item.code)))+" ")]):_vm._e()]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [(item.timestamp)?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock")]),_c('base-date-time',{attrs:{"timestamp":item.timestamp}})],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.onRowItemClick(item)}}},[_vm._v(" mdi-eye ")])]}}],null,false,1713004956)}):_c('div',{staticClass:"text-h5"},[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"x-large","color":"warning"}},[_vm._v("mdi-exclamation")]),_c('span',[_vm._v(_vm._s(_vm.$t("msg-no-data")))])],1),_c('div',{staticClass:"text-center"},[_c('base-pagination',{attrs:{"length":_vm.totalPages},on:{"input":_vm.onPaginationInput},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('base-dialog',{attrs:{"title":_vm.$t('title-info-dialog'),"icon":"mdi-information","max-width":"500"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.selectedItem)?_c('v-container',[_c('i18n',{attrs:{"path":("info-" + (_vm.FILTERS.kebabizeLower(
                            _vm.selectedItem.code
                        ))),"tag":"span"},scopedSlots:_vm._u([{key:"user",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.itemUserFullName(_vm.selectedItem)))])]},proxy:true},{key:"timestamp",fn:function(){return [_c('base-date-time',{attrs:{"timestamp":_vm.selectedItem.timestamp,"clsDate":"font-weight-bold","clsTime":"font-weight-bold"}})]},proxy:true},{key:"manufacturerName",fn:function(){return [(
                                    _vm.itemHasExtras(_vm.selectedItem) &&
                                    _vm.selectedItem.extras.manufacturer_name
                                )?_c('strong',[_vm._v(_vm._s(_vm.selectedItem.extras.manufacturer_name))]):_vm._e()]},proxy:true},{key:"reason",fn:function(){return [(
                                    _vm.itemHasExtras(_vm.selectedItem) &&
                                    _vm.selectedItem.extras.reason
                                )?_c('strong',[_vm._v(_vm._s(_vm.selectedItem.extras.reason))]):_vm._e()]},proxy:true},(_vm.itemHasExtras(_vm.selectedItem))?{key:"taskRefId",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.selectedItem.extras.taskRefId))])]},proxy:true}:null,(_vm.itemHasExtras(_vm.selectedItem))?{key:"taskRefIdCopy",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.selectedItem.extras.task_ref_id_copy))])]},proxy:true}:null,(_vm.itemHasExtras(_vm.selectedItem))?{key:"sapResponse",fn:function(){return [_c('div',[_c('base-alert',{attrs:{"type":"warning","dense":""}},[_c('b',[_vm._v(_vm._s(_vm.selectedItem.extras.response.detail .message))])])],1)]},proxy:true}:null],null,true)}),(_vm.itemHasExtras(_vm.selectedItem) && _vm.developerMode)?_c('div',[_c('tree-view',{attrs:{"data":_vm.selectedItem.extras,"options":{ maxDepth: 2 }}})],1):_vm._e()],1):_vm._e()]},proxy:true}]),model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}})]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }