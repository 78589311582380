<i18n src="./form-address-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, computedAddress)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @validate:
                <tree-view
                    :data="Object.assign({}, $v)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-times</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <v-row dense>
            <v-col cols="12">
                <v-switch
                    class="ml-3"
                    :value="computedAddress.additionalAddressLines"
                    @change="
                        emitOnFieldInput(
                            'additionalAddressLines',
                            !computedAddress.additionalAddressLines
                        )
                    "
                    :label="$t('additional-address-lines')"
                >
                </v-switch>
            </v-col>

            <template v-if="computedAddress.additionalAddressLines == true">
                <v-col cols="12" md="6">
                    <!-- addressLine1 -->
                    <base-text-field
                        :value="computedAddress.addressLine1"
                        @input="emitOnFieldInput('addressLine1', $event)"
                        :label="$t('address-line-1-label')"
                        :error-messages="addressLine1Errors"
                        :counter="
                            $v.computedAddress.addressLine1.$params.maxLength
                                .max
                        "
                        :required="
                            isRequiredField($v.computedAddress.addressLine1)
                        "
                    ></base-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <!-- addressLine2 -->
                    <base-text-field
                        :value="computedAddress.addressLine2"
                        @input="emitOnFieldInput('addressLine2', $event)"
                        :label="$t('address-line-2-label')"
                        :counter="
                            $v.computedAddress.addressLine2.$params.maxLength
                                .max
                        "
                        :error-messages="addressLine2Errors"
                        :required="
                            isRequiredField($v.computedAddress.addressLine2)
                        "
                    ></base-text-field>
                </v-col>
            </template>

            <v-col cols="12" sm="10">
                <!-- street -->
                <base-text-field
                    :value="computedAddress.street"
                    @input="emitOnFieldInput('street', $event)"
                    :error-messages="streetErrors"
                    :counter="$v.computedAddress.street.$params.maxLength.max"
                    :label="$t('street-label')"
                    :required="isRequiredField($v.computedAddress.street)"
                ></base-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <!-- houseNr -->
                <base-text-field
                    :value="computedAddress.houseNr"
                    @input="emitOnFieldInput('houseNr', $event)"
                    :label="$t('house-nr-label')"
                    :error-messages="houseNrErrors"
                    :counter="$v.computedAddress.houseNr.$params.maxLength.max"
                    :required="isRequiredField($v.computedAddress.houseNr)"
                ></base-text-field>
            </v-col>

            <template v-if="isLocationSearchAvailable(computedAddress.country)">
                <v-col cols="12" class="py-1">
                    <WLocationSearch
                        :value="locationSearch"
                        :disabled="disabled"
                        :country="computedAddress.country"
                        :readonly="readonly"
                        :error-messages="locationSearchErrors"
                        :required="
                            isRequiredField($v.computedAddress.zipCode) ||
                            isRequiredField($v.computedAddress.city) ||
                            isRequiredField($v.computedAddress.canton)
                        "
                        @input="onLocationSearchChange"
                        @reset="onWLocationSearchReset"
                    />
                </v-col>
            </template>

            <template v-else>
                <v-col class="py-1" cols="12" sm="4">
                    <base-text-field
                        :value="computedAddress.zipCode"
                        @input="emitOnFieldInput('zipCode', $event)"
                        :error-messages="zipCodeErrors"
                        :counter="
                            $v.computedAddress.zipCode.$params.maxLength.max
                        "
                        :label="$t('zip-code-label')"
                        :required="isRequiredField($v.computedAddress.zipCode)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-1" cols="12" sm="8">
                    <base-text-field
                        :value="computedAddress.city"
                        @input="emitOnFieldInput('city', $event)"
                        :error-messages="cityErrors"
                        :counter="$v.computedAddress.city.$params.maxLength.max"
                        :label="$t('city-label')"
                        :required="isRequiredField($v.computedAddress.city)"
                    ></base-text-field>
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { BaseAddress } from "@/models/tasks";
import validateMixin from "@/mixins/validate";
import mixin from "./mixin";
import Helpers from "@/utils/helpers";
export default {
    name: "FormAddress",
    mixins: [validateMixin, mixin],
    props: {
        value: {
            type: Object,
            default: () => new BaseAddress()
        }
    },
    validations() {
        return {
            computedAddress: {
                addressLine1: {
                    required: requiredIf(function () {
                        if (this.computedAddress.additionalAddressLines) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    maxLength: maxLength(40)
                },
                addressLine2: {
                    required: requiredIf(function () {
                        return (
                            this.computedAddress.additionalAddressLines &&
                            !this.computedAddress.addressLine1
                        );
                    }),
                    maxLength: maxLength(40)
                },
                street: { required, maxLength: maxLength(60) },
                houseNr: { maxLength: maxLength(10) },
                canton: {
                    required: requiredIf(function () {
                        if (this.computedAddress.country === "CH") {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                zipCode: {
                    required,
                    maxLength:
                        this.computedAddress.country === "CH"
                            ? maxLength(4)
                            : maxLength(10)
                },
                city: {
                    required,
                    maxLength: maxLength(40)
                }
            }
        };
    },
    data: () => ({
        locationSearchDefault: {
            zipCode: null,
            city: null,
            canton: null
        },
        locationSearch: null
    }),

    computed: {
        addressLine1Errors() {
            const errors = [];
            if (!this.$v.computedAddress.addressLine1.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.addressLine1.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.computedAddress.addressLine1.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.addressLine1.$params
                                .maxLength.max
                    })
                );
            }
            return errors;
        },
        addressLine2Errors() {
            const errors = [];
            if (!this.$v.computedAddress.addressLine2.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.addressLine2.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.addressLine2.$params
                                .maxLength.max
                    })
                );
            }
            return errors;
        },
        streetErrors() {
            const errors = [];
            if (!this.$v.computedAddress.street.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.street.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.street.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.computedAddress.street.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        houseNrErrors() {
            const errors = [];
            if (!this.$v.computedAddress.houseNr.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.houseNr.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.houseNr.$params.maxLength
                                .max
                    })
                );
            }
            return errors;
        },
        locationSearchErrors() {
            const errors = [];
            if (
                !this.$v.computedAddress.canton.$dirty ||
                !this.$v.computedAddress.zipCode.$dirty ||
                !this.$v.computedAddress.city.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.computedAddress.canton.required ||
                !this.$v.computedAddress.zipCode.required ||
                !this.$v.computedAddress.city.required
            ) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cantonErrors() {
            const errors = [];
            if (!this.$v.computedAddress.canton.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.canton.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        zipCodeErrors() {
            const errors = [];
            if (!this.$v.computedAddress.zipCode.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.zipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.zipCode.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.computedAddress.zipCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.computedAddress.city.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.city.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.city.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.computedAddress.city.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },

    methods: {
        setAddAddressLine1Btn() {
            if (this.showAddressLine1 && this.showAddressLine2) {
                return "";
            } else if (this.showAddressLine1) {
                return "mdi-minus";
            } else if (this.showAddressLine2) {
                return "";
            } else {
                return "mdi-plus";
            }
        },
        onWLocationSearchReset() {
            this.$emit("input", {
                zipCode: null,
                city: null,
                canton: null
            });
        },
        onLocationSearchChange(value) {
            if (!value) return this.onWLocationSearchReset();
            this.$emit(
                "input",
                Object.assign(this.locationSearchDefault, value)
            );
        },
        setLocationSearch(value) {
            const location = Helpers.assignObjOnlyIfKeysExists(
                this.locationSearchDefault,
                value
            );
            this.locationSearch = location;
        },
        clear() {
            this.reset();
            this.$emit("input", {
                additionalAddressLines: null,
                addressLine1: null,
                addressLine2: null,
                street: null,
                houseNr: null,
                zipCode: null,
                city: null,
                canton: null
            });
        },
        isLocationSearchAvailable(country) {
            return Helpers.isLocationSearchAvailable(country);
        }
    },

    watch: {
        value: {
            handler: function (value) {
                this.setLocationSearch(value);
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
