<i18n src="./form-chamber-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                ChamberId : {{ chamberId }}
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                Aktiv beheizt : {{ activelyHeated }}
            </template>
        </app-dev-container>

        <v-row dense>
            <v-col class="py-0" sm="6">
                <!-- descripton -->
                <base-text-field
                    v-model="descripton"
                    :label="$t('descripton-label')"
                    :hint="$t('descripton-hint')"
                    :error-messages="descriptonErrors"
                    :counter="$v.descripton.$params.maxLength.max"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersDescripton')"
                    :required="isRequiredField($v.descripton)"
                ></base-text-field>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
                <!-- volume -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    @input="volume = $event"
                    :options="fieldsOptions.volume"
                    :value="volume"
                    ref="volume"
                    :suffix="$t('label-liter')"
                    :label="$t('volume-label')"
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-liter')
                        })
                    "
                    :hint="
                        $t('hint', {
                            minValue: $v.volume.$params.minValue.min,
                            maxValue: $v.volume.$params.maxValue.max
                        })
                    "
                    :error-messages="volumeErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersVolume')"
                    :required="isRequiredField($v.volume)"
                >
                </WDecimalField>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col class="py-0" cols="12" sm="6">
                <!-- min. zul. Druck (in bar, -+, relativ zur Meereshöhe) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMinPressure"
                    @input="lastMinPressure = $event"
                    v-model="lastMinPressure"
                    ref="lastMinPressure"
                    :suffix="$t('label-bar')"
                    :label="$t('last-min-pressure-label')"
                    :hint="
                        $t('hint', {
                            minValue: $v.lastMinPressure.$params.minValue.min,
                            maxValue: $v.lastMinPressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="lastMinPressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersLastMinPressure')"
                    :required="isRequiredField($v.lastMinPressure)"
                >
                </WDecimalField>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
                <!-- max. zul. Druck (in bar, +, relativ zur Meereshöhe) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMaxPressure"
                    @input="lastMaxPressure = $event"
                    v-model="lastMaxPressure"
                    ref="lastMaxPressure"
                    :suffix="$t('label-bar')"
                    :label="$t('last-max-pressure-label')"
                    :hint="
                        $t('hint', {
                            minValue: $v.lastMaxPressure.$params.minValue.min,
                            maxValue: $v.lastMaxPressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="lastMaxPressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersLastMaxPressure')"
                    :required="isRequiredField($v.lastMaxPressure)"
                >
                </WDecimalField>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col class="py-0" cols="12" sm="6">
                <!-- min. zul. Konzessionstemperatur (in °C) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMinConcessionTemperature"
                    @input="lastMinConcessionTemperature = $event"
                    :value="lastMinConcessionTemperature"
                    ref="lastMinConcessionTemperature"
                    :suffix="$t('label-celsius')"
                    :label="$t('last-min-concession-temperature-label')"
                    :hint="
                        $t('hint', {
                            minValue:
                                $v.lastMinConcessionTemperature.$params.minValue
                                    .min,
                            maxValue:
                                $v.lastMinConcessionTemperature.$params.maxValue
                                    .max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-celsius')
                        })
                    "
                    :error-messages="lastMinConcessionTemperatureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="
                        showFaq('chambersLastMinConcessionTemperature')
                    "
                    :required="isRequiredField($v.lastMinConcessionTemperature)"
                >
                </WDecimalField>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
                <!-- max. zul. Konzessionstemperatur (in °C) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMaxConcessionTemperature"
                    @input="lastMaxConcessionTemperature = $event"
                    :value="lastMaxConcessionTemperature"
                    ref="lastMaxConcessionTemperature"
                    :suffix="$t('label-celsius')"
                    :label="$t('last-max-concession-temperature-label')"
                    :hint="
                        $t('hint', {
                            minValue:
                                $v.lastMaxConcessionTemperature.$params.minValue
                                    .min,
                            maxValue:
                                $v.lastMaxConcessionTemperature.$params.maxValue
                                    .max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-celsius')
                        })
                    "
                    :error-messages="lastMaxConcessionTemperatureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="
                        showFaq('chambersLastMaxConcessionTemperature')
                    "
                    :required="isRequiredField($v.lastMaxConcessionTemperature)"
                >
                </WDecimalField>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="6">
                <!-- prüfdruck -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.testPressure"
                    @input="testPressure = $event"
                    :value="testPressure"
                    ref="testPressure"
                    :label="$t('test-pressure-label')"
                    :suffix="$t('label-bar')"
                    :hint="
                        $t('hint', {
                            minValue: $v.testPressure.$params.minValue.min,
                            maxValue: $v.testPressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="testPressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersTestPressure')"
                    :required="isRequiredField($v.testPressure)"
                >
                </WDecimalField>
            </v-col>
            <v-col cols="12" md="6">
                <!-- prüfmedium -->
                <base-autocomplete
                    :items="sortedTestMediumItems"
                    v-model="testMedium"
                    :label="$t('test-medium-label')"
                    item-value="key"
                    :clearable="!readonlyModus"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersTestMedium')"
                    :filter="customFilter"
                    :item-text="
                        (item) =>
                            item.value[$i18n.locale]
                                ? item.value[$i18n.locale]
                                : item.value.de
                    "
                >
                </base-autocomplete>
            </v-col>
        </v-row>
        <!-- stoffCode -->
        <base-autocomplete
            :items="sortedMaterialCodeItems"
            v-model="materialCode"
            :label="$t('material-code-label')"
            item-value="key"
            :error-messages="materialCodeErrors"
            :clearable="!readonlyModus"
            append-icon="mdi-information-outline"
            @click:append="showFaq('chambersMaterialCode')"
            :required="isRequiredField($v.materialCode)"
            :filter="customFilter"
        >
            <template v-slot:item="{ item }">
                <span v-if="item.value[$i18n.locale]">
                    {{ item.value[$i18n.locale] }}
                </span>
                <span v-else class="font-italic">
                    {{ item.value.de }} (de)
                </span>
            </template>
            <template v-slot:selection="{ item }">
                <!-- use vuetify class to break the text if is too large  -->
                <div class="v-select__selection v-select__selection--comma">
                    <span v-if="item.value[$i18n.locale]">
                        {{ item.value[$i18n.locale] }}
                    </span>
                    <span v-else class="font-italic">
                        {{ item.value.de }} (de)
                    </span>
                </div>
            </template>
        </base-autocomplete>

        <v-row dense>
            <v-col>
                <!-- coverageNotAvailable -->
                <v-checkbox
                    v-model="coverageNotAvailable"
                    :label="$t('coverage-not-available-label')"
                    color="primary"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersCoverageNotAvailable')"
                ></v-checkbox>
            </v-col>
            <v-col v-if="coverageNotAvailable" cols="12">
                <base-textarea
                    v-model="coverageNotAvailableReason"
                    :error-messages="coverageNotAvailableReasonErrors"
                    :label="$t('coverage-not-available-reason-label')"
                    :clearable="!readonlyModus"
                    :counter="
                        $v.coverageNotAvailableReason.$params.maxLength.max
                    "
                    :required="isRequiredField($v.coverageNotAvailableReason)"
                >
                </base-textarea>
            </v-col>
        </v-row>

        <v-row dense v-show="!coverageNotAvailable">
            <v-col cols="12" md="4">
                <!-- coverageArt -->
                <base-autocomplete
                    :items="sortedCoverageArtItems"
                    v-model="coverageArt"
                    :label="$t('coverage-art-label')"
                    item-value="key"
                    :error-messages="coverageArtErrors"
                    :clearable="!readonlyModus"
                    :required="isRequiredField($v.coverageArt)"
                    :filter="customFilter"
                    :item-text="
                        (item) =>
                            item.value[$i18n.locale]
                                ? item.value[$i18n.locale]
                                : item.value.de
                    "
                >
                </base-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
                <!-- coverageLocation -->
                <base-autocomplete
                    :items="sortedCoverageLocationItems"
                    v-model="coverageLocation"
                    :label="$t('coverage-location-label')"
                    item-value="key"
                    :error-messages="coverageLocationErrors"
                    :clearable="!readonlyModus"
                    :filter="customFilter"
                    :required="isRequiredField($v.coverageLocation)"
                    :item-text="
                        (item) =>
                            item.value[$i18n.locale]
                                ? item.value[$i18n.locale]
                                : item.value.de
                    "
                >
                </base-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
                <!-- coveragePressure -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.coveragePressure"
                    @input="coveragePressure = $event"
                    :value="coveragePressure"
                    ref="coveragePressure"
                    :label="$t('coverage-pressure-label')"
                    :suffix="$t('label-bar')"
                    :hint="
                        $t('hint', {
                            minValue: $v.coveragePressure.$params.minValue.min,
                            maxValue: $v.coveragePressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="coveragePressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersCoveragePressure')"
                    :required="isRequiredField($v.coveragePressure)"
                >
                </WDecimalField>
            </v-col>
        </v-row>

        <app-dev-container>
            <template v-slot:content>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
                @GetData:
                <tree-view
                    :data="getData()"
                    :options="{ maxDepth: 1 }"
                ></tree-view>
            </template>
        </app-dev-container>
    </v-form>
</template>
<script>
import {
    required,
    requiredIf,
    minLength,
    maxLength,
    minValue,
    maxValue,
    helpers
} from "vuelidate/lib/validators";
import WDecimalField from "@/components/fields/WDecimalField.vue";
import { mapActions } from "vuex";
import { sortAttributesAlphabetically } from "@/utils";
import _ from "lodash";
import validateMixin from "@/mixins/validate";
// --------------------- PLEASE READ ---------------------------------
// the fields:
// - lastMinConcessionPressure
// - lastMaxConcessionPressure
// have been remove/hidded, but the value need to be provide
// the value for this fields will be copied from the fields (seeh the 'wather'):
// - lastMinPressure
// - coveragePressure
//this way was easier to prevent bugs on save, check or get values
// change from: Stefano Cipolla
// -------------------------------------------------------------------
const greaterThan = (param) =>
    function (value) {
        if (!helpers.req(value)) {
            return true;
        } else if (isNaN(param)) {
            return true;
        } else {
            if (param) {
                //parseFloat to be sure that de decimal value will be compared
                return parseFloat(value) >= parseFloat(param);
            } else {
                return true;
            }
        }
    };

const lesserThan = (param) =>
    function (value) {
        if (!helpers.req(value)) {
            return true;
        } else if (isNaN(param)) {
            return true;
        } else {
            if (param) {
                //parseFloat to be sure that de decimal value will be compared
                return parseFloat(value) <= parseFloat(param);
            } else {
                return true;
            }
        }
    };

export default {
    name: "FormChamber",
    mixins: [validateMixin],
    validations() {
        return {
            descripton: {
                required,
                maxLength: maxLength(30)
            },
            volume: {
                required,
                minValue: minValue(0.1),
                maxValue: maxValue(this.fieldsOptions.volume.maximumValue)
            },
            lastMinConcessionTemperature: {
                minValue: minValue(
                    this.fieldsOptions.lastMinConcessionTemperature.minimumValue
                ),
                maxValue: maxValue(
                    this.fieldsOptions.lastMinConcessionTemperature.maximumValue
                ),
                lesserThan: lesserThan(this.lastMaxConcessionTemperature)
            },
            lastMaxConcessionTemperature: {
                required: requiredIf(function () {
                    if (this.activelyHeated) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                minValue: minValue(
                    this.lastMinConcessionTemperature > 0
                        ? this.lastMinConcessionTemperature
                        : 0.1
                ),
                maxValue: maxValue(
                    this.fieldsOptions.lastMaxConcessionTemperature.maximumValue
                ),
                greaterThan: greaterThan(this.lastMinConcessionTemperature)
            },
            lastMinPressure: {
                minValue: minValue(
                    this.fieldsOptions.lastMinPressure.minimumValue
                ),
                maxValue: maxValue(
                    this.fieldsOptions.lastMinPressure.maximumValue
                ),
                lesserThan: lesserThan(this.lastMaxPressure)
            },
            lastMaxPressure: {
                required: requiredIf(function () {
                    if (this.activelyHeated) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                minValue: minValue(
                    this.fieldsOptions.lastMaxPressure.minimumValue == 0
                        ? 0.1
                        : this.fieldsOptions.lastMaxPressure.minimumValue
                ),
                maxValue: maxValue(
                    this.fieldsOptions.lastMaxPressure.maximumValue
                ),
                greaterThan: greaterThan(this.lastMinPressure)
            },
            materialCode: {
                required
            },
            coverageNotAvailableReason: {
                required: requiredIf(function () {
                    if (this.coverageNotAvailable) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                minLength: minLength(2),
                maxLength: maxLength(500)
            },
            coverageArt: {
                required: requiredIf(function () {
                    if (this.coverageNotAvailable) {
                        return false;
                    } else {
                        return true;
                    }
                })
            },
            coverageLocation: {
                required: requiredIf(function () {
                    if (this.coverageNotAvailable) {
                        return false;
                    } else {
                        return true;
                    }
                })
            },
            coveragePressure: {
                required: requiredIf(function () {
                    if (this.coverageNotAvailable) {
                        return false;
                    } else {
                        return true;
                    }
                }),
                minValue: minValue(
                    this.fieldsOptions.coveragePressure.minimumValue == 0
                        ? 0.1
                        : this.fieldsOptions.coveragePressure.minimumValue
                ),
                maxValue: maxValue(
                    this.fieldsOptions.coveragePressure.maximumValue
                ),
                greaterThan: greaterThan(this.lastMinPressure),
                lesserThan: lesserThan(this.lastMaxPressure)
            },
            testPressure: {
                minValue: minValue(
                    this.fieldsOptions.testPressure.minimumValue == 0
                        ? 0.1
                        : this.fieldsOptions.testPressure.minimumValue
                ),
                maxValue: maxValue(
                    this.fieldsOptions.testPressure.maximumValue
                ),
                greaterThan: greaterThan(this.lastMaxPressure)
            }
        };
    },
    components: {
        WDecimalField
    },
    props: {
        chamberId: {
            type: Number,
            default: 1
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        activelyHeated: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        descripton: "",
        volume: "",
        lastMinConcessionPressure: "",
        lastMaxConcessionPressure: "",
        lastMinConcessionTemperature: "",
        lastMaxConcessionTemperature: "",
        lastMinPressure: "",
        lastMaxPressure: "",
        faqDialog: false,
        faqSearch: null,
        coverageNotAvailable: false,
        coverageNotAvailableReason: null,
        coverageLocation: null,
        coverageLocationItems: [],
        coveragePressure: "",
        coverageArt: null,
        coverageArtItems: [],
        materialCode: null,
        materialCodeItems: [],
        testPressure: "",
        testMedium: null,
        testMediumItems: []
    }),
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        onVolumeFormat(val) {
            this.volume = val;
        },
        reset() {
            this.$v.$reset();
            this.descripton = null;
            this.volume = null;
            this.lastMinPressure = null;
            this.lastMaxPressure = null;
            this.lastMinConcessionPressure = null;
            this.lastMaxConcessionPressure = null;
            this.lastMinConcessionTemperature = null;
            this.lastMaxConcessionTemperature = null;

            this.coverageNotAvailable = false;
            this.coverageNotAvailableReason = null;
            this.coverageArt = "";
            this.coverageLocation = "";
            this.coveragePressure = null;
            this.materialCode = "";
            this.testMedium = "";
            this.testPressure = null;
        },
        getData() {
            let ret = {};
            ret.id = this.chamberId;
            ret.descripton = this.descripton;
            ret.volume = this.volume;
            ret.lastMinPressure = this.lastMinPressure;
            ret.lastMaxPressure = this.lastMaxPressure;
            ret.lastMinConcessionPressure = this.lastMinConcessionPressure;
            ret.lastMaxConcessionPressure = this.lastMaxConcessionPressure;
            ret.lastMinConcessionTemperature =
                this.lastMinConcessionTemperature;
            ret.lastMaxConcessionTemperature =
                this.lastMaxConcessionTemperature;
            ret.coverageNotAvailable = this.coverageNotAvailable;
            ret.coverageNotAvailableReason = this.coverageNotAvailableReason;
            if (!this.coverageNotAvailable) {
                ret.coverageLocation = this.coverageLocation;
                ret.coverageArt = this.coverageArt;
                ret.coveragePressure = this.coveragePressure;
            } else {
                ret.coverageLocation = null;
                ret.coverageArt = null;
                ret.coveragePressure = null;
            }
            ret.materialCode = this.materialCode;
            ret.testMedium = this.testMedium;
            ret.testPressure = this.testPressure;

            return ret;
        },
        setData(data) {
            this.descripton = data.descripton;
            this.volume = data.volume;
            this.lastMinPressure = data.lastMinPressure;
            this.lastMaxPressure = data.lastMaxPressure;
            this.lastMinConcessionPressure = data.lastMinConcessionPressure;
            this.lastMaxConcessionPressure = data.lastMaxConcessionPressure;
            this.lastMinConcessionTemperature =
                data.lastMinConcessionTemperature;
            this.lastMaxConcessionTemperature =
                data.lastMaxConcessionTemperature;
            this.coverageNotAvailable = data.coverageNotAvailable;
            this.coverageNotAvailableReason = data.coverageNotAvailableReason;
            this.coverageArt = data.coverageArt;
            this.coverageLocation = data.coverageLocation;
            this.coveragePressure = data.coveragePressure;
            this.materialCode = data.materialCode;
            this.testMedium = data.testMedium;
            this.testPressure = data.testPressure;
        },
        setFakeData() {
            this.descripton = "My Kammer " + this.chamberId;
            this.$refs.volume.setValue("800.1");
            this.$refs.lastMinPressure.setValue("0.1");
            this.$refs.lastMaxPressure.setValue("230.1");
            this.$refs.lastMinConcessionTemperature.setValue("30.1");
            this.$refs.lastMaxConcessionTemperature.setValue("220.1");
            this.$refs.testPressure.setValue("240.0");

            this.coverageNotAvailable = false;
            this.coverageNotAvailableReason = null;
            this.$refs.coveragePressure.setValue("22.2");
            this.coverageArt =
                this.coverageArtItems[
                    Math.floor(Math.random() * this.coverageArtItems.length)
                ].key;
            this.coverageLocation =
                this.coverageLocationItems[
                    Math.floor(
                        Math.random() * this.coverageLocationItems.length
                    )
                ].key;
            this.materialCode =
                this.sortedMaterialCodeItems[
                    Math.floor(
                        Math.random() * this.sortedMaterialCodeItems.length
                    )
                ].key;
            this.testMedium =
                this.sortedTestMediumItems[
                    Math.floor(
                        Math.random() * this.sortedTestMediumItems.length
                    )
                ].key;
        },
        getItemsByIndex(index) {
            let coverageArtKey = "absicherungsart" + index;
            this.loader().show();
            this.getStoredAttributesByKey(coverageArtKey)
                .then((attributes) => {
                    if (attributes) {
                        //remove "keine absicherung vorhanden" from the list an handle it with chekbox
                        this.coverageArtItems = _.clone(attributes).filter(
                            (item) => item.key !== "KAB"
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("coverage-art")
                        })
                    );
                });

            let coverageLocationKey = "absicherungsort" + index;
            this.loader().show();
            this.getStoredAttributesByKey(coverageLocationKey)
                .then((attributes) => {
                    if (attributes) {
                        this.coverageLocationItems = _.clone(attributes);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("coverage-location")
                        })
                    );
                });

            let materialCodeKey = "stoffcode" + index;
            this.loader().show();
            this.getStoredAttributesByKey(materialCodeKey)
                .then((attributes) => {
                    if (attributes) {
                        this.materialCodeItems = _.clone(attributes);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("material-code")
                        })
                    );
                });

            let testMediumKey = "pruefmedium" + index;
            this.loader().show();
            this.getStoredAttributesByKey(testMediumKey)
                .then((attributes) => {
                    if (attributes) {
                        this.testMediumItems = _.clone(attributes);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("test-medium")
                        })
                    );
                });
        },
        customFilter(item, queryText) {
            const locale = this.$i18n.locale || "de";
            const itemValue = item.value[locale]
                ? item.value[locale]
                : item.value["de"];
            const itemValueText = itemValue.toLowerCase();
            const searchText = queryText.toLowerCase();
            return itemValueText.indexOf(searchText) > -1;
        },
        getParsedFloatValue(value) {
            return parseFloat(value).toFixed(1);
        }
    },
    computed: {
        //WDecimalFields : Validations Options
        fieldsOptions() {
            return {
                volume: {
                    minimumValue: "0",
                    maximumValue: "99999999.9"
                },
                coveragePressure: {
                    minimumValue: this.lastMinPressure > 0
                            ? String(this.lastMinPressure)
                            : "0",
                    maximumValue: this.lastMaxPressure > 0
                            ? String(this.lastMaxPressure)
                            : "9999.9"
                },
                lastMinConcessionTemperature: {
                    minimumValue: "-273.1",
                    maximumValue:
                        this.lastMaxConcessionTemperature &&
                        this.lastMaxConcessionTemperature > 0 < 999.9
                            ? String(this.lastMaxConcessionTemperature)
                            : "999.9"
                },
                lastMaxConcessionTemperature: {
                    minimumValue: "0",
                    maximumValue: "9999.9"
                },
                lastMinPressure: {
                    minimumValue: "-1",
                    maximumValue: this.lastMaxPressure
                        ? String(this.lastMaxPressure)
                        : "9999.9"
                },
                lastMaxPressure: {
                    minimumValue:
                        this.lastMinPressure > 0
                            ? String(this.lastMinPressure)
                            : "0",
                    maximumValue: "9999.9"
                },
                testPressure: {
                    minimumValue: this.lastMaxPressure
                        ? String(this.lastMaxPressure)
                        : "0",
                    maximumValue: "9999.9"
                }
            };
        },
        descriptonErrors() {
            const errors = [];
            if (!this.$v.descripton.$dirty) {
                return errors;
            }
            if (!this.$v.descripton.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.descripton.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.descripton.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        volumeErrors() {
            const errors = [];
            if (!this.$v.volume.$dirty) {
                return errors;
            }
            if (!this.$v.volume.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.volume.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.volume.$params.minValue.min
                    })
                );
            }
            if (!this.$v.volume.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.volume.$params.maxValue.max
                    })
                );
            }

            return errors;
        },
        lastMinConcessionTemperatureErrors() {
            const errors = [];
            if (!this.$v.lastMinConcessionTemperature.$dirty) {
                return errors;
            }
            if (!this.$v.lastMinConcessionTemperature.lesserThan) {
                errors.push(
                    this.$t("field-lesser-than-value", {
                        fieldName: this.$t("last-max-concession-temperature"),
                        maxValue: this.getParsedFloatValue(
                            this.lastMaxConcessionTemperature
                        ),
                        unit: this.$t("label-celsius")
                    })
                );
            }
            if (!this.$v.lastMinConcessionTemperature.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.lastMinConcessionTemperature.$params
                                .minValue.min
                    })
                );
            }
            if (!this.$v.lastMinConcessionTemperature.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue:
                            this.$v.lastMinConcessionTemperature.$params
                                .maxValue.max
                    })
                );
            }
            return errors;
        },
        lastMaxConcessionTemperatureErrors() {
            const errors = [];
            if (!this.$v.lastMaxConcessionTemperature.$dirty) {
                return errors;
            }
            if (!this.$v.lastMaxConcessionTemperature.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.lastMaxConcessionTemperature.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-min-concession-temperature"),
                        minValue: this.getParsedFloatValue(
                            this.lastMinConcessionTemperature
                        ),
                        unit: this.$t("label-celsius")
                    })
                );
            }
            if (!this.$v.lastMaxConcessionTemperature.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.lastMaxConcessionTemperature.$params
                                .minValue.min
                    })
                );
            }
            if (!this.$v.lastMaxConcessionTemperature.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue:
                            this.$v.lastMaxConcessionTemperature.$params
                                .maxValue.max
                    })
                );
            }
            return errors;
        },
        lastMinPressureErrors() {
            const errors = [];
            if (!this.$v.lastMinPressure.$dirty) {
                return errors;
            }
            if (!this.$v.lastMinPressure.lesserThan) {
                errors.push(
                    this.$t("field-lesser-than-value", {
                        fieldName: this.$t("last-max-pressure"),
                        maxValue: this.getParsedFloatValue(
                            this.lastMaxPressure
                        ),
                        unit: this.$t("label-bar")
                    })
                );
            }
            if (!this.$v.lastMinPressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.lastMinPressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.lastMinPressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.lastMinPressure.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        lastMaxPressureErrors() {
            const errors = [];
            if (!this.$v.lastMaxPressure.$dirty) {
                return errors;
            }
            if (!this.$v.lastMaxPressure.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.lastMaxPressure.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-min-pressure"),
                        minValue: this.getParsedFloatValue(
                            this.lastMinPressure
                        ),
                        unit: this.$t("label-bar")
                    })
                );
            }
            if (!this.$v.lastMaxPressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.lastMaxPressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.lastMaxPressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.lastMaxPressure.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        materialCodeErrors() {
            const errors = [];
            if (!this.$v.materialCode.$dirty) {
                return errors;
            }
            if (!this.$v.materialCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        coverageArtErrors() {
            const errors = [];
            if (!this.$v.coverageArt.$dirty) {
                return errors;
            }
            if (!this.$v.coverageArt.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        coverageLocationErrors() {
            const errors = [];
            if (!this.$v.coverageLocation.$dirty) {
                return errors;
            }
            if (!this.$v.coverageLocation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        coveragePressureErrors() {
            const errors = [];
            if (!this.$v.coveragePressure.$dirty) {
                return errors;
            }
            if (!this.$v.coveragePressure.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.coveragePressure.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-min-pressure"),
                        minValue: this.getParsedFloatValue(
                            this.lastMinPressure
                        ),
                        unit: this.$t("label-bar")
                    })
                );
            }
            if (!this.$v.coveragePressure.lesserThan) {
                errors.push(
                    this.$t("field-lesser-than-value", {
                        fieldName: this.$t("last-max-pressure"),
                        maxValue: this.getParsedFloatValue(
                            this.lastMaxPressure
                        ),
                        unit: this.$t("label-bar")
                    })
                );
            }
            if (!this.$v.coveragePressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.coveragePressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.coveragePressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.coveragePressure.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        coverageNotAvailableReasonErrors() {
            const errors = [];
            if (!this.$v.coverageNotAvailableReason.$dirty) {
                return errors;
            }
            if (!this.$v.coverageNotAvailableReason.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.coverageNotAvailableReason.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.coverageNotAvailableReason.$params.maxLength
                                .max
                    })
                );
            }
            if (!this.$v.coverageNotAvailableReason.minLength) {
                errors.push(
                    this.$t("field-min-length", {
                        minLength:
                            this.$v.coverageNotAvailableReason.$params.minLength
                                .min
                    })
                );
            }
            return errors;
        },
        testPressureErrors() {
            const errors = [];
            if (!this.$v.testPressure.$dirty) {
                return errors;
            }
            if (!this.$v.testPressure.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-max-pressure"),
                        minValue: this.getParsedFloatValue(this.lastMaxPressure)
                    })
                );
            }
            if (!this.$v.testPressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.testPressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.testPressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        minValue: this.$v.testPressure.$params.maxValue.max
                    })
                );
            }

            return errors;
        },
        sortedMaterialCodeItems() {
            if (this.materialCodeItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(this.materialCodeItems, locale);
        },
        sortedTestMediumItems() {
            if (this.testMediumItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(this.testMediumItems, locale);
        },
        sortedCoverageArtItems() {
            if (this.coverageArtItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(this.coverageArtItems, locale);
        },
        sortedCoverageLocationItems() {
            if (this.coverageLocationItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(
                this.coverageLocationItems,
                locale
            );
        }
    },
    watch: {
        chamberId: {
            handler: function (newVal) {
                // watch it
                if (newVal) {
                    this.getItemsByIndex(newVal);
                }
            },
            immediate: true
        },
        data: {
            handler: function (newVal) {
                // watch it
                if (newVal) {
                    this.setData(newVal);
                }
            },
            immediate: true
        },
        lastMinPressure: {
            handler: function (newVal) {
                this.lastMinConcessionPressure = newVal;
            },
            immediate: true
        },
        coveragePressure: {
            handler: function (newVal) {
                this.lastMaxConcessionPressure = newVal;
            },
            immediate: true
        }
    }
};
</script>
