<template>
    <v-icon :color="color" v-html="icon"></v-icon>
</template>
<script>
export default {
    name: "BaseIcon",
    props: {
        icon: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: "regOfficeBtnPrimary"
        }
    }
};
</script>
