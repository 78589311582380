<template>
  <SectionBase id="devel-base-fields" title="Base Fields">
    <base-header id="devel-base-fields-header">base-header</base-header>
    <base-subheader id="devel-base-fields-subheader"
      >base-subheader</base-subheader
    >
    <base-radio-group
      id="devel-base-fields-radio-group"
      label="Label"
      v-model="baseRadioGroup"
      required
      append-icon="mdi-vuetify"
      @change="(value) => {$toast.info(`change value to: ${value}`) }"
    >
      <v-radio label="Vivamus magna justo, lacinia eget consectetur sed" :value="true"></v-radio>
      <v-radio label="Vivamus magna justo, lacinia eget consectetur sed"  :value="false"></v-radio>
    </base-radio-group>
    <base-btn @click="baseRadioGroup = null">reset</base-btn>
    <br />
    <br />
    <base-text-field
      id="devel-base-fields-text-field"
      label="BaseTextField Required"
      v-model="baseTextField1"
      required
    >
    </base-text-field>
    <base-text-field
      label="BaseTextField"
      v-model="baseTextField2"
    ></base-text-field>
    <base-text-field
      label="BaseTextField Required && clearable"
      v-model="baseTextField3"
      required
      clearable
      :v="$v.baseTextField3"
    >
    </base-text-field>
    <v-switch
      v-model="requiredFields"
      label="Fields required?"
    >
    </v-switch>
    <v-row>
      <v-col>
        <base-textarea
          id="devel-base-fields-textarea"
          label="Base Textarea"
          v-model="modelTextarea"
          :counter="100"
          :required="requiredFields"
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <base-autocomplete
          id="devel-base-fields-autocomplete"
          label="Base Autocomplete"
          v-model="modelAutocomplete"
          :required="requiredFields"
          :items="sampleItems"
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <base-select
        id="devel-base-fields-select"
          label="Base Select"
          v-model="modelSelect"
          :required="requiredFields"
          :items="sampleItems"
          item-text="text"
          item-value="value"
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <base-alert type="info">
        Base Alert Info
        </base-alert>
      </v-col>
      <v-col cols="12">
        <base-alert type="error">
        Base Alert error
        </base-alert>
      </v-col>
      <v-col cols="12">
        <base-alert type="warning">
        Base Alert warning
        </base-alert>
      </v-col>
      <v-col cols="12">
        <base-alert type="success">
        Base Alert success
        </base-alert>
      </v-col>
      <v-col cols="12">
        <base-alert text="<b>Base</b> Alert Default" />
      </v-col>
    </v-row>
    <hr class="my-3" />
    <br />
    <v-row id="devel-base-fields-buttons">
      <v-col v-for="btn in buttons" :key="btn.text" cols="12" xs="12" sm="4" mdAndUp="3">
        <base-btn
          :type="btn.type"
          :color="btn.color"
          :outlined="btn.outlined"
          >{{ btn.text }}</base-btn
        >
      </v-col>
    </v-row>
  </SectionBase>
</template>

<script>
import validateMixin from '@/mixins/validate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
export default {
  name: "SectionBaseFields",
  mixins: [validateMixin],
  components: { SectionBase: () => import("./base.vue") },
    validations() {
    return {
      baseTextField3: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(20),
      }
    };
  },
  data: () => ({
    baseRadioGroup: null,
    baseTextField1: null,
    baseTextField2: null,
    baseTextField3: null,
    buttons: [
      {
        text: "BaseBtn",
      },
      {
        text: "BaseBtn Outlined",
        outlined: true,
      },
      {
        text: "BaseBtn Color Error",
        color: "error",
      },
      {
        text: "BaseBtn Color Success",
        color: "success",
      },
      {
        type: "primary",
        text: "BaseBtn Primary",
      },
      {
        type: "secondary",
        text: "BaseBtn Secondary",
      },
      {
        type: "info",
        text: "BaseBtn Info",
      },
      {
        type: "success",
        text: "BaseBtn Success",
      },
      {
        type: "error",
        text: "BaseBtn Error",
      },
      {
        type: "warning",
        text: "BaseBtn Warning",
      },
    ],
    requiredFields: false,
    modelTextarea: "test",
    modelAutocomplete: null,
    modelSelect: null,
    sampleItems: [
      {
        text: "Vuejs",
        value: "vuejs"
      },{
        text: "Vuetify",
        value: "vuetify"
      },{
        text: "Angular",
        value: "angular"
      },{
        text: "React",
        value: "react"
      }
    ],
  }),
  methods: {},
};
</script>
