var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',_vm._g(_vm._b({directives:[{name:"disabled-icon-focus",rawName:"v-disabled-icon-focus"}],ref:"wCompanySearch",class:_vm.showRequiredFieldLine ? 'cls-required-field' : undefined,attrs:{"items":_vm.companies,"search-input":_vm.search,"loading":_vm.isLoading,"return-object":"","full-width":"","open-on-clear":"","no-filter":"","filled":"","label":_vm.label ? _vm.label : _vm.$t('label'),"clearable":"","item-value":"name","required":_vm.required},on:{"click:clear":_vm.reset,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"":_vm.$listeners},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-menu',{staticStyle:{"top":"-12px"},attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"pa-6"},[_c('v-row',{attrs:{"dense":""}},[(_vm.developerMode)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"ml-1 pa-0 ma-0",attrs:{"color":"orange","label":_vm.$t('switch-dev-search-label')},model:{value:(_vm.switchDevSearch),callback:function ($$v) {_vm.switchDevSearch=$$v},expression:"switchDevSearch"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('base-btn',{attrs:{"tpye":"info"},on:{"click":function($event){_vm.dialogAdvancedSearch =
                                            !_vm.dialogAdvancedSearch}}},[_vm._v(" "+_vm._s(_vm.$t("switch-advanced-search-label"))+" ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
                                            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-list-item-subtitle',[(item.street)?_c('span',[(item.houseNr)?[_vm._v(" "+_vm._s(item.street)+" "+_vm._s(item.houseNr)+", ")]:[_vm._v(" "+_vm._s(item.street)+", ")]],2):_vm._e(),(item.country)?_c('span',[(item.zipCode)?[_vm._v(" ("+_vm._s(item.country)+")-"+_vm._s(item.zipCode)+" ")]:[_vm._v(" ("+_vm._s(item.country)+") ")]],2):_c('span',[(item.zipCode)?_c('span',[_vm._v(" "+_vm._s(item.zipCode)+" ")]):_vm._e()]),(item.city)?_c('span',[_vm._v(" "+_vm._s(item.city)+" ")]):_vm._e(),(item.canton)?_c('span',[_vm._v(" ("+_vm._s(item.canton)+") ")]):_vm._e()])],1)]}},{key:"selection",fn:function(ref){
                                            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.street)}})],1)]}},{key:"no-data",fn:function(){return [(
                    (!_vm.search && !_vm.isLoading) ||
                    (_vm.search && _vm.search.length <= _vm.searchMinLength)
                )?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-keyboard")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-results-no-search", { minLength: _vm.searchMinLength }))+" ")])],1):_c('v-list-item',{attrs:{"disabled":"","dense":""}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-database-remove")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-results"))+" ")])],1)]},proxy:true}]),model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),(_vm.dialogAdvancedSearch)?_c('DialogCompanyAdvancedSearch',{on:{"click:confirm-selected-company":_vm.onCompanyChange},model:{value:(_vm.dialogAdvancedSearch),callback:function ($$v) {_vm.dialogAdvancedSearch=$$v},expression:"dialogAdvancedSearch"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }