var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{ref:"baseDialog",attrs:{"value":_vm.value,"max-width":"800","icon":"mdi-notebook-outline"},on:{"close":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("title-dialog"))+" "),_c('FaqOpenBtn',{attrs:{"searchId":"addressBook"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-container',{staticClass:"cls-scroll-hidden"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"solo":"","prepend-inner-icon":"mdi-magnify","placeholder":_vm.$t('search-field-hint'),"label":_vm.$t('search-field-label'),"hide-details":"","clearable":"","loading":_vm.isLoader},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoader),expression:"isLoader"}],attrs:{"color":"info","indeterminate":"","absolute":"","height":"3"}})]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-menu',{staticStyle:{"top":"-12px"},attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"pa-6"},[_c('v-btn-toggle',{attrs:{"mandatory":"","group":""},model:{value:(_vm.toggleMultimediaView),callback:function ($$v) {_vm.toggleMultimediaView=$$v},expression:"toggleMultimediaView"}},[_c('v-btn',{attrs:{"value":"list","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-bulleted-square")])],1),_c('v-btn',{attrs:{"value":"grid","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-view-grid")])],1)],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('div',{staticClass:"ps-navigation-content"},[_c('PerfectScrollbar',{staticClass:"ps-navigation-content-items",attrs:{"options":_vm.perfectScrollbarOptions},on:{"ps-scroll-y":function (evt) {
                            _vm.shallShadowBottom =
                                evt.srcElement.scrollTop > 0;
                        }}},[_c('v-item-group',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-row',{staticClass:"px-4 pt-2",attrs:{"dense":""}},[_vm._l((_vm.myAddresses),function(address){return _c('v-col',{key:address.id,attrs:{"cols":"12","xs":"12","sm":_vm.toggleMultimediaView === 'grid'
                                        ? _vm.myAddresses.length > 2
                                            ? 4
                                            : 6
                                        : 12}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var active = ref.active;
                                        var toggle = ref.toggle;
return [_c('v-sheet',{staticClass:"fill-height",attrs:{"outlined":"","rounded":"","color":active ? 'info' : ''}},[_c('CardAddressBook',{attrs:{"address":address,"multimediaView":_vm.toggleMultimediaView},on:{"click":function($event){$event.preventDefault();return toggle.apply(null, arguments)}}})],1)]}}],null,true)})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_vm._l((_vm.addressBookAddresses),function(address){return _c('v-col',{key:address.id,attrs:{"cols":"12","xs":"12","sm":_vm.toggleMultimediaView === 'grid' ? 6 : 12}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var active = ref.active;
                                        var toggle = ref.toggle;
return [_c('v-sheet',{staticClass:"fill-height",attrs:{"outlined":"","rounded":"","color":active ? 'info' : ''}},[_c('CardAddressBook',{attrs:{"address":address,"multimediaView":_vm.toggleMultimediaView},on:{"click":function($event){$event.preventDefault();return toggle.apply(null, arguments)}}})],1)]}}],null,true)})],1)}),(_vm.filteredAddresses.length === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_vm._v("No data found")])]):_vm._e()],2)],1)],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_vm._t("actions",function(){return [_c('v-spacer'),_c('base-btn',{attrs:{"disabled":_vm.selected == null,"type":"save"},on:{"click":_vm.onClickSave}},[_vm._v(" "+_vm._s(_vm.$t("btn-save"))+" ")])]})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }