import { axiosManufacturers } from "@/api/axios-instances";
import _ from "lodash";

const state = {
    companies: [],
    totalCompaniesCount: 0
};

const mutations = {
    setCompanies(state, companies) {
        state.companies = companies;
    },
    setTotalCompaniesCount(state, count) {
        state.totalCompaniesCount = count;
    },
    addOrUpdateCompany(state, company) {
        const item = state.companies.find(
            (item) => item.docId === company.docId
        );
        if (item) {
            _.merge(item, company);
        } else {
            state.companies.push(company);
        }
    },
    deleteCompany(state, company){
        const idx = state.companies.findIndex((item) => item.docId === company.docId);
        if (idx !== -1){
            state.companies = state.companies.slice(0, idx);
        }
    }
};

const getters = {};

const actions = {
    fetchAllCompanies: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .get("/", {params})
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const companies = resData?.data?.manufacturers;
                        const totalCount = resData?.data?.totalCount;
                        if (companies){
                            commit("setCompanies", companies);
                            commit("setTotalCompaniesCount", totalCount);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getCompany: (_ctx, docId) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .get(`/${docId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    addCompany: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .post("/", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        company.country = company.address.country;
                        if (company.docId){
                            commit("addOrUpdateCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    updateCompany: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .put(`/${data.docId}`, data )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        company.country = company.address.country;
                        if (company.docId){
                            commit("addOrUpdateCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deleteCompany: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
            .delete(`/${data.docId}`, { data })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        if (company.docId){
                            // Update company because company becomes a new delete state
                            // Company will be not removed
                            commit("addOrUpdateCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    searchCompany: (_ctx, params) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
            .get("/search", {params})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    reindexAll: () => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .post("/reindex")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    restoreFromTrash: ({ commit }, docId) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .put(`/restoreFromTrash/${docId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        company.country = company.address.country;
                        if (company.docId){
                            commit("addOrUpdateCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
