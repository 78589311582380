<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./registrations-with-open-dialogs-list-i18n.yaml"></i18n>
<template>
    <v-card tile flat>
        <v-card-title
            class="info--text"
            :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'text-h6'"
        >
            <v-icon
                class="mr-1"
                :small="$vuetify.breakpoint.xsOnly"
                color="info"
                >mdi-alert-circle-outline</v-icon
            >
            <span
                v-html="
                    $tc('msg-open-dialogs', registrations.length, {
                        counter: registrations.length
                    })
                "
            ></span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
            <v-list three-line class="pa-0" dense>
                <template v-for="(item, index) in registrations">
                    <v-subheader v-if="item.header" :key="item.header">
                        {{ item.header }}
                    </v-subheader>
                    <v-divider
                        v-else-if="item.divider"
                        :key="index"
                    ></v-divider>
                    <RegistrationListItem
                        v-else
                        :key="item.title"
                        @click="handleClick(item)"
                        :registration="getItemFormatted(item)"
                    ></RegistrationListItem>
                </template>
            </v-list>
            <v-divider></v-divider>
        </v-card-text>
    </v-card>
</template>
<script>
import { getRegistrationStateColor } from "@/utils/defaults.js";
import { OBJECT_TYPES } from "@/data/globals.js";
import RegistrationListItem from "@/components/tasks/common/registration-list-item.vue";
export default {
    name: "RegistrationsListWithOpenDialogs",
    components: {
        RegistrationListItem
    },
    props: {
        registrations: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        OBJECT_TYPES: OBJECT_TYPES
    }),
    methods: {
        handleClick(item) {
            if (!item.referenceId) return;
            this.$router.push({
                name: "task-dialogs",
                query: { ref: item.referenceId }
            });
        },
        getRegistrationStateColor: getRegistrationStateColor,
        getItemFormatted(item) {
            let ret = {};
            ret.workingTitle = item.workingTitle;
            ret.manufacturer = item.manufacturer;
            ret.factoryNumber = item.factoryNumber;
            ret.constructionYear = item.constructionYear;
            ret.objectType = item.objectType;
            ret.process = item.process;
            ret.state = item.state;
            const eqLocation = item.equipmentLocation;

            if (eqLocation) {
                const eqLocationAddrress = eqLocation.address;
                if (eqLocationAddrress) {
                    let address = {};
                    address.companyName = eqLocationAddrress.companyName;
                    address.department = eqLocationAddrress.department;
                    address.street = eqLocationAddrress.street;
                    address.houseNr = eqLocationAddrress.houseNr;
                    address.zipCode = eqLocationAddrress.zipCode;
                    address.city = eqLocationAddrress.city;
                    address.canton = eqLocationAddrress.canton;
                    address.country = eqLocationAddrress.country;
                    ret.address = address;
                }
            }
            return ret;
        }
    }
};
</script>
