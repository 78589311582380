<i18n src="./form-address-company-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <!-- <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, computedAddress)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @validate:
                <tree-view
                    :data="Object.assign({}, $v)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-times</v-icon>
                </base-btn>
            </template>
        </app-dev-container> -->
        <v-row>
            <v-col cols="12" md="6">
                <!-- <base-text-field
                    :value="computedAddress.companyName"
                    @input="emitOnFieldInput('companyName', $event)"
                    :label="$t('company-name-label')"
                    :required="isRequiredField($v.computedAddress.companyName)"
                    :error-messages="companyNameErrors"
                    :counter="
                        isRequiredField($v.computedAddress.companyName)
                            ? $v.computedAddress.companyName.$params.maxLength
                                  .max
                            : false
                    "
                ></base-text-field> -->
                <base-text-field
                    :value="computedAddress.companyName"
                    @input="emitOnFieldInput('companyName', $event)"
                    :label="$t('address-line-1-label')"
                    :error-messages="companyNameErrors"
                    :counter="
                        $v.computedAddress.companyName.$params.maxLength.max
                    "
                    :required="isRequiredField($v.computedAddress.companyName)"
                ></base-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
                <base-text-field
                    :value="computedAddress.department"
                    @input="emitOnFieldInput('department', $event)"
                    :label="$t('department-label')"
                    :required="isRequiredField($v.computedAddress.department)"
                    :error-messages="departmentErrors"
                    :counter="
                        isRequiredField($v.computedAddress.department)
                            ? $v.computedAddress.department.$params.maxLength
                                  .max
                            : false
                    "
                ></base-text-field>
            </v-col>
            <v-col cols="12">
                <base-text-field
                    :value="computedAddress.uid"
                    @input="emitOnFieldInput('uid', $event)"
                    :label="$t('uid-formatted-label')"
                    v-mask="'###.###.###'"
                    :prefix="
                        computedAddress.uidCategory
                            ? computedAddress.uidCategory
                            : 'CHE-'
                    "
                    clearable
                ></base-text-field>
            </v-col> -->
        </v-row>
    </v-form>
</template>

<script>
import validateMixin from "@/mixins/validate";
import { required, maxLength } from "vuelidate/lib/validators";
import mixin from "./mixin";
import { CompanyAddress } from "@/models/tasks";
export default {
    name: "FormAddressCompany",
    mixins: [validateMixin, mixin],
    props: {
        value: {
            type: Object,
            default: () => new CompanyAddress()
        }
    },
    validations() {
        return {
            computedAddress: {
                companyName: {
                    required,
                    maxLength: maxLength(40)
                },
                department: {
                    maxLength: maxLength(40)
                }
            }
        };
    },
    computed: {
        companyNameErrors() {
            const errors = [];
            if (!this.$v.computedAddress.companyName.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.companyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.companyName.$params
                                .maxLength.max
                    })
                );
            }
            if (!this.$v.computedAddress.companyName.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        departmentErrors() {
            const errors = [];
            if (!this.$v.computedAddress.department.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.department.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.department.$params.maxLength
                                .max
                    })
                );
            }
            return errors;
        }
    }
};
</script>
