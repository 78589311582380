import { render, staticRenderFns } from "./dialog-about-app.vue?vue&type=template&id=959cea84&"
import script from "./dialog-about-app.vue?vue&type=script&lang=js&"
export * from "./dialog-about-app.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./dialog-about-app-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fgeneral%2Fdialog-about-app.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VContainer,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VSubheader,VTooltip})
