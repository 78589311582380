<i18n src="@/data/countries/i18n/all.json"></i18n>
<i18n src="./WCountrySelect-i18n.yaml"></i18n>
<template>
    <base-autocomplete
        item-value="code"
        :items="countriesSorted"
        :label="label"
        :clearable="!readonly"
        ref="country"
        v-on="inputListeners"
        v-bind="$attrs"
        :value="value"
        :filter="customFilter"
        :search-input.sync="search"
        :menu-props="{ closeOnContentClick: true }"
    >
        <template v-slot:prepend-item v-if="preferredCountries && !search">
            <v-list-item
                v-for="(country, idx) in countriesFiltered"
                :key="idx"
                @click="$emit('input', country.code)"
                dense
            >
                <v-list-item-action class="mr-2">
                    <country-flag class="mb-0"  :country="country.code" />
                </v-list-item-action>
                <v-list-item-content class="pl-0 ml-0">
                    {{ country.name }}
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item }">
            <country-flag class="mb-0" :country="item.code" />
            <span class="pl-2">
                {{ item.name }}
            </span>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item dense @click="$emit('input', item.code)">
                <v-list-item-action class="mr-2">
                    <country-flag :country="item.code" />
                </v-list-item-action>
                <v-list-item-content class="pl-0 ml-0">
                    <v-list-item-title>
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </base-autocomplete>
</template>
<script>
import countriesListData from "@/data/countries/data.js";
import _ from "lodash";
export default {
    name: "WCountrySelect",
    props: {
        value: {
            type: String,
            default: ""
        },
        name: Boolean,
        className: String,
        shortCodeDropdown: Boolean,
        usei18n: {
            type: Boolean,
            default: true
        },
        preferredCountries: {
            type: Array,
            default: null
        },
        onlyCountries: {
            type: Array,
            default: null
        },
        ignoredCountries: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default() {
                return this.$t("label");
            }
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        search: null
    }),

    methods: {
        customFilter(item, queryText) {
            const countryName = item.name.toLowerCase();
            const countryCode = item.code.toLowerCase();
            const searchText = queryText.toLowerCase();

            return (
                countryName.indexOf(searchText) > -1 ||
                countryCode.indexOf(searchText) > -1
            );
        }
    },

    computed: {
        countriesListData() {
            const countriesList = this.$t("countries") || [];
            if (countriesList.length === 0) return countriesListData;
            let array = [];
            _.forEach(countriesList, (value, key) => {
                array.push({ code: key, name: value });
            });

            return array.sort((a, b) => a.name.localeCompare(b.name)) || array;
        },
        //this method allow to use all listners from outside
        //of the component and also to override the listners inside
        inputListeners: function () {
            // `Object.assign` merges objects together to form a new object
            return Object.assign({}, this.$listeners);
        },
        countriesFiltered() {
            let me = this;
            let countriesExceptions =
                this.onlyCountries || this.preferredCountries;
            return countriesExceptions.map((country) =>
                me.countriesList.find((item) => item.code.includes(country))
            );
        },
        otherCountries() {
            return this.countriesList.filter(
                (item) => !this.preferredCountries.includes(item.code)
            );
        },
        countriesSorted() {
            return this.onlyCountries
                ? this.countriesFiltered
                : this.countriesList;
        },
        countriesList() {
            return this.countriesListData.filter(
                (item) => !this.ignoredCountries.includes(item.code)
            );
        }
    }
};
</script>


