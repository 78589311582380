<i18n src="./form-communication-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="validate">
                    <v-icon>mdi-update</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <StepContentTitle :title="computedTitle" />
        <v-container fill-height fluid no-gutters class="pa-0 ma-0">
            <!-- Kontaktperson -->
            <v-row class="mt-2">
                <v-col class="py-1" cols="12" md="6">
                    <!-- Telefon -->
                    <WTelNumberField
                        ref="phone"
                        :required="
                            fieldsRequired || requiredFields.includes('phone')
                        "
                        :label="$t('phone-label')"
                        @input="onInputPhone"
                        :value="phone.number"
                        :disabled="disabled"
                        :readonly="readonlyModus"
                    >
                    </WTelNumberField>
                </v-col>
                <v-col class="py-1" cols="12" md="6">
                    <!-- Telefax -->
                    <WTelNumberField
                        ref="fax"
                        :required="
                            fieldsRequired || requiredFields.includes('fax')
                        "
                        :label="$t('fax-label')"
                        @input="onInputFax"
                        :value="fax.number"
                        :disabled="disabled"
                        :readonly="readonlyModus"
                    >
                    </WTelNumberField>
                </v-col>
            </v-row>
        </v-container>
        <v-container fill-height fluid>
            <v-row>
                <!-- E-Mail -->
                <base-text-field
                    v-model.trim="email"
                    :label="$t('email-label')"
                    :error-messages="emailErrors"
                    @input="onInputEmail"
                    :required="isRequiredField($v.email)"
                ></base-text-field>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import { maxLength, email, requiredIf } from "vuelidate/lib/validators";
import WTelNumberField from "@/components/fields/WTelNumberField.vue";
import StepContentTitle from "../components/step-content-title.vue";
import _ from "lodash";
import validateMixin from "@/mixins/validate";

export default {
    name: "FormCommunication",
    components: {
        WTelNumberField,
        StepContentTitle
    },
    mixins: [validateMixin],
    validations() {
        return {
            email: {
                required: requiredIf(function () {
                    if (
                        this.fieldsRequired ||
                        this.requiredFields.includes("email")
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                email,
                maxLength: maxLength(50)
            }
        };
    },
    props: {
        required: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        requiredFields: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        }
    },
    data: () => ({
        email: null,
        phone: {
            number: "",
            valid: false,
            country: "CH"
        },
        fax: {
            number: "",
            valid: false,
            country: "CH"
        }
    }),
    methods: {
        onFieldChange(field, value) {
            if (!field) return;
            this.$emit("field-change", field, value);
        },
        onInputPhone: _.debounce(function (newPhone) {
            if (newPhone) {
                if ("valid" in newPhone) {
                    this.phone.valid = newPhone.valid;
                }
                if ("country" in newPhone) {
                    this.phone.country = newPhone.country;
                }
                if ("number" in newPhone) {
                    this.phone.number = newPhone.number;
                    this.onFieldChange("phone", newPhone.number);
                }
            }
        }, 100),
        onInputFax: _.debounce(function (newFax) {
            if (newFax) {
                if ("valid" in newFax) {
                    this.fax.valid = newFax.valid;
                }
                if ("country" in newFax) {
                    this.fax.country = newFax.country;
                }
                if ("number" in newFax) {
                    this.fax.number = newFax.number;
                    this.onFieldChange("fax", newFax.number);
                }
            }
        }, 100),
        onInputEmail: _.debounce(function (newEmail) {
            this.onFieldChange("email", newEmail);
        }, 100),
        isValid() {
            let isValid = true;
            if (!this.$v.$dirty) {
                return null;
            } else {
                if (this.fieldsAreEmpty) return false;
                if (!this.$v.$anyError) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            }
            if (
                this.requiredFields.includes("phone") ||
                (this.phone.number && this.phone.number.length > 0)
            ) {
                if (!this.$refs.phone.isValid()) {
                    isValid = false;
                }
            }
            if (
                this.requiredFields.includes("fax") ||
                (this.fax.number && this.fax.number.length > 0)
            ) {
                if (!this.$refs.fax.isValid()) {
                    isValid = false;
                }
            }
            return isValid;
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        validate() {
            this.$v.$touch();
            this.$refs.phone.validate();
            this.$refs.fax.validate();
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                this.$refs.phone.scrollToFirstError();
                this.$refs.fax.scrollToFirstError();
            });
        },
        reset() {
            this.phone = {
                number: "",
                valid: false,
                country: "CH"
            };
            this.fax = {
                number: "",
                valid: false,
                country: "CH"
            };

            this.$v.$reset();
            this.$refs.phone.reset();
            this.$refs.fax.reset();
            this.email = null;

            this.onFieldChange("phone", null);
            this.onFieldChange("fax", null);
            this.onFieldChange("email", null);
        },
        getData() {
            let ret = {};
            ret.phone = this.phone.number;
            ret.fax = this.fax.number;
            ret.email = this.email;

            return ret;
        },
        setFakeData() {
            const data = {
                phone: "+41 79 563 88 00",
                fax: "+41 79 563 88 11",
                email: "info@was.ch"
            };
            this.setData(data);
        },
        setData(data) {
            if (!data) return;
            const faxNr = data.fax || null;
            const phoneNr = data.phone || null;
            const email = data.email || null;
            if (phoneNr != null) {
                this.phone.number = data.phone;
            } else {
                this.phone.number = "";
            }
            if (faxNr != null) {
                this.fax.number = data.fax;
            } else {
                this.fax.number = "";
            }
            if (email) {
                this.email = email;
            }
        },
        checkIfAllFieldsAreEmpty(data) {
            let empty = false;
            if (!data) return;
            const faxNr = data.fax || null;
            const phoneNr = data.phone || null;
            const email = data.email || null;
            if (
                !email &&
                (phoneNr === "" || !phoneNr) &&
                (faxNr === "" || !faxNr)
            ) {
                empty = true;
            }
            return empty;
        }
    },
    computed: {
        fieldsAreEmpty() {
            const data = {
                email: this.email,
                phone: this.phone.number,
                fax: this.fax.number
            };
            return this.checkIfAllFieldsAreEmpty(data);
        },
        computedTitle() {
            if (this.title) {
                return this.title;
            }
            return this.$t("title");
        },
        fieldsRequired() {
            if (this.required) {
                return this.fieldsAreEmpty;
            } else {
                return false;
            }
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) {
                return errors;
            }
            if (!this.$v.email.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.email.email) {
                errors.push(this.$t("field-invalid-email"));
            }
            if (!this.$v.email.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.email.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },
    watch: {
        formData: {
            handler: function (newData) {
                if (newData) {
                    this.setData(newData);
                }
            },
            immediate: true
        }
    }
};
</script>
