<i18n src="./content-i18n.yaml"></i18n>
<template>
    <base-card>
        <BackofficeBaseList
            :items="items"
            :options="options"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            @reloadDataClick="fetchPendingPoolRegistrations"
            :show-select="Boolean(developerMode)"
            v-model="selectedTasks"
            item-key="docId"
            @task:lock-for-review="onTaskLockForReview"
            @task:delete="onTaskDelete"
            @task:remove-from-db="onTaskRemoveFromDB"
        >
        </BackofficeBaseList>
        <template v-if="developerMode">
            <template v-if="selectedTasks.length > 0">
                <base-btn type="delete" @click="onDeleteSelected">
                    Delete {{ selectedTasks.length }}
                </base-btn>
            </template>
        </template>
    </base-card>
</template>
<script>
import { mapState } from "vuex";
import { TaskActionTypes } from "@/data/task-action-types";
import taskList from "@/mixins/backoffice/taskList";
import BackofficeBaseList from "@/components/backoffice/components/base-list.vue";
export default {
    name: "BackofficePendingPoolContent",
    mixins: [taskList],
    components: {
        BackofficeBaseList
    },
    data() {
        return {
            options: {
                sortBy: "registrantCompleteDate"
            },
            actionsItems: [
                TaskActionTypes.VIEW,
                TaskActionTypes.DATA,
                TaskActionTypes.LOCK_FOR_REVIEW,
                TaskActionTypes.VIEW_HISTORY,
                TaskActionTypes.DELETE,
                TaskActionTypes.REMOVE_FROM_DB
            ],
            selectedTasks: []
        };
    },
    computed: {
        ...mapState("backoffice", {
            items: "pendingPoolRegistrations"
        }),
        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant"),
                    value: "registrant",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false,
                    sort: (a, b) => {
                        const timestampA = this.$moment.utc(a).unix();
                        const timestampB = this.$moment.utc(b).unix();
                        return timestampA - timestampB;
                    }
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        }
    },
    methods: {
        fetchPendingPoolRegistrations() {
            this.loader().show();
            this.$store
                .dispatch("backoffice/fetchPendingPoolRegistrations")
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        onTaskLockForReview(task) {
            this.loader().show();
            this.$axiosBackoffice
                .put(`/lockForReview/${task.docId}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("lock-for-review-msg-success")
                        );
                        this.$store.commit(
                            "backoffice/removeRegistrationFromList",
                            {
                                list: "pendingPoolRegistrations",
                                docId: task.docId
                            }
                        );
                        if ("process" in task && "docId" in task) {
                            this.onEditViewRegistration(
                                task.process,
                                task.referenceId
                            );
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(
                        this.$t("msg-task-lock-for-review-error")
                    );
                    console.log(error);
                    this.loader().hide();
                });
        },
        onTaskRemoveFromDB(item) {
            this.loader().show();
            this.$store
                .dispatch(
                    "backoffice/deletePendingPoolRegistrationFromDB",
                    item.docId
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-task-remove-from-db-success")
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-task-remove-from-db-error"));
                    this.loader().hide();
                });
        },

        onTaskDelete(task, result) {
            this.loader().show();
            const data = {
                docId: task.docId,
                reason: result.reason
            };
            this.$store
                .dispatch("backoffice/deletePendingPoolRegistration", data)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-task-delete-success"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-task-delete-error"));
                    this.loader().hide();
                });
        },
        onDeleteSelected() {
            if (!this.developerMode || this.selectedTasks.length === 0) return;
            this.selectedTasks.forEach((task) => {
                this.onTaskRemoveFromDB(task);
            });
            this.selectedTasks = [];
        }
    },

    created() {
        if (this.isAuth) {
            this.fetchPendingPoolRegistrations();
        }
    }
};
</script>
