<template>
    <v-navigation-drawer
        v-model="drawer"
        v-bind="$attrs"
        v-on="inputListeners"
        app
        touchless
        :right="right"
        :fixed="fixed"
        :clipped="clipped"
        width="300"
    >
        <slot name="navigation-content" />
        <template v-slot:append>
            <div class="pa-2">
                <NavigationFooter @logout="onLogout" />
            </div>
        </template>
    </v-navigation-drawer>
</template>
<script>
import NavigationFooter from "./navigation-footer.vue";
export default {
    name: "AppNavigationDrawer",
    components: {
        NavigationFooter
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: true
        },
        fixed: {
            type: Boolean,
            default: true
        },
        clipped: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onNavigationItemClick() {
            this.$emit("navigation-item-click");
        },
        onLogout() {
            this.$store.dispatch("auth/logout");
            this.onNavigationItemClick();
        }
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        },
        inputListeners: function () {
            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function (value) {
                        vm.$emit("input", value);
                    }
                }
            );
        }
    }
};
</script>
