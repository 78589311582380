var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"dense":""}},_vm._l((_vm.data),function(item,idx){return _c('v-list-item',{key:idx},[(_vm.$vuetify.breakpoint.xsOnly)?[('key' in item && 'value' in item)?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold text-body-2"},[(item.key.icon)?[(item.key.icon.action)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                                    'icon-key-click',
                                    item.key.icon.action
                                )}}},[_c('v-icon',{attrs:{"small":""},domProps:{"innerHTML":_vm._s(item.key.icon.value)}})],1):_c('v-icon',{attrs:{"color":item.key.icon.color
                                    ? item.key.icon.color
                                    : 'primary',"small":""},domProps:{"innerHTML":_vm._s(item.key.icon.value)}})]:_vm._e(),_vm._v(" "+_vm._s(item.key.text)+" ")],2),_c('div',{staticClass:"text-subtitle-2"},[(item.value.icon)?[(item.value.icon.action)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                                    'icon-value-click',
                                    item.value.icon.action
                                )}}},[_c('v-icon',{attrs:{"small":""},domProps:{"innerHTML":_vm._s(item.value.icon.value)}})],1):_c('v-icon',{attrs:{"color":item.value.icon.color
                                    ? item.value.icon.color
                                    : 'primary',"small":""},domProps:{"innerHTML":_vm._s(item.value.icon.value)}})]:_vm._e(),_vm._v(" "+_vm._s(item.value.text)+" ")],2)],1):_vm._e()]:[(item.key && item.key.text)?_c('v-list-item-content',{staticClass:"mr-0"},[_c('div',{staticClass:"mr-0 pa-0 ma-0"},[(item.key && item.key.icon)?_c('v-list-item-icon',{staticClass:"mr-0"},[(item.key.icon.action)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                                    'icon-key-click',
                                    item.key.icon.action
                                )}}},[_c('v-icon',{attrs:{"small":""},domProps:{"innerHTML":_vm._s(item.key.icon.value)}})],1):_c('v-icon',{attrs:{"color":item.key.icon.color
                                    ? item.key.icon.color
                                    : 'primary',"small":""},domProps:{"innerHTML":_vm._s(item.key.icon.value)}})],1):_vm._e(),_vm._v(" "+_vm._s(item.key.text)+" ")],1)]):_vm._e(),(item.value && item.value.text)?_c('v-list-item-content',{staticClass:"align-end mr-0"},[_c('div',[(item.value && item.value.icon)?_c('v-list-item-icon',{staticClass:"mr-0"},[(item.value.icon.action)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                                    'icon-value-click',
                                    item.value.icon.action
                                )}}},[_c('v-icon',{attrs:{"small":""},domProps:{"innerHTML":_vm._s(item.value.icon.value)}})],1):_c('v-icon',{attrs:{"color":item.value.icon.color
                                    ? item.value.icon.color
                                    : 'primary',"small":""},domProps:{"innerHTML":_vm._s(item.value.icon.value)}})],1):_vm._e(),_vm._v(" "+_vm._s(item.value.text)+" ")],1)]):_vm._e()]],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }