import { render, staticRenderFns } from "./task-summary-copy.vue?vue&type=template&id=e48534fa&scoped=true&"
import script from "./task-summary-copy.vue?vue&type=script&lang=js&"
export * from "./task-summary-copy.vue?vue&type=script&lang=js&"
import style0 from "./task-summary-copy.vue?vue&type=style&index=0&id=e48534fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e48534fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VDivider,VList,VListItem,VListItemAction,VListItemContent})
