<i18n src="./config-i18n.yaml"></i18n>
<template>
    <div>
        <v-row dense>
            <v-col cols="12">
                <base-header>
                    <v-icon>mdi-cog</v-icon>
                    {{ $t("title") }}
                </base-header>
            </v-col>
            <v-col>
                <base-card-text v-if="configData">
                    <template v-slot:content>
                        <FormConfig
                            :disabled="isDisabled"
                            ref="formConfig"
                            :data="configData"
                            @change="onFormConfigDataChange"
                        />
                    </template>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <base-btn
                            type="info"
                            :btnIcon="isDisabled ? 'mdi-lock' : 'mdi-lock-open'"
                            @click.prevent="isDisabled = !isDisabled"
                        >
                        </base-btn>
                        <base-btn
                            v-if="!isDisabled"
                            type="save"
                            @click.prevent="onSave"
                        >
                            {{ $t("btn-save") }}
                        </base-btn>
                    </v-card-actions>
                </base-card-text>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "ViewConfig",
    components: {
        FormConfig: () => import("@/components/admin/config/form-config.vue")
    },
    data: () => ({
        data: {},
        isDisabled: true
    }),
    computed: {
        ...mapState("admin", {
            configData: "config"
        })
    },
    methods: {
        reload() {
            this.fetchConfigData();
        },
        fetchConfigData() {
            this.loader().show(this.$t("loading-msg-fetch-data"));
            this.$store
                .dispatch("admin/fetchConfig")
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        onSave() {
            this.$refs.formConfig.validate();
            if (this.$refs.formConfig.isValid()) {
                this.loader().show(this.$t("loading-msg-save-data"));
                this.$store
                    .dispatch("admin/updateConfig", this.data)
                    .then(() => {
                        this.$toast.success(this.$t("save-msg-success"));
                        this.isDisabled = true;
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(this.$t("save-msg-error"));
                        this.loader().hide();
                    });
            } else {
                this.$toast.error(this.$t("form-is-invalid-msg"));
            }
        },
        onFormConfigDataChange(data) {
            this.data = data;
        }
    },
    mounted() {
        this.fetchConfigData();
    }
};
</script>
