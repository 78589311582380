import { mapGetters, mapState } from "vuex";
import fluidContentWrapper from "@/mixins/fluidContentWrapper";
import TasksTable from "@/components/backoffice/components/tasks-table.vue";
import DialogViewData from "@/components/tasks/common/dialog-view-data.vue";
import { PROCESS_TYPES } from "@/data/globals.js";

export default {
    mixins: [fluidContentWrapper],
    components: {
        TasksTable,
        DialogViewData
    },

    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapGetters("settings", ["developerMode"]),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        computedTitle() {
            if (this.title) {
                return this.title + " (" + this.items.length + ")";
            }
            return null;
        }
    },

    methods: {
        onViewRegistration(process, referenceId) {
            if (!referenceId) return;
            if (process === PROCESS_TYPES.REGISTRATION) {
                this.$router.push({
                    name: "backoffice-task-view",
                    params: { referenceId: referenceId }
                });
            } else if (process === PROCESS_TYPES.DEREGISTRATION) {
                this.$router.push({
                    name: "backoffice-task-view",
                    params: { referenceId: referenceId }
                });
            } else {
                this.$toast.info(
                    `View for ${process} with ReferenceID: ${referenceId} is currently in development (available soon)`
                );
            }
        },
        onEditViewRegistration(process, referenceId) {
            if (!referenceId) return;
            if (process === PROCESS_TYPES.REGISTRATION) {
                this.$router.push({
                    name: "backoffice-view",
                    params: { referenceId: referenceId }
                });
            } else if (process === PROCESS_TYPES.DEREGISTRATION) {
                this.$router.push({
                    name: "backoffice-deregistration-view-edit",
                    params: { referenceId: referenceId }
                });
            } else {
                this.$toast.info(
                    `View for ${process} with docID: ${referenceId} is currently in development (available soon)`
                );
            }
        }
    },

    watch: {
        computedTitle: {
            handler: function (value) {
                if (value) {
                    this.$store.commit("backoffice/setTitle", value);
                }
            },
            immediate: true
        }
    },

    destroyed() {
        this.$store.commit("backoffice/setDefaulTitle");
    },

    mounted() {
        this.$store.commit("backoffice/setTitle", this.computedTitle);
    }
};
