<i18n src="./WSapEquipmentNr-i18n.yaml"></i18n>
<template>
    <base-text-field
        v-model="sapEquipmentNr"
        type="number"
        class="remove-spin-buttons"
        v-mask="'#######'"
        v-bind="$attrs"
        v-on="$listeners"
        :hint="hint"
        :label="label"
    ></base-text-field>
</template>
<script>
import validateMixin from "@/mixins/validate";
export default {
    name: "WSapEquipmentNr",
    mixins: [validateMixin],
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        hint: {
            type: String,
            default() {
                return this.$t("hint");
            }
        },
        label: {
            type: String,
            default() {
                return this.$t("label");
            }
        }
    },
    computed: {
        sapEquipmentNr: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    }
};
</script>
