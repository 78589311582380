var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","flat":""}},[(_vm.title)?_c('v-card-title',{staticClass:"text-h6 pl-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subtitle)?_c('v-card-subtitle',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.title || _vm.subtitle)?_c('v-divider'):_vm._e(),_c('v-list',{attrs:{"dense":"","subheader":""}},[(_vm.stepSortedDialogs.length > 0)?_vm._l((_vm.stepSortedDialogs),function(dialogItem){return _c('v-list-item',{key:dialogItem.id,attrs:{"three-line":"","disabled":_vm.replyDisabled(dialogItem.state)},on:{"click":function($event){return _vm.$emit('click', dialogItem)}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[(
                            dialogItem.state ==
                            _vm.TaskDialogStatus.TASK_DIALOG_CREATED
                        )?_c('w-icon',{attrs:{"data":require('@/assets/icons/w-message-star.svg'),"color":_vm.getDialogItemColorByState(dialogItem.state),"width":_vm.dialogIconWidth}}):_vm._e(),(
                            dialogItem.state ==
                            _vm.TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                        )?_c('w-icon',{attrs:{"data":require('@/assets/icons/w-message-star.svg'),"color":_vm.getDialogItemColorByState(dialogItem.state),"width":_vm.dialogIconWidth}}):_vm._e(),(
                            dialogItem.state ==
                            _vm.TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
                        )?_c('w-icon',{attrs:{"data":require('@/assets/icons/w-message-eye.svg'),"color":_vm.getDialogItemColorByState(dialogItem.state),"width":_vm.dialogIconWidth}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-bell")]),(
                                _vm.$moment
                                    .utc(dialogItem.reminder)
                                    .local()
                                    .diff(_vm.$moment(), 'days') == 2
                            )?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-bell-alert")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("reminder-item", { reminderDate: _vm.getFormattedDate( dialogItem.reminder ), reminderDateString: _vm.getFormattedDateString( dialogItem.reminder ) }))+" ")],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("dialog-created-caption", { userName: dialogItem.createdBy, timestamp: _vm.getFormattedDateString( dialogItem.createdTimestamp ) }))+" ")]),_c('v-list-item-title',[(
                                dialogItem.messages.slice(-1).pop().reply &&
                                dialogItem.messages.slice(-1).pop().reply
                                    .text
                            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("registrant"))+": "+_vm._s(dialogItem.messages.slice(-1).pop().reply .text)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("backoffice"))+": "+_vm._s(dialogItem.messages.slice(-1).pop().question .text)+" ")])])],1),_c('v-list-item-action',[_c('base-btn',{attrs:{"tile":_vm.$vuetify.breakpoint.mdAndUp,"color":"info","disabled":_vm.replyDisabled(dialogItem.state),"fab":_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', dialogItem)}}},[(
                                dialogItem.state ==
                                    _vm.TaskDialogStatus.TASK_DIALOG_CREATED ||
                                (dialogItem.state ==
                                    _vm.TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS &&
                                    _vm.registrationData.state ==
                                        _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS)
                            )?_c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v("mdi-message-reply-text")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-reply"))+" ")]):_vm._e()],1):_c('div',[(
                                    _vm.registrationData.state ==
                                    _vm.TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                                )?_c('span',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-edit"))+" ")]):_vm._e()],1):_c('span',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v(" mdi-eye ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-show"))+" ")]):_vm._e()],1)])])],1)],1)}):_vm._e(),(_vm.stepSortedDialogs.length == 0)?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-title',{staticClass:"text-body-1 font-weight-bold py-1"},[_vm._v(_vm._s(_vm.$t("empty-messages-text"))+" ")])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }