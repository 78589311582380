<i18n src="./modal-address-book-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="value"
        max-width="800"
        icon="mdi-notebook-outline"
        ref="baseDialog"
        @close="onCloseDialog"
    >
        <template v-slot:toolbar-title>
            {{ $t("title-dialog") }}
            <FaqOpenBtn searchId="addressBook" />
        </template>
        <template v-slot:content>
            <v-container class="cls-scroll-hidden">
                <v-toolbar flat>
                    <v-text-field
                        v-model="searchTerm"
                        solo
                        prepend-inner-icon="mdi-magnify"
                        :placeholder="$t('search-field-hint')"
                        :label="$t('search-field-label')"
                        hide-details
                        clearable
                        :loading="isLoader"
                    >
                        <template v-slot:progress>
                            <v-progress-linear
                                v-show="isLoader"
                                color="info"
                                indeterminate
                                absolute
                                height="3"
                            ></v-progress-linear>
                        </template>
                        <template v-slot:append-outer>
                            <v-menu
                                style="top: -12px"
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon left v-bind="attrs" v-on="on">
                                        mdi-dots-vertical
                                    </v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="pa-6">
                                        <v-btn-toggle
                                            v-model="toggleMultimediaView"
                                            mandatory
                                            group
                                        >
                                            <v-btn value="list" small>
                                                <v-icon small
                                                    >mdi-format-list-bulleted-square</v-icon
                                                >
                                            </v-btn>
                                            <v-btn value="grid" small>
                                                <v-icon small
                                                    >mdi-view-grid</v-icon
                                                >
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-text-field>
                </v-toolbar>
                <div class="ps-navigation-content">
                    <PerfectScrollbar
                        class="ps-navigation-content-items"
                        :options="perfectScrollbarOptions"
                        @ps-scroll-y="
                            (evt) => {
                                shallShadowBottom =
                                    evt.srcElement.scrollTop > 0;
                            }
                        "
                    >
                        <v-item-group v-model="selected">
                            <v-row dense class="px-4 pt-2">
                                <v-col
                                    v-for="address in myAddresses"
                                    :key="address.id"
                                    cols="12"
                                    xs="12"
                                    :sm="
                                        toggleMultimediaView === 'grid'
                                            ? myAddresses.length > 2
                                                ? 4
                                                : 6
                                            : 12
                                    "
                                >
                                    <v-item v-slot="{ active, toggle }">
                                        <v-sheet
                                            outlined
                                            rounded
                                            :color="active ? 'info' : ''"
                                            class="fill-height"
                                        >
                                            <CardAddressBook
                                                :address="address"
                                                :multimediaView="
                                                    toggleMultimediaView
                                                "
                                                @click.prevent="toggle"
                                            />
                                        </v-sheet>
                                    </v-item>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col
                                    v-for="address in addressBookAddresses"
                                    :key="address.id"
                                    cols="12"
                                    xs="12"
                                    :sm="
                                        toggleMultimediaView === 'grid' ? 6 : 12
                                    "
                                >
                                    <v-item v-slot="{ active, toggle }">
                                        <v-sheet
                                            outlined
                                            rounded
                                            :color="active ? 'info' : ''"
                                            class="fill-height"
                                        >
                                            <CardAddressBook
                                                :address="address"
                                                :multimediaView="
                                                    toggleMultimediaView
                                                "
                                                @click.prevent="toggle"
                                            />
                                        </v-sheet>
                                    </v-item>
                                </v-col>
                                <v-col
                                    cols="12"
                                    v-if="filteredAddresses.length === 0"
                                >
                                    <div class="text-center">No data found</div>
                                </v-col>
                            </v-row>
                        </v-item-group>
                    </PerfectScrollbar>
                </div>
            </v-container>
        </template>
        <template v-slot:actions>
            <slot name="actions">
                <v-spacer></v-spacer>
                <base-btn
                    @click="onClickSave"
                    :disabled="selected == null"
                    type="save"
                >
                    {{ $t("btn-save") }}
                </base-btn>
            </slot>
        </template>
    </base-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { ADDRESS_TYPES, ADDRESS_IDS } from "@/data/globals.js";
export default {
    name: "ModalAddressBook",
    components: {
        FaqOpenBtn: () => import("@/components/general/faq/faq-open-btn.vue"),
        CardAddressBook: () => import("./card-address-book.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isLoader: false,
        searchTerm: null,
        toggleMultimediaView: "grid",
        selected: null,
        perfectScrollbarOptions: {
            maxScrollbarLength: 80,
            wheelPropagation: false,
            minScrollbarLength: 20,
            suppressScrollX: true
        }
    }),
    computed: {
        ...mapState("auth", ["addresses"]),
        ...mapGetters("auth", [
            "addressOperator",
            "addressBill",
            "addressOwner"
        ]),

        filteredAddressesBySearchTerm() {
            if (!this.searchTerm) return this.addresses;
            const searchTermLowerCase = this.searchTerm.toLowerCase().trim();
            return this.addresses.filter((address) => {
                return (
                    (address.addressType === ADDRESS_TYPES.COMPANY
                        ? address.companyName
                              .toLowerCase()
                              .includes(searchTermLowerCase) ||
                          address.uid
                              .toLowerCase()
                              .includes(searchTermLowerCase) ||
                          address.uidFormatted
                              .toLowerCase()
                              .includes(searchTermLowerCase)
                        : `${address.firstName} ${address.familyName}`
                              .toLowerCase()
                              .includes(searchTermLowerCase)) ||
                    address.street
                        .toLowerCase()
                        .includes(searchTermLowerCase) ||
                    address.city.toLowerCase().includes(searchTermLowerCase) ||
                    address.zipCode
                        .toLowerCase()
                        .includes(searchTermLowerCase) ||
                    (address.communication
                        ? address.communication.email
                            ? address.communication.email
                                  .toLowerCase()
                                  .includes(searchTermLowerCase)
                            : undefined || address.communication.phone
                            ? address.communication.phone
                                  .toLowerCase()
                                  .includes(searchTermLowerCase)
                            : undefined || address.communication.fax
                            ? address.communication.fax
                                  .toLowerCase()
                                  .includes(searchTermLowerCase)
                            : undefined
                        : undefined)
                );
            });
        },
        myAddresses() {
            let filteredAddresses = [...this.filteredAddressesBySearchTerm];
            const filterIds = [
                ADDRESS_IDS.OPERATOR,
                ADDRESS_IDS.BILL,
                ADDRESS_IDS.OWNER
            ];
            return this.getMyAddresses(filteredAddresses, filterIds);
        },
        addressBookAddresses() {
            let filteredAddresses = [...this.filteredAddressesBySearchTerm];
            const filterIds = [
                ADDRESS_IDS.OPERATOR,
                ADDRESS_IDS.BILL,
                ADDRESS_IDS.OWNER
            ];
            return this.getAddressBookAddresses(filteredAddresses, filterIds);
        },
        filteredAddresses() {
            return [...this.myAddresses, ...this.addressBookAddresses];
        }
    },
    methods: {
        getMyAddresses(filteredAddresses, filterIds) {
            let ret = [];
            filteredAddresses.forEach((a) => {
                if (a.id) {
                    const idx = filterIds.indexOf(a.id);
                    if (idx !== -1) {
                        return (ret[idx] = a);
                    }
                }
            });
            // filter array with empty function to remove empty objs
            return ret.filter(() => true);
        },
        getAddressBookAddresses(filteredAddresses, filterIds) {
            return filteredAddresses.filter((address) => {
                return !filterIds.includes(address.id);
            });
        },
        onCloseDialog(e) {
            this.$emit("input", e);
        },
        onSelectAddress(address) {
            this.$emit("select:address", address);
        },
        onClickSave() {
            const address = this.filteredAddresses[this.selected];
            if (address) {
                return this.onSelectAddress(address);
            }
        },
        fetchAddresses() {
            this.loader().show();
            this.$store
                .dispatch("auth/fetchAllAddresses")
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                // watch it
                if (newVal) {
                    this.selected = null;
                    this.fetchAddresses();
                }
            },
            immediate: true
        }
    }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style lang="sass" scoped>
.ps-navigation-content
    height: 550px
    .ps__rail-y,
    .ps__rail-x
        opacity: 0.6 !important
        background-color: transparent !important
    .ps-navigation-content-items
        height: 100% !important
</style>
