<template>
    <v-fab-transition>
        <v-btn
            fixed
            bottom
            right
            fab
            width="45px"
            height="45px"
            :title="text"
            color="regOfficePrimary"
            @click="toTop"
            v-show="visible"
            ref="btnScrollTop"
            style="z-index: 2"
            aria-label="text"
            v-scroll="onScroll"
        >
            <v-icon color="secondary">mdi-chevron-up</v-icon>
        </v-btn>
    </v-fab-transition>
</template>
<script>
//reference:  https://github.com/caiofsouza/vue-backtotop
import { Scroll } from "vuetify/lib/directives";
export default {
    name: "BtnScrollTop",
    directives: {
        Scroll
    },
    props: {
        text: {
            type: String,
            default: "Nach oben scrollen"
        },
        visibleoffset: {
            type: [String, Number],
            default: 100
        },
        visibleoffsetbottom: {
            type: [String, Number],
            default: 0
        },
        right: {
            type: String,
            default: "20px"
        },
        bottom: {
            type: String,
            default: "40px"
        },
        scrollFn: {
            type: Function,
            default: () => {}
        },
        navigationDrawerStatus: {
            type: Boolean,
            default: false
        },
        maxContentSize: {
            type: String,
            default: "1040px"
        },
        visibleTimeout: {
            type: Number,
            default: 3000
        }
    },
    data() {
        return {
            visible: false
        };
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    destroyed() {
        window.removeEventListener("scroll", this.catchScroll);
    },
    methods: {
        onResize() {
            if (this.navigationDrawerStatus) {
                this.$refs.btnScrollTop.$el.style.right = 310 + "px";
            } else {
                let value = window.innerWidth;
                this.isMobile = value < 600;
                let counter = 0;
                if (this.maxContentSize === "100%") {
                    counter = 0;
                } else {
                    counter = value - this.maxContentSize;
                }
                if (counter > 0) {
                    let margin = counter / 2 - 6;
                    if (this.$refs.btnScrollTop) {
                        this.$refs.btnScrollTop.$el.style.right = margin + "px";
                    }
                } else {
                    this.$refs.btnScrollTop.$el.style.right = "";
                }
            }
        },
        onScroll() {
            if (typeof window === "undefined") return;
            const top =
                window.pageYOffset || document.documentElement.offsetTop || 0;
            this.visible = top > 300;
        },
        toTop() {
            this.$vuetify.goTo(0);
        }
    },

    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true
            });
        }
    },

    watch: {
        visible: {
            handler: function (show) {
                if (show) {
                    setTimeout(() => {
                        this.visible = false;
                    }, this.visibleTimeout);
                }
            },
            immediate: true
        }
    }
};
</script>
