<i18n src="./account-settings-i18n.yaml"></i18n>
<template>
    <v-card flat class="mx-auto">
        <BaseSubheader class="px-4">
            {{ $t("title-account-settings") }}
        </BaseSubheader>
        <SettingsTitle>
            <template v-slot:title>
                {{ $t("title-account-settings-name") }}
            </template>
        </SettingsTitle>
        <v-card-text>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" v-if="!hasSettings">
                        <base-alert type="info" dense>
                            {{ $t("msg-alert-user-has-not-settings") }}
                        </base-alert>
                    </v-col>
                    <v-col cols="12" md="2">
                        <base-select
                            :items="salutationItems"
                            v-model="salutation"
                            :label="$t('salutation-label')"
                            item-value="key"
                            :error-messages="salutationErrors"
                            @change="onFieldChange"
                            :required="isRequiredField($v.salutation)"
                            :item-text="
                                (item) => $t(item.value.title[$i18n.locale])
                            "
                        >
                        </base-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                        <base-text-field
                            name="firstname"
                            v-model="firstname"
                            :label="$t('firstname')"
                            :error-messages="firstnameErrors"
                            clearable
                            @input="onFieldChange"
                            :counter="$v.firstname.$params.maxLength.max"
                            :required="isRequiredField($v.firstname)"
                        >
                        </base-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                        <base-text-field
                            name="familyname"
                            v-model="familyname"
                            :label="$t('familyname')"
                            :error-messages="familynameErrors"
                            clearable
                            @input="onFieldChange"
                            :counter="$v.familyname.$params.maxLength.max"
                            :required="isRequiredField($v.familyname)"
                        >
                        </base-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <base-text-field
                            :label="$t('email')"
                            :value="decryptMail(mail)"
                            disabled
                        >
                        </base-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <base-btn
                v-if="hasSettings"
                type="save"
                class="mr-4"
                @click.prevent="save"
                :disabled="isSubmitBtnDisabled"
            >
                {{ $t("btn-submit") }}
            </base-btn>
            <base-btn
                v-if="!hasSettings"
                class="mr-4"
                type="save"
                @click.prevent="saveAndGoToDashboard"
                :disabled="isSubmitBtnDisabled"
            >
                {{ $t("btn-submit-and-goto-dashboard") }}
            </base-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
export default {
    name: "AccountSettings",
    mixins: [validateMixin],
    components: {
        SettingsTitle: () =>
            import("@/components/user-dashboard/settings-title.vue")
    },
    props: {
        docId: {
            type: String,
            default: null
        }
    },
    validations: {
        salutation: {
            required
        },
        firstname: {
            required,
            maxLength: maxLength(50)
        },
        familyname: {
            required,
            maxLength: maxLength(50)
        }
    },
    data: () => ({
        salutation: null,
        firstname: "",
        familyname: ""
    }),
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        getAccountSettings() {
            return new Promise((resolve, reject) => {
                this.loader().show();
                this.$store
                    .dispatch("auth/getAccountSettings")
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            resolve(response);
                        }
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader().hide();
                        reject(error);
                    });
            });
        },
        saveAndGoToDashboard() {
            this.submit().then(() => {
                this.$router.push({ name: "user-dashboard" });
            });
        },
        save() {
            this.submit();
        },
        submit() {
            return new Promise((resolve, reject) => {
                this.validate();
                if (this.$v.$anyError) {
                    reject("Form is invalid");
                    return;
                }
                this.loader().show();
                let data = {};
                data.salutation = this.salutation;
                data.firstname = this.firstname;
                data.familyname = this.familyname;
                data.lang = this.$root.$i18n.locale;
                if (this.docId) {
                    data.docId = this.docId;
                } else {
                    data.docId = this.accountDocId;
                }
                this.$store
                    .dispatch("auth/setAccountSettings", data)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            this.$toast.success(this.$t("msg-save-success"));
                            return this.getAccountSettings().then(
                                (getAccountSettingsResponse) => {
                                    resolve(
                                        response,
                                        getAccountSettingsResponse
                                    );
                                }
                            );
                        }
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(this.$t("msg-save-error"));
                        this.loader().hide();
                        reject(error);
                    });
            });
        },
        clear() {
            this.$refs.form.reset();
            this.salutation = null;
            this.firstname = null;
            this.familyname = null;
        },
        onFieldChange() {
            if (this.$v.$dirty) {
                this.$v.$touch();
            } else {
                return;
            }
        },
        decryptMail(mail) {
            return atob(mail);
        }
    },
    computed: {
        ...mapGetters("auth", [
            "accountDocId",
            "userSalutation",
            "userFirstname",
            "userFamilyname",
            "mail",
            "hasSettings"
        ]),
        ...mapGetters({
            salutations: "attributes/salutations"
        }),
        salutationItems() {
            return this.salutations;
        },
        salutationErrors() {
            const errors = [];
            if (!this.$v.salutation.$dirty) {
                return errors;
            }
            if (!this.$v.salutation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        firstnameErrors() {
            const errors = [];
            if (!this.$v.firstname.$dirty) {
                return errors;
            }
            if (!this.$v.firstname.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.firstname.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.firstname.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        familynameErrors() {
            const errors = [];
            if (!this.$v.familyname.$dirty) {
                return errors;
            }
            if (!this.$v.familyname.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.familyname.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.familyname.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        formEqualsDbData() {
            return (
                this.userSalutation === this.salutation &&
                this.userFirstname === this.firstname &&
                this.userFamilyname === this.familyname
            );
        },
        isSubmitBtnDisabled() {
            return !this.isModelValid(this.$v) || this.formEqualsDbData;
        }
    },
    mounted() {
        if (this.userSalutation) {
            this.salutation = this.userSalutation;
        }
        if (this.userFirstname) {
            this.firstname = this.userFirstname;
        }
        if (this.userFamilyname) {
            this.familyname = this.userFamilyname;
        }
    },
    watch: {
        docId: {
            handler: function (newVal) {
                if (newVal) {
                    this.documentId = newVal;
                }
            },
            immediate: true
        },
        userSalutation: {
            handler: function (salutation) {
                if (salutation) {
                    this.salutation = salutation;
                }
            },
            immediate: true
        },
        userFirstname: {
            handler: function (firstname) {
                if (firstname) {
                    this.firstname = firstname;
                }
            },
            immediate: true
        },
        userFamilyname: {
            handler: function (familyname) {
                if (familyname) {
                    this.familyname = familyname;
                }
            },
            immediate: true
        }
    },
    created() {
        this.loader().show();
        let salutationsKey = "salutations";
        this.getStoredAttributesByKey(salutationsKey)
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    "Laden der Daten: " + salutationsKey + " war nicht möglich"
                );
            });
    }
};
</script>
