<template>
    <v-menu transition="slide-y-transition" bottom left>
        <template v-slot:activator="{ on, attrs }">
            <base-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="actions.length === 0 || btnDisabled"
            >
                <v-icon color="info"> mdi-dots-vertical </v-icon>
            </base-btn>
        </template>
        <v-list>
            <template v-for="(actionItem, idx) in actions">
                <v-list-item :key="idx" @click="onClickAction(actionItem)">
                    <v-list-item-icon v-if="actionItem.icon" class="mr-2">
                        <v-icon v-text="actionItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ actionItem.title }}
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ActionsMenu",
    props: {
        btnDisabled: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onClickAction(action) {
            this.$emit("click:action", action);
        }
    }
};
</script>
