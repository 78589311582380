<template>
    <base-btn
        v-if="webShareApiSupported"
        icon
        small
        v-bind="btnProps"
        @click="shareViaWebShare"
    >
        <v-icon :left="btnProps && btnProps.title"> mdi-share-variant </v-icon>
        <span v-if="btnProps && btnProps.title">{{ btnProps.title }}</span>
    </base-btn>
</template>
<script>
export default {
    name: "BtnShare",
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        btnProps: {
            type: Object,
            default: () => ({
                icon: true
            })
        }
    },
    computed: {
        webShareApiSupported() {
            return navigator.share ? true : false;
        }
    },
    methods: {
        shareViaWebShare() {
            if (this.webShareApiSupported) {
                navigator
                    .share({
                        title: this.title,
                        text: this.text,
                        url: this.url
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error) => console.log("Error sharing", error));
            }
        }
    }
};
</script>
