<i18n src="./BaseDateTime-i18n.yaml"></i18n>
<template>
    <span>
        <span v-if="!hidePrefixDate"> {{ $t("on") }} </span>
        <span :class="clsDate">
            {{ $moment.utc(timestamp).local().format(formatDate) }}
        </span>
        <span v-if="!hidePrefixTime"> {{ $t("at") }} </span>
        <span :class="clsTime">
            {{ $moment.utc(timestamp).local().format(formatTime) }}
        </span>
    </span>
</template>
<script>
export default {
    name: "BaseDateTime",

    props: {
        timestamp: {
            type: String,
            default: null,
            required: true
        },
        hidePrefixDate: {
            type: Boolean,
            default: false
        },
        hidePrefixTime: {
            type: Boolean,
            default: false
        },
        clsDate: {
            type: String,
            default: null
        },
        clsTime: {
            type: String,
            default: null
        }
    },

    computed: {
        formatDate() {
            return "DD.MM.YYYY";
        },
        formatTime() {
            return "HH:mm";
        }
    }
};
</script>
