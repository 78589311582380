import { isEmpty } from "@/utils";
export default {
    props: {
        required: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedProps() {
            return {
                ...this.$props,
                ...this.$attrs,
                filled: true
            };
        },
        showRequiredFieldLine() {
            let errorMsgs = [];
            if ('error-messages' in this.computedProps){
                errorMsgs = this.computedProps['error-messages']
            }
            if ('errorMessages' in this.computedProps){
                errorMsgs = this.computedProps['errorMessages']
            }
            if (isEmpty(this.value) && this.required && errorMsgs.length === 0) {
                return true;
            }
            return false;
        }
    }
};
