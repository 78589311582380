<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./message-card-question-i18n.yaml"></i18n>
<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12">
                <MessageCard
                    v-if="message && message.text"
                    class="my-6"
                    :message="message"
                    :accountDocId="accountDocId"
                    @edit="$emit('edit', $event)"
                    @delete="$emit('delete', $event)"
                    :showEditIcon="showEditIcon"
                    :disableEditIcon="disableEditIcon"
                    :showDeleteIcon="showDeleteIcon"
                >
                    <template v-slot:additional-actions>
                        <v-badge
                            v-if="
                                requireAttachments &&
                                requireAttachmentsCategories.length > 0
                            "
                            :content="requireAttachmentsCategories.length"
                            bordered
                            overlap
                        >
                            <base-btn
                                icon
                                @click="dialogAttachmentsInfo = true"
                            >
                                <v-icon>mdi-file-multiple</v-icon>
                            </base-btn>
                        </v-badge>
                    </template>
                </MessageCard>
            </v-col>
            <v-col cols="12" v-if="dialogAttachmentsInfo">
                <base-dialog
                    :value="dialogAttachmentsInfo"
                    @input="dialogAttachmentsInfo = $event"
                    :title="$t('title-categories-dialog')"
                    max-width="600"
                    icon="mdi-information"
                >
                    <template v-slot:content>
                        <v-container
                            v-if="requireAttachmentsCategories.length > 0"
                        >
                            <div
                                v-for="fileCategory in requireAttachmentsCategories"
                                :key="fileCategory"
                            >
                                {{ $t(`attachment-category-${fileCategory}`) }}
                            </div>
                        </v-container>
                    </template>
                </base-dialog>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "MessageCardQuestion",
    components: {
        MessageCard: () => import("./message-card.vue")
    },
    props: {
        message: {
            type: Object,
            default: () => {}
        },
        accountDocId: {
            type: String,
            default: null
        },
        showEditIcon: {
            type: Boolean,
            default: false
        },
        disableEditIcon: {
            type: Boolean,
            default: false
        },
        showDeleteIcon: {
            type: Boolean,
            default: false
        },
        disableDeleteIcon: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: null
        },
        requireAttachments: {
            type: Boolean,
            default: false
        },
        requireAttachmentsCategories: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        dialogAttachmentsInfo: false
    })
};
</script>
