<template>
    <base-dialog
        :title="title"
        icon="mdi-code-json"
        max-width="800"
        :value="value"
        @input="$emit('input', $event)"
    >
        <template v-slot:content>
            <v-container no-gutters class="pa-0 pt-2">
                <div :class="$vuetify.theme.isDark ? '' : 'yellow lighten-3'">
                    <tree-view
                        :data="registrationData"
                        :options="{ maxDepth: 1 }"
                    ></tree-view>
                </div>
            </v-container>
        </template>
    </base-dialog>
</template>
<script>
import { mapActions } from "vuex";
import _ from "lodash";
export default {
    name: "DialogViewData",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        docId: {
            type: String,
            default: null
        }
    },

    data: () => ({
        registrationData: {}
    }),
    computed: {
        title() {
            let title = "";
            if (!this.registrationData) return title;
            title += this.$t(this.registrationData.objectType) + ": ";
            title += this.registrationData.workingTitle;
            title += " (";
            if (
                typeof this.registrationData.manufacturer === "object" &&
                this.registrationData.manufacturer !== null
            ) {
                title += this.registrationData.manufacturer.name;
            } else {
                title += this.registrationData.manufacturer;
            }
            title += ", " + this.registrationData.constructionYear + ")";
            return title;
        }
    },
    methods: {
        ...mapActions("registrations", ["fetchRegistrationDataByDocId"]),
        getRegistrationData() {
            this.loader().show();
            this.fetchRegistrationDataByDocId(this.docId)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        this.registrationData = {};
                        this.registrationData = _.assignIn({}, resData);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                if (newVal && this.docId) {
                    this.getRegistrationData();
                }
            },
            immediate: true
        }
    }
};
</script>
