<i18n src="./dialog-external-link-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="600"
        icon="mdi-open-in-new"
    >
        <template v-slot:content>
            <v-container>
                <span
                    v-html="
                        $t('text', {
                            linkName: urlName,
                            link: link
                        })
                    "
                >
                </span>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn
                type="primary"
                :href="link"
                target="_blank"
                @click="closeDialog"
            >
                <v-icon class="mr-2">mdi-open-in-new</v-icon>
                {{ $t("btn-go-link", { urlName: urlName }) }}
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
export default {
    name: "DialogExternalLink",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        link: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dialog: false,
        urlName: null
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        getUrlName(url) {
            /* eslint-disable-next-line */
            // /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim;
            const regex =
                /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim;
            let m;
            while ((m = regex.exec(url)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                return m[1];
            }
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        },
        link: {
            handler: function (newLink) {
                if (newLink) {
                    this.urlName = this.getUrlName(newLink);
                }
            },
            immediate: true
        }
    }
};
</script>
