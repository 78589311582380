<i18n src="./pre-formulated-questions-i18n.yaml"></i18n>
<template>
    <v-container fluid class="pa-0">
        <v-row v-if="showAllRegistrationsItems" no-gutters>
            <v-col
                cols="auto"
                v-for="(item, idx) in allRegistrationsItems"
                :key="idx"
                class="pa-1"
            >
                <w-cascader
                    :text="item.text"
                    :items="item.values"
                    @click="$toast.info($event)"
                    @select="$emit('change', $event)"
                />
            </v-col>
        </v-row>
        <template v-if="show">
            <v-row
                align="center"
                no-gutters
                v-for="reason in reasons"
                :key="reason.value"
                class="my-6"
            >
                <v-menu>
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <base-btn
                                    color="info"
                                    text
                                    small
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                >
                                    {{
                                        $t(
                                            `step-${step}-reason-${reason.value}-label`
                                        )
                                    }}
                                </base-btn>
                            </template>
                            <span>{{ $t("copy-values") }}</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <template v-for="(langItem, idx) in languages">
                            <v-list-item
                                :key="idx"
                                v-if="
                                    messages[langItem.toLowerCase()] &&
                                    messages[langItem.toLowerCase()][
                                        `step-${step}-reason-${reason.value}-text`
                                    ]
                                "
                                @click="
                                    $emit(
                                        'change',
                                        messages[langItem.toLowerCase()][
                                            `step-${step}-reason-${reason.value}-text`
                                        ]
                                    )
                                "
                            >
                                <v-list-item-title class="text-uppercase">{{
                                    langItem
                                }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
            </v-row>
        </template>
    </v-container>
</template>
<script>
import { isEmpty } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import WCascader from "@/components/fields/w-cascader.vue";
import { REGISTRATION_LOAD_CHANGE_TYPES } from "@/data/globals.js";
import _ from "lodash";
export default {
    name: "PreFormulatedQuestions",
    components: {
        WCascader
    },
    props: {
        step: {
            type: [Number, String],
            default: 0,
            required: true
        },
        registrationData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        newLine: "\n",
        space: " ",
        listsItemsKeys: {
            salutations: "salutations",
            coverageArt: "absicherungsart",
            coverageLocation: "absicherungsort",
            materialCode: "stoffcode",
            testMedium: "pruefmedium",
            bob: "bob"
        }
    }),
    computed: {
        ...mapGetters({
            salutationItems: "attributes/salutations"
        }),
        attributesState() {
            return this.$store.state.attributes;
        },
        locale() {
            return this.$root.$i18n.locale || "de";
        },
        messages() {
            return this.$i18n.messages;
        },
        reasons() {
            const reasonsCount = 5;
            let reasons = [];
            for (let i = 0; i < reasonsCount; i++) {
                let idx = i + 1;
                let item = {
                    value: idx,
                    text:
                        this.language &&
                        this.messages[this.language] &&
                        this.messages[this.language]["reason-" + idx]
                            ? this.messages[this.language]["reason-" + idx]
                            : this.messages["de"]["reason-" + idx]
                };
                reasons.push(item);
            }
            return reasons;
        },
        languages() {
            return this.$i18n.availableLocales;
        },
        show() {
            let show = false;
            this.languages.forEach((lang) => {
                if (
                    this.messages[lang.toLowerCase()] &&
                    this.messages[lang.toLowerCase()][
                        `step-${this.step}-reason-1-text`
                    ]
                ) {
                    show = true;
                }
            });
            return show;
        },
        showAllRegistrationsItems() {
            if (
                this[`step${this.step}`] &&
                this[`step${this.step}`].length > 0
            ) {
                return true;
            }
            return false;
        },
        allRegistrationsItems() {
            return this[`step${this.step}`];
        },
        step0() {
            if (!this.registrationData) return [];
            let items = [];
            let item = {
                text: this.$t("basic-data"),
                values: this._getBasicData(this.registrationData)
            };
            items.push(item);
            return items;
        },
        step1() {
            if (!this.registrationData) return [];
            let items = [];
            let item = {
                text: this.$t("object-properties"),
                values: this._getObjectPpropertiesData(this.registrationData)
            };
            items.push(item);
            return items;
        },
        step2() {
            if (!this.registrationData) return [];
            let items = [];
            const equipmentLocation = this.registrationData.equipmentLocation;
            let address = {};
            let contactPerson = {};
            let customerSpeDesig = {};
            if (equipmentLocation) {
                address = equipmentLocation.address;
                contactPerson = equipmentLocation.contactPerson;
                customerSpeDesig =
                    equipmentLocation.customerSpecificDesignation;
            }
            if (address) {
                let itemAddress = {
                    text: this.$t("equipment-location-address"),
                    values: this._getAddressValues(address)
                };
                items.push(itemAddress);
            }
            if (contactPerson) {
                let itemContactPerson = {
                    text: this.$t("equipment-location-contact-person"),
                    values: this._getContactPersonValues(contactPerson)
                };
                items.push(itemContactPerson);
            }
            if (customerSpeDesig && customerSpeDesig.specificDesignation) {
                let itemCustomerSpeDesig = {
                    text: this.$t("equipment-location-customer-spe-desig"),
                    values: this._getCustomerSpeDesigValues(customerSpeDesig)
                };
                items.push(itemCustomerSpeDesig);
            }
            return items;
        },
        step3() {
            if (!this.registrationData) return [];
            let items = [];
            let chambers = this.registrationData.chambers || [];
            if (chambers) {
                _.forEach(chambers, (chamber) => {
                    const chamberId = chamber.id;
                    let item = {
                        text: this.$t("chamber-id", { chamberId: chamberId }),
                        values: this._getChamberValues(_.clone(chamber))
                    };
                    items.push(item);
                });
            }
            return items;
        },
        step4() {
            if (!this.registrationData) return [];
            let items = [];
            if (this.registrationData.operatorAddress) {
                let item = {
                    text: this.$t("operator-address"),
                    values: this._getAddressValues(
                        this.registrationData.operatorAddress
                    )
                };
                items.push(item);
            }
            return items;
        },
        step5() {
            if (!this.registrationData) return [];
            let items = [];
            if (this.registrationData.billingAddress) {
                let item = {};
                let address = null;
                if (
                    this.registrationData.billingAddress.sameAddressAsOperator
                ) {
                    address = this.registrationData.operatorAddress;
                } else {
                    address = this.registrationData.billingAddress;
                }
                item = {
                    text: this.$t("billing-address"),
                    values: this._getAddressValues(address)
                };
                if (item) {
                    items.push(item);
                }
            }
            return items;
        },
        step6() {
            if (!this.registrationData) return [];
            let items = [];
            if (this.registrationData.ownerAddress) {
                let item = {};
                let address = null;
                if (this.registrationData.ownerAddress.sameAddressAsOperator) {
                    address = this.registrationData.operatorAddress;
                } else {
                    address = this.registrationData.ownerAddress;
                }
                item = {
                    text: this.$t("owner-address"),
                    values: this._getAddressValues(address)
                };
                if (item) {
                    items.push(item);
                }
            }
            return items;
        },
        step8() {
            if (!this.registrationData) return [];
            let items = [];
            let item = {
                text: this.$t("inspection-data"),
                values: this._getInpectionData(this.registrationData)
            };
            items.push(item);
            return items;
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        _getAddressValues(address) {
            let values = [];
            let item = {
                text: this.$t("address-all"),
                value: this.addressToString(_.clone(address))
            };
            if (address.addressType) {
                delete address.addressType;
            }
            if (address.additionalAddressLines) {
                delete address.additionalAddressLines;
            }
            values.push(item);
            _.forEach(address, (value, idx) => {
                if (value) {
                    let item = {};
                    if (typeof value === "object") {
                        _.forEach(value, (itemValue, itemIdx) => {
                            if (!isEmpty(itemValue)) {
                                item = {
                                    text: itemIdx,
                                    value: itemValue
                                };
                            }
                        });
                    } else if (idx === "salutation") {
                        item = {
                            text: this.$t(idx),
                            value: this.getSalutationsTranslatedByKeyOrNull(
                                value
                            )
                        };
                    } else {
                        item = {
                            text: this.$t(idx),
                            value: value
                        };
                    }
                    if (!isEmpty(item)) {
                        values.push(item);
                    }
                }
            });
            return values;
        },
        getSalutationsTranslatedByKeyOrNull(key) {
            const salutationTransItem = this.salutationItems.filter(
                (sal) => sal.key == key
            );
            if (salutationTransItem !== -1) {
                return salutationTransItem[0]?.value?.title[this.$i18n.locale];
            }
            return null;
        },
        addressToString(address) {
            let ret = "";
            if (address.companyName) {
                ret += address.companyName;
            }
            if (address.position) {
                ret += this.newLine;
                ret += address.position;
            }
            const salutation = this.getSalutationsTranslatedByKeyOrNull(
                address.salutation
            );
            if (salutation) {
                ret += salutation;
            }
            if (address.firstName) {
                if (salutation) ret += this.space;
                ret += address.firstName;
            }
            if (address.familyName) {
                if (address.firstName) ret += this.space;
                ret += address.familyName;
            }
            if (address.street && address.houseNr) {
                ret += this.newLine;
                ret += address.street;
                ret += this.space;
                ret += address.houseNr;
            }
            if (address.country || (address.zipCode && address.city)) {
                ret += this.newLine;
                ret += address.country;
                ret += "-";
                ret += address.city;
                ret += this.space;
                ret += address.zipCode;
            }
            if (address.canton) {
                ret += this.space;
                ret += "(" + address.canton + ")";
            }

            return ret;
        },
        _getChamberValues(chamber) {
            let values = [];
            let item = {
                text: this.$t("chamber-all"),
                value: this.chamberToString(_.clone(chamber))
            };
            values.push(item);
            const chamberId = chamber.id;
            if (chamber.id) {
                delete chamber.id;
            }
            if (chamber.coverageNotAvailable) {
                delete chamber.coverageNotAvailable;
            }
            const itemsToFetch = [
                "coverageArt",
                "coverageLocation",
                "materialCode",
                "testMedium"
            ];
            _.forEach(chamber, (value, idx) => {
                if (itemsToFetch.includes(idx)) {
                    const itemsName = this.listsItemsKeys[idx] + chamberId;
                    let items = this.attributesState[itemsName];
                    this._getChamberItemsByIndex(chamberId).then(() => {
                        let itemValues = [];
                        _.forEach(this.languages, (lang) => {
                            let listItemIdx = _.findIndex(items, {
                                key: value
                            });
                            if (listItemIdx !== -1) {
                                let _item = {
                                    text: lang.toUpperCase(),
                                    value: items[listItemIdx].value[lang]
                                };
                                itemValues.push(_item);
                            }
                        });
                        if (itemValues.length > 0) {
                            let item = {
                                text: this.$t(idx),
                                value: idx,
                                children: itemValues
                            };
                            values.push(item);
                        }
                    });
                } else {
                    if (value) {
                        let item = {};
                        if (typeof value === "object") {
                            _.forEach(value, (itemValue, itemIdx) => {
                                if (!isEmpty(itemValue)) {
                                    item = {
                                        text: this.$t(itemIdx),
                                        value: itemValue
                                    };
                                }
                            });
                        } else {
                            item = {
                                text: this.$t(idx),
                                value: value
                            };
                        }
                        if (!isEmpty(item)) {
                            values.push(item);
                        }
                    }
                }
            });
            return values;
        },
        chamberToString(chamber) {
            let ret = "";
            if (chamber.descripton) {
                ret += chamber.descripton;
            }
            ret += this.newLine;
            return ret;
        },
        _getBasicData(registration) {
            let values = [];
            let workingTitle = {
                text: this.$t("workingTitle"),
                value: registration.workingTitle
            };
            values.push(workingTitle);
            if (registration.manufacturer) {
                let manufacturer = {};
                if (
                    registration.manufacturer.name &&
                    registration.manufacturer.department
                ) {
                    manufacturer = {
                        text: this.$t("manufacturer"),
                        value:
                            registration.manufacturer.name +
                            this.space +
                            "(" +
                            registration.manufacturer.department +
                            ")"
                    };
                } else if (
                    registration.manufacturer.name &&
                    !registration.manufacturer.department
                ) {
                    manufacturer = {
                        text: this.$t("manufacturer"),
                        value: registration.manufacturer.name
                    };
                }
                if (manufacturer) {
                    values.push(manufacturer);
                }
            }
            if (registration.factoryNumber) {
                let factoryNumber = {
                    text: this.$t("factoryNumber"),
                    value: registration.factoryNumber
                };
                values.push(factoryNumber);
            }
            if (registration.inOperationYear) {
                let inOperationYear = {
                    text: this.$t("inOperationYear"),
                    value: registration.inOperationYear
                };
                values.push(inOperationYear);
            }
            if (registration.constructionYear) {
                let constructionYear = {
                    text: this.$t("constructionYear"),
                    value: registration.constructionYear
                };
                values.push(constructionYear);
            }
            if (registration.intendedUse) {
                let intendedUse = {
                    text: this.$t("intendedUse"),
                    value: registration.intendedUse
                };
                values.push(intendedUse);
            }
            return values;
        },
        _getObjectPpropertiesData(registration) {
            let values = [];
            if (registration.loadChangeAssessmentNecessary) {
                if (registration.loadChange) {
                    let maxAllowedQuantityValues = [];
                    if (
                        registration.loadChange ===
                        REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
                    ) {
                        if (registration.loadChangeMax) {
                            let loadChangeMax = {
                                text: this.$t("loadChangeMax"),
                                value: registration.loadChangeMax
                            };
                            maxAllowedQuantityValues.push(loadChangeMax);
                        }
                        if (registration.loadChangeYear) {
                            let loadChangeYear = {
                                text: this.$t("loadChangeYear"),
                                value: registration.loadChangeYear
                            };
                            maxAllowedQuantityValues.push(loadChangeYear);
                        }
                        if (maxAllowedQuantityValues.length > 0) {
                            let maxAllowedQuantity = {
                                text: this.$t("max-allowed-quantity"),
                                value: REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY,
                                children: maxAllowedQuantityValues
                            };
                            values.push(maxAllowedQuantity);
                        }
                    } else {
                        let loadChangeDuration = {
                            text: this.$t("loadChangeDuration"),
                            value: registration.loadChangeDuration
                        };
                        values.push(loadChangeDuration);
                    }
                }
            }
            if (registration.bob) {
                let bobLangItems = [];
                const myPromise = new Promise((resolve) => {
                    let promises = [];
                    _.forEach(this.languages, (lang) => {
                        const key = this.listsItemsKeys["bob"];
                        promises.push(
                            this._getAttributeValue(key, registration.bob, lang)
                        );
                    });

                    resolve(Promise.all(promises));
                });
                myPromise
                    .then((items) => {
                        _.forEach(items, (item) => {
                            const itemLang = item.lang;
                            const itemValue = item.value;
                            if (item) {
                                let bob = {
                                    text: itemLang.toUpperCase(),
                                    value: itemValue
                                };
                                bobLangItems.push(bob);
                            }
                        });
                        if (bobLangItems.length > 0) {
                            let bob = {
                                text: this.$t("bob"),
                                value: "bob",
                                children: bobLangItems
                            };
                            values.push(bob);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            return values;
        },
        _getInpectionData(registration) {
            let values = [];
            if (registration.specialistCompany) {
                if (registration.specialistCompany.name) {
                    let specialistCompany = {
                        text: this.$t("specialistCompany"),
                        value: registration.specialistCompany.name
                    };
                    values.push(specialistCompany);
                }
            }
            return values;
        },
        _getContactPersonValues(contactPerson) {
            let values = [];
            if (contactPerson.salutation) {
                const itemsName = this.listsItemsKeys["salutations"];
                let items = this.attributesState[itemsName];
                let salutationItemIdx = _.findIndex(items, {
                    key: contactPerson.salutation
                });
                _.forEach(this.languages, (lang) => {
                    let salutationTrans = null;
                    if (salutationItemIdx !== -1) {
                        salutationTrans =
                            items[salutationItemIdx].value.title[lang];
                    }
                    if (contactPerson.name && salutationTrans) {
                        let _item = {
                            text: lang.toUpperCase(),
                            value:
                                salutationTrans +
                                this.space +
                                contactPerson.name
                        };
                        values.push(_item);
                    }
                });
            }

            return values;
        },
        _getCustomerSpeDesigValues(customerSpeDesig) {
            let values = [];
            if (customerSpeDesig.constructionNumber) {
                let constructionNumber = {
                    text: this.$t("construction-number"),
                    value: customerSpeDesig.constructionNumber
                };
                values.push(constructionNumber);
            }
            if (customerSpeDesig.equipmentDescription) {
                let equipmentDescription = {
                    text: this.$t("equipment-description"),
                    value: customerSpeDesig.equipmentDescription
                };
                values.push(equipmentDescription);
            }
            if (customerSpeDesig.inventoryNumber) {
                let inventoryNumber = {
                    text: this.$t("inventory-number"),
                    value: customerSpeDesig.inventoryNumber
                };
                values.push(inventoryNumber);
            }
            if (customerSpeDesig.posLocation) {
                let posLocation = {
                    text: this.$t("pos-location"),
                    value: customerSpeDesig.posLocation
                };
                values.push(posLocation);
            }
            return values;
        },
        _getSalutationsAttributes() {
            this.loader().show();
            let salutationsKey = "salutations";
            this.getStoredAttributesByKey(salutationsKey)
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        "Laden der Daten: " +
                            salutationsKey +
                            " war nicht möglich"
                    );
                });
        },
        _getAttributesByKey(key) {
            return new Promise((resolve, reject) => {
                this.loader().show();
                this.getStoredAttributesByKey(key)
                    .then((response) => {
                        this.loader().hide();
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader().hide();
                        this.$toast.error(
                            "Laden der Daten: " + key + " war nicht möglich"
                        );
                        reject(error);
                    });
            });
        },
        _getAttributeValue(attributeKey, attributeValue, lang) {
            return new Promise((resolve, reject) => {
                this.getStoredAttributesByKey(attributeKey)
                    .then(() => {
                        let items = this.attributesState[attributeKey];
                        let listItemIdx = _.findIndex(items, {
                            key: attributeValue
                        });
                        this.loader().hide();
                        if (listItemIdx !== -1) {
                            resolve({
                                lang: lang,
                                value: items[listItemIdx].value[lang]
                            });
                        }
                        reject(null);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader().hide();
                        this.$toast.error(
                            "Laden der Daten: " +
                                attributeKey +
                                " war nicht möglich"
                        );
                        reject(error);
                    });
            });
        },
        _getChamberItemsByIndex(index) {
            let axiosPromises = [];
            let coverageArtKey = "absicherungsart" + index;
            if (!this.attributesState[coverageArtKey]) {
                let coverageArtPromise =
                    this.getStoredAttributesByKey(coverageArtKey);
                axiosPromises.push(coverageArtPromise);
            }
            let coverageLocationKey = "absicherungsort" + index;
            if (!this.attributesState[coverageLocationKey]) {
                let coverageLocationPromise =
                    this.getStoredAttributesByKey(coverageLocationKey);
                axiosPromises.push(coverageLocationPromise);
            }
            let materialCodeKey = "stoffcode" + index;
            if (!this.attributesState[materialCodeKey]) {
                let materialCodePromise =
                    this.getStoredAttributesByKey(materialCodeKey);
                axiosPromises.push(materialCodePromise);
            }
            let testMediumKey = "pruefmedium" + index;
            if (!this.attributesState[testMediumKey]) {
                let testMediumPromise =
                    this.getStoredAttributesByKey(testMediumKey);
                axiosPromises.push(testMediumPromise);
            }
            this.loader().show();
            return new Promise((resolve, reject) => {
                Promise.all(axiosPromises)
                    .then(() => {
                        this.loader().hide();
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader().hide();
                        reject(false);
                    });
            });
        }
    },
    created() {
        this._getSalutationsAttributes();
    }
};
</script>
