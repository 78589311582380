<template>
    <div>
        <v-sheet class="pa-1" v-if="isLoading">
            <v-skeleton-loader
                class="mx-auto"
                width="100%"
                height="100%"
                type="list-item-avatar, image"
            ></v-skeleton-loader>
        </v-sheet>
        <app-card-chart
            v-if="data && !isLoading"
            v-bind="$attrs"
            :title="title"
            :subtitle="subtitle"
            :icon="icon"
            :chartOptions="computeChartOption"
            :showBtnDownload="!hideDownloadBtn"
            :showBtnReload="!hideReloadBtn"
            @click:reload="$emit('click:reload')"
        >
            <!-- Dynamically inherit slots from parent -->
            <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
                <slot :name="name" v-bind="item"></slot>
            </template>
            <!-- make all slots from component reusable from parent -->
            <template v-for="(_, slot) in $slots">
                <template :slot="slot">
                    <slot :name="slot"></slot>
                </template>
            </template>
        </app-card-chart>
    </div>
</template>
<script>
export default {
    name: "PieChartWidget",
    props: {
        title: String,
        subtitle: String,
        icon: String,
        cardColor: {
            type: String,
            default: "white"
        },
        iconColor: {
            type: String,
            default: "success"
        },
        chartColor: {
            tpye: Array,
            default: () => []
        },
        chartHeight: {
            tpye: String,
            default: "308px"
        },
        chartWidth: {
            tpye: String,
            default: "100%"
        },
        data: {
            tpye: Array,
            default: () => []
        },
        options: {
            tpye: Array,
            default: () => []
        },
        isLoading: {
            tpye: Boolean,
            default: true
        },
        hideReloadBtn: {
            type: Boolean,
            default: false
        },
        hideDownloadBtn: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computeCardDark() {
            return this.cardColor !== "white";
        },
        defaultOption() {
            return [
                ["dataset.source", this.data],
                ["color", this.chartColor],
                [("legend.orient", "horizontal")],
                ["legend.y", "bottom"],
                ["xAxis.show", false],
                ["yAxis.show", false],
                ["series[0].type", "pie"],
                ["series[0].avoidLabelOverlap", true],
                ["series[0].radius", ["50%", "70%"]]
            ];
        },
        computeChartOption() {
            return [...this.defaultOption, ...this.options];
        }
    }
};
</script>
