<template>
  <SectionBase id="devel-" title="">

  </SectionBase>
</template>

<script>
export default {
  name: "Section",
  components: { SectionBase: () => import("./base.vue") },
  data: () => ({}),
  methods: {},
};
</script>
