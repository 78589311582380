<i18n       src="./dialog-address-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="close"
        :title="defaultTitle"
        width="700"
        icon="mdi-office-building"
    >
        <template v-slot:content class="mt-4">
            <v-container>
                <WCountrySelect
                    ref="country"
                    :preferred-countries="['CH', 'LI', 'DE', 'FR', 'IT', 'AT']"
                    @country-changed="reset()"
                    @input="country = $event"
                    :value="country"
                    :error-messages="countryErrors"
                ></WCountrySelect>
                <form-company-address
                    ref="formCompanyAddress"
                    :requiredFields="requiredFields"
                    hasPoBox
                    :country="country"
                    :address="address"
                    @input="$emit('address:input', $event)"
                    @reset="$emit('address:reset', $event)"
                >
                </form-company-address>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn type="primary" @click="save">
                <v-icon>mdi-save</v-icon>
                {{ $t("btn-save") }}
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
import { requiredIf } from "vuelidate/lib/validators";
import WCountrySelect from "@/components/fields/WCountrySelect.vue";
import FormCompanyAddress from "@/components/forms/company-address.vue";
import validateMixin from "@/mixins/validate";
export default {
    name: "DialogAddress",
    components: {
        WCountrySelect,
        FormCompanyAddress
    },
    mixins: [validateMixin],
    validations() {
        return {
            country: {
                required: requiredIf(function () {
                    if (this.requiredFields.includes("country")) {
                        return true;
                    } else {
                        return false;
                    }
                })
            }
        };
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        requiredFields: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        },
        address: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        dialog: false,
        country: "CH"
    }),
    methods: {
        close(value) {
            this.dialog = value;
            this.$emit("input", value);
        },
        reset() {
            this.$nextTick(() => {
                this.$refs.formCompanyAddress.reset();
            });
        },
        validate() {
            this.$v.$touch();
            this.$refs.formCompanyAddress.validate();
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                this.$refs.formCompanyAddress.scrollToFirstError();
            });
        },
        isValid() {
            if (!this.$v.$anyDirty) {
                return false;
            } else if (!this.$refs.formCompanyAddress.isValid()) {
                return false;
            } else {
                if (this.$v.$anyError) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        save() {
            this.validate();
            if (!this.isValid()) {
                return;
            }
            let manufacturer = this.getData();
            this.$emit("save", manufacturer);
        },
        getData() {
            let ret = {};
            let form = this.address;
            ret.companyName = form.companyName;
            ret.department = form.department;
            ret.additionalAddressLines = form.additionalAddressLines;
            ret.addressLine1 = form.addressLine1;
            ret.addressLine2 = form.addressLine2;
            ret.street = form.street;
            ret.houseNr = form.houseNr;
            ret.zipCode = form.zipCode;
            ret.city = form.city;
            ret.canton = form.canton;
            ret.country = this.country;
            ret.poBox = form.poBox;
            ret.poBoxNr = form.poBoxNr;
            ret.poBoxZipCode = form.poBoxZipCode;
            ret.poBoxCity = form.poBoxCity;
            return ret;
        },
        setData(address) {
            if (address) {
                this.address = Object.assign(this.address, address);
                if (address.country) {
                    this.country = address.country;
                }
            }
        }
    },
    computed: {
        countryErrors() {
            const errors = [];
            if (!this.$v.country.$dirty) {
                return errors;
            }
            if (!this.$v.country.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        defaultTitle() {
            if (this.title) {
                return this.title;
            }
            return this.$t("title");
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        },
        address: {
            handler: function (newValue) {
                if (newValue) {
                    this.setData(newValue);
                }
            },
            immediate: true
        }
    }
};
</script>
