<i18n src="./edit-content-i18n.yaml"></i18n>
<template>
    <div>
        <v-container
            v-if="(!registration || !registration.docId) && isLoading"
            fluid
            :class="isLoading ? 'pl⁻3' : 'pl-0 ml-0'"
        >
            <v-skeleton-loader class="mx-auto pl-7" tile type="chip">
            </v-skeleton-loader>
            <v-layout row wrap>
                <v-flex sm6>
                    <div class="pl-10 py-4 text-h3">
                        {{ $t("view-registrant-title") }}
                    </div>
                    <v-skeleton-loader
                        class="mx-auto pb-10 px-10"
                        type="list-item"
                    >
                    </v-skeleton-loader>
                    <v-sheet
                        class="px-3 pt-3 pb-10 px-10"
                        v-for="n in 9"
                        :key="n"
                    >
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-flex>
                <v-flex sm6>
                    <div class="pl-4 py-4 text-h3">
                        {{ $t("view-backoffice-title") }}
                    </div>
                    <v-skeleton-loader
                        class="mx-auto pb-10 px-10"
                        type="list-item"
                    >
                    </v-skeleton-loader>
                    <v-sheet
                        class="px-3 pt-3 pb-10 px-10"
                        v-for="n in 9"
                        :key="n"
                    >
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container if="registration" fluid fill-height pa-0 ma-0>
            <v-row>
                <v-col cols="12">
                    <div>{{ $t("view-title") }}</div>
                    <v-btn-toggle v-model="toggleView" mandatory>
                        <v-btn
                            value="both"
                            v-if="!$vuetify.breakpoint.mdAndDown"
                            :disabled="$vuetify.breakpoint.mdAndDown"
                        >
                            <v-icon>mdi-compare</v-icon>
                        </v-btn>
                        <v-btn value="onlyRegistratn">
                            <v-icon>mdi-account</v-icon>
                        </v-btn>
                        <v-btn value="onlyBackoffice">
                            <v-icon>mdi-desktop-tower-monitor</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-show="
                        toggleView == 'both' || toggleView == 'onlyRegistratn'
                    "
                    :cols="toggleView == 'both' ? '6' : '12'"
                >
                    <div class="text-h3">
                        <v-icon left large>mdi-account</v-icon
                        >{{ $t("view-registrant-title") }}
                    </div>
                    <StepperPressureEquipmentRegistrant
                        v-if="registration.docId"
                        ref="stepperPressureEquipment"
                        :class="$vuetify.breakpoint.mobile ? 'mx-0' : ''"
                        hide-step-buttons
                        :stepSync="step"
                        :expandBasicDataCard="expandBasicDataCard"
                        @change:step="step = $event"
                        @change:expand-basic-data-card="
                            expandBasicDataCard = !expandBasicDataCard
                        "
                    />
                </v-col>
                <v-col
                    v-show="
                        toggleView == 'both' || toggleView == 'onlyBackoffice'
                    "
                    :cols="toggleView == 'both' ? '6' : '12'"
                >
                    <div class="text-h3">
                        <v-icon left large>mdi-desktop-tower-monitor</v-icon
                        >{{ $t("view-backoffice-title") }}
                    </div>
                    <StepperPressureEquipmentBackoffice
                        v-if="registration.docId"
                        ref="stepperPressureEquipment"
                        :class="$vuetify.breakpoint.mobile ? 'mx-0' : ''"
                        :stepSync="step"
                        :expandBasicDataCard="expandBasicDataCard"
                        @change:step="step = $event"
                        @change:expand-basic-data-card="
                            expandBasicDataCard = !expandBasicDataCard
                        "
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import _ from "lodash";
import fluidContentWrapper from "@/mixins/fluidContentWrapper";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
import { TaskStatus } from "@/data/globals";
export default {
    name: "BackofficeTaskRegistrationContent",
    mixins: [fluidContentWrapper],
    components: {
        StepperPressureEquipmentRegistrant: () =>
            import(
                "@/components/tasks/edit/registrant/steppers/pressure-equipment/stepper.vue"
            ),
        StepperPressureEquipmentBackoffice: () =>
            import(
                "@/components/tasks/edit/backoffice/steppers/pressure-equipment/stepper.vue"
            )
    },

    props: {
        referenceId: {
            type: String,
            default: null
        }
    },

    data: () => ({
        step: 1,
        isEditing: true,
        toggleView: 2,
        expandBasicDataCard: true
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapGetters({
            registrationTimestampStatus: "settings/registrationTimestampStatus",
            registrationTimestamp: "settings/registrationTimestamp"
        }),
        ...mapGetters({
            registration: "backofficeRegistration/registration"
        }),
        ...mapState({
            readonlyModus: "backofficeRegistration/readonlyModus"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        workingTitle() {
            if (!this.registration || !this.registration.workingTitle)
                return null;
            return this.registration.workingTitle;
        },
        manufacturer() {
            if (!this.registration || !this.registration.manufacturer)
                return null;
            return this.registration.manufacturer;
        },
        factoryNumber() {
            if (!this.registration || !this.registration.factoryNumber)
                return null;
            return this.registration.factoryNumber;
        },
        constructionYear() {
            if (!this.registration || !this.registration.constructionYear)
                return null;
            return this.registration.constructionYear;
        },
        isSaving() {
            return (
                this.referenceId &&
                this.registrationTimestampStatus === "loading"
            );
        }
    },
    methods: {
        ...mapMutations("backofficeRegistration", [
            MUTATION_TYPES.CLEAR_REGISTRATION
        ]),
        ...mapActions("backofficeRegistration", [
            ACTION_TYPES.FETCH_REGISTRATION_AND_DIALOGS_DATA
        ]),
        ...mapMutations("registration", [
            MUTATION_TYPES.SET_REGISTRATION
        ]),
        getData() {
            if (!this.referenceId) {
                return;
            }
            this.loader().show();
            let data = {};
            data.referenceId = this.referenceId;
            this[ACTION_TYPES.FETCH_REGISTRATION_AND_DIALOGS_DATA](data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        const registrationClient = resData.clientRegistration;
                        this[MUTATION_TYPES.SET_REGISTRATION](registrationClient);
                        this.setTitles();
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus = error.response.status;
                    if (resStatus === 404) {
                        this.$router.push("/404");
                    } else if (resStatus === 403) {
                        this.$router.push("/403");
                    } else {
                        this.$router.push("/500");
                    }
                    this.loader().hide();
                });
        },
        setTitles() {
            if (!this.registration) return;
            if (
                !this.workingTitle ||
                !this.manufacturer ||
                !this.factoryNumber ||
                !this.constructionYear
            ) {
                this.$store.commit("settings/setDefaultTitles");
                return;
            }
            let data = {};
            data.title01 = this.getTitle01();
            data.title02 = this.getTitle02();
            data.registrationTimestamp = this.registration.lastChangeTimestamp;
            this.$store.commit("settings/setTitles", data);
        },
        getTitle01() {
            let title = "";
            if (this.workingTitle) {
                title += this.workingTitle;
            }
            return title;
        },
        getTitle02() {
            let title = "";
            if (this.registration) {
                if (_.isObject(this.manufacturer)) {
                    title += this.manufacturer.name + "<br>";
                } else {
                    title += this.manufacturer + "<br>";
                }
                title +=
                    this.$t("serial-number-short") +
                    ":" +
                    this.factoryNumber +
                    ", " +
                    this.$t("construction-year-short") +
                    ":" +
                    this.constructionYear;
            }
            return title;
        },
        reset() {
            //reset to defautl values
            this[MUTATION_TYPES.CLEAR_REGISTRATION]();
        },
        preventNav(event) {
            if (!this.isSaving) return;
            event.preventDefault();
            event.returnValue = "";
        }
    },

    created() {
        if (this.referenceId) {
            this.getData();
        } else {
            if (this.isAuth) {
                this.$router.push({ name: "backoffice-home" });
            } else {
                this.$router.push("/");
            }
        }
    },
    destroyed() {
        this.$store.commit("settings/setDefaultTitles");
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    beforeRouteLeave(to, from, next) {
        if (!this.registration?.state || this.readonlyModus) {
            this.reset();
            next();
            return;
        } else if (this.isSaving) {
            this.$toast.warning(this.$t("task-is-saving-warning"));
        } else if (this.registration.state >= TaskStatus.TASK_CONFIRMED) {
            next();
            return;
        } else if (this.referenceId) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-leave-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-leave-text", {
                    time: this.$moment
                        .utc(this.registrationTimestamp)
                        .local()
                        .from(this.$moment())
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.reset();
                    next();
                } else {
                    next(false);
                }
            });
        } else {
            next();
        }
    },
    watch: {
        workingTitle: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        manufacturer: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        factoryNumber: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        constructionYear: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        "$vuetify.breakpoint.mdAndDown": {
            handler: function (breakpoint) {
                if (breakpoint) {
                    this.toggleView = "onlyBackoffice";
                }
            },
            immediate: true
        },
        "$vuetify.breakpoint.lgAndUp": {
            handler: function (breakpoint) {
                if (breakpoint) {
                    this.toggleView = "both";
                }
            },
            immediate: true
        }
    }
};
</script>
