<i18n src="./dialog-report-new-address-i18n.yaml"></i18n>
<template>
    <base-dialog
        :title="$t('title')"
        icon="mdi-email-send"
        :value="dialog"
        v-on="$listeners"
        max-width="500"
    >
        <template v-slot:content>
            <v-container>
                <slot name="content-prepend" />
                <template v-if="isAddressRecorded(address)">
                    <DisplayAddress :address="address" />
                    <base-textarea
                        class="pt-2"
                        v-model="message"
                        name="message"
                        :error-messages="messageErrors"
                        :label="$t('message-label')"
                        clearable
                        :counter="$v.message.$params.maxLength.max"
                        :required="isRequiredField($v.message)"
                    ></base-textarea>
                </template>
                <slot name="content-append" />
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn
                :disabled="!isAddressRecorded(address)"
                @click="onSend"
                type="primary"
                btn-icon="mdi-email-send"
                :loading="isLoading"
            >
                {{ $t("btn-send") }}
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
import { maxLength } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
import { mapState } from "vuex";
import Helpers from "@/utils/helpers";

export default {
    name: "DialogReportNewAddress",
    mixins: [validateMixin],
    components: {
        DisplayAddress: () => import("@/components/general/display-address.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        address: {
            type: Object,
            default: () => {}
        },
        referenceId: {
            type: String,
            default: null
        }
    },
    validations() {
        return {
            message: { maxLength: maxLength(500) }
        };
    },
    data: () => ({
        dialog: false,
        message: null
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        messageErrors() {
            const errors = [];
            if (!this.$v.message.$dirty) {
                return errors;
            }
            if (!this.$v.message.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    methods: {
        onSend() {
            let data = {
                address: this.address,
                message: this.message,
                referenceId: this.referenceId
            };
            this.loader().show();
            this.$axiosBackoffice
                .post("/reportNewAddress", data)
                .then(() => {
                    this.$toast.success(this.$t("msg-send-success"));
                    this.$emit("complete");
                    this.onReset();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-send-error"));
                    this.loader().hide();
                });
        },
        onClose() {
            this.onReset();
            this.$emit("input", false);
        },
        onReset() {
            this.$emit("reset");
            this.message = null;
            this.reset();
        },
        isAddressRecorded(address) {
            return Helpers.isAddressRecorded(address);
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                this.dialog = newVal;
            },
            immediate: true
        }
    }
};
</script>
