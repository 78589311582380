<i18n src="./dialog-verify-token-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="500"
        icon="mdi-shield-key"
    >
        <template v-slot:content>
            <v-container class="pa-4" v-if="validationImages.length > 0">
                <span v-html="$t('mail-images-check', { mail: mail })"></span>
                <v-container>
                    <v-row>
                        <v-col
                            cols="4"
                            v-for="photo in validationImages"
                            :key="photo.fileName"
                        >
                            <v-card flat tile>
                                <v-card-text class="pa-0 ma-0">
                                    <v-img
                                        :src="
                                            'data:image/jpeg;base64,' +
                                            photo.data
                                        "
                                        :lazy-src="
                                            'data:image/jpeg;base64,' +
                                            photo.data
                                        "
                                        class="grey lighten-2"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-col>
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-img>
                                    <v-row align="center" justify="center">
                                        <v-col>
                                            <span
                                                v-text="
                                                    photo.title[$i18n.locale]
                                                "
                                            ></span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="mb-2">
                    <base-title>{{ $t("next-steps") }}</base-title>
                    <v-divider></v-divider>
                    <base-list-ordered
                        :items="listOrderedItems"
                    ></base-list-ordered>
                    <span class="text-body-2">{{ $t("status-text") }}</span>
                </div>
                <div class="pt-2">
                    <v-progress-linear
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                </div>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
        </template>
    </base-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "DialogVerifyToken",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        queryParams: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        dialog: false,
        validationImages: [],
        mail: null,
        token: null,
        isLoading: false
    }),

    computed: {
        ...mapGetters("settings", {
            supportMail: "supportMail"
        }),
        computedPath() {
            if (!this.queryParams || !this.queryParams.redirect) {
                return { name: "user-dashboard" };
            }
            const path = this.queryParams.redirect;
            delete this.queryParams.redirect;
            return { path: path, query: this.queryParams };
        },
        listOrderedItems() {
            return [
                this.$t("proceed-further-01"),
                this.$t("proceed-further-02"),
                this.$t("proceed-further-03", { mail: this.supportMail })
            ];
        }
    },

    methods: {
        closeDialog() {
            this.dialog = false;
            clearInterval(this.intervalCheckToken);
            this.$emit("input", this.dialog);
        },
        checkIfTokenIsValid() {
            if (
                !this.$cookies.isKey("authtoken") ||
                !this.$cookies.isKey("mail")
            ) {
                clearInterval(this.intervalCheckToken);
                this.isLoading = false;
                this.$router.go();
                return;
            }
            this.isLoading = true;
            let data = {};
            this.token = this.$cookies.get("authtoken");
            this.mail = atob(this.$cookies.get("mail"));
            data.token = this.token;
            data.mail = btoa(this.mail);
            this.$store
                .dispatch("auth/isTokenEnabled", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$store
                            .dispatch("auth/isTokenStillValid", data)
                            .then((response) => {
                                let status = response.status;
                                if (status === 200) {
                                    this.$router.push(this.computedPath);
                                    this.$emit("verification-change", true);
                                    clearInterval(this.intervalCheckToken);
                                }
                                this.isLoading = false;
                                return true;
                            })
                            .catch((error) => {
                                console.log(error);
                                this.$emit("verification-change", false);
                                this.isLoading = false;
                                return false;
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    clearInterval(this.intervalCheckToken);
                    this.$store.commit("auth/clearAuthData");
                    this.$router.push({ path: "token-invalid" });
                });
        },
        getTokenInfos() {
            let token = this.$cookies.get("authtoken");
            let mail = this.$cookies.get("mail");
            this.loader().show();
            this.$axiosAuth
                .get("/getTokenInfo", { params: { mail: mail, token: token } })
                .then((response) => {
                    let status = response.status;
                    let resData = response.data;
                    if (status === 200) {
                        this.validationImages = resData.images;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        }
    },

    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
                if (newValue) {
                    this.getTokenInfos();
                    this.intervalCheckToken = setInterval(() => {
                        this.isValid = this.checkIfTokenIsValid();
                    }, 1000);
                }
            },
            immediate: true
        }
    }
};
</script>
