import { BaseAddress } from './BaseAddress';
import { Communication } from './Communication';
import { AdditionalAddressLines } from './AdditionalAddressLines';
import { aggregation } from '@/utils/aggregation';

export class Address extends aggregation(BaseAddress, AdditionalAddressLines) {
    constructor() {
        super();
        this.addressType = null;
        this.communication = new Communication();
        this.sameAddressAsOperator = false;
        this.sameAddressAsBilling = false;
        this.sameAddressAsOwner = false;
    }
}
