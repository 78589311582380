<template>
    <app-center-container-card>
        <template v-slot:content>
            <v-row dense>
                <v-col cols="12" align="center" justify="center">
                    <slot name="image">
                        <v-img
                            v-if="imgSrc"
                            :src="imgSrc"
                            max-height="250"
                            max-width="250"
                            contain
                        ></v-img>
                        <w-icon
                            v-if="wIconSrc"
                            :data="wIconSrc"
                            width="300"
                            height="300"
                        ></w-icon>
                    </slot>
                </v-col>
                <v-col cols="12" align="center" justify="center">
                    <div class="text-center">
                        <slot name="text-container-center">
                            <div
                                class="py-2 cls-title-msg"
                                :class="
                                    $vuetify.breakpoint.mobile
                                        ? 'text-h2'
                                        : 'text-h1'
                                "
                            >
                                <slot name="title">
                                    {{ title }}
                                </slot>
                            </div>
                            <div class="py-2 text-h5">
                                <slot name="text">
                                    {{ text }}
                                </slot>
                            </div>
                        </slot>
                    </div>
                </v-col>
            </v-row>
        </template>
    </app-center-container-card>
</template>
<script>
export default {
    name: "UtilityPageContainer",
    props: {
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        imgSrc: {
            type: Object,
            default: () => {}
        },
        wIconSrc: {
            type: Object,
            default: () => {}
        }
    }
};
</script>

<style scoped>
.cls-title-msg {
    font-size: 150px;
    font-weight: 600;
}
</style>
