<i18n src="./file-uploader-i18n.yaml"></i18n>
<template>
    <span v-if="fileState">
        <span v-if="firstName">{{ firstName }}</span>
        <span v-if="familyName" :class="firstName ? 'pl-1' : undefined">{{
            familyName
        }}</span>
        <span
            :class="firstName || familyName ? 'pl-1' : undefined"
            v-if="
                fileState === TaskStatus.TASK_REGISTRANT_IN_PROCESS ||
                fileState === TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
            "
        >
            ({{ $t("registrant") }})
        </span>
        <span v-else>({{ $t("backoffice") }})</span>
    </span>
</template>
<script>
import { TaskStatus } from "@/data/globals";
export default {
    name: "FileUploader",
    props: {
        fileState: {
            type: [String, Number],
            default: null,
            required: true
        },
        firstName: {
            type: String,
            default: null
        },
        familyName: {
            type: String,
            default: null
        }
    },
    data: () => ({
        TaskStatus: TaskStatus
    })
};
</script>
