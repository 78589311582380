var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',_vm._b({staticClass:"w-toolbar primary--text",attrs:{"color":"backgroundInner","dark":"","app":"","clipped-left":"","elevation":1,"height":"70","extension-height":"20"},scopedSlots:_vm._u([(_vm.showExtension)?{key:"extension",fn:function(){return [_c('v-container',{staticClass:"ml-7",attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-caption ml-1",class:_vm.getTimestampSatusColor(
                                    _vm.registrationTimestampStatus
                                ) + '--text lighten-5'},on),[(
                                    !_vm.registrationTimestampStatus &&
                                    _vm.registrationTimestamp
                                )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("backup")))]),(_vm.registrationTimestampUpdated)?_c('span',[_vm._v(" "+_vm._s(_vm.registrationTimestampUpdated))]):_c('span',[_vm._v(" "+_vm._s(_vm.$moment .utc(_vm.registrationTimestamp) .local() .from(_vm.$moment())))])]):(
                                    _vm.registrationTimestampStatus &&
                                    _vm.registrationTimestampStatus ===
                                        'loading'
                                )?_c('span',{staticClass:"loading-dots red--text",class:_vm.getTimestampSatusColor(
                                        _vm.registrationTimestampStatus
                                    ) + '--text'},[_vm._v(" "+_vm._s(_vm.$t("backup-loading"))+" ")]):(
                                    _vm.registrationTimestampStatus &&
                                    _vm.registrationTimestampStatus === 'error'
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("backup-error"))+" ")]):(
                                    _vm.registrationTimestampStatus &&
                                    _vm.registrationTimestampStatus === 'saved'
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("backup-saved"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("error-occurred"))+" ")])])]}}],null,false,2688541090)},[(
                            !_vm.registrationTimestampStatus &&
                            _vm.registrationTimestamp
                        )?_c('span',[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("was"))+" "),(_vm.registrationTimestampUpdated)?_c('span',[_vm._v(" "+_vm._s(_vm.registrationTimestampUpdated))]):_c('span',[_vm._v(" "+_vm._s(_vm.$moment .utc(_vm.registrationTimestamp) .local() .from(_vm.$moment())))]),_vm._v(" "+_vm._s(_vm.$t("success-save"))+" ")])]):(
                            _vm.registrationTimestampStatus &&
                            _vm.registrationTimestampStatus === 'loading'
                        )?_c('span',{staticClass:"loading-dots caption"},[_vm._v(" "+_vm._s(_vm.$t("backup-loading"))+" ")]):(
                            _vm.registrationTimestampStatus &&
                            _vm.registrationTimestampStatus === 'error'
                        )?_c('span',{class:'caption' +
                            ' ' +
                            _vm.getTimestampSatusColor(
                                _vm.registrationTimestampStatus
                            ) +
                            '--text'},[_vm._v(" "+_vm._s(_vm.$t("backup-error"))+" ")]):(
                            _vm.registrationTimestampStatus &&
                            _vm.registrationTimestampStatus === 'saved'
                        )?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("backup-saved"))+" ")]):_c('span',{class:'caption' +
                            ' ' +
                            _vm.getTimestampSatusColor(
                                _vm.registrationTimestampStatus
                            ) +
                            '--text'},[_vm._v(" "+_vm._s(_vm.$t("error-occurred"))+" ")])])],1)],1)]},proxy:true}:null],null,true)},'v-app-bar',_vm.$attrs,false),[(_vm.appMenuPosition === 'left')?[(_vm.showMenuBtn)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-btn',{attrs:{"icon":"","color":"regOfficePrimary","large":""},on:{"click":_vm.onMenuIconClick}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1):_vm._e(),_vm._t("app-menu")]:_vm._e(),(!_vm.hideLogo)?[_vm._t("app-logo",function(){return [_c('router-link',{attrs:{"to":_vm.isAuth ? { name: 'user-dashboard' } : '/'}},[_c('v-img',{class:_vm.$vuetify.breakpoint.mobile ? undefined : 'ml-8 mr-8',attrs:{"src":require('@/' + _vm.registrationOfficeLogoPath),"contain":"","max-width":_vm.logoMaxWith}})],1)]})]:_vm._e(),_c('v-container',{staticClass:"py-0 ml-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"pl-2",attrs:{"row":"","wrap":""}},[_c('div',[(_vm.title01)?_c('div',{staticClass:"cls-toolbar-header",class:_vm.$vuetify.breakpoint.mobile
                            ? 'text-subtitle-2'
                            : 'text-subtitle-1'},[(
                            _vm.title01 == 'default-title' && _vm.scrollSize >= 100
                        )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.title01))}}):(_vm.title01 != 'default-title')?_c('div',[(_vm.$vuetify.breakpoint.mobile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({staticClass:"cls-card-header",domProps:{"innerHTML":_vm._s(_vm.title01)}},on))]}}],null,false,1441607334)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title01)}},[_vm._v(_vm._s(_vm.title01))])]):_c('span',{staticClass:"cls-card-header",domProps:{"innerHTML":_vm._s(_vm.title01)}})],1):_vm._e()]):_vm._e(),(_vm.title02)?_c('div',{staticClass:"pa-0 ma-0 cls-toolbar-header",class:_vm.$vuetify.breakpoint.mobile
                            ? 'text-caption'
                            : 'text-subtitle-2'},[(
                            _vm.title02 == 'default-subtitle' &&
                            _vm.scrollSize >= 100
                        )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.title02))}}):(_vm.title02 != 'default-subtitle')?_c('div',[(_vm.$vuetify.breakpoint.mobile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({domProps:{"innerHTML":_vm._s(_vm.title02)}},on))]}}],null,false,800338149)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title02)}})]):_c('span',{staticClass:"cls-card-header",domProps:{"innerHTML":_vm._s(_vm.title02)}})],1):_vm._e()]):_vm._e()])])],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndUp)?_c('WLanguageMenu'):_vm._e(),(_vm.appMenuPosition === 'right')?[(_vm.showMenuBtn)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-btn',{attrs:{"icon":"","color":"regOfficePrimary","large":""},on:{"click":_vm.onMenuIconClick}},[(_vm.drawer)?_c('v-icon',[_vm._v("mdi-arrow-right")]):_c('v-icon',[_vm._v("mdi-menu")])],1)],1):_vm._e(),_vm._t("app-menu")]:_vm._e(),(_vm.showAutoSaveProgressLinear)?_c('v-progress-linear',{attrs:{"indeterminate":_vm.registrationTimestampStatus &&
            _vm.registrationTimestampStatus === 'loading',"absolute":"","height":"3","rounded":"","active":"","bottom":"","color":_vm.getTimestampSatusColor(_vm.registrationTimestampStatus) +
            ' darken-5',"backgroundColor":_vm.getTimestampSatusColor(_vm.registrationTimestampStatus) +
            ' lighten-2'}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }