export class BackofficeTasksCounters {
    constructor() {
        this.registrationsPendingPool = 0;
        this.registrationsNewReplies = 0;
        this.registrationsOverdueQuestions = 0;
        this.registrationsOverdueRegistrations = 0;
        this.registrationsOpenReplies = 0;
        this.boReadyForSapExport = 0;
        this.allReadyForSapExport = 0;
        this.tasksTotalCount = 0;
    }
}
