<i18n src="./content-i18n.yaml"></i18n>
<template>
    <base-card>
        <BackofficeBaseList
            :items="items"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            @reloadDataClick="reload"
            item-key="docId"
            @action:send-reminder-mail="onSendReminderMail"
        >
        </BackofficeBaseList>
    </base-card>
</template>
<script>
import { mapState } from "vuex";
import taskList from "@/mixins/backoffice/taskList";
import { TaskActionTypes } from "@/data/task-action-types";
import BackofficeBaseList from "@/components/backoffice/components/base-list.vue";

export default {
    name: "BackofficeOverdueQuastionsContent",
    mixins: [taskList],
    components: {
        BackofficeBaseList
    },
    data() {
        return {
            actionsItems: [
                TaskActionTypes.VIEW_CHECK,
                TaskActionTypes.DATA,
                TaskActionTypes.SEND_REMINDER_MAIL,
                TaskActionTypes.VIEW_HISTORY
            ]
        };
    },
    methods: {
        onSendReminderMail(referenceId) {
            this.loader().show();
            this.$store
                .dispatch(
                    "registrationDialogs/dialogsSendReminderMail",
                    referenceId
                )
                .then(() => {
                    this.$toast.success(
                        this.$t("msg-task-reminder-mail-success")
                    );
                    this.loader().hide();
                })
                .catch((err) => {
                    console.log(err);
                    this.$toast.error(this.$t("msg-task-reminder-mail-error"));
                    this.loader().hide();
                });
        },
        fetchOverdueQuestions() {
            this.loader().show();
            this.$store
                .dispatch("backoffice/fetchOverdueQuestions")
                .then(() => {
                    this.loader().hide();
                })
                .catch((err) => {
                    console.log(err);
                    this.loader().hide();
                });
        },
        reload() {
            return this.fetchOverdueQuestions();
        }
    },
    computed: {
        ...mapState("backoffice", {
            items: "overdueQuestions"
        }),
        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-employee"),
                    value: "boEmployee",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-reminder-assign-registrant"),
                    value: "boReminderAssignRegistrant",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-reminder"),
                    value: "boReminder",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        }
    },
    created() {
        if (this.isAuth) {
            this.fetchOverdueQuestions();
        }
    },
    mounted() {
        this.$store.commit("backoffice/setSubtitle", this.$t("subtitle"));
    }
};
</script>
