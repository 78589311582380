//import components and views
import Home from "@/components/home/content.vue";
import {
    AppDefaultLayout,
    AppFullscreenLayout,
    RouteWrapper
} from "@/components/layout";

// Public Routes if nobody is loggegIn
export const publicRoute = [
    {
        path: "*",
        name: "*",
        meta: { title: "*", hiddenInMenu: true, requiresAuth: false },
        redirect: { path: "/404" }
    },
    {
        path: "/",
        component: AppDefaultLayout,
        redirect: "/",
        children: [
            {
                path: "/",
                name: "home",
                meta: {
                    title: "Home",
                    hiddenInMenu: false,
                    requiresAuth: false,
                    icon: "mdi-home",
                    hideForAuth: true
                },
                component: Home
            },
            {
                path: "/validate-token",
                name: "validate-token",
                meta: {
                    title: "validate-token",
                    hiddenInMenu: true,
                    requiresAuth: false
                },
                component: () => import("@/views/auth/validate-token.vue")
            },
            {
                path: "/login",
                name: "login",
                meta: {
                    title: "Login",
                    hiddenInMenu: true,
                    requiresAuth: false,
                    hideForAuth: false
                },
                component: () => import("@/views/auth/login.vue")
            },
            {
                path: "/faq",
                name: "faq",
                meta: {
                    title: "FAQ",
                    hiddenInMenu: true
                },
                component: () =>
                    import("@/components/general/faq/faq-content.vue"),
                props: true
            }
        ]
    },
    {
        path: "/403",
        name: "403",
        meta: {
            title: "Access Denied",
            hiddenInMenu: true,
            requiresAuth: false
        },
        component: () => import("@/views/error/deny.vue")
    },
    {
        path: "/404",
        name: "404",
        meta: {
            title: "Not Found",
            hiddenInMenu: true,
            requiresAuth: false
        },
        component: () => import("@/views/error/not-found.vue")
    },
    {
        path: "/500",
        name: "500",
        meta: {
            title: "Server Error",
            hiddenInMenu: true,
            requiresAuth: false
        },
        component: () => import("@/views/error/error.vue")
    },
    {
        path: "/token-invalid",
        name: "token-invalid",
        meta: {
            title: "Token Expired",
            hiddenInMenu: true,
            requiresAuth: false
        },
        component: () => import("@/views/error/token-invalid.vue")
    },
    {
        path: "/coming-soon",
        name: "coming-soon",
        meta: {
            title: "Coming Soon",
            hiddenInMenu: true,
            requiresAuth: false
        },
        component: () => import("@/views/utilities/coming-soon.vue")
    },
    {
        path: "/maintenance",
        name: "maintenance",
        meta: {
            title: "In Maintenance ",
            hiddenInMenu: true,
            requiresAuth: false
        },
        component: () => import("@/views/utilities/maintenance.vue")
    }
];

// Protected Routes only if somebody is loggegIn
export const protectedRoute = [
    {
        path: "/",
        component: AppDefaultLayout,
        redirect: "/dashboard",
        meta: { hiddenInMenu: true, requiresAuth: true },
        children: [
            {
                path: "/dashboard",
                name: "user-dashboard",
                meta: {
                    title: "User Dashboard",
                    hiddenInMenu: true,
                    requiresAuth: true
                },
                component: () =>
                    import("@/views/registrant/dashboard-view.vue"),
                props: true
            },
            {
                path: "/account-settings",
                meta: {
                    title: "Account Settings",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    icon: "mdi-account-cog"
                },
                component: () =>
                    import("@/views/registrant/account-settings/tabs.vue"),
                props: true,
                children: [
                    {
                        path: "", //selected tab by default
                        name: "account-settings-user",
                        component: () =>
                        import("@/views/registrant/account-settings/account-settings.vue"),
                    },
                    {
                        path: "addresses",
                        name: "account-settings-addresses",
                        component: () =>
                        import("@/views/registrant/account-settings/addresses.vue"),
                    }
                ]
            }
        ]
    },
    {
        path: "/task",
        component: AppDefaultLayout,
        redirect: "/404",
        meta: { hiddenInMenu: true, requiresAuth: true },
        children: [
            {
                path: "/task/my-tasks",
                name: "my-tasks",
                meta: {
                    title: "My Reports",
                    requiresAuth: true,
                    icon: "mdi-file-cabinet",
                    isDevel: true
                },
                component: () =>
                    import("@/views/registrant/tasks/view-list.vue")
            },
            {
                path: "/task/create/:referenceId",
                name: "task-create",
                meta: {
                    title: "Task Create",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    icon: "mdi-account-cog"
                },
                component: () => import("@/views/registrant/tasks/Create.vue"),
                props: true
            },
            {
                path: "/task/view/:referenceId",
                name: "task-view",
                meta: {
                    title: "Task View",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    icon: "mdi-account-cog"
                },
                component: () => import("@/views/registrant/tasks/View.vue"),
                props: true
            },
            {
                path: "/task/dialogs",
                name: "task-dialogs",
                meta: {
                    title: "Task Dialogs",
                    hiddenInMenu: true,
                    requiresAuth: false,
                    icon: "mdi-account-cog"
                },
                component: () =>
                    import("@/components/tasks/dialogs/list/content.vue")
            }
        ]
    },
    {
        path: "/devel",
        component: AppFullscreenLayout,
        redirect: "/devel/dashboard",
        meta: { hiddenInMenu: true, requiresAuth: true, isDevel: true },
        children: [
            {
                path: "/devel/test",
                name: "devel-test",
                meta: {
                    title: "DevelTest",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    isDevel: true,
                    icon: "mdi-bug"
                },
                component: () => import("@/views/devel/test-view.vue")
            },
            {
                path: "/devel/dashboard",
                name: "devel-dashboard",
                meta: {
                    title: "Devel Dashboard",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    isDevel: true,
                    icon: "mdi-bug"
                },
                component: () => import("@/views/devel/dashboard-view.vue")
            },
            {
                path: "/devel/logs",
                name: "devel-logs",
                meta: {
                    title: "Devel Logs",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    isDevel: true,
                    icon: "mdi-bug"
                },
                component: () => import("@/views/devel/logs-view.vue")
            },
            {
                path: "/devel/mailspreview",
                name: "devel-mailspreview",
                meta: {
                    title: "DevelMailsPreview",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    isDevel: true,
                    icon: "mdi-bug"
                },
                component: () =>
                    import("@/views/devel/mail-templates-preview.vue")
            }
        ]
    },
    {
        path: "/backoffice",
        name: "backoffice",
        meta: {
            title: "Backoffice",
            hiddenInMenu: false,
            requiresAuth: true,
            icon: "mdi-account-cog"
        },
        component: AppFullscreenLayout,
        redirect: "/backoffice/home",
        children: [
            {
                path: "/backoffice/home",
                name: "backoffice-home",
                meta: {
                    title: "backofficehome",
                    requiresAuth: true,
                    isBackoffice: true
                },
                component: () => import("@/views/backoffice/dashboard-view.vue")
            },
            {
                path: "/backoffice/tasks",
                name: "backoffice-tasks",
                meta: {
                    title: "backoffice-tasks",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    icon: "mdi-account-cog"
                },
                component: RouteWrapper,
                redirect: "/backoffice/home",
                children: [
                    {
                        path: "/backoffice/tasks/pending-pool",
                        name: "backoffice-tasks-list-pending-pool",
                        meta: {
                            title: "listnew",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb: "backoffice-tasks-list-pending-pool"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/pendingpool/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/newreplies",
                        name: "backoffice-tasks-list-newreplies",
                        meta: {
                            title: "newreplies",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb: "backoffice-tasks-list-newreplies"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/newreplies/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/overduetasks",
                        name: "backoffice-tasks-list-overduetasks",
                        meta: {
                            title: "overduetasks",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb: "backoffice-tasks-list-overduetasks"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/overduetasks/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/overduequestions",
                        name: "backoffice-tasks-list-overduequestions",
                        meta: {
                            title: "overduequestions",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb: "backoffice-tasks-list-overduequestions"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/overduequestions/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/opentasks",
                        name: "backoffice-tasks-list-opentasks",
                        meta: {
                            title: "opentasks",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb: "backoffice-tasks-list-opentasks"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/opentasks/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/bo-ready-for-sap-export",
                        name: "backoffice-tasks-list-bo-ready-for-sap-export",
                        meta: {
                            title: "bo-ready-for-sap-export",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb:
                                "backoffice-tasks-list-bo-ready-for-sap-export"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/bo-ready-for-sap-export/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/all-ready-for-sap-export",
                        name: "backoffice-tasks-list-all-ready-for-sap-export",
                        meta: {
                            title: "all-ready-for-sap-export",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb:
                                "backoffice-tasks-list-all-ready-for-sap-export"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/all-ready-for-sap-export/content.vue"
                            )
                    },
                    {
                        path: "/backoffice/tasks/search",
                        name: "backoffice-tasks-list-search",
                        meta: {
                            title: "search",
                            requiresAuth: true,
                            isBackoffice: true,
                            breadCrumb: "backoffice-tasks-list-search"
                        },
                        component: () =>
                            import(
                                "@/views/backoffice/tasks/list/search/content.vue"
                            )
                    }
                ]
            },
            {
                path: "/backoffice/task/:referenceId",
                name: "backoffice-view",
                meta: {
                    title: "Check Task",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    isBackoffice: true
                },
                component: () =>
                    import(
                        "@/views/backoffice/tasks/registration/edit-content.vue"
                    ),
                props: true
            },
            {
                path: "/backoffice/task/view/:referenceId",
                name: "backoffice-task-view",
                meta: {
                    title: "View Task",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    isBackoffice: true
                },
                component: () => import("@/views/backoffice/tasks/View.vue"),
                props: true
            },
            {
                path: "/backoffice/deregistration/:referenceId",
                name: "backoffice-deregistration-view-edit",
                meta: {
                    title: "deregistration_view_edit",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    isBackoffice: true
                },
                component: () =>
                    import(
                        "@/views/backoffice/tasks/deregistration/ViewEdit.vue"
                    ),
                props: true
            }
        ]
    },
    {
        path: "/admin",
        name: "admin",
        meta: { title: "Admin", hiddenInMenu: false, requiresAuth: true },
        component: AppFullscreenLayout,
        redirect: "/admin/dashboard",
        children: [
            {
                path: "/admin/dashboard",
                name: "admin-dashboard",
                meta: {
                    title: "AdminDashboard",
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import("@/views/admin/dashboard-view.vue")
            },
            {
                path: "/admin/specialists-companies/view",
                name: "admin-specialists-companies-view",
                meta: {
                    title: "SpecialistsCompaniesViewDashboard",
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () =>
                    import("@/views/admin/specialists-companies/view.vue")
            },
            {
                path: "/admin/users/view",
                name: "admin-users-view",
                meta: {
                    title: "UsersView",
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import("@/views/admin/users/view.vue")
            },
            {
                path: "/admin/manufacturers-companies/view",
                name: "admin-manufacturers-companies-view",
                meta: {
                    title: "ManufacturersCompaniesViewDashboard",
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () =>
                    import("@/views/admin/manufacturers-companies/view.vue")
            },
            {
                path: "/admin/config/view",
                name: "admin-config-view",
                meta: {
                    title: "AdminConfigView",
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import("@/views/admin/config.vue")
            }
        ]
    },
    {
        path: "/insurer",
        name: "insurer",
        meta: {
            title: "Insurer",
            hiddenInMenu: false,
            requiresAuth: true,
            icon: "mdi-account-cog"
        },
        component: AppFullscreenLayout,
        redirect: "/insurer/dashboard",
        children: [
            {
                path: "/insurer/dashboard",
                name: "insurer-dashboard",
                meta: {
                    title: "insurer-dashboard",
                    requiresAuth: true,
                    isInsurer: true
                },
                component: () => import("@/views/insurer/dashboard.vue")
            },
            {
                path: "/insurer/tasks",
                name: "insurer-tasks",
                meta: {
                    title: "insurer-tasks",
                    hiddenInMenu: false,
                    requiresAuth: true,
                    icon: "mdi-account-cog"
                },
                component: RouteWrapper,
                redirect: "/insurer/dashboard",
                children: [
                    {
                        path: "/insurer/tasks/approval-required",
                        name: "insurer-tasks-list-approval-required",
                        meta: {
                            title: "Approval required pool",
                            requiresAuth: true,
                            isInsurer: true,
                            breadCrumb: "insurer-tasks-list-approval-required"
                        },
                        component: () =>
                            import(
                                "@/views/insurer/tasks/list/approval-required-pool-tasks/content.vue"
                            )
                    }
                ]
            },
            {
                path: "/insurer/task/:referenceId",
                name: "insurer-task-check",
                meta: {
                    title: "Check Task",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    isInsurer: true
                },
                component: () => import("@/views/insurer/tasks/check.vue"),
                props: true
            },
            {
                path: "/insurer/task/view/:referenceId",
                name: "insurer-task-view",
                meta: {
                    title: "View Task",
                    hiddenInMenu: true,
                    requiresAuth: true,
                    isInsurer: true
                },
                component: () => import("@/views/insurer/tasks/view.vue"),
                props: true
            }
        ]
    }
];
