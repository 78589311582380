<i18n src="./maintenance-i18n.yaml"></i18n>
<template>
    <UtilityPageContainer
        :wIconSrc="require('@/assets/icons/pages/maintenance.svg')"
        :title="$t('msg-title')"
        :text="$t('msg-text')"
    >
    </UtilityPageContainer>
</template>
<script>
export default {
    name: "ViewUtilitiesMaintenance",
    components: {
        UtilityPageContainer: () =>
            import("@/components/general/utlis/utility-page-container.vue")
    }
};
</script>
