de:
  modal_btn_cancel: Abbrechen
  modal_btn_save: Übernehmen
  example_date: 'Bsp: {example}'
en:
it:
  example_date: 'Es.: {example}'
  modal_btn_cancel: Abortire
  modal_btn_save: Acquisire
fr:
  example_date: 'Ex.: {example}'
  modal_btn_cancel: Interrompre
  modal_btn_save: Accepter
