<i18n src="./base-list-i18n.yaml"></i18n>
<template>
    <div>
        <ViewTableList
        :title="computedTitle"
        :subtitle="subtitle"
        :is-loading="items.length === 0 && isLoading"
        >
            <template v-slot:table>
                <TasksTable
                    :items="items"
                    :actions="filteredActions"
                    @click:action="onActionClick"
                    v-on="$listeners"
                    v-bind="$attrs"
                >
                    <!-- Dynamically inherit slots from parent -->
                    <template
                        v-for="(slot, name) in $scopedSlots"
                        v-slot:[name]="item"
                    >
                        <slot :name="name" v-bind="item"></slot>
                    </template>
                </TasksTable>
                <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col md="8">
                            <DialogViewData
                                :docId="selectedTask.docId"
                                :value="dataDialog"
                                @input="dataDialog = $event"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </ViewTableList>
    </div>
</template>

<script>
import { TaskActionTypes } from "@/data/task-action-types";
import { mapGetters, mapState } from "vuex";
import Helpers from "@/utils/helpers";
import { PROCESS_TYPES } from "@/data/globals.js";

export default {
    name: "InsurerBaseList",
    components: {
        TasksTable: () =>
            import("@/components/backoffice/components/tasks-table.vue"),
        DialogViewData: () =>
            import("@/components/tasks/common/dialog-view-data.vue"),
        ViewTableList: () => import("@/components/general/view-table-list.vue")
    },

    props: {
        actions: {
            type: Array,
            default() {
                return [TaskActionTypes.DATA];
            },
            validator: function (actions) {
                let validTypes = Object.keys(TaskActionTypes);
                actions.forEach((action) => {
                    if (!validTypes.includes(action)) return false;
                });
                return true;
            }
        },
        items: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dataDialog: false,
        selectedTask: {}
    }),

    methods: {
        onActionClick({ action, item }) {
            this.selectedTask = item;
            switch (action) {
                case TaskActionTypes.VIEW:
                    this.onViewTask(item.process, item.referenceId);
                    break;
                case TaskActionTypes.VIEW_CHECK:
                    this.onCheckTask(item.process, item.referenceId);
                    break;
                case TaskActionTypes.DATA:
                    this.dataDialog = true;
                    break;
                case TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP:
                    this.onDownloadAllAttachmentsAsZip(item);
                    break;
                case TaskActionTypes.VIEW_HISTORY:
                    this.onViewHistory(item);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        onViewHistory(task) {
            this.$root.$historyModal.show(task.docId);
        },
        onDownloadAllAttachmentsAsZip(task) {
            const taskDocId = task.docId;
            if (!taskDocId) return;
            this.loader().show();
            this.$axiosBinary
                .get(`/getAllAsZip/${taskDocId}`, {
                    responseType: "blob" // important
                })
                .then((response) => {
                    const downloaded =
                        Helpers.downloadAttachmentsAsZipByResponse(response);
                    if (downloaded) {
                        this.$toast.success(
                            this.$t("download-attachments-as-zip-msg-success")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("download-attachments-as-zip-msg-error")
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("download-attachments-as-zip-msg-error")
                    );
                });
        },
        onViewTask(process, referenceId) {
            if (!referenceId) return;
            if (process === PROCESS_TYPES.REGISTRATION) {
                this.$router.push({
                    name: "insurer-task-view",
                    params: { referenceId: referenceId }
                });
            } else {
                this.$toast.info(
                    `View for ${process} with ReferenceID: ${referenceId} isn't available)`
                );
            }
        },
        onCheckTask(process, referenceId) {
            if (!referenceId) return;
            if (process === PROCESS_TYPES.REGISTRATION) {
                this.$router.push({
                    name: "insurer-task-check",
                    params: { referenceId: referenceId }
                });
            } else {
                this.$toast.info(
                    `View for ${process} with docID: ${referenceId} isn't available`
                );
            }
        }
    },

    computed: {
        ...mapState("auth", ["accountDocId"]),
        ...mapGetters("auth", ["isUserAdmin"]),
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        computedTitle() {
            if (!this.title) return null;
            return this.title + " (" + this.items.length + ")";
        },
        filteredActions() {
            return this.actionsItems.filter((action) => {
                let show = true;
                if (action.isDevel && !this.developerMode) {
                    show = false;
                }
                if (action.isAdmin && !this.isUserAdmin) {
                    show = false;
                }
                return show;
            });
        },
        allowedActions() {
            return {
                [TaskActionTypes.VIEW]: {
                    title: this.$t("action-view"),
                    icon: "mdi-eye",
                    action: TaskActionTypes.VIEW
                },
                [TaskActionTypes.VIEW_CHECK]: {
                    title: this.$t("action-view-check"),
                    icon: "mdi-eye",
                    action: TaskActionTypes.VIEW_CHECK
                },
                [TaskActionTypes.DATA]: {
                    title: this.$t("action-data"),
                    icon: "mdi-code-json",
                    action: TaskActionTypes.DATA,
                    isDevel: true
                },

                [TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP]: {
                    title: this.$t("action-download-all-attachments-as-zip"),
                    icon: "mdi-folder-zip",
                    action: TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP,
                    disabled: (task) => {
                        return task.attachmentsCounter === 0;
                    }
                },
                [TaskActionTypes.VIEW_HISTORY]: {
                    title: this.$t("action-view-history"),
                    icon: "mdi-history",
                    action: TaskActionTypes.VIEW_HISTORY,
                    isAdmin: true
                }
            };
        },
        actionsItems() {
            let actions = [];
            this.actions.forEach((action) => {
                let _action = this.allowedActions[action];
                if (_action) {
                    actions.push(_action);
                }
            });
            return actions;
        }
    }
};
</script>
