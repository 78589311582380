const date = (value) => {
    let regex = null;

    //DD.MM.YYYY
    regex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    if (value.match(regex)) return true;

    //DD/mm/YYYY
    regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    if (value.match(regex)) return true;

    //DD-mm-YYYY
    regex = /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/;
    if (value.match(regex)) return true;

    //mm/DD/YYYY
    regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (value.match(regex)) return true;

    //YYYY.MM.DD
    regex = /^((19|20)\d{2})\.(0[1-9]|1[0-2])\.(0[1-9]|1\d|2\d|3[01])$/;
    if (value.match(regex)) return true;

    //YYYY/MM/DD
    regex = /^((19|20)\d{2})\/(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])$/;
    if (value.match(regex)) return true;

    //YYYY-MM-DD
    regex = /^((19|20)\d{2})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
    if (value.match(regex)) return true;

    return false;
};

export { date };
