import { render, staticRenderFns } from "./modal-settings.vue?vue&type=template&id=6ee6866a&scoped=true&"
import script from "./modal-settings.vue?vue&type=script&lang=js&"
export * from "./modal-settings.vue?vue&type=script&lang=js&"
import style0 from "./modal-settings.vue?vue&type=style&index=0&id=6ee6866a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee6866a",
  null
  
)

/* custom blocks */
import block0 from "./modal-settings-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fgeneral%2Fmodal-settings.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtnToggle,VContainer,VDivider,VFlex,VIcon,VLayout,VOtpInput,VOverlay,VProgressCircular,VSpacer,VSubheader,VSwitch})
