<i18n src="./form-contact-person-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="validate">
                    <v-icon>mdi-reload</v-icon>
                </base-btn>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
            </template>
        </app-dev-container>
        <StepContentTitle :title="$t('title')" />
        <v-container fill-height fluid no-gutters class="pa-0 ma-0">
            <!-- Kontaktperson -->
            <v-row class="mt-2">
                <v-col cols="12" md="2">
                    <base-select
                        :items="salutationItems"
                        v-model="salutation"
                        :label="$t('salutation-label')"
                        item-value="key"
                        :error-messages="salutationErrors"
                        :required="isRequiredField($v.contactPerson.salutation)"
                        :item-text="
                            (item) => $t(item.value.title[$i18n.locale])
                        "
                    >
                    </base-select>
                </v-col>
                <v-col cols="12" md="10">
                    <!-- Name -->
                    <base-text-field
                        v-model="name"
                        :label="$t('name-label')"
                        :error-messages="nameErrors"
                        :counter="$v.contactPerson.name.$params.maxLength.max"
                        append-icon="mdi-information-outline"
                        @click:append="showFaq('equipmentContactPerson')"
                        :required="isRequiredField($v.contactPerson.name)"
                    ></base-text-field>
                </v-col>
            </v-row>
        </v-container>
        <form-communication
            ref="formCommunication"
            v-on:field-change="onCommunicationFieldsChange"
            :formData.sync="contactPerson.communication"
            :readonlyModus="readonlyModus"
            :disabled="disabled"
            required
        >
        </form-communication>
    </v-form>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import FormCommunication from "./form-communication.vue";
import _ from "lodash";
import StepContentTitle from "../components/step-content-title.vue";
import validateMixin from "@/mixins/validate";
export default {
    name: "FormContactPerson",
    components: {
        FormCommunication,
        StepContentTitle
    },
    mixins: [validateMixin],
    validations: {
        contactPerson: {
            salutation: {
                required
            },
            name: {
                required,
                maxLength: maxLength(30)
            }
        }
    },
    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        contactPerson: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        onFieldChange(field, value) {
            if (!field) return;
            this.onFormFieldChange(field, value);
            this.$emit("field-change", { field, value });
        },
        onFormFieldChange(field, value) {
            let contactPerson = _.cloneDeep(this.contactPerson);
            contactPerson = _.set(contactPerson, field, value);
            this.onFormChange(contactPerson);
        },
        onFormChange(form) {
            this.$emit("form-change", form);
        },
        onCommunicationFieldsChange(field, value) {
            if (!field) return;
            let commucationForm = {};
            if (
                this.contactPerson.communication &&
                Object.keys(this.contactPerson.communication).length > 0
            ) {
                commucationForm = _.cloneDeep(this.contactPerson.communication);
                commucationForm = _.set(commucationForm, field, value);
            } else {
                commucationForm = _.set(commucationForm, field, value);
            }
            this.onFieldChange("communication", commucationForm);
        },
        isValid() {
            if (!this.$v.$dirty) {
                return null;
            } else {
                if (
                    !this.$v.$anyError &&
                    this.$refs.formCommunication.isValid()
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        validate() {
            this.$v.$touch();
            this.$refs.formCommunication.validate();
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                this.$refs.formCommunication.scrollToFirstError();
            });
        },
        reset() {
            this.$v.$reset();
            this.$refs.formCommunication.reset();
            this.$emit("reset");
        },
        setFakeData() {
            this.reset();
            let contactPerson = _.cloneDeep(this.contactPerson);
            contactPerson.salutation = "0002";
            contactPerson.name = "Ricardo Coelho";
            let communication = {};
            communication.phone = "+41 79 563 88 00";
            communication.fax = "+41 79 563 88 11";
            communication.email = "info@was.ch";
            contactPerson.communication = communication;
            this.onFormChange(contactPerson);
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        }
    },
    computed: {
        salutation: {
            get() {
                return this.contactPerson.salutation;
            },
            set(value) {
                this.onFieldChange("salutation", value);
            }
        },
        name: {
            get() {
                return this.contactPerson.name;
            },
            set(value) {
                this.onFieldChange("name", value);
            }
        },
        salutationItems() {
            return this.salutations;
        },
        ...mapGetters({
            salutations: "attributes/salutations"
        }),
        salutationErrors() {
            const errors = [];
            if (!this.$v.contactPerson.salutation.$dirty) {
                return errors;
            }
            if (!this.$v.contactPerson.salutation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.contactPerson.name.$dirty) {
                return errors;
            }
            if (!this.$v.contactPerson.name.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.contactPerson.name.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.contactPerson.name.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },
    created() {
        this.loader().show();
        let salutationsKey = "salutations";
        this.getStoredAttributesByKey(salutationsKey)
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    "Laden der Daten: " + salutationsKey + " war nicht möglich"
                );
            });
    }
};
</script>
