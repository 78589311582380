import { render, staticRenderFns } from "./task-summary-with-verification.vue?vue&type=template&id=6f3ae6d1&scoped=true&"
import script from "./task-summary-with-verification.vue?vue&type=script&lang=js&"
export * from "./task-summary-with-verification.vue?vue&type=script&lang=js&"
import style0 from "./task-summary-with-verification.vue?vue&type=style&index=0&id=6f3ae6d1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f3ae6d1",
  null
  
)

/* custom blocks */
import block0 from "./task-summary-with-verification-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fedit%2Fbackoffice%2Fcomponents%2Ftask-summary-with-verification.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VDivider,VIcon,VList,VListItem,VListItemAction,VListItemContent,VTooltip})
