<i18n src="./modal-stepper-copy-task-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="value"
        @close="$emit('input', $event)"
        :title="$t('dialog-title')"
        width="700"
        ref="modalDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        icon="mdi-content-copy"
    >
        <template v-slot:content>
            <app-dev-container>
                <template v-slot:content>
                    @task:
                    <tree-view
                        :data="Object.assign({}, task)"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                    @registration:
                    <tree-view
                        :data="Object.assign({}, registration)"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                    <base-btn
                        tile
                        depressed
                        color="warning"
                        @click="setFakeData"
                    >
                        <v-icon>mdi-bug</v-icon>
                        Set Dummy Data
                    </base-btn>
                </template>
            </app-dev-container>
            <v-stepper
                v-model="step"
                :vertical="$vuetify.breakpoint.mobile"
                class="elevation-0"
            >
                <v-stepper-header>
                    <v-stepper-step
                        :editable="step > 1"
                        :complete="step > 1"
                        step="1"
                        >{{ $t("working-title") }}</v-stepper-step
                    >
                    <v-divider></v-divider>
                    <v-stepper-step
                        :editable="step > 2"
                        :complete="step > 2"
                        step="2"
                        >{{ $t("object-details") }}</v-stepper-step
                    >
                    <v-divider></v-divider>
                    <v-stepper-step step="3">{{
                        $t("task-copy-data")
                    }}</v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentWorkingTitle
                                    ref="contentWorkingTitle"
                                    :workingTitle="workingTitle"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentObjectDetails
                                    ref="contentObjectDetails"
                                    :manufacturer="manufacturer"
                                    :factoryNumber="factoryNumber"
                                    :constructionYear="constructionYear"
                                    :intendedUse="intendedUse"
                                    :inOperationYear="inOperationYear"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <span class="subtitle-1">
                                    {{ $t("task-summary-copy-info") }}
                                </span>

                                <TaskSummaryCopy
                                    ref="taskSummaryCopy"
                                    class="px-2"
                                    :task="task"
                                    @change="taskDataToCopy = $event"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>
        <template v-slot:actions>
            <base-btn
                v-if="step > 1"
                type="secondary"
                @click="step--"
                :btnIcon="vertical ? 'mdi-arrow-up' : 'mdi-arrow-left'"
            >
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-back") }}
                </span>
            </base-btn>
            <v-spacer />
            <base-btn
                v-if="step !== 3"
                @click="submit"
                type="primary"
                :btnIcon="vertical ? 'mdi-arrow-down' : 'mdi-arrow-right'"
            >
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-continue") }}
                </span>
            </base-btn>
            <base-btn v-if="step === 3" type="save" @click="submit">
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-create") }}
                </span>
            </base-btn>
        </template>
    </base-dialog>
</template>

<script>
import { BaseRegistration } from "@/models/tasks";
import { PROCESS_TYPES } from "@/data/globals.js";

export default {
    name: "ModalStepperCopyTask",
    components: {
        ContentWorkingTitle: () =>
            import("@/components/tasks/create/content-working-title.vue"),
        ContentObjectDetails: () =>
            import("@/components/tasks/create/content-object-details.vue"),
        TaskSummaryCopy: () => import("./task-summary-copy.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        task: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        step: 1,
        registration: new BaseRegistration(),
        vertical: false,
        taskDataToCopy: {}
    }),
    computed: {
        workingTitle: {
            get() {
                return this.registration.workingTitle;
            },
            set(value) {
                this.registration.workingTitle = value;
            }
        },
        manufacturer: {
            get() {
                return this.registration.manufacturer;
            },
            set(value) {
                this.registration.manufacturer = value;
            }
        },
        factoryNumber: {
            get() {
                return this.registration.factoryNumber;
            },
            set(value) {
                this.registration.factoryNumber = value;
            }
        },
        constructionYear: {
            get() {
                return this.registration.constructionYear;
            },
            set(value) {
                this.registration.constructionYear = value;
            }
        },
        intendedUse: {
            get() {
                return this.registration.intendedUse;
            },
            set(value) {
                this.registration.intendedUse = value;
            }
        },
        inOperationYear: {
            get() {
                return this.registration.inOperationYear;
            },
            set(value) {
                this.registration.inOperationYear = value;
            }
        }
    },
    methods: {
        setFakeData() {
            this.clearRegistration();
            this.step = 2;
            this.registration.workingTitle = "Mein Arbeitstitel";
            this.registration.factoryNumber = "238832424-34234";
            this.registration.constructionYear = 2017;
            this.registration.intendedUse =
                "Curabitur aliquet quam id dui posuere blandit. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.";
            this.registration.inOperationYear = 2018;
            this.registration.role = this.task.role;
            this.registration.objectType = this.task.objectType;
            this.registration.process = this.task.process;
        },
        onFieldValueChange({ field, value }) {
            if (!field) return;
            this[field] = value;
        },
        reset() {
            this.step = 1;
            this.clearRegistration();
        },
        clearRegistration() {
            this.registration = new BaseRegistration();
            delete this.registration.referenceId;
            delete this.registration.step;
        },
        getScrollOptions() {
            let baseDialog = this.$refs.modalDialog;
            let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
            return {
                container: baseDialogCardTextRef
            };
        },
        submit() {
            let scrollOptions = this.getScrollOptions();
            if (this.step == 1) {
                this.$refs.contentWorkingTitle.validate();
                if (this.$refs.contentWorkingTitle.isValid()) {
                    this.step = 2;
                } else {
                    this.$refs.contentWorkingTitle.scrollToFirstError(
                        scrollOptions
                    );
                }
                return;
            }
            if (this.step == 2) {
                this.$refs.contentObjectDetails.validate();
                if (this.$refs.contentObjectDetails.isValid()) {
                    this.step = 3;
                } else {
                    this.$refs.contentObjectDetails.scrollToFirstError(
                        scrollOptions
                    );
                }
                return;
            }
            if (this.step == 3) {
                this.loader().show();
                let data = Object.assign(
                    this.registration,
                    this.taskDataToCopy
                );
                const copyReferenceId = this.task.referenceId;
                data.copyReferenceId = copyReferenceId;
                this.$store
                    .dispatch("registrations/createCopy", {
                        data,
                        copyReferenceId
                    })
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            let resData = response.data.data;
                            if (data.process === PROCESS_TYPES.REGISTRATION) {
                                this.$router.push({
                                    name: "task-create",
                                    params: {
                                        referenceId: resData.referenceId
                                    }
                                });
                                this.$emit('task:created');
                            }
                        }
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        const resData = error?.response?.data;
                        const errorCode = resData.code;
                        const errorData = resData.data;
                        if (this.$te(`error-code-${errorCode}`)) {
                            //CHECK IF TRANSLATE EXISTS
                            if (errorCode === 5) {
                                if (errorData.addressType) {
                                    this.$toast.error(
                                        this.$t("error-code-5", {
                                            addressType: this.$t(
                                                `address-type-${errorData.addressType}`
                                            )
                                        })
                                    );
                                }
                            } else if (errorCode === 7) {
                                //field is missing
                                const missingField = errorData.missingField;
                                if (missingField) {
                                    if (
                                        this.$te(
                                            `registration-field-${missingField}`
                                        )
                                    ) {
                                        this.$toast.error(
                                            this.$t("error-code-7", {
                                                missingField: this.$t(
                                                    `registration-field-${missingField}`
                                                )
                                            })
                                        );
                                    } else {
                                        this.$toast.error(
                                            this.$t(
                                                `error-code-${errorCode}`,
                                                3000
                                            )
                                        );
                                    }
                                }
                            } else {
                                this.$toast.error(
                                    this.$t(`error-code-${errorCode}`, 3000)
                                );
                            }
                        } else {
                            //ELSE WILL DISPLAY AN GENERAL ERROR MESSAGE
                            this.$toast.error(this.$t("error-code-0"));
                        }
                        this.loader().hide();
                    });
            }
        },
        setRegistrationDataOnTaskChange(task) {
            this.registration.role = task.role;
            this.registration.objectType = task.objectType;
            this.registration.process = task.process;
        }
    },
    watch: {
        "$vuetify.breakpoint.mobile"() {
            this.step = 2;
            requestAnimationFrame(() => (this.step = 1)); // Workarounds
        },
        value: {
            handler: function (newValue) {
                if (newValue) {
                    this.setRegistrationDataOnTaskChange(this.task);
                }
            },
            immediate: true
        }
    }
};
</script>

<style>
</style>
