<i18n src="./date-range-filter-card-i18n.yaml"></i18n>
<template>
    <v-card max-width="1000">
        <v-row>
            <v-col cols="12" sm="3">
                <v-container>
                    <v-list>
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="onListItemClick(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="$t(item.text)"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <base-select
                        :items="groupByItems"
                        v-model="groupBy"
                        :label="$t('label-group-by')"
                        item-value="id"
                        :item-text="(item) => $t(item.text)"
                    >
                    </base-select>
                </v-container>
            </v-col>
            <v-col>
                <v-container>
                    <div class="text-center pt-2">
                        {{ $t("label-from") }}: {{ formatDate(startDate) }}
                    </div>
                    <v-date-picker
                        v-model="startDate"
                        full-width
                        no-title
                        show-week
                        :max="dateNow"
                    ></v-date-picker> </v-container
            ></v-col>
            <v-col>
                <v-container>
                    <div class="text-center pt-2">
                        {{ $t("label-to") }}: {{ formatDate(endDate) }}
                    </div>
                    <v-date-picker
                        v-model="endDate"
                        full-width
                        no-title
                        show-week
                        :min="startDate"
                    ></v-date-picker> </v-container
            ></v-col>
        </v-row>
        <v-divider />
        <v-card-actions>
            <v-spacer></v-spacer>
            <base-btn @click="onApplyChanges">Anwenden</base-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Utils from "@/utils";
const LIST_OPTIONS = {
    LAST_7_DAYS: "LAST_7_DAYS",
    LAST_30_DAYS: "LAST_30_DAYS",
    CURRENT_MONTH: "CURRENT_MONTH",
    LAST_MONTH: "LAST_MONTH",
    CURRENT_YEAR: "CURRENT_YEAR",
    LAST_YEAR: "LAST_YEAR"
};
const GROUP_BY_CHART_OPTIONS = {
    DAY: "day",
    MONTH: "month",
    YEAR: "year"
};
export default {
    name: "DateRangeFilterCard",
    data: () => ({
        LIST_OPTIONS,
        GROUP_BY_CHART_OPTIONS,
        selectedItem: 1,
        dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        startDate: null,
        endDate: null,
        items: [
            { text: "last-7-days", id: LIST_OPTIONS.LAST_7_DAYS },
            { text: "last-30-days", id: LIST_OPTIONS.LAST_30_DAYS },
            { text: "current-month", id: LIST_OPTIONS.CURRENT_MONTH },
            { text: "last-month", id: LIST_OPTIONS.LAST_MONTH },
            { text: "current-year", id: LIST_OPTIONS.CURRENT_YEAR },
            { text: "last-year", id: LIST_OPTIONS.LAST_YEAR }
        ],
        groupByItems: [
            {
                text: "label-day",
                id: GROUP_BY_CHART_OPTIONS.DAY
            },
            {
                text: "label-month",
                id: GROUP_BY_CHART_OPTIONS.MONTH
            },
            {
                text: "label-year",
                id: GROUP_BY_CHART_OPTIONS.YEAR
            }
        ],
        groupBy: GROUP_BY_CHART_OPTIONS.MONTH,
        payloadData: {
            startDate: null,
            endDate: null,
            groupBy: null
        }
    }),
    methods: {
        formatDate: Utils.formatDate,
        formatDateIso: Utils.formatDateIso,
        onListItemClick(item) {
            switch (item.id) {
                case LIST_OPTIONS.LAST_7_DAYS: {
                    this.startDate = this.formatDateIso(
                        this.$moment().subtract(7, "day")
                    );
                    this.endDate = this.formatDateIso(this.$moment());
                    break;
                }
                case LIST_OPTIONS.LAST_30_DAYS: {
                    this.startDate = this.formatDateIso(
                        this.$moment().subtract(30, "day")
                    );
                    this.endDate = this.formatDateIso(this.$moment());
                    break;
                }
                case LIST_OPTIONS.CURRENT_MONTH: {
                    this.startDate = this.formatDateIso(
                        this.$moment().startOf("month")
                    );
                    this.endDate = this.formatDateIso(this.$moment());
                    break;
                }
                case LIST_OPTIONS.LAST_MONTH: {
                    const lastMonth = this.$moment().subtract(1, "month");
                    this.startDate = this.formatDateIso(
                        lastMonth.startOf("month")
                    );
                    this.endDate = this.formatDateIso(lastMonth.endOf("month"));
                    break;
                }
                case LIST_OPTIONS.CURRENT_YEAR: {
                    this.startDate = this.formatDateIso(
                        this.$moment().startOf("year")
                    );
                    this.endDate = this.formatDateIso(this.$moment());
                    break;
                }
                case LIST_OPTIONS.LAST_YEAR: {
                    const lastYear = this.$moment().subtract(1, "year");
                    this.startDate = this.formatDateIso(
                        lastYear.startOf("year")
                    );
                    this.endDate = this.formatDateIso(lastYear.endOf("year"));
                    break;
                }

                default:
                    break;
            }
        },
        onApplyChanges() {
            this.$emit("change", {
                startDate: this.startDate,
                endDate: this.endDate,
                groupBy: this.groupBy
            });
        }
    },
    mounted() {
        this.$emit("change", {
            startDate: this.startDate,
            endDate: this.endDate,
            groupBy: this.groupBy
        });
    },
    created() {
        this.startDate = this.formatDateIso(this.$moment().startOf("month"));
        this.endDate = this.formatDateIso(this.$moment());
    }
};
</script>
