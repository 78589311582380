<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <v-list-item @click="$emit('click')">
        <v-list-item-avatar size="50">
            <registration-icon
                class="pt-2"
                :objectType="registration.objectType"
                :state="registration.state"
                :deletedState="
                    registration.deletedState ? registration.deletedState : 0
                "
                :process="registration.process"
                :stateText="$t(`task-object-type-${registration.objectType}`)"
            >
            </registration-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <RegistrationCardContent :item="registration" />
        </v-list-item-content>
        <v-list-item-action>
            <v-icon color="info">mdi-eye</v-icon>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
import { getRegistrationStateColor } from "@/utils/defaults.js";
import { OBJECT_TYPES } from "@/data/globals.js";
import RegistrationIcon from "@/components/tasks/common/registration-icon.vue";
import RegistrationCardContent from "./registration-card-content.vue";
export default {
    name: "RegistrationListItem",
    components: {
        RegistrationIcon,
        RegistrationCardContent
    },
    props: {
        registration: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        OBJECT_TYPES: OBJECT_TYPES
    }),
    methods: {
        getRegistrationStateColor: getRegistrationStateColor
    }
};
</script>
