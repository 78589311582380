var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionBase',{attrs:{"id":"devel-size-class","title":"SIZE CLASS","subtitle":"Test Size Class Field dependending on type"}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-text-field',{attrs:{"label":"totalChambersVolume"},on:{"input":_vm.onChamberLiterChanges},model:{value:(_vm.totalChambersVolume),callback:function ($$v) {_vm.totalChambersVolume=$$v},expression:"totalChambersVolume"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-autocomplete',{attrs:{"items":_vm.sortedObjectStatusItems,"label":_vm.$t('object-status-label'),"item-value":"key","clearable":"","filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.value[_vm.$i18n.locale])?_c('span',[_c('b',[_vm._v(_vm._s(item.key))]),_vm._v(": "+_vm._s(item.value[_vm.$i18n.locale])+" ")]):_c('span',[_c('b',[_vm._v(_vm._s(item.key))]),_vm._v(": "+_vm._s(item.value.de)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[(item.value[_vm.$i18n.locale])?_c('span',[_c('b',[_vm._v(_vm._s(item.key))]),_vm._v(": "+_vm._s(item.value[_vm.$i18n.locale])+" ")]):_c('span',[_c('b',[_vm._v(_vm._s(item.key))]),_vm._v(": "+_vm._s(item.value.de)+" ")])])]}}]),model:{value:(_vm.objectStatus),callback:function ($$v) {_vm.objectStatus=$$v},expression:"objectStatus"}})],1),(_vm.objectStatus)?_c('v-col',{attrs:{"cols":"12"}},[_c('base-autocomplete',{attrs:{"items":_vm.sortedObjectCodeItems,"label":_vm.$t('object-code-label'),"item-value":"key","clearable":"","filter":_vm.customFilter,"item-text":function (item) { return item.value[_vm.$i18n.locale]
                                ? item.value[_vm.$i18n.locale]
                                : item.value.de; }},model:{value:(_vm.objectCode),callback:function ($$v) {_vm.objectCode=$$v},expression:"objectCode"}})],1):_vm._e(),(_vm.objectStatus && _vm.objectCode)?_c('v-col',{attrs:{"cols":"12"}},[_c('base-select',{attrs:{"items":_vm.objectCategoryItems,"label":_vm.$t('object-category-label'),"clearable":"","item-text":function (item) { return _vm.$t(
                                ("task-object-category-" + (_vm.FILTERS.kebabizeLower(
                                    item.value
                                )))
                            ); }},on:{"change":_vm.onObjectCategoryChange},model:{value:(_vm.objectCategory),callback:function ($$v) {_vm.objectCategory=$$v},expression:"objectCategory"}})],1):_vm._e(),(_vm.objectCategory && _vm.objectStatus && _vm.objectCode)?_c('v-col',{attrs:{"cols":"12"}},[_c('base-autocomplete',{attrs:{"items":_vm.sizeClassItems,"label":_vm.$t('size-class-label'),"item-value":"id","clearable":"","filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [(
                                (item.range[0] === 0) &&
                                item.range[1]
                            )?_c('i18n',{attrs:{"path":"size-class-text-00","tag":"span"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(item.id))])]},proxy:true},{key:"range2",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[1]))])]},proxy:true}],null,true)}):_vm._e(),(
                                item.range[0] &&
                                item.range[1]
                            )?_c('i18n',{attrs:{"path":"size-class-text-01","tag":"span"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(item.id))])]},proxy:true},{key:"range1",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[0]))])]},proxy:true},{key:"range2",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[1]))])]},proxy:true}],null,true)}):_vm._e(),(
                                item.range[0] &&
                                !item.range[1]
                            )?_c('i18n',{attrs:{"path":"size-class-text-02","tag":"span"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(item.id))])]},proxy:true},{key:"range1",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[0]))])]},proxy:true}],null,true)}):_vm._e()]}},{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[(
                                    (item.range[0] === 0) &&
                                    item.range[1]
                                )?_c('i18n',{attrs:{"path":"size-class-text-00","tag":"span"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(item.id))])]},proxy:true},{key:"range2",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[1]))])]},proxy:true}],null,true)}):_vm._e(),(
                                    item.range[0] &&
                                    item.range[1]
                                )?_c('i18n',{attrs:{"path":"size-class-text-01","tag":"span"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(item.id))])]},proxy:true},{key:"range1",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[0]))])]},proxy:true},{key:"range2",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[1]))])]},proxy:true}],null,true)}):_vm._e(),(
                                    item.range[0] &&
                                    !item.range[1]
                                )?_c('i18n',{attrs:{"path":"size-class-text-02","tag":"span"},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(item.id))])]},proxy:true},{key:"range1",fn:function(){return [_c('b',[_vm._v(_vm._s(item.range[0]))])]},proxy:true}],null,true)}):_vm._e()],1)]}}],null,false,2320786086),model:{value:(_vm.sizeClass),callback:function ($$v) {_vm.sizeClass=$$v},expression:"sizeClass"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }