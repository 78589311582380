var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SuvaPartnerFinderSearch',{attrs:{"uid":_vm.uid},on:{"change":function($event){return _vm.$emit('change', $event)}}})],1),(_vm.formattedAddress)?_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"type":"success","dense":""}},[(_vm.suvaPartnerFinder.timestamp)?_c('div',{staticClass:"text-caption"},[_c('i18n',{attrs:{"path":"search-timestamp","tag":"span"},scopedSlots:_vm._u([{key:"timestamp",fn:function(){return [_c('base-date-time',{staticClass:"font-weight-bold",attrs:{"timestamp":_vm.suvaPartnerFinder.timestamp}})]},proxy:true},{key:"user",fn:function(){return [(
                                _vm.suvaPartnerFinder.user &&
                                _vm.suvaPartnerFinder.user.name
                            )?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.suvaPartnerFinder.user.name))]):_vm._e()]},proxy:true}],null,false,2170453103)})],1):_vm._e(),_c('i18n',{attrs:{"path":"search-msg-found","tag":"span"},scopedSlots:_vm._u([{key:"partnerNr",fn:function(){return [(_vm.suvaPartnerFinder.partnerNr)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.suvaPartnerFinder.partnerNr))]):_vm._e()]},proxy:true}],null,false,3355710390)}),_c('display-address-inline',{attrs:{"address":_vm.formattedAddress}})],1)],1):_vm._e(),(
            _vm.suvaPartnerFinder &&
            Object.keys(_vm.suvaPartnerFinder).length > 0 &&
            _vm.suvaPartnerFinder.searchState == false
        )?_c('v-col',{attrs:{"cols":"12"}},[(_vm.suvaPartnerFinder.timestamp)?_c('base-alert',{attrs:{"type":"info","dense":""}},[(_vm.suvaPartnerFinder.timestamp)?_c('div',{staticClass:"text-caption"},[_c('i18n',{attrs:{"path":"search-timestamp","tag":"span"},scopedSlots:_vm._u([{key:"timestamp",fn:function(){return [_c('base-date-time',{staticClass:"font-weight-bold",attrs:{"timestamp":_vm.suvaPartnerFinder.timestamp}})]},proxy:true},{key:"user",fn:function(){return [(
                                _vm.suvaPartnerFinder.user &&
                                _vm.suvaPartnerFinder.user.name
                            )?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.suvaPartnerFinder.user.name))]):_vm._e()]},proxy:true}],null,false,2170453103)})],1):_vm._e(),_c('i18n',{attrs:{"path":"search-msg-not-found","tag":"span"},scopedSlots:_vm._u([{key:"uid",fn:function(){return [(_vm.suvaPartnerFinder.uid)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.suvaPartnerFinder.uid))]):_vm._e()]},proxy:true}],null,false,1645442236)})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }