<template>
    <v-pagination
        v-bind="$attrs"
        v-on="$listeners"
        :circle="circle"
        :total-visible="totalVisible"
    >
        <!-- Dynamically inherit slots from parent -->
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
        </template>
        <!-- make all slots from v-text-field reusable from parent -->
        <template v-for="(_, slot) in $slots">
            <template :slot="slot">
                <slot :name="slot"></slot>
            </template>
        </template>
    </v-pagination>
</template>

<script>
export default {
    name: "BasePagination",
    props: {
        circle: {
            type: Boolean,
            default: true
        },
        totalVisible: {
            type: [Number, String],
            default: 5
        }
    }
};
</script>

