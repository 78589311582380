<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./view-list-i18n.yaml"></i18n>
<template>
    <div>
        <v-container>
            <v-container>
                <v-row justify="center">
                    <span class="center font-weight-bold title display-3">
                        {{ $t("title") }}
                    </span>
                </v-row>
            </v-container>
            <template v-if="allTasks.length === 0 && isLoading">
                <v-sheet class="px-3 pt-3 pb-3" v-for="n in 30" :key="n">
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar">
                    </v-skeleton-loader>
                </v-sheet>
            </template>
            <v-container v-if="allTasks.length === 0 && !isLoading">
                <v-row justify="center">
                    <span class="center font-weight-bold text-h6">
                        {{ $t("empty-list") }}
                    </span>
                </v-row>
                <v-row justify="center" class="pt-10">
                    <base-btn color="info" :to="{ name: 'user-dashboard' }">
                        <v-icon class="mr-2">mdi-pencil</v-icon>
                        {{ $t("create-task") }}
                    </base-btn>
                </v-row>
            </v-container>
            <TasksTable
                :items="allTasks"
                :headers="computedHeaders"
                :title="$t('title')"
                @reloadDataClick="fetchAllTasks"
                @click:action="onActionClick"
                :actions="actionsItems"
                :funcShowAction="showAction"
            >
            </TasksTable>

            <v-container fill-height fluid>
                <v-row align="center" justify="center">
                    <v-col md="8">
                        <DialogViewData
                            :value="dataDialog"
                            @input="dataDialog = $event"
                            :docId="selectedItem.docId"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <base-dialog
                :value="mailDialog"
                @input="mailDialog = $event"
                :title="$t('title-mail-dialog')"
                max-width="600"
                icon="mdi-email"
            >
                <template v-slot:content>
                    <v-container fill-height fluid>
                        <base-text-field
                            required
                            prepend-icon="mdi-email"
                            :label="$t('email-label')"
                            v-model="mailField"
                        >
                        </base-text-field>
                    </v-container>
                </template>
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <base-btn
                        :disabled="!mailField"
                        @click="sendRegistrationMail(selectedItem.docId)"
                    >
                        <v-icon class="mr-2">mdi-email-send</v-icon>
                        {{ $t("btn-send-mail") }}
                    </base-btn>
                </template>
            </base-dialog>

            <app-dev-container>
                <template v-slot:content>
                    <tree-view
                        :data="allTasks"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                </template>
            </app-dev-container>
        </v-container>
        <GlobalOverlay v-if="isOverlay" :isLoading="isOverlay" />
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DialogViewData from "@/components/tasks/common/dialog-view-data.vue";
import { getRegistrationStateColor } from "@/utils/defaults.js";
import { PROCESS_TYPES, TaskStatus } from "@/data/globals.js";
import GlobalOverlay from "@/components/general/global-overlay.vue";
import TasksTable from "@/components/backoffice/components/tasks-table.vue";

const ActionTypes = {
    VIEW: "view",
    SEND_AS_MAIL: "sendAsMail",
    DATA: "data",
    DELETE_FROM_DB: "deleteFromDB"
};
export default {
    components: {
        DialogViewData,
        GlobalOverlay,
        TasksTable
    },

    data() {
        return {
            mailDialog: false,
            dialogCreateQuestion: false,
            isOverlay: false,
            page: 1,
            itemsPerPage: 15,
            isMobile: false,
            dataDialog: false,
            selectedItem: {},
            getRegistrationStateColor: getRegistrationStateColor,
            actionsItems: [
                {
                    title: this.$t("action-view"),
                    icon: "mdi-eye",
                    action: ActionTypes.VIEW
                },
                {
                    title: this.$t("action-send-as-mail"),
                    icon: "mdi-mail",
                    action: ActionTypes.SEND_AS_MAIL
                },
                {
                    title: this.$t("action-data"),
                    icon: "mdi-code-json",
                    action: ActionTypes.DATA,
                    isDevel: true
                },
                {
                    title: this.$t("action-delete-from-db"),
                    icon: "mdi-delete",
                    action: ActionTypes.DELETE_FROM_DB,
                    isDevel: true
                }
            ],
            mailField: null,
            allTasks: []
        };
    },
    computed: {
        ...mapGetters("settings", ["developerMode"]),
        ...mapGetters("auth", ["mail"]),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        computedHeaders() {
            if (this.isMobile) {
                return this.headers.filter((header) => header.isMobile);
            }
            return this.headers;
        },
        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: true
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false
                }
            ];
        }
    },
    methods: {
        ...mapActions("tasks", ["removeTaskFromDbByDocId"]),
        onActionClick({ action, item }) {
            this.selectedItem = item;
            switch (action) {
                case ActionTypes.SEND_AS_MAIL:
                    this.mailDialog = true;
                    break;
                case ActionTypes.VIEW:
                    this.onShowRegistration(item.process, item.referenceId);
                    break;
                case ActionTypes.DATA:
                    this.dataDialog = true;
                    break;
                case ActionTypes.DELETE_FROM_DB:
                    this.deleteFromDB(item.docId);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
        onShowRegistration(process, referenceId) {
            if (process === PROCESS_TYPES.REGISTRATION) {
                this.$router.push({
                    name: "task-create",
                    params: { referenceId: referenceId }
                });
            }
            if (process === PROCESS_TYPES.MUTATION) {
                this.$toast.info("Mutation not implemented yet");
            }
            if (process === PROCESS_TYPES.DEREGISTRATION) {
                this.$toast.info("Checkout not implemented yet");
            }
        },
        sendRegistrationMail(docId) {
            if (!this.mailField) return;
            this.isOverlay = true;
            let data = {};
            data.docId = docId;
            data.mails = [this.mailField];
            data.lang = this.$i18n.locale;
            this.$axiosMail
                .post("/sendRegistrationMail", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$toast.success("Das Mail wurde versendet");
                        this.mailDialog = false;
                    }
                    this.isOverlay = false;
                })
                .catch((error) => {
                    console.log(error);
                    const errorCode = error.response.data.code || 0;
                    if (errorCode > 0) {
                        switch (errorCode) {
                            case 1:
                                this.$toast.error(
                                    this.$t("task-not-completed-yet")
                                );
                                break;
                            default:
                                this.$toast.error(error);
                                break;
                        }
                    } else {
                        this.$toast.error(error);
                    }
                    this.mailDialog = false;
                    this.isOverlay = false;
                });
        },
        decryptMail(mail) {
            return Buffer.from(mail, "base64");
        },
        fetchAllTasks() {
            this.loader().show();
            let data = {};
            data.accountDocId = this.$store.state.auth.accountDocId;
            this.$store
                .dispatch("tasks/fetchAllFromUser")
                .then((response) => {
                    const resData = response.data.data;
                    this.allTasks = resData;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        deleteFromDB(docId) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-task-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-delete-task-text", {
                    docId: docId
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.isOverlay = true;
                    this.removeRegistrationFromDbByDocId(docId)
                        .then((response) => {
                            let status = response.status;
                            if (status === 200) {
                                this.fetchAllTasks();
                                this.$toast.success(
                                    `All DOCS with docId ${docId} were successuflly deleted from DB`
                                );
                            }
                            this.isOverlay = false;
                        })
                        .catch((error) => {
                            this.$toast.error(error);
                            console.log(error);
                            this.isOverlay = false;
                        });
                }
            });
        },
        showAction(item, actionItem) {
            const itemState = item.state;
            const itemProcessType = item.process;
            const action = actionItem.action;
            if (item.deletedState > 0) return false;
            if (actionItem.action === ActionTypes.DELETE_FROM_DB) {
                if (itemState >= TaskStatus.TASK_CONFIRMED) return false;
            }
            if (action == ActionTypes.SEND_AS_MAIL) {
                if (itemProcessType !== PROCESS_TYPES.REGISTRATION) {
                    return false;
                }
            }

            return true;
        }
    },
    watch: {
        isAuth: {
            handler: function (auth) {
                if (auth) {
                    this.fetchAllTasks();
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true
            });
        }
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    created() {
        if (this.isAuth) {
            this.fetchAllTasks();
            const mail = this.decryptMail(this.mail);
            if (mail) {
                this.mailField = mail;
            }
        }
    }
};
</script>
