<i18n src="./summary-address-operator-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-operator-address')" :expandable="expandable">
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li
                    v-if="
                        task.operatorAddress &&
                        !checkIfAddressIsEmpty(task.operatorAddress)
                    "
                >
                    <DisplayAddressInline
                        :address="task.operatorAddress"
                        :show-copy-btn="showCopyBtn"
                    />
                </li>
                <li v-else>
                    <span class="font-italic">{{
                        $t("not-recorded-yet")
                    }}</span>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import Helpers from "@/utils/helpers";
import mixin from "../mixin";
export default {
    name: "SummaryAddressOperator",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue"),
        DisplayAddressInline: () =>
            import("@/components/general/display-address-inline.vue")
    },
    methods: {
        checkIfAddressIsEmpty(address) {
            return Helpers.checkIfAddressIsEmpty(address);
        }
    }
};
</script>
