// router/componentHooks.ts

import Component from "vue-class-component";

// Register the router hooks with their names
Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate"
]);
