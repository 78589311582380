<i18n src="./Modal-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="title"
        max-width="360"
        icon="mdi-keyboard"
        @keydown="onDlgKeydown"
    >
        <template v-slot:content>
            <v-card class="mx-auto" width="100%" flat>
                <v-card-text>
                    <v-text-field
                        outlined
                        readonly
                        v-model="fieldValue"
                        id="txtBox"
                        :placeholder="
                            fieldPlaceholder
                                ? fieldPlaceholder
                                : $t('field-placeholder')
                        "
                        :hint="fieldHint ? fieldHint : undefined"
                        :persistent-hint="fieldHint ? true : false"
                        class="px-1"
                        autofocus
                    />
                    <Keyboard
                        ref="keyboard"
                        @pressed="fieldValue = $event"
                        :value="fieldValue"
                        :allowNegative="allowNegative"
                    >
                    </Keyboard>
                    <v-divider />
                </v-card-text>
                <v-card-actions class="pl-6 pr-4">
                    <v-spacer />
                    <base-btn color="info" block @click="onSave" height="45">
                        <span v-if="btnSaveText">{{ btnSaveText }}</span>
                        <span v-else>{{ $t("btn-save-text") }}</span>
                        <v-spacer />
                        <v-icon v-html="btnSaveIcon"></v-icon>
                    </base-btn>
                    <slot name="actions"></slot>
                </v-card-actions>
            </v-card>
        </template>
    </base-dialog>
</template>
<script>
import Keyboard from "./Keyboard.vue";

export default {
    name: "VirtualKeyboardModal",
    components: { Keyboard },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        keyboardType: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: ""
        },
        fieldPlaceholder: {
            type: String,
            default: null
        },
        fieldHint: {
            type: String,
            default: null
        },
        btnSaveText: {
            type: String,
            default: null
        },
        btnSaveIcon: {
            type: String,
            default: "mdi-check-bold"
        },
        allowNegative: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        fieldValue: "",
        dialog: false
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        onSave() {
            this.$emit("save", this.fieldValue);
            this.closeDialog();
        },
        onDlgKeydown(e) {
            if (e.keyCode === 13) {
                this.onSave();
            } else {
                return this.$refs.keyboard.onKeydown(e);
            }
        }
    },
    watch: {
        show: {
            handler: function (newValue) {
                if (newValue == true) {
                    this.$nextTick(() => {
                        this.$refs.keyboard.show(this.keyboardType);
                    });
                    this.fieldValue = this.value;
                }
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
