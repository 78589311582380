import { validationMixin } from "vuelidate";
export default {
    mixins: [validationMixin],
    data: () => ({
        _scrollOptions: {
            duration: 500,
            offset: 300,
            easing: "easeInOutCubic"
        }
    }),
    methods: {
        getInvalidFields() {
            const params = this.$v?.$params;
            if (params) {
                let keys = Object.keys(params);
                if (keys.length) {
                    return keys.filter(
                        (fieldName) => this.$v[fieldName]?.$invalid
                    );
                }
            }
            return [];
        },
        scrollToFirstErrorIfInvalidFieldsFound(scrollOptions) {
            let invalidFields = this.getInvalidFields();
            if (invalidFields.length > 0) {
                const selector = ".v-messages.error--text";
                const querySelector = document.querySelector(selector);
                if (querySelector) {
                    this.$vuetify.goTo(querySelector, scrollOptions);
                } else {
                    console.log(`Element: "${selector}" DOES NOT exist!`);
                }
            }
        },
        validate() {
            this.$v.$touch();
        },
        scrollToFirstError(scrollOptions = {}) {
            let _scrollOptions = {
                ...scrollOptions,
                ...this._scrollOptions,
                ...this.extraScrollOptions
            };
            this.$nextTick(() => {
                return this.scrollToFirstErrorIfInvalidFieldsFound(
                    _scrollOptions
                );
            });
        },
        isValid(scrollOptions) {
            if (!this.$v.$anyDirty) {
                return null;
            } else {
                if (this.$v.$anyError) {
                    this.scrollToFirstError(scrollOptions);
                    return false;
                } else {
                    return true;
                }
            }
        },
        reset() {
            this.$v.$reset();
        }
    }
};
