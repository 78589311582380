<i18n src="./dialog-attachments-i18n.yaml"></i18n>
<template>
    <ModalMovableDialog
        :toolbarTitle="$t('title')"
        :value="dialog"
        @input="$emit('input', $event)"
        toolbarIcon="mdi-file-multiple"
        clsCardContent="pt-5"
        :clsCardActions="$vuetify.breakpoint.mdAndUp ? 'pl-12 pr-6' : null"
    >
        <template v-slot:content>
            <multimedia-viewer
                v-if="Object.keys(attachments).length > 0"
                :attachments="attachments"
                thumbnailWidth="100px"
                thumbnailMaxWidth="100px"
                thumbnailHeight="100px"
                :docId="taskDocId"
                showBtnDownloadAsZip
                :readonlyModus="readonlyModus"
                :deletable="!readonlyModus"
                v-on:attachment-deleted="$emit('attachment:delete', $event)"
                v-on:delete-multiple-attachments="
                    $emit('attachment:delete-multiple', $event)
                "
            >
            </multimedia-viewer>
            <v-container v-else>
                <v-layout
                    align-center
                    justify-center
                    column
                    fill-height
                    class="pb-2"
                >
                    <v-flex row align-center>
                        <v-icon
                            :size="$vuetify.breakpoint.smAndDown ? 60 : 100"
                        >
                            mdi-image-search
                        </v-icon>
                    </v-flex>
                    <v-flex row align-center class="pt-5">
                        <div
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-h6'
                                    : 'text-h4'
                            "
                        >
                            {{ $t("no-attachments-found") }}
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </template>
    </ModalMovableDialog>
</template>
<script>
import MultimediaViewer from "@/components/general/multimedia-viewer.vue";
import ModalMovableDialog from "@/components/general/modal-movable-dialog.vue";
export default {
    name: "DialogAttachments",
    components: {
        MultimediaViewer,
        ModalMovableDialog
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        registration: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        attachments: {
            type: Object,
            default: () => {}
        },
        taskDocId: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dialog: false
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
