<i18n src="./navigation-footer-i18n.yaml"></i18n>
<template>
    <v-container fluid class="pa-0 ma-0">
        <v-layout row>
            <WLanguageMenu v-if="$vuetify.breakpoint.smAndDown" type="list" />
        </v-layout>
        <v-layout class="px-4 pb-2" row>
            <base-btn
                v-if="$vuetify.breakpoint.mobile"
                icon
                large
                @click="onFullScreenClick"
            >
                <v-icon
                    v-html="
                        fullScreenMode
                            ? 'mdi-fullscreen-exit'
                            : 'mdi-fullscreen'
                    "
                ></v-icon>
            </base-btn>
        </v-layout>
        <v-layout class="pb-4" align-center justify-center row v-if="isAuth">
            <base-btn block @click="$emit('logout')" type="primary">
                <v-icon>mdi-logout</v-icon>
                {{ $t("logout") }}
            </base-btn>
        </v-layout>
        <v-layout align-center justify-center>
            <span class="caption"
                >{{ $t("company-name") }} &copy;
                {{ new Date().getFullYear() }}</span
            >
        </v-layout>
        <v-layout align-center justify-center>
            <span class="caption">V. {{ appVersion }}</span>
        </v-layout>
    </v-container>
</template>
<script>
import WLanguageMenu from "@/components/fields/WLanguageMenu.vue";
import { mapGetters } from "vuex";
import Utils from "@/utils";
export default {
    name: "AppNavigationFooter",
    components: {
        WLanguageMenu
    },
    data: () => ({
        fullScreenMode:
            document.fullScreen ||
            document.mozFullScreen ||
            document.webkitIsFullScreen
    }),
    methods: {
        onFullScreenClick() {
            Utils.toggleFullScreen();
        },
        fullscreenChange() {
            if (document.fullscreenElement != null) {
                this.fullScreenMode = true;
            } else {
                this.fullScreenMode = false;
            }
        }
    },
    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION;
        },
        ...mapGetters({
            isAuth: "auth/isAuthenticated"
        })
    },
    created() {
        window.addEventListener("mozfullscreenchange", this.fullscreenChange);
        window.addEventListener(
            "webkitfullscreenchange",
            this.fullscreenChange
        );
        window.addEventListener("fullscreenchange", this.fullscreenChange);
    },
    destroyed() {
        window.removeEventListener(
            "mozfullscreenchange",
            this.fullscreenChange
        );
        window.removeEventListener(
            "webkitfullscreenchange",
            this.fullscreenChange
        );
        window.removeEventListener("fullscreenchange", this.fullscreenChange);
    }
};
</script>
