<i18n src="./address-copy-operator-i18n.yaml"></i18n>
<template>
    <div>
        <app-dev-container>
            <template v-slot:content>
                @Task:
                <tree-view :data="task" :options="{ maxDepth: 0 }"></tree-view>
                @Address:
                <tree-view
                    :data="Object.assign({}, address)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
                @operatorAddressIsRecorded: {{ operatorAddressIsRecorded }}
            </template>
        </app-dev-container>
        <StepContentTitle :title="$t('title')" />
        <v-container
            :class="$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pa-0 ma-0'"
            fluid
            v-if="(address && Object.keys(address).length > 0) || address"
        >
            <v-form :readonly="readonlyModus" :disabled="disabled">
                <v-row>
                    <v-col>
                        <base-radio-group
                            :label="
                                typeOfAddress
                                    ? $t(
                                          `label-same-address-as-operator-${typeOfAddress}`
                                      )
                                    : undefined
                            "
                            :required="isRequiredField($v.sameAddressAs)"
                            :class="
                                $vuetify.breakpoint.xsOnly ? undefined : 'pl-2'
                            "
                            v-model="sameAddressAs"
                            :error-messages="sameAddressAsErrors"
                            @change="onChangeAddressSameAs"
                        >
                            <v-radio
                                v-for="radio in radioItems"
                                :key="radio.key"
                                :label="radio.label"
                                :value="radio.value"
                                :disabled="radio.disabled"
                            >
                            </v-radio>
                        </base-radio-group>
                        <v-container
                            v-if="showSameAddressAs"
                            fluid
                            style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
                            class="pa-0 ma-0 pb-2 ml-12"
                        >
                            <v-card
                                class="py-0"
                                tile
                                flat
                                max-width="400"
                                color="transparent"
                            >
                                <v-card-text class="pt-0 my-0">
                                    <display-address
                                        :address="sameAddressAsAddress"
                                    >
                                    </display-address>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <v-container
            fluid
            v-if="sameAddressAs === SAME_AS_TYPES.CREATE_NEW_ADDRESS"
        >
            <v-row dense>
                <v-col class="pb-6 pl-3">
                    <AddressCopyBtn
                        :task="task"
                        :allowedCopyItems="allowedCopyItems"
                        v-on="$listeners"
                        :disabled="disabled"
                        :readonly="readonlyModus"
                    />
                    <base-btn
                        class="ml-2"
                        type="secondary"
                        btnIcon="mdi-notebook-outline"
                        color="primary"
                        @click="modalAddressBook = true"
                    >
                        <span v-if="!isMobileBreakpoint">{{
                            $t("title-adressbook")
                        }}</span>
                    </base-btn>
                </v-col>
            </v-row>
            <AddressForm
            :dense="$vuetify.breakpoint.xsOnly ? true : false"
                :value.sync="address"
                :outlined="false"
                ref="addressForm"
                :disabled="disabled"
                :readonly="readonlyModus"
                @input:field="onFieldChange"
                @change="onChangeAddress"
                @clear="onClear(typeOfAddress)"
            />
        </v-container>
        <ModalAddressBook
            v-model="modalAddressBook"
            @select:address="onSelectAddressFromAddressBook"
        ></ModalAddressBook>
        <slot></slot>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
import { ADDRESS_TYPES } from "@/data/globals.js";
import { SAME_AS_TYPES } from "./same-as-types.js";
import _ from "lodash";
export default {
    name: "AddressCopyOperator",
    mixins: [validateMixin],

    components: {
        DisplayAddress: () =>
            import("@/components/general/display-address.vue"),
        AddressForm: () =>
            import("@/components/tasks/edit/common/forms/address/address.vue"),
        StepContentTitle: () => import("./step-content-title.vue"),
        AddressCopyBtn: () => import("./address-copy-btn.vue"),
        ModalAddressBook: () =>
            import("@/components/user-dashboard/modal-address-book.vue")
    },

    validations: {
        sameAddressAs: { required }
    },

    props: {
        address: {
            type: Object,
            default: () => {}
        },
        task: {
            type: Object,
            default: () => {}
        },
        typeOfAddress: {
            type: String,
            default: null
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowedCopyItems: {
            type: Array,
            default: () => []
        },
        allowedSameAsItems: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        SAME_AS_TYPES,
        sameAddressAs: null,
        sameAddressAsAddress: {},
        operatorAddress: {},
        billingAddress: {},
        ownerAddress: {},
        modalAddressBook: false
    }),

    computed: {
        isMobileBreakpoint() {
            return this.$vuetify?.breakpoint?.mobile;
        },
        showSameAddressAs() {
            return (
                this.sameAddressAs !== null &&
                this.sameAddressAs !== SAME_AS_TYPES.CREATE_NEW_ADDRESS &&
                this.sameAddressAsAddress
            );
        },
        sameAddressAsErrors() {
            const errors = [];
            if (!this.$v.sameAddressAs.$dirty) {
                return errors;
            }
            if (!this.$v.sameAddressAs.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        operatorAddressIsRecorded() {
            return this.isAddressRecorded(this.operatorAddress);
        },
        billingAddressIsRecorded() {
            return this.isAddressRecorded(this.billingAddress);
        },
        ownerAddressIsRecorded() {
            return this.isAddressRecorded(this.ownerAddress);
        },
        radioItems() {
            let items = [];
            if (this.isSameAsAddressAllowed(SAME_AS_TYPES.SAME_AS_OPERATOR)) {
                items.push({
                    key: SAME_AS_TYPES.SAME_AS_OPERATOR,
                    value: SAME_AS_TYPES.SAME_AS_OPERATOR,
                    label: this.$t("same-address-as-operator"),
                    disabled: !this.operatorAddressIsRecorded
                });
            }
            if (this.isSameAsAddressAllowed(SAME_AS_TYPES.SAME_AS_BILLING)) {
                items.push({
                    key: SAME_AS_TYPES.SAME_AS_BILLING,
                    value: SAME_AS_TYPES.SAME_AS_BILLING,
                    label: this.$t("same-address-as-billing"),
                    disabled: !this.billingAddressIsRecorded
                });
            }
            if (this.isSameAsAddressAllowed(SAME_AS_TYPES.SAME_AS_OWNER)) {
                items.push({
                    key: SAME_AS_TYPES.SAME_AS_OWNER,
                    value: SAME_AS_TYPES.SAME_AS_OWNER,
                    label: this.$t("same-address-as-owner"),
                    disabled: !this.ownerAddressIsRecorded
                });
            }
            if (this.isSameAsAddressAllowed(SAME_AS_TYPES.CREATE_NEW_ADDRESS)) {
                items.push({
                    key: SAME_AS_TYPES.CREATE_NEW_ADDRESS,
                    value: SAME_AS_TYPES.CREATE_NEW_ADDRESS,
                    label: this.$t("same-address-as-create-address")
                });
            }
            return items;
        }
    },

    methods: {
        isSameAsAddressAllowed(addressType) {
            return this.allowedSameAsItems.includes(addressType);
        },
        isAddressRecorded(address) {
            if (address && address instanceof Object) {
                if (
                    (this.isValidAddressCompany(address) ||
                        this.isValidAddressPrivateCompany(address)) &&
                    this.isValidAddress(address)
                ) {
                    return true;
                }
            }
            return false;
        },
        onFieldChange({ field, value }) {
            this.$emit("change:field", { field, value });
        },
        validate() {
            this.$v.$touch();
            if (this.sameAddressAs === SAME_AS_TYPES.CREATE_NEW_ADDRESS) {
                if (this.$refs.addressForm) {
                    this.$refs.addressForm.validate();
                }
            }
        },
        reset() {
            this.sameAddressAsAddress = {};
            this.$emit("reset");
        },
        onClearAddress({ field, address }) {
            address.sameAddressAsOperator = false;
            address.sameAddressAsBilling = false;
            address.sameAddressAsOwner = false;
            this.$emit("clear:address", { field, address });
        },
        onClear(typeOfAddress) {
            this.$emit("clear", { field: typeOfAddress });
        },
        isValid() {
            if (this.sameAddressAs !== SAME_AS_TYPES.CREATE_NEW_ADDRESS) {
                return true;
            }
            if (this.$refs.addressForm) {
                return this.$refs.addressForm.isValid();
            }
            return null;
        },
        onChangeAddressSameAsOperator(val) {
            this.onFieldChange({
                field: "sameAddressAsOperator",
                value: val
            });
        },
        onChangeAddressSameAsBilling(val) {
            this.onFieldChange({
                field: "sameAddressAsBilling",
                value: val
            });
        },
        onChangeAddressSameAsOnwer(val) {
            this.onFieldChange({
                field: "sameAddressAsOwner",
                value: val
            });
        },
        onChangeAddressSameAs(val) {
            switch (val) {
                case SAME_AS_TYPES.SAME_AS_OPERATOR:
                    this.onChangeAddressSameAsOperator(true);
                    this.onChangeAddressSameAsBilling(false);
                    this.onChangeAddressSameAsOnwer(false);
                    this.sameAddressAsAddress = this.operatorAddress;
                    break;
                case SAME_AS_TYPES.SAME_AS_BILLING:
                    this.onChangeAddressSameAsBilling(true);
                    this.onChangeAddressSameAsOperator(false);
                    this.onChangeAddressSameAsOnwer(false);
                    this.sameAddressAsAddress = this.billingAddress;
                    break;
                case SAME_AS_TYPES.SAME_AS_OWNER:
                    this.onChangeAddressSameAsOnwer(true);
                    this.onChangeAddressSameAsBilling(false);
                    this.onChangeAddressSameAsOperator(false);
                    this.sameAddressAsAddress = this.ownerAddress;
                    break;
                default:
                    this.sameAddressAsAddress = {};
                    this.reset();
                    break;
            }
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                if (!this.sameAddressAsOperator) {
                    if (this.$refs.addressForm) {
                        this.$refs.addressForm.scrollToFirstError();
                    }
                }
            });
        },
        isValidAddressCompany(address) {
            return (
                address?.addressType === ADDRESS_TYPES.COMPANY &&
                address?.companyName
            );
        },
        isValidAddressPrivateCompany(address) {
            return (
                address?.addressType === ADDRESS_TYPES.PRIVATE &&
                address?.salutation &&
                address?.firstName &&
                address?.familyName
            );
        },
        isValidAddress(address) {
            return address?.street && address?.zipCode && address?.city;
        },
        setData(data) {
            if (data.sameAddressAsOperator) {
                this.sameAddressAs = SAME_AS_TYPES.SAME_AS_OPERATOR;
            } else if (data.sameAddressAsBilling) {
                this.sameAddressAs = SAME_AS_TYPES.SAME_AS_BILLING;
            } else if (data.sameAddressAsOwner) {
                this.sameAddressAs = SAME_AS_TYPES.SAME_AS_OWNER;
            } else {
                if (data.country) {
                    this.sameAddressAs = SAME_AS_TYPES.CREATE_NEW_ADDRESS;
                }
            }
        },
        onSelectAddressFromAddressBook(address) {
            this.onClear(this.typeOfAddress);
            this.onChangeAddress(address);
            this.modalAddressBook = false;
        },
        onChangeAddress(address) {
            this.$emit("change:address", address);
        }
    },

    watch: {
        address: {
            handler: function (newData) {
                if (newData) {
                    this.setData(newData);
                }
            },
            immediate: true
        },
        task: {
            handler: function (newData) {
                if (newData) {
                    this.operatorAddress = Object.assign(
                        {},
                        _.clone(newData.operatorAddress)
                    );
                    this.billingAddress = _.clone(newData.billingAddress);
                    this.ownerAddress = _.clone(newData.ownerAddress);
                }
            },
            immediate: true,
            deep: true
        },
        sameAddressAs: {
            handler: function (newData) {
                switch (newData) {
                    case SAME_AS_TYPES.SAME_AS_OPERATOR:
                        this.sameAddressAsAddress = this.operatorAddress;
                        break;
                    case SAME_AS_TYPES.SAME_AS_BILLING:
                        this.sameAddressAsAddress = this.billingAddress;
                        break;
                    case SAME_AS_TYPES.SAME_AS_OWNER:
                        this.sameAddressAsAddress = this.ownerAddress;
                        break;
                    default:
                        this.sameAddressAsAddress = {};
                        break;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
