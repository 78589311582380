var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.address)?_c('div',[_c('div',{staticClass:"subtitle-1"},[_c('div',{staticClass:"ml-3"},[(_vm.showSameAsOperator && _vm.address.sameAddressAsOperator)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("same-address-as-operator"))+" ")]):_vm._e(),(_vm.address.uidFormatted)?_c('div',[_vm._v(" "+_vm._s(_vm.address.uidFormatted)+" ")]):_vm._e(),(_vm.address.addressType === _vm.ADDRESS_TYPES.COMPANY)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.address.companyName)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.address.department)+" ")])]):_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.address.firstName)+" "+_vm._s(_vm.address.familyName)+" ")])]),(_vm.address.additionalAddressLines)?_c('div',[(_vm.address.addressLine1)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("additional-address-lines-prefix"))+" "+_vm._s(_vm.address.addressLine1)+" ")]):_vm._e(),(_vm.address.addressLine2)?_c('div',[_vm._v(" "+_vm._s(_vm.address.addressLine2)+" ")]):_vm._e()]):_vm._e()]),(_vm.address.street)?_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.link = _vm.openGoogleMap(_vm.address);
                                _vm.dialogExternalLink = true;}}},[_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-google-maps ")])],1),_c('span',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.address.street)+" "),(_vm.address.houseNr)?_c('span',[_vm._v(" "+_vm._s(_vm.address.houseNr)+" ")]):_vm._e()])],1)]}}],null,false,1297421153)},[_c('div',[_vm._v(_vm._s(_vm.$t("open-google-maps")))])])],1):_vm._e(),_c('div',{staticClass:"ml-10"},[_vm._v(" "+_vm._s(_vm.address.country)+"-"+_vm._s(_vm.address.zipCode)+" "+_vm._s(_vm.address.city)+" "),(_vm.address.canton)?_c('span',[_vm._v("("+_vm._s(_vm.address.canton)+")")]):_vm._e()])]),(!_vm.isComminicationIsEmpty(_vm.address.communication))?_c('div',{staticClass:"subtitle-1 pt-2"},[(_vm.address.communication.phone)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","href":'tel:' + _vm.address.communication.phone}},[_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-phone ")])],1),_vm._v(" "+_vm._s(_vm.address.communication.phone)+" ")],1)]}}],null,false,3974100019)},[_c('div',[_vm._v(_vm._s(_vm.$t("communication-phone")))])]):_vm._e(),(_vm.address.communication.fax)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('div',[_c('v-icon',_vm._g({staticClass:"pa-2",attrs:{"small":""}},on),[_vm._v(" mdi-fax ")]),_vm._v(" "+_vm._s(_vm.address.communication.fax)+" ")],1)]}}],null,false,2439979002)},[_c('div',[_vm._v(_vm._s(_vm.$t("communication-fax")))])]):_vm._e(),(_vm.address.communication.email)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","href":'mailto:' + _vm.address.communication.email}},[_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-email ")])],1),_vm._v(" "+_vm._s(_vm.address.communication.email)+" ")],1)]}}],null,false,2185992780)},[_c('div',[_vm._v(_vm._s(_vm.$t("communication-email")))])]):_vm._e()],1):_vm._e(),(_vm.dialogExternalLink && _vm.link)?_c('DialogExternalLink',{staticClass:"py-3",attrs:{"value":_vm.dialogExternalLink,"link":_vm.link.url},on:{"input":function($event){_vm.dialogExternalLink = $event}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }