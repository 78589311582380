<i18n src="./form-address-private-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, computedAddress)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-check-circle</v-icon>
                </base-btn>
                <base-btn icon color="error" @click="reset">
                    <v-icon>mdi-lock-reset</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="setFakeData">
                    <v-icon>mdi-account</v-icon>
                </base-btn>
                <base-btn icon color="error" @click="clear">
                    <v-icon>mdi-delete</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <v-row dense>
            <v-col cols="12" md="2">
                <base-select
                    :items="salutationItems"
                    :value="computedAddress.salutation"
                    @input="emitOnFieldInput('salutation', $event)"
                    :label="$t('salutation-label')"
                    item-value="key"
                    :item-text="(item) => $t(item.value.title[$i18n.locale])"
                    :error-messages="salutationErrors"
                    :required="isRequiredField($v.computedAddress.salutation)"
                >
                </base-select>
            </v-col>
            <v-col cols="12" md="5">
                <!-- firstName -->
                <base-text-field
                    :value="computedAddress.firstName"
                    @input="emitOnFieldInput('firstName', $event)"
                    :label="$t('first-name-label')"
                    :error-messages="firstNameErrors"
                    :counter="
                        $v.computedAddress.firstName.$params.maxLength.max
                    "
                    :required="isRequiredField($v.computedAddress.firstName)"
                ></base-text-field>
            </v-col>
            <v-col cols="12" md="5">
                <!-- familyName -->
                <base-text-field
                    :value="computedAddress.familyName"
                    @input="emitOnFieldInput('familyName', $event)"
                    :label="$t('last-name-label')"
                    :error-messages="familyNameErrors"
                    :counter="
                        $v.computedAddress.familyName.$params.maxLength.max
                    "
                    :required="isRequiredField($v.computedAddress.familyName)"
                ></base-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import validateMixin from "@/mixins/validate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { PrivateAddress } from "@/models/tasks";
import mixin from "./mixin";
export default {
    name: "FormAddressCompany",
    mixins: [validateMixin, mixin],

    props: {
        value: {
            type: Object,
            default: () => new PrivateAddress()
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    validations() {
        return {
            computedAddress: {
                salutation: {
                    required
                },
                firstName: {
                    required,
                    maxLength: maxLength(40)
                },
                familyName: {
                    required,
                    maxLength: maxLength(40)
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            salutationItems: "attributes/salutations"
        }),
        salutationErrors() {
            const errors = [];
            if (!this.$v.computedAddress.salutation.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.salutation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        firstNameErrors() {
            const errors = [];
            if (!this.$v.computedAddress.firstName.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.firstName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.computedAddress.firstName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.firstName.$params.maxLength
                                .max
                    })
                );
            }
            return errors;
        },
        familyNameErrors() {
            const errors = [];
            if (!this.$v.computedAddress.familyName.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.familyName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.computedAddress.familyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedAddress.familyName.$params.maxLength
                                .max
                    })
                );
            }
            return errors;
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        clear() {
            this.emitOnFieldInput("salutation", null);
            this.emitOnFieldInput("firstName", null);
            this.emitOnFieldInput("familyName", null);
        },
        setFakeData() {
            this.emitOnFieldInput("salutation", "0002");
            this.emitOnFieldInput("firstName", "Joe");
            this.emitOnFieldInput("familyName", "Doe");
        }
    },
    created() {
        this.loader().show();
        let salutationsKey = "salutations";
        this.getStoredAttributesByKey(salutationsKey)
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    "Laden der Daten: " + salutationsKey + " war nicht möglich"
                );
            });
    }
};
</script>
