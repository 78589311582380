<i18n src="./content-i18n.yaml"></i18n>
<template>
    <base-card>
        <BackofficeBaseList
            :items="items"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            @reloadDataClick="reload"
            item-key="docId"
            @action:sap-export="onSapExport"
        >
        </BackofficeBaseList>
        <DialogSapExport
            v-model="dialogSapExport"
            :task="selectedTask"
            @save:success="onSaveSuccess"
        />
    </base-card>
</template>
<script>
import { mapState } from "vuex";
import taskList from "@/mixins/backoffice/taskList";
import { TaskActionTypes } from "@/data/task-action-types";
export default {
    name: "BackofficeTasksBoReadyForSapExportContent",
    mixins: [taskList],
    components: {
        DialogSapExport: () =>
            import("@/components/backoffice/components/dialog-sap-export.vue"),
        BackofficeBaseList: () =>
            import("@/components/backoffice/components/base-list.vue")
    },
    data() {
        return {
            dataDialog: false,
            actionsItems: [
                TaskActionTypes.VIEW,
                TaskActionTypes.DATA,
                TaskActionTypes.SAP_EXPORT,
                TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP,
                TaskActionTypes.VIEW_HISTORY
            ],
            dialogSapExport: false,
            selectedTask: {}
        };
    },
    computed: {
        ...mapState("backoffice", {
            items: "boReadyForSapExport"
        }),

        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-employee"),
                    value: "boEmployee",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-backoffice-complete-date"),
                    value: "backofficeCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        }
    },
    methods: {
        fetchTasks() {
            this.loader().show();
            this.$store
                .dispatch("backoffice/fetchBoTasksReadyForSapExport")
                .then(() => {
                    this.loader().hide();
                })
                .catch(() => {
                    this.loader().hide();
                });
        },
        onSapExport(task) {
            this.selectedTask = task;
            this.dialogSapExport = true;
        },
        reload() {
            return this.fetchTasks();
        },
        onSaveSuccess() {
            this.$router.push({
                name: "backoffice-task-view",
                params: { referenceId: this.selectedTask.referenceId }
            });
        }
    },
    created() {
        if (this.isAuth) {
            this.fetchTasks();
        }
    }
};
</script>
