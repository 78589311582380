<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./summary-backoffice-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-backoffice')" :expandable="expandable">
        <template v-slot:content v-if="backoffice">
            <ul class="text-subtitle-2 primary--text">
                <li v-if="backoffice.duplicateDecision">
                    <app-key-value-text-inline
                        :keyText="$t('task-duplicate-decision-label')"
                        :valueText="
                            $translateKebablizerLower(
                                `task-duplicate-decision-type-${backoffice.duplicateDecision}`
                            )
                        "
                    />
                </li>
                <li v-if="backoffice.sapEquipmentNr">
                    <app-key-value-text-inline
                        :keyText="$t('task-sap-equipment-nr-label')"
                        :valueText="backoffice.sapEquipmentNr"
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="backoffice.sapEquipmentNr"
                    />
                </li>
                <li v-if="backoffice.reportingToSuva !== null">
                    <app-key-value-text-inline
                        :keyText="$t('task-reporting-to-suva-label')"
                        :valueText="
                            backoffice.reportingToSuva
                                ? $t('yes-label')
                                : $t('no-label')
                        "
                    />
                </li>
                <li v-if="backoffice.objectStatus">
                    <app-key-value-text-inline
                        :keyText="$t('task-object-status-label')"
                        :valueText="`${objectStatusTranslatedItem} (${backoffice.objectStatus})`"
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="backoffice.objectStatus"
                    />
                </li>
                <li v-if="sizeClassItem">
                    <app-key-value-text-inline
                        v-if="sizeClassItem"
                        :keyText="$t('task-size-class-label')"
                        :valueText="
                            sizeClassItem.range.length === 1
                                ? $t('size-class-text-start', {
                                      value: backoffice.sizeClass,
                                      range1: sizeClassItem.range[0]
                                  })
                                : (
                                    sizeClassItem.range[0] === 0
                                    ? $t('size-class-text-upto', {
                                          value: backoffice.sizeClass,
                                          range2: sizeClassItem.range[1]
                                      })
                                    : $t('size-class-text-range', {
                                          value: backoffice.sizeClass,
                                          range1: sizeClassItem.range[0],
                                          range2: sizeClassItem.range[1]
                                      })
                                  )
                        "
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="backoffice.sizeClass"
                    />
                </li>
                <li v-if="backoffice.objectCode">
                    <app-key-value-text-inline
                        :keyText="$t('task-object-code-label')"
                        :valueText="objectCodeTranslatedItem"
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="objectCodeTranslatedItem"
                    />
                </li>
                <li v-if="backoffice.objectCategory">
                    <app-key-value-text-inline
                        :keyText="$t('task-object-category-label')"
                        :valueText="
                            $translateKebablizerLower(
                                `task-object-category-${backoffice.objectCategory}`
                            )
                        "
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="
                            `task-object-category-${backoffice.objectCategory}`
                                | kebabizeLower
                        "
                    />
                </li>
                <li v-if="backoffice.regionCode">
                    <app-key-value-text-inline
                        :keyText="$t('task-region-code-label')"
                        :valueText="backoffice.regionCode"
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="backoffice.regionCode"
                    />
                </li>
                <li v-if="backoffice.subCircleCode">
                    <app-key-value-text-inline
                        :keyText="$t('task-sub-circle-code-label')"
                        :valueText="backoffice.subCircleCode"
                    />
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="backoffice.subCircleCode"
                    />
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import { mapActions } from "vuex";
import mixin from "../mixin";
import { SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY } from "@/data/globals.js";
export default {
    name: "SummaryBackoffice",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    data: () => ({
        objectStatusItems: [],
        objectCodeItems: []
    }),
    computed: {
        backoffice() {
            return this.task.backoffice;
        },
        objectStatus() {
            return this.task?.backoffice?.objectStatus;
        },
        objectCategory() {
            return this.task?.backoffice?.objectCategory;
        },
        objectCode() {
            return this.task?.backoffice?.objectCode;
        },
        objectStatusTranslatedItem() {
            if (this.objectStatusItems.length === 0 || !this.objectStatus)
                return null;
            const sorted = this.objectStatusItems.filter(
                (item) => item.key === this.objectStatus
            );
            const locale = this.$root.$i18n.locale || "de";
            if (sorted.length != 1) return null;
            const item = sorted[0];
            if ("value" in item) {
                return item.value[locale];
            }
            return null;
        },
        objectCodeTranslatedItem() {
            if (this.objectStatusItems.length === 0 || !this.objectCode)
                return null;
            const sorted = this.objectCodeItems.filter(
                (item) => item.key === this.objectCode
            );
            const locale = this.$root.$i18n.locale || "de";
            if (sorted.length != 1) return null;
            return sorted[0]?.value[locale];
        },
        sizeClassItems() {
            return SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY(this.objectCategory);
        },
        sizeClassItem() {
            if (!this.sizeClassItems) return null;
            if (!this.backoffice?.sizeClass) return null;
            let filteredSizeClassItems = this.sizeClassItems.filter(
                (item) => item.id === this.backoffice.sizeClass
            );
            if (filteredSizeClassItems.length === 1) {
                return filteredSizeClassItems[0];
            }
            return null;
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        fetchObjectStatusItems() {
            this.loader().show();
            let objectStatusKey = "objektstatus";
            this.getStoredAttributesByKey(objectStatusKey)
                .then((items) => {
                    this.objectStatusItems = items;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("attributes-load-error", {
                            attributeName: objectStatusKey
                        })
                    );
                });
        },
        fetchObjectCodeItems() {
            let objectCodeKey = "objektcode";
            this.loader().show();
            this.getStoredAttributesByKey(objectCodeKey)
                .then((items) => {
                    this.objectCodeItems = items;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("object-code")
                        })
                    );
                });
        }
    },
    mounted() {
        if (this.objectStatus) {
            this.fetchObjectStatusItems();
        }
        if (this.objectCode) {
            this.fetchObjectCodeItems();
        }
    }
};
</script>
