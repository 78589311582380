import { render, staticRenderFns } from "./WCountrySelect.vue?vue&type=template&id=b4a9f9dc&"
import script from "./WCountrySelect.vue?vue&type=script&lang=js&"
export * from "./WCountrySelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/data/countries/i18n/all.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ffields%2FWCountrySelect.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./WCountrySelect-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ffields%2FWCountrySelect.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VListItem,VListItemAction,VListItemContent,VListItemTitle})
