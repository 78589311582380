<i18n       src="./company-address-i18n.yaml"></i18n>
<template>
    <v-form>
        <app-dev-container class="my-6">
            <template v-slot:content>
                <v-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </v-btn>
                <v-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn icon color="info" @click="validate">
                    <v-icon>mdi-alert-circle-check-outline</v-icon>
                </v-btn>
                IsValid: {{ isValid() }}
                <tree-view
                    :data="Object.assign({}, address)"
                    :options="{ maxDepth: 3 }"
                ></tree-view>
            </template>
        </app-dev-container>
        <v-container fluid class="pa-0 ma-0">
            <v-row>
                <v-col class="py-0" cols="12" md="6">
                    <base-text-field
                        v-model="address.companyName"
                        :error-messages="companyNameErrors"
                        :counter="$v.address.companyName.$params.maxLength.max"
                        :label="$t('company-name-label')"
                        :disabled="disableAllFields"
                        :required="isRequiredField($v.address.companyName)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                    <base-text-field
                        v-model="address.department"
                        :disabled="disableAllFields"
                        :error-messages="departmentErrors"
                        :counter="$v.address.department.$params.maxLength.max"
                        :label="$t('department-label')"
                        :required="isRequiredField($v.address.department)"
                    ></base-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-switch
                        class="ml-1"
                        v-model="address.additionalAddressLines"
                        :label="$t('additional-address-lines')"
                        :disabled="disableAllFields"
                    >
                    </v-switch>
                </v-col>
            </v-row>

            <v-row class="py-0 pb-4" v-if="address.additionalAddressLines">
                <v-col class="py-0" cols="12" md="6">
                    <!-- addressLine1 -->
                    <base-text-field
                        v-model="address.addressLine1"
                        :label="$t('address-line-1-label')"
                        clearable
                        :disabled="disableAllFields"
                        :error-messages="addressLine1Errors"
                        :counter="$v.address.addressLine1.$params.maxLength.max"
                        :required="isRequiredField($v.address.addressLine1)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                    <!-- addressLine2 -->
                    <base-text-field
                        v-model="address.addressLine2"
                        :label="$t('address-line-2-label')"
                        clearable
                        :disabled="disableAllFields"
                        :counter="$v.address.addressLine2.$params.maxLength.max"
                        :error-messages="addressLine2Errors"
                        :required="isRequiredField($v.address.addressLine2)"
                    ></base-text-field>
                </v-col>
            </v-row>

            <v-row class="py-0">
                <v-col class="py-0" cols="12" md="10">
                    <!-- street -->
                    <base-text-field
                        v-model="address.street"
                        :error-messages="streetErrors"
                        :counter="$v.address.street.$params.maxLength.max"
                        :label="$t('street-label')"
                        :required="isRequiredField($v.address.street)"
                        :disabled="disableAllFields"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="2">
                    <!-- houseNr -->
                    <base-text-field
                        v-model="address.houseNr"
                        :label="$t('house-nr-label')"
                        :error-messages="houseNrErrors"
                        clearable
                        :disabled="disableAllFields"
                        :counter="$v.address.houseNr.$params.maxLength.max"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row v-if="isLocationSearchAvailable(country)">
                <v-col>
                    <w-location-search
                        ref="wLocationSearch"
                        v-model="locationSearch"
                        :country="country"
                        :disabled="disableAllFields"
                        :error-messages="locationSearchErrors"
                        :required="
                            isRequiredField($v.address.canton) ||
                            isRequiredField($v.address.zipCode) ||
                            isRequiredField($v.address.city)
                        "
                        @reset="onWLocationSearchReset"
                    />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col class="py-0" cols="12" md="2" sm="6">
                    <base-text-field
                        v-model="address.zipCode"
                        :disabled="disableAllFields"
                        :error-messages="zipCodeErrors"
                        :counter="$v.address.zipCode.$params.maxLength.max"
                        :label="$t('zip-code-label')"
                        :required="isRequiredField($v.address.zipCode)"
                    ></base-text-field>
                </v-col>
                <v-col
                    class="py-0"
                    cols="12"
                    :md="country === 'CH' ? 8 : 10"
                    sm="12"
                >
                    <base-text-field
                        v-model="address.city"
                        :disabled="disableAllFields"
                        :error-messages="cityErrors"
                        :counter="$v.address.city.$params.maxLength.max"
                        :label="$t('city-label')"
                        :required="isRequiredField($v.address.city)"
                    ></base-text-field>
                </v-col>
                <v-col
                    v-if="country === 'CH'"
                    class="py-0"
                    cols="12"
                    md="2"
                    sm="6"
                >
                    <WCantonField
                        ref="canton"
                        :value="address.canton"
                        :disabled="disableAllFields"
                        :error-messages="cantonErrors"
                        @change="onFieldChange('canton', $event)"
                    ></WCantonField>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="pa-0 ma-0">
            <!-- Postfachnummer, PLZ, Ort -->
            <v-row dense v-if="address.hasPoBox" class="pl-2">
                <!-- poBox -->
                <v-checkbox
                    v-model="address.poBox"
                    :label="$t('po-box-label')"
                    color="primary"
                ></v-checkbox>
            </v-row>
            <v-row v-if="address.poBox">
                <v-col class="py-0" cols="12" md="3" sm="6">
                    <base-text-field
                        v-model="address.poBoxNr"
                        :error-messages="poBoxNrErrors"
                        type="number"
                        :label="$t('po-box-nr-label')"
                        :required="isRequiredField($v.address.poBoxNr)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="3" sm="6">
                    <base-text-field
                        v-model="address.poBoxZipCode"
                        :error-messages="poBoxZipCodeErrors"
                        :counter="$v.address.poBoxZipCode.$params.maxLength.max"
                        type="number"
                        :label="$t('po-box-zip-code-label')"
                        :required="isRequiredField($v.address.poBoxZipCode)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="6" sm="12">
                    <base-text-field
                        v-model="address.poBoxCity"
                        :error-messages="poBoxCityErrors"
                        :counter="$v.address.poBoxCity.$params.maxLength.max"
                        :label="$t('po-box-city-label')"
                        :required="isRequiredField($v.address.poBoxCity)"
                    ></base-text-field>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import { maxLength, requiredIf } from "vuelidate/lib/validators";
import WCantonField from "@/components/fields/WCantonField.vue";
import { CompanyAddress } from "@/models/tasks";
import validateMixin from "@/mixins/validate";
import Helpers from "@/utils/helpers";
export default {
    name: "FormAddress",
    components: {
        WCantonField
    },
    mixins: [validateMixin],

    validations() {
        return {
            address: {
                companyName: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("companyName");
                    }),
                    maxLength: maxLength(50)
                },
                department: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("department");
                    }),
                    maxLength: maxLength(50)
                },
                addressLine1: {
                    required: requiredIf(function () {
                        return this.address.additionalAddressLines;
                    }),
                    maxLength: maxLength(40)
                },
                addressLine2: {
                    required: requiredIf(function () {
                        return (
                            this.address.additionalAddressLines &&
                            !this.address.addressLine1
                        );
                    }),
                    maxLength: maxLength(40)
                },
                street: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("street");
                    }),
                    maxLength: maxLength(50)
                },
                houseNr: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("houseNr");
                    }),
                    maxLength: maxLength(10)
                },
                canton: {
                    required: requiredIf(function () {
                        return (
                            this.requiredFields.includes("canton") &&
                            this.country === "CH"
                        );
                    })
                },
                zipCode: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("zipCode");
                    }),
                    maxLength:
                        this.country === "CH" ? maxLength(4) : maxLength(7)
                },
                city: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("city");
                    }),
                    maxLength: maxLength(50)
                },
                poBoxNr: {
                    required: requiredIf(function () {
                        return this.poBox;
                    })
                },
                poBoxZipCode: {
                    required: requiredIf(function () {
                        return this.poBox;
                    }),
                    maxLength:
                        this.country === "CH" ? maxLength(4) : maxLength(7)
                },
                poBoxCity: {
                    required: requiredIf(function () {
                        return this.poBox;
                    }),
                    maxLength: maxLength(50)
                }
            }
        };
    },

    props: {
        country: {
            type: String,
            default: "CH"
        },
        hasPoBox: {
            type: Boolean,
            default: false
        },
        requiredFields: {
            type: Array,
            default: () => []
        },
        address: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        disableAllFields: false
    }),

    methods: {
        onFieldChange(field, value) {
            this.$emit("field:change", { field, value });
        },
        reset() {
            this.$v.$reset();
            this.locationSearch = null;
            this.$emit("reset");
        },

        setAllDisabled(val) {
            this.disableAllFields = val;
        },
        setFakeData() {
            let address = new CompanyAddress();
            address.companyName = "Was.ch GmbH";
            address.department = "IT";
            address.additionalAddressLines = true;
            address.addressLine1 = "Untere Stock";
            address.addressLine2 = "an der Ecke";
            address.street = "Vonwilstrasse";
            address.houseNr = "23";
            address.zipCode = "9000";
            address.city = "St. Gallen";
            address.canton = "SG";
            this.$emit("input", address);
        },
        onWLocationSearchReset() {
            this.address.zipCode = null;
            this.address.city = null;
            this.address.canton = null;
        },
        isLocationSearchAvailable(country) {
            return Helpers.isLocationSearchAvailable(country);
        }
    },

    computed: {
        locationSearch: {
            get() {
                let search = {};
                search.zipCode = this.address.zipCode || null;
                search.city = this.address.city || null;
                search.canton = this.address.canton || null;
                return search;
            },
            set(val) {
                if (!val) {
                    this.onWLocationSearchReset();
                    return;
                }
                this.address.zipCode = val.zipCode;
                this.address.city = val.city;
                this.address.canton = val.canton;
            }
        },
        locationSearchErrors() {
            const errors = [];
            if (
                !this.$v.address.canton.$dirty ||
                !this.$v.address.zipCode.$dirty ||
                !this.$v.address.city.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.address.canton.required ||
                !this.$v.address.zipCode.required ||
                !this.$v.address.city.required
            ) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        companyNameErrors() {
            const errors = [];
            if (!this.$v.address.companyName.$dirty) {
                return errors;
            }
            if (!this.$v.address.companyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.companyName.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.companyName.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        departmentErrors() {
            const errors = [];
            if (!this.$v.address.department.$dirty) {
                return errors;
            }
            if (!this.$v.address.department.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.department.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        addressLine1Errors() {
            const errors = [];
            if (!this.$v.address.addressLine1.$dirty) {
                return errors;
            }
            if (!this.$v.address.addressLine1.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.addressLine1.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.addressLine1.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        addressLine2Errors() {
            const errors = [];
            if (!this.$v.address.addressLine2.$dirty) {
                return errors;
            }
            if (!this.$v.address.addressLine2.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.addressLine2.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.addressLine2.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        streetErrors() {
            const errors = [];
            if (!this.$v.address.street.$dirty) {
                return errors;
            }
            if (!this.$v.address.street.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.street.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.street.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        houseNrErrors() {
            const errors = [];
            if (!this.$v.address.houseNr.$dirty) {
                return errors;
            }
            if (!this.$v.address.houseNr.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.houseNr.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.houseNr.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        cantonErrors() {
            const errors = [];
            if (!this.$v.address.canton.$dirty) {
                return errors;
            }
            if (!this.$v.address.canton.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        zipCodeErrors() {
            const errors = [];
            if (!this.$v.address.zipCode.$dirty) {
                return errors;
            }
            if (!this.$v.address.zipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.zipCode.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.zipCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.address.city.$dirty) {
                return errors;
            }
            if (!this.$v.address.city.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.city.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.city.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },

        poBoxNrErrors() {
            const errors = [];
            if (!this.$v.address.poBoxNr.$dirty) {
                return errors;
            }
            if (!this.$v.address.poBoxNr.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        poBoxZipCodeErrors() {
            const errors = [];
            if (!this.$v.address.poBoxZipCode.$dirty) {
                return errors;
            }
            if (!this.$v.address.poBoxZipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.poBoxZipCode.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.poBoxZipCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        poBoxCityErrors() {
            const errors = [];
            if (!this.$v.address.poBoxCity.$dirty) {
                return errors;
            }
            if (!this.$v.address.poBoxCity.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.poBoxCity.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.poBoxCity.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },

    watch: {
        address: {
            handler: function () {
                this.$emit("input", this.address);
            },
            immediate: true
        }
    }
};
</script>
