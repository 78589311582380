import { render, staticRenderFns } from "./registration-dialog-container.vue?vue&type=template&id=ae1a741e&"
import script from "./registration-dialog-container.vue?vue&type=script&lang=js&"
export * from "./registration-dialog-container.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/defaults-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fdialogs%2Fregistration-dialog-container.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./registration-dialog-container-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fdialogs%2Fregistration-dialog-container.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VContainer,VFlex,VIcon,VList,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VTooltip})
