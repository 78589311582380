<i18n src="./insurer-alert-msg-i18n.yaml"></i18n>
<template>
    <base-alert :type="insurer.approved ? 'warning' : 'error'">
        <i18n path="msg-info-insurer-approved" v-if="insurer.approved === true">
            <template #insurer>
                <b>{{ insurerFullName }}</b>
            </template>
            <template #timestamp>
                <base-date-time
                    :timestamp="insurer.timestamp"
                    clsDate="font-weight-bold"
                    clsTime="font-weight-bold"
                />
            </template>
        </i18n>
        <i18n
            path="msg-info-insurer-declined"
            v-if="insurer.approved === false"
        >
            <template #insurer>
                <b>{{ insurerFullName }}</b>
            </template>
            <template #timestamp>
                <base-date-time
                    :timestamp="insurer.timestamp"
                    clsDate="font-weight-bold"
                    clsTime="font-weight-bold"
                />
            </template>
        </i18n>
    </base-alert>
</template>

<script>
export default {
    name: "InsurerAlertMsg",
    props: {
        insurer: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        insurerFullName() {
            if (!this.insurer) return;
            return `${this.insurer?.account?.firstName} ${this.insurer?.account?.familyName}`;
        }
    }
};
</script>
