<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./registration-card-i18n.yaml"></i18n>
<template>
    <v-card class="mx-auto" flat>
        <v-card-title
            v-if="title"
            class="info--text ml-0"
            :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'text-h6'"
        >
            <v-icon
                v-if="icon"
                class="mr-1"
                :small="$vuetify.breakpoint.xsOnly"
                color="info"
                v-html="icon"
            ></v-icon>
            <span v-html="title"></span>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
            <v-list-item class="pa-0 ma-0">
                <v-list-item-avatar size="50">
                    <RegistrationIcon
                        class="pt-2"
                        :objectType="registration.objectType"
                        :state="registration.state"
                        :deletedState="
                            registration.deletedState
                                ? registration.deletedState
                                : 0
                        "
                        :process="registration.process"
                        :stateText="
                            $t(`task-object-type-${registration.objectType}`)
                        "
                    >
                    </RegistrationIcon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <RegistrationCardContent :item="registration">
                        <template v-slot:append-content>
                            <span class="caption">
                                {{ $t("changed") }}
                                <span
                                    >am {{ formattedLastChangeTimestamp }}</span
                                >
                                <span
                                    >&ensp;({{
                                        formattedLastChangeTimestampUtc
                                    }})</span
                                >
                            </span>
                        </template>
                    </RegistrationCardContent>
                </v-list-item-content>
            </v-list-item>
        </v-card-text>
    </v-card>
</template>
<script>
import RegistrationIcon from "@/components/tasks/common/registration-icon.vue";
import RegistrationCardContent from "@/components/tasks/common/registration-card-content.vue";

export default {
    name: "RegistrationsCard",
    components: {
        RegistrationIcon,
        RegistrationCardContent
    },
    props: {
        registration: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        }
    },
    computed: {
        formattedLastChangeTimestamp() {
            return this.$moment
                .utc(this.registration.lastChangeTimestamp)
                .local()
                .format("DD.MM.YYYY HH:mm");
        },
        formattedLastChangeTimestampUtc() {
            return this.$moment
                .utc(this.registration.lastChangeTimestamp)
                .local()
                .from(this.$moment());
        }
    }
};
</script>
