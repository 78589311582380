<i18n src="./tabs-i18n.yaml"></i18n>
<template>
    <v-container fill-height>
        <v-row
            ><v-col>
                <v-card flat outlined>
                    <v-tabs
                        v-model="tab"
                        icons-and-text
                        height="60px"
                        :grow="isMobileBreakpoint"
                    >
                        <v-tabs-slider></v-tabs-slider>
                        <template v-for="tab in tabs">
                            <v-tab
                                v-if="!tab.hidding"
                                :key="tab.id"
                                :to="{ name: tab.route }"
                                :href="`#${tab}`"
                                exact-path
                                :disabled="tab.disabled"
                            >
                                {{ tab.title }}
                                <v-icon>{{ tab.icon }}</v-icon>
                            </v-tab>
                        </template>
                    </v-tabs>
                    <v-card flat>
                        <v-card-text><router-view></router-view></v-card-text>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "AccountSettingsTabsView",
    props: {
        docId: {
            type: String,
            default: null
        }
    },
    data: () => ({
        tab: "account-settings-user"
    }),
    computed: {
        ...mapGetters("auth", ["hasSettings"]),
        tabs() {
            return [
                {
                    id: 1,
                    title: this.$t("tab-settings-title"),
                    icon: "mdi-account",
                    route: "account-settings-user"
                },
                {
                    id: 2,
                    title: this.$t("tab-addresses-title"),
                    icon: "mdi-home-map-marker",
                    route: "account-settings-addresses",
                    hidding: !this.hasSettings
                }
            ];
        },
        isMobileBreakpoint() {
            return this.$vuetify?.breakpoint?.mobile;
        }
    },
    methods: {
        getAccountSettings() {
            this.loader().show();
            this.$store
                .dispatch("auth/getAccountSettings")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$router.push({ name: "user-dashboard" });
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        }
    }
};
</script>
