<i18n src="./login-i18n.yaml"></i18n>
<template>
    <v-container fluid class="pa-0 ma-0">
        <v-card
            v-if="!$cookies.isKey('authtoken') || !$cookies.isKey('mail')"
            class="mx-auto pa-0 ma-0"
            no-gutters
            flat
        >
            <v-card-text class="pa-0 ma-0 pl-3 pr-3">
                <div>
                    {{ $t("subtitle") }}
                </div>
                <div class="pt-4" v-html="$t('text')"></div>
                <v-text-field
                    class="pt-3"
                    :placeholder="$t('email-placeholder')"
                    style="font-size: 20px"
                    flat
                    outlined
                    v-model.trim="email"
                    :error-messages="emailErrors"
                    :label="$t('email-label')"
                    :hitn="$t('email-hint')"
                    :required="isRequiredField($v.email)"
                    clearable
                    @input="onValidateFields"
                    @keydown="onEmailInputKeydown"
                ></v-text-field>
            </v-card-text>
            <v-card-actions class="pt-0 mt-0 pr-3">
                <v-spacer></v-spacer>
                <base-btn type="primary" @click="submit">{{
                    $t("btn-send")
                }}</base-btn>
            </v-card-actions>
        </v-card>
        <v-card
            v-if="
                $cookies.isKey('authtoken') && $cookies.isKey('mail') && !isAuth
            "
            class="mx-auto pa-0 ma-0"
            no-gutters
            flat
            tile
        >
            <v-card-title>
                <v-toolbar dark color="primary" flat>
                    <v-icon>mdi-shield-key</v-icon>
                    <v-toolbar-title class="ml-2 secondary--text">{{
                        $t("validation-title")
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <div class="title">{{ $t("validation-text") }}</div>
                <div class="subtitle-1">{{ $t("validation-subtitle-01") }}</div>
                <div class="subtitle-1">{{ $t("validation-subtitle-02") }}</div>
            </v-card-text>
            <v-card-actions class="pa-4">
                <base-btn type="secondary" @click="clearTokens">{{
                    $t("btn-request-new-token")
                }}</base-btn>
                <v-spacer></v-spacer>
                <base-btn type="primary" @click="dialogVerifyToken = true">{{
                    $t("btn-verification")
                }}</base-btn>
            </v-card-actions>
        </v-card>

        <DialogVerifyToken
            :value="dialogVerifyToken"
            @input="dialogVerifyToken = $event"
            :validationImages="validationImages"
            :queryParams="queryParams"
        >
        </DialogVerifyToken>
    </v-container>
</template>
<script>
import { required, maxLength, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import DialogVerifyToken from "./dialog-verify-token.vue";
import validateMixin from "@/mixins/validate";
export default {
    name: "AuthLogin",
    components: {
        DialogVerifyToken
    },
    mixins: [validateMixin],

    validations: {
        email: {
            required,
            email,
            maxLength: maxLength(50)
        }
    },

    props: {
        queryParams: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        email: "",
        dialogVerifyToken: false,
        validationImages: []
    }),

    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) {
                return errors;
            }
            if (!this.$v.email.email) {
                errors.push(this.$t("field-invalid-email"));
            }
            if (!this.$v.email.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.email.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.email.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },

    methods: {
        onValidateFields() {
            if (this.$v.$dirty) {
                this.$v.email.$touch();
            } else {
                return;
            }
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loader().show();
                let data = {};
                let mail = btoa(this.email);
                data.mail = mail;
                let params = {};
                let validate = {};
                if (this.queryParams) {
                    params = Object.assign(params, this.queryParams);
                    validate = params;
                }
                if (validate) {
                    let urlParams = {};
                    urlParams.validate = validate;
                    data.urlParams = urlParams;
                }

                this.$store
                    .dispatch("auth/register", data)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            this.loader().hide();
                            this.dialogVerifyToken = true;
                        }
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.showAlertContainer = true;
                        this.loader().hide();
                    });
            }
        },
        clear() {
            this.$v.$reset();
            this.email = "";
        },
        clearTokens() {
            this.$store.commit("auth/clearAuthData");
            this.$router.go();
        },
        onEmailInputKeydown(keyboardEvent) {
            if (!this.email) {
                return;
            } else {
                if (keyboardEvent.keyCode === 13) {
                    this.submit();
                }
            }
        }
    }
};
</script>
