<template>
  <SectionBase id="devel-registration-office" title="Registration Office">
    <v-row>
      <v-col cols="12">
        <v-btn-toggle v-model="computedRegistrationOffice">
          <v-btn text value="SVTI">
            <v-img
              :src="require('@/assets/svti-logo.svg')"
              contain
              height="50"
              width="25"
            ></v-img>
          </v-btn>
          <v-btn text value="SUVA">
            <v-img
              :src="require('@/assets/suva-logo.png')"
              contain
              height="125"
              width="50"
            ></v-img>
          </v-btn>
        </v-btn-toggle>
        <v-img
          :src="require('@/' + registrationOfficeLogoPath)"
          width="100"
        ></v-img>
      </v-col>
      <v-col cols="12" :md="4">
        <v-card-title class="headline primary--text pb-5"
          >Alerts Samples</v-card-title
        >
        <base-alert color="regOfficePrimary" text="regOfficePrimary" />
        <base-alert color="regOfficeSecondary" text="regOfficeSecondary" />
        <base-alert color="primary" text="primary" />
        <base-alert color="secondary">
          <div class="primary--text">secondary</div>
        </base-alert>
        <base-alert color="info" text="info" />
        <base-alert color="success" text="success" />
        <base-alert color="error" text="error" />
        <base-alert color="warning" text="warning" />
      </v-col>
      <v-col cols="12" :md="8">
        <v-card-title class="headline primary--text pb-5"
          >Change colors</v-card-title
        >
        <v-container fluid>
          <div>Select theme to change colors</div>
          <v-btn-toggle v-model="selectedTheme">
            <v-btn text value="dark">Dark</v-btn>
            <v-btn text value="light">Light</v-btn>
          </v-btn-toggle>
          <v-row>
            <v-col
              v-for="(color, idx) in $vuetify.theme.themes[this.selectedTheme]"
              :key="idx"
              cols="12"
              md="3"
            >
              <ColorPicker
                :value="color"
                @input="onColorPickerInput($event, idx)"
                :overtitle="idx"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col> </v-col>
    </v-row>
  </SectionBase>
</template>

<script>
import colors from "vuetify/es5/util/colors";
import { mapGetters } from "vuex";
export default {
  name: "SectionRegistrationOffice",
  components: {
    SectionBase: () => import("./base.vue"),
    ColorPicker: () => import("@/components/devel/components/color-picker.vue"),
  },
  data: () => ({ color: colors, selectedTheme: "light" }),
  computed: {
    ...mapGetters("settings", {
      registrationOffice: "getRegistrationOffice",
      registrationOfficeLogoPath: "getRegistrationOfficeLogoPath"
    }),
    computedRegistrationOffice: {
      get() {
        return this.registrationOffice;
      },
      set(value) {
        this.$store.commit("settings/setRegistrationOffice", value);
        if (value === "SUVA") {
          this.setSuvaColors();
        } else {
          this.setSvtiColors();
        }
      },
    },
  },
  methods: {
    onColorPickerInput(color, colorType) {
      this.$vuetify.theme.themes[this.selectedTheme][colorType] = color;
    },
    setSuvaColors() {
      const suvaGrau = "#666666";
      const suvaOrange = "#ff8200";
      const suvaBlau = "#00b8cf";
      const suvaGrün = "#c1e200";
      const suvaRot = "#eb0064";
      const suvaYellow = "#fce300";
      this.$vuetify.theme.themes[
        this.selectedTheme
      ].regOfficePrimary = suvaOrange;
      this.$vuetify.theme.themes[
        this.selectedTheme
      ].regOfficeSecondary = suvaGrau;
      this.$vuetify.theme.themes[this.selectedTheme].info = suvaBlau;
      this.$vuetify.theme.themes[this.selectedTheme].success = suvaGrün;
      this.$vuetify.theme.themes[this.selectedTheme].error = suvaRot;
      this.$vuetify.theme.themes[this.selectedTheme].warning = suvaYellow;
      this.$vuetify.theme.themes[
        this.selectedTheme
      ].backgroundOutter = suvaGrau;
    },
    setSvtiColors() {
      const secondary = "#FFFFFF";
      const info = "#1160cf";
      const success = "#4caf50";
      const error = "#F44336";
      const warning = "#ff9800";
      const backgroundOutter = "#a8a39f";
      this.$vuetify.theme.themes[this.selectedTheme].regOfficePrimary =
        "#E73636";
      this.$vuetify.theme.themes[
        this.selectedTheme
      ].regOfficeSecondary = secondary;
      this.$vuetify.theme.themes[this.selectedTheme].info = info;
      this.$vuetify.theme.themes[this.selectedTheme].success = success;
      this.$vuetify.theme.themes[this.selectedTheme].error = error;
      this.$vuetify.theme.themes[this.selectedTheme].warning = warning;
      this.$vuetify.theme.themes[
        this.selectedTheme
      ].backgroundOutter = backgroundOutter;
    },
  },
};
</script>
