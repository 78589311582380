export default {
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedAddress: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    methods: {
        emitOnFieldInput(field, value) {
            this.$emit("input:field", { field, value });
        }
    }
};
