<i18n src="./error-i18n.yaml"></i18n>
<template>
    <AppErrorContainer
        errorTitle="500"
        :errorMsg="$t('error-msg')"
        :wIconSrc="require('@/assets/icons/pages/500.svg')"
    />
</template>
<script>
export default {
    name: "ViewErrorError"
};
</script>
