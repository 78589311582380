<template >
    <v-text-field
        :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
        v-bind="computedProps"
        v-on="$listeners"
        v-disabled-icon-focus
        :required="required"
        ref="input"
    >
        <!-- Dynamically inherit slots from parent -->
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
        </template>
        <!-- make all slots from v-text-field reusable from parent -->
        <template v-for="(_, slot) in $slots">
            <template :slot="slot">
                <slot :name="slot"></slot>
            </template>
        </template>
        <template v-slot:append v-if="showValidatorAppendIcon">
            <v-icon
                small
                v-if="validModel !== null"
                :color="validModel === true ? 'success' : 'error'"
                v-html="validModel ? 'mdi-check-circle' : 'mdi-close-circle'"
            >
            </v-icon>
        </template>
    </v-text-field>
</template>
<script>
import _ from "lodash";
import mixinWField from "@/mixins/w-field";
export default {
    name: "BaseTextField",
    mixins: [mixinWField],
    props: {
        v: {
            type: Object
        }
    },

    data: () => ({
        showValidatorAppendIcon: false
    }),

    computed: {
        validModel() {
            if (!this.v) return null;
            if (this.v.$model === null) return null;
            if (this.v.$model && this.v.$invalid) return false;
            if (this.v.$model && !this.v.$invalid) return true;
            return null;
        }
    },
    methods: {
        setShowValidatorAppendIconState: _.debounce(function (state) {
            this.showValidatorAppendIcon = state;
        }, 2000)
    },

    watch: {
        "v.$model": {
            handler: function (value) {
                if (value !== null) {
                    if (this.validModel !== null) {
                        if (this.validModel === true) {
                            this.showValidatorAppendIcon = true;
                            return this.setShowValidatorAppendIconState(false);
                        } else {
                            this.showValidatorAppendIcon = true;
                            return this.setShowValidatorAppendIconState(true);
                        }
                    }
                }
                this.showValidatorAppendIcon = false;
            }
        }
    }
};
</script>
