<i18n src="@/components/app/app-modal-history-i18n.yaml"></i18n>
<i18n src="./history-avtivities-i18n.yaml"></i18n>
<template>
    <app-card-title-actions
        :actions="cardActions"
        v-bind="$attrs"
        v-on="$listeners"
        :loading="isLoading"
        :title="$t('title')"
        @click:reload="fetchHistoryActivitiesItems"
    >
        <template v-slot:content>
            <v-timeline align-top dense v-if="activities.length > 0">
                <v-timeline-item
                    v-for="(item, index) in activities"
                    :key="index"
                    small
                >
                    <div>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small>{{
                                    getIconAndColorByHistoryItem(item).icon
                                }}</v-icon>
                            </template>
                            {{
                                $t(
                                    `category-${FILTERS.kebabizeLower(
                                        item.category
                                    )}`
                                )
                            }}
                        </v-tooltip>
                        <i18n
                            :path="`info-${FILTERS.kebabizeLower(item.code)}`"
                            tag="span"
                            class="ml-1"
                        >
                            <template #user>
                                <b>{{ itemUserFullName(item) }}</b>
                            </template>
                            <template #timestamp>
                                <base-date-time
                                    :timestamp="item.timestamp"
                                    clsDate="font-weight-bold"
                                    clsTime="font-weight-bold"
                                />
                            </template>
                            <template #manufacturerName>
                                <strong
                                    v-if="
                                        itemHasExtras(item) &&
                                        item.extras.manufacturer_name
                                    "
                                    >{{ item.extras.manufacturer_name }}</strong
                                >
                            </template>
                            <template #reason>
                                <strong
                                    v-if="
                                        itemHasExtras(item) &&
                                        item.extras.reason
                                    "
                                    >{{ item.extras.reason }}</strong
                                >
                            </template>
                            <template #taskRefId v-if="itemHasExtras(item)">
                                <b>{{ item.extras.taskRefId }}</b>
                            </template>
                            <template #taskRefIdCopy v-if="itemHasExtras(item)">
                                <b>{{ item.extras.task_ref_id_copy }}</b>
                            </template>
                            <template #sapResponse v-if="itemHasExtras(item)">
                                <div>
                                    <base-alert type="warning" dense>
                                        <b>{{
                                            item.extras.response.detail.message
                                        }}</b>
                                    </base-alert>
                                </div>
                            </template>
                        </i18n>
                    </div>
                    <div v-if="item.timestamp">
                        <v-icon small>mdi-clock</v-icon>
                        <base-date-time
                            :timestamp="item.timestamp"
                            clsDate="font-weight-bold"
                            clsTime="font-weight-bold"
                        />
                    </div>
                </v-timeline-item>
            </v-timeline>
            <div v-else>
                {{ $t("msg-no-data") }}
            </div>
        </template>
        <div class="text-center">
            <base-pagination
                v-model="page"
                :length="totalPages"
                @input="fetchHistoryActivitiesItems"
            ></base-pagination>
        </div>
    </app-card-title-actions>
</template>

<script>
import { FILTERS } from "@/filters";
import { HISTORY_CATEGORIES, HISTORY_CODES } from "@/data/globals.js";
export default {
    name: "HistoryActivities",
    data: () => ({
        activities: [],
        FILTERS,
        HISTORY_CATEGORIES,
        HISTORY_CODES,
        page: 1,
        totalCount: 0,
        pages: 0,
        limit: 5,
        isLoading: false
    }),
    computed: {
        cardActions() {
            return [
                {
                    id: "reload",
                    emit: "click:reload",
                    icon: "mdi-reload",
                    title: this.$t("action-reload")
                }
            ];
        },
        totalPages() {
            return this.totalCount > 5
                ? Math.floor(this.totalCount / this.limit)
                : 1;
        }
    },
    methods: {
        itemHasExtras(item) {
            return item && item.extras ? Object.keys(item?.extras).length > 0 : false;
        },
        fetchHistoryActivitiesItems() {
            this.isLoading = true;
            this.loader().show();
            const startPage = this.page - 1;
            let params = {
                page: startPage,
                limit: 5
            };
            this.$axiosHistoryApi
                .get(`/activity`, { params })
                .then((response) => {
                    let resData = response.data;
                    this.activities = [...resData.data.activities];
                    this.totalCount = resData.data.totalCount;
                    this.isLoading = false;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error("error");
                    this.isLoading = false;
                    this.loader().hide();
                });
        },
        itemUserFullName(item) {
            return `${item.userFirstName} ${item.userFamilyName}`;
        },
        getIconAndColorByHistoryItem(item) {
            const category = item.category;
            const code = item.code;
            let icon = null;
            let color = null;
            switch (category) {
                case HISTORY_CATEGORIES.CREATE:
                    icon = "mdi-star";
                    color = "success";
                    break;
                case HISTORY_CATEGORIES.UPDATE:
                    icon = "mdi-pencil";
                    color = "info";
                    break;
                case HISTORY_CATEGORIES.DELETE:
                    icon = "mdi-delete";
                    color = "error";
                    break;
                case HISTORY_CATEGORIES.UPLOAD:
                    icon = "mdi-upload";
                    color = "info";
                    break;
                case HISTORY_CATEGORIES.RESTORE_FROM_TRASH:
                    icon = "mdi-delete-restore";
                    color = "error";
                    break;
                case HISTORY_CATEGORIES.SAP:
                    icon = "mdi-delete-restore";
                    color =
                        code === HISTORY_CODES.SAP_EXPORT_RESPONSE_ERROR
                            ? "error"
                            : "success";
                    break;
                default:
                    icon = "mdi-history";
                    break;
            }
            return {
                icon: icon,
                color: color
            };
        }
    },
    created() {
        this.fetchHistoryActivitiesItems();
    }
};
</script>
