<template>
  <SectionBase id="devel-svg-icons" title="SVG-Icons">
    <v-row align="center">
      <v-col v-for="(icon, idx) in wIconsList" :key="idx">
        {{ icon.name }}
        <w-icon
          :data="require(`@/assets/icons/${icon.name}`)"
          :color="icon.colors"
          width="100"
          height="100"
        ></w-icon>
      </v-col>
    </v-row>
  </SectionBase>
</template>

<script>
export default {
  name: "SectionSvgIcons",
  components: {
    SectionBase: () => import("./base.vue"),
  },

  data: () => ({
    wIconsList: [
      {
        name: "w-message-star.svg",
        colors: "red blue",
      },
      {
        name: "w-message-warning.svg",
        colors: "red blue",
      },
      {
        name: "w-file-document-multiple-warning.svg",
        colors: "red blue",
      },
      {
        name: "w-file-document-multiple-star.svg",
        colors: "red blue",
      },
      {
        name: "w-file-document-multiple-new.svg",
        colors: "red blue",
      },
      {
        name: "w-account.svg",
        colors: "red blue",
      },
      {
        name: "w-account-group.svg",
        colors: "red blue",
      },
      {
        name: "w-registration-bottles-status-check.svg",
        colors: "orange green",
      },
      {
        name: "w-registration-bottles-status-deleted.svg",
        colors: "orange green",
      },
      {
        name: "w-registration-bottles-status-dialog.svg",
        colors: "orange green",
      },
      //registrations
      { name: "w-registration-bottles-status-check.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-deleted.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-dialog.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-edit.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-eye.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-star.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-sync.svg", colors: "orange green" },
      { name: "w-registration-bottles-status-shield.svg", colors: "orange green" },
      { name: "w-registration-compressor-status-check.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-deleted.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-dialog.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-edit.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-eye.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-star.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-sync.svg", colors: "lime green" },
      { name: "w-registration-compressor-status-shield.svg", colors: "lime green" },
      { name: "w-registration-pipes-status-check.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-deleted.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-dialog.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-edit.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-eye.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-star.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-sync.svg", colors: "yellow green" },
      { name: "w-registration-pipes-status-shield.svg", colors: "yellow green" },
      //deregistrations
      { name: "w-deregistration-bottles-status-check.svg", colors: "grey red orange" },
      { name: "w-deregistration-bottles-status-eye.svg", colors: "grey red purple" },
      { name: "w-deregistration-bottles-status-star.svg", colors: "grey red lime" },
      { name: "w-deregistration-compressor-status-check.svg", colors: "grey red green" },
      { name: "w-deregistration-compressor-status-eye.svg", colors: "grey red amber" },
      { name: "w-deregistration-compressor-status-star.svg", colors: "grey red blue" },
      { name: "w-deregistration-pipes-status-check.svg", colors: "grey red black" },
      { name: "w-deregistration-pipes-status-eye.svg", colors: "grey red pink" },
      { name: "w-deregistration-pipes-status-star.svg", colors: "grey red teal" },
      //tasks
      { name: "w-task-transfer-to-system.svg", colors: "grey red teal" },
      { name: "w-task-transfer-to-system-check.svg", colors: "grey red teal" },
      { name: "w-task-transfer-to-system-star.svg", colors: "grey red teal" },
      { name: "w-task-transfer-to-system-warning.svg", colors: "grey red teal" },
    ],
  }),
};
</script>
