<i18n       src="@/lang/defaults-i18n.yaml"></i18n>
<i18n       src="./dialog-duplicates-check-i18n.yaml"></i18n>
<template>
    <ModalMovableDialog
        :value="dialog"
        @input="$emit('input', $event)"
        :toolbarTitle="$t('title')"
        max-width="600"
        ref="baseDialog"
        toolbarIcon="mdi-content-duplicate"
    >
        <template v-slot:content>
            <v-container>
                <v-card class="mx-auto" flat>
                    <v-card-title
                        class="
                            text-h6
                            font-weight-regular
                            justify-space-between
                        "
                    >
                        <v-row no-gutters>
                            <v-col>
                                <v-avatar
                                    color="primary lighten-2"
                                    class="subheading white--text mr-2"
                                    size="24"
                                    v-text="step"
                                ></v-avatar>
                                <span>{{ currentTitle }}</span>
                            </v-col>
                            <v-col cols="1" align="end" v-if="step === 1">
                                <MenuDuplicatesCheck :task="registrationData" />
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-window v-model="step">
                        <v-window-item :value="1">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col>
                                        <base-alert type="warning" dense>
                                            <div>
                                                {{ $t("warning-msg-summary") }}
                                            </div>
                                        </base-alert>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <InsurerAlertMsg
                                            v-if="registrationData.insurer"
                                            :insurer="registrationData.insurer"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <TaskSummaryWithVerification
                                            :task="registrationData"
                                            :reviewVerification="
                                                reviewVerification
                                            "
                                            @change:review-verification-step="
                                                $emit(
                                                    'change:review-verification-step',
                                                    $event
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-window-item>

                        <v-window-item :value="2">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <base-alert type="warning" dense>
                                            <div>
                                                {{ $t("warning-msg-decision") }}
                                            </div>
                                        </base-alert>
                                    </v-col>
                                    <v-col cols="12" xs="6">
                                        <base-radio-group
                                            v-model="duplicateDecision"
                                            :disabled="readonlyModus"
                                            :required="
                                                isRequiredField(
                                                    $v.duplicateDecision
                                                )
                                            "
                                            :label="
                                                $t('duplicate-decision-label')
                                            "
                                            :error-messages="
                                                duplicateDecisionErrors
                                            "
                                        >
                                            <v-radio
                                                :label="
                                                    $t(
                                                        `duplicate-decision-${DUPLICATE_DECISION_TYPES.NEW}`
                                                    )
                                                "
                                                :value="
                                                    DUPLICATE_DECISION_TYPES.NEW
                                                "
                                            ></v-radio>
                                            <v-radio
                                                :label="
                                                    $t(
                                                        `duplicate-decision-${DUPLICATE_DECISION_TYPES.MUTATION}`
                                                    )
                                                "
                                                :value="
                                                    DUPLICATE_DECISION_TYPES.MUTATION
                                                "
                                            ></v-radio>
                                            <v-radio
                                                :label="
                                                    $t(
                                                        `duplicate-decision-${DUPLICATE_DECISION_TYPES.DUPLICATE}`
                                                    )
                                                "
                                                :value="
                                                    DUPLICATE_DECISION_TYPES.DUPLICATE
                                                "
                                            ></v-radio>
                                        </base-radio-group>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        v-if="
                                            duplicateDecision ===
                                            DUPLICATE_DECISION_TYPES.DUPLICATE
                                        "
                                    >
                                        <base-alert type="warning" dense>
                                            <div>
                                                {{
                                                    $t(
                                                        "warning-msg-duplicate-01"
                                                    )
                                                }}
                                            </div>
                                            <br />
                                            <div>
                                                {{
                                                    $t(
                                                        "warning-msg-duplicate-02"
                                                    )
                                                }}
                                            </div>
                                        </base-alert>
                                    </v-col>
                                    <v-col cols="12" xs="6">
                                        <base-text-field
                                            v-model="sapEquipmentNr"
                                            type="number"
                                            class="remove-spin-buttons"
                                            :disabled="readonlyModus"
                                            :label="
                                                $t('sap-equipment-nr-label')
                                            "
                                            :error-messages="
                                                sapEquipmentNrErrors
                                            "
                                            v-mask="'#######'"
                                            :required="
                                                isRequiredField(
                                                    $v.sapEquipmentNr
                                                )
                                            "
                                        ></base-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-window-item>
                    </v-window>
                </v-card>
            </v-container>
        </template>
        <template v-slot:actions>
            <base-btn type="close" v-if="step === 1" @click="closeDialog">{{
                $t("btn-close")
            }}</base-btn>
            <base-btn
                type="close"
                v-if="step !== 1"
                @click="step--"
                btnIcon="mdi-arrow-left"
                >{{ $t("btn-back") }}</base-btn
            >
            <v-spacer></v-spacer>
            <base-btn
                v-if="step !== 2"
                type="primary"
                @click="step++"
                btnIcon="mdi-arrow-right"
                >{{ $t("btn-continue") }}</base-btn
            >
            <base-btn
                v-if="!readonlyModus && step === 2"
                type="save"
                @click="save"
                >{{ $t("btn-save") }}</base-btn
            >
        </template>
    </ModalMovableDialog>
</template>
<script>
import {
    required,
    maxLength,
    minLength,
    requiredIf
} from "vuelidate/lib/validators";
import { DUPLICATE_DECISION_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
export default {
    name: "DialogDuplicatesCheck",
    components: {
        ModalMovableDialog: () =>
            import("@/components/general/modal-movable-dialog.vue"),
        TaskSummaryWithVerification: () =>
            import("./task-summary-with-verification.vue"),
        InsurerAlertMsg: () =>
            import("@/components/tasks/common/insurer-alert-msg.vue"),
        MenuDuplicatesCheck: () =>
            import(
                "@/components/tasks/edit/backoffice/components/menu-duplicates-check.vue"
            )
    },
    mixins: [validateMixin],
    validations: {
        duplicateDecision: { required },
        sapEquipmentNr: {
            required: requiredIf(function () {
                if (
                    this.duplicateDecision == DUPLICATE_DECISION_TYPES.MUTATION
                ) {
                    return true;
                }
                return false;
            }),
            minLength: minLength(7),
            maxLength: maxLength(7)
        }
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        registrationData: {
            type: Object,
            default: () => {}
        },
        reviewVerification: {
            type: Object,
            default: () => {}
        },
        backoffice: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        dialog: false,
        duplicateDecision: null,
        sapEquipmentNr: null,
        DUPLICATE_DECISION_TYPES: DUPLICATE_DECISION_TYPES,
        step: 1
    }),

    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        scrollToFirstErrorWithScrollOptions() {
            let movableDialog = this.$refs.baseDialog;
            let movableDialogCardTextRef = movableDialog.$refs.cardText;
            let scrollOptions = {
                container: movableDialogCardTextRef
            };
            this.scrollToFirstError(scrollOptions);
        },
        save() {
            this.validate();
            if (this.isValid()) {
                let backoffice = {
                    sapEquipmentNr: this.sapEquipmentNr,
                    duplicateDecision: this.duplicateDecision
                };
                this.$emit("save", backoffice);
            } else {
                this.scrollToFirstErrorWithScrollOptions();
                this.$toast.error(this.$t("has-errors-msg"));
            }
        },
        setValues() {
            if (!this.backoffice) return;
            this.sapEquipmentNr = this.backoffice?.sapEquipmentNr;
            this.duplicateDecision = this.backoffice?.duplicateDecision;
        }
    },

    computed: {
        duplicateDecisionErrors() {
            const errors = [];
            if (!this.$v.duplicateDecision.$dirty) {
                return errors;
            }
            if (!this.$v.duplicateDecision.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        sapEquipmentNrErrors() {
            const errors = [];
            if (!this.$v.sapEquipmentNr.$dirty) {
                return errors;
            }
            if (!this.$v.sapEquipmentNr.required) {
                errors.push(this.$t("field-required"));
            }
            if (
                !this.$v.sapEquipmentNr.minLength ||
                !this.$v.sapEquipmentNr.maxLength
            ) {
                errors.push(
                    this.$t("field-equipment-id-length", {
                        length: this.sapEquipmentNr.length
                    })
                );
            }
            return errors;
        },
        currentTitle() {
            switch (this.step) {
                case 1:
                    return "Zusammenfassung";
                case 2:
                    return "Entscheidung";
                default:
                    return "Account created";
            }
        }
    },

    watch: {
        value: {
            handler: function (newValue) {
                this.setValues();
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
