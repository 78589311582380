import { render, staticRenderFns } from "./base-stepper-step.vue?vue&type=template&id=b7fa3bf8&"
import script from "./base-stepper-step.vue?vue&type=script&lang=js&"
export * from "./base-stepper-step.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./base-stepper-step-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fedit%2Fbackoffice%2Fcomponents%2Fbase-stepper-step.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VStepperStep})
