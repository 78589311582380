<i18n src="./btn-download-zip-i18n.yaml"></i18n>
<template>
    <base-btn color="info" @click="onDowloadFile" btnIcon="mdi-folder-zip">
        {{ $t("btn-download-zip-files") }}
    </base-btn>
</template>
<script>
import Helpers from "@/utils/helpers";
export default {
    name: "BtnDownloadZip",
    props: {
        taskDocId: {
            type: String,
            default: null,
            required: true
        }
    },
    methods: {
        onDowloadFile() {
            if (!this.taskDocId) return;
            this.loader().show();
            this.$axiosBinary
                .get(`/getAllAsZip/${this.taskDocId}`, {
                    responseType: "blob" // important
                })
                .then((response) => {
                    const downloaded =
                        Helpers.downloadAttachmentsAsZipByResponse(response);
                    if (downloaded) {
                        this.$toast.success(this.$t("get-file-success"));
                    } else {
                        this.$toast.error(this.$t("get-file-error"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(this.$t("get-file-error"));
                });
        }
    }
};
</script>
