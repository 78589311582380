<i18n src="./dashboard-i18n.yaml"></i18n>
<template>
    <div>
        <v-row>
            <v-col cols="12">
                <base-header>
                    <v-icon>mdi-view-dashboard</v-icon>
                    {{ $t("title") }}
                </base-header>
            </v-col>
        </v-row>
        <PanelGroup :items="items" :isLoading="isLoading" />
    </div>
</template>

<script>
import Helpers from "@/utils/helpers";
import menu from "@/utils/menu.js";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import * as ACTION_TYPES from "@/store/types/action-types";
export default {
    name: "ViewInsurerDashboard",
    components: {
        PanelGroup: () => import("@/components/general/PanelGroup.vue")
    },
    data: () => ({
        menuItems: menu
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        approvalRequiredPoolTasks() {
            return this.$store.state.insurer.counters.approvalRequiredPoolTasks;
        },
        insurersList() {
            const filtered = _.filter(this.menuItems, {
                group: "insurerListsAll"
            });
            let items = [];
            _.forEach(filtered[0].items, (item) => {
                let newItem = {
                    color: item.svgColor,
                    svgIcon: item.svgIcon,
                    title: this.$t(item.name),
                    action: {
                        path: { name: item.name }
                    }
                };
                let data;
                switch (item.name) {
                    case "insurer-tasks-list-approval-required":
                        data = this.approvalRequiredPoolTasks;
                        break;
                    default:
                        data = 0;
                        break;
                }
                newItem.data = data;
                items.push(newItem);
            });
            return items;
        },
        items() {
            return [
                {
                    title: this.$t("title-insurer-all-tasks"),
                    svgIcon: "w-account.svg",
                    svgIconColor: this.insurersListIconColor,
                    items: this.insurersList
                }
            ];
        }
    },
    methods: {
        ...mapActions("insurer", [ACTION_TYPES.FETCH_DASHBOARD_COUNTERS_DATA]),
        fetchData() {
            this.loader().show();
            this[ACTION_TYPES.FETCH_DASHBOARD_COUNTERS_DATA]();
            this.loader().hide();
        },
        _getColorByKey(key, colorization = "base") {
            return Helpers.getColorByKey(key, colorization);
        },
        reload() {
            this.fetchData();
        }
    },
    mounted() {
        this.$store.commit("backoffice/setTitle", this.$t("title"));
        this.fetchData();
    },
    destroyed() {
        this.$store.commit("backoffice/setDefaulTitle");
    }
};
</script>
