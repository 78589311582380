<i18n       src="./chamber-card-i18n.yaml"></i18n>
<template>
    <v-card
        height="100%"
        class="mx-auto d-flex flex-column"
        max-width="360"
        min-width="200"
        flat
        outlined
        tile
    >
        <v-card-title class="title">
            <v-layout row wrap>
                <v-flex xs10>
                    <div class="cls-card-header">
                        {{ chamberData.descripton }}
                    </div>
                </v-flex>
                <v-flex xs2>
                    <v-avatar
                        dark
                        color="primary lighten-2"
                        class="secondary--text pa-3"
                        size="20"
                    >
                        <span>{{ chamberIndex + 1 }}</span>
                    </v-avatar>
                </v-flex>
            </v-layout>
        </v-card-title>
        <v-card-text class="py-0 fill-height">
            <template
                v-if="
                    chamberData.lastMaxConcessionPressure !== null &&
                    chamberData.lastMaxConcessionPressure !== ''
                "
            >
                <v-icon class="mr-1">mdi-speedometer</v-icon>
                <span
                    >{{
                        parseFloat(
                            chamberData.lastMaxConcessionPressure
                        ).toFixed(1)
                    }}
                    bar</span
                >
            </template>
        </v-card-text>

        <v-card-actions class="card-actions">
            <base-btn
                v-if="!readonlyModus"
                :disabled="readonlyModus"
                icon
                color="error"
                @click="onBtnRemoveClick"
            >
                <v-icon>mdi-delete</v-icon>
            </base-btn>
            <v-spacer></v-spacer>
            <base-btn
                v-if="!readonlyModus"
                :disabled="readonlyModus"
                text
                color="info"
                class="ml-2"
                @click="onBtnEditClick"
            >
                <v-icon class="mr-1">mdi-pencil</v-icon>
                {{ $t("btn-edit") }}
            </base-btn>
            <base-btn
                v-if="readonlyModus"
                text
                class="info--text ml-2"
                @click="onBtnEditClick"
            >
                <v-icon class="mr-1">mdi-eye</v-icon>
                {{ $t("btn-show") }}
            </base-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "ChamberCard",
    props: {
        chamberData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        chamberIndex: {
            type: Number,
            default: null
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        show: false
    }),

    methods: {
        onBtnRemoveClick() {
            this.$emit("remove-chamber", this.chamberIndex);
        },
        onBtnEditClick() {
            this.$emit("edit-chamber", this.chamberData);
        }
    }
};
</script>
