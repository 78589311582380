import { render, staticRenderFns } from "./dropzone-uploader.vue?vue&type=template&id=2467d55c&"
import script from "./dropzone-uploader.vue?vue&type=script&lang=js&"
export * from "./dropzone-uploader.vue?vue&type=script&lang=js&"
import style0 from "./dropzone-uploader.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./dropzone-uploader-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fgeneral%2Fdropzone-uploader.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
