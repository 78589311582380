<i18n src="./app-dev-container-i18n.yaml"></i18n>
<template>
    <v-card
        class="mx-auto my-3"
        outlined
        v-if="showCard"
        tile
        color="red"
        width="100%"
    >
        <v-card-title
            style="cursor: pointer"
            @click.prevent="expand = !expand"
            :class="color"
            class="pa-1 ma-0"
        >
            <v-icon class="pr-2">mdi-bug</v-icon>
            <div>{{ title }}</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="hide = !hide">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn icon @click="expand = !expand">
                <v-icon>{{
                    expand ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <div v-show="expand">
                <v-divider></v-divider>
                <v-card-text :class="color">
                    <div :class="color">
                        <slot name="content"></slot>
                    </div>
                </v-card-text>
                <v-card-actions :class="color">
                    <slot name="card-actions"></slot>
                </v-card-actions>
            </div>
        </v-expand-transition>
    </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "AppDevContainer",
    props: {
        color: {
            type: String,
            default: "yellow lighten-3"
        },
        title: {
            type: String,
            default: "Devloper Mode"
        },
        expanded: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        expand: false,
        hide: false
    }),
    computed: {
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        showCard() {
            if (!this.developerMode) return false;
            if (this.hide) return false;
            return true;
        }
    },
    watch: {
        expanded: {
            handler: function (value) {
                if (value) {
                    this.expand = true;
                }
            },
            immediate: true
        }
    }
};
</script>
