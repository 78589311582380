var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card-title-actions',{attrs:{"subtitle":_vm.$t('subtitle'),"actions":_vm.cardActions,"loading":_vm.isLoading},on:{"click:reload":_vm.getLogsMonthCounters,"click:show-logs":function($event){return _vm.$router.push({ name: 'devel-logs' })}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i18n',{attrs:{"path":"title","tag":"span"},scopedSlots:_vm._u([{key:"counter",fn:function(){return [_c('count-to',{staticClass:"pl-2 font-weight-bold",attrs:{"start-val":0,"end-val":_vm.errorLevelCountersTotal,"duration":3200}})]},proxy:true}])})]},proxy:true},{key:"content",fn:function(){return [(_vm.errorLevelCountersDataFormatted)?_c('e-chart',{attrs:{"path-option":[
                ['dataset.source', _vm.errorLevelCountersDataFormatted],
                [
                    'color',
                    [_vm.color.yellow.base, _vm.color.orange.base, _vm.color.red.base]
                ],
                ['legend.orient', 'horizontal'],
                ['legend.y', 'bottom'],
                ['xAxis.show', false],
                ['yAxis.show', false],
                ['series[0].type', 'pie'],
                ['series[0].avoidLabelOverlap', true],
                ['series[0].radius', ['50%', '70%']]
            ],"height":"350px","width":"100%"}}):_c('v-sheet',{staticClass:"pa-1"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"100%","type":"list-item-avatar, image"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }