<i18n src="./summary-operator-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('operator-address')" :expandable="expandable">
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li
                    v-if="
                        task.operator && !checkIfAddressIsEmpty(task.operator)
                    "
                >
                    {{ task.operator.companyName }}
                </li>
                <li v-else>
                    <span class="font-italic">{{
                        $t("not-recorded-yet")
                    }}</span>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import mixin from "../mixin";
import Helpers from "@/utils/helpers";
export default {
    name: "SummaryOperator",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    methods: {
        checkIfAddressIsEmpty(address) {
            return Helpers.checkIfAddressIsEmpty(address);
        }
    }
};
</script>
