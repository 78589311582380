<i18n src="./WCompanySearch-i18n.yaml"></i18n>
<template>
    <div>
        <v-autocomplete
            v-model="selectedCompany"
            :items="companies"
            @click:clear="reset"
            :search-input.sync="search"
            :loading="isLoading"
            return-object
            full-width
            open-on-clear
            no-filter
            filled
            :label="label ? label : $t('label')"
            v-bind="$attrs"
            clearable
            v-on="$listeners"
            item-value="name"
            v-disabled-icon-focus
            v-on:="$listeners"
            ref="wCompanySearch"
            :required="required"
            :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
        >
            <template v-slot:append-outer>
                <v-menu
                    style="top: -12px"
                    offset-y
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon left v-bind="attrs" v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>
                    <v-card>
                        <v-card-text class="pa-6">
                            <v-row dense>
                                <v-col cols="12" v-if="developerMode">
                                    <v-switch
                                        class="ml-1 pa-0 ma-0"
                                        color="orange"
                                        v-model="switchDevSearch"
                                        :label="$t('switch-dev-search-label')"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12">
                                    <base-btn
                                        tpye="info"
                                        @click="
                                            dialogAdvancedSearch =
                                                !dialogAdvancedSearch
                                        "
                                    >
                                        {{ $t("switch-advanced-search-label") }}
                                    </base-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle>
                        <span v-if="item.street">
                            <template v-if="item.houseNr">
                                {{ item.street }} {{ item.houseNr }},
                            </template>
                            <template v-else> {{ item.street }}, </template>
                        </span>
                        <span v-if="item.country">
                            <template v-if="item.zipCode">
                                ({{ item.country }})-{{ item.zipCode }}
                            </template>
                            <template v-else> ({{ item.country }}) </template>
                        </span>
                        <span v-else>
                            <span v-if="item.zipCode">
                                {{ item.zipCode }}
                            </span>
                        </span>
                        <span v-if="item.city">
                            {{ item.city }}
                        </span>
                        <span v-if="item.canton"> ({{ item.canton }}) </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
                <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="item.street"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </template>
            <template v-slot:no-data>
                <v-list-item
                    disabled
                    v-if="
                        (!search && !isLoading) ||
                        (search && search.length <= searchMinLength)
                    "
                >
                    <v-icon class="pr-2">mdi-keyboard</v-icon>
                    <span>
                        {{
                            $t("no-results-no-search", {
                                minLength: searchMinLength
                            })
                        }}
                    </span>
                </v-list-item>
                <v-list-item disabled dense v-else>
                    <v-icon class="pr-2">mdi-database-remove</v-icon>
                    <span> {{ $t("no-results") }} </span>
                </v-list-item>
            </template>
        </v-autocomplete>
        <DialogCompanyAdvancedSearch
            v-if="dialogAdvancedSearch"
            v-model="dialogAdvancedSearch"
            @click:confirm-selected-company="onCompanyChange"
        />
    </div>
</template>

<script>
import _ from "lodash";
import mixinWField from "@/mixins/w-field";
import { mapState } from "vuex";
export default {
    name: "WCompanySearch",
    mixins: [mixinWField],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        label: {
            type: String,
            default: null
        }
    },

    components: {
        DialogCompanyAdvancedSearch: () =>
            import("@/components/general/dialog-company-advanced-search.vue")
    },

    data() {
        return {
            searchValue: null,
            companies: [],
            numberOfHits: 0,
            search: null,
            searchMinLength: 2,
            dialogAddManufacturer: false,
            isLoading: false,
            switchDevSearch: false,
            dialogAdvancedSearch: null,
            selectedCompany: {}
        };
    },

    computed: {
        ...mapState("settings", ["developerMode"])
    },

    methods: {
        onCompanyChange(val) {
            if (val) {
                this.$emit("change", val);
                if (this.dialogAdvancedSearch) {
                    this.dialogAdvancedSearch = false;
                    this.$nextTick(() => {
                        this.$vuetify.goTo(this.$refs.wCompanySearch);
                    });
                }
            }
        },
        reset() {
            this.searchValue = null;
            this.search = null;
            this.resetSearch();
            this.companies = [];
            this.$emit("reset");
        },
        resetSearch() {
            this.selectedCompany = null;
            this.companies = [];
            this.numberOfHits = 0;
        }
    },

    created() {
        this.fetchData = _.debounce(function () {
            this.isLoading = true;
            let data = {};
            data.name = this.search;
            data.devSearch = this.switchDevSearch;
            return new Promise((resolve, reject) => {
                this.$axiosUidApi
                    .post("/", data)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            let resData = response.data;
                            this.companies = [...resData.data];
                        }
                        this.isLoading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                        reject(error);
                    });
            });
        }, 500);
    },
    mounted() {
        this.switchDevSearch = this.developerMode;
    },

    watch: {
        search(val) {
            if (!val || val.length < this.searchMinLength) {
                if (!this.selectedCompany) {
                    this.resetSearch();
                }
            } else {
                this.resetSearch();
                this.page = 0;
                this.isLoading = true;
                this.searchValue = val.trim();
                this.fetchData();
            }
        },
        value: {
            handler: function (val) {
                if (val && typeof val === "object" && val !== null) {
                    if (!val.zipCode && !val.city) {
                        if (this.selectedCompany) {
                            this.reset();
                        }
                        return;
                    }
                    if (val.zipCode && val.city) {
                        this.companies.push(val);
                        this.selectedCompany = this.companies[0];
                    }
                } else {
                    if (!val) {
                        this.reset();
                    }
                }
            },
            immediate: true
        },
        developerMode: {
            handler: function (val) {
                this.switchDevSearch = val;
            },
            immediate: true
        }
    }
};
</script>
