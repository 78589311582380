<template>
    <base-card v-bind="$attrs" v-on="$listeners">
        <v-card-title>
            <slot name="title">
                <span
                    v-if="title"
                    class="d-inline-block text-truncate"
                    :style="{ maxWidth: maxTextTitleWidth + 'px' }"
                >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <span v-on="on"> {{ title }}</span>
                        </template>
                        <span> {{ title }}</span>
                    </v-tooltip>
                </span>
            </slot>
            <slot name="title-extra" />
            <v-spacer />
            <slot name="title-actions">
                <slot name="title-actions-additional"></slot>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <template v-for="action in actions">
                        <v-tooltip right v-if="action.title" :key="action.id">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="action.emit"
                                    v-on="on"
                                    icon
                                    @click="onClickAction(action, $event)"
                                    ><v-icon>{{ action.icon }}</v-icon>
                                </v-btn>
                                <v-icon v-else :key="action.id" v-on="on">
                                    {{ action.icon }}
                                </v-icon>
                            </template>
                            {{ action.title }}
                        </v-tooltip>
                        <template v-else>
                            <v-btn
                                v-if="action.emit"
                                :key="action.id"
                                icon
                                @click="onClickAction(action, $event)"
                                ><v-icon>{{ action.icon }}</v-icon>
                            </v-btn>
                            <v-icon v-else :key="action.id">
                                {{ action.icon }}
                            </v-icon>
                        </template>
                    </template>
                </template>
                <v-menu v-else bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="action in actions"
                            :key="action.id"
                            @click="onClickAction(action, $event)"
                        >
                            <v-list-item-title
                                ><v-icon>{{ action.icon }} </v-icon>
                                <span v-if="action.title">
                                    {{ action.title }}
                                </span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </slot>
        </v-card-title>
        <v-card-subtitle class="pb-0">
            <slot name="subtitle">
                <span
                    v-if="subtitle"
                    class="d-inline-block text-truncate"
                    :style="{ maxWidth: maxTextSubtitleWidth + 'px' }"
                >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <span v-on="on"> {{ subtitle }}</span>
                        </template>
                        <span> {{ subtitle }}</span>
                    </v-tooltip>
                </span>
            </slot>
        </v-card-subtitle>
        <v-divider />
        <v-card-text>
            <slot name="content" />
        </v-card-text>
        <slot />
    </base-card>
</template>

<script>
export default {
    name: "AppCardTitleActions",
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        actions: {
            tpye: Array,
            default: () => []
        }
    },
    data: () => ({
        renderedCardClientWidth: 0,
        maxTextTitleWidth: 0
    }),
    computed: {
        breakpointWidth() {
            return this.$vuetify.breakpoint;
        },
        isMobile() {
            return this.$vuetify?.breakpoint?.mobile;
        },
        maxTextSubtitleWidth() {
            return this.renderedCardClientWidth - 30;
        }
    },
    methods: {
        onClickAction(action, e) {
            if (action.emit) {
                this.$emit(action.emit, e);
            }
        },
        setCardWidth() {
            this.renderedCardClientWidth = this.$el?.clientWidth;
            this.maxTextTitleWidth =
                this.renderedCardClientWidth - (this.isMobile ? 100 : 150);
        }
    },
    mounted() {
        this.setCardWidth();
    },
    watch: {
        breakpointWidth: {
            handler: function () {
                this.setCardWidth();
            },
            immediate: true
        }
    }
};
</script>
