import { render, staticRenderFns } from "./size-class.vue?vue&type=template&id=7e4ff24e&"
import script from "./size-class.vue?vue&type=script&lang=js&"
export * from "./size-class.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/defaults-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fviews%2Fdevel%2Fsections%2Fsize-class.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/tasks/edit/backoffice/components/dialog-complete-registration-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fviews%2Fdevel%2Fsections%2Fsize-class.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VRow})
