<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12">
                <MessageCard
                    v-if="message && message.text"
                    :message="message"
                    :accountDocId="accountDocId"
                    @edit="$emit('edit', $event)"
                    @delete="$emit('delete', $event)"
                    :showEditIcon="showEditIcon"
                    :disableEditIcon="disableEditIcon"
                    :showDeleteIcon="showDeleteIcon"
                >
                    <template v-slot:additional-actions>
                        <v-badge
                            v-if="attachments"
                            :content="attachmentsCounters[0]"
                            bordered
                            overlap
                        >
                            <base-btn
                                icon
                                @click="dialogAttachmentsView = true"
                            >
                                <v-icon>mdi-file-eye</v-icon>
                            </base-btn>
                        </v-badge>
                    </template>
                </MessageCard>
            </v-col>
            <v-col cols="12" v-if="dialogAttachmentsView">
                <DialogAttachments
                    :value="dialogAttachmentsView"
                    @input="dialogAttachmentsView = $event"
                    :readonlyModus="true"
                    :attachments="attachments"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Helpers from "@/utils/helpers";
export default {
    name: "MessageCardReply",
    components: {
        MessageCard: () => import("./message-card.vue"),
        DialogAttachments: () =>
            import("@/components/general/dialog-attachments.vue")
    },
    props: {
        message: {
            type: Object,
            default: () => {}
        },
        accountDocId: {
            type: String,
            default: null
        },
        showEditIcon: {
            type: Boolean,
            default: false
        },
        disableEditIcon: {
            type: Boolean,
            default: false
        },
        showDeleteIcon: {
            type: Boolean,
            default: false
        },
        disableDeleteIcon: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: null
        },
        attachments: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        dialogAttachmentsView: false
    }),

    computed: {
        attachmentsCounters() {
            if (!this.attachments) return [0, 0, 0];
            return Helpers.getAttachmentsCounters(this.attachments);
        },
        attachmentsCountersByCategory() {
            if (!this.attachments) {
                return {};
            }
            return Helpers.getAttachmentsCountersByCategory(this.attachments);
        }
    }
};
</script>
