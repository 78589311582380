<i18n src="./suva-partner-finder-i18n.yaml"></i18n>
<template>
    <v-row>
        <v-col cols="12">
            <SuvaPartnerFinderSearch
                :uid="uid"
                @change="$emit('change', $event)"
            />
        </v-col>
        <v-col cols="12" v-if="formattedAddress">
            <base-alert type="success" dense>
                <div class="text-caption" v-if="suvaPartnerFinder.timestamp">
                    <i18n path="search-timestamp" tag="span">
                        <template #timestamp>
                            <base-date-time
                                class="font-weight-bold"
                                :timestamp="suvaPartnerFinder.timestamp"
                            />
                        </template>
                        <template #user>
                            <span
                                class="font-weight-bold"
                                v-if="
                                    suvaPartnerFinder.user &&
                                    suvaPartnerFinder.user.name
                                "
                                >{{ suvaPartnerFinder.user.name }}</span
                            >
                        </template>
                    </i18n>
                </div>
                <i18n path="search-msg-found" tag="span">
                    <template #partnerNr>
                        <span
                            class="font-weight-bold"
                            v-if="suvaPartnerFinder.partnerNr"
                            >{{ suvaPartnerFinder.partnerNr }}</span
                        >
                    </template>
                </i18n>
                <display-address-inline :address="formattedAddress" />
            </base-alert>
        </v-col>
        <v-col
            cols="12"
            v-if="
                suvaPartnerFinder &&
                Object.keys(suvaPartnerFinder).length > 0 &&
                suvaPartnerFinder.searchState == false
            "
        >
            <base-alert type="info" dense v-if="suvaPartnerFinder.timestamp">
                <div class="text-caption" v-if="suvaPartnerFinder.timestamp">
                    <i18n path="search-timestamp" tag="span">
                        <template #timestamp>
                            <base-date-time
                                class="font-weight-bold"
                                :timestamp="suvaPartnerFinder.timestamp"
                            />
                        </template>
                        <template #user>
                            <span
                                class="font-weight-bold"
                                v-if="
                                    suvaPartnerFinder.user &&
                                    suvaPartnerFinder.user.name
                                "
                                >{{ suvaPartnerFinder.user.name }}</span
                            >
                        </template>
                    </i18n>
                </div>
                <i18n path="search-msg-not-found" tag="span">
                    <template #uid>
                        <span
                            class="font-weight-bold"
                            v-if="suvaPartnerFinder.uid"
                            >{{ suvaPartnerFinder.uid }}</span
                        >
                    </template>
                </i18n>
            </base-alert>
        </v-col>
    </v-row>
</template>
<script>
import DisplayAddressInline from "@/components/general/display-address-inline.vue";
import SuvaPartnerFinderSearch from "./suva-partner-finder-search.vue";
import _ from "lodash";
import { CompanyAddress } from "@/models/tasks";
export default {
    name: "SuvaPartnerFinder",
    components: {
        SuvaPartnerFinderSearch,
        DisplayAddressInline
    },
    props: {
        uid: {
            type: [String, Number],
            default: null
        },
        suvaPartnerFinder: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        formattedAddress() {
            if (!this.suvaPartnerFinder || !this.suvaPartnerFinder.partnerNr)
                return null;
            return Object.assign(
                new CompanyAddress(),
                _.cloneDeep(this.suvaPartnerFinder)
            );
        }
    }
};
</script>
