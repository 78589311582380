<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./summary-attachments-i18n.yaml"></i18n>
<template>
    <BaseListItem
        :title="$t('title-attachments-details')"
        :expandable="expandable"
    >
        <template
            v-slot:title-additional
            v-if="showAttachmentsIcon && totalAttachments > 0"
        >
            <v-btn icon x-small @click="dialogAttachments = true">
                <v-icon> mdi-file-eye </v-icon>
            </v-btn>
            <DialogAttachments
                v-model="dialogAttachments"
                :readonlyModus="true"
                :attachments="task.attachments"
                :taskDocId="task.docId"
            />
        </template>
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li v-if="totalAttachments === 0">
                    <span class="font-italic">{{ $t("not-uploaded") }}</span>
                </li>
                <li
                    v-else
                    v-for="(
                        attachments, category
                    ) in attachmentsCountersByCategory"
                    :key="category"
                >
                    {{ $t(`attachment-category-${category}`) }} (<span
                        v-if="attachments.photos > 0"
                    >
                        <v-icon x-small>mdi-file-image</v-icon>
                        {{
                            $tc("photos-count", attachments.photos, {
                                count: attachments.photos
                            })
                        }}
                    </span>
                    <span v-if="attachments.photos && attachments.documents">
                        ,
                    </span>
                    <span v-if="attachments.documents > 0">
                        <v-icon x-small>mdi-file-multiple</v-icon>
                        {{
                            $tc("documents-count", attachments.documents, {
                                count: attachments.documents
                            })
                        }} </span
                    >)
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import Helpers from "@/utils/helpers";
import mixin from "../mixin";
export default {
    name: "SummaryAttachments",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue"),
        DialogAttachments: () =>
            import("@/components/general/dialog-attachments.vue")
    },
    props: {
        showAttachmentsIcon: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        dialogAttachments: false
    }),
    computed: {
        attachmentsCounters() {
            if (!this.task || !this.task.attachments) return [0, 0, 0];
            const value = Helpers.getAttachmentsCounters(this.task.attachments);
            return value;
        },
        totalAttachments() {
            return this.attachmentsCounters[0];
        },
        photosCount() {
            return this.attachmentsCounters[1];
        },
        documentsCount() {
            return this.attachmentsCounters[2];
        },
        attachmentsCountersByCategory() {
            if (!this.task || !this.task.attachments) return {};
            return Helpers.getAttachmentsCountersByCategory(
                this.task.attachments
            );
        }
    }
};
</script>
