var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._g(_vm._b({directives:[{name:"disabled-icon-focus",rawName:"v-disabled-icon-focus"}],class:_vm.showRequiredFieldLine ? 'cls-required-field' : undefined,attrs:{"items":_vm.locationsList,"search-input":_vm.search,"loading":_vm.isLoading,"return-object":"","full-width":"","open-on-clear":"","no-filter":"","filled":"","label":_vm.label ? _vm.label : _vm.$t('label'),"clearable":!_vm.readonly,"item-value":"zipCode","required":_vm.required},on:{"click:clear":_vm.reset,"change":_vm.onAutocompleteChange,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.zipCode)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.city)+" ")]),(item.canton)?_c('span',{staticClass:"pl-2"},[_vm._v(" ("+_vm._s(item.canton)+") ")]):_vm._e()]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.zipCode)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.city)+" ")]),(item.canton)?_c('span',{staticClass:"pl-2"},[_vm._v(" ("+_vm._s(item.canton)+") ")]):_vm._e()]}},{key:"no-data",fn:function(){return [(
                (!_vm.search && !_vm.isLoading) ||
                (_vm.search && _vm.search.length <= _vm.searchMinLength)
            )?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-keyboard")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-results-no-search", { minLength: _vm.searchMinLength }))+" ")])],1):_c('v-list-item',{attrs:{"disabled":"","dense":""}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-database-remove")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-results"))+" ")])],1)]},proxy:true}]),model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }