var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',_vm._g(_vm._b({directives:[{name:"disabled-icon-focus",rawName:"v-disabled-icon-focus"}],class:_vm.showRequiredFieldLine ? 'cls-required-field' : undefined,attrs:{"items":_vm.manufacturersList,"clearable":!_vm.readonly,"search-input":_vm.search,"loading":_vm.isLoading,"return-object":"","no-filter":"","filled":"","hide-selected":"","full-width":"","open-on-clear":"","required":_vm.required},on:{"click:clear":_vm.reset,"change":_vm.onAutocompleteChange,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.deletedState > 0 ? 'grey--text' : undefined,staticStyle:{"display":"flex","align-items":"center","column-gap":"6px","flex-wrap":"wrap"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"display":"flex","align-items":"center"}},on),[_vm._v(" ("),(item.country)?_c('country-flag',{attrs:{"size":"small","country":item.country}}):_vm._e(),_c('span',{staticClass:"pl-1 text-caption"},[_vm._v(_vm._s(item.country))]),_vm._v(")"),(item.department)?_c('span',[_vm._v(",")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getCountryTranslationByCountryCode(item.country))+" ")])]),(item.department)?_c('span',{domProps:{"innerHTML":_vm._s(item.department)}}):_vm._e(),(item.deletedState > 0)?_c('span',[_vm._v("( "+_vm._s(_vm.$t("manufacturer-inactive"))+" )")]):_vm._e()],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.deletedState > 0 ? 'grey--text' : undefined},[(item.department)?_c('span',{domProps:{"innerHTML":_vm._s(item.name + ', ' + item.department)}}):_c('span',{domProps:{"innerHTML":_vm._s(item.name)}}),(item.deletedState > 0)?_c('span',{staticClass:"pl-2"},[_vm._v("( "+_vm._s(_vm.$t("manufacturer-inactive"))+" )")]):_vm._e()])]}},{key:"no-data",fn:function(){return [(
                    !_vm.search ||
                    (_vm.search &&
                        !_vm.isLoading &&
                        _vm.search.length <= _vm.searchMinLength)
                )?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-keyboard")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-results-no-search", { minLength: _vm.searchMinLength }))+" ")])],1):_vm._e(),(
                    _vm.manufacturersList.length == 0 &&
                    _vm.search &&
                    !_vm.isLoading &&
                    _vm.search.length >= _vm.searchMinLength
                )?_c('v-list-item',{on:{"click":function($event){_vm.dialogAddManufacturer = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i18n',{attrs:{"path":"add-manufacturer-text","tag":"span"},scopedSlots:_vm._u([{key:"manufacturer",fn:function(){return [_c('strong',{staticClass:"regOfficePrimary--text"},[_vm._v(_vm._s(_vm.search))])]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.dialogAddManufacturer = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,false,251162374)})],1)],1)],1):_vm._e()]},proxy:true},(_vm.manufacturersList.length > 0 && !_vm.selectedManufacturer)?{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),(
                    (_vm.manufacturersList.length == _vm.numberOfHits &&
                        _vm.numberOfHits > 0) ||
                    (_vm.manufacturersList.length == 0 &&
                        !_vm.isLoading &&
                        _vm.search.length >= _vm.searchMinLength)
                )?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){_vm.dialogAddManufacturer = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i18n',{attrs:{"path":"add-manufacturer-text","tag":"span"},scopedSlots:_vm._u([{key:"manufacturer",fn:function(){return [_c('strong',{staticClass:"regOfficePrimary--text"},[_vm._v(_vm._s(_vm.search))])]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.dialogAddManufacturer = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,false,251162374)})],1)],1)],1):_vm._e(),(
                    _vm.manufacturersList.length < _vm.numberOfHits &&
                    _vm.numberOfHits > 0
                )?_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_vm._v(" "+_vm._s(_vm.$t("all-items-match-loaded", { itemsLength: _vm.manufacturersList.length, numberOfHits: _vm.numberOfHits }))+" ")]),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" "+_vm._s(_vm.$t("no-more-items", { type: _vm.$t("type") }))+" ")]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(_vm._s(_vm.$t("no-results")))])]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.selectedManufacturer),callback:function ($$v) {_vm.selectedManufacturer=$$v},expression:"selectedManufacturer"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners)),_c('DialogAddress',{attrs:{"value":_vm.dialogAddManufacturer,"requiredFields":_vm.manufacturersRequiredFields,"title":_vm.$t('manufacturer-dialog-title'),"address":_vm.address},on:{"save":_vm.onSaveManufacturer,"input":function($event){_vm.dialogAddManufacturer = $event},"address:input":function (newAddress) {
                _vm.address = newAddress;
            },"address:reset":_vm.onResetAddress}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }