<i18n src="./form-create-address-i18n.yaml"></i18n>
<template>
    <div>
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="validate">
                    <v-icon>mdi-alert-circle-check-outline</v-icon>
                </base-btn>
                @Address:
                <tree-view
                    :data="address"
                    :options="{ maxDepth: 1 }"
                ></tree-view>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
            </template>
        </app-dev-container>
        <v-form :readonly="readonlyModus" :disabled="disabled">
            <v-row>
                <v-col>
                    <base-radio-group
                        class="py-0 mt-0"
                        :value="address.addressType"
                        @change="onAddressTypeChange"
                        :required="isRequiredField($v.addressType)"
                        :error-messages="addressTypeErrors"
                        :label="computedAddressTypeLabel"
                    >
                        <v-radio :value="ADDRESS_TYPES.COMPANY" class="pl-2">
                            <template v-slot:label>
                                <v-icon> mdi-office-building </v-icon>
                                <span class="pl-1">
                                    {{ computedAddressTypeCompanyLabel }}
                                </span>
                            </template>
                        </v-radio>
                        <v-radio class="pl-2" :value="ADDRESS_TYPES.PRIVATE">
                            <template v-slot:label>
                                <v-icon> mdi-account </v-icon>
                                <span class="pl-1">
                                    {{ computedAddressTypePrivateLabel }}
                                </span>
                            </template>
                        </v-radio>
                    </base-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-0" cols="12" sm="6">
                    <WCountrySelect
                        ref="country"
                        v-show="address.addressType"
                        :preferred-countries="[
                            'CH',
                            'LI',
                            'DE',
                            'FR',
                            'IT',
                            'AT'
                        ]"
                        v-model="country"
                        @click:clear="country = null"
                        :error-messages="countryErrors"
                        :readonly="readonlyModus"
                        :disabled="disabled"
                    ></WCountrySelect>
                </v-col>
            </v-row>
        </v-form>
        <uid-search
            v-show="
                address.country === 'CH' &&
                address.addressType === ADDRESS_TYPES.COMPANY
            "
            closeSearchCard
            v-on:confirm-selected-company="onConfirmSelectedCompany"
            v-on:change-manual-entry="$vuetify.goTo($refs.formCommunication)"
            ref="uidSearch"
            class="pb-6"
            :readonlyModus="readonlyModus"
            :disabled="disabled"
        >
        </uid-search>
        <form-company-address
            v-show="
                address.addressType === ADDRESS_TYPES.COMPANY && address.country
            "
            ref="formCompanyAddress"
            :country="address.country"
            :addressType="address.addressType"
            v-on:field-change="onFieldChange"
            :formData.sync="address"
            :readonlyModus="readonlyModus"
            :disabled="disabled"
            :required="address.addressType === ADDRESS_TYPES.COMPANY"
        >
        </form-company-address>
        <form-private-address
            v-show="
                address.addressType === ADDRESS_TYPES.PRIVATE && address.country
            "
            ref="formPrivateAddress"
            v-on:field-change="onFieldChange"
            :country="address.country"
            :formData.sync="address"
            :readonlyModus="readonlyModus"
            :disabled="disabled"
            :required="address.addressType === ADDRESS_TYPES.PRIVATE"
        >
        </form-private-address>
        <v-switch
            v-show="address.country && address.addressType"
            :disabled="disabled"
            class="ml-3 py-0"
            :label="communicationTitle"
            v-model="communication"
            :readonlyModus="readonlyModus"
            @change="onCommunicationSwitchChange"
        >
        </v-switch>
        <form-communication
            class="pt-5 mb-0 pb-0"
            v-show="communication && address.country && address.addressType"
            ref="formCommunication"
            v-on:field-change="onCommunicationFieldsChange"
            :required="communication"
            :formData.sync="address.communication"
            :readonlyModus="readonlyModus"
            :disabled="disabled"
        >
        </form-communication>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import UidSearch from "../components/uid-search.vue";
import WCountrySelect from "@/components/fields/WCountrySelect.vue";
import FormCompanyAddress from "./form-company-address.vue";
import FormPrivateAddress from "./form-private-address.vue";
import FormCommunication from "./form-communication.vue";
import { ADDRESS_TYPES } from "@/data/globals.js";
import {
    CompanyAddress,
    PrivateAddress,
    Communication,
    Address
} from "@/models/tasks";
import _ from "lodash";
import validateMixin from "@/mixins/validate";

export default {
    name: "FormCreateAddress",
    mixins: [validateMixin],
    components: {
        UidSearch,
        WCountrySelect,
        FormCompanyAddress,
        FormPrivateAddress,
        FormCommunication
    },
    validations: {
        addressType: { required },
        address: {
            country: {
                required
            }
        }
    },
    props: {
        typeOfAddress: {
            type: String,
            default: null
        },
        formData: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        addressTypeLabel: {
            type: String,
            default: null
        },
        addressTypeCompanyLabel: {
            type: String,
            default: null
        },
        addressTypePrivateLabel: {
            type: String,
            default: null
        }
    },
    data: () => ({
        address: new Address(),
        addressType: null,
        communication: false,
        ADDRESS_TYPES: ADDRESS_TYPES
    }),
    methods: {
        onFieldChange({ field, value }) {
            this.$emit("field-change", {
                address: this.addressField,
                field: field,
                value: value
            });
        },
        onCommunicationFieldsChange(field, value) {
            if (!field) return;
            let commucationForm = {};
            if (
                this.address.communication &&
                Object.keys(this.address.communication).length > 0
            ) {
                commucationForm = _.cloneDeep(this.address.communication);
                commucationForm = _.set(commucationForm, field, value);
            } else {
                commucationForm = _.set(commucationForm, field, value);
            }
            this.onFieldChange({
                field: "communication",
                value: commucationForm
            });
        },
        onAddressChange() {
            this.$emit("address-change", this.address);
        },
        validate() {
            this.$v.$touch();
            if (this.addressType === ADDRESS_TYPES.COMPANY) {
                this.$refs.formCompanyAddress.validate();
            } else {
                this.$refs.formPrivateAddress.validate();
            }
            if (this.communication) {
                this.$refs.formCommunication.validate();
            }
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                if (this.addressType === ADDRESS_TYPES.COMPANY) {
                    this.$refs.formCompanyAddress.scrollToFirstError();
                } else {
                    this.$refs.formPrivateAddress.scrollToFirstError();
                }
                if (this.communication) {
                    this.$refs.formCommunication.scrollToFirstError();
                }
            });
        },
        isValid() {
            if (!this.$v.$anyDirty) {
                return null;
            }
            if (!this.country) return false;
            if (!this.addressType) return false;
            let isValid = true;
            let formCompanyAddressValid = null;
            let formPrivateAddressValid = null;
            let formCommunicationValid = null;
            if (this.addressType === ADDRESS_TYPES.COMPANY) {
                if (this.$refs.formCompanyAddress) {
                    formCompanyAddressValid =
                        this.$refs.formCompanyAddress.isValid();
                    if (!formCompanyAddressValid) {
                        isValid = false;
                    }
                }
            } else {
                if (this.$refs.formPrivateAddress) {
                    formPrivateAddressValid =
                        this.$refs.formPrivateAddress.isValid();
                    if (!formPrivateAddressValid) {
                        isValid = false;
                    }
                }
            }
            if (this.communication) {
                if (this.$refs.formCommunication) {
                    formCommunicationValid =
                        this.$refs.formCommunication.isValid();
                    if (!formCommunicationValid) {
                        isValid = false;
                    }
                }
            }
            if (
                formCompanyAddressValid === null &&
                formPrivateAddressValid === null &&
                formCommunicationValid === null
            ) {
                return null;
            }
            if (this.$v.$anyError) {
                isValid = false;
            }
            return isValid;
        },
        onClearAddress() {
            if (!this.addressField) return;
            this.$emit("clear-address", {
                field: this.addressField,
                address: this.address
            });
        },
        reset() {
            this.onClearAddress();
        },
        setAddressByType(type) {
            this.address = {};
            if (type === ADDRESS_TYPES.COMPANY) {
                this.address = new CompanyAddress();
            } else {
                this.address = new PrivateAddress();
            }
        },
        onAddressTypeChange(addressType) {
            this.setAddressByType(addressType);
            this.onClearAddress();
            this.onFieldChange({ field: "addressType", value: addressType });
        },
        onConfirmSelectedCompany(company) {
            this.onFieldChange({ field: "companyName", value: company.name });
            this.onFieldChange({ field: "street", value: company.street });
            this.onFieldChange({ field: "houseNr", value: company.houseNr });
            this.onFieldChange({ field: "zipCode", value: company.zipCode });
            this.onFieldChange({ field: "city", value: company.city });
            this.onFieldChange({ field: "canton", value: company.canton });
            this.onFieldChange({ field: "uid", value: company.uid });
            this.onFieldChange({
                field: "uidFormatted",
                value: company.uidFormatted
            });
            this.onFieldChange({
                field: "uidCategory",
                value: company.uidCategory
            });

            this.$nextTick(() => {
                this.$refs.formCompanyAddress.setAllDisabled(true);
            });
            this.showFormCompanyAddress = true;
        },
        checkIfFieldsAreEmpty(fields) {
            let empty = true;
            Object.keys(fields).forEach((key) => {
                if (
                    fields[key] &&
                    fields[key].length &&
                    fields[key] != "" &&
                    fields[key] != null
                ) {
                    empty = false;
                }
            });
            return empty;
        },
        setCommunication() {
            let communication = false;
            if (this.formData.communication) {
                if (this.checkIfFieldsAreEmpty(this.formData.communication)) {
                    communication = false;
                } else {
                    communication = true;
                }
            }
            this.communication = communication;
        },
        onCommunicationSwitchChange() {
            if (!this.communication) {
                this.onFieldChange({
                    field: "communication",
                    value: new Communication()
                });
            }
        }
    },
    computed: {
        computedAddressTypeLabel() {
            if (this.addressTypeLabel) {
                return this.addressTypeLabel;
            } else if (this.typeOfAddress) {
                return this.$t("address-type-label", {
                    type: this.$t(`type-of-address-${this.typeOfAddress}`)
                });
            } else {
                return null;
            }
        },
        computedAddressTypeCompanyLabel() {
            if (this.addressTypeCompanyLabel) {
                return this.addressTypeCompanyLabel;
            } else if (this.typeOfAddress) {
                return this.$t("address-type-company-label", {
                    type: this.$t(`type-of-address-${this.typeOfAddress}`)
                });
            } else {
                return null;
            }
        },
        computedAddressTypePrivateLabel() {
            if (this.addressTypePrivateLabel) {
                return this.addressTypePrivateLabel;
            } else if (this.typeOfAddress) {
                return this.$t("address-type-privat-label", {
                    type: this.$t(`type-of-address-${this.typeOfAddress}`)
                });
            } else {
                return null;
            }
        },
        addressTypeErrors() {
            const errors = [];
            if (!this.$v.addressType.$dirty) {
                return errors;
            }
            if (!this.$v.addressType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        countryErrors() {
            const errors = [];
            if (!this.$v.address.country.$dirty) {
                return errors;
            }
            if (!this.$v.address.country.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        communicationTitle() {
            if (this.typeOfAddress) {
                return this.$t("communication-title", {
                    type: this.$t(`type-of-address-${this.typeOfAddress}`)
                });
            }
            return null;
        },
        hasUID() {
            if (this.address && this.address.uid) {
                return true;
            }
            return false;
        },
        addressField() {
            if (!this.typeOfAddress) return null;
            return `${this.typeOfAddress}Address`;
        },
        country: {
            get() {
                return this.address ? this.address.country : "CH";
            },
            set(value) {
                this.setAddressByType(this.address.addressType);
                this.onClearAddress();
                this.onFieldChange({ field: "country", value });
            }
        }
    },
    watch: {
        formData: {
            handler: function (newData, oldVal) {
                if (newData && Object.keys(newData).length > 0) {
                    if ("addressType" in newData) {
                        this.addressType = newData.addressType;
                        this.address = {};
                        if (newData.addressType === ADDRESS_TYPES.COMPANY) {
                            this.address = new CompanyAddress();
                        } else {
                            this.address = new PrivateAddress();
                        }
                        this.address = Object.assign(this.address, newData);
                        if (!oldVal) {
                            this.setCommunication();
                        }
                        if (
                            this.address.addressType === ADDRESS_TYPES.COMPANY
                        ) {
                            let disableFields = false;
                            if (this.hasUID) {
                                disableFields = true;
                            }
                            this.$nextTick(() => {
                                this.$refs.formCompanyAddress.setAllDisabled(
                                    disableFields
                                );
                            });
                        }
                    } else {
                        this.address = new Address();
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
