<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./check-i18n.yaml"></i18n>
<template>
    <v-container pa-0 ma-0 fluid>
        <v-row align="center" justify="center">
            <template
                v-if="(!task || Object.keys(task).length === 0) && isLoading"
            >
                <v-col cols="12" sm="6">
                    <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </template>
            <v-col cols="12" md="10">
                <app-dev-container>
                    <template v-slot:content>
                        <tree-view
                            :data="Object.assign({}, task)"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                    </template>
                </app-dev-container>
                <TaskCompleteView :task="task" show-copy-btn>
                    <template v-slot:prepend>
                        <base-alert type="warning" dense>
                            <i18n path="msg-info-insurer">
                                <template #operator>
                                    <b>{{ operatorCompanyName }}</b>
                                </template>
                                <template #specialistCompany>
                                    <b>{{ task.specialistCompany.name }}</b>
                                </template>
                            </i18n>
                        </base-alert>
                    </template>
                </TaskCompleteView>
                <v-card-actions>
                    <base-btn
                        type="secondary"
                        @click="onDecline"
                        btn-icon="mdi-close"
                        >{{ $t("btn-decline") }}</base-btn
                    >
                    <v-spacer></v-spacer>
                    <base-btn
                        type="primary"
                        @click="onConfirm"
                        btn-icon="mdi-check"
                        >{{ $t("btn-confirm") }}</base-btn
                    >
                </v-card-actions>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import * as ACTION_TYPES from "@/store/types/action-types";
import { RegistrationBackoffice } from "@/models";
import { TaskStatus } from "@/data/globals";
export default {
    components: {
        TaskCompleteView: () =>
            import("@/components/tasks/common/task-complete-view.vue")
    },

    props: {
        referenceId: {
            type: String,
            default: null
        }
    },

    data: () => ({
        isEditing: true,
        task: new RegistrationBackoffice()
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        operatorCompanyName() {
            return this.task?.operatorAddress?.companyName || null;
        },
        specialistCompanyName() {
            if (!this.task?.specialistCompany) return;
            return this.task?.specialistCompany?.name;
        }
    },
    methods: {
        ...mapActions("insurer", [
            ACTION_TYPES.FETCH_REGISTRATION_DATA,
            ACTION_TYPES.SET_APPROVAL_REQUIRED_STATE
        ]),
        getData() {
            if (!this.referenceId) {
                return;
            }
            this.loader().show();
            let data = {};
            data.referenceId = this.referenceId;
            this[ACTION_TYPES.FETCH_REGISTRATION_DATA](data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        this.task = resData.data;
                        const taskState = this.task.state;
                        if (
                            taskState !==
                            TaskStatus.TASK_INSURER_APPROVAL_REQUIRED_POOL
                        ) {
                            this.$router.push({ name: "404" });
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus = error.response.status;
                    if (resStatus === 404) {
                        this.$router.push({ name: "404" });
                    } else if (resStatus === 403) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.$router.push({ name: "500" });
                    }
                    this.loader().hide();
                });
        },
        goToInsurerDashboard() {
            this.$router.push({ name: "insurer-dashboard" });
        },
        onConfirm() {
            if (!this.referenceId) {
                return;
            }
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-confirm-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-confirm-msg")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.loader().show();
                    let data = {};
                    data.referenceId = this.referenceId;
                    data.approved = true;
                    this[ACTION_TYPES.SET_APPROVAL_REQUIRED_STATE](data)
                        .then((response) => {
                            let status = response.status;
                            if (status === 200) {
                                this.$toast.success(
                                    this.$t("msg-confirm-success")
                                );
                                this.goToInsurerDashboard();
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.error(this.$t("msg-confirm-error"));
                            this.loader().hide();
                        });
                }
            });
        },
        onDecline() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-decline-reason-title"),
                    icon: "mdi-alert"
                },
                type: "reason",
                message: this.$t("msg-box-decline-reason-text")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result.action === "agree") {
                    if (!this.referenceId) {
                        return;
                    }
                    this.loader().show();
                    let data = {};
                    data.referenceId = this.referenceId;
                    data.approved = false;
                    data.declineReason = result.reason;
                    this[ACTION_TYPES.SET_APPROVAL_REQUIRED_STATE](data)
                        .then((response) => {
                            let status = response.status;
                            if (status === 200) {
                                this.$toast.success(
                                    this.$t("msg-decline-success")
                                );
                                this.goToInsurerDashboard();
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.error(this.$t("msg-decline-error"));
                            this.loader().hide();
                        });
                }
            });
        }
    },

    mounted() {
        if (this.referenceId) {
            this.getData();
        } else {
            if (this.isAuth) {
                this.goToInsurerDashboard();
            } else {
                this.$router.push("/");
            }
        }
    }
};
</script>
