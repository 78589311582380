import { axiosTasks, axiosDialogs, axiosBinary } from '@/api/axios-instances';
import Helpers from '@/utils/helpers'
import _ from "lodash";
const state = {
    dialogs : [],
    registrationData : {}
};

const mutations = {
    setDialogs(state, dialogs) {
        state.dialogs = dialogs;
    },
    setRegistrationData(state, payload) {
        state.registrationData = payload;
    },
    editMessageDialog(state, message) {
        const dialogs = state.dialogs;
        const dialogAndMsgIdx = Helpers.getDialogAndMsgIndexOrNull(dialogs, message.messageId);
        if (dialogAndMsgIdx) {
            const dialogIdx = dialogAndMsgIdx[0];
            const msgIdx = dialogAndMsgIdx[1];
            const dialogFound = state.dialogs[dialogIdx];
            const msgFound = dialogFound.messages[msgIdx];
            if ((msgFound.question && msgFound.question.text)
                && (!msgFound.reply || !msgFound.reply.text)) {
                msgFound.question.text = message.text;
            }
            if ((msgFound.question && msgFound.question.text)
                && (msgFound.reply && msgFound.reply.text)) {
                _.set(msgFound.reply, 'text', message.text);
            }
            dialogs[dialogIdx] = dialogFound;
        }
        _.set(state, 'dialogs', [...dialogs]);
    },
    addReplyToDialog(state, reply) {
        const dialogs = state.dialogs;
        const dialogAndMsgIdx = Helpers.getDialogAndMsgIndexOrNull(dialogs, reply.messageId);
        if (dialogAndMsgIdx) {
            const dialogIdx = dialogAndMsgIdx[0];
            const msgIdx = dialogAndMsgIdx[1];
            const dialogFound = state.dialogs[dialogIdx];
            const msgFound = dialogFound.messages[msgIdx];
            delete reply.messageId
            delete reply.taskDocId
            delete reply.id
            msgFound.reply = Object.assign({}, reply);
            dialogFound.state = 30;
            dialogs[dialogIdx] = dialogFound;
        }
        _.set(state, 'dialogs', [...dialogs]);
    },
    deleteMessagesgFromDialog(state, message) {
        const dialogs = state.dialogs;
        const dialogAndMsgIdx = Helpers.getDialogAndMsgIndexOrNull(dialogs, message.messageId);
        if (dialogAndMsgIdx) {
            const dialogIdx = dialogAndMsgIdx[0];
            const msgIdx = dialogAndMsgIdx[1];
            const dialogFound = state.dialogs[dialogIdx];
            const msgFound = dialogFound.messages[msgIdx];
            if (dialogFound.state === 20) {
                const messages = dialogFound.messages.splice(msgIdx, 1);
                dialogs[dialogIdx].messages = messages;
                dialogFound.state = 0;
            }
            if (dialogFound.state === 30) {
                msgFound.reply = {};
                dialogFound.messages[msgIdx] = msgFound;
                dialogFound.state = 20;
            }

            if ([20, 30].includes(dialogFound.state)) {
                dialogs[dialogIdx] = dialogFound;
            }
        }
        _.set(state, 'dialogs', [...dialogs]);
    },
    addAttachmentToMsg(state, {attachment, msgId}){
        let dialogs = state.dialogs;
        const dialogAndMsgIdx = Helpers.getDialogAndMsgIndexOrNull(dialogs, msgId);
        if (dialogAndMsgIdx) {
            const dialogIdx = dialogAndMsgIdx[0];
            const msgIdx = dialogAndMsgIdx[1];
            if (dialogIdx !== -1 && msgIdx !== -1 ) {
                const attachmentCategory = Object.keys(attachment)[0];
                const attachmentTypeList = Object.keys(attachment[attachmentCategory])[0];
                if (!dialogs[dialogIdx]?.messages[msgIdx]?.attachments){
                    // create attachments key if key is missing in the data
                    // otherwise will run into an error
                    _.set(dialogs[dialogIdx]?.messages[msgIdx], 'attachments', {});
                }
                const attachmentsHasCategory = dialogs[dialogIdx]?.messages[msgIdx]?.attachments[attachmentCategory];
                let attachmentsHasCategoryList = null;
                if (attachmentsHasCategory){
                    attachmentsHasCategoryList = dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList];
                }
                if (!attachmentsHasCategory && !attachmentsHasCategoryList){
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory] = {}
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList] = [];
                } else if (attachmentsHasCategory && !attachmentsHasCategoryList) {
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList] = [];
                }
                dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList].push(attachment[attachmentCategory][attachmentTypeList][0])
            }
        }
        _.set(state, 'dialogs', [...dialogs]);
    },
    deleteAttachmentFromMsg(state, {msgId, attachment}){
        let dialogs = state.dialogs;
        const dialogAndMsgIdx = Helpers.getDialogAndMsgIndexOrNull(dialogs, msgId);
        if (dialogAndMsgIdx) {
            const dialogIdx = dialogAndMsgIdx[0];
            const msgIdx = dialogAndMsgIdx[1];
            if (dialogIdx !== -1 && msgIdx !== -1 ) {
                const attachmentCategory = attachment.category;
                let attachmentTypeList = null;
                if (attachment.fileType === "pdf"){
                    attachmentTypeList = "documents"
                } else {
                    attachmentTypeList = "photos"
                }
                const attachmentsHasCategory = dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory];
                if (!attachmentsHasCategory && !attachmentTypeList){
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory] = {}
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList] = [];
                } else if (attachmentCategory && !attachmentTypeList) {
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList] = [];
                }
                const attachmentIdx = _.findIndex(dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList], {
                    id : attachment.id
                })
                // remove attachment from category and listType (documents or photos)
                if (attachmentIdx !== -1){
                    dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList].splice(attachmentIdx, 1)
                }
                //check if photos or documents list are empty, if true, remove list from category
                const attachmentsCatList = dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList];
                if (attachmentsCatList.length === 0){
                    // remove it
                    delete dialogs[dialogIdx].messages[msgIdx].attachments[attachmentCategory][attachmentTypeList];
                }
                //check if category has photos or documents keys, if true, remove category from attachments
                const attachmentsCatKeys = Object.keys(dialogs[dialogIdx].messages[msgIdx].attachments);
                if (attachmentsCatKeys.length > 0){
                    _.forEach(attachmentsCatKeys, (key) => {
                        //check if is empty
                        const listType = dialogs[dialogIdx].messages[msgIdx].attachments[key];
                        if (Object.keys(listType).length === 0){
                            // remove it
                            delete dialogs[dialogIdx].messages[msgIdx].attachments[key]

                        }
                    })
                }
            }
        }
        _.set(state, 'dialogs', [...dialogs]);
    }
};

const getters = {};

const actions = {
    getQuestionsByDocId({commit}, taskDocId) {
        return new Promise((resolve, reject) => {
            axiosDialogs.get(`/${taskDocId}`)
            .then(response => {
                const question = response.data.question;
                const registrationData = response.data.taskData;
                commit('setQuestion', question);
                commit('setRegistrationData', registrationData);
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    createDialogQuestion(_ctx, question) {
        const taskDocId = question.taskDocId;
        return new Promise((resolve, reject) => {
            axiosDialogs.post(`/${taskDocId}`, question)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    deleteDialogQuestion(_ctx, dialog) {
        const taskDocId = dialog.taskDocId;
        return new Promise((resolve, reject) => {
            axiosDialogs.delete(`/${taskDocId}`, {data: dialog})
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    addReply(_ctx, reply) {
        const taskReferenceId = reply.referenceId;
        return new Promise((resolve, reject) => {
            axiosDialogs.put(`/reply/${taskReferenceId}`, reply)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    addQuestion(_ctx, question) {
        const taskReferenceId = question.referenceId;
        return new Promise((resolve, reject) => {
            axiosDialogs.put(`/question/${taskReferenceId}`, question)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    editMessage(_ctx, message) {
        const taskReferenceId = message.referenceId;
        const messageId = message.messageId;
        return new Promise((resolve, reject) => {
            axiosDialogs.put(`/message/${taskReferenceId}/${messageId}`, message)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    deleteMessage(_ctx, message) {
        const taskReferenceId = message.referenceId;
        const messageId = message.messageId;
        return new Promise((resolve, reject) => {
            axiosDialogs.delete(`/message/${taskReferenceId}/${messageId}`, {data: message})
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    acceptAndClose(_ctx, data) {
        const taskDocId = data.docId;
        return new Promise((resolve, reject) => {
            axiosDialogs.put(`/${taskDocId}`, data)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    getAllDialogsAndTaskDataByReferenceId({commit}, referenceId) {
        return new Promise((resolve, reject) => {
            axiosTasks.get(`/getAllDialogsAndTaskDataByReferenceId/${referenceId}`)
            .then(response => {
                const resData = response.data.data;
                const dialogs = resData.dialogs;
                const registrationData = resData.taskData;
                commit('setDialogs', dialogs);
                commit('setRegistrationData', registrationData);
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
    dialogsSendReminderMail: (_ctx, referenceId) => {
        return new Promise((resolve, reject) => {
            axiosDialogs.post(`/sendReminderMail/${referenceId}`)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    deleteMsgAttachment(_ctx, {attachment, msgId}){
        const data = {};
        data.docId = attachment.taskDocId;
        data.msgId = msgId;
        data.attachmentId = attachment.id;
        return new Promise((resolve, reject) => {
            axiosBinary.delete('/dialog/', {data})
            .then(response => {
                _ctx.commit('deleteAttachmentFromMsg', {
                    msgId,
                    attachment
                });
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
