<i18n src="./content-i18n.yaml"></i18n>
<template>
    <base-card>
        <BackofficeBaseList
            :items="items"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            @reloadDataClick="fetchOverdueRegistrations"
            :funcShowAction="showAction"
            item-key="docId"
            @task:force-take-over="onTaskForceTakeOver"
            @task:unlock="onTaskUnlock"
        >
            <template v-slot:boReminder="{ item }">
                <span class="warning--text">
                    <v-icon color="warning"> mdi-alert </v-icon>
                    {{ $moment.utc(item.boReminder).local().from($moment()) }}
                </span>
            </template>
        </BackofficeBaseList>
    </base-card>
</template>
<script>
import { mapState } from "vuex";
import taskList from "@/mixins/backoffice/taskList";
import { TaskActionTypes } from "@/data/task-action-types";
import { TaskStatus } from "@/data/globals";
import BackofficeBaseList from "@/components/backoffice/components/base-list.vue";

export default {
    name: "BackofficeOverdueTasksContent",
    mixins: [taskList],
    components: {
        BackofficeBaseList
    },
    data() {
        return {
            dataDialog: false,
            actionsItems: [
                TaskActionTypes.VIEW,
                TaskActionTypes.VIEW_CHECK,
                TaskActionTypes.DATA,
                TaskActionTypes.UNLOCK_TASK,
                TaskActionTypes.FORCE_TAKE_OVER,
                TaskActionTypes.VIEW_HISTORY
            ]
        };
    },
    computed: {
        ...mapState("backoffice", {
            items: "overdueTasks"
        }),
        ...mapState("auth", ["accountDocId"]),

        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-employee"),
                    value: "boEmployee",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-reminder"),
                    value: "boReminder",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        }
    },
    methods: {
        fetchOverdueRegistrations() {
            this.loader().show();
            this.$store
                .dispatch("backoffice/fetchOverdueRegistrations")
                .then(() => {
                    this.loader().hide();
                })
                .catch(() => {
                    this.loader().hide();
                });
        },
        onTaskUnlock(task) {
            this.loader().show();
            const taskDocId = task.docId;
            this.$store
                .dispatch("backoffice/registrationUnlockForReview", taskDocId)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-task-unlock-success"));
                        this.$store.commit(
                            "backoffice/removeRegistrationFromList",
                            {
                                list: "overdueTasks",
                                docId: task.docId
                            }
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-task-unlock-error"));
                    this.loader().hide();
                });
        },
        onTaskForceTakeOver(task) {
            this.loader().show();
            const taskDocId = task.docId;
            this.$store
                .dispatch("backoffice/registrationForceTakeOver", taskDocId)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-task-force-take-over-success")
                        );
                        this.$store.commit(
                            "backoffice/removeRegistrationFromList",
                            {
                                list: "overdueTasks",
                                docId: taskDocId
                            }
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(
                        this.$t("msg-task-force-take-over-error")
                    );
                    this.loader().hide();
                });
        },
        showAction(item, actionItem) {
            const itemState = item.state;
            const action = actionItem.action;
            let boAccountDocId = null;
            if (item.boEmployee) {
                boAccountDocId = item.boEmployee.docId;
            }
            if (item.deletedState > 0) return false;
            if (
                action === TaskActionTypes.DELETE ||
                action === TaskActionTypes.REMOVE_FROM_DB ||
                action === TaskActionTypes.VIEW_CHECK
            ) {
                if (itemState >= TaskStatus.TASK_CONFIRMED) return false;
            }
            if (action === TaskActionTypes.VIEW) {
                if (boAccountDocId === this.accountDocId) {
                    return false;
                }
            }
            if (
                action === TaskActionTypes.VIEW_CHECK ||
                action === TaskActionTypes.UNLOCK_TASK
            ) {
                if (boAccountDocId !== this.accountDocId) {
                    return false;
                }
            }
            if (action === TaskActionTypes.FORCE_TAKE_OVER) {
                if (boAccountDocId === this.accountDocId || !boAccountDocId) {
                    return false;
                }
            }
            if (
                action === TaskActionTypes.UNLOCK_TASK ||
                action === TaskActionTypes.FORCE_TAKE_OVER
            ) {
                if (
                    itemState <
                        TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS ||
                    itemState >= TaskStatus.TASK_CONFIRMED
                ) {
                    return false;
                }
            }
            return true;
        }
    },

    created() {
        if (this.isAuth) {
            this.fetchOverdueRegistrations();
        }
    }
};
</script>
