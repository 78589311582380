var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('ModalMovableDialog',{ref:"modalMovableDialog",attrs:{"toolbarTitle":_vm.$t('title', { step: _vm.$t(("step-" + _vm.step)) }),"value":_vm.dialog,"toolbarIcon":"mdi-comment-question","clsCardActions":_vm.$vuetify.breakpoint.mdAndUp ? 'pl-12 pr-10' : null,"clsCardContent":_vm.$vuetify.breakpoint.mdAndUp ? 'px-10' : 'pa-3'},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(
                _vm.registrationDialogData.state !=
                    _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                _vm.formCloseDialog.acceptCloseDialog &&
                _vm.lastMessage &&
                !_vm.lastMessage.reply
            )?_c('base-alert',{attrs:{"type":"warning"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("alert-msg-open-question"))+" ")])]):_vm._e(),(
                _vm.registrationDialogData.state !=
                    _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                !_vm.formCloseDialog.acceptCloseDialog &&
                _vm.lastMessage &&
                !_vm.lastMessage.reply &&
                _vm.registrationData.lang &&
                _vm.isBackofficeEditor
            )?_c('base-alert',{attrs:{"dense":"","type":"warning"}},[_c('div',[_c('i18n',{attrs:{"path":"registrant-prefered-lang-alert","tag":"span"},scopedSlots:_vm._u([{key:"lang",fn:function(){return [_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.$t(_vm.registrationData.lang))+" ("+_vm._s(_vm.registrationData.lang)+")")])]},proxy:true}],null,false,907927311)})],1)]):_vm._e(),_c('app-dev-container',{staticClass:"my-3",scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" @isBackofficeEditor: "+_vm._s(_vm.isBackofficeEditor)+" "),_c('br'),_vm._v(" @isRegistrantEditor: "+_vm._s(_vm.isRegistrantEditor)+" "),_c('br'),_vm._v(" @showDialogTextArea: "+_vm._s(_vm.showDialogTextArea)+" "),_c('br'),_vm._v(" @registrationData: "),_c('tree-view',{attrs:{"data":_vm.registrationData,"options":{ maxDepth: 0 }}}),_c('br'),_vm._v(" @registrationDialogData: "),_c('tree-view',{attrs:{"data":Object.assign({}, _vm.registrationDialogData),"options":{ maxDepth: 0 }}}),_c('br'),_vm._v(" @lastMessage: "),_c('tree-view',{attrs:{"data":Object.assign({}, _vm.lastMessage),"options":{ maxDepth: 0 }}}),_c('br'),_vm._v(" @sortedMessages: "),_c('tree-view',{attrs:{"data":Object.assign({}, _vm.sortedMessages),"options":{ maxDepth: 0 }}}),_c('br'),_vm._v(" @historyMessages: "),_c('tree-view',{attrs:{"data":Object.assign({}, _vm.historyMessages),"options":{ maxDepth: 0 }}})]},proxy:true}],null,false,756355300)}),_c('MessagesCard',{attrs:{"historyMessages":_vm.historyMessages,"lastMessage":Object.assign({}, _vm.lastMessage),"accountDocId":_vm.accountDocId,"canRegistrantEdit":_vm.canRegistrantEdit,"canBackofficeEdit":_vm.canBackofficeEdit,"editMessageModus":_vm.editMessageModus},on:{"edit":_vm.onMessageEdit,"delete":_vm.onMessageDelete}}),_c('RegistrationDialogModalEdit',{attrs:{"dialog":_vm.editMessageModus,"label":_vm.$t('text-label', {
                    type: _vm.isRegistrantEditor ? _vm.$t('reply') : _vm.$t('question')
                }),"placeholder":_vm.$t('text-placeholder', {
                    type: _vm.isRegistrantEditor ? _vm.$t('reply') : _vm.$t('question')
                }),"title":_vm.$t('message-edit-dialog', {
                    type: _vm.isRegistrantEditor ? _vm.$t('reply') : _vm.$t('question')
                }),"text":_vm.text},on:{"cancel":function($event){_vm.editMessageModus = false},"save":_vm.onMessageEditSave,"text-change":function($event){_vm.text = $event}}}),_c('FormAdditionalData',{directives:[{name:"show",rawName:"v-show",value:(
                (_vm.requireAttachments || _vm.requireChambers) &&
                _vm.isBackofficeEditor &&
                _vm.registrationDialogData.state >=
                    _vm.TaskDialogStatus.TASK_DIALOG_DEFAULT &&
                _vm.showDialogTextArea
            ),expression:"\n                (requireAttachments || requireChambers) &&\n                isBackofficeEditor &&\n                registrationDialogData.state >=\n                    TaskDialogStatus.TASK_DIALOG_DEFAULT &&\n                showDialogTextArea\n            "}],ref:"formAdditionalData",attrs:{"disabled":_vm.disableFormAdditionalData,"requireAttachments":_vm.requireAttachments,"requireChambers":_vm.requireChambers,"formData":_vm.formAdditionalData},on:{"update":_vm.onFormAdditionalDataUpdate}}),(_vm.msgRequireAttachments && _vm.canRegistrantEdit)?_c('v-container',{staticClass:"pa-0 ma-0 pb-4",attrs:{"fluid":""}},[_c('v-alert',{staticClass:"pa-0",attrs:{"color":_vm.fileUploaderColor,"tile":"","outlined":""}},[_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":_vm.uploadedCategories.length ===
                                _vm.msgRequireAttachmentsCategories.length
                                    ? 'success'
                                    : _vm.fileUploaderColor},domProps:{"innerHTML":_vm._s(_vm.fileUploaderIcon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap",class:(_vm.fileUploaderColor + "--text"),domProps:{"textContent":_vm._s(
                                _vm.uploadedCategories.length ===
                                _vm.msgRequireAttachmentsCategories.length
                                    ? _vm.$tc(
                                          'attachments-upload-card-title',
                                          _vm.lastMessageAttachmentsCounters[0],
                                          {
                                              count: _vm.lastMessageAttachmentsCounters[0]
                                          }
                                      )
                                    : _vm.$t(
                                          'upload-missing-multiple',
                                          _vm.missingCategories.length
                                      )
                            )}})],1)],1),_c('v-divider',{staticClass:"mx-2 info",staticStyle:{"opacity":"0.22"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grow"},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.msgRequireAttachmentsCategories),function(cat){return _c('v-list-item',{key:cat,on:{"click":function($event){_vm.uploadFileCategory = cat;
                                    _vm.modalFileUploader = true;}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":_vm.getCategoryIconColorByErrorStateAndCategory(
                                                cat
                                            )},domProps:{"innerHTML":_vm._s(
                                            _vm.getCategoryIconByErrorStateAndCategory(
                                                cat
                                            )
                                        )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap",class:_vm.getCategoryTextColorByErrorStateAndCategory(
                                                cat
                                            ),domProps:{"textContent":_vm._s(
                                            _vm.$t(("attachment-category-" + cat))
                                        )}})],1)],1)}),1)],1),(
                            _vm.lastMessageAttachmentsCounters[0] > 0 &&
                            _vm.registrationDialogData.state <=
                                _vm.TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                        )?_c('v-col',{class:!_vm.$vuetify.breakpoint.xsOnly
                                ? 'shrink mt-4 mr-4'
                                : 'pa-5',attrs:{"align-self":"start","cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 0}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-badge',{attrs:{"content":_vm.lastMessageAttachmentsCounters[0],"bordered":"","overlap":""}},[_c('base-btn',{attrs:{"icon":!_vm.$vuetify.breakpoint.xsOnly,"block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":function($event){_vm.dialogAttachmentsView = true}}},[_c('v-icon',_vm._g({attrs:{"left":_vm.$vuetify.breakpoint.xsOnly}},on),[_vm._v("mdi-file-eye")]),(
                                                _vm.$vuetify.breakpoint.xsOnly
                                            )?_c('span',[_vm._v(_vm._s(_vm.$t("tooltip-uploaded-files")))]):_vm._e()],1)],1)]}}],null,false,363515544)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip-uploaded-files")))])])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.isRegistrantEditor)?_c('v-row',[(_vm.uploadFileCategory)?_c('v-col',{attrs:{"cols":"12"}},[_c('modal-file-uploader',{attrs:{"value":_vm.modalFileUploader,"docId":_vm.registrationData.docId,"fileCategory":_vm.uploadFileCategory,"url":_vm.$axiosBinary.defaults.baseURL + '/dialog/',"params":_vm.fileUploaderParams},on:{"input":function($event){_vm.modalFileUploader = $event},"upload:success":_vm.onUploadSuccess}})],1):_vm._e()],1):_vm._e(),(_vm.dialogAttachmentsView)?_c('v-row',[_c('v-col',[_c('DialogAttachments',{attrs:{"value":_vm.dialogAttachmentsView,"readonlyModus":!_vm.isRegistrantEditor,"attachments":Object.assign({}, _vm.lastMessage.attachments)},on:{"input":function($event){_vm.dialogAttachmentsView = $event},"attachment:delete":_vm.onDeleteAttachment,"attachment:delete-multiple":_vm.onDeleteMultipleAttachment}})],1)],1):_vm._e(),(_vm.showDialogTextArea)?_c('v-row',[_c('v-col',[_c('base-textarea',{ref:"textArea",attrs:{"label":_vm.$t('text-label', {
                            type: _vm.isRegistrantEditor
                                ? _vm.$t('reply')
                                : _vm.$t('question')
                        }),"placeholder":_vm.$t('text-placeholder', {
                            type: _vm.isRegistrantEditor
                                ? _vm.$t('reply')
                                : _vm.$t('question')
                        }),"clearable":"","autofocus":"","no-resize":"","counter":_vm.$v.text.$params.maxLength.max,"error-messages":_vm.textErrors,"required":_vm.isRequiredField(_vm.$v.text)},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBackofficeEditor && _vm.registrationDialogData.state > 10),expression:"isBackofficeEditor && registrationDialogData.state > 10"}]},[_c('v-col',[_c('FormCloseDialog',{ref:"formCloseDialog",attrs:{"disabled":_vm.registrationDialogData.state ==
                        _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED,"formData":_vm.formCloseDialog},on:{"update":_vm.onFormCloseDialogUpdate}})],1)],1),(_vm.isBackofficeEditor && _vm.showDialogTextArea)?_c('v-row',[_c('v-col',[_c('PreFormulatedQuestions',{attrs:{"step":_vm.step,"registrationData":_vm.registrationData},on:{"change":_vm.onPreFormulatedQuestionsChange}})],1)],1):_vm._e()]},proxy:true},(
            _vm.registrationDialogData.state <
                _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
            (_vm.canBackofficeEdit || _vm.canRegistrantEdit)
        )?{key:"actions",fn:function(){return [_c('v-spacer'),(
                _vm.showDialogTextArea &&
                !_vm.formCloseDialog.acceptCloseDialog &&
                _vm.registrationDialogData.state >
                    _vm.TaskDialogStatus.TASK_DIALOG_DEFAULT
            )?_c('base-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"type":"save"},on:{"click":_vm.onAddMessageToDialog}},[_vm._v(" "+_vm._s(_vm.$t("btn-save-and-close", { type: _vm.isRegistrantEditor ? _vm.$t("reply") : _vm.$t("question") }))+" ")]):_vm._e(),(
                _vm.isBackofficeEditor &&
                !_vm.isRegistrantEditor &&
                _vm.formCloseDialog.acceptCloseDialog
            )?_c('base-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"type":"save"},on:{"click":_vm.onAcceptAndClose}},[_vm._v(" "+_vm._s(_vm.$t("btn-accept-and-close"))+" ")]):_vm._e(),(
                _vm.registrationDialogData.state ===
                    _vm.TaskDialogStatus.TASK_DIALOG_DEFAULT &&
                _vm.registrationDialogData.messages.length === 0
            )?_c('base-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"type":"save"},on:{"click":_vm.onAddMessageToDialog}},[_vm._v(" "+_vm._s(_vm.$t("btn-dialog-create"))+" ")]):_vm._e()]},proxy:true}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }