import { axiosAuth, axiosAccountSettings } from "@/api/axios-instances";
import router from "@/router";
import { ACCOUNT_ROLE, ADDRESS_IDS } from "@/data/globals.js";
import i18n from "@/lang/lang.js";

const state = {
    token: null,
    mail: null,
    accountDocId: null,
    accountSettingsDocId: null,
    salutation: null,
    firstname: null,
    familyname: null,
    lang: null,
    roles: [],
    addresses: []
};

const mutations = {
    setAccountsCounter(state, counter) {
        state.accountsCounter = counter;
    },
    authUser(state, userData) {
        if (userData.token) {
            state.token = userData.token;
        }
        if (userData.mail) {
            state.mail = userData.mail;
        }
    },
    clearAuthData(state) {
        state.token = null;
        state.mail = null;
        window.$cookies.remove("authtoken");
        window.$cookies.remove("mail");
    },
    setUserData(state, user) {
        if (user.accountDocId) {
            state.accountDocId = user.accountDocId;
        }
        if (user.accountSettingsDocId) {
            state.accountSettingsDocId = user.accountSettingsDocId;
        }
        if (user.salutation) {
            state.salutation = user.salutation;
        }
        if (user.firstname) {
            state.firstname = user.firstname;
        }
        if (user.familyname) {
            state.familyname = user.familyname;
        }
        if (user.lang) {
            state.lang = user.lang;
        }
        if (user.roles) {
            state.roles = user.roles;
        }
    },
    clearUserData(state) {
        state.accountSettingsDocId = null;
        state.accountDocId = null;
        state.salutation = null;
        state.firstname = null;
        state.familyname = null;
        state.lang = null;
    },
    setAddresses(state, payload) {
        state.addresses = payload;
    },
    addOrUpdateAddress(state, payload) {
        const address = state.addresses.find(
            (address) => address.id === payload.id
        );
        if (address) {
            state.addresses = [
                ...state.addresses.filter(
                    (address) => address.id !== payload.id
                ),
                payload
            ];
        } else {
            state.addresses.push(payload);
        }
    },
    deleteAddress(state, addressId) {
        state.addresses = state.addresses
            .slice(0)
            .filter((address) => address.id !== addressId);
    }
};

const getters = {
    isAuthenticated(state) {
        return state.token !== null;
    },
    accountDocId(state) {
        return state.accountDocId;
    },
    accountSettingsDocId(state) {
        return state.accountSettingsDocId;
    },
    token(state) {
        return state.token;
    },
    mail(state) {
        return state.mail;
    },
    userSalutation(state) {
        return state.salutation;
    },
    userFirstname(state) {
        return state.firstname;
    },
    userFamilyname(state) {
        return state.familyname;
    },
    userFullname(state) {
        if (state.firstname && state.familyname) {
            return state.firstname + " " + state.familyname;
        } else {
            return null;
        }
    },
    userModel(state) {
        const firstName = state.firstname;
        const familyName = state.familyname;
        const accountDocId = state.accountDocId;
        const accountSettingsDocId = state.accountSettingsDocId;
        return {
            firstName,
            familyName,
            accountDocId,
            accountSettingsDocId
        };
    },
    hasSettings(state) {
        return state.salutation !== null && state.firstname !== null && state.familyname !== null;
    },
    userLang(state) {
        return state.lang;
    },
    isUserDevolper(state) {
        return state.roles.includes(ACCOUNT_ROLE.DEVELOPER);
    },
    isUserBackoffice(state) {
        return state.roles.includes(ACCOUNT_ROLE.BACKOFFICE);
    },
    isUserAdmin(state) {
        return state.roles.includes(ACCOUNT_ROLE.ADMIN);
    },
    isUserInsurer(state) {
        return state.roles.includes(ACCOUNT_ROLE.INSURER);
    },
    addressOperator(state) {
        const id = ADDRESS_IDS.OPERATOR;
        const address = state.addresses.find((address) => address.id === id);
        if (!address) {
            return { id };
        }
        return address;
    },
    addressBill(state) {
        const id = ADDRESS_IDS.BILL;
        const address = state.addresses.find((address) => address.id === id);
        if (!address) {
            return { id };
        }
        return address;
    },
    addressOwner(state) {
        const id = ADDRESS_IDS.OWNER;
        const address = state.addresses.find((address) => address.id === id);
        if (!address) {
            return { id };
        }
        return address;
    }
};

const actions = {
    autoLogin({ commit, dispatch }) {
        let token = window.$cookies.get("authtoken");
        let mail = window.$cookies.get("mail");

        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/isTokenStillValid", {
                    params: { mail: mail, token: token }
                })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        commit("authUser", {
                            token: token,
                            mail: mail
                        });
                        dispatch("getAccountSettings")
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        reject(response);
                        window.$cookies.remove("authtoken");
                        window.$cookies.remove("mail");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    logout: ({ commit }) => {
        let token = window.$cookies.get("authtoken");
        let mail = window.$cookies.get("mail");
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/disableAuthToken", {
                    params: { mail: mail, token: token }
                })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        window.$cookies.remove("mail");
                        window.$cookies.remove("authtoken");
                        commit("clearAuthData");
                        commit("clearUserData");
                        router.push({ name: "home" });
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    disableToken: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/disableAuthToken", {
                    params: { mail: data.mail, token: data.token }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    register: (_ctx, params) => {
        if (!params.lang) {
            const locale = i18n?.locale || "de";
            params.lang = locale;
        }
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/requestAuthToken", { params })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    validateToken: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/validateAuthToken", {
                    params: { mail: data.mail, token: data.token }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    isTokenStillValid: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/isTokenStillValid", {
                    params: { mail: data.mail, token: data.token }
                })
                .then((response) => {
                    commit("authUser", {
                        token: data.token
                    });
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    isTokenEnabled: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/isTokenEnabled", {
                    params: { mail: data.mail, token: data.token }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    requestNewToken: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosAuth
                .get("/requestAuthToken", { params: { mail: data.mail } })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    setAccountSettings: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .post("/me/", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getAccountSettings: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .get("/me/")
                .then((response) => {
                    let status = response.status;
                    const resData = response.data.data;
                    if (status === 200) {
                        commit("setUserData", {
                            salutation: resData.salutation,
                            firstname: resData.firstname,
                            familyname: resData.familyname,
                            lang: resData.lang,
                            accountSettingsDocId: resData.docId,
                            accountDocId: resData.accountDocId,
                            roles: resData.roles
                        });
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAllAddresses: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .get("/me/address/")
                .then((response) => {
                    const resData = response.data;
                    const addresses = resData.data;
                    if (addresses) {
                        commit("setAddresses", addresses);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    addOrUpdateAddress: (ctx, data) => {
        const addressId = data.id;
        return new Promise((resolve, reject) => {
            if (addressId) {
                axiosAccountSettings
                    .put(`/me/address/${addressId}`, data)
                    .then((response) => {
                        const resData = response.data;
                        const address = resData.data;
                        address.id = addressId;
                        ctx.commit("addOrUpdateAddress", address);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            } else {
                axiosAccountSettings
                    .post("/me/address/", data)
                    .then((response) => {
                        const resData = response.data;
                        const address = resData.data;
                        ctx.commit("addOrUpdateAddress", address);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        });
    },
    deleteAddressById: (ctx, addressId) => {
        if (!addressId) return;
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .delete(`/me/address/${addressId}`)
                .then((response) => {
                    ctx.commit("deleteAddress", addressId);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
