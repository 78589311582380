<i18n src="./copy-to-clipboard-i18n.yaml"></i18n>
<template>
    <v-btn v-bind="$attrs" icon small @click="doCopy">
        <v-icon x-small v-html="icon" />
    </v-btn>
</template>
<script>
export default {
    name: "CopyToClipboard",
    props: {
        value: {
            required: true
        },
        msgSuccess: {
            type: String,
            default: "msg-success"
        },
        msgError: {
            type: String,
            default: "msg-error"
        },
        icon: {
            type: String,
            default: "mdi-content-copy"
        }
    },
    methods: {
        onCopySuccess() {
            let msgSuccess;
            if (this.msgSuccess === "msg-success") {
                msgSuccess = this.$t("msg-success", {
                    value: this.value
                });
            } else {
                msgSuccess = this.msgSuccess;
            }
            this.$toast.success(msgSuccess);
        },
        onCopyError(error) {
            let msgError;
            if (this.msgError === "msg-error") {
                msgError = this.$t("msg-error", {
                    value: this.value
                });
            } else {
                msgError = this.msgError;
            }
            this.$toast.error(msgError);
            console.log(error);
        },
        doCopy: function () {
            const container = document.querySelector(".v-dialog");
            if (container) {
                this.$copyText(String(this.value), container)
                    .then(() => {
                        this.onCopySuccess();
                    })
                    .catch((error) => {
                        this.onCopyError(error);
                    });
            } else {
                this.$copyText(String(this.value))
                    .then(() => {
                        this.onCopySuccess();
                    })
                    .catch((error) => {
                        this.onCopyError(error);
                    });
            }
        }
    }
};
</script>
