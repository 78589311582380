<i18n>
de:
    label-locale: Sprache
    label-templates: Template
    label-format: Format
    label-auto-reload: Auto-Reload

</i18n>
<template>
  <div id="preview">
    <v-container>
      <v-row>
        <v-col cols="12">
          <base-alert type="info">
            Bilder werden nicht dargestellt
          </base-alert>
        </v-col>
        <v-col cols="12" :xs="6" :md="2">
          <base-select
            :items="available_locales"
            clearable
            v-model="locale"
            :label="$t('label-locale')"
            @change="onFielChange"
          >
          </base-select>
        </v-col>
        <v-col cols="12" :xs="6" :md="2">
          <base-select
            :items="available_formats"
            clearable
            v-model="format"
            :label="$t('label-format')"
            @change="onFielChange"
          >
          </base-select>
        </v-col>
        <v-col cols="12" :md="4">
          <base-select
            :items="available_messages"
            clearable
            v-model="message"
            :label="$t('label-templates')"
            @change="onFielChange"
          >
          </base-select>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="autoReload"
            :label="$t('label-auto-reload')"
            color="primary"
          ></v-checkbox>
        </v-col>
        <v-col>
          <base-btn @click="getTemplate">
            <v-icon> mdi-reload</v-icon></base-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid fill-height>
      <div v-if="format === 'txt'" class="pl-10 email_frame text-block">
        {{ template }}
      </div>
      <iframe
        v-else
        :srcdoc="template"
        id="preview_iframe"
        :class="format === 'txt' ? ' text-block' : undefined"
        class="email_frame"
      ></iframe>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MailTemplatesPreview",

  data: () => ({
    available_locales: [],
    available_messages: [],
    available_formats: ["html", "txt"],
    locale: "",
    message: "",
    format: "txt",
    seed: "",
    template: "",
    autoReload: false,
  }),
  methods: {
    onFielChange() {
      return this.getTemplate();
    },
    startSeedInterval() {
      this.seedInterval = setInterval(() => {
        this.$axiosMail
          .get("/preview/seed")
          .then((response) => {
            if (response.status === 200) {
              this.seed = response.data.seed;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Fehler: " + error);
          });
      }, 3000);
    },
    stopSeedInterval() {
      clearInterval(this.seedInterval);
    },
    getTemplate() {
      if (!this.locale || !this.message || !this.format) return;
      this.$axiosMail
        .get(`/preview/${this.locale}/${this.message}/${this.format}`)
        .then((response) => {
          this.template = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Fehler: " + error);
        });
    },
    fetchAllTemplates() {
      this.loader().show("Templates werden generiert..");
      this.$axiosMail
        .get("/preview/preview_config")
        .then((response) => {
          const preview_config = response.data;
          this.available_locales = preview_config.available_locales;
          this.available_messages = preview_config.available_messages;
          this.locale = preview_config.available_locales[0];
          this.message = preview_config.available_messages[0];
          this.getTemplate();
          this.loader().hide();
        })
        .catch((error) => {
          this.loader().hide();
          console.log(error);
          this.$toast.error("Fehler: " + error);
        });
    },
  },
  watch: {
    seed: function () {
      this.getTemplate();
    },
    $route() {
      this.stopSeedInterval();
    },
    autoReload(newValue) {
      if (newValue) {
        this.startSeedInterval();
      } else {
        this.stopSeedInterval();
      }
    },
  },
  mounted() {
    this.fetchAllTemplates();
  },
  beforeDestroy() {
    this.stopSeedInterval();
  },
};
</script>

<style>
#preview {
  font-family: sans-serif;
  font-size: 14px;
}
.email_frame {
  margin-top: 10px;
  border: 1px solid #aaa;
  height: calc(100vh - 250px);
  width: 100%;
  overflow: auto;
}

.text-block {
  white-space: pre;
}
</style>
