<i18n src="./modal-task-complete-further-actions-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="value"
        :title="$t('dialog-title')"
        max-width="450"
        icon="mdi-alert"
        @close="onClickClose"
    >
        <template v-slot:content>
            <v-container fluid>
                <base-alert
                    type="success"
                    :text="$t('alert-msg-success')"
                ></base-alert>
                <v-divider class="py-2" />
                <base-subheader class="text-h6">{{
                    $t("further-actions-title")
                }}</base-subheader>
                <v-row>
                    <v-col cols="12">
                        <div>{{ $t("msg-action-task-copy") }}</div>
                        <base-btn
                            class="mt-2"
                            type="info"
                            btnIcon="mdi-content-copy"
                            @click="onClickTaskCopy"
                        >
                            {{ $t("btn-action-task-copy") }}
                        </base-btn>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                        <div>
                            {{ $t("msg-action-task-create") }}
                        </div>
                        <base-btn
                            class="mt-2"
                            type="info"
                            btnIcon="mdi-star"
                            @click="onClickTaskCreate"
                        >
                            {{ $t("btn-action-task-create") }}
                        </base-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:actions>
            <slot name="actions">
                <v-spacer></v-spacer>
                <base-btn @click="onClickClose" type="close">
                    {{ $t("btn-close") }}
                </base-btn>
            </slot>
        </template>
    </base-dialog>
</template>

<script>
export default {
    name: "ModalTaskCompleteFurtherActions",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickClose() {
            this.$emit("click:close");
        },
        onClickTaskCreate() {
            this.$emit("click:task-create");
        },
        onClickTaskCopy() {
            this.$emit("click:task-copy");
        }
    }
};
</script>
