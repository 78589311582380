<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./task-complete-view-i18n.yaml"></i18n>
<template>
    <base-card-text>
        <template v-slot:content v-if="task">
            <base-alert type="info" dense>
                <slot name="alert-msg">
                    <ul>
                        <li v-show="task.state">
                            <i18n path="msg-info-status">
                                <template #process>
                                    <b>{{
                                        $translateKebablizerLower(
                                            `task-process-type-${task.process}`
                                        )
                                    }}</b>
                                </template>
                                <template #taskState>
                                    <b>{{ $t(`task-status-${task.state}`) }}</b>
                                </template>
                            </i18n>
                        </li>

                        <li v-if="task.clientCompleteDate">
                            <i18n path="msg-info-registrant">
                                <template #registrant>
                                    <b>{{ registrantFullName }}</b>
                                </template>
                                <template #timestamp>
                                    <base-date-time
                                        :timestamp="task.clientCompleteDate"
                                        clsDate="font-weight-bold"
                                        clsTime="font-weight-bold"
                                    />
                                </template>
                            </i18n>
                        </li>
                        <li v-if="task.backofficeCompleteDate">
                            <i18n path="msg-info-bo-employee">
                                <template #boEmployee>
                                    <b>{{ boFullName }}</b>
                                </template>
                                <template #timestamp>
                                    <base-date-time
                                        :timestamp="task.backofficeCompleteDate"
                                        clsDate="font-weight-bold"
                                        clsTime="font-weight-bold"
                                    />
                                </template>
                            </i18n>
                        </li>
                    </ul>
                </slot>
            </base-alert>
            <InsurerAlertMsg v-if="task.insurer" :insurer="task.insurer" />
            <slot name="prepend" />
            <v-list>
                <SummaryDataRegistrant
                    v-if="task.registrant"
                    :registrant="task.registrant"
                    :show-copy-btn="showCopyBtn"
                />
                <template v-if="isBackoffice">
                    <v-divider />
                    <SummaryDataBackoffice
                        v-if="
                            task.backoffice &&
                            task.backoffice.account &&
                            task.state >=
                                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS
                        "
                        :backoffice="task.backoffice.account"
                        :show-copy-btn="showCopyBtn"
                    />
                </template>
                <v-divider />
                <SummaryBaseData :task="task" :show-copy-btn="showCopyBtn" />
                <template v-if="task.process === PROCESS_TYPES.REGISTRATION">
                    <v-divider />
                    <SummaryObjectDetails
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryEquipmentLocation
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryChambers
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryAddressOperator
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryAddressBill
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryAddressOwner
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryAttachments
                        :task="task"
                        show-attachments-icon
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryActivelyHeated
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <template v-if="isBackoffice">
                        <v-divider />
                        <SummaryBackoffice
                            :task="task"
                            :show-copy-btn="showCopyBtn"
                        />
                    </template>
                </template>
                <template v-if="task.process === PROCESS_TYPES.DEREGISTRATION">
                    <SummaryReason :task="task" :show-copy-btn="showCopyBtn" />
                    <v-divider />
                    <SummaryNewAddress
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                    <v-divider />
                    <SummaryOperator
                        :task="task"
                        :show-copy-btn="showCopyBtn"
                    />
                </template>
            </v-list>
            <slot name="append" />
        </template>
    </base-card-text>
</template>

<script>
import Helpers from "@/utils/helpers";
import { PROCESS_TYPES, TaskStatus } from "@/data/globals.js";
export default {
    name: "TaskCompleteView",
    props: {
        task: {
            type: Object,
            default: () => {},
            required: true
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        },
        isBackoffice: {
            type: Boolean,
            defaul: false
        }
    },
    components: {
        SummaryDataRegistrant: () =>
            import(
                "@/components/tasks/common/summary/summary-data-registrant.vue"
            ),
        SummaryDataBackoffice: () =>
            import(
                "@/components/tasks/common/summary/summary-data-backoffice.vue"
            ),
        SummaryBaseData: () =>
            import("@/components/tasks/common/summary/summary-base-data.vue"),
        SummaryBackoffice: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-backoffice.vue"
            ),
        SummaryObjectDetails: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-object-details.vue"
            ),
        SummaryEquipmentLocation: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-equipment-location.vue"
            ),
        SummaryChambers: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-chambers.vue"
            ),
        SummaryAddressOperator: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-operator.vue"
            ),
        SummaryAddressBill: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-bill.vue"
            ),
        SummaryAddressOwner: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-owner.vue"
            ),
        SummaryAttachments: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-attachments.vue"
            ),
        SummaryActivelyHeated: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-actively-heated.vue"
            ),
        SummaryReason: () =>
            import(
                "@/components/tasks/common/summary/deregistration/summary-reason.vue"
            ),
        SummaryOperator: () =>
            import(
                "@/components/tasks/common/summary/deregistration/summary-operator.vue"
            ),
        SummaryNewAddress: () =>
            import(
                "@/components/tasks/common/summary/deregistration/summary-new-address.vue"
            ),
        InsurerAlertMsg: () =>
            import(
                "@/components/tasks/common/insurer-alert-msg.vue"
            )
    },
    data: () => ({
        PROCESS_TYPES,
        TaskStatus
    }),
    computed: {
        isActivelyHeated() {
            return Helpers.isActivelyHeated(this.task);
        },
        registrantFullName() {
            if (!this.task) return;
            return `${this.task?.registrant?.firstName} ${this.task?.registrant?.familyName}`;
        },
        boFullName() {
            if (!this.task) return;
            return `${this.task?.backoffice?.account?.firstName} ${this.task?.backoffice?.account?.familyName}`;
        }
    }
};
</script>
