import moment from "moment";

const randomNumber = (min, max) => {
    return Math.floor(Math.random() * max) + min;
}

const randomString = (length = 5) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

const randomElement = (arr = []) => {
    return arr[Math.floor(Math.random() * arr.length)]
}

const toggleFullScreen = () => {
    let doc = window.document
    let docEl = doc.documentElement

    let requestFullScreen =
        docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    let cancelFullScreen =
        doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl)
    } else {
        cancelFullScreen.call(doc)
    }
}

const getNameInitials = (name) => {
    if (!name) return;
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
}

const formatBytes = (bytes, decimals=2) => {
    if (bytes === 0 || !bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 2 ? 2 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function sortAttributesAlphabetically(attributes, locale='de', ascending=true) {
    // default to ascending
    if (typeof(ascending) === "undefined")
        ascending = true;

        const multiplier = ascending ? 1 : -1;

        const sorter = function(a, b) {
            const valueA = a.value[locale] ? a.value[locale] : a.value['de'];
            const valueB = b.value[locale] ? b.value[locale] : b.value['de'];
            const textA = valueA ? valueA.toLowerCase() : valueA;
            const textB = valueB ? valueB.toLowerCase() : valueB;
            if (textA === textB) // identical? return 0
                return 0;
            else if (textA === null || !textA)  // a is null? last
                return 1;
            else if (textB === null || !textB)  // b is null? last
                return -1;
            else// compare, negate if descending
                return textA.localeCompare(textB) * multiplier;
        }

    return attributes.sort(sorter);
}

export function isEmpty(value) {
    return  value === undefined ||
            value === null ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (typeof value === "string" && value.trim().length === 0) ||
            (value instanceof Array && value.length === 0)
}

const upperCaseFirstChar = (str) => {
    if (typeof str !== 'string') return null;
    let firstLetter = str.substr(0, 1);
    return firstLetter.toUpperCase() + str.substr(1);
}

const formatDate = (date) => {
    if (!date) return;
    return moment(date).format("DD.MM.YYYY");
}

const formatDateIso = (date) => {
    if (!date) return;
    return moment(date).format("YYYY-MM-DD");
}

export default {
    randomNumber,
    randomString,
    randomElement,
    toggleFullScreen,
    getNameInitials,
    formatBytes,
    upperCaseFirstChar,
    formatDate,
    formatDateIso
}
