import { aggregation } from "@/utils/aggregation";
import {
    BaseRegistration,
    ObjectProperties,
    EquipmentLocation,
    Address,
    CustomerSpecificDesignation,
    BillingAdditionalInfos
} from "./tasks";
export class Registration extends aggregation(
    BaseRegistration,
    ObjectProperties
) {
    constructor() {
        super();
        this.customerSpecificDesignation = new CustomerSpecificDesignation();
        this.equipmentLocation = new EquipmentLocation();
        this.chambers = [];
        this.operatorAddress = new Address();
        this.billingAddress = new Address();
        this.ownerAddress = new Address();
        this.inspectionType = "SVTI";
        this.specialistCompany = {};
        this.scrollPosition = null;
        this.referenceId = null;
        this.billingAdditionalInfos = new BillingAdditionalInfos();
    }
}
