var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"100%","heigth":"500","type":"card"}}):_c('base-card-text',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"item-class":_vm.computedItemClasses,"options":_vm.computedOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","extended":!_vm.hideSearch},scopedSlots:_vm._u([(!_vm.hideSearch)?{key:"extension",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":6}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('base-text-field',{attrs:{"value":_vm.searchModel,"append-icon":"mdi-magnify","label":_vm.searchFieldLabel
                                                    ? _vm.searchFieldLabel
                                                    : _vm.$t(
                                                          'search-field-label'
                                                      ),"single-line":"","hide-details":""},on:{"input":function($event){return _vm.$emit(
                                                    'input:search',
                                                    $event
                                                )}}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)],1),_c('v-spacer')],1)]},proxy:true}:null],null,true)},[_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_vm._t("toolbar-actions",function(){return [_vm._t("toolbar-actions-additional",function(){return [_vm._l((_vm.toolbarActions),function(action){return [(action.title)?_c('v-tooltip',{key:action.id,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [(action.emit)?_c('v-btn',_vm._g({attrs:{"icon":"","color":action.color},on:{"click":function($event){return _vm.onClickAction(
                                                                action
                                                            )}}},on),[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_c('v-icon',_vm._g({key:action.id,attrs:{"color":action.color}},on),[_vm._v(" "+_vm._s(action.icon)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(action.title)+" ")]):[(action.emit)?_c('v-btn',{key:action.id,attrs:{"icon":"","color":action.color},on:{"click":function($event){return _vm.onClickAction(
                                                            action
                                                        )}}},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_c('v-icon',{key:action.id,attrs:{"color":action.color}},[_vm._v(" "+_vm._s(action.icon)+" ")])]]})]}),(!_vm.hideBtnAdd)?_c('base-btn',{attrs:{"icon":_vm.showAddBtnAsIcon},on:{"click":function($event){return _vm.$emit('click:add')}}},[_c('v-icon',{attrs:{"left":!_vm.showAddBtnAsIcon}},[_vm._v("mdi-plus")]),(!_vm.showAddBtnAsIcon)?_c('span',[_vm._v(_vm._s(_vm.btnAddText))]):_vm._e()],1):_vm._e(),(!_vm.hideBtnReload)?_c('base-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:reload')}}},[_c('v-icon',[_vm._v("mdi-reload")])],1):_vm._e()]})],2)],1)],1)]},proxy:true},_vm._l((_vm.$scopedSlots),function(slot,name){return {key:name,fn:function(item){return [_vm._t(name,null,null,item)]}}}),{key:"item.actions",fn:function(ref){
                                                        var item = ref.item;
return [_vm._t("actions",function(){return [[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
                                                        var attrs = ref.attrs;
return [_c('base-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.actions.length === 0 ||
                                            _vm.computedItemClasses(
                                                item
                                            ).includes('disabled')}},'base-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-reorder-horizontal ")])],1)]}}],null,true)},[_c('v-list',[_vm._l((_vm.actions),function(actionItem,i){return [(
                                                _vm.funcShowAction(
                                                    item,
                                                    actionItem
                                                )
                                            )?_c('v-list-item',{key:i,on:{"click":function($event){return _vm.onMenuActionClick(
                                                    actionItem.action,
                                                    item
                                                )}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(actionItem.icon)}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(actionItem.title))+" ")])],1):_vm._e()]})],2)],1)]]},{"item":item})]}},{key:"no-data",fn:function(){return [_vm._t("no-data",function(){return [_c('span',[_c('p',{staticClass:"pt-2 blue--text subheading"},[_c('v-icon',{staticClass:"blue--text",attrs:{"medium":""}},[_vm._v(" mdi-information ")]),(_vm.noDataText)?_c('span',[_vm._v(" "+_vm._s(_vm.noDataText)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-data-msg"))+" ")])],1)])]})]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }