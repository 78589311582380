<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./registration-dialog-container-i18n.yaml"></i18n>
<template>
    <v-container fluid fill-height pa-0 ma-0>
        <app-dev-container>
            <template v-slot:content>
                @registrationData
                <tree-view
                    :data="registrationData"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @registrationDialog
                <tree-view
                    :data="Object.assign({}, registrationDialog)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
            </template>
        </app-dev-container>
        <v-flex
            xs12
            class="pt-2"
            v-if="
                registrationDialog.state != null &&
                registrationDialog.messages &&
                registrationDialog.messages.length > 0
            "
        >
            <v-list dense subheader color="light-blue lighten-5" tile flat>
                <v-list-item
                    :three-line="
                        registrationDialog.state >=
                        TaskDialogStatus.TASK_DIALOG_CONFIRMED
                    "
                    :two-line="
                        registrationDialog.state <
                        TaskDialogStatus.TASK_DIALOG_CONFIRMED
                    "
                    @click="registrationDialogModal = true"
                >
                    <v-list-item-avatar>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    :color="
                                        getRegistrationStateColor(
                                            registrationDialog.state
                                        )
                                    "
                                    v-on="on"
                                >
                                    mdi-circle
                                </v-icon>
                            </template>
                            <span>{{
                                $t(
                                    `registration-dialog-status-${registrationDialog.state}`
                                )
                            }}</span>
                        </v-tooltip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle
                            v-if="
                                registrationDialog.state !=
                                TaskDialogStatus.TASK_DIALOG_CONFIRMED
                            "
                        >
                            <v-tooltip right v-if="registrationDialog.reminder">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        x-small
                                        :color="
                                            $moment
                                                .utc(
                                                    registrationDialog.reminder
                                                )
                                                .local()
                                                .diff($moment(), 'days') == 2
                                                ? 'error'
                                                : 'primary'
                                        "
                                        v-on="on"
                                    >
                                        mdi-bell-alert
                                    </v-icon>
                                </template>
                                <span>
                                    {{
                                        $t("dialog-caption-deadline", {
                                            date: $moment
                                                .utc(
                                                    registrationDialog.reminder
                                                )
                                                .local()
                                                .format(
                                                    "DD.MM.YYYY [" +
                                                        $t(
                                                            "date-time-separator"
                                                        ) +
                                                        "] HH:mm"
                                                ),
                                            timestamp: $moment
                                                .utc(
                                                    registrationDialog.reminder
                                                )
                                                .local()
                                                .from($moment())
                                        })
                                    }}
                                </span>
                            </v-tooltip>
                            {{
                                $t("dialog-caption-create", {
                                    date: $moment
                                        .utc(registrationDialog.timestamp)
                                        .local()
                                        .format("DD.MM.YYYY [um] HH:mm"),
                                    timestamp: $moment
                                        .utc(registrationDialog.timestamp)
                                        .local()
                                        .from($moment()),
                                    userName: registrationDialog.createdBy
                                })
                            }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-if="
                                registrationDialog.state ==
                                TaskDialogStatus.TASK_DIALOG_CONFIRMED
                            "
                        >
                            {{
                                $t("dialog-caption-close", {
                                    userName: registrationDialog.closedBy,
                                    timestamp: $moment
                                        .utc(registrationDialog.closedTimestamp)
                                        .local()
                                        .from($moment())
                                })
                            }}
                        </v-list-item-subtitle>
                        <v-list-item-title
                            v-if="
                                registrationDialog.state ==
                                    TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                                registrationDialog.reason
                            "
                            class="text-body-1 py-1"
                        >
                            Grund: {{ registrationDialog.reason }}
                        </v-list-item-title>
                        <v-list-item-title
                            v-if="
                                registrationDialog.state ==
                                    TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                                registrationDialog.note
                            "
                            class="text-body-1 py-1"
                        >
                            Note: {{ registrationDialog.note }}
                        </v-list-item-title>
                        <v-list-item-title
                            v-if="
                                registrationDialog.state != null &&
                                registrationDialog.state !=
                                    TaskDialogStatus.TASK_DIALOG_CONFIRMED
                            "
                            class="text-body-1 py-1 10"
                        >
                            <span
                                v-if="
                                    registrationDialog.state ==
                                        TaskDialogStatus.TASK_DIALOG_CREATED ||
                                    registrationDialog.state ==
                                        TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                                "
                            >
                                F:{{ lastMessage.question.text }}
                            </span>
                            <span v-else> A:{{ lastMessage.reply.text }} </span>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row>
                            <v-btn
                                v-if="
                                    registrationDialog.state >=
                                    TaskDialogStatus.TASK_DIALOG_CREATED
                                "
                                small
                                fab
                                depressed
                                color="info"
                                @click.stop="registrationDialogModal = true"
                            >
                                <v-icon>mdi-comment-eye</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="
                                    registrationDialog.state ==
                                    TaskDialogStatus.TASK_DIALOG_CREATED
                                "
                                class="ml-2"
                                small
                                fab
                                depressed
                                color="error"
                                @click.stop="
                                    onRegistrationDialogDelete(
                                        registrationDialog
                                    )
                                "
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-row>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-flex>
        <v-flex
            xs12
            v-if="
                registrationData.state >=
                    TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                !reviewVerification.approved &&
                (('dialog' in reviewVerification &&
                    'messages' in reviewVerification.dialog &&
                    reviewVerification.dialog.messages.length === 0) ||
                    Object.entries(reviewVerification.dialog).length === 0)
            "
        >
            <base-btn @click="registrationDialogModal = true" class="mt-5 mr-4">
                <v-icon class="mr-2">mdi-comment-question</v-icon>
                {{ $t("btn-dialog-create") }}
            </base-btn>
        </v-flex>
        <RegistrationDialogModal
            :value="registrationDialogModal"
            @input="registrationDialogModal = $event"
            ref="registrationDialogModal"
            :registrationDialogData="registrationDialog"
            :registrationData="registrationData"
            :requireChambers="requireChambers"
            :requireAttachments="requireAttachments"
            :step="step"
            @add-question="onAddQuestionToMessageDialog"
            @accept-and-close-dialog="onAcceptAndCloseDialog"
            @create="onRegistrationDialogCreate"
            @edit="onRegistrationDialogMsgEdit"
            @delete="onRegistrationDialogMsgDelete"
        >
        </RegistrationDialogModal>
    </v-container>
</template>
<script>
import { getRegistrationStateColor } from "@/utils/defaults.js";
import { TaskDialog } from "@/models";
import RegistrationDialogModal from "./registration-dialog-modal.vue";
import _ from "lodash";
import { TaskDialogStatus } from "@/data/globals.js";
import { mapMutations, mapActions } from "vuex";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
export default {
    name: "RegistrationDialogContainer",
    components: {
        RegistrationDialogModal
    },
    props: {
        step: {
            type: [String, Number],
            default: null
        },
        requireChambers: {
            type: Boolean,
            default: false
        },
        requireAttachments: {
            type: Boolean,
            default: false
        },
        registrationData: {
            type: Object,
            default: () => {}
        },
        reviewVerification: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        TaskDialogStatus: TaskDialogStatus,
        registrationDialogModal: false
    }),
    methods: {
        ...mapMutations("backofficeRegistration", [
            MUTATION_TYPES.SET_DIALOG_TO_ACCEPTED,
            MUTATION_TYPES.EDIT_MESSAGE_DIALOG,
            MUTATION_TYPES.DELETE_MESSAGES_FROM_DIALOG
        ]),
        ...mapActions("backofficeRegistration", [
            ACTION_TYPES.AUTO_SAVE_REGISTRATION
        ]),
        onRegistrationDialogCreate(registrationDialog) {
            this.$emit("create", registrationDialog);
            this.registrationDialogModal = false;
        },
        onRegistrationDialogEdit(registrationDialog) {
            this.registrationDialogModalCreate = true;
            this.$nextTick(() => {
                this.$refs.registrationDialogModalCreate.setData(
                    registrationDialog
                );
            });
        },
        getRegistrationStateColor: getRegistrationStateColor,
        onRegistrationDialogDelete() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-dialog-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-delete-dialog-msg")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("delete", this.registrationDialog);
                }
            });
        },
        onAddReplyToMessageDialog(reply) {
            if (!this.registrationData || !this.registrationData.referenceId) {
                console.error("Missing registration referenceId");
                return;
            }
            this.loader().show();
            reply.referenceId = this.registrationData.referenceId;
            this.$store
                .dispatch("registrationDialogs/addReply", reply)
                .then((response) => {
                    if (response.status === 200) {
                        this.registrationDialogModal = false;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onAddQuestionToMessageDialog(question) {
            if (!this.registrationData || !this.registrationData.referenceId) {
                console.error("Missing registration referenceId");
                return;
            }
            this.loader().show();
            question.referenceId = this.registrationData.referenceId;
            this.$store
                .dispatch("registrationDialogs/addQuestion", question)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-question-add-success")
                        );
                        question.id = response.data;
                        this[MUTATION_TYPES.ADD_QUESTION_TO_DIALOG](question);
                        this.registrationDialogModal = false;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onAcceptAndCloseDialog(data) {
            if (!this.registrationData || !this.registrationData.referenceId) {
                console.error("Missing registration referenceId");
                return;
            }
            this.loader().show();
            data.referenceId = this.registrationData.referenceId;
            data.docId = this.registrationData.docId;
            this.$store
                .dispatch("registrationDialogs/acceptAndClose", data)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-dialog-close-success")
                        );
                        this[MUTATION_TYPES.SET_DIALOG_TO_ACCEPTED](data);
                        this.registrationDialogModal = false;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onRegistrationDialogMsgEdit(msg) {
            if (!this.registrationData || !this.registrationData.referenceId) {
                console.error("Missing registration referenceId");
                return;
            }
            this.loader().show();
            msg.referenceId = this.registrationData.referenceId;
            this.$store
                .dispatch("registrationDialogs/editMessage", msg)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-edit-success"));
                        this[MUTATION_TYPES.EDIT_MESSAGE_DIALOG](msg);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onRegistrationDialogMsgDelete(msg) {
            if (!this.registrationData || !this.registrationData.referenceId) {
                console.error("Missing registration referenceId");
                return;
            }
            this.loader().show();
            msg.referenceId = this.registrationData.referenceId;
            this.$store
                .dispatch("registrationDialogs/deleteMessage", msg)
                .then((response) => {
                    if (response.status === 200) {
                        this.registrationDialogModal = false;
                        if (this.sortedMessages.length === 1) {
                            this.$emit("delete", this.registrationDialog);
                        } else {
                            this[MUTATION_TYPES.DELETE_MESSAGES_FROM_DIALOG](
                                msg
                            );
                            this.$toast.success(this.$t("msg-delete-success"));
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        }
    },
    computed: {
        registrationDialog() {
            if (!this.reviewVerification) return;
            if (!("dialog" in this.reviewVerification)) return;
            if (Object.entries(this.reviewVerification.dialog).length > 0) {
                return this.reviewVerification.dialog;
            }
            let dialog = new TaskDialog();
            dialog.step = this.registrationData.step;
            dialog.boAccountId = this.registrationData.backoffice.accountId;
            return dialog;
        },
        sortedMessages() {
            if (
                !this.registrationDialog ||
                !("messages" in this.registrationDialog) ||
                this.registrationDialog.messages.length === 0
            ) {
                return [];
            }
            return _.orderBy(
                this.registrationDialog.messages,
                "lastChangeTimestamp"
            );
        },
        lastMessage() {
            if (this.sortedMessages.length === 0) {
                return {};
            }
            const lastIdx = _.findLastIndex(this.sortedMessages);
            return this.sortedMessages[lastIdx];
        }
    }
};
</script>
