<i18n src="./WMultipleTextField-i18n.yaml"></i18n>
<template>
    <div v-if="v">
        <v-row v-if="Object.keys(v.$each.$iter).length === 0" class="pt-2">
            <v-col cols="3">
                <base-btn @click="onAddField">
                    <v-icon left>mdi-plus</v-icon
                    >{{ $t("label-btn-add") }}</base-btn
                >
            </v-col>
        </v-row>
        <v-row v-else dense v-for="(v, index) in v.$each.$iter" :key="index">
            <v-col>
                <base-text-field
                    v-bind="$attrs"
                    v-model.trim="model[index]"
                    :label="`${label} ${Number(index) + 1}`"
                    :error-messages="errorMessagesByField(v)"
                />
            </v-col>
            <v-col
                align="start"
                cols="1"
                v-if="showBtnRemoveField(index) || showBtnAddField(index)"
            >
                <base-btn
                    v-if="showBtnRemoveField(index)"
                    :small="isSmallBtn(index)"
                    icon
                    color="error"
                    @click="onRemoveFieldByIndex(index)"
                    ><v-icon>mdi-delete</v-icon></base-btn
                >
                <base-btn
                    v-if="showBtnAddField(index)"
                    icon
                    :small="isSmallBtn(index)"
                    @click="onAddField"
                    :disabled="isBtnAddFieldDisabled"
                    ><v-icon>mdi-plus</v-icon></base-btn
                >
            </v-col>
        </v-row>
    </div>
</template>

<script>
import validateMixin from "@/mixins/validate";
export default {
    name: "WMultipleTextField",
    mixins: [validateMixin],
    props: {
        value: {
            type: Array,
            default: () => []
        },
        label: String,
        v: Object,
        disabled: Boolean,
        readonly: Boolean,
        errorMessagesByField: {
            type: Function,
            default: () => undefined
        }
    },

    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        isBtnAddFieldDisabled() {
            if (this.disabled || this.readonly) return true;
            let idx = this.value.findIndex((item) => !item || item === "");
            if (idx === -1) return false;
            return true;
        }
    },
    methods: {
        showBtnAddByIndex(index) {
            return index + 1 >= this.model.length;
        },
        onAddField() {
            this.model.push("");
        },
        onRemoveFieldByIndex(index) {
            if (index !== 0) {
                this.model.splice(index, 1);
            }
        },
        showBtnRemoveField() {
            if (this.disabled || this.readonly) return false;
            let minLengthFields = this.v?.$params?.minLength?.min || 0;
            if (this.model.length > minLengthFields) return true;
            return false;
        },
        showBtnAddField() {
            if (this.disabled || this.readonly) return false;
            let maxLengthFields = this.v?.$params?.maxLength?.max;
            if (maxLengthFields && this.model.length === maxLengthFields)
                return false;
            return true;
        },
        isSmallBtn(index) {
            return (
                this.showBtnRemoveField(index) && this.showBtnAddField(index)
            );
        },

        onFieldChange(value, index) {
            this.$emit("change:field", { value, index });
        }
    },
    watch: {
        value: {
            handler: function (value) {
                this.model = value;
            },
            immediate: true
        }
    }
};
</script>
