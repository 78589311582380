<template >
    <v-textarea
        v-on:click:append="reveal = !reveal"
        v-on="$listeners"
        v-bind="computedProps"
        v-disabled-icon-focus
        :filled="filled"
        :autoGrow="autoGrow"
        :rows="rows"
        :required="required"
        :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
    >
    </v-textarea>
</template>
<script>
import mixinWField from "@/mixins/w-field";
export default {
    name: "BaseTextarea",
    mixins: [mixinWField],
    props: {
        wType: {
            type: String,
            default: null,
            validator: function (value) {
                let validTypes = ["note"];
                return validTypes.indexOf(value) !== -1;
            }
        },
        filled: {
            type: Boolean,
            default: true
        },
        autoGrow: {
            type: Boolean,
            default: true
        },
        rows: {
            type: [Number, String],
            default: 5
        }
    },

    computed: {
        computedProps() {
            let props = {};
            if (this.wType) {
                props = Object.assign(props, this.getWTypeProps());
            }
            return {
                ...this.$attrs,
                ...props
            };
        }
    },
    methods: {
        getWTypeProps() {
            let props = {};
            if (this.wType === "note") {
                props = Object.assign(props, {
                    backgroundColor: "amber lighten-4"
                });
            }
            return props;
        }
    }
};
</script>
