import { axiosAccountSettings } from "@/api/axios-instances";

const state = {
    accounts: []
};

const mutations = {
    setAccounts(state, accounts) {
        state.accounts = accounts;
    },
    addOrUpdateAccount(state, account) {
        const item = state.accounts.find(
            (item) => item.accountDocId === account.accountDocId
        );
        if (item) {
            state.accounts = [
                ...state.accounts.filter(
                    (item) => item.accountDocId !== account.accountDocId
                ),
                account
            ];
        } else {
            state.accounts.push(account);
        }
    }
};

const getters = {};

const actions = {
    fetchAllAccounts: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .get("/")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const accounts = resData.data;
                        if (accounts) {
                            commit("setAccounts", accounts);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getUserAccount: (_ctx, accountDocId) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .get(`/${accountDocId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    updateUserAccount: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .post("/", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const account = resData.data;
                        if (account.accountDocId) {
                            if (!account.fullName) {
                                account.fullName =
                                    account.firstname +
                                    " " +
                                    account.familyname;
                            }
                            commit("addOrUpdateAccount", account);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    reindexAll: () => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .post("/reindex")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
