<template>
    <section :id="id">
        <base-card class="my-2 mx-auto">
            <v-card-title v-if="title" class="text-h5 primary--text">{{
                title
            }}</v-card-title>
            <v-card-subtitle class="pb-0 primary--text">{{
                subtitle
            }}</v-card-subtitle>
            <v-card-text class="primary--text">
                <slot />
            </v-card-text>
            <v-card-actions>
                <slot name="card-actions" />
            </v-card-actions>
        </base-card>
    </section>
</template>

<script>
export default {
    name: "SectionBase",
    props: {
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        }
    }
};
</script>

<style>
</style>
