var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-subheader',[_vm._v(_vm._s(_vm.$t("subtitle-reminders")))]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('WNumericField',{attrs:{"minValue":_vm.$v.form.boDefaultLockDays.$params.minimumValue.min,"label":_vm.$t('bo-default-lock-days-label'),"error-messages":_vm.boDefaultLockDaysErrors,"required":_vm.isRequiredField(_vm.$v.form.boDefaultLockDays),"suffix":_vm.$tc('label-days', Number(_vm.form.boDefaultLockDays) || 2)},model:{value:(_vm.form.boDefaultLockDays),callback:function ($$v) {_vm.$set(_vm.form, "boDefaultLockDays", $$v)},expression:"form.boDefaultLockDays"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('WNumericField',{attrs:{"minValue":_vm.$v.form.boDefaultReminderAssignBoDays.$params
                        .minimumValue.min,"label":_vm.$t('bo-default-reminder-assign-bo-days-label'),"error-messages":_vm.boDefaultReminderAssignBoDaysErrors,"required":_vm.isRequiredField(_vm.$v.form.boDefaultReminderAssignBoDays),"suffix":_vm.$tc(
                        'label-days',
                        Number(_vm.form.boDefaultReminderAssignBoDays) || 2
                    )},model:{value:(_vm.form.boDefaultReminderAssignBoDays),callback:function ($$v) {_vm.$set(_vm.form, "boDefaultReminderAssignBoDays", $$v)},expression:"form.boDefaultReminderAssignBoDays"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('WNumericField',{attrs:{"minValue":_vm.$v.form.boDefaultReminderAssignRegistrantDays.$params
                        .minimumValue.min,"label":_vm.$t('bo-default-reminder-assign-registrant-days-label'),"error-messages":_vm.boDefaultReminderAssignRegistrantDaysErrors,"required":_vm.isRequiredField(
                        _vm.$v.form.boDefaultReminderAssignRegistrantDays
                    ),"suffix":_vm.$tc(
                        'label-days',
                        Number(
                            _vm.form.boDefaultReminderAssignRegistrantDays
                        ) || 2
                    )},model:{value:(_vm.form.boDefaultReminderAssignRegistrantDays),callback:function ($$v) {_vm.$set(_vm.form, "boDefaultReminderAssignRegistrantDays", $$v)},expression:"form.boDefaultReminderAssignRegistrantDays"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('WNumericField',{attrs:{"minValue":_vm.$v.form.boDefaultReminderDays.$params.minimumValue.min,"label":_vm.$t('bo-default-reminder-days-label'),"error-messages":_vm.boDefaultReminderDaysErrors,"required":_vm.isRequiredField(_vm.$v.form.boDefaultReminderDays),"suffix":_vm.$tc(
                        'label-days',
                        Number(_vm.form.boDefaultReminderDays) || 2
                    )},model:{value:(_vm.form.boDefaultReminderDays),callback:function ($$v) {_vm.$set(_vm.form, "boDefaultReminderDays", $$v)},expression:"form.boDefaultReminderDays"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-subheader',[_vm._v(_vm._s(_vm.$t("subtitle-mails")))]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-field-label',[_vm._v(_vm._s(_vm.$t("label-text-email-bo-report-new-address")))]),(
                    _vm.form.mailsBoReportNewAddress &&
                    _vm.form.mailsBoReportNewAddress.length == 0 &&
                    (_vm.disabled || _vm.readonly)
                )?_c('app-text-not-recorded'):_c('WMultipleTextField',{attrs:{"label":_vm.$t('label-email-bo-report-new-address'),"v":_vm.$v.form.mailsBoReportNewAddress,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.isRequiredField(_vm.$v.form.mailsBoReportNewAddress),"errorMessagesByField":_vm.getMailErrorMessageByField},model:{value:(_vm.form.mailsBoReportNewAddress),callback:function ($$v) {_vm.$set(_vm.form, "mailsBoReportNewAddress", $$v)},expression:"form.mailsBoReportNewAddress"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-field-label',[_vm._v(_vm._s(_vm.$t("label-text-email-bo-report-new-address-reply-to")))]),(
                    _vm.form.mailsBoReportNewAddressReplyTo &&
                    _vm.form.mailsBoReportNewAddressReplyTo.length == 0 &&
                    (_vm.disabled || _vm.readonly)
                )?_c('app-text-not-recorded'):_c('WMultipleTextField',{attrs:{"label":_vm.$t('label-email-bo-report-new-address-reply-to'),"v":_vm.$v.form.mailsBoReportNewAddressReplyTo,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.isRequiredField(_vm.$v.form.mailsBoReportNewAddressReplyTo),"errorMessagesByField":_vm.getMailErrorMessageByField},model:{value:(_vm.form.mailsBoReportNewAddressReplyTo),callback:function ($$v) {_vm.$set(_vm.form, "mailsBoReportNewAddressReplyTo", $$v)},expression:"form.mailsBoReportNewAddressReplyTo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-field-label',[_vm._v(_vm._s(_vm.$t("label-text-email-insurer")))]),(
                    _vm.form.mailsInsurer &&
                    _vm.form.mailsInsurer.length == 0 &&
                    (_vm.disabled || _vm.readonly)
                )?_c('app-text-not-recorded'):_c('WMultipleTextField',{attrs:{"label":_vm.$t('label-email-insurer'),"v":_vm.$v.form.mailsInsurer,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.isRequiredField(_vm.$v.form.mailsInsurer),"errorMessagesByField":_vm.getMailErrorMessageByField},model:{value:(_vm.form.mailsInsurer),callback:function ($$v) {_vm.$set(_vm.form, "mailsInsurer", $$v)},expression:"form.mailsInsurer"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-field-label',[_vm._v(_vm._s(_vm.$t("label-text-email-bo-support")))]),(
                    _vm.form.mailsBoSupport &&
                    _vm.form.mailsBoSupport.length == 0 &&
                    (_vm.disabled || _vm.readonly)
                )?_c('app-text-not-recorded',{attrs:{"errorMessagesByField":_vm.getMailErrorMessageByField}}):_c('WMultipleTextField',{attrs:{"label":_vm.$t('label-email-bo-support'),"v":_vm.$v.form.mailsBoSupport,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.isRequiredField(_vm.$v.form.mailsBoSupport),"errorMessagesByField":_vm.getMailErrorMessageByField},model:{value:(_vm.form.mailsBoSupport),callback:function ($$v) {_vm.$set(_vm.form, "mailsBoSupport", $$v)},expression:"form.mailsBoSupport"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-field-label',[_vm._v(_vm._s(_vm.$t("label-text-email-bo-pool")))]),(
                    _vm.form.mailsBoPool &&
                    _vm.form.mailsBoPool.length == 0 &&
                    (_vm.disabled || _vm.readonly)
                )?_c('app-text-not-recorded'):_c('WMultipleTextField',{attrs:{"label":_vm.$t('label-email-bo-pool'),"v":_vm.$v.form.mailsBoPool,"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.isRequiredField(_vm.$v.form.mailsBoPool),"errorMessagesByField":_vm.getMailErrorMessageByField},model:{value:(_vm.form.mailsBoPool),callback:function ($$v) {_vm.$set(_vm.form, "mailsBoPool", $$v)},expression:"form.mailsBoPool"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }