<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./modal-file-uploader-i18n.yaml"></i18n>
<template>
    <base-dialog
        v-model="dialog"
        max-width="950px"
        icon="mdi-upload"
        @close="onBtnCloseClick"
    >
        <template v-slot:toolbar-title>
            <span>
                {{ $t(`attachment-category-${fileCategory}`) }}
                {{ $t("upload") }}
            </span>
            <FaqOpenBtn :searchId="getFaqSearchByFileCategory(fileCategory)" />
        </template>
        <template v-slot:content class="mt-4">
            <v-container fluid>
                <file-uploader
                    class="pb-2"
                    ref="fileUploader"
                    :docId="docId"
                    :category="fileCategory"
                    :key="modalUploaderInstance"
                    @upload:complete="onUploadComplete"
                    @upload:success="$emit('upload:success', $event)"
                    :maxFileSize="maxFileSize"
                    @change-files-count="filesCount = $event"
                    :url="url"
                    :params="params"
                >
                </file-uploader>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn type="secondary" @click="onBtnCloseClick" outlined>
                {{ $t("btn-cancel") }}
            </base-btn>
            <base-btn
                v-show="filesCount > 0"
                :disabled="filesCount === 0"
                @click="onBtnUploadClick"
                type="primary"
            >
                {{ $t("btn-upload") }}
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
import FileUploader from "@/components/general/file-uploader.vue";
import Utils from "@/utils";
export default {
    name: "ModalFileUploader",
    components: {
        FileUploader,
        FaqOpenBtn: () => import("@/components/general/faq/faq-open-btn.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        docId: {
            type: String,
            default: ""
        },
        fileCategory: {
            type: String,
            default: ""
        },
        maxFileSize: {
            type: Number,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        modalUploaderInstance: 0,
        filesCount: 0
    }),
    methods: {
        getFaqSearchByFileCategory(fileCategory) {
            return `attachments${Utils.upperCaseFirstChar(
                fileCategory
            )}`;
        },
        onUploadComplete(e) {
            this.$emit("upload:complete", e);
            this.close();
        },
        onBtnCloseClick() {
            if (
                this.$refs.fileUploader &&
                this.$refs.fileUploader.isFileUploading()
            ) {
                let msgOptions = {
                    title: {
                        text: this.$t("msg-box-complete-registration-title"),
                        icon: "mdi-alert"
                    },
                    message: this.$t("msg-box-complete-registration-text")
                };
                this.$root.$msgBox.open(msgOptions).then((result) => {
                    if (result === "agree") {
                        this.$refs.fileUploader.cancelFileUpload();
                    }
                    this.close();
                });
            } else {
                this.close();
            }
        },
        close() {
            this.reset();
            this.dialog = false;
        },
        onBtnUploadClick() {
            if (this.$refs.fileUploader.isFileUploading()) {
                //is already uploading
                return;
            }
            this.$emit("btn-upload-click");
            if (this.$refs.fileUploader) {
                if (this.$refs.fileUploader.upload()) {
                    this.$refs.fileUploader.upload();
                }
            }
        },
        onFileUploadClearClick() {
            this.reset();
        },
        reset() {
            this.$refs.fileUploader.reset();
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                this.dialog = newVal;
                //increade variable to create a new file uploader isntance
                //this will create a new instalce for dropzone
                this.modalUploaderInstance++;
            },
            immediate: true
        }
    }
};
</script>
