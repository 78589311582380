<i18n src="./dialog-about-app-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="400"
        icon="mdi-information-outline"
    >
        <template v-slot:content>
            <v-container>
                <v-list>
                    <v-subheader>{{ $t("app-title") }}</v-subheader>
                    <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in appItems" :key="i">
                            <v-list-item-icon>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-text="item.icon"
                                            v-on="on"
                                        ></v-icon>
                                    </template>
                                    <span>{{
                                        $t(`tooltip-${item.tooltip}`)
                                    }}</span>
                                </v-tooltip>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="item.text"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-container>
        </template>
    </base-dialog>
</template>
<script>
import _ from "lodash";
export default {
    name: "DialogAboutApp",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        apiVersion: 0
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        fetchApiVersion() {
            this.loader().show();
            this.$axiosMainApi
                .get("/version")
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        let resData = response.data;
                        const version = resData.data;
                        if (_.isNumber(version)) {
                            this.apiVersion = version.toFixed(1);
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-occured"));
                    this.loader().hide();
                });
        }
    },
    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION;
        },
        appItems() {
            let ret = [];
            ret.push(
                {
                    text: this.appVersion,
                    tooltip: "version",
                    icon: "mdi-source-branch"
                },
                {
                    text: this.apiVersion,
                    tooltip: "api-version",
                    icon: "mdi-source-branch"
                }
            );
            return ret;
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                if (newValue) {
                    this.fetchApiVersion();
                }
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
