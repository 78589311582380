<i18n 	  src="./modal-file-infos-i18n.yaml"></i18n>
<template>
    <base-dialog
        v-model="dialog"
        :title="$t('file-infos-title')"
        width="450"
        @close="$emit('close')"
        icon="mdi-information-outline"
    >
        <template v-slot:content class="mt-4">
            <v-container fill-height fluid v-if="file">
                <v-row align="center" justify="center" class="my-1">
                    <Photoswipe bubble>
                        <v-card
                            v-if="file.fileType == 'img'"
                            tile
                            max-height="150px"
                        >
                            <v-card-text class="pa-0 ma-0">
                                <v-img
                                    :src="
                                        $axiosBinary.defaults.baseURL +
                                        file.thumbnail.url
                                    "
                                    :lazy-src="
                                        $axiosBinary.defaults.baseURL +
                                        file.thumbnail.url
                                    "
                                    v-pswp="
                                        $axiosBinary.defaults.baseURL + file.url
                                    "
                                    class="align-end"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                                    aspect-ratio="1"
                                    contain
                                    height="100"
                                    @error="$toast.error('Error loading image')"
                                    max-height="100"
                                    min-width="100"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-card-text>
                        </v-card>
                    </Photoswipe>
                    <v-icon
                        v-if="file.fileType == 'pdf'"
                        class="ma-4"
                        size="60"
                    >
                        mdi-file-pdf
                    </v-icon>
                </v-row>
                <v-container v-if="file">
                    <v-card tile flat>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-list dense>
                                <v-list-item v-if="file.originalFullFileName">
                                    <v-list-item-content>
                                        <span>
                                            <v-icon small>mdi-file</v-icon>
                                            {{ $t("file-name") }}
                                        </span>
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">{{
                                        file.originalFullFileName
                                    }}</v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="file.fileSize && file.fileType"
                                >
                                    <v-list-item-content>
                                        <span>
                                            <v-icon small
                                                >mdi-move-resize-variant</v-icon
                                            >
                                            {{ $t("file-type") }},
                                            {{ $t("file-size") }}
                                        </span>
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end"
                                        >{{
                                            file.fileType == "img"
                                                ? $t("img")
                                                : $t("pdf")
                                        }},
                                        {{
                                            formatBytes(file.fileSize)
                                        }}</v-list-item-content
                                    >
                                </v-list-item>
                                <v-list-item
                                    v-if="file.uploader && file.taskState"
                                >
                                    <v-list-item-content>
                                        <span>
                                            <v-icon small>mdi-account</v-icon>
                                            {{ $t("file-uploader") }}
                                        </span>
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <span v-if="file.taskState">
                                            {{ file.uploader.firstName }}
                                            {{ file.uploader.familyName }}
                                            <span
                                                v-if="
                                                    file.taskState === 20 ||
                                                    file.taskState === 50
                                                "
                                                >({{ $t("registrant") }})</span
                                            >
                                            <span v-else-if="file.taskState"
                                                >({{ $t("backoffice") }})</span
                                            >
                                        </span>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="file.uploader">
                                    <v-list-item-content>
                                        <span>
                                            <v-icon small>mdi-clock</v-icon>
                                            {{ $t("file-uploader-timestamp") }}
                                        </span>
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <span
                                            >am
                                            {{
                                                $moment
                                                    .utc(
                                                        file.uploader.timestamp
                                                    )
                                                    .local()
                                                    .format("DD.MM.YYYY HH:mm")
                                            }}</span
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-container>
        </template>
    </base-dialog>
</template>
<script>
import Utils from "@/utils";
export default {
    name: "ModalFileInfos",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        modal: {
            type: Boolean,
            default: false
        },
        file: {
            type: Object,
            default: () => null
        }
    },
    methods: {
        formatBytes(bytes) {
            return Utils.formatBytes(bytes);
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    watch: {
        value: function (newVal) {
            // watch it
            this.dialog = newVal;
        }
    }
};
</script>
