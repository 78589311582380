var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalTable',{attrs:{"items":_vm.accounts,"options":_vm.tableOptions,"headers":_vm.headers,"actions":_vm.filteredActions,"title":_vm.$t('title'),"funcShowAction":_vm.showAction,"funcRowClasses":_vm.rowClasses,"search":_vm.search,"isLoading":_vm.isGlobalLoading,"hideBtnAdd":"","sort-by":['fullName', 'email'],"sort-desc":[false, true],"multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:action":_vm.onActionClick,"options:change":function (options) {
                Object.assign(_vm.tableOptions, options);
            },"input:search":function($event){_vm.search = $event},"click:reload":_vm.fetchAllAccounts},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"12","md":8,"xl":4}},[(_vm.searchByRole)?_c('base-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search-field-label'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_c('w-account-search',{attrs:{"filters":_vm.filters},on:{"items:changed":_vm.onAccountSearchItemsChanged},model:{value:(_vm.accountSearch),callback:function ($$v) {_vm.accountSearch=$$v},expression:"accountSearch"}})],1),_c('v-col',{attrs:{"cols":"8","sm":3,"md":2,"lg":3}},[_c('base-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.switchAdvancedSearch = !_vm.switchAdvancedSearch}}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(
                                _vm.switchAdvancedSearch
                                    ? 'mdi-filter-off'
                                    : 'mdi-filter-menu'
                            )}})],1),_c('base-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(
                                _vm.dialog
                                    ? 'mdi-filter-off'
                                    : 'mdi-filter-plus'
                            )}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_c('v-col',{attrs:{"align":"end"}},[_c('base-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.fetchAllAccounts}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)],1),(_vm.switchAdvancedSearch)?_c('v-expand-transition',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('base-select',{attrs:{"items":_vm.roles,"clearable":"","label":_vm.$t('search-by-role-label'),"item-text":function (item) { return _vm.$t(("account-role-" + (item.value))); },"single-line":""},on:{"change":_vm.onSearchByRoleChange},model:{value:(_vm.searchByRole),callback:function ($$v) {_vm.searchByRole=$$v},expression:"searchByRole"}})],1)],1)],1)],1):_vm._e()]},proxy:true},{key:"item.roles",fn:function(ref){
                                        var item = ref.item;
return [(item.roles)?_c('div',{staticClass:"pt-3"},[_vm._l((item.roles),function(role,roleIdx){return [(_vm.rolesIcons[role])?_c('v-icon',{key:roleIdx,attrs:{"color":_vm.rolesIcons[role].color}},[_vm._v(_vm._s(_vm.rolesIcons[role].icon))]):_vm._e()]})],2):_vm._e()]}},{key:"item.lang",fn:function(ref){
                                        var item = ref.item;
return [(item.lang)?_c('div',{staticClass:"pt-3"},[_c('country-flag',{attrs:{"country":item.lang}})],1):_vm._e()]}}])}),(_vm.showLoader)?_c('GlobalOverlay',{attrs:{"isLoading":_vm.isLoading,"loadingMsg":_vm.loadingMsg}}):_vm._e(),_c('BaseDialog',{attrs:{"title":_vm.$t('title-dialog-form-user-account'),"icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',{attrs:{"grid-list-md":"","text-xs-center":""}},[_c('FormUserAccount',{attrs:{"userData":_vm.editUser}})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"save"},on:{"click":_vm.onEditAccountSave}},[_vm._v(" "+_vm._s(_vm.$t("btn-edit-account-save"))+" ")])]},proxy:true}]),model:{value:(_vm.dialogFormUser),callback:function ($$v) {_vm.dialogFormUser=$$v},expression:"dialogFormUser"}}),(_vm.dialog)?_c('DialogSearchFilters',{attrs:{"title":_vm.$t('title-dialog-filter'),"max-width":"600","icon":"mdi-filter-plus","filterRoles":_vm.rolesFilter},on:{"filter-roles:change":function (roles) { return (_vm.rolesFilter = roles); }},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }