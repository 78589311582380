<i18n       src="./form-customer-specific-designation-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <StepContentTitle :title="$t('specific-designation-title')" />
        <v-container fill-height fluid no-gutters class="pa-0 ma-0">
            <!-- Kundenspezifische Bezeichnung (Checkbox "eigene Bezeichnungen hinzufügen?") -->
            <v-row dense>
                <v-col class="ml-2">
                    <!-- specificDesignation -->
                    <v-checkbox
                        value
                        v-model="specificDesignation"
                        :label="$t('specific-designation-label')"
                        color="primary"
                        append-icon="mdi-information-outline"
                        @click:append="showFaq('specificDesignation')"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="customerSpecificDesignation.specificDesignation">
                <v-col class="py-1" cols="12" sm="6">
                    <!-- equipmentDescription -->
                    <base-text-field
                        v-model="equipmentDescription"
                        :label="$t('equipment-description-label')"
                        :error-messages="equipmentDescriptionErrors"
                        :required="
                            isRequiredField(
                                $v.customerSpecificDesignation
                                    .equipmentDescription
                            )
                        "
                        append-icon="mdi-information-outline"
                        @click:append="showFaq('equipmentDescription')"
                        :counter="
                            $v.customerSpecificDesignation.equipmentDescription
                                .$params.maxLength.max
                        "
                    ></base-text-field>
                </v-col>
                <v-col class="py-1" cols="12" sm="6">
                    <!-- clientPosLocation -->
                    <base-text-field
                        v-model="posLocation"
                        :label="$t('pos-location-label')"
                        :error-messages="posLocationErrors"
                        append-icon="mdi-information-outline"
                        @click:append="showFaq('posLocation')"
                        :required="
                            isRequiredField(
                                $v.customerSpecificDesignation.posLocation
                            )
                        "
                        :counter="
                            $v.customerSpecificDesignation.posLocation.$params
                                .maxLength.max
                        "
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row v-if="customerSpecificDesignation.specificDesignation">
                <v-col class="py-1" cols="12" sm="6">
                    <!-- inventoryNumber -->
                    <base-text-field
                        v-model="inventoryNumber"
                        :label="$t('inventory-number-label')"
                        :error-messages="inventoryNumberErrors"
                        append-icon="mdi-information-outline"
                        @click:append="showFaq('inventoryNumber')"
                        :required="
                            isRequiredField(
                                $v.customerSpecificDesignation.inventoryNumber
                            )
                        "
                        :counter="
                            $v.customerSpecificDesignation.inventoryNumber
                                .$params.maxLength.max
                        "
                    ></base-text-field>
                </v-col>
                <v-col class="py-1" cols="12" sm="6">
                    <!-- constructionNumber -->
                    <base-text-field
                        v-model="constructionNumber"
                        :label="$t('construction-number-label')"
                        :error-messages="constructionNumberErrors"
                        append-icon="mdi-information-outline"
                        @click:append="showFaq('constructionNumber')"
                        :required="
                            isRequiredField(
                                $v.customerSpecificDesignation
                                    .constructionNumber
                            )
                        "
                        :counter="
                            $v.customerSpecificDesignation.constructionNumber
                                .$params.maxLength.max
                        "
                    ></base-text-field>
                </v-col>
                <v-col class="py-1" cols="12" sm="4">

                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import { requiredIf, maxLength } from "vuelidate/lib/validators";
import StepContentTitle from "../components/step-content-title.vue";
import _ from "lodash";
import validateMixin from "@/mixins/validate";
export default {
    name: "FormCustomerSpecificDesignation",
    mixins: [validateMixin],
    validations: {
        customerSpecificDesignation: {
            inventoryNumber: {
                required: requiredIf(function () {
                    if (
                        this.customerSpecificDesignation.specificDesignation &&
                        this.fieldAreNotEmpty()
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                maxLength: maxLength(30)
            },
            equipmentDescription: {
                required: requiredIf(function () {
                    if (
                        this.customerSpecificDesignation.specificDesignation &&
                        this.fieldAreNotEmpty()
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                maxLength: maxLength(30)
            },
            posLocation: {
                required: requiredIf(function () {
                    if (
                        this.customerSpecificDesignation.specificDesignation &&
                        this.fieldAreNotEmpty()
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                maxLength: maxLength(30)
            },
            constructionNumber: {
                required: requiredIf(function () {
                    if (
                        this.customerSpecificDesignation.specificDesignation &&
                        this.fieldAreNotEmpty()
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                maxLength: maxLength(30)
            }
        }
    },
    components: {
        StepContentTitle
    },
    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        customerSpecificDesignation: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        fieldAreNotEmpty() {
            let empty = true;
            if (this.customerSpecificDesignation.inventoryNumber) {
                empty = false;
            }
            if (this.customerSpecificDesignation.equipmentDescription) {
                empty = false;
            }
            if (this.customerSpecificDesignation.posLocation) {
                empty = false;
            }
            if (this.customerSpecificDesignation.constructionNumber) {
                empty = false;
            }
            return empty;
        },
        onFieldChange(field, value) {
            if (!field) return;
            this.onFormFieldChange(field, value);
            this.$emit("field-change", { field, value });
        },
        onFormFieldChange(field, value) {
            let custSpecDesig = _.cloneDeep(this.customerSpecificDesignation);
            custSpecDesig = _.set(custSpecDesig, field, value);
            this.onFormChange(custSpecDesig);
        },
        onFormChange(form) {
            this.$emit("form-change", form);
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        onSpecificDesignationChange(value) {
            this.$v.$reset();
            this.onFieldChange("specificDesignation", Boolean(value));
        },
        reset() {
            this.specificDesignation = false;
            this.resetFields();
        },
        resetFields() {
            this.$v.$reset();
            this.$emit("reset");
        },
        setFakeData() {
            let custSpecDesig = _.cloneDeep(this.customerSpecificDesignation);
            custSpecDesig.specificDesignation = true;
            custSpecDesig.inventoryNumber = "1234567890098765";
            custSpecDesig.equipmentDescription = "Meine Description";
            custSpecDesig.posLocation = "Meine Location";
            custSpecDesig.constructionNumber = "21312321312";
            this.onFormChange(custSpecDesig);
        }
    },
    computed: {
        specificDesignation: {
            get() {
                return this.customerSpecificDesignation.specificDesignation;
            },
            set(value) {
                this.$v.$reset();
                this.onFieldChange("specificDesignation", Boolean(value));
            }
        },
        equipmentDescription: {
            get() {
                return this.customerSpecificDesignation.equipmentDescription;
            },
            set(value) {
                this.onFieldChange("equipmentDescription", value);
            }
        },
        posLocation: {
            get() {
                return this.customerSpecificDesignation.posLocation;
            },
            set(value) {
                this.onFieldChange("posLocation", value);
            }
        },
        inventoryNumber: {
            get() {
                return this.customerSpecificDesignation.inventoryNumber;
            },
            set(value) {
                this.onFieldChange("inventoryNumber", value);
            }
        },
        constructionNumber: {
            get() {
                return this.customerSpecificDesignation.constructionNumber;
            },
            set(value) {
                this.onFieldChange("constructionNumber", value);
            }
        },

        inventoryNumberErrors() {
            const errors = [];
            if (!this.$v.customerSpecificDesignation.inventoryNumber.$dirty) {
                return errors;
            }
            if (!this.$v.customerSpecificDesignation.inventoryNumber.required) {
                errors.push(this.$t("field-required"));
            }
            if (
                !this.$v.customerSpecificDesignation.inventoryNumber.maxLength
            ) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.customerSpecificDesignation.inventoryNumber
                                .$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        equipmentDescriptionErrors() {
            const errors = [];
            if (
                !this.$v.customerSpecificDesignation.equipmentDescription.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.customerSpecificDesignation.equipmentDescription
                    .required
            ) {
                errors.push(this.$t("field-required"));
            }
            if (
                !this.$v.customerSpecificDesignation.equipmentDescription
                    .maxLength
            ) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.customerSpecificDesignation
                                .equipmentDescription.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        posLocationErrors() {
            const errors = [];
            if (!this.$v.customerSpecificDesignation.posLocation.$dirty) {
                return errors;
            }
            if (!this.$v.customerSpecificDesignation.posLocation.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.customerSpecificDesignation.posLocation.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.customerSpecificDesignation.posLocation
                                .$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        constructionNumberErrors() {
            const errors = [];
            if (
                !this.$v.customerSpecificDesignation.constructionNumber.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.customerSpecificDesignation.constructionNumber.required
            ) {
                errors.push(this.$t("field-required"));
            }
            if (
                !this.$v.customerSpecificDesignation.constructionNumber
                    .maxLength
            ) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.customerSpecificDesignation
                                .constructionNumber.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    }
};
</script>
