<template>
    <div class="text-center">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="250"
            :max-width="350"
            offset-y
            bottom
            left
        >
            <template v-slot:activator="{ on }">
                <transition name="fade" mode="out-in">
                    <v-btn icon color="regOfficePrimary" large v-on="on">
                        <v-icon class="material-icons expand" v-if="menu"
                            >mdi-close</v-icon
                        >
                        <v-icon class="material-icons expand" v-else
                            >mdi-menu</v-icon
                        >
                    </v-btn>
                </transition>
            </template>
            <div class="ps-navigation-content backgroundInner">
                <div
                    class="shadow-bottom"
                    :class="{ 'd-block': shallShadowBottom }"
                />
                <PerfectScrollbar
                    class="ps-navigation-content-items"
                    :options="perfectScrollbarOptions"
                    @ps-scroll-y="
                        (evt) => {
                            shallShadowBottom = evt.srcElement.scrollTop > 0;
                        }
                    "
                >
                    <v-card
                        color="backgroundInner"
                        class="mx-auto flexcard page-height"
                        outlined
                        max-width="360"
                    >
                        <slot name="content" />
                        <v-card-actions class="card-actions backgroundInner">
                            <v-container fill-height fluid no-gutters>
                                <slot name="footer" />
                                <NavigationFooter @logout="onLogout" />
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </PerfectScrollbar>
            </div>
        </v-menu>
    </div>
</template>
<script    >
import NavigationFooter from "./navigation-footer.vue";
export default {
    name: "AppMenu",
    components: {
        NavigationFooter
    },

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        shallShadowBottom: null,
        perfectScrollbarOptions: {
            maxScrollbarLength: 80,
            wheelPropagation: false,
            minScrollbarLength: 20,
            suppressScrollX: true
        }
    }),
    computed: {
        menu: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },

    methods: {
        onLogout() {
            this.$store.dispatch("auth/logout");
            this.$emit("input", false);
        }
    }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style lang="sass" scoped>
.ps-navigation-content
    height: 100% !important
    .ps__rail-y,
    .ps__rail-x
        opacity: 0.6 !important
        background-color: transparent !important
    .ps-navigation-content-items
        height: calc(100% - 57px) !important

    // menu scroll shadow
    .shadow-bottom
        display: none
        position: absolute
        z-index: 2
        height: 50px
        width: 100%
        left: -6px
        pointer-events: none
        margin-top: -0.7rem
        filter: blur(5px)

.page-height
    max-height: calc(100vh - 130px)

.card-actions
    position: sticky
    bottom: 0
</style>
