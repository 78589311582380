<template>
  <SectionBase id="devel-keyboard-input" title="Keyboard-Input">
    <v-row align="center">
      <v-col cols="6">
        <base-text-field
          readonly
          v-model="VKeyboardInteger"
          @click="onKeyboardFieldClick('integer')"
          label="Integer"
        ></base-text-field>
      </v-col>
      <v-col cols="6">
        <base-text-field
          readonly
          v-model="VKeyboardDecimal"
          @click="
            onKeyboardFieldClick('decimal');
            vKeyboardAllowNegative = false;
          "
          label="Decimal"
        ></base-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6">
        <base-text-field
          readonly
          v-model="VKeyboardIntegerNegative"
          @click="
            onKeyboardFieldClick('integer');
            vKeyboardAllowNegative = true;
          "
          label="Integer Negative"
        ></base-text-field>
      </v-col>
      <v-col cols="6">
        <base-text-field
          readonly
          v-model="VKeyboardDecimalNegative"
          @click="
            onKeyboardFieldClick('decimal');
            vKeyboardAllowNegative = true;
          "
          label="Decimal Negative"
        ></base-text-field>
      </v-col>
    </v-row>
    <ModalVirtualKeyboard
      :allowNegative="vKeyboardAllowNegative"
      :keyboardType="keyboardType"
      :show="showKeyboard"
      @input="showKeyboard = $event"
      :title="VKeyboardTitle"
      @save="onKeyboardSave"
      :value="VKeyboardValue"
    />
  </SectionBase>
</template>

<script>
export default {
  name: "SectionKeyboardInput",
  components: {
    SectionBase: () => import("./base.vue"),
    ModalVirtualKeyboard: () =>
      import("@/components/general/VirtualKeyboard/Modal.vue"),
  },
  data: () => ({
    showKeyboard: false,
    keyboardType: null,
    VKeyboardInteger: "",
    VKeyboardDecimal: "",
    VKeyboardIntegerNegative: "",
    VKeyboardDecimalNegative: "",
    VKeyboardValue: "",
    VKeyboardTitle: "",
    vKeyboardAllowNegative: false,
  }),
  methods: {
    onKeyboardFieldClick(type) {
      this.keyboardType = type;
      if (this.keyboardType === "integer") {
        this.VKeyboardValue = this.VKeyboardInteger;
        this.VKeyboardTitle = "Numeric Keyboard";
      }
      if (this.keyboardType === "decimal") {
        this.VKeyboardValue = this.VKeyboardDecimal;
        this.VKeyboardTitle = "Decimal Keyboard";
      }
      this.showKeyboard = true;
    },
    onKeyboardSave(value) {
      if (this.keyboardType === "integer") {
        this.VKeyboardInteger = value;
      }
      if (this.keyboardType === "decimal") {
        this.VKeyboardDecimal = value;
      }
    },
  },
};
</script>
