<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="@/components/tasks/edit/backoffice/components/dialog-complete-registration-i18n.yaml"></i18n>
<template>
    <SectionBase
        id="devel-size-class"
        title="SIZE CLASS"
        subtitle="Test Size Class Field dependending on type"
    >
        <v-card-text>
            <v-row dense>
                <v-col cols="12">
                    <base-text-field
                        v-model="totalChambersVolume"
                        label="totalChambersVolume"
                        @input="onChamberLiterChanges"
                    ></base-text-field>
                </v-col>
                <!-- Objekt-Status -->
                <v-col cols="12">
                    <base-autocomplete
                        v-model="objectStatus"
                        :items="sortedObjectStatusItems"
                        :label="$t('object-status-label')"
                        item-value="key"
                        clearable
                        :filter="customFilter"
                    >
                        <template v-slot:item="{ item }">
                            <span v-if="item.value[$i18n.locale]">
                                <b>{{ item.key }}</b
                                >:
                                {{ item.value[$i18n.locale] }}
                            </span>
                            <span v-else>
                                <b>{{ item.key }}</b
                                >: {{ item.value.de }}
                            </span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <!-- use vuetify class to break the text if is too large  -->
                            <div
                                class="v-select__selection v-select__selection--comma"
                            >
                                <span v-if="item.value[$i18n.locale]">
                                    <b>{{ item.key }}</b
                                    >:
                                    {{ item.value[$i18n.locale] }}
                                </span>
                                <span v-else>
                                    <b>{{ item.key }}</b
                                    >: {{ item.value.de }}
                                </span>
                            </div>
                        </template>
                    </base-autocomplete>
                </v-col>
                <v-col cols="12" v-if="objectStatus">
                    <base-autocomplete
                        :items="sortedObjectCodeItems"
                        v-model="objectCode"
                        :label="$t('object-code-label')"
                        item-value="key"
                        clearable
                        :filter="customFilter"
                        :item-text="
                            (item) =>
                                item.value[$i18n.locale]
                                    ? item.value[$i18n.locale]
                                    : item.value.de
                        "
                    >
                    </base-autocomplete>
                </v-col>
                <v-col cols="12" v-if="objectStatus && objectCode">
                    <base-select
                        :items="objectCategoryItems"
                        v-model="objectCategory"
                        :label="$t('object-category-label')"
                        clearable
                        @change="onObjectCategoryChange"
                        :item-text="
                            (item) =>
                                $t(
                                    `task-object-category-${FILTERS.kebabizeLower(
                                        item.value
                                    )}`
                                )
                        "
                    >
                    </base-select>
                </v-col>
                <!-- Grössenklasse: 01-12 -->
                <v-col
                    cols="12"
                    v-if="objectCategory && objectStatus && objectCode"
                >
                    <base-autocomplete
                        v-model="sizeClass"
                        :items="sizeClassItems"
                        :label="$t('size-class-label')"
                        item-value="id"
                        clearable
                        :filter="customFilter"
                    >
                        <template v-slot:item="{ item }">
                            <i18n
                                path="size-class-text-00"
                                tag="span"
                                v-if="
                                    (item.range[0] === 0) &&
                                    item.range[1]
                                "
                            >
                                <template #value>
                                    <b>{{ item.id }}</b>
                                </template>
                                <template #range2>
                                    <b>{{ item.range[1] }}</b>
                                </template>
                            </i18n>
                            <i18n
                                path="size-class-text-01"
                                tag="span"
                                v-if="
                                    item.range[0] &&
                                    item.range[1]
                                "
                            >
                                <template #value>
                                    <b>{{ item.id }}</b>
                                </template>
                                <template #range1>
                                    <b>{{ item.range[0] }}</b>
                                </template>
                                <template #range2>
                                    <b>{{ item.range[1] }}</b>
                                </template>
                            </i18n>
                            <i18n
                                path="size-class-text-02"
                                tag="span"
                                v-if="
                                    item.range[0] &&
                                    !item.range[1]
                                "
                            >
                                <template #value>
                                    <b>{{ item.id }}</b>
                                </template>
                                <template #range1>
                                    <b>{{ item.range[0] }}</b>
                                </template>
                            </i18n>
                        </template>
                        <template v-slot:selection="{ item }">
                            <!-- use vuetify class to break the text if is too large  -->
                            <div
                                class="v-select__selection v-select__selection--comma"
                            >
                                <i18n
                                    path="size-class-text-00"
                                    tag="span"
                                    v-if="
                                        (item.range[0] === 0) &&
                                        item.range[1]
                                    "
                                >
                                    <template #value>
                                        <b>{{ item.id }}</b>
                                    </template>
                                    <template #range2>
                                        <b>{{ item.range[1] }}</b>
                                    </template>
                                </i18n>
                                <i18n
                                    path="size-class-text-01"
                                    tag="span"
                                    v-if="
                                        item.range[0] &&
                                        item.range[1]
                                    "
                                >
                                    <template #value>
                                        <b>{{ item.id }}</b>
                                    </template>
                                    <template #range1>
                                        <b>{{ item.range[0] }}</b>
                                    </template>
                                    <template #range2>
                                        <b>{{ item.range[1] }}</b>
                                    </template>
                                </i18n>
                                <i18n
                                    path="size-class-text-02"
                                    tag="span"
                                    v-if="
                                        item.range[0] &&
                                        !item.range[1]
                                    "
                                >
                                    <template #value>
                                        <b>{{ item.id }}</b>
                                    </template>
                                    <template #range1>
                                        <b>{{ item.range[0] }}</b>
                                    </template>
                                </i18n>
                            </div>
                        </template>
                    </base-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
    </SectionBase>
</template>

<script>
import {
    OBJECT_CATEGORIES,
    SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY
} from "@/data/globals.js";
import { mapActions } from "vuex";
import { sortAttributesAlphabetically } from "@/utils";
import _ from "lodash";
import { FILTERS } from "@/filters";
export default {
    name: "SectionSizeClass",
    components: {
        SectionBase: () => import("./base.vue")
    },
    data: () => ({
        FILTERS,
        totalChambersVolume: 0,
        objectStatusItems: [],
        objectCodeItems: [],
        objectCategoryItems: [
            {
                value: OBJECT_CATEGORIES.STEAM_AND_HW_BOILERS
            },
            {
                value: OBJECT_CATEGORIES.ELECTRIC_BOILER
            },
            {
                value: OBJECT_CATEGORIES.PRESSURE_TANK
            }
        ],
        sizeClassItems: [],
        objectStatus: null,
        sizeClass: null,
        objectCode: null,
        objectCategory: null
    }),
    computed: {
        sortedObjectStatusItems() {
            if (this.objectStatusItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            // [1]- IBB
            // [2]- IIE
            // [3]- NNU
            // [..]- rest
            let ret = [];
            const sorted = sortAttributesAlphabetically(
                this.objectStatusItems,
                locale
            );
            const ibb = _.find(this.objectStatusItems, ["key", "IBB"]);
            if (ibb) {
                ret.push(ibb);
            }
            const iie = _.find(this.objectStatusItems, ["key", "IIE"]);
            if (iie) {
                ret.push(iie);
            }
            const nnu = _.find(this.objectStatusItems, ["key", "NNU"]);
            if (nnu) {
                ret.push(nnu);
            }
            return _.union(ret, sorted);
        },
        sortedObjectCodeItems() {
            if (this.objectCodeItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(this.objectCodeItems, locale);
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        customFilter(item, queryText) {
            const locale = this.$i18n.locale || "de";
            const itemValue = item.value[locale]
                ? item.value[locale]
                : item.value["de"];
            const itemKey = item.key.toLowerCase();
            const itemValueText = itemValue.toLowerCase();
            const searchText = queryText.toLowerCase();
            return (
                itemValueText.indexOf(searchText) > -1 ||
                itemKey.indexOf(searchText) > -1
            );
        },
        setSizeClassItemsByObjectCategory(objectCategory) {
            if (objectCategory) {
                this.sizeClassItems =
                    SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY(objectCategory);
            }
        },
        fetchObjectCodeItems() {
            let objectCodeKey = "objektcode";
            this.loader().show();
            this.getStoredAttributesByKey(objectCodeKey)
                .then((items) => {
                    this.objectCodeItems = items;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("object-code")
                        })
                    );
                });
        },
        fetchObjectStatusItems() {
            let objectStatusKey = "objektstatus";
            this.loader().show();
            this.getStoredAttributesByKey(objectStatusKey)
                .then((items) => {
                    this.objectStatusItems = items;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("object-status")
                        })
                    );
                });
        },
        onObjectCategoryChange(value) {
            if (value) {
                this.setSizeClassItemsByObjectCategory(value);
                this.setSizeClassValueByRangeValue(this.totalChambersVolume);
            } else {
                this.sizeClass = null;
            }
        },
        setSizeClassValueByRangeValue(value) {
            if (_.isNaN(value) || _.isNil(value) || value === "") {
                return;
            }
            value = _.toNumber(value);

            for (let i = 0; i < this.sizeClassItems.length; i++) {
                let item = this.sizeClassItems[i];
                let range = item.range;
                if (range) {
                    let inRange = false;
                    if (range.length === 2) {
                        if (value >= range[0] && value <= range[1]) {
                            inRange = true;
                        }
                    }
                    if (range.length === 1) {
                        if (value > range[0]) {
                            inRange = true;
                        }
                    }
                    if (inRange) {
                        return (this.sizeClass = item.id);
                    }
                }
            }
        },
        onChamberLiterChanges(value) {
            if (this.objectCategory) {
                this.setSizeClassValueByRangeValue(value);
            }
        }
    },
    created() {
        this.fetchObjectCodeItems();
        this.fetchObjectStatusItems();
    }
};
</script>
