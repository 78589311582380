var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"readonly":_vm.readonlyModus,"disabled":_vm.disabled}},[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('base-btn',{attrs:{"icon":"","color":"warning"},on:{"click":_vm.setFakeData}},[_c('v-icon',[_vm._v("mdi-bug")])],1),_c('base-btn',{attrs:{"icon":"","color":"yellow"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]},proxy:true}])}),_c('StepContentTitle',{attrs:{"title":_vm.$t('specific-designation-title')}}),_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fill-height":"","fluid":"","no-gutters":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"ml-2"},[_c('v-checkbox',{attrs:{"value":"","label":_vm.$t('specific-designation-label'),"color":"primary","append-icon":"mdi-information-outline"},on:{"click:append":function($event){return _vm.showFaq('specificDesignation')}},model:{value:(_vm.specificDesignation),callback:function ($$v) {_vm.specificDesignation=$$v},expression:"specificDesignation"}})],1)],1),(_vm.customerSpecificDesignation.specificDesignation)?_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('equipment-description-label'),"error-messages":_vm.equipmentDescriptionErrors,"required":_vm.isRequiredField(
                            _vm.$v.customerSpecificDesignation
                                .equipmentDescription
                        ),"append-icon":"mdi-information-outline","counter":_vm.$v.customerSpecificDesignation.equipmentDescription
                            .$params.maxLength.max},on:{"click:append":function($event){return _vm.showFaq('equipmentDescription')}},model:{value:(_vm.equipmentDescription),callback:function ($$v) {_vm.equipmentDescription=$$v},expression:"equipmentDescription"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('pos-location-label'),"error-messages":_vm.posLocationErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(
                            _vm.$v.customerSpecificDesignation.posLocation
                        ),"counter":_vm.$v.customerSpecificDesignation.posLocation.$params
                            .maxLength.max},on:{"click:append":function($event){return _vm.showFaq('posLocation')}},model:{value:(_vm.posLocation),callback:function ($$v) {_vm.posLocation=$$v},expression:"posLocation"}})],1)],1):_vm._e(),(_vm.customerSpecificDesignation.specificDesignation)?_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('inventory-number-label'),"error-messages":_vm.inventoryNumberErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(
                            _vm.$v.customerSpecificDesignation.inventoryNumber
                        ),"counter":_vm.$v.customerSpecificDesignation.inventoryNumber
                            .$params.maxLength.max},on:{"click:append":function($event){return _vm.showFaq('inventoryNumber')}},model:{value:(_vm.inventoryNumber),callback:function ($$v) {_vm.inventoryNumber=$$v},expression:"inventoryNumber"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('construction-number-label'),"error-messages":_vm.constructionNumberErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(
                            _vm.$v.customerSpecificDesignation
                                .constructionNumber
                        ),"counter":_vm.$v.customerSpecificDesignation.constructionNumber
                            .$params.maxLength.max},on:{"click:append":function($event){return _vm.showFaq('constructionNumber')}},model:{value:(_vm.constructionNumber),callback:function ($$v) {_vm.constructionNumber=$$v},expression:"constructionNumber"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }