export class Chamber {
    constructor() {
        this.descripton = null;
        this.volume = null;
        this.lastMinPressure = null;
        this.lastMaxPressure = null;
        this.lastMinConcessionPressure = null;
        this.lastMaxConcessionPressure = null;
        this.lastMinConcessionTemperature = null;
        this.lastMaxConcessionTemperature = null;

        this.coverageNotAvailable = false;
        this.coverageNotAvailableReason = null;
        this.coverageArt = '';
        this.coverageLocation = '';
        this.coveragePressure = '';
        this.materialCode = '';
    }
}
