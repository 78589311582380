import { Base } from "./Base";
export class BaseRegistration extends Base {
    constructor() {
        super();
        this.step = 1;
        this.workingTitle = null;
        this.manufacturer = null;
        this.intendedUse = null;
        this.inOperationYear = null;
    }
}
