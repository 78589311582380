<i18n src="./test-view-i18n.yaml"></i18n>
<template>
    <v-row>
        <v-col cols="12">
            <SectionBase id="devel-toc">
                <v-card class="mx-auto" max-width="300" tile flat>
                    <v-card-title class="text-h5 primary--text"
                        >TableOfContents</v-card-title
                    >
                    <v-list dense>
                        <template v-for="item in TOCitems">
                            <v-list-group
                                v-if="item.items"
                                :key="item.title"
                                v-model="item.active"
                                :prepend-icon="item.icon"
                                no-action
                            >
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="item.title"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item
                                    v-for="child in item.items"
                                    :key="child.title"
                                    @click="$vuetify.goTo(child.link)"
                                >
                                    <v-list-item-icon>
                                        <v-icon v-text="child.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="child.title"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <v-list-item
                                v-else
                                :key="item.title"
                                link
                                @click="$vuetify.goTo(item.link)"
                            >
                                <v-list-item-icon class="justify-center">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitile-1">{{
                                        item.title
                                    }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </SectionBase>
        </v-col>
        <v-col cols="12" :md="6">
            <SectionSuvaApi />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionUidSearchApi />
        </v-col>
        <v-col cols="12">
            <SectionSvgIcons />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionKeyboardInput />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionMailTemplates />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionWFields />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionBaseFields />
        </v-col>
        <v-col cols="12">
            <SectionRegistrationOffice />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionUtilCompareObjects />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionGeneralComponents />
        </v-col>
        <v-col cols="12" :md="6">
            <SectionSizeClass />
        </v-col>
        <v-col cols="12" :md="6">
            Adress
            <div v-if="address">
                {{ address }}
            </div>
            <AddressCard v-model="address" ref="address" />
            <v-card-actions>
                <base-btn @click="validateAddress" type="success"
                    >Validate</base-btn
                >
                <base-btn @click="setFakeAddress" type="devel"
                    >setFakeAddress</base-btn
                >
                <base-btn @click="clearAddress" type="delete">Clear</base-btn>
            </v-card-actions>
        </v-col>
        <v-col cols="12">
            <SectionCharts />
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "TestView",
    components: {
        SectionBase: () => import("./sections/base.vue"),
        SectionSuvaApi: () => import("./sections/suva-api.vue"),
        SectionUidSearchApi: () => import("./sections/uid-search-api.vue"),
        SectionSvgIcons: () => import("./sections/svg-icons.vue"),
        SectionKeyboardInput: () => import("./sections/keyboard-input.vue"),
        SectionWFields: () => import("./sections/w-fields.vue"),
        SectionRegistrationOffice: () =>
            import("./sections/registration-office.vue"),
        SectionBaseFields: () => import("./sections/base-fields.vue"),
        SectionUtilCompareObjects: () =>
            import("./sections/util-compare-objects.vue"),
        SectionGeneralComponents: () =>
            import("./sections/general-components.vue"),
        SectionMailTemplates: () => import("./sections/mail-templates.vue"),
        SectionCharts: () => import("./sections/charts.vue"),
        AddressCard: () =>
            import("@/components/tasks/edit/common/forms/address/address.vue"),
        SectionSizeClass: () =>
            import("./sections/size-class.vue")
    },
    data: () => ({
        address: {},
        TOCitems: [
            {
                icon: "mdi-api",
                title: "Suva-API",
                link: "#devel-suva-api"
            },
            {
                icon: "mdi-api",
                title: "UID-Search API",
                link: "#devel-uid-search-api"
            },
            {
                icon: "mdi-svg",
                title: "SVG-Icons",
                link: "#devel-svg-icons"
            },
            {
                icon: "mdi-keyboard",
                title: "Keyboard-Input",
                link: "#devel-keyboard-input"
            },
            {
                icon: "mdi-bug",
                title: "WFields",
                // link: "#devel-w-fields",
                items: [
                    {
                        icon: "mdi-arrow-right",
                        title: "WCascader",
                        link: "#devel-w-fields-w-cascader"
                    },
                    {
                        icon: "mdi-earth",
                        title: "WCountrySelect",
                        link: "#devel-w-fields-w-country-select"
                    },
                    {
                        icon: "mdi-office-building",
                        title: "WManufacturerSearch",
                        link: "#devel-w-fields-w-manufacturer-search"
                    },
                    {
                        icon: "mdi-phone",
                        title: "WTelNumberField",
                        link: "#devel-w-fields-w-tel-number-field"
                    },
                    {
                        icon: "mdi-map-marker",
                        title: "WLocationSearch",
                        link: "#devel-w-fields-w-location-search"
                    },
                    {
                        icon: "mdi-numeric",
                        title: "WNumericField",
                        link: "#devel-w-fields-w-numeric-field"
                    },
                    {
                        icon: "mdi-decimal",
                        title: "WDecimalField",
                        link: "#devel-w-fields-w-decimal-field"
                    }
                ]
            },
            {
                icon: "mdi-account-hard-hat",
                title: "RegistrationOffice",
                link: "#devel-registration-office"
            },
            {
                icon: "mdi-bug",
                title: "Base-Fields",
                // link: "#devel-base-fields",
                items: [
                    {
                        icon: "mdi-format-text",
                        title: "BaseHeader",
                        link: "#devel-base-fields-header"
                    },
                    {
                        icon: "mdi-format-text",
                        title: "BaseSubheader",
                        link: "#devel-base-fields-subheader"
                    },
                    {
                        icon: "mdi-cursor-text",
                        title: "BaseTextField",
                        link: "#devel-base-fields-text-field"
                    },
                    {
                        icon: "mdi-form-textarea",
                        title: "BaseTextarea",
                        link: "#devel-base-fields-textarea"
                    },
                    {
                        icon: "mdi-form-textbox",
                        title: "BaseAutocomplete",
                        link: "#devel-base-fields-autocomplete"
                    },
                    {
                        icon: "mdi-form-select",
                        title: "BaseSelect",
                        link: "#devel-base-fields-select"
                    },
                    {
                        icon: "mdi-gesture-tap-hold",
                        title: "BaseButtons",
                        link: "#devel-base-fields-buttons"
                    }
                ]
            },
            {
                icon: "mdi-compare",
                title: "Compare 2 Objects",
                link: "#devel-util-compare-objects"
            },
            {
                icon: "mdi-cog",
                title: "General Components",
                // link: "#devel-general-components",
                items: [
                    {
                        icon: "mdi-loading",
                        title: "GlobalOverlay",
                        link: "#devel-general-components-global-overlay"
                    },
                    {
                        icon: "mdi-bread-slice",
                        title: "Vuetify-Toast-Snackbar",
                        link: "#devel-general-components-vuetify-toast-snackbar"
                    },
                    {
                        icon: "mdi-package-variant",
                        title: "W-Msg-Box",
                        link: "#devel-general-components-w-msg-box"
                    }
                ]
            },
            {
                icon: "mdi-email",
                title: "Mail-Templates",
                link: "#devel-mail-templates"
            },
            {
                icon: "mdi-chart-bar",
                title: "Charts",
                link: "#devel-charts"
            }
        ]
    }),
    methods: {
        validateAddress() {
            this.$refs.address.validate();
            let isValid = this.$refs.address.isValid();
            if (isValid === false || !isValid) {
                this.$toast.error("Invalid");
            } else {
                this.$toast.success("Valid");
            }
        },
        clearAddress() {
            this.$refs.address.reset();
            this.$refs.address.clear();
        },
        setFakeAddress() {
            this.address = {
                additionalAddressLines: false,
                addressLine1: null,
                addressLine2: null,
                street: "Postgasse",
                houseNr: "38",
                canton: "BE",
                zipCode: 3011,
                city: "Bern",
                country: "CH",
                addressType: "COMPANY",
                communication: {
                    phone: "+41 79 875 77 00",
                    email: "coelho@was.ch",
                    fax: null
                },
                poBox: false,
                poBoxNr: null,
                poBoxZipCode: null,
                poBoxCity: null,
                companyName: "Lies was GmbH",
                department: null,
                uid: 275152675,
                uidCategory: "CHE",
                uidFormatted: "CHE-275.152.675",
                sameAddressAsOperator: false,
                name: "Lies was GmbH"
            };
        }
    }
};
</script>
