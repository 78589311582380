import { axiosBackoffice, axiosTasks } from "@/api/axios-instances";
import {
    RegistrationBackoffice,
    ReviewVerification,
    TaskDialog,
    TaskDialogStatus,
    DialogMessage
} from "@/models";
import {
    ContactPerson,
    CustomerSpecificDesignation,
    LocationAddress
} from "@/models/tasks";
import store from "@/store";
import { debounce } from "lodash";
import _ from "lodash";
import Helpers from "@/utils/helpers";
import { AUTO_SAVE } from "../helpers/actions";
import { TaskStatus } from "@/data/globals";
import * as ACTION_TYPES from "../types/action-types";
import * as MUTATION_TYPES from "../types/mutation-types";
import { PROCESS_TYPES } from "@/data/globals";

const initialState = () => ({
    ...new RegistrationBackoffice(),
    attachments: {},
    lastChangeTimestamp: null,
    state: null,
    lang: null,
    registrationOffice: null,
    scrollPosition: null,
    readonlyModus: false,
    registrant: {
        email: null,
        familyName: null,
        firstName: null
    }
});

const state = initialState();
const mutations = {
    setTitle(state, title) {
        if (!title) return;
        state.title = title;
    },
    setSubtitle(state, subtitle) {
        if (!subtitle) return;
        state.subtitle = subtitle;
    },
    setDefaulTitle(state) {
        state.title = null;
        state.subtitle = null;
    },
    [MUTATION_TYPES.SET_REGISTRATION](state, payload) {
        const registration = Object.assign(
            new RegistrationBackoffice(),
            payload
        );
        Object.assign(state, registration);
    },
    [MUTATION_TYPES.CLEAR_REGISTRATION](state) {
        Object.assign(state, initialState());
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_FIELD](state, { field, value }) {
        state[field] = value;
    },
    [MUTATION_TYPES.MERGE_REGISTRATION_FIELDS](state, { field_name, values }) {
        if (!field_name) return;
        Object.assign(state[field_name], values);
    },
    [MUTATION_TYPES.CLEAR_REGISTRATION_ADDRESS](state, { field, address }) {
        if (!field) return;
        if (field === "operatorAddress") {
            let billingAddress = state.billingAddress;
            let ownerAddress = state.ownerAddress;
            if (address) {
                if (billingAddress && billingAddress.sameAddressAsOperator) {
                    state.billingAddress = Object.assign({}, address);
                }
                if (ownerAddress && ownerAddress.sameAddressAsOperator) {
                    state.ownerAddress = Object.assign({}, address);
                }
                state.operatorAddress = Object.assign({}, address);
            } else {
                state.operatorAddress = {};
            }
        } else {
            if (field) {
                if (!address) {
                    state[field] = {};
                } else {
                    state[field] = Object.assign({}, address);
                }
            }
        }
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS_FIELD](
        state,
        { address, field, value }
    ) {
        if (!address || !field) return;
        let newAddress = _.cloneDeep(state[address]);
        newAddress = _.set(newAddress, field, value);
        state[address] = Object.assign({}, newAddress);
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS](state, { field, value }) {
        if (!field) return;
        state[field] = _.set(state, field, value);
    },
    [MUTATION_TYPES.UPDATE_CHAMBERS](state, chamber) {
        if (chamber.id) {
            if (state.chambers.length > 0) {
                state.chambers[chamber.id - 1] = chamber;
            } else {
                state.chambers.push(chamber);
            }
        }
    },
    [MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON](state, data) {
        let contactPerson = Object.assign(
            state.equipmentLocation.contactPerson,
            data
        );
        _.set(state.equipmentLocation, "contactPerson", contactPerson);
    },
    [MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_ADDRESS](state, data) {
        let address = Object.assign(state.equipmentLocation.address, data);
        _.set(state.equipmentLocation, "address", address);
    },
    [MUTATION_TYPES.REMOVE_CHAMBER_BY_INDEX](state, index) {
        state.chambers.splice(index, 1);
    },
    [MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_ADDRESS](state) {
        _.set(state.equipmentLocation, "address", new LocationAddress());
    },
    [MUTATION_TYPES.RESET_CUSTOMER_SPECIFIC_DESIGNATION](state) {
        state.customerSpecificDesignation = new CustomerSpecificDesignation();
    },
    [MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_CONTACT_PERSON](state) {
        _.set(state.equipmentLocation, "contactPerson", new ContactPerson());
    },
    [MUTATION_TYPES.ENABLE_READONLY_MODUS](state) {
        state.readonlyModus = true;
    },
    [MUTATION_TYPES.DISABLE_READONLY_MODUS](state) {
        state.readonlyModus = false;
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_REVIEW_STATE](state, { step, value }) {
        _.set(state.reviewVerification[step], "approved", value);
    },
    [MUTATION_TYPES.CLEAR_REGISTRATION_REVIEW](state) {
        if (state.reviewVerification) {
            state.reviewVerification = new ReviewVerification();
        }
    },
    [ACTION_TYPES.UPDATE_REGISTRATION_REVIEW_DIALOG](state, { step, dialog }) {
        let reviewVerification = state.reviewVerification;
        let reviewVerificationStep = reviewVerification[step];
        _.set(reviewVerificationStep, "dialog", dialog);
        state.reviewVerification = Object.assign({}, reviewVerification);
    },
    [MUTATION_TYPES.DELETE_REGISTRATION_REVIEW_DIALOG](state, { step }) {
        _.set(state.reviewVerification[step], "dialog", new TaskDialog());
    },
    [MUTATION_TYPES.SET_DIALOG_TO_ACCEPTED](state, payload) {
        const reviewVerification = state.reviewVerification;
        const stepAndMsgIdx = Helpers.getStepAndMsgIndexOrNull(
            reviewVerification,
            payload.messageId
        );
        if (stepAndMsgIdx) {
            const step = stepAndMsgIdx[0];
            const dialog = reviewVerification[step].dialog;
            dialog.note = payload.note;
            dialog.reason = payload.reason;
            dialog.state = TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS;
            _.set(state, "reviewVerification", reviewVerification);
        }
    },
    [MUTATION_TYPES.ADD_QUESTION_TO_DIALOG](state, question) {
        const reviewVerification = state.reviewVerification;
        const stepAndMsgIdx = Helpers.getStepAndMsgIndexOrNull(
            reviewVerification,
            question.messageId
        );
        if (stepAndMsgIdx) {
            const step = stepAndMsgIdx[0];
            let dialogMsg = new DialogMessage();
            const dialog = reviewVerification[step].dialog;
            const messages = dialog.messages;
            delete question.messageId;
            delete question.taskDocId;
            dialogMsg.id = question.id;
            delete question.id;
            dialogMsg.question = Object.assign({}, question);
            messages.push(dialogMsg);
            dialog.state = TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS;
            state.reviewVerification = Object.assign({}, reviewVerification);
        }
    },
    [MUTATION_TYPES.EDIT_MESSAGE_DIALOG](state, message) {
        const reviewVerification = state.reviewVerification;
        const stepAndMsgIdx = Helpers.getStepAndMsgIndexOrNull(
            reviewVerification,
            message.messageId
        );
        if (stepAndMsgIdx) {
            const step = stepAndMsgIdx[0];
            const msgIdx = stepAndMsgIdx[1];
            const dialog = reviewVerification[step].dialog;
            const messages = dialog.messages;
            let dialogMsg = messages[msgIdx];
            if (
                dialogMsg.question &&
                dialogMsg.question.text &&
                (!dialogMsg.reply || !dialogMsg.reply.text)
            ) {
                dialogMsg.question.text = message.text;
            }
            if (
                dialogMsg.question &&
                dialogMsg.question.text &&
                dialogMsg.reply &&
                dialogMsg.reply.text
            ) {
                dialogMsg.reply.text = message.text;
            }
        }
        _.set(state, "reviewVerification", reviewVerification);
    },
    [MUTATION_TYPES.DELETE_MESSAGES_FROM_DIALOG](state, message) {
        const reviewVerification = state.reviewVerification;
        const stepAndMsgIdx = Helpers.getStepAndMsgIndexOrNull(
            reviewVerification,
            message.messageId
        );
        if (stepAndMsgIdx) {
            const step = stepAndMsgIdx[0];
            const msgIdx = stepAndMsgIdx[1];
            const dialog = reviewVerification[step].dialog;
            if (dialog.state === 20) {
                dialog.messages.splice(msgIdx, 1);
                dialog.state = 30;
            }
        }
        _.set(state, "reviewVerification", reviewVerification);
    },
    [MUTATION_TYPES.UPDATE_BACKOFFICE_FIELD](state, { field, value }) {
        if (!field) return;
        let backoffice = _.set(state.backoffice, field, value);
        state.backoffice = Object.assign({}, backoffice);
    }
};

const getters = {
    readonlyModus(state) {
        return state.readonlyModus;
    },
    docId(state) {
        return state.docId;
    },
    state(state) {
        return state.state;
    },
    referenceId(state) {
        return state.referenceId;
    },
    locationAddress(state) {
        return state.equipmentLocation?.address;
    },
    locationContactPerson(state) {
        return state.equipmentLocation?.contactPerson;
    },
    customerSpecificDesignation(state) {
        return state.customerSpecificDesignation;
    },
    billingAdditionalInfos(state) {
        return state.billingAdditionalInfos;
    },
    reviewVerification(state) {
        return state.reviewVerification;
    },
    backoffice(state) {
        return state.backoffice;
    },
    registration(state) {
        let registration = Object.assign(new RegistrationBackoffice(), state);
        return registration;
    },
    registrationToSave(state) {
        let registration = Object.assign(new RegistrationBackoffice(), state);
        registration.registrationOffice =
            store.getters["settings/getRegistrationOffice"];
        return registration;
    }
};

const actions = {
    [ACTION_TYPES.AUTO_SAVE]: AUTO_SAVE,
    [ACTION_TYPES.FETCH_REGISTRATION_AND_DIALOGS_DATA]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get(`/getTaskDataAndDialogsData/${data.referenceId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        const registration = resData.backofficeRegistration;
                        //set default steps data on load
                        commit(MUTATION_TYPES.CLEAR_REGISTRATION);
                        commit(MUTATION_TYPES.SET_REGISTRATION, registration);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ACTION_TYPES.FETCH_REGISTRATION_DATA]: ({ commit }, data) => {
        const referenceId = data.referenceId;
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/ref/${referenceId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        const registration = resData;
                        commit(MUTATION_TYPES.SET_REGISTRATION, registration);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    [ACTION_TYPES.UPDATE_SCROLL_POSITION]: _.debounce(
        ({ commit }, position) => {
            commit(MUTATION_TYPES.UPDATE_REGISTRATION_FIELD, {
                field: "scrollPosition",
                value: position
            });
        },
        1000
    ),
    [ACTION_TYPES.UPDATE_REGISTRATION]: debounce((ctx, data) => {
        let field = data?.field;
        let value = data?.value;
        if (ctx.state.state >= TaskStatus.TASK_CONFIRMED) return;
        ctx.commit(MUTATION_TYPES.UPDATE_REGISTRATION_FIELD, { field, value });
        store.commit("settings/registrationTimestampStatus", "loading");
    }, 1000),
    [ACTION_TYPES.AUTO_SAVE_REGISTRATION]: (ctx, data) => {
        let field = data?.field;
        let value = data?.value;
        if (ctx.state.state >= TaskStatus.TASK_CONFIRMED) return;
        store.commit("settings/registrationTimestampStatus", "loading");
        if (field) {
            ctx.commit(MUTATION_TYPES.UPDATE_REGISTRATION_FIELD, {
                field,
                value
            });
        }
        ctx.dispatch(ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION);
    },
    [ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION]: debounce((ctx) => {
        if (ctx.state.state >= TaskStatus.TASK_CONFIRMED) return;
        store.commit("settings/registrationTimestampStatus", "loading");
        if (ctx.state.referenceId) {
            const registration = ctx.getters.registrationToSave;
            ctx.dispatch(ACTION_TYPES.AUTO_SAVE, registration);
        }
    }, 3000),

    [ACTION_TYPES.FETCH_TASK_DATA_BY_REFERENCE_ID]: (ctx, referenceId) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/ref/${referenceId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        const task = resData;
                        switch (task.process) {
                            case PROCESS_TYPES.REGISTRATION:
                                ctx.commit(
                                    MUTATION_TYPES.SET_REGISTRATION,
                                    resData
                                );
                                store.commit(
                                    `registration/${MUTATION_TYPES.SET_REGISTRATION}`,
                                    resData
                                );

                                break;
                            default:
                                break;
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
