<i18n src="./chart-data-i18n.yaml"></i18n>
<template>
    <div>
        <BarChart
            :title="computedTitle"
            :subtitle="subtitle"
            :data="data"
            :chart-color="chartColor"
            :options="options"
            :cardActions="cardActions"
            v-bind="$attrs"
            v-on="$listeners"
            @click:calendar="show"
            ref="barChart"
        >
            <template v-slot:title-extra>
                <count-to
                    :start-val="0"
                    :end-val="totalCounter"
                    :duration="3200"
                    class="pl-2 font-weight-bold"
                />
            </template>
        </BarChart>
        <v-menu
            v-model="showMenu"
            eager
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            :close-on-content-click="false"
        >
            <DateRangeFilterCard @change="onChangeFilterParams" />
        </v-menu>
    </div>
</template>
<script>
import MaterialColors from "vuetify/es5/util/colors";
export default {
    name: "ChartData",
    components: {
        BarChart: () => import("@/components/widgets/chart/bar-chart.vue"),
        DateRangeFilterCard: () => import("./date-range-filter-card.vue"),
        CountTo: () => import("vue-count-to")
    },
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: "mdi-chart-bar"
        },
        titleWord: {
            type: String,
            default: null
        },
        chartColor: {
            type: Array,
            default: () => [
                MaterialColors.yellow.base,
                MaterialColors.orange.base
            ]
        },
        hideReloadBtn: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        },
        totalCounter: {
            type: [Number, String],
            default: 0
        }
    },
    data: () => ({
        countersData: null,
        logsTotalCount: 0,
        showMenu: false,
        x: 0,
        y: 0,
        params: {
            startDate: null,
            endDate: null,
            groupBy: null
        }
    }),
    computed: {
        computedTitle() {
            if (this.title) return this.title;
            let type = null;
            switch (this.params.groupBy) {
                case "day":
                    type = this.$t("label-day");
                    break;
                case "month":
                    type = this.$t("label-month");
                    break;
                case "year":
                    type = this.$t("label-year");
                    break;
                default:
                    break;
            }
            return this.$t("title-counter-chart", {
                word: this.titleWord,
                type: type
            });
        },
        cardActions() {
            return [
                {
                    id: "calendar",
                    emit: "click:calendar",
                    icon: "mdi-calendar-range",
                    title: this.$t("action-calender")
                }
            ];
        }
    },
    methods: {
        show(e) {
            e.preventDefault();
            this.showMenu = false;
            this.x = e.clientX;
            this.y = e.clientY;
            this.$nextTick(() => {
                this.showMenu = true;
            });
        },

        onChangeFilterParams(params) {
            this.params = Object.assign(this.params, params);
            this.$emit("fetch", params);
            this.showMenu = false;
        }
    }
};
</script>
