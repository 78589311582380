export class BaseAddress {
    constructor() {
        this.additionalAddressLines = null;
        this.addressLine1 = null;
        this.addressLine2 = null;
        this.street = null;
        this.houseNr = null;
        this.canton = null;
        this.zipCode = null;
        this.city = null;
        this.country = null;
    }
}
