<template>
    <v-combobox
        v-on="$listeners"
        v-bind="computedProps"
        v-disabled-icon-focus
        :required="required"
        :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
        chips
    >
        <!-- Dynamically inherit slots from parent -->
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
        </template>
        <!-- make all slots from v-combobox reusable from parent -->
        <template v-for="(_, slot) in $slots">
            <template :slot="slot">
                <slot :name="slot"></slot>
            </template>
        </template>
    </v-combobox>
</template>
<script>
import mixinWField from "@/mixins/w-field";
export default {
    name: "BaseCombobox",
    mixins: [mixinWField],
    model: {
        // These are the default values
        prop: "value",
        event: "input"
    }
};
</script>
