<template>
    <div v-if="keys.length > 0">
        <v-row v-for="(row, rowIdx) in keys" :key="rowIdx" no-gutters>
            <v-col
                v-for="(field, fieldIdx) in row"
                :key="fieldIdx"
                style="padding: 2px"
                :cols="3 * field.parts"
            >
                <v-btn
                    v-if="shouldShow(field)"
                    text
                    class="ma-1 primary lighten-1"
                    color="white"
                    @click="press(field)"
                    width="100%"
                    height="60"
                >
                    <template v-if="field.iconCls">
                        <v-icon v-html="field.iconCls"></v-icon>
                    </template>
                    <template v-else>
                        <span class="font-weight-bold">{{
                            field.display
                        }}</span>
                    </template>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { NumberBlockFields } from "./templates/NumberBlock";
import { NumberBlockWithoutDecimalFields } from "./templates/NumberBlockWithoutDecimal";
import _ from "lodash";
export default {
    name: "VirtualKeyboardKeyboard",
    props: {
        value: {
            type: String,
            default: ""
        },
        allowNegative: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        _value: "",
        keys: []
    }),
    methods: {
        show(keyboardType) {
            if (keyboardType)
                switch (keyboardType) {
                    case "integer":
                        this.keys = NumberBlockWithoutDecimalFields;
                        break;
                    case "decimal":
                        this.keys = NumberBlockFields;
                        break;
                    default:
                        this.keys = NumberBlockWithoutDecimalFields;
                        break;
                }
        },
        press(key) {
            if (key.fn) {
                const val = key.fn(this._value);
                this._value = val;
            } else {
                this._value = `${this._value}${key.value}`;
            }
            this.$emit("pressed", this._value);
        },
        onKeydown(e) {
            if (e.keyCode === 8 || e.keyCode === 46) {
                // 8 = backspace
                // 46 = delete
                this._value = this.deleteOneChar(this._value);
            } else {
                const char = e.key;
                _.forEach(this.keys, (row) => {
                    _.forEach(row, (key) => {
                        if (char === "-" && !this.allowNegative) return;
                        if (char === key.value) {
                            this._value = `${this._value}${e.key}`;
                        }
                    });
                });
            }
            this.$emit("pressed", this._value);
        },
        deleteOneChar(value) {
            return value.substring(0, value.length - 1);
        },
        shouldShow(field) {
            if (field.value === "-" && !this.allowNegative) return false;
            return true;
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                this._value = newVal;
            },
            immediate: true
        }
    }
};
</script>
