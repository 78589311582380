var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" @billingAddress: "),_c('tree-view',{attrs:{"data":Object.assign(_vm.addressData),"options":{ maxDepth: 1 }}}),_vm._v(" @Address: "),_c('tree-view',{attrs:{"data":Object.assign(_vm.address),"options":{ maxDepth: 0 }}}),_vm._v(" @Validation: "),_c('tree-view',{attrs:{"data":_vm.$v,"options":{ maxDepth: 0 }}}),_vm._v(" @operatorAddressIsRecorded: "+_vm._s(_vm.operatorAddressIsRecorded)+" ")]},proxy:true}])}),_c('StepContentTitle',{attrs:{"title":_vm.$t('title')}}),(Object.keys(_vm.addressData).length > 0 || _vm.address)?_c('v-container',{class:_vm.$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pa-0 ma-0',attrs:{"fluid":""}},[_c('v-form',{attrs:{"readonly":_vm.readonlyModus,"disabled":_vm.disabled}},[_c('v-row',[_c('v-col',[_c('base-radio-group',{class:_vm.$vuetify.breakpoint.xsOnly ? undefined : 'pl-2',attrs:{"label":_vm.typeOfAddress
                                ? _vm.$t(
                                      ("label-same-address-as-operator-" + _vm.typeOfAddress)
                                  )
                                : undefined,"required":_vm.isRequiredField(
                                _vm.$v.address.sameAddressAsOperator
                            ),"error-messages":_vm.sameAddressAsOperatorErrors},on:{"change":_vm.onSameAddressAsOperatorChange},model:{value:(_vm.sameAddressAsOperator),callback:function ($$v) {_vm.sameAddressAsOperator=$$v},expression:"sameAddressAsOperator"}},[(
                                _vm.operatorAddress ||
                                _vm.address.sameAddressAsOperator
                            )?_c('v-radio',{attrs:{"label":_vm.$t('same-address-as-operator-true'),"value":true,"disabled":!_vm.operatorAddressIsRecorded}}):_vm._e(),(
                                (_vm.operatorAddress &&
                                    _vm.sameAddressAsOperator === true) ||
                                _vm.address.sameAddressAsOperator
                            )?_c('v-container',{staticClass:"pa-0 ma-0 pb-2 ml-12",staticStyle:{"border-left":"1px solid rgba(0, 0, 0, 0.12)"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"py-0",attrs:{"tile":"","flat":"","max-width":"400","color":"transparent"}},[_c('v-card-text',{staticClass:"pt-0 my-0"},[_c('display-address',{attrs:{"address":_vm.operatorAddress}})],1)],1)],1):_vm._e(),_c('v-radio',{attrs:{"label":_vm.$t('same-address-as-operator-false'),"value":false}})],1)],1)],1)],1)],1):_vm._e(),(_vm.sameAddressAsOperator === false)?_c('v-container',{class:_vm.$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pa-0 ma-0',attrs:{"fluid":""}},[(_vm.sameAddressAsOperator === false)?_c('FormCreateAddress',{ref:"createAddress",attrs:{"typeOfAddress":_vm.typeOfAddress,"formData":_vm.address,"readonlyModus":_vm.readonlyModus,"disabled":_vm.disabled},on:{"field-change":_vm.onFieldChange,"address-change":_vm.onAddressFieldsChange,"clear-address":_vm.onClearAddress}}):_vm._e()],1):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }