<i18n src="./menu-duplicates-check-i18n.yaml"></i18n>
<template>
    <div>
        <ActionsMenu :actions="actions" @click:action="onMenuClickAction" />
        <DialogReportNewAddress
            v-model="dialogReportNewAddress"
            :address="selectedAddress"
            :referenceId="task.referenceId"
            @reset="onReset"
        >
            <template v-slot:content-prepend>
                <base-select
                    :items="addressItems"
                    v-model="addressId"
                    :label="$t('address-id-label')"
                    item-value="key"
                    :error-messages="addressIdErrors"
                    :required="isRequiredField($v.addressId)"
                    item-text="text"
                    clearable
                >
                </base-select>
            </template>
        </DialogReportNewAddress>
    </div>
</template>
<script>
import _ from "lodash";
import Helpers from "@/utils/helpers";
import { ADDRESS_TYPES } from "@/data/globals.js";
import { required } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";

const ACTION_TYPES = {
    REPORT_NEW_ADDRESS: "REPORT_NEW_ADDRESS"
};
const ITEM_TYPES = {
    ADDRESS_OPERATOR: "ADDRESS_OPERATOR",
    ADDRESS_OWNER: "ADDRESS_OWNER",
    ADDRESS_BILL: "ADDRESS_BILL",
    ADDRESS_MANUFACTURER: "ADDRESS_MANUFACTURER"
};
export default {
    name: "MenuDuplicatesCheck",
    mixins: [validateMixin],
    components: {
        ActionsMenu: () => import("@/components/general/actions-menu.vue"),
        DialogReportNewAddress: () =>
            import(
                "@/components/tasks/edit/backoffice/components/dialog-report-new-address.vue"
            )
    },
    props: {
        task: {
            type: Object,
            default: () => {}
        }
    },

    validations() {
        return {
            addressId: {
                required
            }
        };
    },

    data: () => ({
        dialogReportNewAddress: false,
        selectedAddress: {},
        addressId: {},
        operatorAddress: {},
        ownerAddress: {},
        billingAddress: {},
        manufacturerAddress: {},
    }),

    computed: {
        actions() {
            return [
                {
                    title: this.$t("action-report-new-address"),
                    icon: "mdi-email-send",
                    action: ACTION_TYPES.REPORT_NEW_ADDRESS
                }
            ];
        },
        addressItems() {
            let items = [];
            if (
                Helpers.isAddressRecorded(this.operatorAddress) &&
                !this.isAddressSameAs(this.operatorAddress)
            ) {
                items.push({
                    text: this.$t("text-item-address-operator"),
                    key: ITEM_TYPES.ADDRESS_OPERATOR
                });
            }
            if (
                Helpers.isAddressRecorded(this.ownerAddress) &&
                !this.isAddressSameAs(this.ownerAddress)
            ) {
                items.push({
                    text: this.$t("text-item-address-owner"),
                    key: ITEM_TYPES.ADDRESS_OWNER
                });
            }
            if (
                Helpers.isAddressRecorded(this.billingAddress) &&
                !this.isAddressSameAs(this.billingAddress)
            ) {
                items.push({
                    text: this.$t("text-item-address-bill"),
                    key: ITEM_TYPES.ADDRESS_BILL
                });
            }
            if (
                Helpers.isAddressRecorded(this.manufacturerAddress) &&
                !this.isAddressSameAs(this.manufacturerAddress)
            ) {
                items.push({
                    text: this.$t("text-item-address-manufacturer"),
                    key: ITEM_TYPES.ADDRESS_MANUFACTURER
                });
            }
            return items;
        },
        addressIdErrors() {
            const errors = [];
            if (!this.$v.addressId.$dirty) {
                return errors;
            }
            if (!this.$v.addressId.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    methods: {
        onMenuClickAction(item) {
            let action = item.action;
            switch (action) {
                case ACTION_TYPES.REPORT_NEW_ADDRESS:
                    this.onReportNewAddress();

                    break;
                default:
                    this.$toast.error(this.$t("action-not-found-msg"));
            }
        },
        onReportNewAddress() {
            this.dialogReportNewAddress = true;
        },
        isAddressSameAs(address) {
            if (address.sameAddressAsOperator) {
                return true;
            }
            return false;
        },
        getAddressDataByAddressId(addressId) {
            switch (addressId) {
                case ITEM_TYPES.ADDRESS_OPERATOR:
                    return this.operatorAddress;
                case ITEM_TYPES.ADDRESS_OWNER:
                    return this.ownerAddress;
                case ITEM_TYPES.ADDRESS_BILL:
                    return this.billingAddress;
                case ITEM_TYPES.ADDRESS_MANUFACTURER:
                    return this.manufacturerAddress;
                default:
                    return null;
            }
        },
        onReset() {
            this.addressId = null;
            this.selectedAddress = {};
            this.reset();
        },
        getManufacturerAddressById(manufacturerId) {
            this.loader().show();
            this.$axiosManufacturers
                .get(`/${manufacturerId}`)
                .then((response) => {
                    const resData = response?.data?.data;
                    let address = resData?.address;
                    address.companyName = resData.name;
                    address.department = resData.department;
                    address.addressType = ADDRESS_TYPES.COMPANY;
                    this.manufacturerAddress = address;
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-get-manufacturer-error"));
                    this.loader().hide();
                });
        }
    },
    watch: {
        task: {
            handler: function (newData) {
                if (newData) {
                    this.operatorAddress = Object.assign(
                        {},
                        _.clone(newData.operatorAddress)
                    );
                    this.locationAddress = _.clone(
                        newData.equipmentLocation?.address
                    );
                    this.billingAddress = _.clone(newData.billingAddress);
                    this.ownerAddress = _.clone(newData.ownerAddress);
                    if (newData.manufacturer?.id) {
                        this.getManufacturerAddressById(
                            newData.manufacturer.id
                        );
                    }
                }
            },
            immediate: true,
            deep: true
        },
        addressId: {
            handler: function (newVal) {
                if (newVal) {
                    this.selectedAddress =
                        this.getAddressDataByAddressId(newVal);
                } else {
                    this.onReset();
                }
            },
            immediate: true
        }
    }
};
</script>
