<i18n src="./app-error-container-i18n.yaml"></i18n>
<template>
    <app-center-container-card>
        <template v-slot:content>
            <v-row dense>
                <v-col cols="12" align="center" justify="center">
                    <slot name="image">
                        <v-img
                            v-if="imgSrc"
                            :src="imgSrc"
                            max-height="250"
                            max-width="250"
                            contain
                        ></v-img>
                        <w-icon
                            v-if="wIconSrc"
                            :data="wIconSrc"
                            width="300"
                            height="300"
                        ></w-icon>
                    </slot>
                </v-col>
                <v-col cols="12" align="center" justify="center">
                    <div class="text-center">
                        <div
                            v-if="errorTitle"
                            :class="
                                $vuetify.breakpoint.mobile
                                    ? 'text-h2'
                                    : 'text-h1'
                            "
                            class="title-error-code"
                            v-html="errorTitle"
                        ></div>
                        <div v-if="errorMsg" class="my-3 text-h5">
                            {{ errorMsg }}
                        </div>
                        <slot name="additional-content"></slot>
                        <div>
                            <base-btn color="info" @click="goBack">
                                <v-icon left>mdi-arrow-left</v-icon>
                                <span
                                    v-if="btnGoBackText === 'btn-go-back-text'"
                                >
                                    {{ $t("btn-go-back-text") }}
                                </span>
                                <span v-else>
                                    {{ btnGoBackText }}
                                </span>
                            </base-btn>
                            <base-btn
                                class="ml-2"
                                :to="isAuth ? { name: 'user-dashboard' } : '/'"
                            >
                                <v-icon left>mdi-home</v-icon>
                                <span
                                    v-if="btnGoHomeText === 'btn-go-home-text'"
                                >
                                    {{ $t("btn-go-home-text") }}
                                </span>
                                <span v-else>
                                    {{ btnGoHomeText }}
                                </span>
                            </base-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </template>
    </app-center-container-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "AppErrorContainer",
    props: {
        errorTitle: {
            type: String,
            default: null
        },
        errorMsg: {
            type: String,
            default: null
        },
        btnGoHomeText: {
            type: String,
            default: "btn-go-home-text"
        },
        btnGoBackText: {
            type: String,
            default: "btn-go-back-text"
        },
        imgSrc: {
            type: Object,
            default: () => {}
        },
        wIconSrc: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        })
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        }
    }
};
</script>
<style scoped>
.title-error-code {
    font-size: 150px;
    font-weight: 600;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px;
}
</style>
