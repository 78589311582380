//import modules
import Vue       from 'vue';
import Router    from 'vue-router';
import { publicRoute, protectedRoute } from './config';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store';
import _ from 'lodash';

const routes = publicRoute.concat(protectedRoute);

Vue.use(Router);

const router = new Router({
    mode: "history",
    linkActiveClass: "active",
    routes: routes,
    base: process.env.BASE_URL
});


// router gards
/* eslint-disable-next-line no-unused-vars */
router.beforeEach((to, from, next) => {
    NProgress.start();
    let token =  window.$cookies.get('authtoken')
    let mail =  window.$cookies.get('mail')
    const requireAuth = to.matched.some(record => record.meta.requiresAuth);
    const isDevel = to.matched.some(record => record.meta.isDevel);
    const isBackoffice = to.matched.some(record => record.meta.isBackoffice);
    const isAdmin = to.matched.some(record => record.meta.isAdmin);
    const isInsurer = to.matched.some(record => record.meta.isInsurer);
    const hideForAuth = to.matched.some(record => record.meta.hideForAuth);
    let isAuth = store.getters['auth/isAuthenticated'];
    let hasSettings;

    if (requireAuth && !token) {
        if (isAuth) {
            store.commit('auth/clearAuthData');
            next('/token-invalid');
        } else {
            const path = window.location.pathname;
            let query = {};
            query.redirect = path;
            if (to.query) {
                query = Object.assign(query, to.query)
            }
            next({ name: 'login', query });
        }
    }
    else if (!requireAuth && !token){
        if (isAuth) {
            store.commit('auth/clearAuthData');
            next('/token-invalid');
        } else {
            next();
        }
    }
    else {
        store.dispatch('auth/autoLogin')
        .then(response => {
            let status = response.status;
            let isUserBackoffice = store.getters['auth/isUserBackoffice'];
            let isUserDevolper = store.getters['auth/isUserDevolper'];
            let developerMode = store.getters['settings/developerMode'];
            let isUserAdmin = store.getters['auth/isUserAdmin'];
            let isUserInsurer = store.getters['auth/isUserInsurer'];
            hasSettings = store.getters['auth/hasSettings'];
            if (status === 200) {
                if (!hasSettings) {
                    if (to.name === "account-settings-user") {
                        next();
                    } else {
                        next({name: 'account-settings-user'});
                    }
                }
                if (hideForAuth) {
                    next({ name: 'user-dashboard' });
                }
                if (isBackoffice && !isUserBackoffice) {
                    next('/403');
                } else if (isDevel && (!developerMode || !isUserDevolper)) {
                    next('/403');
                } else if (isAdmin && !isUserAdmin) {
                    next('/403');
                } else if (isInsurer && !isUserInsurer) {
                    next('/403');
                } else if (to.path === '/') {
                    next({ name: "user-dashboard" });
                } else {
                    next();
                }
            } else {
                next('/404');
            }
        }).catch(error => {
            console.log(error);
            const res = error.response;
            hasSettings = store.getters['auth/hasSettings'];
            if (!token || !mail) {
                window.$cookies.remove('authtoken')
                window.$cookies.remove('mail')
                if (isAuth) {
                    store.commit('auth/clearAuthData');
                    next('/token-invalid');
                } else {
                    next('/token-invalid');
                }

            } else {
                if (requireAuth) {
                    if (!hasSettings && _.isObject(res)
                        && res.data.code && res.data.code === 2){
                        if (to.name === 'account-settings-user') {
                            next();
                        } else{
                            next({name: 'account-settings-user'});
                        }
                    } else {
                        next('/403');
                    }
                } else {
                    next();
                }
            }
        });
    }
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
