import { SuvaPartnerFinder,BackofficeFields } from "./tasks";
import { ReviewVerification, Registration } from "./";
export class RegistrationBackoffice extends Registration {
    constructor() {
        super();
        this.reviewVerification = new ReviewVerification();
        this.backoffice = new BackofficeFields();
        this.isBackoffice = true;
        this.suvaPartnerFinder = new SuvaPartnerFinder();
    }
}
