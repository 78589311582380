import { Registration } from "@/models";
import {
    ContactPerson,
    CustomerSpecificDesignation,
    LocationAddress
} from "@/models/tasks";
import { axiosTasks } from "@/api/axios-instances";
import { TaskStatus } from "@/data/globals";
import _ from "lodash";
import store from "@/store";
import { AUTO_SAVE } from "../helpers/actions";
import { PROCESS_TYPES } from "@/data/globals";
import * as ACTION_TYPES from "../types/action-types";
import * as MUTATION_TYPES from "../types/mutation-types";

const initialState = () => ({
    ...new Registration(),
    attachments: {},
    lastChangeTimestamp: null,
    state: null,
    lang: null,
    registrationOffice: null,
    scrollPosition: null,
    readonlyModus: false,
    registrant: {
        email: null,
        familyName: null,
        firstName: null
    }
});

const state = initialState();

const mutations = {
    [MUTATION_TYPES.SET_REGISTRATION](state, payload) {
        const registration = Object.assign(new Registration(), payload);
        Object.assign(state, registration);
    },
    [MUTATION_TYPES.CLEAR_REGISTRATION](state) {
        Object.assign(state, initialState());
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_FIELD](state, { field, value }) {
        state[field] = value;
    },
    [MUTATION_TYPES.MERGE_REGISTRATION_FIELDS](state, { field_name, values }) {
        if (!field_name) return;
        Object.assign(state[field_name], values);
    },
    [MUTATION_TYPES.CLEAR_REGISTRATION_ADDRESS](state, { field, address }) {
        if (!field) return;
        if (field === "operatorAddress") {
            let billingAddress = state.billingAddress;
            let ownerAddress = state.ownerAddress;
            if (address) {
                if (billingAddress && billingAddress.sameAddressAsOperator) {
                    state.billingAddress = Object.assign({}, address);
                }
                if (ownerAddress && ownerAddress.sameAddressAsOperator) {
                    state.ownerAddress = Object.assign({}, address);
                }
                state.operatorAddress = Object.assign({}, address);
            } else {
                state.operatorAddress = {};
            }
        } else {
            if (field) {
                if (!address) {
                    state[field] = {};
                } else {
                    state[field] = Object.assign({}, address);
                }
            }
        }
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS_FIELD](
        state,
        { address, field, value }
    ) {
        if (!address || !field) return;
        let newAddress = _.cloneDeep(state[address]);
        newAddress = _.set(newAddress, field, value);
        state[address] = Object.assign({}, newAddress);
    },
    [MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS](state, { field, value }) {
        if (!field) return;
        state[field] = _.set(state, field, value);
    },
    [MUTATION_TYPES.UPDATE_CHAMBERS](state, chamber) {
        if (chamber.id) {
            if (state.chambers.length > 0) {
                state.chambers[chamber.id - 1] = chamber;
            } else {
                state.chambers.push(chamber);
            }
        }
    },
    [MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON](state, data) {
        let contactPerson = Object.assign(
            state.equipmentLocation.contactPerson,
            data
        );
        _.set(state.equipmentLocation, "contactPerson", contactPerson);
    },
    [MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_ADDRESS](state, data) {
        let address = Object.assign(state.equipmentLocation.address, data);
        _.set(state.equipmentLocation, "address", address);
    },
    [MUTATION_TYPES.REMOVE_CHAMBER_BY_INDEX](state, index) {
        state.chambers.splice(index, 1);
    },
    [MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_ADDRESS](state) {
        _.set(state.equipmentLocation, "address", new LocationAddress());
    },
    [MUTATION_TYPES.RESET_CUSTOMER_SPECIFIC_DESIGNATION](state) {
        state.customerSpecificDesignation = new CustomerSpecificDesignation();
    },
    [MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_CONTACT_PERSON](state) {
        _.set(state.equipmentLocation, "contactPerson", new ContactPerson());
    },
    [MUTATION_TYPES.ENABLE_READONLY_MODUS](state) {
        state.readonlyModus = true;
    },
    [MUTATION_TYPES.DISABLE_READONLY_MODUS](state) {
        state.readonlyModus = false;
    }
};

const getters = {
    readonlyModus(state) {
        return state.readonlyModus;
    },
    docId(state) {
        return state.docId;
    },
    taskState(state) {
        return state.state;
    },
    referenceId(state) {
        return state.referenceId;
    },
    locationAddress(state) {
        return state.equipmentLocation?.address;
    },
    locationContactPerson(state) {
        return state.equipmentLocation?.contactPerson;
    },
    customerSpecificDesignation(state) {
        return state.customerSpecificDesignation;
    },
    billingAdditionalInfos(state) {
        return state.billingAdditionalInfos;
    },
    registration(state) {
        let registration = Object.assign(new Registration(), state);
        return registration;
    },
    getRegistrationToSave(state) {
        let registration = Object.assign(new Registration(), state);
        registration.registrationOffice =
            store.getters["settings/getRegistrationOffice"];
        return registration;
    },
    isAutoSaving(state) {
        return (
            state.referenceId &&
            store.getters["settings/registrationTimestampStatus"] === "loading"
        );
    }
};

const actions = {
    [ACTION_TYPES.AUTO_SAVE]: (ctx, data) => {
        if (data.state >= TaskStatus.TASK_CONFIRMED) return;
        return AUTO_SAVE(ctx, data);
    },
    [ACTION_TYPES.UPDATE_SCROLL_POSITION]: _.debounce(
        ({ commit }, position) => {
            commit(MUTATION_TYPES.UPDATE_REGISTRATION_FIELD, {
                field: "scrollPosition",
                value: position
            });
        },
        1000
    ),
    [ACTION_TYPES.UPDATE_REGISTRATION]: _.debounce(
        ({ commit, state }, { field, value }) => {
            if (state.state >= TaskStatus.TASK_REGISTRANT_COMPLETED) return;
            commit(MUTATION_TYPES.UPDATE_REGISTRATION_FIELD, { field, value });
            store.commit("settings/registrationTimestampStatus", "loading");
        },
        1000
    ),
    [ACTION_TYPES.AUTO_SAVE_REGISTRATION]: (ctx, data) => {
        if (ctx.state.state >= TaskStatus.TASK_REGISTRANT_COMPLETED) return;
        store.commit("settings/registrationTimestampStatus", "loading");
        if (data && data.field) {
            ctx.commit(MUTATION_TYPES.UPDATE_REGISTRATION_FIELD, {
                field: data.field,
                value: data.value
            });
        }
        ctx.dispatch(ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION);
    },
    [ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION]: _.debounce((ctx) => {
        if (ctx.state.state >= TaskStatus.TASK_REGISTRANT_COMPLETED) return;
        store.commit("settings/registrationTimestampStatus", "loading");
        if (ctx.state.referenceId) {
            const registration = ctx.getters.getRegistrationToSave;
            ctx.dispatch(ACTION_TYPES.AUTO_SAVE, registration);
        }
    }, 3000),
    [ACTION_TYPES.FETCH_OPEN_REGISTRATION_DATA_BY_REFERENCE_ID]: (
        { commit },
        referenceId
    ) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/ref/${referenceId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let registration = response.data.data;
                        // commit([MUTATION_TYPES.CLEAR_REGISTRATION]);
                        commit(MUTATION_TYPES.CLEAR_REGISTRATION);
                        // set default steps data on load
                        commit(MUTATION_TYPES.SET_REGISTRATION, registration);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ACTION_TYPES.COMPLETE]: (ctx) => {
        const data = ctx.getters.getRegistrationToSave;
        data.registrationOffice =
            store.getters["settings/getRegistrationOffice"];
        return new Promise((resolve, reject) => {
            axiosTasks
                .put(`/complete/${PROCESS_TYPES.REGISTRATION}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
