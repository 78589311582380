<template>
    <li>
        <ul class="cls-canvas-language">
            <li class="cls-canvas-language-item">
                <a
                    class="
                        a-link
                        cls-canvas-language-link
                        font-weight-black
                        text-subtitle-1
                    "
                    v-for="(language, l) in languages"
                    :key="l"
                    :class="getCls(language, l)"
                    @click="$emit('change', language)"
                    >{{ language }}</a
                >
            </li>
        </ul>
    </li>
</template>
<script>
export default {
    name: "LanguagesList",
    props: {
        languages: {
            type: Array,
            default: () => [],
            required: true
        },
        selectedLang: {
            type: String,
            default: null,
            required: true
        }
    },
    methods: {
        getCls(lang, idx) {
            let cls = "";
            if (this.selectedLang === lang) {
                cls = "regOfficePrimary--text";
            }
            if (idx !== this.languages.length - 1) {
                cls += " cls-canvas-language-link-divider";
            }
            return cls;
        }
    }
};
</script>
<style src="./languages-list.styl" scoped lang="stylus"></style>
