<i18n       src="./form-company-address-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <v-container fluid class="pa-0 ma-0">
            <app-dev-container class="my-6">
                <template v-slot:content>
                    <base-btn icon color="warning" @click="setFakeData">
                        <v-icon>mdi-bug</v-icon>
                    </base-btn>
                    <base-btn icon color="yellow" @click="reset">
                        <v-icon>mdi-refresh</v-icon>
                    </base-btn>
                    <base-btn icon color="info" @click="validate">
                        <v-icon>mdi-alert-circle-check-outline</v-icon>
                    </base-btn>
                    IsValid: {{ isValid() }} required: {{ required }}
                </template>
            </app-dev-container>
            <v-row>
                <v-col class="pb-0 mb-0" cols="12" md="6">
                    <base-text-field
                        v-model="companyName"
                        :error-messages="companyNameErrors"
                        :counter="
                            required
                                ? $v.address.companyName.$params.maxLength.max
                                : false
                        "
                        :label="$t('company-name-label')"
                        :disabled="disableAllFields"
                        :required="isRequiredField($v.address.companyName)"
                    ></base-text-field>
                </v-col>
                <v-col class="pb-0 mb-0" cols="12" md="6">
                    <base-text-field
                        v-model="department"
                        :disabled="disableAllFields"
                        :error-messages="departmentErrors"
                        :counter="
                            required
                                ? $v.address.department.$params.maxLength.max
                                : false
                        "
                        :label="$t('department-label')"
                        :required="isRequiredField($v.address.department)"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-switch
                        class="pl-3"
                        v-model="additionalAddressLines"
                        :label="$t('additional-address-lines')"
                        :disabled="disableAllFields"
                        value
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row v-if="address.additionalAddressLines">
                <v-col class="py-1" cols="12" md="6">
                    <!-- addressLine1 -->
                    <base-text-field
                        v-model="addressLine1"
                        :label="$t('address-line-1-label')"
                        :disabled="disableAllFields"
                        :error-messages="addressLine1Errors"
                        :counter="
                            required
                                ? $v.address.addressLine1.$params.maxLength.max
                                : false
                        "
                        :required="isRequiredField($v.address.addressLine1)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-1" cols="12" md="6">
                    <!-- addressLine2 -->
                    <base-text-field
                        v-model="addressLine2"
                        :label="$t('address-line-2-label')"
                        :disabled="disableAllFields"
                        :counter="
                            required
                                ? $v.address.addressLine2.$params.maxLength.max
                                : false
                        "
                        :error-messages="addressLine2Errors"
                        :required="isRequiredField($v.address.addressLine2)"
                    ></base-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="py-1" cols="12" md="10">
                    <!-- street -->
                    <base-text-field
                        v-model="street"
                        :error-messages="streetErrors"
                        :counter="
                            required
                                ? $v.address.street.$params.maxLength.max
                                : false
                        "
                        :label="$t('street-label')"
                        :required="isRequiredField($v.address.street)"
                        :disabled="disableAllFields"
                    ></base-text-field>
                </v-col>
                <v-col class="py-1" cols="12" md="2">
                    <!-- houseNr -->
                    <base-text-field
                        v-model="houseNr"
                        :label="$t('house-nr-label')"
                        :error-messages="houseNrErrors"
                        :disabled="disableAllFields"
                        :counter="
                            required
                                ? $v.address.houseNr.$params.maxLength.max
                                : false
                        "
                        :required="isRequiredField($v.address.houseNr)"
                    ></base-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid class="pa-0 ma-0">
            <w-location-search
                v-if="isLocationSearchAvailable(country)"
                v-model="locationSearch"
                @reset="onWLocationSearchReset"
                ref="wLocationSearch"
                :country="country"
                :disabled="disableAllFields"
                :readonly="readonlyModus"
                :error-messages="locationSearchErrors"
                :required="
                    isRequiredField($v.address.zipCode) ||
                    isRequiredField($v.address.city) ||
                    isRequiredField($v.address.canton)
                "
            />
            <v-row v-else>
                <v-col class="py-0" cols="12" md="3">
                    <base-text-field
                        v-model="zipCode"
                        :disabled="disableAllFields"
                        :error-messages="zipCodeErrors"
                        :counter="
                            required
                                ? $v.address.zipCode.$params.maxLength.max
                                : false
                        "
                        :label="$t('zip-code-label')"
                        :required="isRequiredField($v.address.zipCode)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="9">
                    <base-text-field
                        v-model="city"
                        :disabled="disableAllFields"
                        :error-messages="cityErrors"
                        :counter="
                            required
                                ? $v.address.city.$params.maxLength.max
                                : false
                        "
                        :label="$t('city-label')"
                        :required="isRequiredField($v.address.city)"
                    ></base-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="pa-0 ma-0">
            <!-- Postfachnummer, PLZ, Ort -->
            <v-row dense v-if="hasPoBox" class="pl-2">
                <!-- poBox -->
                <v-checkbox
                    v-model="poBox"
                    :label="$t('po-box-label')"
                    color="primary"
                ></v-checkbox>
            </v-row>
            <v-row v-if="address.poBox">
                <v-col class="py-0" cols="12" md="3" sm="6">
                    <base-text-field
                        v-model="poBoxNr"
                        :error-messages="poBoxNrErrors"
                        type="number"
                        :label="$t('po-box-nr-label')"
                        :required="isRequiredField($v.address.poBoxNr)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="3" sm="6">
                    <base-text-field
                        v-model="poBoxZipCode"
                        :error-messages="poBoxZipCodeErrors"
                        :counter="
                            required
                                ? $v.address.poBoxZipCode.$params.maxLength.max
                                : false
                        "
                        type="number"
                        :label="$t('po-box-zip-code-label')"
                        :required="isRequiredField($v.address.poBoxZipCode)"
                    ></base-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="6" sm="12">
                    <base-text-field
                        v-model="poBoxCity"
                        :error-messages="poBoxCityErrors"
                        :counter="
                            required
                                ? $v.address.poBoxCity.$params.maxLength.max
                                : false
                        "
                        :label="$t('po-box-city-label')"
                        :required="isRequiredField($v.address.poBoxCity)"
                    ></base-text-field>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { CompanyAddress } from "@/models/tasks";
import _ from "lodash";
import validateMixin from "@/mixins/validate";
import Helpers from "@/utils/helpers";

const DEFAULT_DEBOUNCE_TIME = 200;
export default {
    name: "FormCompanyAddress",
    mixins: [validateMixin],
    validations() {
        return {
            address: {
                companyName: {
                    required,
                    maxLength: maxLength(40)
                },
                department: {
                    maxLength: maxLength(40)
                },
                addressLine1: {
                    required: requiredIf(function () {
                        if (this.address.additionalAddressLines) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    maxLength: maxLength(40)
                },
                addressLine2: {
                    required: requiredIf(function () {
                        return (
                            this.address.additionalAddressLines &&
                            !this.address.addressLine1
                        );
                    }),
                    maxLength: maxLength(40)
                },
                street: { required, maxLength: maxLength(60) },
                houseNr: { maxLength: maxLength(10) },
                canton: {
                    required: requiredIf(function () {
                        if (this.address.country === "CH") {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                zipCode: {
                    required,
                    maxLength:
                        this.address.country === "CH"
                            ? maxLength(4)
                            : maxLength(10)
                },
                city: {
                    required,
                    maxLength: maxLength(40)
                },
                poBoxNr: {
                    required: requiredIf(function () {
                        if (this.address.poBox) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                poBoxZipCode: {
                    required: requiredIf(function () {
                        if (this.address.poBox) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    maxLength:
                        this.country === "CH" ? maxLength(4) : maxLength(7)
                },
                poBoxCity: {
                    required: requiredIf(function () {
                        if (this.address.poBox) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    maxLength: maxLength(50)
                }
            }
        };
    },
    props: {
        country: {
            type: String,
            default: "CH"
        },
        hasPoBox: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        address: new CompanyAddress(),
        disableAllFields: false
    }),
    methods: {
        onFieldChange(field, value) {
            this.address[field] = value;
            this.$emit("field-change", { field, value });
        },
        validate() {
            if (this.required) this.$v.$touch();
        },
        isValid() {
            if (!this.required) {
                return true;
            }
            if (!this.$v.$anyDirty) {
                return null;
            } else {
                if (this.$v.$anyError) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        reset() {
            this.$v.$reset();
            this.address = new CompanyAddress();
            this.address.country = this.country;
            if (this.country === "CH") {
                this.$refs.wLocationSearch.resetSearch();
            }
            this.$emit("reset");
            this.onFieldChange("companyName", "");
            this.onFieldChange("department", "");
            this.onFieldChange("additionalAddressLines", false);
            this.onFieldChange("addressLine1", "");
            this.onFieldChange("addressLine2", "");
            this.onFieldChange("street", "");
            this.onFieldChange("houseNr", "");
            this.onFieldChange("zipCode", "");
            this.onFieldChange("city", "");
            this.onFieldChange("canton", "");
            this.onFieldChange("poBox", false);
            this.onFieldChange("poBoxNr", "");
            this.onFieldChange("poBoxZipCode", "");
            this.onFieldChange("poBoxCity", "");
            this.onFieldChange("uid", "");
            this.onFieldChange("uidFormatted", "");
            this.onFieldChange("uidCategory", "");
        },
        setAllDisabled(val) {
            this.disableAllFields = val;
        },
        setFakeData() {
            this.onFieldChange("companyName", "Was.ch GmbH");
            this.onFieldChange("department", "IT");
            this.onFieldChange("additionalAddressLines", true);
            this.onFieldChange("addressLine1", "Untere Stock");
            this.onFieldChange("addressLine2", "an der Ecke");
            this.onFieldChange("street", "Vonwilstrasse");
            this.onFieldChange("houseNr", "23");
            this.onFieldChange("zipCode", "9000");
            this.onFieldChange("city", "St. Gallen");
            this.onFieldChange("canton", "SG");
            this.onFieldChange("poBox", false);
            this.onFieldChange("poBoxNr", "");
            this.onFieldChange("poBoxZipCode", "");
            this.onFieldChange("poBoxCity", "");
            this.onFieldChange("uid", "");
            this.onFieldChange("uidFormatted", "");
            this.onFieldChange("uidCategory", "");
        },
        onWLocationSearchReset() {
            this.onFieldChange("zipCode", null);
            this.onFieldChange("city", null);
            this.onFieldChange("canton", null);
        },
        isLocationSearchAvailable(country) {
            return Helpers.isLocationSearchAvailable(country);
        }
    },
    computed: {
        locationSearch: {
            get() {
                let search = {};
                search.zipCode = this.zipCode || null;
                search.city = this.city || null;
                search.canton = this.canton || null;
                if (search.zipCode || search.city || search.canton) {
                    return search;
                }
                return null;
            },
            set(value) {
                if (!value) return;
                this.zipCode = value.zipCode;
                this.city = value.city;
                this.canton = value.canton;
            }
        },
        companyName: {
            get() {
                return this.address ? this.address.companyName : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("companyName", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        department: {
            get() {
                return this.address ? this.address.department : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("department", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        additionalAddressLines: {
            get() {
                return this.address
                    ? this.address.additionalAddressLines
                    : null;
            },
            set(value) {
                this.onFieldChange("additionalAddressLines", value);
            }
        },
        addressLine1: {
            get() {
                return this.address ? this.address.addressLine1 : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("addressLine1", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        addressLine2: {
            get() {
                return this.address ? this.address.addressLine2 : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("addressLine2", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        street: {
            get() {
                return this.address ? this.address.street : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("street", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        houseNr: {
            get() {
                return this.address ? this.address.houseNr : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("houseNr", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        canton: {
            get() {
                return this.address ? this.address.canton : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("canton", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        zipCode: {
            get() {
                return this.address ? this.address.zipCode : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("zipCode", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        city: {
            get() {
                return this.address ? this.address.city : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("city", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        poBox: {
            get() {
                return this.address ? this.address.poBox : null;
            },
            set(value) {
                this.onFieldChange("poBox", value);
            }
        },
        poBoxNr: {
            get() {
                return this.address ? this.address.poBoxNr : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("poBoxNr", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        poBoxZipCode: {
            get() {
                return this.address ? this.address.poBoxZipCode : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("poBoxZipCode", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        poBoxCity: {
            get() {
                return this.address ? this.address.poBoxCity : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("poBoxCity", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        companyNameErrors() {
            const errors = [];
            if (!this.$v.address.companyName.$dirty) {
                return errors;
            }
            if (!this.$v.address.companyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.companyName.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.companyName.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        departmentErrors() {
            const errors = [];
            if (!this.$v.address.department.$dirty) {
                return errors;
            }
            if (!this.$v.address.department.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.department.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        addressLine1Errors() {
            const errors = [];
            if (!this.$v.address.addressLine1.$dirty) {
                return errors;
            }
            if (!this.$v.address.addressLine1.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.addressLine1.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.addressLine1.$params.maxLength.max
                    })
                );
            }
            return errors;
        },

        addressLine2Errors() {
            const errors = [];
            if (!this.$v.address.addressLine2.$dirty) {
                return errors;
            }
            if (!this.$v.address.addressLine2.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.addressLine2.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.addressLine2.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        streetErrors() {
            const errors = [];
            if (!this.$v.address.street.$dirty) {
                return errors;
            }
            if (!this.$v.address.street.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.street.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.street.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        houseNrErrors() {
            const errors = [];
            if (!this.$v.address.houseNr.$dirty) {
                return errors;
            }
            if (!this.$v.address.houseNr.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.houseNr.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        locationSearchErrors() {
            const errors = [];
            if (
                !this.$v.address.canton.$dirty ||
                !this.$v.address.zipCode.$dirty ||
                !this.$v.address.city.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.address.canton.required ||
                !this.$v.address.zipCode.required ||
                !this.$v.address.city.required
            ) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cantonErrors() {
            const errors = [];
            if (!this.$v.address.canton.$dirty) {
                return errors;
            }
            if (!this.$v.address.canton.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        zipCodeErrors() {
            const errors = [];
            if (!this.$v.address.zipCode.$dirty) {
                return errors;
            }
            if (!this.$v.address.zipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.zipCode.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.zipCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.address.city.$dirty) {
                return errors;
            }
            if (!this.$v.address.city.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.city.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.city.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },

        poBoxNrErrors() {
            const errors = [];
            if (!this.$v.address.poBoxNr.$dirty) {
                return errors;
            }
            if (!this.$v.address.poBoxNr.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        poBoxZipCodeErrors() {
            const errors = [];
            if (!this.$v.address.poBoxZipCode.$dirty) {
                return errors;
            }
            if (!this.$v.address.poBoxZipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.poBoxZipCode.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.poBoxZipCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        poBoxCityErrors() {
            const errors = [];
            if (!this.$v.address.poBoxCity.$dirty) {
                return errors;
            }
            if (!this.$v.address.poBoxCity.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.poBoxCity.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.poBoxCity.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    watch: {
        formData: {
            handler: function (newData) {
                if (newData) {
                    this.address = _.assign(this.address, newData);
                }
            },
            immediate: true
        }
    }
};
</script>
