import {
    axiosBackoffice,
    axiosTasks,
    axiosSapApi
} from "@/api/axios-instances";
import { BackofficeTasksCounters } from "@/models";
import * as ACTION_TYPES from "../types/action-types";

const state = {
    pendingPoolRegistrations: [],
    newReplies: [],
    overdueTasks: [],
    overdueQuestions: [],
    openTasks: [],
    allReadyForSapExport: [],
    boReadyForSapExport: [],
    all: [],
    counters: new BackofficeTasksCounters(),
    readonlyModus: false,
    title: null,
    subtitle: null
};
const mutations = {
    setTitle(state, title) {
        if (!title) return;
        state.title = title;
    },
    setSubtitle(state, subtitle) {
        if (!subtitle) return;
        state.subtitle = subtitle;
    },
    setDefaulTitle(state) {
        state.title = null;
        state.subtitle = null;
    },
    setPendingPoolRegistrations(state, payload) {
        state.pendingPoolRegistrations = payload;
    },
    setNewReplies(state, payload) {
        state.newReplies = payload;
    },
    setOverdueRegistrations(state, payload) {
        state.overdueTasks = payload;
    },
    setOverdueQuestions(state, payload) {
        state.overdueQuestions = payload;
    },
    setOpenRegistrations(state, payload) {
        state.openTasks = payload;
    },
    setAllTasksReadyForSapExport(state, payload) {
        state.allReadyForSapExport = payload;
    },
    setBoTasksReadyForSapExport(state, payload) {
        state.boReadyForSapExport = payload;
    },
    setAll(state, payload) {
        state.all = payload;
    },
    setCounters(state, payload) {
        state.counters = payload;
    },
    deletePendingRegistration(state, docId) {
        const items = state.pendingPoolRegistrations.filter(
            (item) => item.docId !== docId
        );
        state.pendingPoolRegistrations = items;
    },
    removeRegistrationFromList(state, { list, docId }) {
        if (!state[list]) return;
        const items = state[list].filter((item) => item.docId !== docId);
        state[list] = items;
    }
};

const getters = {};

const actions = {
    fetchPendingPoolRegistrations: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getPendingPoolTasks")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setPendingPoolRegistrations", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchNewReplies: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getAllNewRepliesTasks")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setNewReplies", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchOverdueRegistrations: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getAllOverdueTasks")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setOverdueRegistrations", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchOverdueQuestions: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getAllTasksWithOverdueDialogs")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setOverdueQuestions", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchOpenRegistrations: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getAllOpenTasks")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setOpenRegistrations", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAllRegistrations: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getAllTasks")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setAll", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchBoTasksReadyForSapExport: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosSapApi
                .get("/boTasksReadyForSapExport")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setBoTasksReadyForSapExport", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAllTasksReadyForSapExport: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosSapApi
                .get("/allTasksReadyForSapExport")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit("setAllTasksReadyForSapExport", items);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchCountersData: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .get("/getDashboardData")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        let counters = {};
                        counters.registrationsPendingPool =
                            resData.pendingTasks || 0;
                        counters.registrationsNewReplies =
                            resData.newRepliesTasks || 0;
                        counters.registrationsOverdueQuestions =
                            resData.overdueQuestionsTasks || 0;
                        counters.registrationsOverdueRegistrations =
                            resData.overdueTasks || 0;
                        counters.registrationsOpenRegistrations =
                            resData.openTasks || 0;
                        counters.allTasksReadyForSapExport =
                            resData.allTasksReadyForSapExport || 0;
                        counters.boTasksReadyForSapExport =
                            resData.boTasksReadyForSapExport || 0;
                        counters.tasksTotalCount = resData.totalTasks;
                        commit("setCounters", counters);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deletePendingPoolRegistrationFromDB: ({ commit }, docId) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .delete(`/removeFromDbByDocId/${docId}`)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        commit("deletePendingRegistration", docId);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deletePendingPoolRegistration: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .delete(`/doc/${data.docId}`, { data })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        commit("deletePendingRegistration", data.docId);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    registrationUnlockForReview: (_ctx, taskDocId) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .put(`/unlockForReview/${taskDocId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    registrationForceTakeOver: (_ctx, taskDocId) => {
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .put(`/forceTakeOverForReview/${taskDocId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    taskComplete: (_ctx, data) => {
        const taskProcess = data.process;
        const taskDocId = data.docId;
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .put(`/complete/${taskProcess}/${taskDocId}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    exportTaskToSap: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosSapApi
                .put(`/${data.taskDocId}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ACTION_TYPES.BACKOFICCE_TASK_COMPLETE]: (ctx, data) => {
        const taskProcess = data.process;
        const taskDocId = data.docId;
        return new Promise((resolve, reject) => {
            axiosBackoffice
                .put(`/complete/${taskProcess}/${taskDocId}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
