<template>
    <BaseCard :dense="dense" v-bind="$attrs" v-on="$listeners">
        <v-card-text :class="dense ? 'ma-0 pa-0' : undefined">
            <slot name="content"></slot>
        </v-card-text>
        <slot />
    </BaseCard>
</template>
<script>
export default {
    name: "BaseCardText",
    components: {
        BaseCard: () => import("./BaseCard.vue")
    },
    props: {
        dense: {
            type: Boolean,
            default: false
        }
    }
};
</script>
