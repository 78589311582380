<i18n src="./log-table-i18n.yaml"></i18n>
<template>
    <GlobalTable
        :items="items"
        :headers="computedHeaders"
        :actions="computedActions"
        :title="$t('title')"
        :isLoading="!items ? isLoading : false"
        :sort-by.sync="computedOptions.sortBy"
        :sort-desc.sync="computedOptions.sortDesc"
        :server-items-length="logsTotalCount"
        hideBtnAdd
        hideSearch
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template v-slot:item.levelNr="{ item }">
            <slot name="levelNr" :item="item">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-if="item.levelNr === 10"
                            v-on="on"
                            color="green"
                            >mdi-debug</v-icon
                        >
                        <v-icon
                            v-if="item.levelNr === 20"
                            v-on="on"
                            color="info"
                            >mdi-information</v-icon
                        >
                        <v-icon
                            v-if="item.levelNr === 30"
                            v-on="on"
                            color="yellow"
                            >mdi-alert-circle</v-icon
                        >
                        <v-icon
                            v-if="item.levelNr === 40"
                            v-on="on"
                            color="warning"
                            >mdi-alert</v-icon
                        >
                        <v-icon
                            v-if="item.levelNr === 50"
                            v-on="on"
                            color="error"
                            >mdi-alert</v-icon
                        >
                    </template>
                    <span>
                        <div>{{ item.levelNr }}</div>
                        <div>{{ item.levelName }}</div>
                    </span>
                </v-tooltip>
            </slot>
        </template>

        <template v-slot:item.logMsg="{ item }">
            <slot name="logMsg" :item="item">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-message</v-icon>
                    </template>
                    <span>
                        <div>{{ item.logMsg }}</div>
                    </span>
                </v-tooltip>
            </slot>
        </template>

        <template v-slot:item.timestamp="{ item }">
            <slot name="timestamp" :item="item">
                <div v-if="item.timestamp">
                    <v-icon>mdi-calendar-check</v-icon>
                    <base-date-time :timestamp="item.timestamp" />
                </div>
            </slot>
        </template>

        <template v-slot:item.moduleName="{ item }">
            <slot name="moduleName" :item="item">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>
                        <div>Module Name: {{ item.moduleName }}</div>
                        <div>Module Path Name: {{ item.modulePathName }}</div>
                        <div>Line Nr.: {{ item.lineNr }}</div>
                    </span>
                </v-tooltip>
            </slot>
        </template>

        <template v-slot:item.user="{ item }">
            <slot name="user" :item="item">
                <div v-if="item.user">
                    <span v-if="item.user.firstName">{{
                        item.user.firstName
                    }}</span>
                    <span v-if="item.user.familyName" class="ml-2">{{
                        item.user.familyName
                    }}</span>
                </div>
            </slot>
        </template>

        <template slot="no-data">
            <slot name="no-data">
                <span>
                    <p class="pt-2 blue--text subheading">
                        <v-icon medium class="blue--text">
                            mdi-information
                        </v-icon>
                        {{ $t("no-data-msg") }}
                    </p>
                </span>
            </slot>
        </template>
    </GlobalTable>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "LogTable",
    components: {
        GlobalTable: () => import("@/components/general/table.vue")
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        actions: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: "title"
        },
        headers: {
            type: Array,
            default: () => []
        },
        funcShowAction: {
            type: Function,
            default: () => true
        },
        options: {
            type: Object,
            default: () => {}
        },
        logsTotalCount: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        defaultOptions: {
            sortBy: "timestamp",
            itemsPerPage: 5,
            sortDesc: true,
            page: 1
        },
        dialog: false,
        pageCount: 0,
        search: null
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        defaultHeaders() {
            return [
                {
                    text: this.$t("header-log-msg"),
                    value: "logMsg",
                    sortable: false,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-level-nr"),
                    value: "levelNr",
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-timestamp"),
                    value: "timestamp",
                    align: "left",
                    isMobile: true,
                    sort: (a, b) => {
                        const timestampA = this.$moment.utc(a).unix();
                        const timestampB = this.$moment.utc(b).unix();
                        return timestampA - timestampB;
                    }
                },
                {
                    text: this.$t("header-module-name"),
                    value: "moduleName",
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-user"),
                    value: "user",
                    align: "left",
                    isMobile: true
                }
            ];
        },
        computedHeaders() {
            return [...this.headers, ...this.defaultHeaders];
        },
        isNotEmpty() {
            return this.items && this.items.length > 0;
        },
        computedOptions() {
            return Object.assign(this.defaultOptions, this.options);
        },
        defaultActions() {
            return [
                {
                    title: this.$t("action-view"),
                    icon: "mdi-eye",
                    action: "view"
                },
                {
                    title: this.$t("action-delete"),
                    icon: "mdi-delete",
                    action: "delete"
                }
            ];
        },
        computedActions() {
            return [...this.actions, ...this.defaultActions];
        }
    }
};
</script>
