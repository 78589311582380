<i18n src="./suva-partner-finder-search-i18n.yaml"></i18n>
<template>
    <base-text-field
        v-model="partnerNumberSearchUid"
        :required="required"
        label="Suva Partner Finder Suche"
        hint="Geben Sie eine gülige UID ein, dann starten Sie die suche"
        :value="partnerNumberSearchUid"
        @input="partnerNumberSearchUid = $event"
        type="number"
        class="remove-spin-buttons"
        @keydown="onKeydown"
        :loading="found !== null"
    >
        <template v-slot:append-outer>
            <v-icon left color="info" @click="startPartnerFinderSearch"
                >mdi-magnify</v-icon
            >
        </template>
        <template v-slot:append v-if="found !== null">
            <v-icon v-if="found == true" color="success"
                >mdi-check-circle</v-icon
            >
            <v-icon v-if="found == false" color="error"
                >mdi-close-circle</v-icon
            >
        </template>
        <template v-slot:progress v-if="found !== null">
            <v-progress-linear
                :color="found === true ? 'success' : 'error'"
                absolute
                height="2"
            ></v-progress-linear>
        </template>
    </base-text-field>
</template>
<script>
import { SuvaPartnerFinder } from "@/models/tasks";
import { mapGetters } from "vuex";
export default {
    name: "SuvaPartnerFinderSearch",
    props: {
        uid: {
            type: [String, Number],
            default: null
        },
        required: {
            type: Boolean,
            default: null
        }
    },
    data: () => ({
        partnerNumberSearchUid: null,
        found: null
    }),
    computed: {
        ...mapGetters("auth", ["userModel"])
    },
    methods: {
        startPartnerFinderSearch() {
            if (!this.partnerNumberSearchUid) {
                this.$toast.error("No partnerNumberSearchUid found");
                return;
            }
            this.loader().show("START Suva PARTNER UID SEARCH");
            this.$axiosSuvaApi
                .post("/partnerNumberSearchUid", {
                    uid: this.partnerNumberSearchUid
                })
                .then((response) => {
                    if (response.status === 200) {
                        const resData = response.data;
                        const result = resData.data;
                        if (result) {
                            let _emptyModel = this._getEmptyModel(true);
                            const result = resData.data;
                            const model = Object.assign(_emptyModel, result);
                            if (result) {
                                this.$emit("change", model);
                                this.found = true;
                                setTimeout(() => {
                                    this.found = null;
                                }, 3000);
                            }
                            this.$toast.success(
                                this.$t("toast-msg-found", {
                                    uid: this.partnerNumberSearchUid
                                })
                            );
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.partnerNumberSearchUidResult = null;
                    const _emptyModel = this._getEmptyModel(false);
                    _emptyModel.uid = this.partnerNumberSearchUid;
                    const errorRes = error.response;
                    let errorStatus;
                    let errorResData;
                    if (errorRes.status) {
                        errorStatus = errorRes.status;
                        errorResData = errorRes.data;
                        this.found = false;
                        setTimeout(() => {
                            this.found = null;
                        }, 3000);
                        switch (errorStatus) {
                            case 404:
                                if (
                                    errorResData.data &&
                                    errorResData.data.timestamp
                                ) {
                                    _emptyModel.timestamp =
                                        errorResData.data.timestamp;
                                }
                                this.$toast.info(
                                    this.$t("toast-msg-not-found", {
                                        uid: this.partnerNumberSearchUid
                                    })
                                );

                                break;
                            default:
                                this.$toast.error(
                                    this.$t("toast-msg-error", {
                                        uid: this.partnerNumberSearchUid
                                    })
                                );
                                break;
                        }
                    }
                    this.loader().hide();
                    this.$emit("change", _emptyModel);
                });
        },
        onKeydown(e) {
            if (e.keyCode === 13) {
                this.startPartnerFinderSearch();
            }
        },
        _getEmptyModel(status) {
            let model = new SuvaPartnerFinder();
            model.searchState = status;
            if (this.userModel) {
                let user = {
                    name:
                        this.userModel.firstName +
                        " " +
                        this.userModel.familyName,
                    accountDocId: this.userModel.accountDocId
                };
                model.user = Object.assign(model.user, user);
            }
            return model;
        }
    },
    watch: {
        uid: {
            handler: function (uid) {
                if (uid) {
                    this.partnerNumberSearchUid = uid;
                }
            },
            immediate: true
        }
    }
};
</script>
