<i18n src="./app-toolbar-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <v-app-bar
        color="backgroundInner"
        dark
        app
        class="w-toolbar primary--text"
        clipped-left
        :elevation="1"
        height="70"
        extension-height="20"
        v-bind="$attrs"
    >
        <template v-if="appMenuPosition === 'left'">
            <transition name="fade" mode="out-in" v-if="showMenuBtn">
                <v-btn
                    icon
                    color="regOfficePrimary"
                    large
                    @click="onMenuIconClick"
                >
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </transition>
            <slot name="app-menu" />
        </template>
        <template v-if="!hideLogo">
            <slot name="app-logo">
                <router-link :to="isAuth ? { name: 'user-dashboard' } : '/'">
                    <v-img
                        :src="require('@/' + registrationOfficeLogoPath)"
                        contain
                        :class="
                            $vuetify.breakpoint.mobile ? undefined : 'ml-8 mr-8'
                        "
                        :max-width="logoMaxWith"
                    ></v-img>
                </router-link>
            </slot>
        </template>
        <v-container fluid class="py-0 ml-0">
            <v-layout row wrap class="pl-2">
                <div>
                    <div
                        v-if="title01"
                        class="cls-toolbar-header"
                        :class="
                            $vuetify.breakpoint.mobile
                                ? 'text-subtitle-2'
                                : 'text-subtitle-1'
                        "
                    >
                        <span
                            v-if="
                                title01 == 'default-title' && scrollSize >= 100
                            "
                            v-html="$t(title01)"
                        ></span>
                        <div v-else-if="title01 != 'default-title'">
                            <v-tooltip bottom v-if="$vuetify.breakpoint.mobile">
                                <template v-slot:activator="{ on }">
                                    <span
                                        class="cls-card-header"
                                        v-on="on"
                                        v-html="title01"
                                    >
                                    </span>
                                </template>
                                <span v-html="title01">{{ title01 }}</span>
                            </v-tooltip>
                            <span
                                v-else
                                class="cls-card-header"
                                v-html="title01"
                            >
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="title02"
                        class="pa-0 ma-0 cls-toolbar-header"
                        :class="
                            $vuetify.breakpoint.mobile
                                ? 'text-caption'
                                : 'text-subtitle-2'
                        "
                    >
                        <span
                            v-if="
                                title02 == 'default-subtitle' &&
                                scrollSize >= 100
                            "
                            v-html="$t(title02)"
                        ></span>
                        <div v-else-if="title02 != 'default-subtitle'">
                            <v-tooltip bottom v-if="$vuetify.breakpoint.mobile">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" v-html="title02"></div>
                                </template>
                                <span v-html="title02"></span>
                            </v-tooltip>
                            <span
                                v-else
                                class="cls-card-header"
                                v-html="title02"
                            >
                            </span>
                        </div>
                    </div>
                </div>
            </v-layout>
        </v-container>
        <v-spacer></v-spacer>
        <WLanguageMenu v-if="$vuetify.breakpoint.smAndUp" />
        <template v-if="appMenuPosition === 'right'">
            <transition name="fade" mode="out-in" v-if="showMenuBtn">
                <v-btn
                    icon
                    color="regOfficePrimary"
                    large
                    @click="onMenuIconClick"
                >
                    <v-icon v-if="drawer">mdi-arrow-right</v-icon>
                    <v-icon v-else>mdi-menu</v-icon>
                </v-btn>
            </transition>
            <slot name="app-menu" />
        </template>
        <template v-slot:extension v-if="showExtension">
            <v-container fluid fill-height class="ml-7">
                <v-row>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span
                                v-on="on"
                                class="text-caption ml-1"
                                :class="
                                    getTimestampSatusColor(
                                        registrationTimestampStatus
                                    ) + '--text lighten-5'
                                "
                            >
                                <span
                                    v-if="
                                        !registrationTimestampStatus &&
                                        registrationTimestamp
                                    "
                                >
                                    <span>{{ $t("backup") }}</span>
                                    <span v-if="registrationTimestampUpdated"
                                        >&ensp;{{
                                            registrationTimestampUpdated
                                        }}</span
                                    >
                                    <span v-else
                                        >&ensp;{{
                                            $moment
                                                .utc(registrationTimestamp)
                                                .local()
                                                .from($moment())
                                        }}</span
                                    >
                                </span>
                                <span
                                    v-else-if="
                                        registrationTimestampStatus &&
                                        registrationTimestampStatus ===
                                            'loading'
                                    "
                                    class="loading-dots red--text"
                                    :class="
                                        getTimestampSatusColor(
                                            registrationTimestampStatus
                                        ) + '--text'
                                    "
                                >
                                    {{ $t("backup-loading") }}
                                </span>
                                <span
                                    v-else-if="
                                        registrationTimestampStatus &&
                                        registrationTimestampStatus === 'error'
                                    "
                                >
                                    {{ $t("backup-error") }}
                                </span>
                                <span
                                    v-else-if="
                                        registrationTimestampStatus &&
                                        registrationTimestampStatus === 'saved'
                                    "
                                >
                                    {{ $t("backup-saved") }}
                                </span>
                                <span v-else>
                                    {{ $t("error-occurred") }}
                                </span>
                            </span>
                        </template>
                        <span
                            v-if="
                                !registrationTimestampStatus &&
                                registrationTimestamp
                            "
                        >
                            <span class="caption">
                                {{ $t("was") }}
                                <span v-if="registrationTimestampUpdated"
                                    >&ensp;{{
                                        registrationTimestampUpdated
                                    }}</span
                                >
                                <span v-else
                                    >&ensp;{{
                                        $moment
                                            .utc(registrationTimestamp)
                                            .local()
                                            .from($moment())
                                    }}</span
                                >
                                {{ $t("success-save") }}
                            </span>
                        </span>
                        <span
                            v-else-if="
                                registrationTimestampStatus &&
                                registrationTimestampStatus === 'loading'
                            "
                            class="loading-dots caption"
                        >
                            {{ $t("backup-loading") }}
                        </span>
                        <span
                            v-else-if="
                                registrationTimestampStatus &&
                                registrationTimestampStatus === 'error'
                            "
                            :class="
                                'caption' +
                                ' ' +
                                getTimestampSatusColor(
                                    registrationTimestampStatus
                                ) +
                                '--text'
                            "
                        >
                            {{ $t("backup-error") }}
                        </span>
                        <span
                            v-else-if="
                                registrationTimestampStatus &&
                                registrationTimestampStatus === 'saved'
                            "
                            class="caption"
                        >
                            {{ $t("backup-saved") }}
                        </span>
                        <span
                            v-else
                            :class="
                                'caption' +
                                ' ' +
                                getTimestampSatusColor(
                                    registrationTimestampStatus
                                ) +
                                '--text'
                            "
                        >
                            {{ $t("error-occurred") }}
                        </span>
                    </v-tooltip>
                </v-row>
            </v-container>
        </template>
        <v-progress-linear
            v-if="showAutoSaveProgressLinear"
            :indeterminate="
                registrationTimestampStatus &&
                registrationTimestampStatus === 'loading'
            "
            absolute
            height="3"
            rounded
            active
            bottom
            :color="
                getTimestampSatusColor(registrationTimestampStatus) +
                ' darken-5'
            "
            :backgroundColor="
                getTimestampSatusColor(registrationTimestampStatus) +
                ' lighten-2'
            "
        ></v-progress-linear>
    </v-app-bar>
</template>
<script>
import WLanguageMenu from "@/components/fields/WLanguageMenu.vue";
import { mapGetters } from "vuex";
export default {
    name: "AppToolbar",
    components: {
        WLanguageMenu
    },

    inject: ["theme"],

    data: () => ({
        scrollSize: 0,
        registrationTimestampUpdated: null,
        showLanguageMenu: false,
        showExtension: false,
        showAutoSaveProgressLinear: false,
        isBackoffice: null,
        toPathName: null
    }),

    props: {
        drawer: {
            type: Boolean,
            default: false
        },
        showMenuBtn: {
            type: Boolean,
            default: false
        },
        appMenuPosition: {
            type: String,
            default: "right",
            validator: function (value) {
                return ["right", "left"].indexOf(value) !== -1;
            }
        },
        hideLogo: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            isAuth: "auth/isAuthenticated"
        }),
        ...mapGetters("settings", {
            title01: "title01",
            title02: "title02",
            registrationTimestamp: "registrationTimestamp",
            registrationTimestampStatus: "registrationTimestampStatus",
            registrationOfficeLogoPath: "getRegistrationOfficeLogoPath",
            registrationOffice: "getRegistrationOffice"
        }),
        maxWidth() {
            if (this.$router.currentRoute.name == "task-create") {
                return this.$vuetify.breakpoint.width - 200;
            } else {
                return this.$vuetify.breakpoint.width;
            }
        },
        logoMaxWith() {
            if (this.registrationOffice === "SUVA") {
                return "88";
            }
            return "100";
        }
    },

    methods: {
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        onMenuIconClick(value) {
            this.$emit("menu-icon-click", value);
        },
        goHome() {
            if (this.isAuth) {
                this.$router.push({ name: "user-dashboard" });
            } else {
                this.$router.push({ path: "/" });
            }
        },
        onNavigationItemClick() {
            this.onMenuIconClick();
        },
        handleScroll() {
            this.scrollSize = window.scrollY;
        },
        getTimestampSatusColor(status) {
            switch (status) {
                case "saved":
                    return "grey";
                case "loading":
                    return "primary";
                case "error":
                    return "error";
                default:
                    return "grey";
            }
        },
        checkIfShowAutoSaveProgressLinear() {
            // check if showAutoSaveProgressLinear or not
            if (
                (this.toPathName === "backoffice-view" &&
                    this.$vuetify.breakpoint.smAndDown) ||
                (this.toPathName === "task-create" &&
                    this.$vuetify.breakpoint.width < 1024)
            ) {
                this.showAutoSaveProgressLinear = true;
            } else {
                this.showAutoSaveProgressLinear = false;
            }
        },
        checkIfShowExtension() {
            if (
                (this.toPathName === "task-create" &&
                    this.$vuetify.breakpoint.width > 1024) ||
                (this.toPathName === "backoffice-view" &&
                    this.$vuetify.breakpoint.mdAndUp)
            ) {
                this.showExtension = true;
            } else {
                this.showExtension = false;
            }
        },
        checkAll() {
            // check if showAutoSaveProgressLinear or not
            this.checkIfShowAutoSaveProgressLinear();
            // check if showExtension or not
            this.checkIfShowExtension();
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    watch: {
        "$vuetify.breakpoint.width": {
            handler: function (newVal) {
                if ((newVal > 1024) & this.show) {
                    this.onSettingsIconClick(false);
                }
                this.checkAll();
            },
            immediate: true
        },
        $route(to) {
            if (to.meta.isBackoffice !== null) {
                this.isBackoffice = to.meta.isBackoffice;
            }
            if (to.name !== null) {
                this.toPathName = to.name;
            }
            this.checkAll();
        },
        "$vuetify.breakpoint"(breakpoint) {
            if (breakpoint) {
                this.checkAll();
            }
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    mounted() {
        this.$store.commit("settings/setDefaultTitles");
        if (!this.toPathName) {
            this.toPathName = this.$router.currentRoute.name;
        }
        if (!this.isBackoffice) {
            this.isBackoffice = this.$router.currentRoute.meta.isBackoffice;
        }
        this.checkAll();
    }
};
</script>
<style      src="./app-toolbar.styl" lang="stylus"></style>
