var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',_vm._g(_vm._b({},'base-card',_vm.$attrs,false),_vm.$listeners),[_c('v-card-title',[_vm._t("title",function(){return [(_vm.title)?_c('span',{staticClass:"d-inline-block text-truncate",style:({ maxWidth: _vm.maxTextTitleWidth + 'px' })},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.title))])]}}],null,false,2532612980)},[_c('span',[_vm._v(" "+_vm._s(_vm.title))])])],1):_vm._e()]}),_vm._t("title-extra"),_c('v-spacer'),_vm._t("title-actions",function(){return [_vm._t("title-actions-additional"),(_vm.$vuetify.breakpoint.mdAndUp)?[_vm._l((_vm.actions),function(action){return [(action.title)?_c('v-tooltip',{key:action.id,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(action.emit)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.onClickAction(action, $event)}}},on),[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_c('v-icon',_vm._g({key:action.id},on),[_vm._v(" "+_vm._s(action.icon)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(action.title)+" ")]):[(action.emit)?_c('v-btn',{key:action.id,attrs:{"icon":""},on:{"click":function($event){return _vm.onClickAction(action, $event)}}},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_c('v-icon',{key:action.id},[_vm._v(" "+_vm._s(action.icon)+" ")])]]})]:_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.actions),function(action){return _c('v-list-item',{key:action.id,on:{"click":function($event){return _vm.onClickAction(action, $event)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(action.icon)+" ")]),(action.title)?_c('span',[_vm._v(" "+_vm._s(action.title)+" ")]):_vm._e()],1)],1)}),1)],1)]})],2),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._t("subtitle",function(){return [(_vm.subtitle)?_c('span',{staticClass:"d-inline-block text-truncate",style:({ maxWidth: _vm.maxTextSubtitleWidth + 'px' })},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.subtitle))])]}}],null,false,1745984880)},[_c('span',[_vm._v(" "+_vm._s(_vm.subtitle))])])],1):_vm._e()]})],2),_c('v-divider'),_c('v-card-text',[_vm._t("content")],2),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }