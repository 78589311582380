<template>
  <span>
    <slot name="keyText">
      <span class="font-weight-bold"> {{ keyText }}: </span>
    </slot>
    <slot name="valueText">
      <span>
        {{ valueText }}
      </span>
    </slot>
  </span>
</template>

<script>
export default {
  name: "AppKeyValueTextInline",
  props: ["keyText", "valueText"],
};
</script>
