<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./summary-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-device-details')" :expandable="expandable">
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li>
                    <DisplayDeviceDetailsInline
                        :show-copy-btn="showCopyBtn"
                        :manufacturerName="
                            task.manufacturer &&
                            task.manufacturer instanceof Object
                                ? task.manufacturer.name
                                : task.manufacturer
                        "
                        :manufacturerDepartment="
                            task.manufacturer &&
                            task.manufacturer instanceof Object
                                ? task.manufacturer.department
                                : undefined
                        "
                        :factoryNumber="task.factoryNumber"
                    />
                </li>
                <li>
                    <DisplayDeviceDetailsInline
                        :show-copy-btn="showCopyBtn"
                        :constructionYear="task.constructionYear"
                        :inOperationYear="task.inOperationYear"
                    />
                </li>
                <li v-if="task.process && task.objectType">
                    <app-key-value-text-inline
                        :keyText="
                            $translateKebablizerLower(
                                `task-process-type-${task.process}`
                            )
                        "
                        :valueText="$t(`task-object-type-${task.objectType}`)"
                    />
                </li>
                <template v-if="task.process === PROCESS_TYPES.REGISTRATION">
                    <li>
                        <app-key-value-text-inline
                            :keyText="$t('task-working-title')"
                            :valueText="task.workingTitle"
                        />
                        <CopyToClipboard
                            v-if="showCopyBtn"
                            :value="task.workingTitle"
                        />
                    </li>
                </template>
                <template v-if="task.process === PROCESS_TYPES.DEREGISTRATION">
                    <li>
                        <app-key-value-text-inline
                            :keyText="$t('equipment-id-label')"
                        >
                            <template v-slot:valueText>
                                <template v-if="task.sapEquipmentNr">
                                    <CopyToClipboard
                                        v-if="showCopyBtn"
                                        :value="task.sapEquipmentNr"
                                    />
                                    <span>{{ task.sapEquipmentNr }}</span>
                                </template>
                                <template v-else>
                                    <span class="font-italic">{{
                                        $t("not-recorded")
                                    }}</span>
                                </template>
                            </template>
                        </app-key-value-text-inline>
                    </li>
                </template>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import { PROCESS_TYPES } from "@/data/globals.js";
import mixin from "./mixin";
export default {
    name: "SummaryBaseData",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("./base-list-item.vue"),
        DisplayDeviceDetailsInline: () =>
            import("@/components/general/display-device-details-inline.vue")
    },
    data: () => ({
        PROCESS_TYPES
    })
};
</script>
