export class SuvaPartnerFinder {
    constructor() {
        this.companyName = null;
        this.street = null;
        this.houseNr = null;
        this.zipCode = null;
        this.city = null;
        this.country = null;
        this.partnerNr = null;
        this.poBox = null;
        this.poBoxNr = null;
        this.poBoxZipCode = null;
        this.uid = null;
        this.uidNrFormatiert = null;
        this.searchState = null;
        this.timestamp = null;
        this.user = {
            name: null,
            accountDocId: null
        };
    }
}