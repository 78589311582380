<i18n src="./dashboard-view-i18n.yaml"></i18n>
<template>
    <v-container class="grow d-flex flex-column flex-nowrap">
        <v-row dense class="grow">
            <v-col :cols="12">
                <base-header>
                    <v-icon>mdi-view-dashboard</v-icon>
                    {{ $t("title") }}
                </base-header>
            </v-col>
            <v-col :cols="12">
                <CalendarChartLogs class="fill-height"/>
            </v-col>
            <v-col :cols="12" :md="6">
                <LogsCounterPieChart class="fill-height"/>
            </v-col>
            <v-col :cols="12" :md="6">
                <HistoryActivities class="fill-height"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "DashboardView",
    components: {
        CalendarChartLogs: () =>
            import("@/components/devel/widgets/chart/calendar-chart-logs.vue"),
        HistoryActivities: () =>
            import("@/components/devel/components/history-avtivities.vue"),
        LogsCounterPieChart: () =>
            import(
                "@/components/devel/widgets/chart/logs-counter-pie-chart.vue"
            )
    }
};
</script>
