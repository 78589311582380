<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./form-additional-data-i18n.yaml"></i18n>
<template>
    <v-form
        :disabled="disabled"
        :class="$vuetify.breakpoint.mdAndUp ? 'pt-2' : 'mb-4'"
    >
        <v-checkbox
            v-if="requireChambers"
            v-model="chambersRequired"
            :label="$t('require-chambers-label')"
        >
        </v-checkbox>
        <v-checkbox
            v-if="requireAttachments"
            v-model="attachmentsRequired"
            :label="$t('require-attachments-label')"
            class="mr-2 pb-4"
            hide-details
        >
        </v-checkbox>
        <base-select
            v-if="attachmentsRequired"
            :items="attachmentCategoriesItems"
            v-model="requireAttachmentsCategories"
            :label="$t('attachment-categories-label')"
            multiple
            clearable
            :required="attachmentsRequired"
        >
            <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                        <v-icon
                            :color="
                                requireAttachmentsCategories.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                            "
                            >{{ icon }}</v-icon
                        >
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            v-if="requireAttachmentsCategories.length === 0"
                            >{{
                                $t("attachments-select-all-text")
                            }}</v-list-item-title
                        >
                        <v-list-item-title v-else>{{
                            $t("attachments-selected-text", {
                                nrOfSelectedCategories:
                                    requireAttachmentsCategories.length
                            })
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:item="{ item }">
                {{ $t(`attachment-category-${item}`) }}
            </template>
            <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                    {{ $t(`attachment-category-${item}`) }}
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">
                    (+{{ requireAttachmentsCategories.length - 1 }} andere)
                </span>
            </template>
        </base-select>
    </v-form>
</template>
<script>
import { ATTACHMENTS_TYPES } from "@/data/globals.js";
export default {
    name: "FormAdditionalData",
    props: {
        requireAttachments: {
            type: Boolean,
            default: false
        },
        requireChambers: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        attachmentCategoriesItems: [
            ATTACHMENTS_TYPES.DRAWING,
            ATTACHMENTS_TYPES.ASSEMBLY_CERTIFICATE,
            ATTACHMENTS_TYPES.TYPE_EXAMINATION,
            ATTACHMENTS_TYPES.DECLARATION_OF_CONFORMITY_AND_CERTIFICATES,
            ATTACHMENTS_TYPES.OPERATING_AND_MAINTENANCE_INSTRUCTIONS,
            ATTACHMENTS_TYPES.SAFETY_VALVE_SETTING_CERTIFICATE,
            ATTACHMENTS_TYPES.ADDITIONAL
        ]
    }),
    computed: {
        requireAttachmentsCategories: {
            get() {
                return this.formData.requireAttachmentsCategories;
            },
            set(value) {
                this.$emit("update", {
                    field: "requireAttachmentsCategories",
                    value: value
                });
            }
        },
        attachmentsRequired: {
            get() {
                return this.formData.attachmentsRequired;
            },
            set(value) {
                this.$emit("update", {
                    field: "attachmentsRequired",
                    value: value
                });
            }
        },
        chambersRequired: {
            get() {
                return this.formData.chambersRequired;
            },
            set(value) {
                this.$emit("update", {
                    field: "chambersRequired",
                    value: value
                });
            }
        },
        selectedAllCategories() {
            return (
                this.requireAttachmentsCategories.length ===
                this.attachmentCategoriesItems.length
            );
        },
        selectedSomeCategories() {
            return (
                this.requireAttachmentsCategories.length > 0 &&
                !this.selectedAllCategories
            );
        },
        icon() {
            if (this.selectedAllCategories) return "mdi-close-box";
            if (this.selectedSomeCategories) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        }
    },
    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.selectedAllCategories) {
                    this.requireAttachmentsCategories = [];
                } else {
                    this.requireAttachmentsCategories =
                        this.attachmentCategoriesItems.slice();
                }
            });
        }
    }
};
</script>
