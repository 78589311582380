var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.address)?_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":"","fill-height":""}},[((_vm.showSameAsOperator && _vm.address.sameAddressAsOperator)
              || (_vm.showSameAsBilling && _vm.address.sameAddressAsBilling))?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(_vm.showSameAsOperator && _vm.address.sameAddressAsOperator)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("same-address-as-operator"))+" ")]):_vm._e(),(_vm.showSameAsBilling && _vm.address.sameAddressAsBilling)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("same-address-as-billing"))+" ")]):_vm._e()]):_c('span',[(_vm.address.uidFormatted)?_c('div',[_vm._v(" "+_vm._s(_vm.address.uidFormatted)+" "),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.address.uidFormatted}}):_vm._e()],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[(_vm.address.addressType === _vm.ADDRESS_TYPES.COMPANY)?_c('span',[(_vm.address.companyName && !_vm.address.department)?_c('span',[_vm._v(" "+_vm._s(_vm.address.companyName)+", ")]):(
                            !_vm.address.companyName && _vm.address.department
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.address.department)+", ")]):_c('span',[_vm._v(" "+_vm._s(_vm.address.companyName)+" ("+_vm._s(_vm.address.department)+"), ")])]):_c('span',[(_vm.address.firstName && _vm.address.familyName)?_c('span',[_vm._v(" "+_vm._s(_vm.address.firstName)+" "+_vm._s(_vm.address.familyName)+", ")]):_vm._e()]),(_vm.address.additionalAddressLines)?_c('span',[(_vm.address.addressLine1 && !_vm.address.addressLine2)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("additional-address-lines-prefix"))+" "+_vm._s(_vm.address.addressLine1)+", ")]):_vm._e(),(_vm.address.addressLine2)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("additional-address-lines-prefix"))+" "+_vm._s(_vm.address.addressLine1)+" "+_vm._s(_vm.address.addressLine2)+", ")]):_vm._e()]):_vm._e()]),_c('span',{staticClass:"pl-1"},[(_vm.address.street)?_c('span',[_vm._v(" "+_vm._s(_vm.address.street)),(_vm.address.houseNr)?_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.address.houseNr))]):_vm._e(),_vm._v(", ")]):_vm._e(),_c('span',{staticClass:"pr-2"},[(_vm.address.country)?_c('span',[_vm._v("("+_vm._s(_vm.address.country)+")")]):_vm._e(),(
                            _vm.address.country &&
                            _vm.address.zipCode &&
                            _vm.address.city
                        )?_c('span',[_vm._v("-")]):_vm._e(),(_vm.address.zipCode && _vm.address.city)?_c('span',[_vm._v(_vm._s(((_vm.address.zipCode) + " " + (_vm.address.city)))+" ")]):_vm._e(),(_vm.address.country === 'CH' && _vm.address.canton)?_c('span',[_vm._v("("+_vm._s(_vm.address.canton)+")")]):_vm._e()])])]),(_vm.address.communication)?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(_vm.address.communication.phone)?_c('span',{class:_vm.address.communication.fax || _vm.address.communication.email
                        ? 'pr-2'
                        : null},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","href":'tel:' + _vm.address.communication.phone}},[_vm._v("mdi-phone")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":("tel:" + (_vm.address.communication.phone))}},[_vm._v(_vm._s(_vm.address.communication.phone))]),(
                        _vm.address.communication.fax ||
                        _vm.address.communication.email
                    )?_c('span',[_vm._v(",")]):_vm._e()],1):_vm._e(),(_vm.address.communication.fax)?_c('span',{class:_vm.address.communication.email ? 'pr-2' : null},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-fax")]),_c('span',[_vm._v(_vm._s(_vm.address.communication.fax))]),(_vm.address.communication.email)?_c('span',[_vm._v(",")]):_vm._e()],1):_vm._e(),(_vm.address.communication.email)?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-email")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":("mailto:" + (_vm.address.communication.email))}},[_vm._v(_vm._s(_vm.address.communication.email))])],1):_vm._e()]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }