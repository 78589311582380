var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GlobalTable',_vm._g(_vm._b({attrs:{"items":_vm.items,"headers":_vm.computedHeaders,"actions":_vm.computedActions,"title":_vm.$t('title'),"isLoading":!_vm.items ? _vm.isLoading : false,"sort-by":_vm.computedOptions.sortBy,"sort-desc":_vm.computedOptions.sortDesc,"server-items-length":_vm.logsTotalCount,"hideBtnAdd":"","hideSearch":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.computedOptions, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.computedOptions, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.computedOptions, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.computedOptions, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.levelNr",fn:function(ref){
var item = ref.item;
return [_vm._t("levelNr",function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.levelNr === 10)?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-debug")]):_vm._e(),(item.levelNr === 20)?_c('v-icon',_vm._g({attrs:{"color":"info"}},on),[_vm._v("mdi-information")]):_vm._e(),(item.levelNr === 30)?_c('v-icon',_vm._g({attrs:{"color":"yellow"}},on),[_vm._v("mdi-alert-circle")]):_vm._e(),(item.levelNr === 40)?_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-alert")]):_vm._e(),(item.levelNr === 50)?_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("mdi-alert")]):_vm._e()]}}],null,true)},[_c('span',[_c('div',[_vm._v(_vm._s(item.levelNr))]),_c('div',[_vm._v(_vm._s(item.levelName))])])])]},{"item":item})]}},{key:"item.logMsg",fn:function(ref){
var item = ref.item;
return [_vm._t("logMsg",function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-message")])]}}],null,true)},[_c('span',[_c('div',[_vm._v(_vm._s(item.logMsg))])])])]},{"item":item})]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._t("timestamp",function(){return [(item.timestamp)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-check")]),_c('base-date-time',{attrs:{"timestamp":item.timestamp}})],1):_vm._e()]},{"item":item})]}},{key:"item.moduleName",fn:function(ref){
var item = ref.item;
return [_vm._t("moduleName",function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_c('div',[_vm._v("Module Name: "+_vm._s(item.moduleName))]),_c('div',[_vm._v("Module Path Name: "+_vm._s(item.modulePathName))]),_c('div',[_vm._v("Line Nr.: "+_vm._s(item.lineNr))])])])]},{"item":item})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._t("user",function(){return [(item.user)?_c('div',[(item.user.firstName)?_c('span',[_vm._v(_vm._s(item.user.firstName))]):_vm._e(),(item.user.familyName)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.user.familyName))]):_vm._e()]):_vm._e()]},{"item":item})]}}],null,true)},'GlobalTable',_vm.$attrs,false),_vm.$listeners),[_c('template',{slot:"no-data"},[_vm._t("no-data",function(){return [_c('span',[_c('p',{staticClass:"pt-2 blue--text subheading"},[_c('v-icon',{staticClass:"blue--text",attrs:{"medium":""}},[_vm._v(" mdi-information ")]),_vm._v(" "+_vm._s(_vm.$t("no-data-msg"))+" ")],1)])]})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }