var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.gotchas)?[(!_vm.searchVisible)?_c('v-row',[_c('v-col',[(_vm.isFilteredSearch)?_c('base-btn',{attrs:{"type":"secondary"},on:{"click":_vm.reset}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-filter-remove ")]),_vm._v(" "+_vm._s(_vm.$t("btn-filter-clear"))+" ")],1):_vm._e()],1)],1):_vm._e(),(_vm.searchVisible)?_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-text-field',{attrs:{"clearable":"","label":_vm.$t('search-label'),"prepend-inner-icon":"mdi-clipboard-text-search","single-line":"","hide-details":""},model:{value:(_vm.searchModel),callback:function ($$v) {_vm.searchModel=$$v},expression:"searchModel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.filteredTags.length > 0)?[_c('span',[_vm._v(_vm._s(_vm.$t("filter-title")))]),(_vm.$vuetify.breakpoint.xs)?_c('v-combobox',{attrs:{"items":_vm.filteredTags,"label":"Tags","multiple":"","small-chips":"","chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item),attrs:{"input-value":data.selected,"disabled":data.disabled},on:{"click:close":function($event){return data.parent.selectItem(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{staticClass:"primary white--text",attrs:{"left":""},domProps:{"textContent":_vm._s(
                                            _vm.$t(data.item)
                                                .slice(0, 1)
                                                .toUpperCase()
                                        )}}),_vm._v(" "+_vm._s(_vm.$t(data.item))+" ")],1)]}},{key:"item",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item))+" ")]}}],null,false,2427729051),model:{value:(_vm.selectedChips),callback:function ($$v) {_vm.selectedChips=$$v},expression:"selectedChips"}}):_vm._e(),(!_vm.$vuetify.breakpoint.xs)?_c('v-chip-group',{attrs:{"column":"","multiple":""},model:{value:(_vm.selectedChips),callback:function ($$v) {_vm.selectedChips=$$v},expression:"selectedChips"}},_vm._l((_vm.filteredTags),function(item){return _c('v-chip',{key:item,attrs:{"color":_vm.selectedChips.indexOf(item) > -1
                                        ? 'green lighten-2'
                                        : '',"filter":"","value":item}},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])}),1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider'):_vm._e()]:_vm._e()],2)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"mb-12",attrs:{"cols":"12"}},[_c('v-data-iterator',{ref:"gotchasFilter",attrs:{"items":_vm.filtered,"search":_vm.searchModel,"disable-pagination":"","hide-default-footer":"","no-results-text":_vm.$t('no-results-found')},on:{"update:search":function($event){_vm.searchModel=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                        var gotcha = ref.item;
return [_c('section',{staticClass:"mb-12",attrs:{"id":gotcha.id}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(gotcha.q)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(gotcha.q)}})]):_vm._e()])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('FaqBtnShare',{attrs:{"gotcha":gotcha}}),_vm._l((gotcha.tags),function(tag,i){return _c('v-chip',{key:i,staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$t(tag))+" ")])})],2)],1),_c('v-card',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('Markdown',{attrs:{"code":gotcha.d}}),_vm._l((gotcha.p),function(gotchaP){return [(gotcha.p)?_c('div',{key:gotchaP.t},[_c('p',{domProps:{"innerHTML":_vm._s(gotchaP.t)}}),(gotchaP.u)?_c('Markdown',{attrs:{"code":_vm.getAsArray('u', gotchaP.u)}}):_vm._e()],1):_vm._e()]}),(gotcha.o)?_c('Markdown',{attrs:{"code":gotcha.o}}):_vm._e(),(gotcha.u)?_c('Markdown',{attrs:{"code":gotcha.u}}):_vm._e(),_c('Photoswipe',{attrs:{"bubble":""}},[(
                                            gotcha.i &&
                                            _vm.getAsArray('i', gotcha.i).i
                                                .length > 1
                                        )?_c('v-row',[_vm._l((gotcha.i),function(gotchaI,i){return [(gotcha.i)?_c('v-col',{key:i,attrs:{"cols":"12","xs":"12","md":_vm.getAsArray(
                                                        'i',
                                                        gotcha.i
                                                    ).i.length === 2
                                                        ? 6
                                                        : 4}},[(gotchaI.path)?_c('v-img',{directives:[{name:"pswp",rawName:"v-pswp",value:(
                                                        require(("@/assets/" + (gotchaI.path)))
                                                    ),expression:"\n                                                        require(`@/assets/${gotchaI.path}`)\n                                                    "}],staticClass:"align-end",attrs:{"src":require(("@/assets/" + (gotchaI.path))),"lazy-src":require(("@/assets/" + (gotchaI.path))),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)","aspect-ratio":"1"}}):_c('v-img',{directives:[{name:"pswp",rawName:"v-pswp",value:(gotchaI),expression:"gotchaI"}],attrs:{"src":gotchaI,"lazy-src":gotchaI,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)","max-height":"510","aspect-ratio":"1.7"}})],1):_vm._e()]})],2):_vm._e(),(
                                            gotcha.i &&
                                            _vm.getAsArray('i', gotcha.i).i
                                                .length === 1
                                        )?[(gotcha.i[0].path)?_c('v-img',{directives:[{name:"pswp",rawName:"v-pswp",value:(
                                                require(("@/assets/" + (gotcha.i[0].path)))
                                            ),expression:"\n                                                require(`@/assets/${gotcha.i[0].path}`)\n                                            "}],attrs:{"src":require(("@/assets/" + (gotcha.i[0].path))),"lazy-src":require(("@/assets/" + (gotcha.i[0].path))),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)","max-height":"510","aspect-ratio":"1.7"}}):_c('v-img',{directives:[{name:"pswp",rawName:"v-pswp",value:(gotcha.i[0]),expression:"gotcha.i[0]"}],attrs:{"src":gotcha.i[0],"lazy-src":gotcha.i[0],"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)","max-height":"510","aspect-ratio":"1.7"}})]:_vm._e()],2)],2)],1)]}}],null,false,2058908269)})],1)],1)]:[_c('base-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("faq-not-available-in-this-language"))+" ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }