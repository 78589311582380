<i18n src="./login-i18n.yaml"></i18n>
<template>
    <v-container>
        <div v-if="$route.query.redirect" class="pl-2 text-h4">
            {{ $t("login-view-title") }}
        </div>
        <Login :queryParams="$route.query" />
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Login from "@/components/auth/login.vue";
export default {
    name: "LoginView",
    components: {
        Login
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        })
    }
};
</script>
