<i18n src="./summary-address-bill-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-billing-address')" :expandable="expandable">
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li>
                    <DisplayAddressInline
                        v-if="
                            !checkIfAddressIsEmpty(task.billingAddress) ||
                            isSameAddressAsOperator(task.billingAddress)
                        "
                        :showSameAsOperator="
                            isSameAddressAsOperator(task.billingAddress)
                        "
                        :address="task.billingAddress"
                    >
                    </DisplayAddressInline>
                    <span v-else class="font-italic">{{
                        $t("not-recorded-yet")
                    }}</span>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import mixin from "../mixin";
import mixinSameAsOperator from "./mixin-same-as-operator";
export default {
    name: "SummaryAddressBill",
    mixins: [mixin, mixinSameAsOperator]
};
</script>
