var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalTable',{attrs:{"items":_vm.specialistCompanies,"options":_vm.tableOptions,"headers":_vm.headers,"actions":_vm.actionsItems,"title":_vm.$t('title'),"funcShowAction":_vm.showAction,"funcRowClasses":_vm.rowClasses,"search":_vm.search,"btnAddText":_vm.$t('btn-add-company'),"isLoading":_vm.isLoading,"sort-by":['deletedState', 'active', 'name'],"sort-desc":[false, true, false],"multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:action":_vm.onActionClick,"options:change":function (options) {
                Object.assign(_vm.tableOptions, options);
            },"click:add":_vm.onAdd,"click:reload":_vm.fetchAllCompanies,"input:search":function($event){_vm.search = $event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"pt-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(item.deletedState > 0)?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-delete")]):(item.active)?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-check-circle")]):_c('v-icon',_vm._g({attrs:{"color":"grey"}},on),[_vm._v("mdi-close-circle")])]}}],null,true)},[_c('span',[(item.deletedState > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-deleted"))+" ")]):(item.active)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-enabled"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-disabled"))+" ")])])])],1)]}},{key:"item.country",fn:function(ref){
            var item = ref.item;
return [(item.country)?_c('div',{staticClass:"pt-3"},[_c('country-flag',{attrs:{"country":item.country}})],1):_vm._e()]}}])}),_c('DialogAddress',{attrs:{"value":_vm.dialogAddress,"requiredFields":_vm.formAddressRequiredFields,"title":_vm.computedTitle,"address":_vm.address},on:{"input":function($event){_vm.dialogAddress = $event},"save":_vm.onSaveAddress,"address:input":function (newAddress) {
                _vm.address = newAddress;
            },"address:reset":_vm.onResetAddress}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }