<template>
    <v-badge bordered inline>
        <template v-slot:badge>
            <span class="font-weight-black">DEV</span>
        </template>
        <v-icon
            v-if="icon"
            v-html="icon"
            color="regOfficePrimary"
        ></v-icon>
    </v-badge>
</template>

<script>
export default {
    name: "DevBadge",
    props: {
        icon: {
            type: String,
            default: null
        }
    }
};
</script>
