import { render, staticRenderFns } from "./App.vue?vue&type=template&id=53a6c54f&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./lang/defaults-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2FApp.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./App-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2FApp.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
