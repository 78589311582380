<i18n src="@/data/countries/i18n/all.json"></i18n>
<i18n src="./summary-new-address-i18n.yaml"></i18n>
<template>
    <BaseListItem :expandable="expandable" v-if="newAddressExists">
        <template v-slot:title>
            <span
                v-if="
                    task.deregistrationReason ===
                    DEREGISTRATION_REASONS.LOCATION_CHANGED
                "
            >
                {{ $t("label-new-address-location") }}
            </span>
            <span
                v-else-if="
                    task.deregistrationReason === DEREGISTRATION_REASONS.SOLD
                "
            >
                {{ $t("label-new-address") }}
            </span>
        </template>
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li>
                    <app-key-value-text-inline
                        class="align-flag-with-text"
                        :keyText="$t('new-address-country-label-label')"
                    >
                        <template v-slot:valueText>
                            <country-flag
                                size="small"
                                :country="task.newAddress.country"
                            />
                            {{
                                getCountryTranslatedByIsoCode(
                                    task.newAddress.country
                                )
                            }}
                        </template>
                    </app-key-value-text-inline>
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import { getCountriesListByLocale } from "@/data/countries";
import mixin from "../mixin";
import { DEREGISTRATION_REASONS } from "@/data/globals.js";
export default {
    name: "SummaryNewAddress",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    data: () => ({
        DEREGISTRATION_REASONS
    }),
    computed: {
        newAddressExists() {
            return this.task?.newAddress && this.task?.newAddress?.country;
        },
        countriesList() {
            return getCountriesListByLocale(this.$root.$i18n.locale);
        }
    },
    methods: {
        getCountryTranslatedByIsoCode(isoCode) {
            return Object.keys(this.countriesList).length > 0 &&
                this.countriesList[isoCode.toUpperCase()]
                ? this.countriesList[isoCode.toUpperCase()]
                : isoCode;
        }
    }
};
</script>

<style scoped>
.align-flag-with-text {
    display: flex;
    column-gap: 6px;
    align-items: center;
    flex-wrap: wrap;
}
</style>
