<template>
    <v-container fluid>
        <v-row class="pb-1" align="start" v-if="title">
            <v-col>
                <div
                    :class="
                        $vuetify.breakpoint.mdAndDown ? 'text-h4' : 'text-h3'
                    "
                >
                    {{ title }}
                </div>
            </v-col>
        </v-row>
        <v-row v-if="subtitle" class="pb-1" align="start">
            <v-col>
                <div
                    :class="
                        $vuetify.breakpoint.mdAndDown
                            ? 'subtitle-1'
                            : 'subtitle-2'
                    "
                >
                    {{ subtitle }}
                </div>
            </v-col>
        </v-row>
        <v-row v-if="isLoading">
            <v-col cols="12">
                <v-sheet>
                    <v-skeleton-loader class="mx-auto" type="table">
                    </v-skeleton-loader>
                </v-sheet>
            </v-col>
        </v-row>
        <template v-else>
            <slot name="prepend" />
            <slot name="table" />
        </template>
    </v-container>
</template>
<script>
export default {
    name: "ViewTableList",
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    }
};
</script>
