<i18n src="./dialog-contact-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="700"
        ref="dialogContact"
        icon="mdi-account-question"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <template v-slot:toolbar-items>
            <base-btn
                v-if="developerMode"
                icon
                color="warning"
                @click="setFakeData"
            >
                <v-icon>mdi-bug</v-icon>
            </base-btn>
        </template>
        <template v-slot:content>
            <v-container>
                <form>
                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
                        <v-col cols="12" md="2">
                            <base-select
                                :items="salutationItems"
                                v-model="salutation"
                                :label="$t('salutation-label')"
                                item-value="key"
                                :error-messages="salutationErrors"
                                @change="onValidateFields"
                                :required="isRequiredField($v.salutation)"
                                single-line
                                :item-text="
                                    (item) => $t(item.value.title[$i18n.locale])
                                "
                            >
                            </base-select>
                        </v-col>
                        <v-col cols="12" md="5">
                            <!-- firstName -->
                            <base-text-field
                                v-model="firstName"
                                :label="$t('firstname-label')"
                                :error-messages="firstNameErrors"
                                clearable
                                :counter="$v.firstName.$params.maxLength.max"
                                @input="onValidateFields"
                                :required="isRequiredField($v.firstName)"
                            ></base-text-field>
                        </v-col>
                        <v-col cols="12" md="5">
                            <!-- familyName -->
                            <base-text-field
                                v-model="familyName"
                                :label="$t('familyname-label')"
                                :error-messages="familyNameErrors"
                                clearable
                                :counter="$v.familyName.$params.maxLength.max"
                                @input="onValidateFields"
                                :required="isRequiredField($v.familyName)"
                            ></base-text-field>
                        </v-col>
                    </v-row>
                    <base-text-field
                        v-model="email"
                        name="email"
                        :error-messages="emailErrors"
                        :label="$t('email-label')"
                        :required="isRequiredField($v.email)"
                    ></base-text-field>

                    <base-select
                        v-model="reason"
                        name="reason"
                        :items="reasonItems"
                        :error-messages="reasonErrors"
                        :label="$t('reason-label')"
                        @input="onValidateFields"
                        :required="isRequiredField($v.reason)"
                        :item-text="(item) => $t(`reason-${item.value}`)"
                    >
                    </base-select>

                    <base-textarea
                        v-model="message"
                        name="message"
                        :error-messages="messageErrors"
                        :label="$t('message-label')"
                        clearable
                        :counter="$v.message.$params.maxLength.max"
                        :required="isRequiredField($v.message)"
                    ></base-textarea>
                </form>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn type="secondary" @click="closeDialog">{{
                $t("btn-close")
            }}</base-btn>
            <base-btn type="primary" @click="submit">{{
                $t("btn-submit")
            }}</base-btn>
        </template>
    </base-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { required, maxLength, email } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
export default {
    name: "DialogContact",
    mixins: [validateMixin],
    validations: {
        salutation: { required },
        firstName: { required, maxLength: maxLength(50) },
        familyName: { required, maxLength: maxLength(50) },
        email: { required, email },
        reason: { required },
        message: { required, maxLength: maxLength(500) }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        salutation: "",
        firstName: "",
        familyName: "",
        email: "",
        reason: null,
        reasonItems: [
            {
                value: 1
            },
            {
                value: 2
            }
        ],
        message: ""
    }),
    methods: {
        ...mapActions("attributes", ["fetchSalutationsAttributes"]),
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        closeDialog() {
            this.clear();
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        scrollToFirstErrorWithScrollOptions() {
            let dialogContactRef = this.$refs.dialogContact;
            let baseDialogCardTextRef =
                dialogContactRef.$refs.baseDialogCardText;
            let scrollOptions = {
                container: baseDialogCardTextRef
            };
            this.scrollToFirstError(scrollOptions);
        },
        submit() {
            this.validate();
            if (!this.$v.$anyError) {
                this.loader().show();
                let data = this.getData();
                data.registrationOffice = this.registrationOffice;
                data.lang = this.$i18n.locale;
                this.$axiosMail
                    .post("/sendContactForm", data)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            this.$toast.success(this.$t("email-sent-success"));
                        }
                        this.loader().hide();
                        this.closeDialog();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(
                            this.$t("email-sent-error", { code: error })
                        );
                        this.loader().hide();
                    });
            } else {
                this.scrollToFirstErrorWithScrollOptions();
            }
        },
        clear() {
            this.$v.$reset();
            this.salutation = "";
            this.firstName = "";
            this.familyName = "";
            this.email = "";
            this.reason = null;
            this.message = "";
        },
        getData() {
            let data = {};
            data.salutation = this.salutation;
            data.firstName = this.firstName;
            data.familyName = this.familyName;
            data.email = this.email;
            data.reason = this.reason;
            data.message = this.message;
            return data;
        },
        setFakeData() {
            this.salutation = "0002";
            this.firstName = "Ricardo";
            this.familyName = "Coelho";
            this.email = "coelho@was.ch";
            this.reason = 1;
            this.message =
                "Das ist ein Nachricht von Suva/SVTI Kontaktformular";
        }
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        ...mapGetters("auth", [
            "accountDocId",
            "userSalutation",
            "userFirstname",
            "userFamilyname",
            "mail"
        ]),
        ...mapGetters({
            developerMode: "settings/developerMode",
            salutations: "attributes/salutations",
            registrationOffice: "settings/getRegistrationOffice"
        }),
        ...mapGetters("settings", {}),
        salutationItems() {
            return this.salutations;
        },
        salutationErrors() {
            const errors = [];
            if (!this.$v.salutation.$dirty) {
                return errors;
            }
            if (!this.$v.salutation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        firstNameErrors() {
            const errors = [];
            if (!this.$v.firstName.$dirty) {
                return errors;
            }
            if (!this.$v.firstName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.firstName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.firstName.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        familyNameErrors() {
            const errors = [];
            if (!this.$v.familyName.$dirty) {
                return errors;
            }
            if (!this.$v.familyName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.familyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.familyName.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.reason.$dirty) {
                return errors;
            }
            if (!this.$v.reason.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        messageErrors() {
            const errors = [];
            if (!this.$v.message.$dirty) {
                return errors;
            }
            if (!this.$v.message.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) {
                return errors;
            }
            if (!this.$v.email.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.email.email) {
                errors.push(this.$t("field-invalid-email"));
            }
            return errors;
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                this.dialog = newVal;
                if (this.isAuth) {
                    if (this.mail) {
                        this.email = atob(this.mail);
                    }
                    if (this.userSalutation) {
                        this.salutation = this.userSalutation;
                    }
                    if (this.userFirstname) {
                        this.firstName = this.userFirstname;
                    }
                    if (this.userFamilyname) {
                        this.familyName = this.userFamilyname;
                    }
                }
            },
            immediate: true
        }
    },
    created() {
        this.loader().show();
        this.fetchSalutationsAttributes()
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(this.$t("load-salutations-list-error"));
            });
    }
};
</script>
