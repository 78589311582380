<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./form-user-account-i18n.yaml"></i18n>
<template>
    <v-form :disabled="disableAllFields">
        <v-container fluid class="pa-0 ma-0">
            <v-row>
                <v-col cols="12" md="2">
                    <base-select
                        :items="salutations"
                        v-model="userData.salutation"
                        :label="$t('salutation-label')"
                        item-value="key"
                        :error-messages="salutationErrors"
                        disabled
                        :required="isRequiredField($v.userData.salutation)"
                        :item-text="
                            (item) => $t(item.value.title[$i18n.locale])
                        "
                    >
                    </base-select>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                    <base-text-field
                        v-model="userData.firstname"
                        :error-messages="firstNameErrors"
                        :counter="$v.userData.firstname.$params.maxLength.max"
                        :label="$t('firstname-name-label')"
                        disabled
                        :required="isRequiredField($v.userData.firstname)"
                    ></base-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                    <base-text-field
                        v-model="userData.familyname"
                        disabled
                        :error-messages="familyNameErrors"
                        :counter="$v.userData.familyname.$params.maxLength.max"
                        :label="$t('familyname-label')"
                        :required="isRequiredField($v.userData.familyname)"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <base-select
                        :items="computedRolesItems"
                        v-model="userData.roles"
                        :label="$t('roles-label')"
                        item-value="value"
                        :error-messages="rolesErrors"
                        multiple
                        :menu-props="{ maxHeight: '400' }"
                        :required="isRequiredField($v.userData.roles)"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon
                                v-html="getIconAndColorByRole(item.value).icon"
                                :color="getIconAndColorByRole(item.value).color"
                            ></v-icon>
                            {{ $t(`account-role-${item.value}`) }}
                        </template>
                        <template v-slot:selection="{ item }">
                            <v-icon
                                v-html="getIconAndColorByRole(item.value).icon"
                                :color="getIconAndColorByRole(item.value).color"
                            ></v-icon>
                        </template>
                    </base-select>
                </v-col>
                <v-col cols="12" md="6">
                    <base-select
                        :items="computedLangItems"
                        filled
                        v-model="userData.lang"
                        :label="$t('lang-label')"
                        item-value="value"
                        :error-messages="langErrors"
                        multiple
                        disabled
                        :menu-props="{ maxHeight: '400' }"
                        :required="isRequiredField($v.userData.lang)"
                    >
                        <template v-slot:item="{ item }">
                            <span v-if="item.value">
                                <country-flag
                                    size="small"
                                    :country="item.value"
                                />
                                {{ $t(`lang-${item.value.toUpperCase()}`) }}
                            </span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span v-if="item.value">
                                <country-flag
                                    size="small"
                                    :country="item.value"
                                />
                                {{ $t(`lang-${item.value.toUpperCase()}`) }}
                            </span>
                        </template>
                    </base-select>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import { maxLength, requiredIf } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import { getIconAndColorByRole } from "@/utils/defaults.js";
import { ACCOUNT_ROLE_LIST, ACCOUNT_ROLE } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
export default {
    name: "FormUserAccount",
    mixins: [validateMixin],
    validations() {
        return {
            userData: {
                salutation: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("salutation");
                    })
                },
                firstname: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("firstname");
                    }),
                    maxLength: maxLength(50)
                },
                familyname: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("familyname");
                    }),
                    maxLength: maxLength(50)
                },
                lang: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("lang");
                    })
                },
                roles: {
                    required: requiredIf(function () {
                        return this.requiredFields.includes("roles");
                    })
                }
            }
        };
    },
    props: {
        requiredFields: {
            type: Array,
            default: () => []
        },
        userData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        roles: ACCOUNT_ROLE_LIST,
        disableAllFields: false
    }),
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        getIconAndColorByRole,
        onFieldChange(field, value) {
            this.$emit("field:change", { field, value });
        },
        reset() {
            this.$v.$reset();
            this.$emit("reset");
        },
        setAllDisabled(val) {
            this.disableAllFields = val;
        }
    },
    computed: {
        ...mapGetters({
            salutations: "attributes/salutations"
        }),
        computedRolesItems() {
            return this.roles.map((item) => {
                let roleItem = {
                    text: this.$t(`account-role-${item}`),
                    value: item
                };
                if (item === ACCOUNT_ROLE.REGISTRANT) {
                    roleItem["disabled"] = true;
                }
                return roleItem;
            });
        },
        computedLangItems() {
            let availableLocales = this.$i18n.availableLocales || [];
            return availableLocales.map((locale) => {
                let langItem = {
                    text: this.$t(`lang-${locale}`),
                    value: locale
                };
                return langItem;
            });
        },
        salutationErrors() {
            const errors = [];
            if (!this.$v.userData.salutation.$dirty) {
                return errors;
            }

            if (!this.$v.userData.salutation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        firstNameErrors() {
            const errors = [];
            if (!this.$v.userData.firstname.$dirty) {
                return errors;
            }
            if (!this.$v.userData.firstname.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.userData.firstname.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.userData.firstname.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        familyNameErrors() {
            const errors = [];
            if (!this.$v.userData.familyname.$dirty) {
                return errors;
            }
            if (!this.$v.userData.familyname.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.userData.familyname.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        langErrors() {
            const errors = [];
            if (!this.$v.userData.lang.$dirty) {
                return errors;
            }
            if (!this.$v.userData.lang.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.userData.lang.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.userData.lang.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        rolesErrors() {
            const errors = [];
            if (!this.$v.userData.roles.$dirty) {
                return errors;
            }
            if (!this.$v.userData.roles.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.userData.roles.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    },
    created() {
        this.loader().show();
        let salutationsKey = "salutations";
        this.getStoredAttributesByKey(salutationsKey)
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    "Laden der Daten: " + salutationsKey + " war nicht möglich"
                );
            });
    }
};
</script>
