import { render, staticRenderFns } from "./summary-data-backoffice.vue?vue&type=template&id=a4bbbe76&"
import script from "./summary-data-backoffice.vue?vue&type=script&lang=js&"
export * from "./summary-data-backoffice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./summary-data-backoffice-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fcommon%2Fsummary%2Fsummary-data-backoffice.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports