<i18n       src="./uid-search-i18n.yaml"></i18n>
<template>
    <v-container fluid class="pa-0 ma-0" v-if="!readonlyModus">
        <v-row>
            <v-col>
                <v-card outlined tile flat id="searchCard">
                    <v-card-title>
                        {{ $t("card-title") }}
                        <base-btn
                            class="pl-3"
                            icon
                            @click="showFaq('uidSearch')"
                        >
                            <v-icon left>mdi-information-outline</v-icon>
                        </base-btn>
                        <v-spacer></v-spacer>
                        <base-btn
                            icon
                            @click="expandSearchCard = !expandSearchCard"
                        >
                            <v-icon>{{
                                expandSearchCard
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                            }}</v-icon>
                        </base-btn>
                    </v-card-title>
                    <v-expand-transition>
                        <div v-show="expandSearchCard">
                            <v-divider></v-divider>
                            <v-card-text>
                                <base-alert dense type="info">
                                    {{ $t("search-info-text") }}
                                    <ul>
                                        <li
                                            v-html="
                                                $t('search-info-text-list-1')
                                            "
                                        ></li>
                                        <li
                                            v-html="
                                                $t('search-info-text-list-2')
                                            "
                                        ></li>
                                    </ul>
                                </base-alert>
                                <v-container
                                    fill-height
                                    fluid
                                    class="pa-0 ma-0"
                                >
                                    <v-row>
                                        <v-col>
                                            <base-text-field
                                                v-model="searchName"
                                                :label="$t('search-name-label')"
                                                @click:clear="reset"
                                                @keydown="
                                                    onSearchNameInputKeydown
                                                "
                                                @input="
                                                    btnSearchDisabled = false
                                                "
                                                clearable
                                                :required="expandSearchCard"
                                                @blur="
                                                    onSearchParamsFieldChange({
                                                        field: 'searchName',
                                                        value: $event.target
                                                            .value
                                                    })
                                                "
                                            >
                                            </base-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="developerMode">
                                        <v-col>
                                            <v-switch
                                                class="ml-1 pa-0 ma-0"
                                                color="orange"
                                                v-model="switchDevSearch"
                                                :label="
                                                    $t(
                                                        'switch-dev-search-label'
                                                    )
                                                "
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-switch
                                                class="ml-1 pa-0 ma-0"
                                                v-model="switchAdvancedSearch"
                                                :label="
                                                    $t(
                                                        'switch-advanced-search-label'
                                                    )
                                                "
                                                hide-details
                                                @change="
                                                    onSearchParamsFieldChange({
                                                        field: 'switchAdvancedSearch',
                                                        value: $event
                                                    })
                                                "
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-expand-transition
                                    v-if="switchAdvancedSearch"
                                >
                                    <v-container class="pa-0 ma-0 pt-4">
                                        <v-row>
                                            <v-col>
                                                <w-location-search
                                                    v-model="locationSearch"
                                                    :country="country"
                                                    :required="
                                                        switchAdvancedSearch
                                                    "
                                                    @reset="
                                                        onWLocationSearchReset
                                                    "
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="10"
                                                class="py-1"
                                            >
                                                <base-text-field
                                                    v-model="
                                                        advancedSearchStreet
                                                    "
                                                    hide-details
                                                    :label="$t('street-label')"
                                                    @input="
                                                        onSearchParamsFieldChange(
                                                            {
                                                                field: 'advancedSearchStreet',
                                                                value: $event
                                                            }
                                                        )
                                                    "
                                                ></base-text-field>
                                            </v-col>
                                            <v-col
                                                class="py-1"
                                                cols="12"
                                                md="2"
                                            >
                                                <base-text-field
                                                    v-model="
                                                        advancedSearchHouseNr
                                                    "
                                                    hide-details
                                                    :label="
                                                        $t('house-nr-label')
                                                    "
                                                    @input="
                                                        onSearchParamsFieldChange(
                                                            {
                                                                field: 'advancedSearchHouseNr',
                                                                value: $event
                                                            }
                                                        )
                                                    "
                                                ></base-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-expand-transition>
                            </v-card-text>
                            <v-card-actions class="pt-5">
                                <v-row class="mx-3 my-3">
                                    <base-btn
                                        :disabled="
                                            (searchName == null &&
                                                !btnSearchDisabled) ||
                                            (btnSearchDisabled &&
                                                searchName != null)
                                        "
                                        @click="onBtnFetchDataClick"
                                        type="primary"
                                    >
                                        <v-icon left
                                            >mdi-database-search</v-icon
                                        >
                                        <div>
                                            {{ $t("btn-search") }}
                                        </div>
                                    </base-btn>
                                    <v-spacer></v-spacer>
                                    <base-btn
                                        v-if="
                                            searchableAddress &&
                                            (btnSearchDisabled ||
                                                searchName == null)
                                        "
                                        type="secondary"
                                        @click="setSearchAddress"
                                    >
                                        <v-icon left
                                            >mdi-database-search</v-icon
                                        >
                                        <span
                                            v-if="$vuetify.breakpoint.smAndUp"
                                        >
                                            {{ $t("btn-take-address") }}
                                        </span>
                                    </base-btn>
                                </v-row>
                            </v-card-actions>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container
                    fill-height
                    fluid
                    no-gutters
                    class="pt-5 px-0"
                    v-show="result.length >= 1"
                >
                    <base-alert
                        width="100%"
                        v-if="errors != null"
                        tile
                        type="error"
                    >
                        {{ errors }}
                    </base-alert>
                    <base-alert
                        v-if="result.length >= 1"
                        width="100%"
                        dense
                        type="info"
                        color="info"
                    >
                        <i18n
                            v-if="switchAdvancedSearch"
                            path="search-result-info-2"
                            tag="div"
                        >
                            <template v-slot:possibleHits>
                                <b>{{
                                    $tc(
                                        "possible-hits",
                                        sortedResultItems.length,
                                        { count: sortedResultItems.length }
                                    )
                                }}</b>
                            </template>
                        </i18n>
                        <i18n v-else path="search-result-info-1" tag="div">
                            <template v-slot:possibleHits>
                                <b>{{
                                    $tc(
                                        "possible-hits",
                                        sortedResultItems.length,
                                        { count: sortedResultItems.length }
                                    )
                                }}</b>
                            </template>
                            <template v-slot:advancedSearch>
                                <a
                                    class="info--text text-decoration-underline"
                                    @click="gotoAdvancedSearch"
                                    >{{ $t("advanced-search") }}</a
                                >
                            </template>
                        </i18n>
                    </base-alert>
                    <v-data-table
                        style="width: 100%"
                        v-model="selected"
                        :headers="computedHeaders"
                        :items-per-page.sync="itemsPerPage"
                        :items="sortedResultItems"
                        hide-default-footer
                        item-key="name"
                        ref="dataTable"
                    >
                        <template v-slot:body="{ items }">
                            <tbody v-if="$vuetify.breakpoint.smAndUp">
                                <tr
                                    style="cursor: pointer"
                                    v-for="(item, idx) in items"
                                    :key="idx"
                                    @click="onShowInfoClick(item)"
                                >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.street }}</td>
                                    <td>{{ item.houseNr }}</td>
                                    <td>{{ item.zipCode }}</td>
                                    <td>{{ item.city }}</td>
                                    <td>{{ item.canton }}</td>
                                </tr>
                            </tbody>
                            <div v-else>
                                <v-card
                                    v-for="(item, idx) in items"
                                    :key="idx"
                                    @click="onShowInfoClick(item)"
                                    flat
                                >
                                    <v-card-text class="py-1">
                                        {{ item.name }}
                                        <div>
                                            {{ item.street }} {{ item.houseNr }}
                                        </div>
                                        <div>
                                            {{ item.zipCode }} {{ item.city }}
                                            {{ item.canton }}
                                        </div>
                                        <div>{{ item.uidFormatted }}</div>
                                    </v-card-text>
                                    <v-divider class="pa-0 ma-0"></v-divider>
                                </v-card>
                            </div>
                        </template>
                    </v-data-table>
                    <base-alert
                        v-if="result.length >= 1"
                        width="100%"
                        class="mt-5"
                        dense
                        type="info"
                        color="info"
                    >
                        <i18n
                            v-if="!switchAdvancedSearch"
                            path="alert-try-advanced-search"
                            tag="div"
                        >
                            <template v-slot:advancedSearch>
                                <a
                                    class="info--text text-decoration-underline"
                                    @click="gotoAdvancedSearch"
                                    >{{ $t("advanced-search") }}</a
                                >
                            </template>
                        </i18n>
                        <i18n
                            v-if="switchAdvancedSearch && !hideCompanyNotFound"
                            path="alert-company-not-found"
                            tag="div"
                        >
                            <template v-slot:manually>
                                <a
                                    class="info--text text-decoration-underline"
                                    @click="setToManualEntry"
                                    >{{ $t("manually") }}</a
                                >
                            </template>
                        </i18n>
                    </base-alert>

                    <base-dialog
                        v-if="dialogNotFound != null"
                        :value="dialogNotFound"
                        @close="dialogNotFound = false"
                        @input="dialogNotFound = $event"
                        :title="$t('company-not-found-title')"
                        max-width="800"
                        icon="mdi-database-remove"
                    >
                        <template v-slot:content>
                            <v-container v-if="switchAdvancedSearch">
                                <base-radio-group
                                    v-model="manualEntryReason"
                                    :label="$t('company-not-found-title')"
                                >
                                    <v-radio
                                        v-for="reason in manualEntryReasons"
                                        :key="reason.value"
                                        :label="reason.label"
                                        :value="reason.value"
                                    ></v-radio>
                                </base-radio-group>
                                <base-textarea
                                    v-if="
                                        manualEntryReason ==
                                        'manualEntryReason4'
                                    "
                                    name="manualEntryReasonDescription"
                                    v-model="manualEntryReasonDescription"
                                    :label="
                                        $t('company-not-found-reason-04-label')
                                    "
                                    :required="
                                        manualEntryReason ==
                                        'manualEntryReason4'
                                    "
                                ></base-textarea>
                            </v-container>
                        </template>
                        <template v-slot:actions>
                            <v-spacer />
                            <base-btn
                                @click="dialogNotFound = false"
                                type="secondary"
                            >
                                {{ $t("btn-company-not-found-cancel") }}
                            </base-btn>
                            <base-btn
                                v-if="switchAdvancedSearch"
                                type="primary"
                                :disabled="!manualEntryReason"
                                @click="onActionDialogNotFount"
                            >
                                {{ $t("btn-company-not-found-confirm") }}
                            </base-btn>
                        </template>
                    </base-dialog>
                </v-container>

                <base-dialog
                    :value="dialogInfoItem"
                    @input="dialogInfoItem = $event"
                    :title="showInfoItem.uidFormatted"
                    v-if="showInfoItem != null"
                    max-width="400"
                    icon="mdi-information-outline"
                >
                    <template v-slot:content>
                        <v-card flat width="100%">
                            <v-card-title class="text-subtitle-1">
                                {{ $t("dialog-info-item-text") }}
                            </v-card-title>
                            <v-card-text>
                                <div class="justify-center text-body-1">
                                    <div>{{ showInfoItem.name }}</div>
                                    <div>
                                        {{ showInfoItem.street }}
                                        {{ showInfoItem.houseNr }}
                                    </div>
                                    <div>
                                        {{ showInfoItem.canton }}
                                        {{ showInfoItem.zipCode }}
                                        {{ showInfoItem.city }}
                                    </div>
                                    <div>{{ showInfoItem.uidFormatted }}</div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:actions>
                        <v-spacer />
                        <base-btn
                            @click="onConfirmSelectedCompany"
                            type="primary"
                        >
                            {{ $t("btn-confirm-selected-company-confirm") }}
                        </base-btn>
                    </template>
                </base-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "UidSearch",
    props: {
        closeSearchCard: {
            type: [Boolean, String],
            default: false
        },
        formData: {
            type: Object,
            default: () => {}
        },
        searchParams: {
            type: Object,
            default: () => {}
        },
        hideCompanyNotFound: {
            type: Boolean,
            default: false
        },
        searchableAddress: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        country: {
            type: String,
            default: "CH"
        }
    },
    data: (vm) => ({
        expandSearchCard: true,
        selectDialog: false,
        dialogNotFound: false,
        selectCheckbox: false,
        btnSearchDisabled: false,
        searchName: null,
        result: [],
        headers: [
            {
                text: vm.$t("header-name"),
                sortable: false,
                value: "name",
                minWidth: "200",
                isMobile: true
            },
            {
                text: vm.$t("header-street"),
                value: "street",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-house-nr"),
                value: "houseNr",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-zip-code"),
                value: "zipCode",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-city"),
                value: "city",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-canton"),
                value: "canton",
                align: "left",
                isMobile: true
            }
        ],
        page: 1,
        itemsPerPage: 30,
        selected: [],
        dialogInfoItem: false,
        selectedCompany: {},
        confirmSelectedCompany: false,
        showInfoItem: null,
        errors: null,
        listItem: 1,
        manualEntry: false,
        manualEntryReason: null,
        manualEntryReasonDescription: "",
        isMobile: false,
        switchDevSearch: false,
        advancedSearchCanton: null,
        advancedSearchZipCode: null,
        advancedSearchCity: null,
        advancedSearchStreet: null,
        advancedSearchHouseNr: null,
        switchAdvancedSearch: null,
        manualEntryReasons: [
            {
                value: "manualEntryReason1",
                label: vm.$t("company-not-found-reason-01")
            },
            {
                value: "manualEntryReason2",
                label: vm.$t("company-not-found-reason-02")
            },
            {
                value: "manualEntryReason3",
                label: vm.$t("company-not-found-reason-03")
            },
            {
                value: "manualEntryReason4",
                label: vm.$t("company-not-found-reason-04")
            }
        ]
    }),

    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true
            });
        }
    },
    mounted() {
        this.switchDevSearch = this.developerMode;
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    computed: {
        ...mapState("settings", ["developerMode"]),
        computedHeaders() {
            if (this.isMobile) {
                return this.headers.filter((header) => header.isMobile);
            }
            return this.headers;
        },
        sortedResultItems() {
            return this.result.filter((item) => item.status === 1);
        },
        locationSearch: {
            get() {
                let search = {};
                if (this.searchParams) {
                    search.zipCode = this.searchParams.zipCode || null;
                    search.city = this.searchParams.city || null;
                    search.canton = this.searchParams.canton || null;
                }
                return search;
            },
            set(val) {
                if (!val) {
                    this.onWLocationSearchReset();
                    return;
                }
                if (val.zipCode) {
                    this.advancedSearchZipCode = val.zipCode;
                    this.onSearchParamsFieldChange({
                        field: "advancedSearchZipCode",
                        value: val.zipCode
                    });
                }
                if (val.city) {
                    this.advancedSearchCity = val.city;
                    this.onSearchParamsFieldChange({
                        field: "advancedSearchCity",
                        value: val.city
                    });
                }
                if (val.canton) {
                    this.advancedSearchCanton = val.canton;
                    this.onSearchParamsFieldChange({
                        field: "advancedSearchCanton",
                        value: val.canton
                    });
                }
            }
        }
    },
    methods: {
        setToManualEntry() {
            this.manualEntry = true;
            this.dialogNotFound = true;
        },
        onSearchParamsFieldChange({ field, value }) {
            if (field) {
                this.$emit("field:change", { field, value });
            }
        },
        onWLocationSearchReset() {
            this.advancedSearchZipCode = null;
            this.advancedSearchCity = null;
            this.advancedSearchCanton = null;
        },
        getData() {
            let ret = {};
            ret.manualEntry = Boolean(this.manualEntry);
            ret.manualEntryReason = this.manualEntryReason;
            ret.manualEntryReasonDescription =
                this.manualEntryReasonDescription;
            ret.searchParams = {};
            ret.searchParams.searchName = this.searchName;
            ret.searchParams.canton = this.advancedSearchCanton;
            ret.searchParams.zipCode = String(this.advancedSearchZipCode);
            ret.searchParams.city = this.advancedSearchCity;
            ret.searchParams.street = this.advancedSearchStreet;
            ret.searchParams.houseNr = this.advancedSearchHouseNr;
            ret.searchParams.switchAdvancedSearch = this.switchAdvancedSearch;

            return ret;
        },
        setData(data) {
            if (data.manualEntry) {
                this.manualEntry = Boolean(data.manualEntry);
                this.expandSearchCard = false;
            }
            this.manualEntryReason = data.manualEntryReason;
            this.manualEntryReasonDescription =
                data.manualEntryReasonDescription;
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        setSearchAddress() {
            if (!this.searchableAddress) {
                return;
            } else {
                this.reset();
                let address = this.searchableAddress;
                this.switchAdvancedSearch = true;
                this.searchName = address.companyName;
                this.advancedSearchCanton = address.canton;
                this.advancedSearchZipCode = address.zipCode;
                this.advancedSearchCity = address.city;
                this.advancedSearchStreet = address.street;
                this.advancedSearchHouseNr = address.houseNr;
                this.btnSearchDisabled = true;
                this.onBtnFetchDataClick();
            }
        },
        gotoAdvancedSearch() {
            this.expandSearchCard = true;
            this.switchAdvancedSearch = true;
            this.$vuetify.goTo("#searchCard");
        },
        onSearchNameInputKeydown(keyboardEvent) {
            if (!this.searchName) {
                return;
            } else {
                if (keyboardEvent.keyCode === 13) {
                    this.onBtnFetchDataClick();
                }
            }
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
        onBtnFetchDataClick() {
            this.$emit("btn-fetch-data-click");
            this.loader().show();
            let data = {};
            data.name = this.searchName;
            data.advancedSearch = this.switchAdvancedSearch;
            data.devSearch = this.switchDevSearch;
            if (this.switchAdvancedSearch) {
                data.zipCode = this.advancedSearchZipCode;
                data.street = this.advancedSearchStreet;
                data.houseNr = this.advancedSearchHouseNr;
                data.city = this.advancedSearchCity;
            }

            this.$axiosUidApi
                .post("/", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let result = response.data.data;
                        this.result = result;
                        if (result.length === 0) {
                            this.$toast.error(this.$t("fetch-uid-api-error"));
                        }
                        this.$nextTick(() => {
                            this.$vuetify.goTo(this.$refs.dataTable);
                        });
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        setSampleData() {
            this.searchName = "was.ch";
            if (this.switchAdvancedSearch) {
                this.zipCode = "9000";
                this.city = "St. Gallen";
                this.street = "Vonwilstrasse";
                this.houseNr = "23";
            }
        },
        reset() {
            this.switchAdvancedSearch = false;
            this.searchName = null;
            this.advancedSearchZipCode = null;
            this.advancedSearchCity = null;
            this.advancedSearchStreet = null;
            this.advancedSearchHouseNr = null;
            this.selected = [];
            this.selectedUid = null;
            this.dialog = false;
            this.errors = null;
            this.result = [];
            this.btnSearchDisabled = false;
        },
        onInputSelectChange() {
            this.selectDialog = true;
        },
        onItemSelect(item) {
            this.$emit("item-select-change", item);
            this.selectCheckbox = true;
            this.selectDialog = false;
        },
        onShowInfoClick(item) {
            this.selectedCompany = item;
            this.dialogInfoItem = true;
            this.showInfoItem = item;
        },
        checkIfCheckboxIsDisabledByStatus(status) {
            if (!status) {
                return true;
            } else if (status == 1) {
                return false;
            } else if (status == 2) {
                return true;
            } else if (status == 3) {
                return true;
            }
        },
        showBtnDialogNotFound() {
            if (this.result.length >= this.itemsPerPage) {
                if (this.result.length / this.itemsPerPage == this.page) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        changeManualEntry() {
            this.$emit("change-manual-entry", this.getData());
            this.expandSearchCard = false;
            this.result = [];
        },
        onActionDialogNotFount() {
            this.changeManualEntry();
            this.dialogNotFound = false;
        },
        onConfirmSelectedCompany() {
            this.confirmSelectedCompany = true;
            this.dialogInfoItem = false;
            this.$emit("confirm-selected-company", this.selectedCompany);
            this.result = [];
            this.expandSearchCard = false;
        },
        setStatusExpandSearchCard(status) {
            if (status === true) {
                this.expandSearchCard = true;
            } else {
                this.expandSearchCard = false;
            }
        },
        setSearchParamsData(params) {
            this.advancedSearchCanton = params.canton;
            this.advancedSearchZipCode = params.zipCode;
            this.advancedSearchCity = params.city;
            this.advancedSearchStreet = params.street;
            this.advancedSearchHouseNr = params.houseNr;
            this.switchAdvancedSearch = params.switchAdvancedSearch;
            this.searchName = params.searchName;
        }
    },

    watch: {
        closeSearchCard: {
            handler: function (newVal) {
                this.expandSearchCard = !newVal;
            },
            immediate: true
        },
        formData: {
            handler: function (newData) {
                if (newData) {
                    this.setData(newData);
                }
            },
            immediate: true
        },
        searchParams: {
            handler: function (newData) {
                if (newData) {
                    this.setSearchParamsData(newData);
                }
            },
            immediate: true
        },
        developerMode: {
            handler: function (enable) {
                if (enable) {
                    this.switchDevSearch = enable;
                }
            },
            immediate: true
        }
    }
};
</script>
<style src="./uid-search.styl" lang="stylus"></style>
