import CountriesI18n from "./i18n/all.json"

export function getCountriesListByLocale(locale) {
    const countriesList = CountriesI18n || {};
    if (Object.keys(countriesList).length === 0) return {};
    const countriesListLocale = countriesList[locale];
    if (countriesListLocale){
        return countriesListLocale.countries;
    }
    return {}
}
