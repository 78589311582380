import { axiosInsurerApi, axiosTasks } from "@/api/axios-instances";
import { InsurerDashboardCounters } from "@/models";
import * as ACTION_TYPES from "../types/action-types";
import * as MUTATION_TYPES from "../types/mutation-types";

const state = {
    approvalRequiredPoolTasks: [],
    counters: new InsurerDashboardCounters()
};
const mutations = {
    [MUTATION_TYPES.SET_TITLE](state, title) {
        if (!title) return;
        state.title = title;
    },
    [MUTATION_TYPES.SET_SUBTITLE](state, subtitle) {
        if (!subtitle) return;
        state.subtitle = subtitle;
    },
    [MUTATION_TYPES.SET_DEFAULT_TITLE](state) {
        state.title = null;
        state.subtitle = null;
    },
    [MUTATION_TYPES.SET_APROVAL_REQUIRED_POOL_TASKS](state, payload) {
        state.approvalRequiredPoolTasks = payload;
    },

    [MUTATION_TYPES.SET_COUNTERS](state, payload) {
        state.counters = payload;
    },
    [MUTATION_TYPES.REMOVE_TASK_FROM_LIST](state, { list, docId }) {
        if (!state[list]) return;
        const items = state[list].filter((item) => item.docId !== docId);
        state[list] = items;
    }
};

const getters = {};

const actions = {
    [ACTION_TYPES.FETCH_APROVAL_REQUIRED_POOL_TASKS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosInsurerApi
                .get("/getApprovalRequiredTasks")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        let items = resData;
                        commit(
                            MUTATION_TYPES.SET_APROVAL_REQUIRED_POOL_TASKS,
                            items
                        );
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ACTION_TYPES.FETCH_DASHBOARD_COUNTERS_DATA]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosInsurerApi
                .get("/getDashboardData")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let resData = response.data.data;
                        let counters = {};
                        counters.approvalRequiredPoolTasks =
                            resData.approvalRequiredPoolTasks || 0;
                        commit(MUTATION_TYPES.SET_COUNTERS, counters);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ACTION_TYPES.FETCH_REGISTRATION_DATA]: (ctx, data) => {
        const referenceId = data.referenceId;
        return new Promise((resolve, reject) => {
            axiosTasks
                .get(`/ref/${referenceId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ACTION_TYPES.SET_APPROVAL_REQUIRED_STATE]: (ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosInsurerApi
                .put('/setApprovalRequiredState', data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
