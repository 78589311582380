import Vue from "vue";

const requireComponent = require.context(
    // The relative path of the components folder
    "@/components/app",
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /app-[\w-]+\.vue$/
);

requireComponent.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Register component globally
    Vue.component(
        componentConfig.default.name,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});
