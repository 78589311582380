<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-equipment-data-i18n.yaml"></i18n>
<template>
    <v-container fluid py-0 my-0>
        <!-- Rolle des Meldenden auswählen -->
        <v-row dense>
            <v-col cols="12">
                <div class="caption" v-html="$t('role-caption')"></div>
                <base-select
                    v-model="computedRole"
                    :items="roleItems"
                    :error-messages="roleErrors"
                    :label="$t('role-label')"
                    :placeholder="$t('role-placeholder')"
                    :required="isRequiredField($v.role)"
                    dense
                    :item-text="(item) => $t(`task-role-${item.value}`)"
                >
                    <template v-slot:prepend-item>
                        <div
                            class="text-body-1 pl-3"
                            v-html="$t('role-label')"
                        ></div>
                        <v-divider></v-divider>
                    </template>
                </base-select>
            </v-col>
            <v-col cols="12" v-if="role">
                <!-- Objekttyp auswählen -->
                <div class="caption" v-html="$t('object-type-caption')"></div>
                <base-select
                    v-model="computedObjectType"
                    :items="objectTypeItems"
                    :error-messages="objectTypeErrors"
                    :label="$t('object-type-label')"
                    :placeholder="$t('object-type-placeholder')"
                    :required="isRequiredField($v.objectType)"
                    dense
                    :item-text="(item) => $t(`task-object-type-${item.value}`)"
                >
                    <template v-slot:prepend-item>
                        <div
                            class="text-body-1 pl-3"
                            v-html="$t('object-type-label')"
                        ></div>
                        <v-divider></v-divider>
                    </template>
                </base-select>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { ROLE_TYPES, OBJECT_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
import { required } from "vuelidate/lib/validators";
export default {
    name: "ContentEquipmentData",
    mixins: [validateMixin],
    props: {
        role: {
            type: String
        },
        objectType: {
            type: String
        }
    },
    validations: {
        role: { required },
        objectType: { required }
    },
    data: () => ({
        roleItems: [
            {
                value: ROLE_TYPES.OPERATOR
            },
            {
                value: ROLE_TYPES.OPERATOR_BEHALF
            }
        ],
        objectTypeItems: [
            {
                value: OBJECT_TYPES.PRESSURE_EQUIPMENT
            },
            {
                value: OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT
            },
            {
                value: OBJECT_TYPES.PIPES_STEAM_HOT_WATER
            }
        ]
    }),
    methods: {
        onFieldChange(field, value) {
            this.$emit("value:change", { field, value });
        },
        reset() {
            this.$v.$reset();
        }
    },
    computed: {
        computedRole: {
            get() {
                return this.role;
            },
            set(value) {
                this.onFieldChange("role", value);
            }
        },
        computedObjectType: {
            get() {
                return this.objectType;
            },
            set(value) {
                this.onFieldChange("objectType", value);
            }
        },
        roleErrors() {
            const errors = [];
            if (!this.$v.role.$dirty) {
                return errors;
            }
            if (!this.$v.role.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        objectTypeErrors() {
            const errors = [];
            if (!this.$v.objectType.$dirty) {
                return errors;
            }
            if (!this.$v.objectType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    }
};
</script>
