<i18n src="./file-uploader-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <div>
        <v-row>
            <v-col>
                <base-alert dense type="info">
                    {{ $t("allowed-file-types", { fileTypes: accept }) }}
                </base-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <dropzone-uploader
                    ref="dropzone"
                    v-on:wdropzone-total-upload-progress="
                        onDropzoneTotalUploadProgress
                    "
                    v-on:wdropzone-upload-progress="onDropzoneUploadProgress"
                    v-on:wdropzone-removed-file="onDropzoneRemovedFile"
                    v-on:wdropzone-file-added="onDropzoneFileAdded"
                    v-on:wdropzone-success="onDropzoneSuccess"
                    v-on:wdropzone-error="onDropzoneError"
                    v-on:wdropzone-sending="onDropzoneSending"
                    v-on:wdropzone-complete="onDropzoneComplete"
                    v-on:wdropzone-queue-complete="onDropzoneQueueComplete"
                    v-on:wdropzone-processing="onDropzoneProcessing"
                    :dictDefaultMessage="$t('default-message')"
                    :docId="docId"
                    :category="category"
                    :maxFileSize="maxFileSize"
                    :url="url"
                    :params="params"
                >
                </dropzone-uploader>
            </v-col>
        </v-row>
        <GlobalOverlay
            :isLoading="isLoading"
            v-if="isLoading && !isUploading"
            :loadingMsg="isLoadingMsg"
        >
        </GlobalOverlay>
        <GlobalOverlay
            :isLoading="isLoading"
            v-if="isLoading && isUploading"
            :loadingMsg="isLoadingMsg"
        >
            <template v-slot:content>
                <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                        <Photoswipe bubble>
                            <v-card
                                tile
                                outlined
                                min-width="400"
                                max-width="400"
                                flat
                                color="transparent"
                            >
                                <v-card-text>
                                    <template v-if="isImage(uploadFile.type)">
                                        <v-img
                                            :src="uploadFile.dataURL"
                                            :lazy-src="uploadFile.dataURL"
                                            v-pswp="uploadFile.dataURL"
                                            contain
                                            width="100%"
                                            aspect-ratio="1"
                                            height="100"
                                            @error="
                                                $toast.error(
                                                    'Error loading image'
                                                )
                                            "
                                            max-height="100"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </template>
                                    <template v-else>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-icon class="ma-4" size="60">
                                                mdi-file-pdf
                                            </v-icon>
                                        </v-row>
                                    </template>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        {{
                                            $t("total-upload-size-info", {
                                                totalFileSizeSent:
                                                    totalFileSizeSent,
                                                totalFileSize: totalFileSize
                                            })
                                        }}
                                    </v-row>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        {{
                                            $t("total-upload-count-info", {
                                                totalFilesSentCount:
                                                    getFilePosition(uploadFile),
                                                totalFilesCount: files.length
                                            })
                                        }}
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <base-btn
                                        v-if="showCancelBtnOnUpload"
                                        small
                                        @click="cancelUpload"
                                        color="white"
                                        class="white--text"
                                        outlined
                                    >
                                        <v-icon>mdi-close</v-icon>
                                        {{ $t("btn-cancel") }}
                                    </base-btn>
                                </v-card-actions>
                            </v-card>
                        </Photoswipe>
                    </v-row>
                </v-container>
            </template>
        </GlobalOverlay>
    </div>
</template>
<script>
import { required, minLength } from "vuelidate/lib/validators";
import DropzoneUploader from "./dropzone-uploader.vue";
import GlobalOverlay from "@/components/general/global-overlay.vue";
import _ from "lodash";
import Utils from "@/utils";
import validateMixin from "@/mixins/validate";
export default {
    name: "FileUploader",
    components: {
        DropzoneUploader,
        GlobalOverlay
    },
    mixins: [validateMixin],
    validations: {
        files: { required, minLength: minLength(1) }
    },
    props: {
        label: {
            type: String,
            default: "MoneyField"
        },
        placeholder: {
            type: String,
            default: ""
        },
        hint: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        persistentHint: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        prependIcon: {
            type: String,
            default: "mdi-paperclip"
        },
        accept: {
            type: String,
            // default : "image/*|application/pdf"
            default: ".png, .jpg, .jpeg, .pdf"
        },
        name: {
            type: String,
            default: ""
        },
        docId: {
            type: String,
            default: ""
        },
        category: {
            type: String,
            default: ""
        },
        maxFileSize: {
            type: Number,
            default: null
        },
        dictDefaultMessage: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        files: [],
        internal_image: null,
        isUploading: false,
        pdf: "",
        errors: false,
        isLoadingMsg: null,
        isLoading: false,
        totalUploadProgress: 0,
        fileUploadProgress: 0,
        totalFileSizeSent: 0,
        totalFileSize: 0,
        uploadFile: {}
    }),

    computed: {
        showCancelBtnOnUpload() {
            if (this.files.length === 0 || this.files.length === 1)
                return false;
            if (
                this.getFilePosition(this.uploadFile) ===
                (this.files.length || this.files.length - 1)
            )
                return false;
            if (this.isUploading && this.totalUploadProgress === 100)
                return false;
            return true;
        }
    },

    methods: {
        validate() {
            this.$v.files.$touch();
            if (this.$v.files.$anyError) {
                return false;
            } else {
                return true;
            }
        },
        onFileUploadChange(file) {
            if (file[0]) {
                this.$emit("upload-file-change", file[0]);
            } else {
                return;
            }
        },
        onFileUploadClearClick(e) {
            this.$emit("upload-file-clear", e);
        },
        /* eslint-disable-next-line no-unused-vars */
        onDropzoneTotalUploadProgress(
            totalUploadProgress,
            totalBytes,
            totalBytesSent
        ) {
            this.totalFileSizeSent = this.formatBytes(totalBytesSent);
        },
        /* eslint-disable-next-line no-unused-vars */
        onDropzoneUploadProgress(file, progress, bytesSent) {
            this.fileUploadProgress = progress;
        },
        onDropzoneProcessing(file) {
            if (file) {
                this.uploadFile = file;
            }
        },
        onDropzoneRemovedFile(file) {
            if (file && file.upload && file.upload.uuid) {
                this.files = this.files.filter(
                    (item) => item.upload.uuid !== file.upload.uuid
                );
            }
        },
        onDropzoneSuccess() {
            this.upload();
        },
        onDropzoneError(file, message) {
            this.totalUploadProgress = 0;
            this.errors = true;
            if (message && _.isNumber(message.code)) {
                this.$toast.error(
                    this.$t("error") +
                        " [" +
                        message.code +
                        "]: " +
                        this.$t(`error-code-${message.code}`)
                );
                file.previewElement.querySelectorAll(
                    ".dz-error-message span"
                )[0].textContent = this.$t(`error-code-${message.code}`);
            } else {
                file.previewElement.querySelectorAll(
                    ".dz-error-message span"
                )[0].textContent = this.$t("error-code-0");
                this.$toast.error(
                    this.$t("error") + ": " + this.$t("error-code-0")
                );
            }
        },
        _checkIfFileCorrupted(base64string) {
            // see: https://stackoverflow.com/questions/41820423/how-to-check-corrupt-image-when-it-uploads
            let src = base64string;
            if (!src) return null;
            const type = src.split(";")[0].split("/")[1];
            let imageData = null;
            let imageCorrupted = false;
            let sequence = [];
            switch (type) {
                case "jpeg" || "jpg":
                    imageData = Uint8Array.from(
                        atob(src.replace(`data:image/${type};base64,`, "")),
                        (c) => c.charCodeAt(0)
                    );
                    imageCorrupted =
                        imageData[imageData.length - 1] !== 217 &&
                        imageData[imageData.length - 2] !== 255;

                    break;
                case "png":
                    imageData = Uint8Array.from(
                        atob(src.replace("data:image/png;base64,", "")),
                        (c) => c.charCodeAt(0)
                    );
                    sequence = [0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130]; // in hex:
                    //check last 12 elements of array so they contains needed values
                    for (var i = 12; i > 0; i--) {
                        if (
                            imageData[imageData.length - i] !== sequence[12 - i]
                        ) {
                            imageCorrupted = true;
                        }
                    }
                    break;
                case "pdf":
                    imageData = Uint8Array.from(
                        atob(src.replace("data:application/pdf;base64,", "")),
                        (c) => c.charCodeAt(0)
                    );
                    sequence = [37, 80, 68, 70]; // in hex:
                    //check first 4 elements of array so they contains needed values
                    sequence.forEach((item, idx) => {
                        if (imageData[idx] !== item) {
                            imageCorrupted = true;
                        }
                    });
                    break;
                default:
                    break;
            }
            return imageCorrupted;
        },
        _removeCorruptedFile(file) {
            if (file) {
                const fileUuid = file.upload ? file.upload.uuid : null;
                this.$toast.error(this.$t("file-corrupted-msg"));
                this.$refs.dropzone.removeFile(file);
                return this._clearCheckExistInterval(fileUuid);
            }
        },
        _clearCheckExistInterval(fileUuid) {
            this.loader().hide();
            clearInterval(this[fileUuid]);
        },
        onDropzoneFileAdded(file) {
            this.loader().show(this.$t("validate-file-msg"));
            let me = this;
            let isConvertigToB64 = false;
            let fileCorrupted = null;
            const isImage = file.type.split("/")[0] === "image";
            const isPdf = file.type.split("/")[1] === "pdf";
            const fileUuid = file.upload ? file.upload.uuid : null;
            let pdfB64 = null;
            this[fileUuid] = setInterval(() => {
                if (isImage) {
                    fileCorrupted = this._checkIfFileCorrupted(file.dataURL);
                } else {
                    const toBase64 = (file) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                        });
                    let convertToBase64 = async function () {
                        isConvertigToB64 = true;
                        pdfB64 = await toBase64(file); // wait until the promise resolves (*)
                        if (pdfB64) {
                            fileCorrupted = me._checkIfFileCorrupted(pdfB64);
                            if (fileCorrupted) {
                                return me._removeCorruptedFile(file);
                            }
                        }
                    };
                    if (file && file.upload && file.upload.uuid) {
                        if (file.size > 20000000) {
                            this.files.push(file);
                            file.previewElement.addEventListener(
                                "click",
                                function () {
                                    me.isLoading = false;
                                    me.$toast.info(me.$t("file-to-large"));
                                }
                            );
                            return this._clearCheckExistInterval(fileUuid);
                        }
                    }
                    if (!isConvertigToB64) {
                        convertToBase64();
                    }
                }
                if (fileCorrupted !== null) {
                    if (fileCorrupted === true) {
                        return this._removeCorruptedFile(file);
                    }
                    if (file && file.upload && file.upload.uuid) {
                        this.files.push(file);
                        file.previewElement.addEventListener(
                            "click",
                            function () {
                                me.isLoading = true;
                                if (isImage) {
                                    if (me.files.length > 0) {
                                        let filesSrcs = [];
                                        me.files.forEach((fileItem) => {
                                            if (fileItem.dataURL) {
                                                filesSrcs.push({
                                                    src: file.dataURL
                                                });
                                            }
                                        });
                                        me.$Pswp.open({
                                            items: filesSrcs
                                        });
                                    }
                                    me.isLoading = false;
                                } else if (isPdf) {
                                    if (pdfB64) {
                                        me.openModalPdf(pdfB64);
                                        me.isLoading = false;
                                    }
                                } else {
                                    me.isLoading = false;
                                    return;
                                }
                            }
                        );
                    }
                    return this._clearCheckExistInterval(fileUuid);
                }
            }, 100); // check every 100ms
        },
        upload() {
            this.$refs.dropzone.processQueue();
            this.isLoadingMsg = this.$t("is-loading-msg");
        },
        enable() {
            this.$refs.dropzone.enable();
        },
        disable() {
            this.$refs.dropzone.disable();
        },
        reset() {
            this.$refs.dropzone.reset();
            this.errors = false;
            this.files = [];
            this.isLoading = false;
            this.isUploading = false;
        },
        openModalPdf(pdf) {
            this.pdf = pdf;
            if (this.pdf) {
                window.open(this.pdf);
                return false;
            }
        },
        onDropzoneSending(file) {
            this.totalFileSize = this.formatBytes(file.size);
            if (file.status === "uploading") {
                this.isUploading = true;
                this.isLoadingMsg = this.$t("is-loading-msg");
                this.isLoading = this.isUploading;
            }
        },
        onDropzoneComplete(file) {
            if (file.status === "uploading") {
                this.isUploading = true;
                this.isLoadingMsg = this.$t("is-loading-msg");
            }
            if (file.status === "success") {
                //remove uploaded file from files array after succesfully upload
                const fileIdx = _.findIndex(this.files, [
                    "upload.uuid",
                    file.upload.uuid
                ]);
                if (fileIdx !== -1) {
                    this.files.splice(fileIdx, 1);
                }
                this.$emit("upload:success", file);
            }
        },
        onDropzoneQueueComplete() {
            if (!this.errors) {
                this.$emit("upload:complete", true);
            }
            this.files = [];
            this.isUploading = false;
            this.isLoading = this.isUploading;
        },
        isFileUploading() {
            return this.isUploading;
        },
        getFilesCount() {
            return this.files.length;
        },
        formatBytes(bytes) {
            return Utils.formatBytes(bytes);
        },
        isImage(fileType) {
            if (fileType) {
                return fileType.split("/")[0] === "image";
            }
            return false;
        },
        cancelUpload() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-cancel-upload-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-cancel-upload-text")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.reset();
                }
            });
        },
        getFilePosition(file) {
            const idx = _.indexOf(this.files, file);
            if (idx != -1) {
                return idx + 1;
            }
            return 0;
        }
    },

    watch: {
        files: {
            handler: function () {
                this.$emit("change-files-count", this.files.length);
                this.totalUploadProgress = 0;
            },
            immediate: true
        }
    }
};
</script>
