var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":"","pa-0":"","ma-0":""}},[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" @registrationData "),_c('tree-view',{attrs:{"data":_vm.registrationData,"options":{ maxDepth: 0 }}}),_vm._v(" @registrationDialog "),_c('tree-view',{attrs:{"data":Object.assign({}, _vm.registrationDialog),"options":{ maxDepth: 0 }}})]},proxy:true}])}),(
            _vm.registrationDialog.state != null &&
            _vm.registrationDialog.messages &&
            _vm.registrationDialog.messages.length > 0
        )?_c('v-flex',{staticClass:"pt-2",attrs:{"xs12":""}},[_c('v-list',{attrs:{"dense":"","subheader":"","color":"light-blue lighten-5","tile":"","flat":""}},[_c('v-list-item',{attrs:{"three-line":_vm.registrationDialog.state >=
                    _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED,"two-line":_vm.registrationDialog.state <
                    _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED},on:{"click":function($event){_vm.registrationDialogModal = true}}},[_c('v-list-item-avatar',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.getRegistrationStateColor(
                                        _vm.registrationDialog.state
                                    )}},on),[_vm._v(" mdi-circle ")])]}}],null,false,3533021670)},[_c('span',[_vm._v(_vm._s(_vm.$t( ("registration-dialog-status-" + (_vm.registrationDialog.state)) )))])])],1),_c('v-list-item-content',[(
                            _vm.registrationDialog.state !=
                            _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED
                        )?_c('v-list-item-subtitle',[(_vm.registrationDialog.reminder)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_vm.$moment
                                            .utc(
                                                _vm.registrationDialog.reminder
                                            )
                                            .local()
                                            .diff(_vm.$moment(), 'days') == 2
                                            ? 'error'
                                            : 'primary'}},on),[_vm._v(" mdi-bell-alert ")])]}}],null,false,237931711)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("dialog-caption-deadline", { date: _vm.$moment .utc( _vm.registrationDialog.reminder ) .local() .format( "DD.MM.YYYY [" + _vm.$t( "date-time-separator" ) + "] HH:mm" ), timestamp: _vm.$moment .utc( _vm.registrationDialog.reminder ) .local() .from(_vm.$moment()) }))+" ")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("dialog-caption-create", { date: _vm.$moment .utc(_vm.registrationDialog.timestamp) .local() .format("DD.MM.YYYY [um] HH:mm"), timestamp: _vm.$moment .utc(_vm.registrationDialog.timestamp) .local() .from(_vm.$moment()), userName: _vm.registrationDialog.createdBy }))+" ")],1):_vm._e(),(
                            _vm.registrationDialog.state ==
                            _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED
                        )?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("dialog-caption-close", { userName: _vm.registrationDialog.closedBy, timestamp: _vm.$moment .utc(_vm.registrationDialog.closedTimestamp) .local() .from(_vm.$moment()) }))+" ")]):_vm._e(),(
                            _vm.registrationDialog.state ==
                                _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                            _vm.registrationDialog.reason
                        )?_c('v-list-item-title',{staticClass:"text-body-1 py-1"},[_vm._v(" Grund: "+_vm._s(_vm.registrationDialog.reason)+" ")]):_vm._e(),(
                            _vm.registrationDialog.state ==
                                _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                            _vm.registrationDialog.note
                        )?_c('v-list-item-title',{staticClass:"text-body-1 py-1"},[_vm._v(" Note: "+_vm._s(_vm.registrationDialog.note)+" ")]):_vm._e(),(
                            _vm.registrationDialog.state != null &&
                            _vm.registrationDialog.state !=
                                _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED
                        )?_c('v-list-item-title',{staticClass:"text-body-1 py-1 10"},[(
                                _vm.registrationDialog.state ==
                                    _vm.TaskDialogStatus.TASK_DIALOG_CREATED ||
                                _vm.registrationDialog.state ==
                                    _vm.TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                            )?_c('span',[_vm._v(" F:"+_vm._s(_vm.lastMessage.question.text)+" ")]):_c('span',[_vm._v(" A:"+_vm._s(_vm.lastMessage.reply.text)+" ")])]):_vm._e()],1),_c('v-list-item-action',[_c('v-row',[(
                                _vm.registrationDialog.state >=
                                _vm.TaskDialogStatus.TASK_DIALOG_CREATED
                            )?_c('v-btn',{attrs:{"small":"","fab":"","depressed":"","color":"info"},on:{"click":function($event){$event.stopPropagation();_vm.registrationDialogModal = true}}},[_c('v-icon',[_vm._v("mdi-comment-eye")])],1):_vm._e(),(
                                _vm.registrationDialog.state ==
                                _vm.TaskDialogStatus.TASK_DIALOG_CREATED
                            )?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onRegistrationDialogDelete(
                                    _vm.registrationDialog
                                )}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)],1)],1)],1):_vm._e(),(
            _vm.registrationData.state >=
                _vm.TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
            !_vm.reviewVerification.approved &&
            (('dialog' in _vm.reviewVerification &&
                'messages' in _vm.reviewVerification.dialog &&
                _vm.reviewVerification.dialog.messages.length === 0) ||
                Object.entries(_vm.reviewVerification.dialog).length === 0)
        )?_c('v-flex',{attrs:{"xs12":""}},[_c('base-btn',{staticClass:"mt-5 mr-4",on:{"click":function($event){_vm.registrationDialogModal = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-comment-question")]),_vm._v(" "+_vm._s(_vm.$t("btn-dialog-create"))+" ")],1)],1):_vm._e(),_c('RegistrationDialogModal',{ref:"registrationDialogModal",attrs:{"value":_vm.registrationDialogModal,"registrationDialogData":_vm.registrationDialog,"registrationData":_vm.registrationData,"requireChambers":_vm.requireChambers,"requireAttachments":_vm.requireAttachments,"step":_vm.step},on:{"input":function($event){_vm.registrationDialogModal = $event},"add-question":_vm.onAddQuestionToMessageDialog,"accept-and-close-dialog":_vm.onAcceptAndCloseDialog,"create":_vm.onRegistrationDialogCreate,"edit":_vm.onRegistrationDialogMsgEdit,"delete":_vm.onRegistrationDialogMsgDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }