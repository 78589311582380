<i18n src="./WMsgBox-i18n.yaml"></i18n>
<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            :width="options.width"
            :style="{ zIndex: options.zIndex }"
        >
            <v-card tile>
                <v-toolbar
                    flat
                    v-if="options.title"
                    class="regOfficePrimary--text"
                    :color="
                        options.title.color
                            ? options.title.color
                            : 'cardHeaderGrey'
                    "
                >
                    <v-icon v-if="options.title.icon" color="regOfficePrimary">
                        {{ options.title.icon }}
                    </v-icon>
                    <v-toolbar-title :class="options.title.icon ? 'pl-2' : ''">
                        {{ options.title.text }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <base-btn
                            icon
                            @click="onBtnClick('cancel')"
                            color="regOfficePrimary"
                        >
                            <v-icon>mdi-close</v-icon>
                        </base-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container v-if="options.type == 'confirm'">
                    <v-card-text
                        v-show="!!options.message"
                        v-html="options.message"
                    />
                </v-container>
                <v-container v-else-if="options.type == 'reason'">
                    <v-card-text
                        v-show="!!options.message"
                        v-html="options.message"
                    />
                    <base-textarea
                        v-model="fieldReason"
                        :label="$t('field-reason-label')"
                        :error-messages="fieldReasonErrors"
                        :counter="$v.fieldReason.$params.maxLength.max"
                        no-resize
                        rows="3"
                        :required="isRequiredField($v.fieldReason)"
                    />
                </v-container>
                <v-container v-else-if="options.type == 'checkbox'">
                    <v-card-text
                        v-show="!!options.message"
                        v-html="options.message"
                    />
                    <v-checkbox
                        class="pl-3"
                        v-model="fieldCheckbox"
                        :label="$t('field-checkbox-label')"
                        :error-messages="fieldCheckboxErrors"
                        @change="validateReason"
                    ></v-checkbox>
                </v-container>
                <v-card-actions v-if="!options.hideButtons">
                    <v-spacer />
                    <base-btn
                        v-for="(btn, index) in sortedButtons"
                        :key="index"
                        :type="btn.type ? btn.type : 'primary'"
                        :class="btn.class ? btn.class : undefined"
                        @click="onBtnClick(btn.name)"
                    >
                        {{ btn.text }}
                    </base-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { requiredIf, maxLength, minLength } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
export default {
    name: "WMsgBox",
    mixins: [validateMixin],
    validations: {
        fieldReason: {
            required: requiredIf(function () {
                if (this.options.type === "reason") {
                    return true;
                }
                return false;
            }),
            maxLength: maxLength(200),
            minLength: minLength(2)
        },
        fieldCheckbox: {
            required: requiredIf(function () {
                if (this.options.type === "checkbox") {
                    return true;
                }
                return false;
            }),
            checked(val) {
                return val;
            }
        }
    },
    data: () => ({
        dialog: false,
        fieldReason: "",
        fieldCheckbox: false,
        options: {},
        resolve: null,
        reject: null
    }),
    computed: {
        sortedButtons() {
            if (this.options.buttons) {
                return this.options.buttons
                    .slice(0)
                    .sort((v1, v2) => v1.position - v2.position); // eslint-disable-line
            }
            return [];
        },
        fieldReasonErrors() {
            const errors = [];
            if (!this.$v.fieldReason.$dirty) {
                return errors;
            }
            if (!this.$v.fieldReason.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.fieldReason.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.fieldReason.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.fieldReason.minLength) {
                errors.push(
                    this.$t("field-min-length", {
                        minLength: this.$v.fieldReason.$params.minLength.max
                    })
                );
            }
            return errors;
        },
        fieldCheckboxErrors() {
            const errors = [];
            if (!this.$v.fieldCheckbox.$dirty) {
                return errors;
            }
            if (!this.$v.fieldCheckbox.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.fieldCheckbox.checked) {
                errors.push(this.$t("field-checked"));
            }

            return errors;
        }
    },
    created() {
        this.setDefaultOptions();
    },
    methods: {
        open(options) {
            this.setDefaultOptions();
            this.dialog = true;
            this.clear();
            this.options = Object.assign(this.defaultOptions, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        onBtnClick(action) {
            const me = this;
            let ret = {};
            if (action == "agree") {
                switch (this.options.type) {
                    case "confirm":
                        me.resolve(action);
                        me.dialog = false;
                        break;
                    case "reason":
                        me.$v.fieldReason.$touch();
                        ret = {};
                        ret.action = action;
                        ret.reason = me.fieldReason;
                        if (me.$v.fieldReason.$dirty) {
                            if (!me.$v.fieldReason.$anyError) {
                                me.resolve(ret);
                                me.dialog = false;
                            }
                        }
                        break;
                    case "checkbox":
                        me.$v.fieldCheckbox.$touch();
                        if (me.$v.fieldCheckbox.$dirty) {
                            if (!me.$v.fieldCheckbox.$anyError) {
                                me.resolve(action);
                                me.dialog = false;
                            }
                        }
                        break;
                    default:
                        break;
                }
            } else {
                this.resolve(action);
                this.dialog = false;
            }
        },
        setDefaultOptions() {
            this.defaultOptions = {
                width: 400,
                zIndex: 203,
                title: {
                    text: "title",
                    icon: "mdi-check"
                },
                message: "message",
                type: "confirm",
                buttons: [
                    {
                        name: "cancel",
                        text: this.$t("btn-cancel"),
                        position: 1,
                        type: "secondary"
                    },
                    {
                        name: "agree",
                        text: this.$t("btn-agree"),
                        position: 2,
                        type: "primary"
                    }
                ],
                hideButtons: false
            };
        },
        validateReason() {
            if (!this.$v.fieldReason.$dirty) {
                this.$v.fieldReason.$touch();
            }
        },
        validateCheckbox() {
            if (!this.$v.fieldCheckbox.$dirty) {
                this.$v.fieldCheckbox.$touch();
            }
        },
        clear() {
            this.$v.$reset();
            this.fieldReason = "";
            this.fieldCheckbox = false;
        }
    },
    provide() {
        return { agree: this.agree, cancel: this.cancel };
    }
};
</script>
