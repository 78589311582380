<i18n src="./not-found-i18n.yaml"></i18n>
<template>
    <AppErrorContainer
        errorTitle="404"
        :errorMsg="$t('error-msg')"
        :wIconSrc="require('@/assets/icons/pages/404.svg')"
    />
</template>
<script>
export default {
    name: "ViewErrorNotFound"
};
</script>
