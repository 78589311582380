<i18n src="./base-stepper-step-i18n.yaml"></i18n>
<template>
    <v-stepper-step
        :step="step"
        :class="computedCls"
        v-bind="$attrs"
        :editable="editable"
        :complete="stepComplete"
        :color="stepColor"
        :edit-icon="stepEditIcon"
    >
        <slot name="title">
            <span :class="editModus ? 'info--text' : null">
                {{ title }}
                <span v-if="editModus"> ({{ $t("text-edit-in-modus") }}) </span>
            </span>
        </slot>
        <btn-lock
            v-if="btnLockShow"
            :value="readonlyModusStep"
            color="info"
            @click.stop="$emit('click:btn-lock')"
            :disabled="stepApproved"
        >
        </btn-lock>
    </v-stepper-step>
</template>

<script>
import { TaskDialogStatus } from "@/data/globals.js";
export default {
    name: "BaseStepperStep",
    components: {
        BtnLock: () => import("@/components/tasks/common/btn-lock.vue")
    },
    inject: ["theme"],
    props: {
        step: {
            type: [Number, String],
            default: null,
            required: true
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        readonlyModusStep: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        btnLock: {
            type: Boolean,
            default: true
        },
        btnLockDisabled: {
            type: Boolean,
            default: false
        },
        btnLockShow: {
            type: Boolean,
            default: false
        },
        approved: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        complete: {
            type: Boolean,
            default: false
        },
        reviewVerification: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        computedCls() {
            let cls = "";
            if (this.$vuetify.breakpoint.mobile) {
                cls += " px-0";
            }
            if (this.editModus) {
                cls += " info--text";
            }
            return cls;
        },
        stepComplete() {
            if (this.complete) return true;
            return (
                (!this.stepApproved && !this.readonlyModusStep) ||
                this.stepApproved
            );
        },
        editModus() {
            return !this.readonlyModusStep;
        },
        stepApproved() {
            return this.reviewVerification?.approved;
        },
        stepEditIcon() {
            if (this.stepApproved || this.complete) return "mdi-check";
            if (this.readonlyModusStep === false) {
                return "mdi-pencil";
            }
            return undefined;
        },
        stepColor() {
            const defaultColor = this.theme.isDark ? "secondary" : "primary";
            if (this.readonlyModusStep === false) {
                return "info";
            }
            if (this.stepApproved || this.complete) {
                return "success";
            }
            if (!this.stepApproved) {
                return "warning";
            }
            if (!this.reviewVerification) return defaultColor;
            if (
                this.reviewVerification?.dialog?.messages.length > 0 &&
                this.reviewVerification?.dialog?.state ==
                    TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
            ) {
                return "info";
            }

            return defaultColor;
        }
    }
};
</script>
