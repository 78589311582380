var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":"","ma-0":""},on:{"click":function($event){return _vm.$emit('click:container')}}},[_c('StepContentTitle',{attrs:{"title":_vm.$t('title')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("title"))+" "),_c('base-btn',{staticClass:"pl-3",attrs:{"icon":""},on:{"click":function($event){return _vm.showFaq('chambersDefinition')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information-outline")])],1)]},proxy:true}])}),_c('v-row',[_c('v-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('chamber-definition'))}})])],1),_c('v-row',[_c('v-col',[_c('base-alert',{attrs:{"type":"info"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('chamber-definition-alert'))}})])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',[_c('span',{staticClass:"pl-0",domProps:{"innerHTML":_vm._s(
                        _vm.$t('subtitle', {
                            maxChambers: _vm.maxChambers,
                            chamber: _vm.$tc('chamber', _vm.maxChambers)
                        })
                    )}}),_c('br'),(_vm.chambers.length > 0)?_c('span',{staticClass:"pl-0",domProps:{"innerHTML":_vm._s(
                        _vm.$t('subtitle-volume', {
                            chambersLength: _vm.chambers.length,
                            totalChambersVolume: _vm.getTotalChambersVolume(),
                            chamber: _vm.$tc('chamber', _vm.chambers.length)
                        })
                    )}}):_vm._e()])])],1),_c('v-container',{attrs:{"pl-0":"","pt-4":"","fluid":"","grid-list-md":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l((_vm.chambers),function(item,index){return _c('v-flex',{key:index,class:_vm.chamberCardCls,attrs:{"xs12":"","sm6":""}},[_c('ChamberCard',{attrs:{"chamberIndex":index,"chamberData":item,"readonlyModus":_vm.readonlyModus},on:{"remove-chamber":_vm.removeChamberByIndex,"edit-chamber":_vm.onEditChamber}})],1)}),(_vm.chambers.length < 4 && !_vm.readonlyModus)?_c('v-flex',{class:_vm.chamberCardCls,attrs:{"xs12":"","sm6":""}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"height":"100%","max-width":"360","min-height":"140","flat":"","outlined":"","tile":"","color":_vm.chamberColor,"ripple":""},nativeOn:{"click":function($event){return _vm.btnAddChamberClick.apply(null, arguments)}}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-icon',{staticClass:"pt-2",attrs:{"color":"white","size":"80"}},[_vm._v(" mdi-plus ")])],1),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('span',{staticClass:"white--text title"},[_vm._v(_vm._s(_vm.$t("btn-add-chamber")))])])],1)],1)],1):_vm._e()],2)],1),(_vm.dialog)?_c('v-row',[_c('v-col',[_c('base-dialog',{ref:"dialog",attrs:{"value":_vm.dialog,"title":_vm.editedIndex === -1
                        ? _vm.$t('title-add-chamber', { chamberNr: _vm.chamberId })
                        : _vm.$t('title-edit-chamber', { chamberNr: _vm.chamberId }),"max-width":"1024","fullscreen":_vm.$vuetify.breakpoint.xsOnly},on:{"input":function($event){_vm.dialog = $event},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==83){ return null; }if(!$event.ctrlKey){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.onBtnSaveChamberClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form-chamber',{ref:"formChamber",staticClass:"pa-2 pt-4",attrs:{"chamberId":_vm.chamberId,"readonlyModus":_vm.readonlyModus,"activelyHeated":_vm.activelyHeated}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('base-btn',{attrs:{"type":"secondary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("btn-cancel"))+" ")]),(!_vm.readonlyModus)?_c('base-btn',{attrs:{"disabled":_vm.readonlyModus,"type":"primary"},on:{"click":_vm.onBtnSaveChamberClick}},[_vm._v(" "+_vm._s(_vm.$t("btn-save"))+" ")]):_vm._e()]},proxy:true}],null,false,1274755182)})],1)],1):_vm._e(),(_vm.dialogCompare && _vm.isBackoffice)?_c('v-row',[_c('v-col',[(_vm.dialogCompare && _vm.isBackoffice)?_c('dialog-chamber-fields-diff',{attrs:{"value":_vm.dialogCompare,"title":_vm.editedIndex === -1
                        ? _vm.$t('title-add-chamber', { chamberNr: _vm.chamberId })
                        : _vm.$t('title-edit-chamber', { chamberNr: _vm.chamberId }),"readonlyModus":_vm.readonlyModus,"chamberId":_vm.chamberId,"originalData":_vm.originalData,"diffData":_vm.diffData},on:{"input":function($event){_vm.dialogCompare = $event},"save":_vm.onChamberSave}}):_vm._e()],1)],1):_vm._e(),_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('tree-view',{attrs:{"data":_vm.chambers,"options":{ maxDepth: 1 }}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }