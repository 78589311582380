<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-i18n.yaml"></i18n>
<template>
    <div>
        <v-container fluid class="pt-10" v-if="isLoading">
            <v-sheet class="px-3 pt-3 pb-3">
                <v-skeleton-loader
                    v-for="(n, idx) in [2].slice(0, 2)"
                    class="mx-auto mb-6"
                    :key="idx"
                    type="list-item-avatar, divider"
                >
                </v-skeleton-loader>
                <v-skeleton-loader class="mx-auto mb-6" type="text">
                </v-skeleton-loader>
                <v-skeleton-loader
                    v-for="n in [4].slice(0, 4)"
                    :key="n"
                    class="mx-auto mb-1"
                    type="heading, list-item-avatar-three-line, actions"
                >
                </v-skeleton-loader>
            </v-sheet>
        </v-container>
        <v-container fluid class="pt-10" v-else>
            <v-row class="pb-6">
                <span class="text-h5">{{ $t("title") }}</span>
                <v-spacer />
                <v-btn icon @click="fetchData">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </v-row>
            <v-row no-gutters>
                <v-col
                    cols="12"
                    v-if="
                        registrationData.state >
                        TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                    "
                >
                    <base-alert type="success">
                        {{ $t("msg-registration-already-completed") }}
                    </base-alert>
                </v-col>
                <v-col cols="12">
                    <v-divider class="pb-2"></v-divider>
                </v-col>
                <v-col cols="12">
                    <RegistrationListItem
                        @click="dialogRegistrationView = true"
                        :registration="registrationDataItem"
                    />
                </v-col>
                <v-col
                    cols="12"
                    class="pt-10"
                    v-if="
                        openedDialogs.length === 0 &&
                        newDialogs.length === 0 &&
                        registrationData.state ==
                            TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                    "
                >
                    <v-container fluid class="px-0 mx-0">
                        <base-alert type="warning">
                            <div v-html="$t('alert-msg-1')"></div>
                        </base-alert>
                    </v-container>
                    <v-container
                        v-if="
                            openedDialogs.length === 0 &&
                            newDialogs.length === 0 &&
                            registrationData.state ==
                                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES
                        "
                        fluid
                        class="px-0 mx-0"
                    >
                        <base-alert type="info">
                            <div v-html="$t('alert-msg-2')"></div>
                        </base-alert>
                    </v-container>
                    <v-container
                        v-if="
                            (openedDialogs.length != 0 ||
                                newDialogs.length != 0) &&
                            registrationData.state !=
                                TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                        "
                        fluid
                        class="px-0 mx-0"
                    >
                        <base-alert type="warning">
                            <div
                                v-html="
                                    $t('alert-msg-3', {
                                        questionTrans: $tc(
                                            'question-trans',
                                            openedDialogs.length +
                                                newDialogs.length
                                        ),
                                        countDialogs:
                                            openedDialogs.length +
                                            newDialogs.length
                                    })
                                "
                            ></div>
                        </base-alert>
                    </v-container>
                    <v-container
                        v-if="registrationData.state >= 60"
                        fluid
                        class="px-0 mx-0"
                    >
                        <base-alert type="success">
                            <div v-html="$t('alert-msg-4')"></div>
                        </base-alert>
                    </v-container>
                </v-col>
                <!-- OPEN DIALOGS -->
                <v-col
                    cols="12"
                    v-if="
                        openDialogsItems.length > 0 &&
                        registrationData.state <=
                            TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                    "
                >
                    <registration-dialog-card
                        :dialogs="openDialogsItems"
                        :registrationData="registrationData"
                        @click="onDialogItemClick"
                        :title="$t('title-open-questions')"
                        :subtitle="$t('title-open-questions-sub')"
                    >
                    </registration-dialog-card>
                </v-col>
                <v-col
                    cols="12"
                    class="pt-10"
                    v-if="
                        registrationData.state ==
                        TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                    "
                >
                    <v-container fluid class="px-0 mx-0">
                        <base-btn
                            block
                            color="info"
                            @click="onConfirmAndAssingToBackoffice"
                            :disabled="
                                openDialogsItems.length !==
                                repliedDialogItems.length
                            "
                        >
                            <span
                                v-if="
                                    openDialogsItems.length !==
                                    repliedDialogItems.length
                                "
                            >
                                {{
                                    $t("btn-open-dialogs", {
                                        counter: repliedDialogItems.length,
                                        total: openDialogsItems.length
                                    })
                                }}
                            </span>
                            <span v-else>
                                {{ $t("btn-assign-to-backoffice") }}
                            </span>
                        </base-btn>
                    </v-container>
                </v-col>
                <!-- CLOSED DIALOGS -->
                <v-col
                    cols="12"
                    class="pt-10"
                    v-if="acceptedDialogsItems.length > 0"
                >
                    <v-card flat outlined class="mt-10" width="100%">
                        <v-expansion-panels flat focusable hover>
                            <v-expansion-panel tile>
                                <v-expansion-panel-header>
                                    <div>
                                        <v-icon>mdi-history</v-icon>
                                        {{ $t("dialog-history-title") }}
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pa-0 ma-0">
                                    <v-list
                                        dense
                                        v-if="acceptedDialogsItems.length > 0"
                                        subheader
                                        class="pa-0"
                                    >
                                        <template
                                            v-if="
                                                acceptedDialogsItems.length > 0
                                            "
                                        >
                                            <v-list-item
                                                two-line
                                                v-for="dialogItem in acceptedDialogsItems"
                                                :key="dialogItem.id"
                                                @click="
                                                    selectedDialog = dialogItem;
                                                    registrationDialogModal = true;
                                                "
                                            >
                                                <!-- {{dialogItem}} -->
                                                <v-list-item-avatar tile>
                                                    <w-icon
                                                        v-if="
                                                            dialogItem.state ==
                                                            TaskDialogStatus.TASK_DIALOG_CONFIRMED
                                                        "
                                                        :data="
                                                            require('@/assets/icons/w-message-check.svg')
                                                        "
                                                        :color="
                                                            getDialogItemColorByState(
                                                                dialogItem.state
                                                            )
                                                        "
                                                        :width="dialogIconWidth"
                                                    >
                                                    </w-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{
                                                            $t(
                                                                "dialog-closed-caption",
                                                                {
                                                                    userName:
                                                                        dialogItem.closedBy,
                                                                    timestamp:
                                                                        $moment
                                                                            .utc(
                                                                                dialogItem.closedTimestamp
                                                                            )
                                                                            .local()
                                                                            .from(
                                                                                $moment()
                                                                            )
                                                                }
                                                            )
                                                        }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title
                                                        class="text-body-1"
                                                    >
                                                        <div
                                                            v-if="
                                                                dialogItem.messages
                                                                    .slice(-1)
                                                                    .pop()
                                                                    .reply &&
                                                                dialogItem.messages
                                                                    .slice(-1)
                                                                    .pop().reply
                                                                    .text
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    "registrant"
                                                                )
                                                            }}:
                                                            {{
                                                                dialogItem.messages
                                                                    .slice(-1)
                                                                    .pop().reply
                                                                    .text
                                                            }}
                                                        </div>
                                                        <div v-else>
                                                            {{
                                                                $t(
                                                                    "backoffice"
                                                                )
                                                            }}:
                                                            {{
                                                                dialogItem.messages
                                                                    .slice(-1)
                                                                    .pop()
                                                                    .question
                                                                    .text
                                                            }}
                                                        </div>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <RegistrationDialogModal
            :value="registrationDialogModal"
            @input="registrationDialogModal = $event"
            @add-reply="onAddReplyToMessageDialog"
            @edit="onRegistrationDialogMsgEdit"
            @delete="onRegistrationDialogMsgDelete"
            :registrationDialogData="selectedDialog"
            :registrationData="registrationData"
            ref="registrationDialogModal"
            :step="selectedDialog.step"
            :requireChambers="selectedDialog.requireChambers"
            :requireAttachments="selectedDialog.requireAttachments"
            @attachment:add="onAddAttachmentToMsg"
            @attachment:delete="onDeleteAttachmentsFromMsg"
            @attachment:delete-multiple="onDeleteMultipleAttachmentsFromMsg"
        >
        </RegistrationDialogModal>
        <DialogRegistrationView
            v-if="dialogRegistrationView"
            :value="dialogRegistrationView"
            @input="dialogRegistrationView = $event"
            max-width="450"
            :referenceId="registrationData.referenceId"
        >
        </DialogRegistrationView>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { getDialogStateColor } from "@/utils/defaults.js";
import Helpers from "@/utils/helpers";
import RegistrationDialogModal from "../registration-dialog-modal.vue";
import { TaskDialogStatus, TaskStatus } from "@/data/globals.js";
import RegistrationListItem from "@/components/tasks/common/registration-list-item.vue";
import DialogRegistrationView from "@/components/tasks/common/dialog-registration-view.vue";
import RegistrationDialogCard from "../registration-dialog-card.vue";
import { mapMutations } from "vuex";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
export default {
    name: "TaskDialogListConent",
    components: {
        RegistrationDialogModal,
        RegistrationListItem,
        DialogRegistrationView,
        RegistrationDialogCard
    },

    data: () => ({
        TaskDialogStatus: TaskDialogStatus,
        TaskStatus: TaskStatus,
        getDialogStateColor: getDialogStateColor,
        registrationDialogModal: false,
        selectedDialog: {},
        expandRegistrationDataCard: false,
        referenceId: null,
        dialogIconWidth: 30,
        dialogRegistrationView: false
    }),
    computed: {
        baseIconColor() {
            return this.getColorByKey("grey", "lighten2");
        },
        registrationDataList() {
            let list = [];
            if (!this.registrationData) return list;
            _.forEach(this.registrationData, (value, key) => {
                let valueText = null;
                if (key === "state") {
                    valueText = this.$t(`task-status-${value}`);
                } else {
                    valueText = this.$te(value) ? this.$t(value) : value;
                }
                let obj = {
                    key: {
                        text: this.$t(key)
                    },
                    value: {
                        text: valueText
                    }
                };
                if (key && value) {
                    list.push(obj);
                }
            });
            return list;
        },
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapState("registrationDialogs", {
            dialogs: "dialogs",
            registrationData: "registrationData"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        sortedDialogs() {
            if (this.dialogs.length === 0) {
                return [];
            }
            return _.orderBy(this.dialogs, "timestamp", "desc");
        },
        newDialogs() {
            if (this.sortedDialogs.length === 0) {
                return [];
            }
            let a = this.sortedDialogs.filter(
                (dialog) => dialog.state == TaskDialogStatus.TASK_DIALOG_CREATED
            );
            return a;
        },
        openedDialogs() {
            if (this.sortedDialogs.length === 0) {
                return [];
            }
            let a = this.sortedDialogs.filter(
                (dialog) =>
                    dialog.state ==
                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
            );
            return a;
        },
        inProcessDialogs() {
            if (this.sortedDialogs.length === 0) {
                return [];
            }
            return this.sortedDialogs.filter(
                (dialog) =>
                    dialog.state ==
                    TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
            );
        },
        closedDialogs() {
            if (this.sortedDialogs.length === 0) {
                return [];
            }
            return this.sortedDialogs.filter(
                (dialog) =>
                    dialog.state == TaskDialogStatus.TASK_DIALOG_CONFIRMED
            );
        },
        openDialogsItems() {
            return [
                ...this.newDialogs,
                ...this.openedDialogs,
                ...this.inProcessDialogs
            ];
        },
        acceptedDialogsItems() {
            return this.closedDialogs;
        },
        toReplyDialogItems() {
            return [...this.newDialogs, ...this.openedDialogs];
        },
        repliedDialogItems() {
            return [...this.inProcessDialogs];
        },
        registrationDataItem() {
            let ret = {};
            if (!this.registrationData) return ret;
            ret.workingTitle = this.registrationData.workingTitle;
            ret.manufacturer = this.registrationData.manufacturer;
            ret.factoryNumber = this.registrationData.factoryNumber;
            ret.constructionYear = this.registrationData.constructionYear;
            ret.state = this.registrationData.state;
            ret.process = this.registrationData.process;
            ret.objectType = this.registrationData.objectType;
            const eqLocationAddress =
                this.registrationData.equipmentLocationAddress;
            if (eqLocationAddress) {
                let address = {};
                address.companyName = eqLocationAddress.companyName;
                address.department = eqLocationAddress.department;
                address.street = eqLocationAddress.street;
                address.houseNr = eqLocationAddress.houseNr;
                address.zipCode = eqLocationAddress.zipCode;
                address.city = eqLocationAddress.city;
                address.canton = eqLocationAddress.canton;
                address.country = eqLocationAddress.country;
                ret.address = address;
            }
            return ret;
        }
    },
    methods: {
        ...mapMutations("backofficeRegistration", [
            MUTATION_TYPES.EDIT_MESSAGE_DIALOG
        ]),
        onAddAttachmentToMsg({ attachment, msgId }) {
            this.$store.commit("registrationDialogs/addAttachmentToMsg", {
                attachment,
                msgId
            });
        },
        onDeleteAttachmentsFromMsg({ attachment, msgId }) {
            this.loadingMsg = this.$t("is-loading-msg-delete");
            this.loader().show(this.loadingMsg);
            this.$store
                .dispatch("registrationDialogs/deleteMsgAttachment", {
                    attachment,
                    msgId
                })
                .then(() => {
                    this.loader().hide();
                    this.loadingMsg = this.$t("is-loading-msg");
                })
                .catch((error) => {
                    console.error(error);
                    this.loader().hide();
                });
        },
        onDeleteMultipleAttachmentsFromMsg({ attachments, msgId }) {
            this.loadingMsg = this.$t("is-loading-msg-delete-multiple");
            this.loader().show(this.loadingMsg);
            let axiosPromises = [];
            attachments.forEach((attachment) => {
                axiosPromises.push(
                    this.$store.dispatch(
                        "registrationDialogs/deleteMsgAttachment",
                        {
                            attachment,
                            msgId
                        }
                    )
                );
            });
            Promise.all(axiosPromises)
                .then(() => {
                    this.loader().hide();
                    this.loadingMsg = this.$t("is-loading-msg");
                })
                .catch((error) => {
                    console.error(error);
                    this.loader().hide();
                    return;
                });
        },
        onDialogItemClick(dialog) {
            this.selectedDialog = dialog;
            this.registrationDialogModal = true;
        },
        getColorByKey(key, colorization = "base") {
            return Helpers.getColorByKey(key, colorization);
        },
        fetchData() {
            this.loader().show();
            this.$store
                .dispatch(
                    "registrationDialogs/getAllDialogsAndTaskDataByReferenceId",
                    this.referenceId
                )
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error("Fehler: " + error);
                    let resStatus = error.response.status;
                    if (resStatus === 404) {
                        this.$router.push("/404");
                    } else if (resStatus === 403) {
                        this.$router.push("/403");
                    } else {
                        this.$router.push("/500");
                    }
                    this.loader().hide();
                });
        },
        onAddReplyToMessageDialog(reply) {
            if (!this.referenceId) {
                console.error("Missing registration referenceId");
                return;
            }
            this.loader().show();
            reply.referenceId = this.referenceId;
            this.$store
                .dispatch("registrationDialogs/addReply", reply)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-reply-successfully-saved")
                        );
                        this.registrationDialogModal = false;
                        this.$store.commit(
                            "registrationDialogs/addReplyToDialog",
                            reply
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onConfirmAndAssingToBackoffice() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-title-confirm-and-assing-to-bo"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-msg-confirm-and-assing-to-bo")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.loader().show();
                    let data = {};
                    data.referenceId = this.referenceId;
                    this.$axiosTasks
                        .put(
                            `/assignToBackofficeOnDialogComplete/${this.referenceId}`
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                this.$toast.success(
                                    this.$t("msg-registration-assigned-to-bo")
                                );
                                this.fetchData();
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            let resStatus;
                            let resData;
                            if (error.response) {
                                resStatus = error.response.status;
                                resData = error.response.data;
                            }
                            if (resData && "code" in resData) {
                                let resErrorCode = resData.code;
                                this.$toast.error(
                                    resStatus +
                                        "(" +
                                        resErrorCode +
                                        "): " +
                                        this.$t("msg-error-occurred")
                                );
                            } else {
                                this.$toast.error(
                                    resStatus +
                                        ": " +
                                        this.$t("msg-error-occurred")
                                );
                            }
                            this.loader().hide();
                        });
                }
            });
        },
        onRegistrationDialogMsgEdit(msg) {
            this.loader().show();
            msg.referenceId = this.referenceId;
            this.$store
                .dispatch("registrationDialogs/editMessage", msg)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("mgs-dialog-edit-saved"));
                        // this[MUTATION_TYPES.EDIT_MESSAGE_DIALOG]();
                        this.$store.commit(
                            "registrationDialogs/editMessageDialog",
                            msg
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onRegistrationDialogMsgDelete(msg) {
            this.loader().show();
            msg.referenceId = this.referenceId;
            this.$store
                .dispatch("registrationDialogs/deleteMessage", msg)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("msg-delete-reply-success")
                        );
                        this.$store.commit(
                            "registrationDialogs/deleteMessagesgFromDialog",
                            msg
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        replyDisabled(state) {
            return (
                this.registrationData.state !=
                    TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS &&
                state == TaskDialogStatus.TASK_DIALOG_CREATED
            );
        },
        getDialogItemColorByState(state) {
            return this.baseIconColor + " " + this.getDialogStateColor(state);
        }
    },

    created() {
        this.referenceId = this.$route.query.ref;
        if (this.referenceId) {
            this.fetchData();
        }
    },
    watch: {
        registrationDialogModal: {
            handler: function (newValue) {
                if (!newValue) {
                    this.selectedDialog = {};
                }
            },
            immediate: true
        },
        openDialogsItems: {
            //UPDATE SELECTED DIALOG AFTER CHANGES
            handler: function (newValue) {
                if (newValue && this.selectedDialog) {
                    const _dialog = this.dialogs.find(
                        (d) => d.step === this.selectedDialog.step
                    );
                    if (_dialog) {
                        this.selectedDialog = Object.assign(
                            {},
                            this.selectedDialog,
                            _dialog
                        );
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
