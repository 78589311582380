import Helpers from "@/utils/helpers";
import { ACCOUNT_ROLE } from '@/data/globals.js'
import {
    TaskDialogStatus,
    TaskStatus
} from "@/data/globals.js";

const _getColorByKey = (key, colorization = "base") => {
    return Helpers.getColorByKey(key, colorization);
};

export function getRegistrationStateColor(state) {
    let color;
    switch (state) {
        case TaskStatus.TASK_REGISTRANT_CREATED:
            color = _getColorByKey("yellow", "base");
            break;
        case TaskStatus.TASK_REGISTRANT_IN_PROCESS:
            color = _getColorByKey("blue", "base");
            break;
        case TaskStatus.TASK_REGISTRANT_COMPLETED:
            color = _getColorByKey("yellow", "base");
            break;
        case TaskStatus.TASK_INSURER_APPROVAL_REQUIRED_POOL:
            color = _getColorByKey("teal", "base");
            break;
        case TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS:
            color = _getColorByKey("blue", "base");
            break;
        case TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES:
            color = _getColorByKey("yellow", "base");
            break;
        case TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS:
            color = _getColorByKey("blue", "base");
            break;
        case TaskStatus.TASK_CONFIRMED:
            color = _getColorByKey("primary", "base");
            break;
        case TaskStatus.TASK_EXPORT_ERROR:
            color = _getColorByKey("primary", "base");
            break;
        case TaskStatus.TASK_EXPORTED:
            color = _getColorByKey("primary", "base");
            break;
        case TaskStatus.TASK_COMPLETED:
            color = _getColorByKey("green", "base");
            break;
        default:
            color = _getColorByKey("primary", "base");
    }
    return color;
}

export function getDialogStateColor(state) {
    let color;
    switch (state) {
        case TaskDialogStatus.TASK_DIALOG_CREATED:
            color = _getColorByKey("yellow", "base");
            break;
        case TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS:
            color = _getColorByKey("blue", "base");
            break;
        case TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS:
            color = _getColorByKey("green", "base");
            break;
        case TaskDialogStatus.TASK_DIALOG_CONFIRMED:
            color = _getColorByKey("green", "base");
            break;
        default:
            color = _getColorByKey("primary", "base");
    }
    return color;
}


export function getIconAndColorByRole(role) {
    let color;
    let icon;
    switch (role) {
        case ACCOUNT_ROLE.REGISTRANT:
            color = "teal";
            icon = "mdi-account";
            break;
        case ACCOUNT_ROLE.BACKOFFICE:
            color = "blue-grey";
            icon = "mdi-account-hard-hat";
            break;
        case ACCOUNT_ROLE.ADMIN:
            color = "amber";
            icon = "mdi-shield-account";
            break;
        case ACCOUNT_ROLE.DEVELOPER:
            color = "lime accent-4";
            icon = "mdi-bug";
            break;
        case ACCOUNT_ROLE.INSURER:
            color = "teal";
            icon = "mdi-account-supervisor";
            break;
        default:
            color = null;
            icon = null;
    }
    return {icon, color};
}
