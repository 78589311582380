<template>
    <div class="fill-height">
        <v-sheet class="pa-1" v-if="data.length === 0">
            <v-skeleton-loader
                class="mx-auto"
                width="100%"
                height="100%"
                type="list-item-avatar, image"
            ></v-skeleton-loader>
        </v-sheet>
        <app-card-chart
            v-bind="$attrs"
            :chartOptions="computeChartOption"
            :showBtnDownload="!hideDownloadBtn"
            :showBtnReload="!hideReloadBtn"
            v-on="$listeners"
        >
            <!-- Dynamically inherit slots from parent -->
            <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
                <slot :name="name" v-bind="item"></slot>
            </template>
            <!-- make all slots from component reusable from parent -->
            <template v-for="(_, slot) in $slots">
                <template :slot="slot">
                    <slot :name="slot"></slot>
                </template>
            </template>
        </app-card-chart>
    </div>
</template>
<script>
export default {
    name: "BarChartWidget",
    props: {
        cardColor: {
            type: String,
            default: "white"
        },
        iconColor: {
            type: String,
            default: "success"
        },
        chartColor: {
            tpye: Array,
            default: () => []
        },
        chartHeight: {
            tpye: String,
            default: "308px"
        },
        chartWidth: {
            tpye: String,
            default: "100%"
        },
        data: {
            tpye: Array,
            default: () => []
        },
        options: {
            tpye: Array,
            default: () => []
        },
        hideReloadBtn: {
            type: Boolean,
            default: false
        },
        hideDownloadBtn: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        defaultOption() {
            return [
                ["dataset.source", this.data],
                ["color", this.chartColor],
                ["legend.show", true],
                ["xAxis.axisLabel.show", true],
                ["yAxis.axisLabel.show", true],
                ["grid.left", "2%"],
                ["grid.bottom", "5%"],
                ["grid.right", "3%"]
            ];
        },
        computeChartOption() {
            return [...this.defaultOption, ...this.options];
        }
    }
};
</script>
