var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalMovableDialog',{ref:"baseDialog",attrs:{"value":_vm.dialog,"toolbarTitle":_vm.$t('title'),"max-width":"600","toolbarIcon":"mdi-content-duplicate"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"\n                        text-h6\n                        font-weight-regular\n                        justify-space-between\n                    "},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-avatar',{staticClass:"subheading white--text mr-2",attrs:{"color":"primary lighten-2","size":"24"},domProps:{"textContent":_vm._s(_vm.step)}}),_c('span',[_vm._v(_vm._s(_vm.currentTitle))])],1),(_vm.step === 1)?_c('v-col',{attrs:{"cols":"1","align":"end"}},[_c('MenuDuplicatesCheck',{attrs:{"task":_vm.registrationData}})],1):_vm._e()],1)],1),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('base-alert',{attrs:{"type":"warning","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("warning-msg-summary"))+" ")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.registrationData.insurer)?_c('InsurerAlertMsg',{attrs:{"insurer":_vm.registrationData.insurer}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TaskSummaryWithVerification',{attrs:{"task":_vm.registrationData,"reviewVerification":_vm.reviewVerification},on:{"change:review-verification-step":function($event){return _vm.$emit(
                                                'change:review-verification-step',
                                                $event
                                            )}}})],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"type":"warning","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("warning-msg-decision"))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"6"}},[_c('base-radio-group',{attrs:{"disabled":_vm.readonlyModus,"required":_vm.isRequiredField(
                                                _vm.$v.duplicateDecision
                                            ),"label":_vm.$t('duplicate-decision-label'),"error-messages":_vm.duplicateDecisionErrors},model:{value:(_vm.duplicateDecision),callback:function ($$v) {_vm.duplicateDecision=$$v},expression:"duplicateDecision"}},[_c('v-radio',{attrs:{"label":_vm.$t(
                                                    ("duplicate-decision-" + (_vm.DUPLICATE_DECISION_TYPES.NEW))
                                                ),"value":_vm.DUPLICATE_DECISION_TYPES.NEW}}),_c('v-radio',{attrs:{"label":_vm.$t(
                                                    ("duplicate-decision-" + (_vm.DUPLICATE_DECISION_TYPES.MUTATION))
                                                ),"value":_vm.DUPLICATE_DECISION_TYPES.MUTATION}}),_c('v-radio',{attrs:{"label":_vm.$t(
                                                    ("duplicate-decision-" + (_vm.DUPLICATE_DECISION_TYPES.DUPLICATE))
                                                ),"value":_vm.DUPLICATE_DECISION_TYPES.DUPLICATE}})],1)],1),(
                                        _vm.duplicateDecision ===
                                        _vm.DUPLICATE_DECISION_TYPES.DUPLICATE
                                    )?_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"type":"warning","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t( "warning-msg-duplicate-01" ))+" ")]),_c('br'),_c('div',[_vm._v(" "+_vm._s(_vm.$t( "warning-msg-duplicate-02" ))+" ")])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","xs":"6"}},[_c('base-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#######'),expression:"'#######'"}],staticClass:"remove-spin-buttons",attrs:{"type":"number","disabled":_vm.readonlyModus,"label":_vm.$t('sap-equipment-nr-label'),"error-messages":_vm.sapEquipmentNrErrors,"required":_vm.isRequiredField(
                                                _vm.$v.sapEquipmentNr
                                            )},model:{value:(_vm.sapEquipmentNr),callback:function ($$v) {_vm.sapEquipmentNr=$$v},expression:"sapEquipmentNr"}})],1)],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(_vm.step === 1)?_c('base-btn',{attrs:{"type":"close"},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("btn-close")))]):_vm._e(),(_vm.step !== 1)?_c('base-btn',{attrs:{"type":"close","btnIcon":"mdi-arrow-left"},on:{"click":function($event){_vm.step--}}},[_vm._v(_vm._s(_vm.$t("btn-back")))]):_vm._e(),_c('v-spacer'),(_vm.step !== 2)?_c('base-btn',{attrs:{"type":"primary","btnIcon":"mdi-arrow-right"},on:{"click":function($event){_vm.step++}}},[_vm._v(_vm._s(_vm.$t("btn-continue")))]):_vm._e(),(!_vm.readonlyModus && _vm.step === 2)?_c('base-btn',{attrs:{"type":"save"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("btn-save")))]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }