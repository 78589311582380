<i18n       src="./form-close-dialog-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <v-checkbox
            v-model="acceptCloseDialog"
            :label="
                dialogState == TaskDialogStatus.TASK_DIALOG_CONFIRMED
                    ? $t('closed-accept-close-dialog-label')
                    : $t('accept-close-dialog-label')
            "
            hide-details
            class="shrink mr-2 mt-0 mb-2"
        ></v-checkbox>
        <base-select
            v-if="acceptCloseDialog"
            :items="reasons"
            v-model="reason"
            :label="
                dialogState == TaskDialogStatus.TASK_DIALOG_CONFIRMED
                    ? $t('closed-reason-label')
                    : $t('reason-label')
            "
            clearable
            :error-messages="reasonErrors"
            :required="acceptCloseDialog"
            :item-text="(item) => $t(`reasons-${item.value}`)"
        >
        </base-select>
        <base-textarea
            v-if="acceptCloseDialog && reason && reason != 4"
            v-model="note"
            w-type="note"
            :label="$t('note-label')"
            :placeholder="$t('note-placeholder')"
            clearable
            autofocus
            no-resize
            :counter="$v.note.$params.maxLength.max"
            :error-messages="noteErrors"
            :required="isRequiredField($v.note)"
        />
    </v-form>
</template>
<script>
import { TaskDialogStatus } from "@/data/globals.js";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
export default {
    name: "FormCloseDialog",
    mixins: [validateMixin],
    validations: {
        reason: {
            required
        },
        note: {
            required: requiredIf(function () {
                if (this.reason != 4) {
                    return true;
                } else {
                    return false;
                }
            }),
            maxLength: maxLength(500)
        }
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        dialogState: {
            type: [String, Number],
            default: undefined
        },
        formData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        reasons: [
            {
                value: 1
            },
            {
                value: 2
            },
            {
                value: 3
            },
            {
                value: 4
            }
        ],
        TaskDialogStatus: TaskDialogStatus
    }),
    computed: {
        acceptCloseDialog: {
            get() {
                return this.formData.acceptCloseDialog;
            },
            set(value) {
                this.$emit("update", {
                    field: "acceptCloseDialog",
                    value: value
                });
            }
        },
        note: {
            get() {
                return this.formData.note;
            },
            set(value) {
                this.$emit("update", { field: "note", value: value });
            }
        },
        reason: {
            get() {
                return this.formData.reason;
            },
            set(value) {
                this.$emit("update", { field: "reason", value: value });
            }
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.reason.$dirty) {
                return errors;
            }
            if (!this.$v.reason.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        noteErrors() {
            const errors = [];
            if (!this.$v.note.$dirty) {
                return errors;
            }
            if (!this.$v.note.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.note.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.note.$params.maxLength.max
                    })
                );
            }
            return errors;
        }
    }
};
</script>
