<template>
    <v-menu v-model="showMenu" :close-on-content-click="false" offset-y tile>
        <template #activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: attrs }">
                    <base-btn color="info" small v-bind="attrs" v-on="on">
                        {{ inputValue }}
                    </base-btn>
                </template>
                <span>{{ $t("copy-values") }}</span>
            </v-tooltip>
        </template>
        <v-sheet class="d-flex">
            <template v-for="(children, dep) in childrens">
                <v-cascader-item
                    :key="dep + 1"
                    :items="children"
                    :depth="dep"
                    :dense="dense"
                    :value="selectedItems[dep]"
                    :multiple="multiple"
                    :item-text="itemText"
                    :item-value="itemValue"
                    @select="handleItemSelect"
                />
            </template>
        </v-sheet>
    </v-menu>
</template>
<script>
import { VMenu, VSheet } from "vuetify/lib/components/";
import VCascaderItem from "./w-cascader-item.vue";
export default {
    name: "WCascader",
    components: {
        VMenu,
        VSheet,
        VCascaderItem
    },
    props: {
        items: Array,
        label: String,
        placeholder: String,
        name: String,
        dense: Boolean,
        outlined: Boolean,
        multiple: Boolean,
        clearable: Boolean,
        returnObject: Boolean,
        childrenKey: {
            type: String,
            default: "children"
        },
        seperator: {
            type: String,
            default: " / "
        },
        itemText: {
            type: String,
            default: "text"
        },
        itemValue: {
            type: String,
            default: "value"
        },
        value: {
            type: [Array, Number, String],
            default: () => []
        },
        text: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showMenu: false,
            currentDepth: 0,
            selectedItems: [],
            childrens: [this.items] // [[],[]]
        };
    },
    computed: {
        inputValue() {
            if (this.text) return this.text;
            const input = this.selectedItems
                .filter((item) => item !== null)
                .map((item) => item[this.itemText])
                .join(this.seperator);
            return input;
        }
    },
    methods: {
        computeValue() {
            const value = this.multiple
                ? this.selectedItems.map((item) => item[this.itemValue])
                : this.selectedItems[this.selectedItems.length - 1][
                      this.itemValue
                  ];
            return value;
        },
        // rebuild
        handleItemSelect({ item, depth }) {
            const childrens = item[this.childrenKey];
            this.$set(this.selectedItems, depth, item);
            // reset all grand child
            const grandDep = depth + 1;
            this.$set(this.childrens, grandDep, childrens); // make array reactive
            for (let i = 0; i < this.childrens.length; i++) {
                if (i > grandDep) {
                    this.childrens[i] = [];
                }
            }
            this.childrens = this.childrens.filter(
                (item) => item && item.length > 0
            );
            for (let i = 0; i < this.selectedItems.length; i++) {
                if (i > depth) {
                    this.selectedItems[i] = null;
                }
            }
            this.selectedItems = this.selectedItems.filter(
                (item) => item !== null
            );
            if (!childrens) {
                this.$emit("select", this.computeValue());
                this.showMenu = false;
            }
            this.$emit("input", this.computeValue());
        },
        handleClickClearable() {
            this.$emit("input", null);
        }
    }
};
</script>
