<i18n src="./global-overlay-i18n.yaml"></i18n>
<template>
    <div class="text-center">
        <v-overlay :value="isLoading" z-index="255" :opacity="opacity" light>
            <slot name="content">
                <v-row justify="center">
                    <v-col>
                        <v-progress-circular
                            color="regOfficePrimary"
                            indeterminate
                            size="64"
                        >
                        </v-progress-circular>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col>
                        <span v-if="loadingMsg" class="mt-2"
                            >{{ loadingMsg }}
                        </span>
                        <span v-else class="mt-2">
                            {{ $t("loading-data") }}
                        </span>
                    </v-col>
                </v-row>
            </slot>
        </v-overlay>
    </div>
</template>
<script>
export default {
    name: "GlobalOverlay",
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        loadingMsg: {
            type: String,
            default: null
        },
        opacity: {
            type: String,
            default: "0.7"
        }
    }
};
</script>
<style src="./global-overlay.styl" lang="stylus"></style>
