<i18n src="./faq-i18n.yaml"></i18n>
<template>
    <DialogFullheight
        v-show="dialog"
        :value="dialog"
        @input="onInput"
        :title="$t('title')"
        icon="mdi-comment-question"
    >
        <template v-slot:content>
            <FaqContent :searchIndex="searchIndex" ref="faqContent" />
        </template>
    </DialogFullheight>
</template>
<script>
export default {
    name: "FaqDialog",
    components: {
        DialogFullheight: () =>
            import("@/components/general/dialog-fullheight.vue"),
        FaqContent: () => import("./faq-content.vue")
    },

    data() {
        return {
            dialog: false,
            searchIndex: null
        };
    },

    methods: {
        onInput(value) {
            if (!value){
                this.onClose();
            }
        },
        onClose() {
            this.dialog = false;
            this.searchIndex = null;
        },
        open(searchIndex) {
            this.searchIndex = searchIndex ? searchIndex : null;
            this.dialog = true;
        }
    }
};
</script>
