import { render, staticRenderFns } from "./tasks-table.vue?vue&type=template&id=f7e488fc&"
import script from "./tasks-table.vue?vue&type=script&lang=js&"
export * from "./tasks-table.vue?vue&type=script&lang=js&"
import style0 from "./tasks-table.styl?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./tasks-table-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fbackoffice%2Fcomponents%2Ftasks-table.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/lang/defaults-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fbackoffice%2Fcomponents%2Ftasks-table.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDataTable,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VMenu})
