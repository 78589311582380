var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tokenValidated === null)?_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-card',{staticClass:"align-center justify-center mx-auto",attrs:{"flat":"","max-width":"520"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("title")))]),_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-list',{attrs:{"flat":"","disabled":"","no-gutters":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.decryptedMail)+" ")])],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{attrs:{"flat":"","min-width":"300"}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":"","no-gutters":"","fill-height":""}},[_c('v-row',_vm._l((_vm.validationImages),function(photo){return _c('v-col',{key:photo.fileName,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"d-flex",attrs:{"flat":"","tile":""}},[_c('v-card-text',[_c('v-img',{staticClass:"\n                                                                grey\n                                                                lighten-2\n                                                            ",attrs:{"src":'data:image/jpeg;base64,' +
                                                                photo.data,"lazy-src":'data:image/jpeg;base64,' +
                                                                photo.data,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"\n                                                                        fill-height\n                                                                        ma-0\n                                                                    ",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"\n                                                                mb-6\n                                                                text-center\n                                                            "},[_c('span',{domProps:{"textContent":_vm._s(
                                                                    photo
                                                                        .title[
                                                                        _vm.$i18n
                                                                            .locale
                                                                    ]
                                                                )}})])],1)],1)],1)}),1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('base-btn',{staticClass:"secondary--text",attrs:{"color":"green","block":""},on:{"click":_vm.validateToken}},[_vm._v(_vm._s(_vm.$t("yes")))])],1),_c('v-col',{attrs:{"md":"6"}},[_c('base-btn',{staticClass:"secondary--text",attrs:{"color":"red","block":""},on:{"click":_vm.disableToken}},[_vm._v(_vm._s(_vm.$t("no")))])],1)],1)],1),_c('v-container',[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("text", { supportMail: _vm.supportMail }))+" ")])])],1)],1)],1)],1):_vm._e(),(_vm.tokenValidated === true)?_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500","outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.$t("connection-confirm"))+" ")],1)],1)],1),(_vm.isAuth)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("create-or-mutate-registration")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("create-or-mutate-registration")))])],1)],1):_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("create-or-mutate-registration")))])],1)],1),(!_vm.isAuth)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("close-window")))])],1)],1):_vm._e(),(
                            _vm.$cookies.isKey('authtoken') &&
                            _vm.$cookies.isKey('mail') &&
                            _vm.isAuth
                        )?_c('v-card-actions',[_c('v-spacer'),_c('base-btn',{on:{"click":_vm.goHome}},[_vm._v(_vm._s(_vm.$t("btn-continue")))]),_c('v-spacer')],1):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.tokenValidated === false)?_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500","outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.$t("connection-refuse"))+" ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("thanks")))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("close-window")))])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }