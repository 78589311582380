<i18n src="./lang/defaults-i18n.yaml"></i18n>
<i18n src="./App-i18n.yaml"></i18n>
<template>
    <v-app>
        <router-view></router-view>
        <DialogVerifyToken
            v-if="dialogVerifyToken"
            :value="dialogVerifyToken"
            @input="dialogVerifyToken = $event"
            :validationImages="validationImages"
            v-on:verification-change="onVerificationChange"
        >
        </DialogVerifyToken>
        <GlobalOverlay
            v-if="showLoader"
            :isLoading="showLoader"
            :loadingMsg="loaderText"
        />
        <w-msg-box ref="msgBox" />
        <app-modal-history ref="historyModal" />
        <FaqDialog ref="faqDialog" />
    </v-app>
</template>
<script>
import WMsgBox from "@/components/general/WMsgBox.vue";
import DialogVerifyToken from "@/components/auth/dialog-verify-token.vue";
import GlobalOverlay from "@/components/general/global-overlay.vue";
import FaqDialog from "@/components/general/faq/faq.vue";
import { mapGetters, mapState } from "vuex";
import ProjectData from "@/data/project.json";
export default {
    name: "App",
    components: {
        DialogVerifyToken,
        GlobalOverlay,
        WMsgBox,
        FaqDialog
    },
    data: () => ({
        dialogVerifyToken: false,
        validationImages: []
    }),
    beforeCreate() {
        // check developerMode and cookiesConsent status from LocalStorage
        this.$store.commit("settings/fetchDeveloperModeFromLocalStorage");

        // set registration office config data depending on domain
        const hostname = window.location.hostname
        const allowedDomains = ProjectData.domains;
        let parts = hostname.split('.');
        let tld = parts.pop();
        let domain = parts.pop();
        let subdomain = parts.join('.');

        let idx = subdomain.lastIndexOf('-');
        let part = subdomain.substring(idx + 1);

        let configDomain = part + '.' + domain + '.' + tld;
        const domainConfig = allowedDomains[configDomain]

        let registrationOffice = null;
        let registrationOfficeLanguage = null;

        if (domainConfig && Object.keys(domainConfig).length > 0){
            registrationOffice = domainConfig.registrationOffice;
            registrationOfficeLanguage = domainConfig.language;
        } else {
            registrationOffice = ProjectData.defaultRegistrationOffice.registrationOffice;
            registrationOfficeLanguage = ProjectData.defaultRegistrationOffice.language;
            if (registrationOffice){
                console.log(`No config for Domain: ${configDomain} found. ${registrationOffice} config is set as default`)
            }
        }


        const favicon = document.getElementById("favicon");
        if (registrationOffice){
            //set favicon
            favicon.href = `/${registrationOffice.toLowerCase()}.ico`;
            //set tab title
            document.title = this.$t('default-title');
            this.$store.commit('settings/setRegistrationOffice', registrationOffice);
        } else {
            favicon.href = "/default.ico";
        }

        if (registrationOfficeLanguage){
            this.$store.commit('settings/setRegistrationOfficeLanguage', registrationOfficeLanguage);
        }
    },
    mounted() {
        this.check();
        this.$root.$msgBox = this.$refs.msgBox;
        this.$root.$historyModal = this.$refs.historyModal;
        this.$root.$faqDialog = this.$refs.faqDialog;
    },
    created(){
        if (this.showEnvMsg){
            this.$toast.info(`<div><b>${this.$t('toast-env-title')}</b></div><div>${this.$t('toast-env-msg')}</div>`, {
                dismissable: true,
                multiLine: true,
                timeout: 10000
            });
        }
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        ...mapGetters("settings", {
            registrationOffice: "getRegistrationOffice"
        }),
        ...mapState("settings", [
            "showLoader",
            "loaderText"
        ]),
        registrationOffices() {
            return ProjectData.registrationOffices || [];
        },
        showEnvMsg(){
            return process.env.NODE_ENV !== 'production'
        }
    },
    methods: {
        setRegistrationOfficeOptions(data) {
            this.$store.commit("settings/setRegistrationOfficeData", data);
            const isDarkTheme = this.$vuetify.theme.isDark;
            let theme = "light";
            if (isDarkTheme) {
                theme = "dark";
            }
            let colors = {};
            if (theme === "dark") {
                colors = data.defaultDarkColors;
            } else {
                colors = data.defaultLightColors;
            }
            if (colors) {
                this.$vuetify.theme.themes[theme].primary = colors.primary;
                this.$vuetify.theme.themes[theme].secondary = colors.secondary;
                this.$vuetify.theme.themes[theme].info = colors.info;
                this.$vuetify.theme.themes[theme].success = colors.success;
                this.$vuetify.theme.themes[theme].error = colors.error;
                this.$vuetify.theme.themes[theme].warning = colors.warning;
                this.$vuetify.theme.themes[theme].backgroundOutter =
                    colors.backgroundOutter;
                this.$vuetify.theme.themes[theme].regOfficePrimary =
                    colors.regOfficePrimary;
                this.$vuetify.theme.themes[theme].regOfficeSecondary =
                    colors.regOfficeSecondary;
                this.$vuetify.theme.themes[theme].regOfficeLink =
                    colors.regOfficeLink;
                this.$vuetify.theme.themes[theme].regOfficeHeader =
                    colors.regOfficeHeader;
                this.$vuetify.theme.themes[theme].regOfficeSubheader =
                    colors.regOfficeSubheader;
                this.$vuetify.theme.themes[theme].regOfficeTitle =
                    colors.regOfficeTitle;
                this.$vuetify.theme.themes[theme].regOfficeBtnPrimary =
                    colors.regOfficeBtnPrimary;
                this.$vuetify.theme.themes[theme].regOfficeBtnSecondary =
                    colors.regOfficeBtnSecondary;
            }
        },
        check() {
            if (
                !this.$cookies.isKey("authtoken") ||
                !this.$cookies.isKey("authtoken")
            ) {
                return;
            }
            //router takes too much time to have pathname
            if (
                window.location.pathname === "/validate-token" ||
                (!this.$router.currentRoute.name &&
                    window.location.pathname != "/")
            ) {
                this.dialogVerifyToken = false;
                return;
            }
            let token = this.$cookies.get("authtoken");
            let mail = this.$cookies.get("mail");
            this.loader().show();
            this.$axiosAuth
                .get("/isTokenValidated", {
                    params: { mail: mail, token: token }
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.dialogVerifyToken = false;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.dialogVerifyToken = true;
                    this.loader().hide();
                });
        },
        onVerificationChange(valid) {
            if (valid) {
                if (this.dialogVerifyToken) {
                    this.dialogVerifyToken = false;
                }
            }
        }
    },

    watch: {
        registrationOffice: {
            handler: function(newVal) {
                if (newVal) {
                    const registrationOfficeFiltered = this.registrationOffices.filter(
                        (regOffice) => regOffice.key === newVal
                    );
                    if (registrationOfficeFiltered.length === 1) {
                        this.setRegistrationOfficeOptions(
                            registrationOfficeFiltered[0]
                        );
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
