<template>
    <v-container fluid pa-0 ma-0>
        <div v-for="(item, idx) in items" :key="idx">
            <base-subheader>
                <v-avatar v-if="item.icon || item.svgIcon">
                    <w-icon
                        v-if="item.svgIcon"
                        :data="require(`@/assets/icons/${item.svgIcon}`)"
                        :color="item.svgIconColor"
                        width="60"
                        height="60"
                    ></w-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-avatar>
                {{ item.title }}
            </base-subheader>
            <v-row v-if="isLoading">
                <v-col
                    v-for="(subItem, subItemIdx) in item.items"
                    :key="subItemIdx"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                >
                    <v-sheet class="pa-1">
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="300"
                            height="100"
                            type="list-item-avatar-three-line"
                        ></v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-else dense class="panel-group">
                <v-col
                    v-for="(subItem, subItemIdx) in item.items"
                    :key="subItemIdx"
                    :cols="autoFlex ? undefined : 12"
                    :sm="autoFlex ? undefined : 6"
                    :md="
                        autoFlex
                            ? undefined
                            : item.items && item.items.length === 4
                            ? 3
                            : 4
                    "
                    :lg="autoFlex ? undefined : 3"
                    :xl="colsSizeXl(item)"
                >
                    <panel-group-item
                        :title="subItem.title"
                        :icon="subItem.icon"
                        :color="subItem.color"
                        :svgIcon="subItem.svgIcon"
                        :svgIconColor="subItem.color"
                        :action="subItem.action"
                        :end="subItem.data ? subItem.data : 0"
                        :duration="4200"
                    />
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
export default {
    name: "PanelGroup",
    components: {
        PanelGroupItem: () => import("./PanelGroupItem.vue")
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        autoFlex: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        colsSizeXl(item) {
            if (this.autoFlex) return undefined;
            if (item?.items?.length === 4) return 3;
            if (item?.items?.length > 4) return 2;
            if (item?.items?.length < 4) return 4;
        }
    }
};
</script>
