<i18n src="./app-card-chart-i18n.yaml"></i18n>
<template>
    <app-card-title-actions
        :actions="actions"
        v-bind="$attrs"
        v-on="$listeners"
        height="100%"
        @click:download="downloadAsImage"
    >
        <!-- Dynamically inherit slots from parent -->
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
        </template>
        <!-- make all slots from component reusable from parent -->
        <template v-for="(_, slot) in $slots">
            <template :slot="slot">
                <slot :name="slot"></slot>
            </template>
        </template>

        <template v-slot:content>
            <v-responsive class="white--text">
                <e-chart
                    :path-option="chartOptions"
                    :height="chartHeight"
                    :width="chartWidth"
                    ref="echart"
                >
                </e-chart>
            </v-responsive>
        </template>
    </app-card-title-actions>
</template>

<script>
export default {
    name: "AppCardChart",
    components: {
        EChart: () => import("@/components/devel/chart/echart")
    },
    props: {
        showBtnReload: {
            type: Boolean,
            default: false
        },
        showBtnDownload: {
            type: Boolean,
            default: false
        },
        chartHeight: {
            tpye: String,
            default: "308px"
        },
        chartWidth: {
            tpye: String,
            default: "100%"
        },
        chartOptions: {
            tpye: Array,
            default: () => []
        },
        cardActions: {
            tpye: Array,
            default: () => []
        }
    },
    computed: {
        actions() {
            let actions = [...this.cardActions];
            if (this.showBtnDownload) {
                actions.push({
                    id: "download",
                    icon: "mdi-download",
                    emit: "click:download",
                    title: this.$t("action-download")
                });
            }
            if (this.showBtnReload) {
                actions.push({
                    id: "reload",
                    emit: "click:reload",
                    icon: "mdi-reload",
                    title: this.$t("action-reload")
                });
            }
            return actions;
        }
    },
    methods: {
        downloadAsImage() {
            const refEChart = this.$refs.echart;
            const echartInstance = refEChart.chartInstance;
            const url = echartInstance.getDataURL({
                pixelRatio: 2,
                backgroundColor: "#fff"
            });
            let timeInMs = Date.now();
            let a = document.createElement("a");
            a.href = url;
            a.download = `${timeInMs}.png`;
            a.click();
            a.remove();
        }
    }
};
</script>
