<i18n src="./WDecimalField-i18n.yaml"></i18n>
<template>
    <div>
        <v-row>
            <v-col>
                <base-text-field
                    :value="v"
                    :required="required"
                    ref="input"
                    v-bind="$attrs"
                    v-on="inputListeners"
                    :readonly="$vuetify.breakpoint.smAndDown"
                    style="
                        -moz-user-select: none;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        -o-user-select: none;
                    "
                    @click="
                        $vuetify.breakpoint.smAndDown && !$attrs.disabled
                            ? (showKeyboard = true)
                            : (showKeyboard = false)
                    "
                    v-disabled-icon-focus
                >
                    <template
                        v-slot:prepend-inner
                        v-if="$vuetify.breakpoint.smAndDown && !$attrs.disabled"
                    >
                        <v-icon
                            @click="
                                $vuetify.breakpoint.smAndDown
                                    ? (showKeyboard = true)
                                    : (showKeyboard = false)
                            "
                        >
                            mdi-calculator
                        </v-icon>
                    </template>
                </base-text-field>
            </v-col>
        </v-row>
        <v-row v-if="showKeyboard">
            <v-col>
                <ModalVirtualKeyboard
                    v-if="showKeyboard"
                    keyboardType="decimal"
                    :show="showKeyboard"
                    :title="$attrs.label ? $attrs.label : undefined"
                    :fieldHint="$attrs.hint ? $attrs.hint : undefined"
                    @input="showKeyboard = $event"
                    @save="onKeyboardSaveClick"
                    :value="v === null ? '' : v"
                    :allowNegative="
                        options.minimumValue && Number(options.minimumValue) < 0
                    "
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import AutoNumeric from "autonumeric";
import _ from "lodash";
import ModalVirtualKeyboard from "@/components/general/VirtualKeyboard/Modal.vue";
export default {
    name: "WDecimalField",
    components: {
        ModalVirtualKeyboard
    },
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        showKeyboard: false,
        v: null,
        autonumOptions: {}
    }),
    methods: {
        notify() {
            this.$emit("input", this.autonum.getNumericString());
        },
        setValue(value) {
            if (isNaN(value)) {
                this.autonum.set(null);
                return;
            } else {
                this.v = value;
                this.autonum.set(value);
            }
            this.notify();
        },
        getValue() {
            return this.autonum.getNumericString();
        },
        reset() {
            this.v = null;
            this.autonum.clear();
            this.notify();
        },
        onKeyboardSaveClick(value) {
            const minimumValue = this.autonumOptions.minimumValue;
            const maximumValue = this.autonumOptions.maximumValue;
            if (minimumValue) {
                if (Number(value) < Number(minimumValue)) {
                    return this.$toast.error(
                        this.$t("msg-error-value-less-than-min", {
                            value: value,
                            min: minimumValue
                        })
                    );
                }
            }
            if (maximumValue) {
                if (Number(value) > Number(maximumValue)) {
                    return this.$toast.error(
                        this.$t("msg-error-value-greater-than-max", {
                            value: value,
                            max: maximumValue
                        })
                    );
                }
            }
            this.setValue(value);
        }
    },
    computed: {
        defaultOptions() {
            let options = {
                decimalCharacterAlternative: ".",
                decimalPlaces: 1,
                onInvalidPaste: "replace",
                decimalPlacesRawValue: 1,
                decimalPlacesShownOnBlur: 1,
                digitGroupSeparator: "'",
                emptyInputBehavior: "null"
            };
            return options;
        },
        //this method allow to use all listners from outside
        //of the component and also to override the listners inside
        inputListeners: function () {
            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function () {
                        vm.$emit("input", vm.autonum.getNumericString());
                    }
                }
            );
        },
        isDisabled() {
            return this.$parent._props.disabled || false;
        }
    },

    mounted() {
        this.autonumOptions = {};
        this.autonumOptions = _.assignIn(
            this.autonumOptions,
            this.options,
            this.defaultOptions
        );
        const input = this.$refs.input;
        const baseTextFieldInput = input.$refs.input;
        const textFieldInput = baseTextFieldInput.$refs.input;
        this.autonum = new AutoNumeric(textFieldInput, this.autonumOptions);
        this.autonum.set(this.value);
    },

    watch: {
        value: function (val) {
            if (val) {
                this.setValue(val);
            } else {
                this.reset();
            }
        }
    }
};
</script>
