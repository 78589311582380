<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./registration-dialog-modal-i18n.yaml"></i18n>
<template>
    <ModalMovableDialog
        v-if="dialog"
        :toolbarTitle="$t('title', { step: $t(`step-${step}`) })"
        :value="dialog"
        @input="$emit('input', $event)"
        toolbarIcon="mdi-comment-question"
        :clsCardActions="$vuetify.breakpoint.mdAndUp ? 'pl-12 pr-10' : null"
        :clsCardContent="$vuetify.breakpoint.mdAndUp ? 'px-10' : 'pa-3'"
        ref="modalMovableDialog"
    >
        <template v-slot:content>
            <base-alert
                type="warning"
                v-if="
                    registrationDialogData.state !=
                        TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                    formCloseDialog.acceptCloseDialog &&
                    lastMessage &&
                    !lastMessage.reply
                "
            >
                <div class="font-weight-bold">
                    {{ $t("alert-msg-open-question") }}
                </div>
            </base-alert>
            <base-alert
                dense
                type="warning"
                v-if="
                    registrationDialogData.state !=
                        TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                    !formCloseDialog.acceptCloseDialog &&
                    lastMessage &&
                    !lastMessage.reply &&
                    registrationData.lang &&
                    isBackofficeEditor
                "
            >
                <div>
                    <i18n path="registrant-prefered-lang-alert" tag="span">
                        <template #lang>
                            <span class="font-weight-bold text-uppercase"
                                >{{ $t(registrationData.lang) }} ({{
                                    registrationData.lang
                                }})</span
                            >
                        </template>
                    </i18n>
                </div>
            </base-alert>
            <app-dev-container class="my-3">
                <template v-slot:content>
                    @isBackofficeEditor: {{ isBackofficeEditor }}
                    <br />
                    @isRegistrantEditor: {{ isRegistrantEditor }}
                    <br />
                    @showDialogTextArea: {{ showDialogTextArea }}
                    <br />
                    @registrationData:
                    <tree-view
                        :data="registrationData"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                    <br />
                    @registrationDialogData:
                    <tree-view
                        :data="Object.assign({}, registrationDialogData)"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                    <br />
                    @lastMessage:
                    <tree-view
                        :data="Object.assign({}, lastMessage)"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                    <br />
                    @sortedMessages:
                    <tree-view
                        :data="Object.assign({}, sortedMessages)"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                    <br />
                    @historyMessages:
                    <tree-view
                        :data="Object.assign({}, historyMessages)"
                        :options="{ maxDepth: 0 }"
                    ></tree-view>
                </template>
            </app-dev-container>
            <!-- This components will display the messages history and the last question and reply -->
            <MessagesCard
                :historyMessages="historyMessages"
                :lastMessage="Object.assign({}, lastMessage)"
                :accountDocId="accountDocId"
                :canRegistrantEdit="canRegistrantEdit"
                :canBackofficeEdit="canBackofficeEdit"
                :editMessageModus="editMessageModus"
                @edit="onMessageEdit"
                @delete="onMessageDelete"
            />
            <!-- This components is used to allow the user to change the message text with an modal dialog -->
            <RegistrationDialogModalEdit
                :dialog="editMessageModus"
                @cancel="editMessageModus = false"
                @save="onMessageEditSave"
                @text-change="text = $event"
                :label="
                    $t('text-label', {
                        type: isRegistrantEditor ? $t('reply') : $t('question')
                    })
                "
                :placeholder="
                    $t('text-placeholder', {
                        type: isRegistrantEditor ? $t('reply') : $t('question')
                    })
                "
                :title="
                    $t('message-edit-dialog', {
                        type: isRegistrantEditor ? $t('reply') : $t('question')
                    })
                "
                :text="text"
            />
            <!-- This form is used to define if registrant need to upload some attachment that is missing or to require some missing chamber -->
            <FormAdditionalData
                v-show="
                    (requireAttachments || requireChambers) &&
                    isBackofficeEditor &&
                    registrationDialogData.state >=
                        TaskDialogStatus.TASK_DIALOG_DEFAULT &&
                    showDialogTextArea
                "
                ref="formAdditionalData"
                :disabled="disableFormAdditionalData"
                :requireAttachments="requireAttachments"
                :requireChambers="requireChambers"
                @update="onFormAdditionalDataUpdate"
                :formData="formAdditionalData"
            />
            <v-container
                v-if="msgRequireAttachments && canRegistrantEdit"
                fluid
                class="pa-0 ma-0 pb-4"
            >
                <v-alert :color="fileUploaderColor" tile outlined class="pa-0">
                    <v-list-item disabled>
                        <v-list-item-icon class="mr-4">
                            <v-icon
                                :color="
                                    uploadedCategories.length ===
                                    msgRequireAttachmentsCategories.length
                                        ? 'success'
                                        : fileUploaderColor
                                "
                                v-html="fileUploaderIcon"
                            ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                class="text-wrap"
                                :class="`${fileUploaderColor}--text`"
                                v-text="
                                    uploadedCategories.length ===
                                    msgRequireAttachmentsCategories.length
                                        ? $tc(
                                              'attachments-upload-card-title',
                                              lastMessageAttachmentsCounters[0],
                                              {
                                                  count: lastMessageAttachmentsCounters[0]
                                              }
                                          )
                                        : $t(
                                              'upload-missing-multiple',
                                              missingCategories.length
                                          )
                                "
                            >
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider
                        class="mx-2 info"
                        style="opacity: 0.22"
                    ></v-divider>

                    <v-row no-gutters>
                        <v-col class="grow">
                            <v-list flat>
                                <v-list-item
                                    v-for="cat in msgRequireAttachmentsCategories"
                                    :key="cat"
                                    @click="
                                        uploadFileCategory = cat;
                                        modalFileUploader = true;
                                    "
                                >
                                    <v-list-item-icon class="mr-4">
                                        <v-icon
                                            :color="
                                                getCategoryIconColorByErrorStateAndCategory(
                                                    cat
                                                )
                                            "
                                            v-html="
                                                getCategoryIconByErrorStateAndCategory(
                                                    cat
                                                )
                                            "
                                        ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="text-wrap"
                                            :class="
                                                getCategoryTextColorByErrorStateAndCategory(
                                                    cat
                                                )
                                            "
                                            v-text="
                                                $t(`attachment-category-${cat}`)
                                            "
                                        >
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col
                            :class="
                                !$vuetify.breakpoint.xsOnly
                                    ? 'shrink mt-4 mr-4'
                                    : 'pa-5'
                            "
                            align-self="start"
                            :cols="$vuetify.breakpoint.xsOnly ? 12 : 0"
                            v-if="
                                lastMessageAttachmentsCounters[0] > 0 &&
                                registrationDialogData.state <=
                                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                            "
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-badge
                                        :content="
                                            lastMessageAttachmentsCounters[0]
                                        "
                                        bordered
                                        overlap
                                    >
                                        <base-btn
                                            :icon="!$vuetify.breakpoint.xsOnly"
                                            :block="$vuetify.breakpoint.xsOnly"
                                            @click="
                                                dialogAttachmentsView = true
                                            "
                                        >
                                            <v-icon
                                                :left="
                                                    $vuetify.breakpoint.xsOnly
                                                "
                                                v-on="on"
                                                >mdi-file-eye</v-icon
                                            >
                                            <span
                                                v-if="
                                                    $vuetify.breakpoint.xsOnly
                                                "
                                                >{{
                                                    $t("tooltip-uploaded-files")
                                                }}</span
                                            >
                                        </base-btn>
                                    </v-badge>
                                </template>
                                <span>{{ $t("tooltip-uploaded-files") }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-container>
            <v-row v-if="isRegistrantEditor">
                <v-col cols="12" v-if="uploadFileCategory">
                    <modal-file-uploader
                        :value="modalFileUploader"
                        @input="modalFileUploader = $event"
                        :docId="registrationData.docId"
                        :fileCategory="uploadFileCategory"
                        @upload:success="onUploadSuccess"
                        :url="$axiosBinary.defaults.baseURL + '/dialog/'"
                        :params="fileUploaderParams"
                    ></modal-file-uploader>
                </v-col>
            </v-row>
            <v-row v-if="dialogAttachmentsView">
                <v-col>
                    <DialogAttachments
                        :value="dialogAttachmentsView"
                        @input="dialogAttachmentsView = $event"
                        :readonlyModus="!isRegistrantEditor"
                        :attachments="
                            Object.assign({}, lastMessage.attachments)
                        "
                        @attachment:delete="onDeleteAttachment"
                        @attachment:delete-multiple="onDeleteMultipleAttachment"
                    />
                </v-col>
            </v-row>
            <v-row v-if="showDialogTextArea">
                <v-col>
                    <base-textarea
                        v-model="text"
                        ref="textArea"
                        :label="
                            $t('text-label', {
                                type: isRegistrantEditor
                                    ? $t('reply')
                                    : $t('question')
                            })
                        "
                        :placeholder="
                            $t('text-placeholder', {
                                type: isRegistrantEditor
                                    ? $t('reply')
                                    : $t('question')
                            })
                        "
                        clearable
                        autofocus
                        no-resize
                        :counter="$v.text.$params.maxLength.max"
                        :error-messages="textErrors"
                        :required="isRequiredField($v.text)"
                    />
                </v-col>
            </v-row>
            <v-row
                v-show="isBackofficeEditor && registrationDialogData.state > 10"
            >
                <v-col>
                    <!-- This form is used to close the dialog and can only see on the bo mode -->
                    <FormCloseDialog
                        ref="formCloseDialog"
                        :disabled="
                            registrationDialogData.state ==
                            TaskDialogStatus.TASK_DIALOG_CONFIRMED
                        "
                        @update="onFormCloseDialogUpdate"
                        :formData="formCloseDialog"
                    >
                    </FormCloseDialog>
                </v-col>
            </v-row>
            <v-row v-if="isBackofficeEditor && showDialogTextArea">
                <v-col>
                    <!-- This component ist used to help the bo employee with pre formulated text in different languages -->
                    <PreFormulatedQuestions
                        @change="onPreFormulatedQuestionsChange"
                        :step="step"
                        :registrationData="registrationData"
                    />
                </v-col>
            </v-row>
        </template>
        <template
            v-slot:actions
            v-if="
                registrationDialogData.state <
                    TaskDialogStatus.TASK_DIALOG_CONFIRMED &&
                (canBackofficeEdit || canRegistrantEdit)
            "
        >
            <v-spacer />
            <base-btn
                v-if="
                    showDialogTextArea &&
                    !formCloseDialog.acceptCloseDialog &&
                    registrationDialogData.state >
                        TaskDialogStatus.TASK_DIALOG_DEFAULT
                "
                :block="$vuetify.breakpoint.smAndDown"
                @click="onAddMessageToDialog"
                type="save"
            >
                {{
                    $t("btn-save-and-close", {
                        type: isRegistrantEditor ? $t("reply") : $t("question")
                    })
                }}
            </base-btn>
            <base-btn
                v-if="
                    isBackofficeEditor &&
                    !isRegistrantEditor &&
                    formCloseDialog.acceptCloseDialog
                "
                :block="$vuetify.breakpoint.smAndDown"
                @click="onAcceptAndClose"
                type="save"
            >
                {{ $t("btn-accept-and-close") }}
            </base-btn>
            <base-btn
                v-if="
                    registrationDialogData.state ===
                        TaskDialogStatus.TASK_DIALOG_DEFAULT &&
                    registrationDialogData.messages.length === 0
                "
                :block="$vuetify.breakpoint.smAndDown"
                @click="onAddMessageToDialog"
                type="save"
            >
                {{ $t("btn-dialog-create") }}
            </base-btn>
        </template>
    </ModalMovableDialog>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { TaskDialog, DialogMessage, Message } from "@/models";
import { mapGetters } from "vuex";
import _ from "lodash";
import RegistrationDialogModalEdit from "./registration-dialog-modal-edit.vue";
import { TaskStatus, TaskDialogStatus } from "@/data/globals.js";
import ModalMovableDialog from "@/components/general/modal-movable-dialog.vue";
import PreFormulatedQuestions from "./components/pre-formulated-questions.vue";
import Helpers from "@/utils/helpers";
import validateMixin from "@/mixins/validate";
export default {
    name: "RegistrationDialogModal",
    mixins: [validateMixin],
    validations: {
        text: {
            required,
            maxLength: maxLength(500)
        },
        dialogMessage: ["text"]
    },
    components: {
        RegistrationDialogModalEdit,
        ModalMovableDialog,
        PreFormulatedQuestions,
        MessagesCard: () => import("./components/messages-card.vue"),
        FormAdditionalData: () =>
            import("./components/form-additional-data.vue"),
        FormCloseDialog: () => import("./components/form-close-dialog.vue"),
        ModalFileUploader: () =>
            import("@/components/general/modal-file-uploader.vue"),
        DialogAttachments: () =>
            import("@/components/general/dialog-attachments.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        docId: {
            type: String,
            default: null
        },
        registrationDialogData: {
            type: Object,
            default: () => {}
        },
        registrationData: {
            type: Object,
            default: () => {}
        },
        step: {
            type: [String, Number],
            default: null
        },
        requireAttachments: {
            type: Boolean,
            default: false
        },
        requireChambers: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        TaskDialogStatus: TaskDialogStatus,
        TaskStatus: TaskStatus,
        question: new Message(),
        dialog: false,
        text: null,
        formCloseDialog: {
            acceptCloseDialog: false,
            reason: null,
            note: null
        },
        formAdditionalData: {
            requireAttachmentsCategories: [],
            attachmentsRequired: false,
            chambersRequired: false
        },
        editMessageModus: false,
        expandAttachmentsCard: false,
        missingFilesErrors: false,
        errorMsg: null,
        d: {},
        uploadFileCategory: null,
        modalFileUploader: null,
        dialogAttachmentsView: false
    }),
    computed: {
        lastMessageAttachmentsValid() {
            if (!this.$v.dialogMessage.$dirty) {
                return null;
            }
            if (this.$v.dialogMessage.$invalid) {
                return false;
            }
            const cloneUploadCategories = _.cloneDeep(this.uploadedCategories);
            const cloneMsgRequireAttachmentsCategories = _.cloneDeep(
                this.msgRequireAttachmentsCategories
            );
            if (
                !_.isEqual(
                    cloneUploadCategories.sort(),
                    cloneMsgRequireAttachmentsCategories.sort()
                )
            ) {
                return false;
            }
            if (
                this.uploadedCategories.length !==
                this.msgRequireAttachmentsCategories.length
            ) {
                return false;
            }
            return true;
        },
        fileUploaderColor() {
            if (
                this.lastMessageAttachmentsValid === false &&
                this.missingCategories.length > 0
            ) {
                return "error";
            } else if (
                this.uploadedCategories.length ===
                this.msgRequireAttachmentsCategories.length
            ) {
                return "primary";
            } else {
                return "info";
            }
        },
        fileUploaderIcon() {
            if (
                this.lastMessageAttachmentsValid === false &&
                this.missingCategories.length > 0
            ) {
                return "mdi-alert";
            } else if (
                this.uploadedCategories.length ===
                this.msgRequireAttachmentsCategories.length
            ) {
                return "mdi-check-circle";
            } else {
                return "mdi-information";
            }
        },
        missingCategories() {
            let missingCategories = [];
            if (
                this.lastMessage.requireAttachments &&
                this.isRegistrantEditor
            ) {
                let allowedCategories =
                    this.lastMessage.requireAttachmentsCategories;
                if (Object.keys(this.lastMessageAttachments).length > 0) {
                    if (allowedCategories.length > 0) {
                        allowedCategories.forEach((category) => {
                            if (category in this.lastMessageAttachments) {
                                return;
                            } else {
                                missingCategories.push(category);
                                return;
                            }
                        });
                    }
                } else {
                    missingCategories =
                        this.lastMessage.requireAttachmentsCategories;
                }
            }
            return missingCategories;
        },
        uploadedCategories() {
            if (
                this.lastMessage.requireAttachments &&
                this.isRegistrantEditor
            ) {
                return Object.keys(this.lastMessageAttachments);
            }
            return [];
        },
        ...mapGetters("auth", {
            isAuth: "isAuthenticated",
            accountDocId: "accountDocId",
            userFullname: "userFullname"
        }),
        isBackofficeEditor() {
            if (
                this.registrationData.state ==
                TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
            ) {
                return false;
            }
            if (this.accountDocId === this.registrationDialogData.boAccountId) {
                return true;
            }
            if (
                "backoffice" in this.registrationData &&
                "accountId" in this.registrationData.backoffice &&
                this.registrationData.backoffice.accountId === this.accountDocId
            ) {
                return true;
            }
            return false;
        },
        isRegistrantEditor() {
            if (this.registrationData.state === (null || undefined))
                return false;
            if (
                Number(this.registrationData.state) !==
                TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
            )
                return false;
            if (
                "boAccountId" in this.registrationDialogData &&
                this.accountDocId != this.registrationDialogData.boAccountId
            ) {
                return true;
            }
            if (
                "question" in this.lastMessage &&
                "createdById" in this.lastMessage.question &&
                this.accountDocId == this.lastMessage.question.createdById
            ) {
                return true;
            }
            if (
                !this.lastMessage.reply ||
                ("reply" in this.lastMessage &&
                    !this.lastMessage.reply.createdId)
            ) {
                return true;
            }
            return false;
        },
        canSomeoneEdit() {
            if (this.registrationDialogData.state === (null || undefined))
                return false;
            if (
                Number(this.registrationDialogData.state) ===
                TaskDialogStatus.TASK_DIALOG_CONFIRMED
            )
                return false;
            return true;
        },
        canRegistrantEdit() {
            if (!this.isRegistrantEditor || !this.canSomeoneEdit) return false;
            if (
                "reply" in this.lastMessage &&
                this.lastMessage.reply.text &&
                "question" in this.lastMessage &&
                this.lastMessage.question.text
            ) {
                return true;
            }
            if (
                ![
                    TaskDialogStatus.TASK_DIALOG_CREATED,
                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                ].includes(Number(this.registrationDialogData.state))
            ) {
                return false;
            }
            return true;
        },
        canBackofficeEdit() {
            if (!this.isBackofficeEditor || !this.canSomeoneEdit) return false;
            if (
                !this.lastMessage &&
                !("question" in this.lastMessage) &&
                !this.lastMessage.question.text &&
                !("reply" in this.lastMessage)
            ) {
                return false;
            }
            return true;
        },
        showDialogTextArea() {
            let show = true;
            if (
                (this.canRegistrantEdit || this.canBackofficeEdit) &&
                this.editMessageModus
            ) {
                return false;
            }
            if (
                this.isBackofficeEditor &&
                this.registrationDialogData.state ==
                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
            ) {
                return false;
            }
            if (this.formCloseDialog.acceptCloseDialog) {
                return false;
            }
            if (
                this.isBackofficeEditor &&
                this.registrationDialogData.state !=
                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS &&
                !this.formCloseDialog.acceptCloseDialog
            ) {
                show = true;
                if (
                    this.registrationDialogData.state === 0 &&
                    this.lastMessage &&
                    this.lastMessage.question
                ) {
                    return false;
                }
            }
            if (
                this.isRegistrantEditor &&
                this.registrationDialogData.state ==
                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS &&
                !this.formCloseDialog.acceptCloseDialog
            ) {
                show = true;
            }
            if (!this.isRegistrantEditor && !this.isBackofficeEditor) {
                return false;
            }
            if (
                this.isRegistrantEditor &&
                this.registrationData.state !=
                    TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
            ) {
                return false;
            }
            if (
                this.isBackofficeEditor &&
                ![
                    TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS,
                    TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES
                ].includes(this.registrationData.state)
            ) {
                return false;
            }
            if (this.isBackofficeEditor && "question" in this.lastMessage) {
                if (
                    "reply" in this.lastMessage &&
                    this.lastMessage.reply.text
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            if (
                this.isRegistrantEditor &&
                ![
                    TaskDialogStatus.TASK_DIALOG_CREATED,
                    TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                ].includes(this.registrationDialogData.state)
            ) {
                return false;
            }
            return show;
        },
        textErrors() {
            const errors = [];
            if (!this.$v.text.$dirty) {
                return errors;
            }
            if (!this.$v.text.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.text.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.text.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        sortedMessages() {
            if (
                !this.registrationDialogData ||
                !("messages" in this.registrationDialogData) ||
                this.registrationDialogData.messages.length === 0
            ) {
                return [];
            }
            return _.orderBy(
                this.registrationDialogData.messages,
                "lastChangeTimestamp"
            );
        },
        lastMessage() {
            if (this.sortedMessages.length === 0) {
                return {};
            }
            const lastIdx = _.findLastIndex(this.sortedMessages);
            return this.sortedMessages[lastIdx];
        },
        historyMessages() {
            if (this.sortedMessages.length === 0) {
                return [];
            }
            let items = _.clone(this.sortedMessages);
            items.pop();
            return items;
        },
        disableFormAdditionalData() {
            const allowedSatates = [
                TaskDialogStatus.TASK_DIALOG_DEFAULT,
                this.TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
            ];
            if (
                allowedSatates.includes(
                    Number(this.registrationDialogData.state)
                )
            )
                return false;
            return true;
        },
        hasLastMessageReply() {
            if (!this.lastMessage && !_.isObject(this.lastMessage))
                return false;
            if (!_.has(this.lastMessage, "reply")) return false;
            if (!_.has(this.lastMessage.reply, "text")) return false;
            return true;
        },
        hasLastMessageQuestion() {
            if (!this.lastMessage && !_.isObject(this.lastMessage))
                return false;
            if (!_.has(this.lastMessage, "question")) return false;
            if (!_.has(this.lastMessage.question, "text")) return false;
            return true;
        },
        msgRequireAttachments() {
            if (!this.lastMessage) return false;
            if (this.hasLastMessageReply) return false;
            if (this.hasLastMessageQuestion) {
                return this.lastMessage.requireAttachments;
            }
            return false;
        },
        msgRequireAttachmentsCategories() {
            if (this.msgRequireAttachments) {
                return this.lastMessage.requireAttachmentsCategories;
            }
            return [];
        },
        fileUploaderParams() {
            if (!this.lastMessage) return {};
            return {
                msgId: this.lastMessage.id
            };
        },
        lastMessageAttachments() {
            if (!this.lastMessage || !this.lastMessage.attachments) return {};
            return this.lastMessage.attachments;
        },
        lastMessageAttachmentsCounters() {
            return Helpers.getAttachmentsCounters(this.lastMessageAttachments);
        },
        lastMessageAttachmentsCountersByCategory() {
            return Helpers.getAttachmentsCountersByCategory(
                this.lastMessageAttachments
            );
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", false);
            this.reset();
        },
        reset() {
            this.$v.$reset();
            this.question = new Message();
            this.reply = new Message();
            this.text = null;
            this.expandDialogHistoryCard = false;
            this.formCloseDialog = {
                acceptCloseDialog: false,
                reason: null,
                note: null
            };
            this.formAdditionalData = {
                requireAttachmentsCategories: [],
                attachmentsRequired: false,
                chambersRequired: false
            };
        },
        scrollToFirstErrorWithScrollOptions() {
            let modalMovableDialog = this.$refs.modalMovableDialog;
            let modalMovableDialogCardTextRef =
                modalMovableDialog.$refs.cardText;
            let scrollOptions = {
                container: modalMovableDialogCardTextRef
            };
            this.scrollToFirstError(scrollOptions);
        },
        onAddMessageToDialog() {
            this.$v.dialogMessage.$touch();
            if (
                this.lastMessage.requireAttachments &&
                this.isRegistrantEditor
            ) {
                if (!this.isValid()) {
                    return this.$toast.error(this.$t("has-errors-msg"));
                }
            }
            if (this.$v.dialogMessage.$invalid) {
                this.scrollToFirstErrorWithScrollOptions();
                this.$toast.error(this.$t("question-has-errors-msg"));
            } else {
                let now = this.$moment.utc().format();
                let messageId = this.lastMessage.id;
                if (
                    !messageId &&
                    this.registrationDialogData.state !==
                        TaskDialogStatus.TASK_DIALOG_DEFAULT
                ) {
                    console.error("ERROR: Missing messageID");
                }
                if (
                    this.requireAttachments &&
                    this.formAdditionalData.attachmentsRequired
                ) {
                    this.question.requireAttachments =
                        this.formAdditionalData.attachmentsRequired;
                    this.question.requireAttachmentsCategories =
                        this.formAdditionalData.requireAttachmentsCategories;
                } else {
                    delete this.question.requireAttachments;
                    delete this.question.requireAttachmentsCategories;
                }
                if (
                    this.requireChambers &&
                    this.formAdditionalData.chambersRequired
                ) {
                    this.question.requireChambers =
                        this.formAdditionalData.chambersRequired;
                } else {
                    delete this.question.requireChambers;
                }
                if (
                    this.registrationDialogData.state ==
                    TaskDialogStatus.TASK_DIALOG_DEFAULT
                ) {
                    this.question.text = this.text;
                    this.question.timestamp = now;
                    this.question.createdBy = this.userFullname;
                    this.question.createdBy = this.userFullname;
                    let message = new DialogMessage();
                    message.question = this.question;
                    let dialog = new TaskDialog();
                    if (
                        this.requireAttachments &&
                        this.formAdditionalData.attachmentsRequired
                    ) {
                        dialog.requireAttachments =
                            this.formAdditionalData.attachmentsRequired;
                        dialog.requireAttachmentsCategories =
                            this.formAdditionalData.requireAttachmentsCategories;
                    } else {
                        delete dialog.requireAttachments;
                        delete dialog.requireAttachmentsCategories;
                    }
                    if (
                        this.requireChambers &&
                        this.formAdditionalData.chambersRequired
                    ) {
                        dialog.requireChambers =
                            this.formAdditionalData.chambersRequired;
                    } else {
                        delete dialog.requireChambers;
                    }
                    dialog.messages.push(message);
                    dialog.createdBy = this.userFullname;
                    dialog.changedBy = this.userFullname;
                    dialog.createdById = this.accountDocId;
                    dialog.step = this.step;
                    this.$emit("create", dialog);
                } else if (
                    this.registrationDialogData.state ==
                        TaskDialogStatus.TASK_DIALOG_CREATED ||
                    this.registrationDialogData.state ==
                        TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                ) {
                    this.reply.messageId = messageId;
                    this.reply.text = this.text;
                    this.reply.timestamp = now;
                    this.reply.createdBy = this.userFullname;
                    this.reply.createdById = this.accountDocId;
                    this.$emit("add-reply", this.reply);
                } else if (
                    this.registrationDialogData.state ==
                    TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
                ) {
                    this.question.messageId = messageId;
                    this.question.text = this.text;
                    this.question.timestamp = now;
                    this.question.createdBy = this.userFullname;
                    this.question.createdById = this.accountDocId;
                    this.$emit("add-question", this.question);
                } else {
                    return;
                }
                this.reset();
                return;
            }
        },
        onAcceptAndClose() {
            if (!this.$refs.formCloseDialog) return false;
            this.$refs.formCloseDialog.$v.$touch();
            if (this.$refs.formCloseDialog.$v.$invalid) {
                this.scrollToFirstErrorWithScrollOptions();
                this.$toast.error(this.$t("has-errors-msg"));
            } else {
                let data = {};
                data.note = this.formCloseDialog.note;
                data.reason = this.formCloseDialog.reason;
                if (this.sortedMessages.length > 0) {
                    data.messageId = this.sortedMessages[0].id;
                    this.$emit("accept-and-close-dialog", data);
                }
            }
        },
        getNameInitials(name) {
            if (!name) return;
            var initials = name.match(/\b\w/g) || [];
            initials = (
                (initials.shift() || "") + (initials.pop() || "")
            ).toUpperCase();
            return initials;
        },
        setAcceptedDialogData() {
            this.formCloseDialog.acceptCloseDialog = true;
            this.formCloseDialog.note = this.registrationDialogData.note;
            this.formCloseDialog.reason = this.registrationDialogData.reason;
        },
        onMessageEdit() {
            this.editMessageModus = !this.editMessageModus;
            if (
                this.isBackofficeEditor &&
                "question" in this.lastMessage &&
                this.lastMessage.question.text &&
                !("reply" in this.lastMessage)
            ) {
                this.text = this.lastMessage.question.text;
            }
            if (this.isRegistrantEditor && !this.isBackofficeEditor) {
                this.text = this.lastMessage.reply.text;
            }
        },
        onMessageDelete() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-message-title", {
                        type: this.isRegistrantEditor
                            ? this.$t("reply")
                            : this.$t("question")
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-delete-message-msg", {
                    type: this.isRegistrantEditor
                        ? this.$t("reply")
                        : this.$t("question")
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    let message = {};
                    let messageId = this.lastMessage.id;
                    if (messageId) {
                        message.messageId = messageId;
                        this.$emit("delete", message);
                    }
                }
            });
        },
        onMessageEditSave() {
            this.editMessageModus = !this.editMessageModus;
            let message = {};
            let messageId = this.lastMessage.id;
            if (messageId) {
                message.messageId = messageId;
                message.text = this.text;
                this.$emit("edit", message);
            }
            this.reset();
        },
        onDeleteMultipleAttachment(attachments) {
            const msgId = this.lastMessage.id;
            this.$emit("attachment:delete-multiple", { attachments, msgId });
        },
        onDeleteAttachment(attachment) {
            const msgId = this.lastMessage.id;
            this.$emit("attachment:delete", { attachment, msgId });
        },
        onUploadSuccess(file) {
            if (file.xhr) {
                let data = file.xhr.responseText;
                if (data) {
                    let jsonResponse = JSON.parse(data);

                    if (jsonResponse && jsonResponse.data) {
                        const attachment = jsonResponse.data;
                        if (attachment) {
                            this.$emit("attachment:add", {
                                attachment,
                                msgId: this.lastMessage.id
                            });
                        }
                    }
                }
            }
        },
        execInsertText(insertText) {
            insertText = insertText.toString();
            if (!insertText.length) return;
            const textareaRef = this.$refs.textArea;
            const textareaBaseRef = textareaRef.$children[0];
            const textarea = textareaBaseRef.$refs.input;
            const sentence = textarea.value;
            const len = sentence.length;
            let pos = textarea.selectionStart;
            if (pos === undefined) {
                pos = 0;
            }

            const before = sentence.substr(0, pos);
            const after = sentence.substr(pos, len);

            this.text = before + insertText + after;

            this.$nextTick().then(() => {
                textarea.selectionStart = pos + insertText.length;
            });
        },
        onPreFormulatedQuestionsChange(val) {
            if (val) {
                return this.execInsertText(val);
            }
        },
        onFormAdditionalDataUpdate({ field, value }) {
            if (field in this.formAdditionalData) {
                this.formAdditionalData[field] = value;
            }
        },
        onFormCloseDialogUpdate({ field, value }) {
            if (field in this.formCloseDialog) {
                this.formCloseDialog[field] = value;
            }
        },
        getCategoryIconByErrorStateAndCategory(category) {
            const defaultIcon = "mdi-upload";
            if (!category) return defaultIcon;
            if (this.uploadedCategories.includes(category)) {
                return "mdi-check";
            }
            return defaultIcon;
        },
        getCategoryIconColorByErrorStateAndCategory(category) {
            const defaultColor = "info";
            if (!category) return defaultColor;
            if (this.uploadedCategories.includes(category)) {
                return "success";
            }
            if (
                this.lastMessageAttachmentsValid === false &&
                this.missingCategories.includes(category)
            ) {
                return "error";
            }
            return defaultColor;
        },
        getCategoryTextColorByErrorStateAndCategory(category) {
            const defaultColor = "info--text";
            if (!category) return defaultColor;
            if (this.uploadedCategories.includes(category)) {
                return "success--text";
            }
            if (
                this.lastMessageAttachmentsValid === false &&
                this.missingCategories.includes(category)
            ) {
                return "error--text";
            }
            return defaultColor;
        },
        isValid() {
            if (this.$v.dialogMessage.$invalid) {
                return false;
            }
            if (
                this.uploadedCategories.length !==
                this.msgRequireAttachmentsCategories.length
            ) {
                return false;
            }
            return true;
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
                this.reset();
                if (this.registrationDialogData) {
                    if (
                        this.registrationDialogData.state ==
                        TaskDialogStatus.TASK_DIALOG_CONFIRMED
                    ) {
                        this.setAcceptedDialogData();
                    }
                }
            },
            immediate: true
        },
        msgRequireAttachments: {
            handler: function (newValue) {
                if (newValue) {
                    this.formAdditionalData = {
                        requireAttachmentsCategories:
                            this.msgRequireAttachmentsCategories,
                        attachmentsRequired: Boolean(
                            this.msgRequireAttachments
                        ),
                        chambersRequired: false
                    };
                }
            },
            immediate: true
        },
        uploadedCategories: {
            handler: function (categories) {
                if (
                    categories.length ===
                    this.msgRequireAttachmentsCategories.length
                ) {
                    this.expandAttachmentsCard = false;
                } else {
                    this.expandAttachmentsCard = true;
                }
            },
            immediate: true
        }
    }
};
</script>
