import { render, staticRenderFns } from "./modal-file-uploader.vue?vue&type=template&id=39a5887e&"
import script from "./modal-file-uploader.vue?vue&type=script&lang=js&"
export * from "./modal-file-uploader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/defaults-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fgeneral%2Fmodal-file-uploader.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./modal-file-uploader-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Fgeneral%2Fmodal-file-uploader.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VSpacer})
