export const TaskDialogStatus = {
    TASK_DIALOG_DEFAULT: 0,
    TASK_DIALOG_CREATED: 10,
    TASK_DIALOG_REGISTRANT_IN_PROCESS: 20,
    TASK_DIALOG_BACKOFFICE_IN_PROCESS: 30,
    TASK_DIALOG_CONFIRMED: 40
};

export const TaskStatus = {
    TASK_REGISTRANT_CREATED: 10,
    TASK_REGISTRANT_IN_PROCESS: 20,
    TASK_REGISTRANT_COMPLETED: 30,
    TASK_INSURER_APPROVAL_REQUIRED_POOL: 31,
    TASK_VERIFICATION_REGISTRAR_IN_PROCESS: 40,
    TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES: 45,
    TASK_VERIFICATION_REGISTRANT_IN_PROCESS: 50,
    TASK_CONFIRMED: 60,
    TASK_EXPORT_ERROR: 69,
    TASK_EXPORTED: 70,
    TASK_COMPLETED: 80
};

export const TaskStatusItems = [
    TaskStatus.TASK_REGISTRANT_CREATED,
    TaskStatus.TASK_REGISTRANT_IN_PROCESS,
    TaskStatus.TASK_REGISTRANT_COMPLETED,
    TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS,
    TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES,
    TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS,
    TaskStatus.TASK_CONFIRMED,
    TaskStatus.TASK_EXPORT_ERROR,
    TaskStatus.TASK_EXPORTED,
    TaskStatus.TASK_COMPLETED
];

// Betreiber
export const OPERATOR = "OPERATOR";
// Im Auftrag vom Betreiber
export const OPERATOR_BEHALF = "OPERATOR_BEHALF";

export const ROLE_TYPES = {
    OPERATOR,
    OPERATOR_BEHALF
};

export const ROLE_TYPES_LIST = [OPERATOR, OPERATOR_BEHALF];

// Firma
export const COMPANY = "COMPANY";
// Private
export const PRIVATE = "PRIVATE";

export const ADDRESS_TYPES = {
    COMPANY,
    PRIVATE
};

export const ADDRESS_TYPES_LIST = [COMPANY, PRIVATE];

// Anmeldung
export const REGISTRATION = "REGISTRATION";
// Mutation
export const MUTATION = "MUTATION";
// Abmeldung
export const DEREGISTRATION = "DEREGISTRATION";

export const PROCESS_TYPES = {
    REGISTRATION,
    MUTATION,
    DEREGISTRATION
};

export const PROCESS_TYPES_LIST = [REGISTRATION, MUTATION, DEREGISTRATION];

//Flaschen in stationären Löschanlagen
export const FIRE_BOTTLES_EQUIPMENT = "FIRE_BOTTLES_EQUIPMENT";
// Inbetriebnahme eines Druckgerätes
export const PRESSURE_EQUIPMENT = "PRESSURE_EQUIPMENT";
// Inbetriebnahme von Rohrleitungen für Dampf oder Heisswasser.
export const PIPES_STEAM_HOT_WATER = "PIPES_STEAM_HOT_WATER";

export const OBJECT_TYPES = {
    FIRE_BOTTLES_EQUIPMENT,
    PRESSURE_EQUIPMENT,
    PIPES_STEAM_HOT_WATER
};

export const OBJECT_TYPES_LIST = [
    FIRE_BOTTLES_EQUIPMENT,
    PRESSURE_EQUIPMENT,
    PIPES_STEAM_HOT_WATER
];

export const REGISTRANT = "REGISTRANT";
export const BACKOFFICE = "BACKOFFICE";
export const ADMIN = "ADMIN";
export const DEVELOPER = "DEVELOPER";
export const INSURER = "INSURER";

export const ACCOUNT_ROLE = {
    REGISTRANT,
    BACKOFFICE,
    ADMIN,
    DEVELOPER,
    INSURER
};

export const ACCOUNT_ROLE_LIST = [
    REGISTRANT,
    BACKOFFICE,
    ADMIN,
    DEVELOPER,
    INSURER
];

export const DOMAINS_DE = ["dgvv", "d-dgvv", "t-dgvv"];

export const DOMAINS_FR = ["ouep", "d-ouep", "t-ouep"];

export const DOMAINS_IT = ["duap", "d-duap", "t-duap"];

export const DOMAINS = {
    DE: DOMAINS_DE,
    FR: DOMAINS_FR,
    IT: DOMAINS_IT
};

export const LINKS_DGVV = {
    DE: "https://www.seco.admin.ch/seco/de/home/Arbeit/Arbeitsbedingungen/Produktsicherheit/Druckgeraete-und-einfache-Druckbehaelter.html",
    FR: "https://www.seco.admin.ch/seco/fr/home/Arbeit/Arbeitsbedingungen/Produktsicherheit/Druckgeraete-und-einfache-Druckbehaelter.html",
    IT: "https://www.seco.admin.ch/seco/it/home/Arbeit/Arbeitsbedingungen/Produktsicherheit/Druckgeraete-und-einfache-Druckbehaelter.html"
};

export const LINKS_SVTI = {
    DE: "https://www.svti.ch/kesselinspektorat/aufgaben-taetigkeiten/inspektion-druckgeraeten",
    FR: "https://www.svti.ch/fr/inspection-des-chaudieres/taches-services/inspection-des-equipements-sous-pression",
    IT: "https://www.svti.ch/it/ispettorato-delle-caldaie/compiti-attivita/ispezione-delle-attrezzature-pressione"
};

export const LINKS_SUVA = {
    DE: "https://www.suva.ch/de-CH/material/Factsheets/druckgeraeteverwendungs-verordnung",
    FR: "https://www.suva.ch/fr-CH/material/Factsheets/druckgeraeteverwendungs-verordnung",
    IT: "https://www.suva.ch/it-CH/material/Factsheets/druckgeraeteverwendungs-verordnung"
};

export const EXTERNAL_LINKS = {
    dgvv: LINKS_DGVV,
    svti: LINKS_SVTI,
    suva: LINKS_SUVA
};

export const EXTERNAL_LINKS_LIST = [LINKS_DGVV, LINKS_SVTI, LINKS_SUVA];

export const DUPLICATE_DECISION_TYPES = {
    NEW: "NEW",
    MUTATION: "MUTATION",
    DUPLICATE: "DUPLICATE"
};

export const REGISTRATION_LOAD_CHANGE_TYPES = {
    MAX_ALLOWED_QUANTITY: "MAX_ALLOWED_QUANTITY",
    TOTAL_USAGE_DURATION: "TOTAL_USAGE_DURATION"
};

export const ATTACHMENTS_TYPES = {
    DRAWING: "DRAWING",
    ASSEMBLY_CERTIFICATE: "ASSEMBLY_CERTIFICATE",
    TYPE_EXAMINATION: "TYPE_EXAMINATION",
    DECLARATION_OF_CONFORMITY_AND_CERTIFICATES:
        "DECLARATION_OF_CONFORMITY_AND_CERTIFICATES",
    OPERATING_AND_MAINTENANCE_INSTRUCTIONS:
        "OPERATING_AND_MAINTENANCE_INSTRUCTIONS",
    SAFETY_VALVE_SETTING_CERTIFICATE: "SAFETY_VALVE_SETTING_CERTIFICATE",
    ADDITIONAL: "ADDITIONAL",
    INSPECTION_PLAN: "INSPECTION_PLAN"
};

export const DEREGISTRATION_REASONS = {
    SCRAPPED: "SCRAPPED",
    DISASSEMBLED_ON_WAREHOUSE: "DISASSEMBLED_ON_WAREHOUSE",
    DISASSEMBLED_LEAVED_ON_LOCATION: "DISASSEMBLED_LEAVED_ON_LOCATION",
    LOCATION_CHANGED: "LOCATION_CHANGED",
    SOLD: "SOLD"
};

//Dampf- und HW-Kessel
export const STEAM_AND_HW_BOILERS = "STEAM_AND_HW_BOILERS";
// Elektrokessel
export const ELECTRIC_BOILER = "ELECTRIC_BOILER";
// Druckbehälter
export const PRESSURE_TANK = "PRESSURE_TANK";

export const OBJECT_CATEGORIES = {
    STEAM_AND_HW_BOILERS,
    ELECTRIC_BOILER,
    PRESSURE_TANK
};

export const HISTORY_CODES = {
    SAP_EXPORT_RESPONSE_ERROR: "SAP_EXPORT_RESPONSE_ERROR"
};

export const HISTORY_CATEGORIES = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    COMPLETE: "COMPLETE",
    UPLOAD: "UPLOAD",
    SAP: "SAP",
    MAIL: "MAIL"
};

export const INSPECTION_TYPES = {
    SVTI: "SVTI",
    SPECIALIST: "SPECIALIST"
};

export const ADDRESS_COPY_TYPES = {
    COPY_ADDRESS_OPERATOR: "COPY_ADDRESS_OPERATOR",
    COPY_ADDRESS_BILL: "COPY_ADDRESS_BILL",
    COPY_ADDRESS_OWNER: "COPY_ADDRESS_OWNER"
};

//Dampf- und HW-Kessel
export const SIZE_CLASS_STEAM_AND_HW_BOILERS = {
    // Inhalt in Liter
    contentInLiters: [
        { id:  1, range: [    0,   400] },
        { id:  2, range: [  400,   500] },
        { id:  3, range: [  500,  2000] },
        { id:  4, range: [ 2000,  3000] },
        { id:  5, range: [ 3000,  5000] },
        { id:  6, range: [ 5000, 10000] },
        { id:  7, range: [10000, 25000] },
        { id:  8, range: [25000, 30000] },
        { id:  9, range: [30000, 40000] },
        { id: 10, range: [40000, 50000] },
        { id: 11, range: [50000, 60000] },
        { id: 12, range: [60000       ] }
    ]
};

// Elektrokessel
export const SIZE_CLASS_ELECTRIC_BOILER = {
    // Inhalt in Liter
    contentInLiters: [
        { id: 1, range: [     0,   1000] },
        { id: 2, range: [  1000,   2000] },
        { id: 3, range: [  2000,   5000] },
        { id: 4, range: [  5000,  10000] },
        { id: 5, range: [ 10000,  20000] },
        { id: 6, range: [ 20000,  50000] },
        { id: 7, range: [ 50000, 100000] },
        { id: 8, range: [100000        ] }
    ]
};
// Druckbehälter
export const SIZE_CLASS_PRESSURE_TANK = {
    // Inhalt in Liter
    contentInLiters: [
        { id:  1, range: [      0,    1000] },
        { id:  2, range: [   1000,    5000] },
        { id:  3, range: [   5000,   10000] },
        { id:  4, range: [  10000,   15000] },
        { id:  5, range: [  15000,   20000] },
        { id:  6, range: [  20000,   50000] },
        { id:  7, range: [  50000,  100000] },
        { id:  8, range: [ 100000,  200000] },
        { id:  9, range: [ 200000,  400000] },
        { id: 10, range: [ 400000, 1000000] },
        { id: 11, range: [1000000, 2000000] },
        { id: 12, range: [2000000         ] }
    ]
};

export const SIZE_CLASS_ITEMS_BY_OBJECT_CATEGORY = (objectCategory) => {
    // Grössenklassen
    let items = [];
    switch (objectCategory) {
        case OBJECT_CATEGORIES.STEAM_AND_HW_BOILERS:
            items = SIZE_CLASS_STEAM_AND_HW_BOILERS.contentInLiters;
            break;
        case OBJECT_CATEGORIES.ELECTRIC_BOILER:
            items = SIZE_CLASS_ELECTRIC_BOILER.contentInLiters;
            break;
        case OBJECT_CATEGORIES.PRESSURE_TANK:
            items = SIZE_CLASS_PRESSURE_TANK.contentInLiters;
            break;
        default:
            break;
    }

    return items;
};

export const ADDRESS_IDS = {
    OPERATOR: "OPERATOR",
    BILL: "BILL",
    OWNER: "OWNER"
};

export const AVAILABLE_LOCATION_SEARCH_COUNTRIES = [
    "CH",
    "LI"
]
