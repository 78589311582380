<template>
    <v-container
        :class="$vuetify.breakpoint.xsOnly ? 'pl-2' : undefined"
        fluid
        pa-0
        ma-0
        pb-4
    >
        <v-row class="my-0" no-gutters>
            <v-col class="py-0 mt-0">
                <base-title color="primary" class="pb-0 mb-0">
                    <slot name="title">
                        {{ title }}
                    </slot>
                </base-title>
            </v-col>
        </v-row>
        <v-row class="py-0 mt-0" no-gutters v-if="description">
            <v-col>
                <slot name="description">
                    {{ description }}
                </slot>
            </v-col>
        </v-row>
        <v-row class="py-0 mt-0" no-gutters>
            <v-col>
                <v-divider />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "StepContentTitle",
    props: {
        title: {
            type: String,
            default: null,
            required: true
        },
        description: {
            type: String,
            default: null
        }
    }
};
</script>
