<i18n src="./WTelNumberField-i18n.yaml"></i18n>
<template>
    <vuetify-phone-number-input
        v-bind="bindProps"
        @input="onInput"
        @validate="onValidate"
        @country-changed="onCountryChange"
        ref="phoneInput"
        :required="required"
    >
    </vuetify-phone-number-input>
</template>
<script>
import VuetifyPhoneNumberInput from "./external-components/vuetify-phone-number-input/vuetify-phone-number-input.vue";
export default {
    name: "WTelNumberField",
    components: {
        VuetifyPhoneNumberInput
    },
    props: {
        value: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        phone: {
            number: null,
            valid: false,
            country: undefined
        },
        regionCode: "CH"
    }),
    methods: {
        validate() {
            this.$refs.phoneInput.validate();
        },
        scrollToFirstError() {
            this.$refs.phoneInput.scrollToFirstError();
        },
        isValid() {
            return this.phone.valid;
        },
        onCountryChange(obj) {
            this.regionCode = obj.iso2;
            this.$emit("country-changed", obj);
        },
        onValidate(phone) {
            this.phone.valid = phone.valid;
            this.$emit("validate", phone);
        },
        onInput(formattedNumber, { number, valid, country }) {
            this.phone.number = number.international;
            this.phone.valid = valid;
            this.phone.country = country && country.iso2;
            this.$emit("input", this.phone);
        },
        clear() {
            this.phone = {};
            this.phone.number = "";
            this.phone.valid = false;
            this.phone.country = undefined;
            this.$emit("input", this.phone);
        },
        reset() {
            this.$refs.phoneInput.reset();
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                if (newVal && newVal != this.phone.number) {
                    this.phone.number = newVal;
                    this.$nextTick(() => {
                        this.$refs.phoneInput.setValue(newVal);
                    });
                }
            },
            immediate: true
        }
    },
    computed: {
        bindProps() {
            let data = {};
            data.hint = this.hint;
            return Object.assign(this.defaultOptions, this.$attrs, data);
        },
        defaultOptions() {
            return {
                defaultCountry: "CH",
                preferredCountries: ["CH", "IT", "FR", "DE", "AT", "LI"],
                // onlyCountries: ["CH", "IT", "FR", "DE", "AT", "LI"],
                mode: "international",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: true,
                enabledFlags: true,
                ignoredCountries: [],
                autocomplete: "on", //off
                name: "number",
                maxLen: 25,
                dropdownOptions: { disabledDialCode: true, tabindex: 0 },
                inputOptions: { showDialCode: false, tabindex: 0 },
                enabledCountryCode: false,
                validCharactersOnly: true
            };
        },
        hint() {
            let hint = "";
            switch (this.regionCode) {
                case "CH":
                    hint = "Ex: +41 70 000 00 00";
                    break;
                case "IT":
                    hint = "Ex: +39 123 45 67 89";
                    break;
                case "FR":
                    hint = "Ex: +33 123 45 67 89";
                    break;
                case "DE":
                    hint = "Ex: +49 123 45 67 89";
                    break;
                case "AT":
                    hint = "Ex: +43 123 45 67 89";
                    break;
                case "LI":
                    hint = "Ex: +423 123 45 67 89";
                    break;
                default:
                    hint = this.$t("enter-valid-number");
            }
            return hint;
        }
    }
};
</script>
