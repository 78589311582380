import {
    axiosSpecialistsCompanies,
    axiosAccountSettings,
    axiosManufacturers,
    axiosTasks,
    axiosDbConfigApi
} from "@/api/axios-instances";
import _ from "lodash";

const state = {
    companiesCounter: 0,
    accountsCounter: 0,
    manufacturersCounter: 0,
    accountRolesCounter: [],
    registrationOfficesCounter: [],
    registrationsProcessCounters: {
        registrant: 0,
        backoffice: 0,
        completed: 0,
        deleted: 0
    },
    registrationsObjectTypesCounter: {},
    registrationsProcessTypesCounter: {},
    config: {}
};

const mutations = {
    setSpecialistCompaniesCounter(state, counter) {
        state.companiesCounter = counter;
    },
    setAccountsCounter(state, counter) {
        state.accountsCounter = counter;
    },
    setManufacturersCounter(state, counter) {
        state.manufacturersCounter = counter;
    },
    setAccountRolesCounter(state, counter) {
        state.accountRolesCounter = counter;
    },
    setRegistrationOfficesCounter(state, counter) {
        state.registrationOfficesCounter = counter;
    },
    setRegistrationsProcessCounters(
        state,
        { registrant, backoffice, completed, deleted }
    ) {
        if (_.isNumber(registrant)) {
            state.registrationsProcessCounters.registrant = registrant;
        }
        if (_.isNumber(backoffice)) {
            state.registrationsProcessCounters.backoffice = backoffice;
        }
        if (_.isNumber(completed)) {
            state.registrationsProcessCounters.completed = completed;
        }
        if (_.isNumber(deleted)) {
            state.registrationsProcessCounters.deleted = deleted;
        }
    },
    setRegistrationsObjectTypesCounter(state, counter) {
        state.registrationsObjectTypesCounter = counter;
    },
    setRegistrationsProcessTypesCounter(state, counter) {
        state.registrationsProcessTypesCounter = counter;
    },
    setConfig(state, payload) {
        state.config = Object.assign({}, payload);
    },
    updateConfigField(state, { field, value }) {
        state.config[field] = value;
    },
    updateConfig(state, payload) {
        state.config = Object.assign(state.config, payload);
    }
};

const getters = {};

const actions = {
    fetchSpecialistCompaniesCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
                .get("/counter")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counter = resData.data;
                        commit("setSpecialistCompaniesCounter", counter);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAccountsCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .get("/counter")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counter = resData.data;
                        commit("setAccountsCounter", counter);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchManufacturersCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosManufacturers
                .get("/counter")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counter = resData.data;
                        commit("setManufacturersCounter", counter);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAccountRolesCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosAccountSettings
                .get("/rolesCounter")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counter = resData.data;
                        commit("setAccountRolesCounter", counter);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchRegistrationOfficesCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get("/registrationOfficesCounter")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counter = resData.data;
                        commit("setRegistrationOfficesCounter", counter);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchRegistrationsProcessCounters: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get("/tasksProcessStatesCounter")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counters = resData.data;
                        commit("setRegistrationsProcessCounters", counters);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchRegistrationsObjectTypesCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get("/counter/ObjectType")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counters = resData.data;
                        commit("setRegistrationsObjectTypesCounter", counters);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchRegistrationsProcessTypesCounter: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosTasks
                .get("/counter/ProcessType")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let counters = resData.data;
                        commit("setRegistrationsProcessTypesCounter", counters);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchConfig: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosDbConfigApi
                .get("/")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let config = resData.data;
                        commit("setConfig", config);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    updateConfig: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosDbConfigApi
                .put("/", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let config = resData.data;
                        commit("updateConfig", config);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
