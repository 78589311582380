var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('base-btn',{attrs:{"icon":"","color":"yellow"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('base-btn',{attrs:{"icon":"","color":"info"},on:{"click":_vm.validate}},[_c('v-icon',[_vm._v("mdi-alert-circle-check-outline")])],1),_vm._v(" @Address: "),_c('tree-view',{attrs:{"data":_vm.address,"options":{ maxDepth: 1 }}}),_vm._v(" @Validation: "),_c('tree-view',{attrs:{"data":_vm.$v,"options":{ maxDepth: 0 }}})]},proxy:true}])}),_c('v-form',{attrs:{"readonly":_vm.readonlyModus,"disabled":_vm.disabled}},[_c('v-row',[_c('v-col',[_c('base-radio-group',{staticClass:"py-0 mt-0",attrs:{"value":_vm.address.addressType,"required":_vm.isRequiredField(_vm.$v.addressType),"error-messages":_vm.addressTypeErrors,"label":_vm.computedAddressTypeLabel},on:{"change":_vm.onAddressTypeChange}},[_c('v-radio',{staticClass:"pl-2",attrs:{"value":_vm.ADDRESS_TYPES.COMPANY},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v(" mdi-office-building ")]),_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.computedAddressTypeCompanyLabel)+" ")])]},proxy:true}])}),_c('v-radio',{staticClass:"pl-2",attrs:{"value":_vm.ADDRESS_TYPES.PRIVATE},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v(" mdi-account ")]),_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.computedAddressTypePrivateLabel)+" ")])]},proxy:true}])})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WCountrySelect',{directives:[{name:"show",rawName:"v-show",value:(_vm.address.addressType),expression:"address.addressType"}],ref:"country",attrs:{"preferred-countries":[
                        'CH',
                        'LI',
                        'DE',
                        'FR',
                        'IT',
                        'AT'
                    ],"error-messages":_vm.countryErrors,"readonly":_vm.readonlyModus,"disabled":_vm.disabled},on:{"click:clear":function($event){_vm.country = null}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1)],1),_c('uid-search',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.address.country === 'CH' &&
            _vm.address.addressType === _vm.ADDRESS_TYPES.COMPANY
        ),expression:"\n            address.country === 'CH' &&\n            address.addressType === ADDRESS_TYPES.COMPANY\n        "}],ref:"uidSearch",staticClass:"pb-6",attrs:{"closeSearchCard":"","readonlyModus":_vm.readonlyModus,"disabled":_vm.disabled},on:{"confirm-selected-company":_vm.onConfirmSelectedCompany,"change-manual-entry":function($event){return _vm.$vuetify.goTo(_vm.$refs.formCommunication)}}}),_c('form-company-address',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.address.addressType === _vm.ADDRESS_TYPES.COMPANY && _vm.address.country
        ),expression:"\n            address.addressType === ADDRESS_TYPES.COMPANY && address.country\n        "}],ref:"formCompanyAddress",attrs:{"country":_vm.address.country,"addressType":_vm.address.addressType,"formData":_vm.address,"readonlyModus":_vm.readonlyModus,"disabled":_vm.disabled,"required":_vm.address.addressType === _vm.ADDRESS_TYPES.COMPANY},on:{"field-change":_vm.onFieldChange,"update:formData":function($event){_vm.address=$event},"update:form-data":function($event){_vm.address=$event}}}),_c('form-private-address',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.address.addressType === _vm.ADDRESS_TYPES.PRIVATE && _vm.address.country
        ),expression:"\n            address.addressType === ADDRESS_TYPES.PRIVATE && address.country\n        "}],ref:"formPrivateAddress",attrs:{"country":_vm.address.country,"formData":_vm.address,"readonlyModus":_vm.readonlyModus,"disabled":_vm.disabled,"required":_vm.address.addressType === _vm.ADDRESS_TYPES.PRIVATE},on:{"field-change":_vm.onFieldChange,"update:formData":function($event){_vm.address=$event},"update:form-data":function($event){_vm.address=$event}}}),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.address.country && _vm.address.addressType),expression:"address.country && address.addressType"}],staticClass:"ml-3 py-0",attrs:{"disabled":_vm.disabled,"label":_vm.communicationTitle,"readonlyModus":_vm.readonlyModus},on:{"change":_vm.onCommunicationSwitchChange},model:{value:(_vm.communication),callback:function ($$v) {_vm.communication=$$v},expression:"communication"}}),_c('form-communication',{directives:[{name:"show",rawName:"v-show",value:(_vm.communication && _vm.address.country && _vm.address.addressType),expression:"communication && address.country && address.addressType"}],ref:"formCommunication",staticClass:"pt-5 mb-0 pb-0",attrs:{"required":_vm.communication,"formData":_vm.address.communication,"readonlyModus":_vm.readonlyModus,"disabled":_vm.disabled},on:{"field-change":_vm.onCommunicationFieldsChange,"update:formData":function($event){return _vm.$set(_vm.address, "communication", $event)},"update:form-data":function($event){return _vm.$set(_vm.address, "communication", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }