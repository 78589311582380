<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-working-title-i18n.yaml"></i18n>
<template>
    <v-container fluid py-0 my-0>
        <!-- Rolle des Meldenden auswählen -->
        <v-row no-gutters>
            <v-col cols="12">
                <span class="subtitle-1">
                    {{ $t("working-title-info") }}
                </span>

                <base-text-field
                    v-model="computedWorkingTitle"
                    :error-messages="workingTitleErrors"
                    :counter="$v.workingTitle.$params.maxLength.max"
                    :label="$t('working-title-label')"
                    :placeholder="$t('working-title-placeholder')"
                    :required="isRequiredField($v.workingTitle)"
                ></base-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import validateMixin from "@/mixins/validate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
    name: "ContentWorkingTitle",
    mixins: [validateMixin],
    props: {
        workingTitle: {
            type: String
        }
    },
    validations: {
        workingTitle: {
            maxLength: maxLength(50),
            required
        }
    },
    methods: {
        onFieldChange(field, value) {
            this.$emit("value:change", { field, value });
        }
    },
    computed: {
        computedWorkingTitle: {
            get() {
                return this.workingTitle;
            },
            set(value) {
                this.onFieldChange("workingTitle", value);
            }
        },
        workingTitleErrors() {
            const errors = [];
            if (!this.$v.workingTitle.$dirty) {
                return errors;
            }
            if (!this.$v.workingTitle.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.workingTitle.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.workingTitle.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    }
};
</script>
