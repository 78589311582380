<i18n src="./form-address-base-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, computedAddress)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @validate:
                <tree-view
                    :data="Object.assign({}, $v)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-times</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <v-row dense>
            <v-col cols="12" sm="6">
                <WCountrySelect
                    ref="country"
                    :preferred-countries="['CH', 'LI', 'DE', 'FR', 'IT', 'AT']"
                    :value="computedAddress.country"
                    @input="emitOnFieldInput('country', $event)"
                    @click:clear="emitOnFieldInput('country', null)"
                    :error-messages="countryErrors"
                    :readonly="readonly"
                    :disabled="disabled"
                ></WCountrySelect>
            </v-col>
            <template v-if="computedAddress.country">
                <v-col>
                    <base-radio-group
                        :value="computedAddress.addressType"
                        row
                        dense
                        :error-messages="addressTypeErrors"
                        @change="onChangeAddressType"
                    >
                        <v-radio
                            :label="$t('address-type-company-label')"
                            value="COMPANY"
                        ></v-radio>
                        <v-radio
                            :label="$t('address-type-private-label')"
                            value="PRIVATE"
                        ></v-radio>
                    </base-radio-group>
                </v-col>
                <v-col
                    cols="12"
                    v-if="
                        computedAddress.addressType === 'COMPANY' &&
                        computedAddress.country === 'CH' &&
                        (!readonly && !disabled)
                    "
                >
                    <WCompanySearch
                        :disabled="readonly"
                        @reset="onClearCompanySearch"
                        @change="onChangeCompanySearch"
                        @is-loading:="
                            (isLoading) => {
                                isLoading ? loader().show() : loader().hide();
                            }
                        "
                    />
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
import validateMixin from "@/mixins/validate";
import { required } from "vuelidate/lib/validators";
import mixin from "./mixin";
export default {
    name: "FormAddressBase",
    mixins: [validateMixin, mixin],
    components: {
        WCountrySelect: () => import("@/components/fields/WCountrySelect.vue"),
        WCompanySearch: () => import("@/components/fields/WCompanySearch.vue")
    },

    validations() {
        return {
            computedAddress: {
                country: {
                    required
                },
                addressType: {
                    required
                }
            }
        };
    },
    computed: {
        addressTypeErrors() {
            const errors = [];
            if (!this.$v.computedAddress.addressType.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.addressType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        countryErrors() {
            const errors = [];
            if (!this.$v.computedAddress.country.$dirty) {
                return errors;
            }
            if (!this.$v.computedAddress.country.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    methods: {
        onChangeAddressType(value) {
            this.$emit("change:address-type", value);
            this.emitOnFieldInput("addressType", value);
        },
        onChangeCompanySearch(value) {
            this.$emit("change:company-search", value);
        },
        onClearCompanySearch() {
            this.companySearch = {};
        }
    }
};
</script>
