<i18n       src="./WNumericField-i18n.yaml"></i18n>
<template>
    <div>
        <v-row>
            <v-col>
                <base-text-field
                    :value="v"
                    ref="input"
                    v-bind="$attrs"
                    v-on="inputListeners"
                    :readonly="$vuetify.breakpoint.smAndDown"
                    style="
                        -moz-user-select: none;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        -o-user-select: none;
                    "
                    class="input-field-remove-selection"
                    @click.prevent="
                        $vuetify.breakpoint.smAndDown
                            ? (showKeyboard = true)
                            : (showKeyboard = false)
                    "
                    :required="required"
                    v-disabled-icon-focus
                >
                    <template
                        v-slot:prepend-inner
                        v-if="$vuetify.breakpoint.smAndDown && !$attrs.disabled"
                    >
                        <v-icon
                            @click="
                                $vuetify.breakpoint.smAndDown
                                    ? (showKeyboard = true)
                                    : (showKeyboard = false)
                            "
                        >
                            mdi-calculator
                        </v-icon>
                    </template>
                </base-text-field>
            </v-col>
        </v-row>
        <v-row v-if="showKeyboard">
            <v-col>
                <ModalVirtualKeyboard
                    keyboardType="integer"
                    :title="$attrs.label ? $attrs.label : undefined"
                    :show="showKeyboard"
                    @input="showKeyboard = $event"
                    @save="onKeyboardSaveClick"
                    :value="value === null ? '' : value"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import AutoNumeric from "autonumeric";
import _ from "lodash";
import ModalVirtualKeyboard from "@/components/general/VirtualKeyboard/Modal.vue";
export default {
    name: "WNumericField",
    components: {
        ModalVirtualKeyboard
    },
    inheritAttrs: false,
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        options: {
            type: Object,
            default: () => {}
        },
        minValue: {
            type: [String, Number],
            default: null
        },
        maxValue: {
            type: [String, Number],
            default: null
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showKeyboard: false,
        v: null,
        autonumOptions: {}
    }),
    methods: {
        notify() {
            if (this.autonum) {
                this.$emit("input", this.autonum.getNumericString());
            }
        },
        setValue(value) {
            if (isNaN(value)) {
                this.autonum.set(null);
                return;
            } else {
                this.v = value;
                if (this.autonum) {
                    this.autonum.set(value);
                } else {
                    this.$nextTick(() => {
                        this.autonum.set(value);
                    });
                }
            }
            this.notify();
        },
        getValue() {
            return this.autonum ? this.autonum.getNumericString() : null;
        },
        reset() {
            this.$emit("input", null);
            this.autonum.clear();
            this.notify();
        },
        onKeyboardSaveClick(value) {
            const minimumValue = this.autonumOptions.minimumValue;
            const maximumValue = this.autonumOptions.maximumValue;
            if (minimumValue) {
                if (Number(value) < Number(minimumValue)) {
                    return this.$toast.error(
                        this.$t("msg-error-value-less-than-min", {
                            value: value,
                            min: minimumValue
                        })
                    );
                }
            }
            if (maximumValue) {
                if (Number(value) > Number(maximumValue)) {
                    return this.$toast.error(
                        this.$t("msg-error-value-greater-than-max", {
                            value: value,
                            max: maximumValue
                        })
                    );
                }
            }
            this.setValue(value);
        }
    },
    computed: {
        defaultOptions() {
            let options = {
                decimalCharacterAlternative: ".",
                digitGroupSeparator: "'",
                emptyInputBehavior: "null",
                decimalPlaces: 0,
                onInvalidPaste: "replace",
                decimalPlacesRawValue: 0,
                decimalPlacesShownOnBlur: 0,
                decimalPlacesShownOnFocus: 0
            };

            if (this.minValue != null) {
                options.minimumValue = this.minValue.toString();
            }
            if (this.maxValue != null) {
                options.maximumValue = this.maxValue.toString();
            }
            return options;
        },
        //this method allow to use all listners from outside
        //of the component and also to override the listners inside
        inputListeners: function () {
            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function () {
                        if (vm.autonum) {
                            vm.$emit("input", vm.autonum.getNumericString());
                        }
                    }
                }
            );
        }
    },
    mounted() {
        this.autonumOptions = {};
        this.autonumOptions = _.assignIn(
            this.autonumOptions,
            this.options,
            this.defaultOptions
        );
        const input = this.$refs.input;
        const baseTextFieldInput = input.$refs.input;
        const textFieldInput = baseTextFieldInput.$refs.input;
        this.autonum = new AutoNumeric(textFieldInput, this.autonumOptions);
        this.autonum.set(this.value);
    },
    watch: {
        value: {
            handler: function (newVal) {
                if (newVal != null || newVal != "") {
                    this.setValue(newVal);
                } else {
                    this.reset();
                }
            },
            immediate: true
        }
    }
};
</script>
