<template>
    <v-container fluid class="px-0 mx-0 pt-0">
        <template v-for="message in historyMessages">
            <div :key="message.reply.timestamp">
                <MessageCardQuestion
                    v-if="message.question"
                    :message="message.question"
                    :accountDocId="accountDocId"
                    :requireAttachments="message.requireAttachments"
                    :requireAttachmentsCategories="
                        message.requireAttachmentsCategories
                    "
                />
                <MessageCardReply
                    v-if="message.reply"
                    :message="message.reply"
                    :accountDocId="accountDocId"
                    :attachments="message.attachments"
                />
                <v-spacer></v-spacer>
            </div>
        </template>
    </v-container>
</template>
<script>
export default {
    name: "MessagesHistoryContainer",
    components: {
        MessageCardReply: () => import("./message-card-reply.vue"),
        MessageCardQuestion: () => import("./message-card-question.vue")
    },
    props: {
        historyMessages: {
            type: Array,
            default: () => [],
            required: true
        },
        accountDocId: {
            type: String,
            default: null
        }
    }
};
</script>
