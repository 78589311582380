var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grow d-flex flex-column flex-nowrap"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('base-header',[_c('v-icon',[_vm._v("mdi-view-dashboard")]),_vm._v(" "+_vm._s(_vm.$t("title"))+" ")],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-subheader',[_vm._v(_vm._s(_vm.$t("groups-base-data-title")))])],1),_c('v-col',[_c('PanelGroup',{attrs:{"items":_vm.groups,"isLoading":_vm.isLoading,"auto-flex":""}})],1)],1),_c('v-row',{staticClass:"grow pt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-subheader',[_vm._v(_vm._s(_vm.$t("groups-informations-title")))])],1),(_vm.totalRegistrationsCounter > 0)?[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('pie-chart',{staticClass:"fill-height",attrs:{"title":_vm.$t('registration-office-counter-chart-title'),"subtitle":_vm.$t('registration-office-counter-chart-subtitle'),"data":_vm.registrationOfficesCounterFormatted,"chart-color":[_vm.color.yellow.base, _vm.color.cyan.base],"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"title-extra",fn:function(){return [_c('count-to',{staticClass:"pl-2 font-weight-bold",attrs:{"start-val":0,"end-val":_vm.totalRegistrationsCounter,"duration":3200}})]},proxy:true}],null,false,3943800807)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('pie-chart',{staticClass:"fill-height",attrs:{"title":_vm.$t('registration-object-types-counter-chart-title'),"subtitle":_vm.$t(
                            'registration-object-types-counter-chart-subtitle'
                        ),"data":_vm.registrationsObjectTypesCounterFormatted,"chart-color":[
                        _vm.color.teal.base,
                        _vm.color.lime.base,
                        _vm.color.orange.base
                    ],"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"title-extra",fn:function(){return [_c('count-to',{staticClass:"pl-2 font-weight-bold",attrs:{"start-val":0,"end-val":_vm.totalRegistrationsObjectTypesCounter,"duration":3200}})]},proxy:true}],null,false,3230838585)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('pie-chart',{staticClass:"fill-height",attrs:{"title":_vm.$t('task-process-types-counter-chart-title'),"subtitle":_vm.$t('task-process-types-counter-chart-subtitle'),"data":_vm.registrationsProcessTypesCounterFormatted,"chart-color":[
                        _vm.color.brown.base,
                        _vm.color.amber.base,
                        _vm.color.cyan.base
                    ],"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"title-extra",fn:function(){return [_c('count-to',{staticClass:"pl-2 font-weight-bold",attrs:{"start-val":0,"end-val":_vm.totalRegistrationsProcessTypesCounter,"duration":3200}})]},proxy:true}],null,false,456240391)})],1)]:[_c('v-col',{attrs:{"cols":12,"lg":4,"md":6,"sm":12}},[_c('base-alert',{attrs:{"type":"info"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("alert-msg-not-tasks-recorded"))+" ")])])],1)]],2),_c('v-row',{staticClass:"grow pt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-subheader',[_vm._v(_vm._s(_vm.$t("groups-monitoring-title")))])],1),(_vm.totalRegistrationsCounter > 0)?_c('v-col',{attrs:{"cols":12,"l":6}},[_c('CalendarChartTaskRegistration',{staticClass:"fill-height",attrs:{"isLoading":_vm.isLoading}})],1):_vm._e()],1),_c('v-row',{staticClass:"pt-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-subheader',[_vm._v(_vm._s(_vm.$t("groups-tasks-process-title")))])],1),_c('v-col',[_c('PanelGroup',{attrs:{"items":_vm.groupsRegistrationsProcess,"isLoading":_vm.isLoading,"auto-flex":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }