<i18n src="./dialog-search-filters-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <BaseDialog
        :value="value"
        @input="$emit('input', $event)"
        :title="$t('title-dialog-filter')"
        max-width="600"
        icon="mdi-filter-plus"
    >
        <template v-slot:content>
            <v-container grid-list-md text-xs-center>
                <v-row dense v-if="roles && roles.length > 0" class="pl-2">
                    <v-col cols="12">
                        <div class="text-h6">{{ $t("subtitle") }}</div>
                    </v-col>
                    <v-col cols="12" md="6" v-for="role in roles" :key="role">
                        <v-checkbox
                            v-model="rolesFilter"
                            :label="$t(`account-role-${role}`)"
                            color="primary"
                            :value="role"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BaseDialog>
</template>
<script>
import { ACCOUNT_ROLE_LIST } from "@/data/globals.js";
export default {
    name: "DialogSearchFilters",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        filterRoles: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        roles: ACCOUNT_ROLE_LIST
    }),
    computed: {
        rolesFilter: {
            get() {
                return this.filterRoles;
            },
            set(value) {
                this.$emit("filter-roles:change", value);
            }
        }
    }
};
</script>
