export default {
    components: {
        CopyToClipboard: () =>
            import("@/components/general/utlis/copy-to-clipboard.vue")
    },
    props: {
        task: {
            type: Object,
            default: () => {},
            required: true
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        },
        expandable: {
            type: Boolean,
            default: false
        }
    }
};
