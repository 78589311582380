<i18n src="./form-private-address-i18n.yaml"></i18n>
<template>
    <v-container fill-height fluid class="pa-0 ma-0">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                <base-btn icon color="info" @click="validate">
                    <v-icon>mdi-alert-circle-check-outline</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <StepContentTitle :title="$t('title')" />
        <!-- Kontaktperson -->
        <v-form :readonly="readonlyModus" :disabled="disabled">
            <v-container fill-height fluid class="pa-0 ma-0 pt-2">
                <v-row>
                    <v-col class="pb-0 mb-0" cols="12" md="2">
                        <base-select
                            :items="salutationItems"
                            v-model="salutation"
                            :label="$t('salutation-label')"
                            item-value="key"
                            :error-messages="salutationErrors"
                            :required="isRequiredField($v.address.salutation)"
                            :item-text="
                                (item) => $t(item.value.title[$i18n.locale])
                            "
                        >
                        </base-select>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12" md="5">
                        <!-- firstName -->
                        <base-text-field
                            v-model="firstName"
                            :label="$t('first-name-label')"
                            :error-messages="firstNameErrors"
                            :counter="
                                $v.address.firstName.$params.maxLength.max
                            "
                            :required="isRequiredField($v.address.firstName)"
                        ></base-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12" md="5">
                        <!-- familyName -->
                        <base-text-field
                            v-model="familyName"
                            :label="$t('last-name-label')"
                            :error-messages="familyNameErrors"
                            :counter="
                                $v.address.familyName.$params.maxLength.max
                            "
                            :required="isRequiredField($v.address.familyName)"
                        ></base-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-switch
                            class="ml-3"
                            v-model="additionalAddressLines"
                            :label="$t('additional-address-lines')"
                            value
                        >
                        </v-switch>
                    </v-col>
                </v-row>

                <v-row v-if="address.additionalAddressLines == true">
                    <v-col cols="12" md="6">
                        <!-- addressLine1 -->
                        <base-text-field
                            v-model="addressLine1"
                            :label="$t('address-line-1-label')"
                            :error-messages="addressLine1Errors"
                            :counter="
                                $v.address.addressLine1.$params.maxLength.max
                            "
                            :required="isRequiredField($v.address.addressLine1)"
                        ></base-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <!-- addressLine2 -->
                        <base-text-field
                            v-model="addressLine2"
                            :label="$t('address-line-2-label')"
                            :counter="
                                $v.address.addressLine2.$params.maxLength.max
                            "
                            :error-messages="addressLine2Errors"
                            :required="isRequiredField($v.address.addressLine2)"
                        ></base-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="10">
                        <!-- street -->
                        <base-text-field
                            v-model="street"
                            :error-messages="streetErrors"
                            :counter="$v.address.street.$params.maxLength.max"
                            :label="$t('street-label')"
                            :required="isRequiredField($v.address.street)"
                        ></base-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <!-- houseNr -->
                        <base-text-field
                            v-model="houseNr"
                            :label="$t('house-nr-label')"
                            :error-messages="houseNrErrors"
                            :counter="$v.address.houseNr.$params.maxLength.max"
                            :required="isRequiredField($v.address.houseNr)"
                        ></base-text-field>
                    </v-col>
                </v-row>

                <v-row v-if="isLocationSearchAvailable(country)">
                    <v-col class="py-1">
                        <w-location-search
                            v-model="locationSearch"
                            :country="country"
                            :disabled="readonlyModus"
                            :error-messages="locationSearchErrors"
                            :required="
                                isRequiredField($v.address.zipCode) ||
                                isRequiredField($v.address.city) ||
                                isRequiredField($v.address.canton)
                            "
                            @reset="onWLocationSearchReset"
                        />
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col class="py-1" cols="12" sm="4">
                        <base-text-field
                            v-model="zipCode"
                            :error-messages="zipCodeErrors"
                            :counter="$v.address.zipCode.$params.maxLength.max"
                            :label="$t('zip-code-label')"
                            :required="isRequiredField($v.address.zipCode)"
                        ></base-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="8">
                        <base-text-field
                            v-model="city"
                            :error-messages="cityErrors"
                            :counter="$v.address.city.$params.maxLength.max"
                            :label="$t('city-label')"
                            :required="isRequiredField($v.address.city)"
                        ></base-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-container>
</template>
<script>
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import { PrivateAddress } from "@/models/tasks";
const DEFAULT_DEBOUNCE_TIME = 200;
import StepContentTitle from "../components/step-content-title.vue";
import validateMixin from "@/mixins/validate";
import _ from "lodash";
import Helpers from "@/utils/helpers";
export default {
    name: "FormPrivateAddress",
    components: {
        StepContentTitle
    },
    mixins: [validateMixin],
    validations() {
        return {
            address: {
                salutation: {
                    required
                },
                firstName: {
                    required,
                    maxLength: maxLength(40)
                },
                familyName: {
                    required,
                    maxLength: maxLength(40)
                },
                addressLine1: {
                    required: requiredIf(function () {
                        if (this.address.additionalAddressLines) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    maxLength: maxLength(40)
                },
                addressLine2: {
                    required: requiredIf(function () {
                        return (
                            this.address.additionalAddressLines &&
                            !this.address.addressLine1
                        );
                    }),
                    maxLength: maxLength(40)
                },
                street: { required, maxLength: maxLength(60) },
                houseNr: { maxLength: maxLength(10) },
                canton: {
                    required: requiredIf(function () {
                        if (this.country === "CH") {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                zipCode: {
                    required,
                    maxLength:
                        this.address.country === "CH"
                            ? maxLength(4)
                            : maxLength(10)
                },
                city: {
                    required,
                    maxLength: maxLength(40)
                }
            }
        };
    },
    props: {
        country: {
            type: String,
            default: "CH"
        },
        formData: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        address: new PrivateAddress()
    }),
    computed: {
        salutationItems() {
            return this.salutations;
        },
        ...mapGetters({
            salutations: "attributes/salutations"
        }),
        locationSearch: {
            get() {
                let search = {};
                search.zipCode = this.zipCode || null;
                search.city = this.city || null;
                search.canton = this.canton || null;
                if (search.zipCode || search.city || search.canton) {
                    return search;
                }
                return null;
            },
            set(value) {
                if (!value) return;
                this.zipCode = value.zipCode;
                this.city = value.city;
                this.canton = value.canton;
            }
        },
        salutation: {
            get() {
                return this.address ? this.address.salutation : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("salutation", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        firstName: {
            get() {
                return this.address ? this.address.firstName : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("firstName", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        familyName: {
            get() {
                return this.address ? this.address.familyName : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("familyName", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        additionalAddressLines: {
            get() {
                return this.address
                    ? this.address.additionalAddressLines
                    : null;
            },
            set(value) {
                this.onFieldChange("additionalAddressLines", value);
            }
        },
        addressLine1: {
            get() {
                return this.address ? this.address.addressLine1 : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("addressLine1", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        addressLine2: {
            get() {
                return this.address ? this.address.addressLine2 : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("addressLine2", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        street: {
            get() {
                return this.address ? this.address.street : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("street", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        houseNr: {
            get() {
                return this.address ? this.address.houseNr : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("houseNr", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        canton: {
            get() {
                return this.address ? this.address.canton : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("canton", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        zipCode: {
            get() {
                return this.address ? this.address.zipCode : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("zipCode", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },
        city: {
            get() {
                return this.address ? this.address.city : null;
            },
            set: _.debounce(function (value) {
                this.onFieldChange("city", value);
            }, DEFAULT_DEBOUNCE_TIME)
        },

        salutationErrors() {
            const errors = [];
            if (!this.$v.address.salutation.$dirty) {
                return errors;
            }
            if (!this.$v.address.salutation.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        firstNameErrors() {
            const errors = [];
            if (!this.$v.address.firstName.$dirty) {
                return errors;
            }
            if (!this.$v.address.firstName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.firstName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.firstName.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        familyNameErrors() {
            const errors = [];
            if (!this.$v.address.familyName.$dirty) {
                return errors;
            }
            if (!this.$v.address.familyName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.familyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.familyName.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        addressLine1Errors() {
            const errors = [];
            if (!this.$v.address.addressLine1.$dirty) {
                return errors;
            }
            if (!this.$v.address.addressLine1.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.addressLine1.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.addressLine1.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        addressLine2Errors() {
            const errors = [];
            if (!this.$v.address.addressLine2.$dirty) {
                return errors;
            }
            if (!this.$v.address.addressLine2.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.addressLine2.$params.maxLength.max
                    })
                );
            }
            return errors;
        },

        streetErrors() {
            const errors = [];
            if (!this.$v.address.street.$dirty) {
                return errors;
            }
            if (!this.$v.address.street.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.street.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.street.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        houseNrErrors() {
            const errors = [];
            if (!this.$v.address.houseNr.$dirty) {
                return errors;
            }
            if (!this.$v.address.houseNr.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.houseNr.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        locationSearchErrors() {
            const errors = [];
            if (
                !this.$v.address.canton.$dirty ||
                !this.$v.address.zipCode.$dirty ||
                !this.$v.address.city.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.address.canton.required ||
                !this.$v.address.zipCode.required ||
                !this.$v.address.city.required
            ) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cantonErrors() {
            const errors = [];
            if (!this.$v.address.canton.$dirty) {
                return errors;
            }
            if (!this.$v.address.canton.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        zipCodeErrors() {
            const errors = [];
            if (!this.$v.address.zipCode.$dirty) {
                return errors;
            }
            if (!this.$v.address.zipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.zipCode.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.zipCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.address.city.$dirty) {
                return errors;
            }
            if (!this.$v.address.city.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.city.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.address.city.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        onCountryChange(countyCode) {
            if (countyCode) {
                this.selectedOtherCountryCode = countyCode;
            } else {
                this.selectedOtherCountryCode = null;
            }
        },
        onFieldChange(field, value) {
            this.$emit("field-change", { field, value });
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        reset() {
            this.$v.$reset();
            this.onFieldChange("salutation", null);
            this.onFieldChange("firstName", null);
            this.onFieldChange("familyName", null);
            this.onFieldChange("additionalAddressLines", false);
            this.onFieldChange("addressLine1", null);
            this.onFieldChange("addressLine2", null);
            this.onFieldChange("street", null);
            this.onFieldChange("houseNr", null);
            this.onFieldChange("zipCode", null);
            this.onFieldChange("city", null);
            this.onFieldChange("canton", null);
        },
        setFakeData() {
            this.onFieldChange("salutation", "0002");
            this.onFieldChange("firstName", "Ricardo");
            this.onFieldChange("familyName", "Coelho");
            this.onFieldChange("additionalAddressLines", true);
            this.onFieldChange("addressLine1", "Untere Stock");
            this.onFieldChange("addressLine2", "an der Ecke");
            this.onFieldChange("street", "Vonwilstrasse");
            this.onFieldChange("houseNr", "23");
            this.onFieldChange("zipCode", "9000");
            this.onFieldChange("city", "St. Gallen");
            this.onFieldChange("canton", "SG");
        },
        setAddAddressLine1Btn() {
            if (this.showAddressLine1 && this.showAddressLine2) {
                return "";
            } else if (this.showAddressLine1) {
                return "mdi-minus";
            } else if (this.showAddressLine2) {
                return "";
            } else {
                return "mdi-plus";
            }
        },
        onWLocationSearchReset() {
            this.onFieldChange("zipCode", null);
            this.onFieldChange("city", null);
            this.onFieldChange("canton", null);
        },
        isLocationSearchAvailable(country) {
            return Helpers.isLocationSearchAvailable(country);
        }
    },
    created() {
        this.loader().show();
        let salutationsKey = "salutations";
        this.getStoredAttributesByKey(salutationsKey)
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    "Laden der Daten: " + salutationsKey + " war nicht möglich"
                );
            });
    },
    watch: {
        formData: {
            handler: function (newData) {
                if (newData) {
                    Object.assign(this.address, newData);
                }
            },
            immediate: true
        }
    }
};
</script>
