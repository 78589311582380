<i18n src="./view-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <div>
        <v-row>
            <v-col cols="12">
                <base-header>
                    <v-icon>mdi-account-multiple</v-icon>
                    {{ $t("title") }}
                </base-header>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-0">
                <base-subheader>{{ $t("group-title-roles") }}</base-subheader>
            </v-col>
            <v-col cols="12" class="py-0">
                <PanelGroup :items="groups" :isLoading="isLoading" auto-flex />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <base-subheader>{{ $t("group-title-tools") }}</base-subheader>
            </v-col>
            <v-col cols="12">
                <base-btn color="info" @click="reindexAllAccounts">
                    <v-icon left>mdi-database-search</v-icon>
                    {{ $t("accounts-reindex") }}
                </base-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <base-subheader>{{
                    $t("group-title-data-management")
                }}</base-subheader>
            </v-col>
            <v-col cols="12" class="py-0">
                <AccountsTable />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { getIconAndColorByRole } from "@/utils/defaults.js";
import { ACCOUNT_ROLE_LIST } from "@/data/globals.js";
import _ from "lodash";
export default {
    name: "UsersView",
    components: {
        PanelGroup: () => import("@/components/general/PanelGroup.vue"),
        AccountsTable: () => import("./table.vue")
    },
    computed: {
        ...mapState("admin", {
            accountRolesCounter: "accountRolesCounter",
            accountsCounter: "accountsCounter"
        }),
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        groups() {
            return [
                {
                    items: [...this.accountRolesItems]
                }
            ];
        },
        accountRolesItems() {
            let items = [];

            let settings = {};
            _.forEach(ACCOUNT_ROLE_LIST, (role) => {
                settings[role] = getIconAndColorByRole(role);
            });
            _.forEach(this.accountRolesCounter, (item) => {
                let groupItem = {
                    title: this.$t(`account-role-${item.role}`),
                    icon: settings[item.role]
                        ? settings[item.role].icon
                        : "mdi-account",
                    color: settings[item.role]
                        ? settings[item.role].color
                        : "lime",
                    data: item.counter
                };
                items.push(groupItem);
            });
            return items;
        }
    },
    methods: {
        randomColorGenerator() {
            return "#" + ((Math.random() * 0xffffff) << 0).toString(16);
        },
        fetchCounters() {
            this.loader().show(this.$t("loading-msg-get-data"));
            let axiosPromises = [];
            axiosPromises.push(
                this.$store.dispatch("admin/fetchAccountRolesCounter")
            );
            axiosPromises.push(
                this.$store.dispatch("admin/fetchAccountsCounter")
            );
            Promise.all(axiosPromises)
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.error(error);
                    this.loader().hide();
                    return;
                });
        },
        reindexAllAccounts() {
            this.loader().show(this.$t("msg-reindex-all"));
            this.$store
                .dispatch("accounts/reindexAll")
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-reindex-all-success"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("msg-reindex-all-error"));
                    console.log(error);
                    this.loader().hide();
                });
        }
    },
    mounted() {
        this.fetchCounters();
    }
};
</script>
