<i18n src="./address-copy-btn-i18n.yaml"></i18n>
<template>
    <v-menu offset-y bottom>
        <template v-slot:activator="{ on, attrs }">
            <base-btn v-bind="attrs" v-on="on" outlined :disabled="disabledBtn">
                <v-icon left>mdi-content-copy</v-icon>
                {{ $t("btn-copy-from-text") }}
                <v-icon right>mdi-menu-down</v-icon>
            </base-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(item, index) in copyAddressBtnItems"
                :key="index"
                @click="onClickBtnCopyAddressAction(item)"
            >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
import { ADDRESS_TYPES, ADDRESS_COPY_TYPES } from "@/data/globals.js";
import _ from "lodash";
export default {
    name: "AddressCopyBtn",

    props: {
        task: {
            type: Object,
            default: () => {}
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowedCopyItems: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        ADDRESS_COPY_TYPES,
        operatorAddress: {},
        billingAddress: {},
        ownerAddress: {}
    }),
    computed: {
        disabledBtn() {
            return this.disabled || this.readonly;
        },
        copyAddressBtnItems() {
            let items = [];
            if (
                this.isAddressRecorded(this.operatorAddress) &&
                this.isAddressAllowed(ADDRESS_COPY_TYPES.COPY_ADDRESS_OPERATOR)
            ) {
                items.push({
                    text: this.$t("address-type-operator"),
                    addressText: this.$t("address-type-operator"),
                    actionId: ADDRESS_COPY_TYPES.COPY_ADDRESS_OPERATOR,
                    address: this.operatorAddress
                });
            }
            if (
                this.isAddressRecorded(this.ownerAddress) &&
                this.isAddressAllowed(ADDRESS_COPY_TYPES.COPY_ADDRESS_OWNER)
            ) {
                items.push({
                    text: this.$t("address-type-owner"),
                    addressText: this.$t("address-type-owner"),
                    actionId: ADDRESS_COPY_TYPES.COPY_ADDRESS_OWNER,
                    address: this.ownerAddress
                });
            }
            if (
                this.isAddressRecorded(this.billingAddress) &&
                this.isAddressAllowed(ADDRESS_COPY_TYPES.COPY_ADDRESS_BILL)
            ) {
                items.push({
                    text: this.$t("address-type-bill"),
                    addressText: this.$t("address-type-bill"),
                    actionId: ADDRESS_COPY_TYPES.COPY_ADDRESS_BILL,
                    address: this.billingAddress
                });
            }
            return items;
        }
    },

    methods: {
        isAddressAllowed(addressType) {
            return this.allowedCopyItems.includes(addressType);
        },
        isAddressRecorded(address) {
            if (address && address instanceof Object) {
                if (
                    (this.isValidAddressCompany(address) ||
                        this.isValidAddressPrivateCompany(address)) &&
                    this.isValidAddress(address)
                ) {
                    return true;
                }
            }
            return false;
        },
        onClickBtnCopyAddressAction(action) {
            this.$toast.success(
                this.$t("copy-msg-success", { addressText: action.addressText })
            );
            this.$emit("click:copy-address", action);
        },

        isValidAddressCompany(address) {
            return (
                address?.addressType === ADDRESS_TYPES.COMPANY &&
                address?.companyName
            );
        },
        isValidAddressPrivateCompany(address) {
            return (
                address?.addressType === ADDRESS_TYPES.PRIVATE &&
                address?.salutation &&
                address?.firstName &&
                address?.familyName
            );
        },
        isValidAddress(address) {
            return address?.street && address?.zipCode && address?.city;
        }
    },

    watch: {
        task: {
            handler: function (newData) {
                if (newData) {
                    this.operatorAddress = Object.assign(
                        {},
                        _.clone(newData.operatorAddress)
                    );
                    this.billingAddress = _.clone(newData.billingAddress);
                    this.ownerAddress = _.clone(newData.ownerAddress);
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
