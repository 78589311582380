export class Message {
    constructor() {
        this.messageId = null;
        this.text = "";
        this.timestamp = "";
        this.createdBy = null;
        this.requireAttachments = "";
        this.requireChambers = "";
        this.requireAttachmentsCategories = [];
    }
}
