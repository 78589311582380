<template>
  <v-container fluid :class="computedCls">
    <slot />
  </v-container>
</template>
<script>
export default {
  name: "AppWrapperContainer",
  props: {
    fullSize: {
      type: Boolean,
      default: false,
    },
    contentLayoutDock: {
      type: String,
      default: "center",
    },
    contentSize: {
      type: String,
      default: "s",
    },
  },

  data: () => ({
    selected: "xs",
    items: ["xs", "s", "m", "xl", "fluid"],
  }),
  computed: {
    computedCls() {
      let cls = "";
      switch (this.contentSize) {
        case "xs":
          cls = "container-size-xs";
          break;
        case "s":
          cls = "container-size-s";
          break;
        case "m":
          cls = "container-size-m";
          break;
        case "l":
          cls = "container-size-l";
          break;
        case "xl":
          cls = "container-size-xl";
          break;
        case "fluid":
          cls = "container-size-fluid";
          break;
        default:
          cls = "container-size-m";
          break;
      }
      switch (this.contentLayoutDock) {
        case "center":
          cls += " container-layout-dock-center";
          break;
        case "right":
          cls += " container-layout-dock-right";
          break;
        case "left":
          cls += " container-layout-dock-left";
          break;
        default:
          cls = " container-layout-dock-center";
          break;
      }
      if (this.fullSize) {
        cls += " app-full-content-wrapper";
      }
      return cls;
    },
  },
};
</script>
<style lang="sass" scoped>
.app-full-content-wrapper
  height: calc(100vh - 200px)
.container-size-xs
  max-width: 700px
.container-size-s
  max-width: 1000px
.container-size-m
  max-width: 1200px
.container-size-l
  max-width: 1400px
.container-size-xl
  max-width: 1900px
.container-size-fluid
  max-width: 100%
.container-layout-dock-center
  margin-right: auto !important
  margin-left: auto !important
.container-layout-dock-left
  margin-left: unset !important
  margin-right: auto !important
.container-layout-dock-right
  margin-right: unset !important
  margin-left: auto !important
</style>
