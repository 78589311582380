<i18n>
{
    "de": {
        "field-required"        : "Pflichtfeld",
        "field-invalid-format"  : "Diese Nummer ist ungültig"
    },
    "en": {
        "field-required"        : "Required field",
        "field-invalid-format"  : "This number is invalid"
    },
    "fr": {
        "field-required"        : "Champ obligatoire",
        "field-invalid-format"  : "Ce numéro n'est pas valide"
    },
    "it": {
        "field-required"        : "Campo obbligatorio",
        "field-invalid-format"  : "Questo numero non è valido"
    }
}
</i18n>
<template>
    <div class="custom-tel-input"  data-app>
        <div v-if="enabledFlagOut" :class="activeCountry.iso2.toLowerCase()" class="flag">
            <country-flag :country="activeCountry.iso2.toLowerCase()" class="flag" />
        </div>
        <div v-else class="country-code">
            <base-select
                v-model="countryCode"
                item-text="name"
                item-value="dialCode"
                class="country-select"
                @change="onChangeCountryCode"
                :items="sortedCountries"
                return-object
                :required="required"
                >
                <template v-slot:selection="data">
                    <div>
                        <template v-if="dropdownOptions.disabledDialCode">
                            <country-flag :country="data.item.iso2" class="flag" />
                        </template>
                        <template v-else-if="dropdownOptions.disableFlagInput">
                            <div>{{ `+${data.item.dialCode}` }}  </div>
                        </template>
                        <template v-else>
                            <country-flag :country="data.item.iso2" class="flag" />
                            <div>{{ `+${data.item.dialCode}` }}  </div>
                        </template>
                    </div>
                </template>
                <template v-slot:item="data">
                    <country-flag :country="data.item.iso2" class="flag" />
                    {{ data.item.name }} {{ `+${data.item.dialCode}` }}
                </template>
            </base-select>
        </div>
        <base-text-field
            ref="input"
            type="tel"
            v-model="phone"
            :label="label"
            :clearable="clearable"
            :autofocus="autofocus"
            :hint="hint"
            :name="name"
            :disabled="disabled"
            :required="required"
            :autocomplete="autocomplete"
            :id="inputId"
            :error-messages="phoneErrors"
            :maxlength="maxLen"
            :tabindex="inputOptions && inputOptions.tabindex ? inputOptions.tabindex : 0"
            @input="onInput"
            @blur="onBlur"
            @keyup.enter="onEnter"
            @keyup.space="onSpace"
            @click:clear="$emit('click:clear')"
            v-bind="$attrs"
        >
            <template v-slot:append>
                <v-icon small v-if="showAppendIcon" :color="phoneObject.valid ? 'success' : 'error'" v-html="phoneObject.valid ? 'mdi-check-circle' : 'mdi-close-circle'"></v-icon>
            </template>
        </base-text-field>
    </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber';
import utils, { getCountry, setCaretPosition } from './utils-phone-input.js';

import validateMixin from '@/mixins/validate';

function getDefault(key) {
    const value = utils.options[key];
    if (typeof value === 'undefined') {
        return utils.options[key];
    }
    return value;
}

// Polyfill for Event.path in IE 11: https://stackoverflow.com/a/46093727
function getParents(node, memo) {
    const parsedMemo = memo || [];
    const { parentNode } = node;
    if (!parentNode) {
        return parsedMemo;
    }
    return getParents(parentNode, parsedMemo.concat(parentNode));
}

export default {
    name: 'VueTelInputVuetify',
    mixins: [validateMixin],
    validations() {
        return {
            phone: {
                required: () => {
                    if (this.required && !this.phone) {
                        return false;
                    } else {
                        return true;
                    }
                },
                validFormat: () => {
                    if (!this.phone) {
                        return true;
                    }
                    if (this.phoneObject && this.phoneObject.valid) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
        }
    },
    directives: {
        // Click-outside by BosNaufal: https://github.com/BosNaufal/vue-click-outside
        'click-outside': {
            bind(el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression ${binding.expression} is not a function, but has to be`;
                    if (compName) {
                        warn += `Found in component ${compName}`;
                    }
                    console.warn(warn);
                }
                // Define Handler and cache it on the element
                const { bubble } = binding.modifiers;
                const handler = (e) => {
                  // Fall back to composedPath if e.path is undefined
                    const path = e.path
                    || (e.composedPath ? e.composedPath() : false)
                    || getParents(e.target);
                    if (
                        bubble
                        || (path.length && !el.contains(path[0]) && el !== path[0])
                    ) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;
                // add Event Listeners
                document.addEventListener('click', handler);
            },
            unbind(el) {
                // Remove Event Listeners
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: () => getDefault('placeholder'),
        },
        label: {
            type: String,
            default: () => getDefault('label'),
        },
        disabledFetchingCountry: {
            type: Boolean,
            default: () => getDefault('disabledFetchingCountry'),
        },
        disabled: {
            type: Boolean,
            default: () => getDefault('disabled'),
        },
        disabledFormatting: {
            type: Boolean,
            default: () => getDefault('disabledFormatting'),
        },
        mode: {
            type: String,
            default: () => getDefault('mode'),
        },
        invalidMsg: {
            type: String,
            default: () => getDefault('invalidMsg'),
        },
        required: {
            type: Boolean,
            default: () => getDefault('required'),
        },
        allCountries: {
            type: Array,
            default: () => getDefault('allCountries'),
        },
        defaultCountry: {
            // Default country code, ie: 'AU'
            // Will override the current country of user
            type: String,
            default: () => getDefault('defaultCountry'),
        },
        enabledCountryCode: {
            type: Boolean,
            default: () => getDefault('enabledCountryCode'),
        },
        enabledFlags: {
            type: Boolean,
            default: () => getDefault('enabledFlags'),
        },
        preferredCountries: {
            type: Array,
            default: () => getDefault('preferredCountries'),
        },
        onlyCountries: {
            type: Array,
            default: () => getDefault('onlyCountries'),
        },
        ignoredCountries: {
            type: Array,
            default: () => getDefault('ignoredCountries'),
        },
        autocomplete: {
            type: String,
            default: () => getDefault('autocomplete'),
        },
        autofocus: {
            type: Boolean,
            default: () => getDefault('autofocus'),
        },
        name: {
            type: String,
            default: () => getDefault('name'),
        },
        wrapperClasses: {
            type: [String, Array, Object],
            default: () => getDefault('wrapperClasses'),
        },
        inputClasses: {
            type: [String, Array, Object],
            default: () => getDefault('inputClasses'),
        },
        inputId: {
            type: String,
            default: () => getDefault('inputId'),
        },
        dropdownOptions: {
            type: Object,
            default: () => getDefault('dropdownOptions'),
        },
        inputOptions: {
            type: Object,
            default: () => getDefault('inputOptions'),
        },
        maxLen: {
            type: Number,
            default: () => getDefault('maxLen'),
        },
        validCharactersOnly: {
            type: Boolean,
            default: () => getDefault('validCharactersOnly'),
        },
        dynamicPlaceholder: {
            type: Boolean,
            default: () => getDefault('dynamicPlaceholder'),
        },
        enabledFlagOut: {
            type: Boolean,
            default: () => getDefault('enabledFlagOut'),
        },
        hint: {
            type: String,
            default: () => getDefault('hint'),
        },
    },
    data() {
        return {
            phone           : '',
            activeCountry   : { iso2: '' },
            open            : false,
            finishMounted   : false,
            selectedIndex   : null,
            typeToFindInput : '',
            typeToFindTimer : null,
            cursorPosition  : 0,
            countryCode     : null,
            showAppendIcon: false
        };
    },
    computed: {
        phoneErrors () {
            const errors = []
            if (!this.$v.phone.$dirty) {
                return errors
            }
            if(!this.$v.phone.required) {
                errors.push(this.$t('field-required'));
            }
            if(!this.$v.phone.validFormat) {
                errors.push(this.$t('field-invalid-format'));
            }
            return errors
        },
        parsedPlaceholder() {
            if (!this.finishMounted) {
                return '';
            }
            if (this.dynamicPlaceholder) {
                const mode = this.mode || 'international';
                return PhoneNumber.getExample(
                    this.activeCountry.iso2,
                    'mobile',
                ).getNumber(mode);
            }
            return this.placeholder;
        },
        parsedMode() {
            if (this.mode) {
                if (!['international', 'national'].includes(this.mode)) {
                    console.error('Invalid value of prop "mode"');
                } else {
                    return this.mode;
                }
            }
            if (!this.phone || this.phone[0] !== '+') {
                return 'national';
            }
            return 'international';
        },
        filteredCountries() {
            // List countries after filtered
            if (this.onlyCountries.length) {
                return this.getCountries(this.onlyCountries);
            }
            if (this.ignoredCountries.length) {
                return this.allCountries.filter(
                    ({ iso2 }) => !this.ignoredCountries.includes(iso2.toUpperCase())
                    && !this.ignoredCountries.includes(iso2.toLowerCase()),
                );
            }
            return this.allCountries;
        },
        sortedCountries() {
            // Sort the list countries: from preferred countries to all countries
            const preferredCountries = this.getCountries(this.preferredCountries).map(
                country => ({ ...country, preferred: true }),
            );
            return [...preferredCountries, ...this.filteredCountries];
        },
        phoneObject() {
            const result = PhoneNumber(this.phone, this.activeCountry.iso2).toJSON();
            Object.assign(result, {
                isValid: result.valid,
                country: this.activeCountry,
            });
            return result;
        },
        phoneText() {
            let key = 'input';
            if (this.phoneObject.valid) {
                key = this.parsedMode;
            }
            return this.phoneObject.number[key] || '';
        },
    },
    watch: {
        // eslint-disable-next-line func-names
        'phoneObject.valid': function (value) {
            if (value) {
                this.phone = this.phoneText;
            }
            this.$emit('validate', this.phoneObject);
        //    this.$emit('onValidate', this.phoneObject); // Deprecated
        },
        value() {
            this.phone = this.value;
        },
        open(isDropdownOpened) {
            // Emit open and close events
            if (isDropdownOpened) {
                this.$emit('open');
            } else {
                this.$emit('close');
            }
        },
        phone(newValue, oldValue) {
            if (this.validCharactersOnly && !this.testCharacters()) {
                this.$nextTick(() => {
                    this.phone = oldValue;
                });
            } else if (newValue) {
                if (newValue[0] === '+') {
                    const code = PhoneNumber(newValue).getRegionCode();
                    if (code) {
                        this.activeCountry = this.findCountry(code) || this.activeCountry;
                    }
                }
            }
            // Reset the cursor to current position if it's not the last character.
            if (oldValue && this.cursorPosition < oldValue.length) {
                this.$nextTick(() => {
                    setCaretPosition(this.$refs.input, this.cursorPosition);
                });
            }
        },
        activeCountry(value) {
            if (value && value.iso2) {
                if(this.countryCode) {
                    this.countryCode   =  value;
                }
                this.$emit('country-changed', value);
            }
        },
        '$v.phone.$anyError' : function(errors) {
            if (errors) {
                this.$emit('form-address-has-errors');
            } else {
                this.$emit('form-address-is-valid');
            }
        },
    },
    mounted() {
        this.initializeCountry()
        .then(() => {
            if (
            !this.phone
            && this.inputOptions
            && this.inputOptions.showDialCode
            && this.activeCountry.dialCode
            ) {
                this.phone = `+${this.activeCountry.dialCode}`;
            }
            this.countryCode = this.activeCountry;
            this.$emit('validate', this.phoneObject);
            this.$emit('onValidate', this.phoneObject); // Deprecated
        })
        .catch(console.error)
        .finally(() => {
            this.finishMounted = true;
        });
    },
    created() {
        if (this.value) {
            this.phone = this.value.trim();
        }
    },
    methods: {
        initializeCountry() {
            return new Promise((resolve) => {
                /**
                 * 1. If the phone included prefix (+12), try to get the country and set it
                 */
                if (this.phone && this.phone[0] === '+') {
                    const activeCountry = PhoneNumber(this.phone).getRegionCode();
                    if (activeCountry) {
                        this.choose(activeCountry);
                        resolve();
                        return;
                    }
                }
                /**
                 * 2. Use default country if passed from parent
                 */
                if (this.defaultCountry) {
                    const defaultCountry = this.findCountry(this.defaultCountry);
                    if (defaultCountry) {
                        this.choose(defaultCountry);
                        resolve();
                        return;
                    }
                }
                const fallbackCountry = this.findCountry(this.preferredCountries[0])
                || this.filteredCountries[0];
                /**
                 * 3. Check if fetching country based on user's IP is allowed, set it as the default country
                 */
                if (!this.disabledFetchingCountry) {
                    getCountry()
                    .then((res) => {
                        this.activeCountry = this.findCountry(res) || this.activeCountry;
                        console.log(this.activeCountry);
                    })
                    .catch((error) => {
                        console.warn(error);
                        /**
                         * 4. Use the first country from preferred list (if available) or all countries list
                         */
                        this.choose(fallbackCountry);
                    })
                    .finally(() => {
                        resolve();
                    });
                    } else {
                    /**
                     * 4. Use the first country from preferred list (if available) or all countries list
                     */
                    this.choose(fallbackCountry);
                    resolve();
                }
            });
        },
        /**
         * Get the list of countries from the list of iso2 code
         */
        getCountries(list = []) {
            return list
                .map(countryCode => this.findCountry(countryCode))
                .filter(Boolean);
        },
        findCountry(iso = '') {
            return this.allCountries.find(
                country => country.iso2 === iso.toUpperCase(),
            );
        },
        getItemClass(index, iso2) {
            const highlighted = this.selectedIndex === index;
            const lastPreferred = index === this.preferredCountries.length - 1;
            const preferred = this.preferredCountries.some(
                c => c.toUpperCase() === iso2,
            );
            return {
                highlighted,
                'last-preferred': lastPreferred,
                preferred,
            };
        },
        choose(country, toEmitInputEvent = false) {
            this.activeCountry = country || this.activeCountry || {};
            if (
                this.phone
                && this.phone[0] === '+'
                && this.activeCountry.iso2
                && this.phoneObject.number.national
            ) {
                // Attach the current phone number with the newly selected country
                this.phone = PhoneNumber(
                    this.phoneObject.number.national,
                    this.activeCountry.iso2,
                ).getNumber('international');
            } else if (
                this.inputOptions
                && this.inputOptions.showDialCode
                && country
            ) {
                // Reset phone if the showDialCode is set
                this.phone = `+${country.dialCode}`;
            }
            if (toEmitInputEvent) {
                this.$emit('input', this.phoneText, this.phoneObject);
                //this.$emit('onInput', this.phoneObject); // Deprecated
            }
        },
        testCharacters() {
            const re = /^[()\-+0-9\s]*$/;
            return re.test(this.phone);
        },
        onInput(e) {
            if (this.validCharactersOnly && !this.testCharacters()) {
                return;
            }
            // this.$refs.input.setCustomValidity(
            //   this.phoneObject.valid ? "" : this.invalidMsg
            // );
            // Returns response.number to assign it to v-model (if being used)
            // Returns full response for cases @input is used
            // and parent wants to return the whole response.
            this.$emit('input', this.phoneText, this.phoneObject);
            this.$emit('onInput', this.phoneObject); // Deprecated
            // Keep the current cursor position just in case the input reformatted
            // and it gets moved to the last character.
            if (e && e.target) {
                this.cursorPosition = e.target.selectionStart;
            }
            this.showAppendIcon = true;
            setTimeout(() => {
                this.showAppendIcon = false;
            }, 2000);
        },
        onBlur() {
            this.$emit('blur');
            this.$emit('onBlur'); // Deprecated
        },
        onEnter() {
            this.$emit('enter');
            this.$emit('onEnter'); // Deprecated
        },
        onSpace() {
            this.$emit('space');
            this.$emit('onSpace'); // Deprecated
        },
        focus() {
            this.$refs.input.focus();
        },
        toggleDropdown() {
            if (this.disabled) {
                return;
            }
            this.open = !this.open;
        },
        clickedOutside() {
            this.open = false;
        },
        keyboardNav(e) {
            if (e.keyCode === 40) {
                // down arrow
                e.preventDefault();
                this.open = true;
                if (this.selectedIndex === null) {
                    this.selectedIndex = 0;
                } else {
                    this.selectedIndex = Math.min(
                        this.sortedCountries.length - 1,
                        this.selectedIndex + 1,
                    );
                }
                const selEle = this.$refs.list.children[this.selectedIndex];
                if (
                    selEle.offsetTop + selEle.clientHeight
                    > this.$refs.list.scrollTop + this.$refs.list.clientHeight
                ) {
                    this.$refs.list.scrollTop = selEle.offsetTop
                    - this.$refs.list.clientHeight
                    + selEle.clientHeight;
                }
            } else if (e.keyCode === 38) {
                // up arrow
                e.preventDefault();
                this.open = true;
                if (this.selectedIndex === null) {
                this.selectedIndex = this.sortedCountries.length - 1;
                } else {
                    this.selectedIndex = Math.max(0, this.selectedIndex - 1);
                }
                const selEle = this.$refs.list.children[this.selectedIndex];
                if (selEle.offsetTop < this.$refs.list.scrollTop) {
                    this.$refs.list.scrollTop = selEle.offsetTop;
                }
            } else if (e.keyCode === 13) {
                // enter key
                if (this.selectedIndex !== null) {
                    this.choose(this.sortedCountries[this.selectedIndex]);
                }
                this.open = !this.open;
            } else {
                // typing a country's name
                this.typeToFindInput += e.key;
                clearTimeout(this.typeToFindTimer);
                this.typeToFindTimer = setTimeout(() => {
                this.typeToFindInput = '';
                }, 700);
                // don't include preferred countries so we jump to the right place in the alphabet
                const typedCountryI = this.sortedCountries
                .slice(this.preferredCountries.length)
                .findIndex(c => c.name.toLowerCase().startsWith(this.typeToFindInput));
                if (typedCountryI >= 0) {
                    this.selectedIndex = this.preferredCountries.length + typedCountryI;
                    const selEle = this.$refs.list.children[this.selectedIndex];
                    const needToScrollTop = selEle.offsetTop < this.$refs.list.scrollTop;
                    const needToScrollBottom = selEle.offsetTop + selEle.clientHeight
                    > this.$refs.list.scrollTop + this.$refs.list.clientHeight;
                    if (needToScrollTop || needToScrollBottom) {
                        this.$refs.list.scrollTop = selEle.offsetTop - this.$refs.list.clientHeight / 2;
                    }
                }
            }
        },
        reset() {
            this.$v.phone.$reset()
        },
        clear() {
          this.selectedIndex = this.sortedCountries
            .map(c => c.iso2)
            .indexOf(this.activeCountry.iso2);
            this.open = false;
            this.phone = '';
        },
        onChangeCountryCode() {
            this.choose(this.countryCode, true);
        },
        validate() {
            this.$v.phone.$touch();
        },
        isValid() {
            if (this.phone && this.phoneObject.isValid) {
                this.$emit('phone-number-valid');
                return true;
            } else {
                this.$emit('phone-number-has-errors');
                return false;
            }

        },
        onInputValidate() {
            if (!this.$v.phone.dirty()) {
                return;
            } else {
                this.validate();
            }
        },
        setValue(value) {
            if (value) {
                this.phone = value;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-tel-input {
  display: flex;
  align-items: center;
}
.country-code {
  width: 75px;
}
li.last-preferred {
  border-bottom: 1px solid #cacaca;
}
.flag {
  margin-right: 10px !important;
}
.country-select .v-select__selections {
    flex-wrap: nowrap;
}
</style>
