<i18n src="./messages-card-i18n.yaml"></i18n>
<template>
    <v-card tile flat>
        <v-switch
            class="pa-0 ma-0 pl-15 pt-2"
            v-if="historyMessages.length > 0"
            v-model="expandDialogHistoryCard"
            :label="$t('switch-label', { nrOfMgs: historyMessages.length })"
        >
        </v-switch>
        <v-card-text
            class="pa-0 ma-0"
            :class="expandDialogHistoryCard ? 'pb-5' : null"
            v-if="historyMessages.length > 0"
        >
            <v-expand-transition
                v-if="historyMessages.length > 0"
                class="mb-10"
            >
                <PerfectScrollbar
                    class="cls-history-messages-scrollbar"
                    :options="{
                        wheelPropagation: true,
                        minScrollbarLength: 20
                    }"
                >
                    <div v-show="expandDialogHistoryCard">
                        <MessagesHistoryContainer
                            :historyMessages="historyMessages"
                            :accountDocId="accountDocId"
                        />
                    </div>
                </PerfectScrollbar>
            </v-expand-transition>
        </v-card-text>
        <v-divider v-if="historyMessages.length > 0" />
        <template v-if="Object.entries(lastMessage).length > 0" class="py-5">
            <MessageCardQuestion
                v-if="lastMessage.question && lastMessage.question.text"
                :key="lastMessage.question.timestamp"
                :message="Object.assign({}, lastMessage.question)"
                :accountDocId="accountDocId"
                @edit="$emit('edit', $event)"
                @delete="$emit('delete', $event)"
                :showEditIcon="showBoEditIcon"
                :disableEditIcon="editMessageModus"
                :showDeleteIcon="showBoDeleteIcon"
                :requireAttachments="lastMessage.requireAttachments"
                :requireAttachmentsCategories="
                    lastMessage.requireAttachmentsCategories
                "
            >
            </MessageCardQuestion>
            <MessageCardReply
                v-if="lastMessage.reply && lastMessage.reply.text"
                :key="lastMessage.reply.timestamp"
                :message="Object.assign({}, lastMessage.reply)"
                :accountDocId="accountDocId"
                @edit="$emit('edit', $event)"
                @delete="$emit('delete', $event)"
                :showEditIcon="showRegEditIcon"
                :disableEditIcon="editMessageModus"
                :showDeleteIcon="showRegDeleteIcon"
                :attachments="lastMessage.attachments"
            >
            </MessageCardReply>
        </template>
    </v-card>
</template>
<script>
export default {
    name: "MessagesCard",
    components: {
        MessagesHistoryContainer: () =>
            import("./messages-history-container.vue"),
        MessageCardReply: () => import("./message-card-reply.vue"),
        MessageCardQuestion: () => import("./message-card-question.vue")
    },
    props: {
        historyMessages: {
            type: Array,
            default: () => [],
            required: true
        },
        lastMessage: {
            type: Object,
            default: () => {},
            required: true
        },
        accountDocId: {
            type: String,
            default: null
        },
        canRegistrantEdit: {
            type: Boolean,
            default: false
        },
        canBackofficeEdit: {
            type: Boolean,
            default: false
        },
        editMessageModus: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        expandDialogHistoryCard: false
    }),
    computed: {
        showBoEditIcon() {
            let show = true;
            if (!this.canBackofficeEdit) {
                return false;
            }
            if ("reply" in this.lastMessage) {
                show = false;
            }
            return show;
        },
        showBoDeleteIcon() {
            let show = true;
            if (!this.canBackofficeEdit) {
                return false;
            }
            if ("reply" in this.lastMessage) {
                show = false;
            }
            return show;
        },
        showRegEditIcon() {
            let show = true;
            if (!this.canRegistrantEdit) {
                return false;
            }
            if ("reply" in this.lastMessage) {
                show = true;
            }
            return show;
        },
        showRegDeleteIcon() {
            let show = true;
            if (!this.canRegistrantEdit) {
                return false;
            }
            if ("reply" in this.lastMessage) {
                show = true;
            }
            return show;
        }
    }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style src="./messages-card.styl" lang="stylus" scoped></style>
