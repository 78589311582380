<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <v-container pa-0 ma-0 fluid>
        <v-row align="center" justify="center">
            <template
                v-if="(!task || Object.keys(task).length === 0) && isLoading"
            >
                <v-col cols="12" sm="6">
                    <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                        <v-skeleton-loader
                            class="mx-auto"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                        >
                        </v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </template>
            <v-col cols="12" md="10">
                <app-dev-container>
                    <template v-slot:content>
                        <tree-view
                            :data="Object.assign({}, task)"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                    </template>
                </app-dev-container>
                <TaskCompleteView :task="task" show-copy-btn />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
    name: "TaskView",
    components: {
        TaskCompleteView: () =>
            import("@/components/tasks/common/task-complete-view.vue")
    },
    props: {
        referenceId: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapGetters({
            developerMode: "settings/developerMode",
            registrationTimestampStatus: "settings/registrationTimestampStatus",
            registrationTimestamp: "settings/registrationTimestamp"
        }),
        ...mapState("registrations", ["readonlyModus", "registration"]),
        ...mapState("registrations", {
            task: "registration"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        workingTitle() {
            if (!this.registration || !this.registration.workingTitle)
                return null;
            return this.registration.workingTitle;
        },
        manufacturer() {
            if (!this.registration || !this.registration.manufacturer)
                return null;
            return this.registration.manufacturer;
        },
        factoryNumber() {
            if (!this.registration || !this.registration.factoryNumber)
                return null;
            return this.registration.factoryNumber;
        },
        constructionYear() {
            if (!this.registration || !this.registration.constructionYear)
                return null;
            return this.registration.constructionYear;
        }
    },
    methods: {
        getData() {
            if (!this.referenceId) {
                return;
            }
            this.loader().show();
            let data = {};
            data.referenceId = this.referenceId;
            this.$store
                .dispatch(
                    "registrations/fetchOpenRegistrationDataByReferenceId",
                    data
                )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.setTitles();
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus = error.response.status;
                    if (resStatus === 404) {
                        this.$router.push("/404");
                    } else if (resStatus === 403) {
                        this.$router.push("/403");
                    } else {
                        this.$router.push("/500");
                    }
                    this.loader().hide();
                });
        },
        setTitles() {
            if (!this.registration) return;
            if (
                !this.workingTitle ||
                !this.manufacturer ||
                !this.factoryNumber ||
                !this.constructionYear
            ) {
                this.$store.commit("settings/setDefaultTitles");
                return;
            }
            let data = {};
            data.title01 = this.getTitle01();
            data.title02 = this.getTitle02();
            data.registrationTimestamp = this.registration.lastChangeTimestamp;
            this.$store.commit("settings/setTitles", data);
        },
        getTitle01() {
            let title = "";
            if (this.workingTitle) {
                title += this.workingTitle;
            }
            return title;
        },
        getTitle02() {
            let title = "";
            if (this.registration) {
                if (_.isObject(this.manufacturer)) {
                    title += this.manufacturer.name + "<br>";
                } else {
                    title += this.manufacturer + "<br>";
                }
                title +=
                    this.$t("label-serial-number-short") +
                    ":" +
                    this.factoryNumber +
                    ", " +
                    this.$t("label-construction-year-short") +
                    ":" +
                    this.constructionYear;
            }
            return title;
        },
        reset() {
            //reset to defautl values
            Object.assign(this.$data, this.$options.data.call(this));
        }
    },
    created() {
        if (this.referenceId) {
            this.getData();
        } else {
            if (this.isAuth) {
                this.$router.push({ name: "user-dashboard" });
            } else {
                this.$router.push("/");
            }
        }
        window.addEventListener("beforeunload", function (event) {
            if (this.registrationTimestampStatus === "loading") {
                event.returnValue = "Write something";
            }
        });
    },
    destroyed() {
        this.$store.commit("settings/setDefaultTitles");
    },
    watch: {
        workingTitle: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        manufacturer: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        factoryNumber: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000),
        constructionYear: _.debounce(function (value) {
            if (value) {
                this.setTitles();
            }
        }, 2000)
    }
};
</script>

<style>
</style>
