<template>
    <base-card-text
        class="mx-auto"
        :to="action ? action.path : undefined"
        dense
        :disabled="disabled"
    >
        <template v-slot:content>
            <v-list-item three-line>
                <v-list-item-avatar tile size="80">
                    <v-icon v-if="icon" :color="color" :size="iconSize">{{
                        icon
                    }}</v-icon>
                    <w-icon
                        v-if="svgIcon"
                        :data="require(`@/assets/icons/${svgIcon}`)"
                        :color="svgIconColor"
                        width="60"
                        height="60"
                    ></w-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="title font-weight-light">
                        {{ title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <count-to
                            :start-val="start"
                            :end-val="end"
                            :duration="duration"
                            class="text-h4 font-weight-bold"
                        />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </base-card-text>
</template>
<script>
export default {
    name: "PanelGroupItem",
    components: {
        CountTo: () => import("vue-count-to")
    },
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: "blue-grey"
        },
        action: {
            type: Object,
            default: () => {}
        },
        start: {
            type: Number,
            default: 0
        },
        end: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            default: 3200
        },
        svgIcon: {
            type: String,
            default: null
        },
        svgIconColor: {
            type: String,
            default: null
        },
        iconSize: {
            type: [String, Number],
            default: 70
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
