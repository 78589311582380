<i18n src="./dialog-complete-deregistration-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="600"
        icon="mdi-alert"
        ref="baseDialog"
    >
        <template v-slot:content>
            <v-container>
                <base-alert type="warning" v-html="$t('warning-msg')" />
                <v-row>
                    <v-col cols="12" xs="6">
                        <WSapEquipmentNr
                            v-model="sapEquipmentNr"
                            :label="$t('sap-equipment-nr-label')"
                            :error-messages="sapEquipmentNrErrors"
                            :required="isRequiredField($v.sapEquipmentNr)"
                        ></WSapEquipmentNr>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn type="close" @click="closeDialog">
                {{ $t("btn-close") }}
            </base-btn>
            <base-btn type="save" @click="onComplete">{{
                $t("btn-complete")
            }}</base-btn>
        </template>
    </base-dialog>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import validateMixin from "@/mixins/validate";
export default {
    name: "DialogCompleteDeregistration",
    mixins: [validateMixin],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        taskData: {
            type: Object,
            default: () => {}
        }
    },
    validations() {
        return {
            sapEquipmentNr: {
                required,
                minLength: minLength(7),
                maxLength: maxLength(7)
            }
        };
    },
    data: () => ({
        dialog: false,
        sapEquipmentNr: null
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        onComplete() {
            this.validate();
            if (this.isValid()) {
                this.$emit("complete", this.sapEquipmentNr);
            }
        }
    },
    computed: {
        sapEquipmentNrErrors() {
            const errors = [];
            if (!this.$v.sapEquipmentNr.$dirty) {
                return errors;
            }
            if (!this.$v.sapEquipmentNr.required) {
                errors.push(this.$t("field-required"));
            }
            if (
                !this.$v.sapEquipmentNr.minLength ||
                !this.$v.sapEquipmentNr.maxLength
            ) {
                errors.push(
                    this.$t("field-equipment-id-length", {
                        length: this.sapEquipmentNr.length
                    })
                );
            }
            return errors;
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
                this.sapEquipmentNr = this.taskData.sapEquipmentNr;
            },
            immediate: true
        }
    }
};
</script>
