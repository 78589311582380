<i18n src="./tasks-table-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <v-data-table
        :headers="computedHeaders"
        :items="items"
        :sort-by.sync="computedOptions.sortBy"
        :sort-desc.sync="computedOptions.sortDesc"
        :loading="isLoading"
        v-on="$listeners"
        v-bind="$attrs"
    >
        <template v-slot:item.state="{ item }">
            <slot name="state" :item="item">
                <registration-icon
                    class="pt-2"
                    :objectType="item.objectType"
                    :state="item.state"
                    :deletedState="item.deletedState ? item.deletedState : 0"
                    :process="item.process"
                >
                </registration-icon>
            </slot>
        </template>
        <template v-slot:item.referenceId="{ item }">
            <slot name="referenceId" :item="item">
                <div v-if="item.referenceId">
                    {{ item.referenceId }}
                    <CopyToClipboard :value="item.referenceId" />
                </div>
            </slot>
        </template>
        <template v-slot:item.manufacturer="{ item }">
            <slot name="manufacturer" :item="item">
                <div v-if="item.manufacturer">{{ item.manufacturer }}</div>
                <div v-if="item.factoryNumber">
                    {{
                        $t("factory-number-short", {
                            number: item.factoryNumber
                        })
                    }}
                </div>
                <div v-if="item.constructionYear">
                    {{
                        $t("construction-year-short", {
                            year: item.constructionYear
                        })
                    }}
                </div>
                <div v-if="item.inOperationYear">
                    {{
                        $t("in-operation-year", { year: item.inOperationYear })
                    }}
                </div>
            </slot>
        </template>
        <template v-slot:item.registrantCompleteDate="{ item }">
            <slot name="registrantCompleteDate" :item="item">
                <div v-if="item.registrantCompleteDate">
                    <v-icon>mdi-calendar-check</v-icon>
                    <base-date-time :timestamp="item.registrantCompleteDate" />
                </div>
            </slot>
        </template>
        <template v-slot:item.backofficeCompleteDate="{ item }">
            <slot name="backofficeCompleteDate" :item="item">
                <div v-if="item.backofficeCompleteDate">
                    <v-icon>mdi-calendar-check</v-icon>
                    <base-date-time :timestamp="item.backofficeCompleteDate" />
                </div>
            </slot>
        </template>
        <template v-slot:item.reminder="{ item }">
            <slot name="reminder" :item="item">
                <div>
                    {{ $moment.utc(item.reminder).local().from($moment()) }}
                </div>
            </slot>
        </template>
        <template v-slot:item.boLockedUntil="{ item }">
            <slot name="boLockedUntil" :item="item">
                <div v-if="item.boLockedUntil">
                    <span
                        v-if="
                            $moment.utc(item.boLockedUntil).local() < $moment()
                        "
                        class="red--text"
                    >
                        {{
                            $moment
                                .utc(item.boLockedUntil)
                                .local()
                                .from($moment())
                        }}
                    </span>
                    <span v-else>
                        {{
                            $moment
                                .utc(item.boLockedUntil)
                                .local()
                                .from($moment())
                        }}
                    </span>
                </div>
            </slot>
        </template>
        <template v-slot:item.boReminderAssignRegistrant="{ item }">
            <slot name="boReminderAssignRegistrant" :item="item">
                <div v-if="item.boReminderAssignRegistrant">
                    <span
                        v-if="
                            getDiffDaysBeetweenDateAndNow(
                                item.boReminderAssignRegistrant
                            ) === 0 ||
                            getDiffDaysBeetweenDateAndNow(
                                item.boReminderAssignRegistrant
                            ) === -1
                        "
                        class="warning--text"
                    >
                        {{
                            $moment
                                .utc(item.boReminderAssignRegistrant)
                                .local()
                                .from($moment())
                        }}
                    </span>
                    <span
                        v-else-if="
                            getDiffDaysBeetweenDateAndNow(
                                item.boReminderAssignRegistrant
                            ) > 0
                        "
                        class="error--text"
                    >
                        {{
                            $moment
                                .utc(item.boReminderAssignRegistrant)
                                .local()
                                .from($moment())
                        }}
                    </span>
                    <span v-else>
                        {{
                            $moment
                                .utc(item.boReminderAssignRegistrant)
                                .local()
                                .from($moment())
                        }}
                    </span>
                </div>
            </slot>
        </template>
        <template v-slot:item.boReminder="{ item }">
            <slot name="boReminder" :item="item">
                <div v-if="item.boReminder">
                    <span
                        v-if="
                            getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                                0 ||
                            getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                                -1
                        "
                        class="warning--text"
                    >
                        {{
                            $moment.utc(item.boReminder).local().from($moment())
                        }}
                    </span>
                    <span
                        v-else-if="
                            getDiffDaysBeetweenDateAndNow(item.boReminder) > 0
                        "
                        class="error--text"
                    >
                        {{
                            $moment.utc(item.boReminder).local().from($moment())
                        }}
                    </span>
                    <span v-else>
                        {{
                            $moment.utc(item.boReminder).local().from($moment())
                        }}
                    </span>
                </div>
            </slot>
        </template>
        <template v-slot:item.operator="{ item }">
            <slot name="operator" :item="item">
                <div v-if="item.operator">
                    <div
                        v-if="
                            item.operator.addressType &&
                            item.operator.addressType == ADDRESS_TYPES.COMPANY
                        "
                    >
                        <div v-if="item.operator.uidFormatted">
                            {{ item.operator.uidFormatted }}
                            <CopyToClipboard
                                :value="item.operator.uidFormatted"
                            />
                        </div>
                        <div v-if="item.operator.companyName">
                            {{ item.operator.companyName }}
                        </div>
                    </div>
                    <div v-else>
                        <span v-if="item.operator.firstName">
                            {{ item.operator.firstName }}
                        </span>
                        <span v-if="item.operator.familyName">
                            {{ item.operator.familyName }}
                        </span>
                    </div>
                    <div v-if="item.operator.street && item.operator.houseNr">
                        <span v-if="item.operator.street">
                            {{ item.operator.street }}
                        </span>
                        <span v-if="item.operator.houseNr">
                            {{ item.operator.houseNr }}
                        </span>
                    </div>
                    <div v-if="item.operator.city && item.operator.zipCode">
                        <span
                            v-if="
                                item.operator.country && item.operator.zipCode
                            "
                        >
                            {{ item.operator.country }}-{{
                                item.operator.zipCode
                            }}
                        </span>
                        <span
                            v-else-if="
                                !item.operator.country && item.operator.zipCode
                            "
                        >
                            {{ item.operator.zipCode }}
                        </span>
                        <span v-else> {{ item.operator.country }} </span>
                        <span v-if="item.operator.city">
                            {{ item.operator.city }}
                            <span v-if="item.operator.zipCode">
                                {{ item.operator.zipCode }}
                            </span>
                        </span>
                        <span v-if="item.operator.canton">
                            ({{ item.operator.canton }})
                        </span>
                    </div>
                </div>
            </slot>
        </template>

        <template v-slot:item.registrant="{ item }">
            <slot name="registrant" :item="item">
                <div v-if="item.account">
                    <v-icon>mdi-account</v-icon>
                    <span v-if="item.account.firstName">
                        {{ item.account.firstName }}
                    </span>
                    <span v-if="item.account.familyName" class="ml-1">
                        {{ item.account.familyName }}
                    </span>
                </div>
            </slot>
        </template>
        <template v-slot:item.boEmployee="{ item }">
            <slot name="boEmployee" :item="item">
                <div v-if="item.boEmployee">
                    <v-icon>mdi-account-hard-hat</v-icon>
                    <span> {{ item.boEmployee.firstName }}</span>
                    <span class="ml-1"> {{ item.boEmployee.familyName }}</span>
                </div>
                <div v-else>
                    <span class="red--text"> {{ $t("not-yet-assigned") }}</span>
                </div>
            </slot>
        </template>
        <template v-slot:item.boDates="{ item }">
            <slot name="boDates" :item="item">
                <div v-if="item.boReminder">
                    <v-icon>mdi-calendar-clock</v-icon>
                    {{ $t("locked-to") }}:
                    <span
                        v-if="
                            getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                                0 ||
                            getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                                -1
                        "
                        class="warning--text"
                    >
                        {{
                            $moment.utc(item.boReminder).local().from($moment())
                        }}
                    </span>
                    <span
                        v-else-if="
                            getDiffDaysBeetweenDateAndNow(item.boReminder) > 0
                        "
                        class="error--text"
                    >
                        {{
                            $moment.utc(item.boReminder).local().from($moment())
                        }}
                    </span>
                    <span v-else class="info--text">
                        {{
                            $moment.utc(item.boReminder).local().from($moment())
                        }}
                    </span>
                </div>
                <div v-if="item.boLockedUntil">
                    <v-icon>mdi-calendar-alert</v-icon>
                    {{ $t("reminder-at") }}:
                    <span
                        v-if="
                            $moment.utc(item.boLockedUntil).local() < $moment()
                        "
                        class="red--text"
                    >
                        {{
                            $moment
                                .utc(item.boLockedUntil)
                                .local()
                                .from($moment())
                        }}
                    </span>
                    <span v-else class="green--text">
                        {{
                            $moment
                                .utc(item.boLockedUntil)
                                .local()
                                .from($moment())
                        }}
                    </span>
                </div>
            </slot>
        </template>
        <template v-slot:item.actions="{ item }">
            <slot name="actions" :item="item">
                <template v-if="actions.length > 0">
                    <v-menu transition="slide-y-transition" bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <base-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :disabled="item.deletedState > 0"
                            >
                                <v-icon color="info">
                                    mdi-reorder-horizontal
                                </v-icon>
                            </base-btn>
                        </template>
                        <v-list>
                            <template v-for="(actionItem, i) in actions">
                                <v-list-item
                                    v-if="funcShowAction(item, actionItem)"
                                    :key="i"
                                    @click="
                                        onActionClick(actionItem.action, item)
                                    "
                                    :disabled="
                                        maybeDisableAction(actionItem, item)
                                    "
                                >
                                    <v-list-item-icon class="mr-2">
                                        <v-icon
                                            v-text="actionItem.icon"
                                        ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ $t(actionItem.title) }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </template>
            </slot>
        </template>
        <template slot="no-data">
            <slot name="no-data">
                <span>
                    <p class="pt-2 blue--text subheading">
                        <v-icon medium class="blue--text">
                            mdi-information
                        </v-icon>
                        {{ $t("no-data-msg") }}
                    </p>
                </span>
            </slot>
        </template>
    </v-data-table>
</template>
<script>
import { getRegistrationStateColor } from "@/utils/defaults.js";
import { OBJECT_TYPES } from "@/data/globals.js";
import { mapState } from "vuex";
import RegistrationIcon from "@/components/tasks/common/registration-icon.vue";
import CopyToClipboard from "@/components/general/utlis/copy-to-clipboard.vue";
import { ADDRESS_TYPES } from "@/data/globals.js";
export default {
    name: "SearchTable",
    components: {
        RegistrationIcon,
        CopyToClipboard
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        actions: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: "title"
        },
        headers: {
            type: Array,
            default: () => []
        },
        funcShowAction: {
            type: Function,
            default: () => true
        },
        options: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            getRegistrationStateColor: getRegistrationStateColor,
            defaultOptions: {
                sortBy: "state",
                rowsPerPage: -1,
                sortDesc: true
            },
            OBJECT_TYPES: OBJECT_TYPES,
            ADDRESS_TYPES: ADDRESS_TYPES
        };
    },
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapState("auth", ["accountDocId"]),
        defaultHeaders() {
            return [
                {
                    text: this.$t("header-actions"),
                    value: "actions",
                    sortable: false,
                    align: "left",
                    isMobile: true
                }
            ];
        },
        computedHeaders() {
            return [...this.headers, ...this.defaultHeaders];
        },
        isNotEmpty() {
            return this.items && this.items.length > 0;
        },
        computedOptions() {
            return Object.assign(this.defaultOptions, this.options);
        }
    },
    methods: {
        getDiffDaysBeetweenDateAndNow(date) {
            var now = this.$moment(new Date()); //now
            var end = this.$moment.utc(date).local();
            const diffDays = now.diff(end, "days");
            return diffDays;
        },
        maybeDisableAction(action, item) {
            if (!action.disabled) return false;
            return action.disabled(item);
        },
        onActionClick(action, item) {
            this.$emit("click:action", { action, item });
        }
    }
};
</script>
<style src="./tasks-table.styl" lang="stylus"></style>
