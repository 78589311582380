<i18n src="./faq-content-i18n.yaml"></i18n>
<i18n src="./faq-gotchas-i18n.yaml"></i18n>
<template>
    <v-container>
        <template v-if="gotchas">
            <v-row v-if="!searchVisible">
                <v-col>
                    <base-btn
                        v-if="isFilteredSearch"
                        type="secondary"
                        @click="reset"
                    >
                        <v-icon left> mdi-filter-remove </v-icon>
                        {{ $t("btn-filter-clear") }}
                    </base-btn>
                </v-col>
            </v-row>
            <v-container v-if="searchVisible" fluid class="pa-0 ma-0">
                <v-row>
                    <v-col cols="12">
                        <base-text-field
                            v-model="searchModel"
                            clearable
                            :label="$t('search-label')"
                            prepend-inner-icon="mdi-clipboard-text-search"
                            single-line
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <template v-if="filteredTags.length > 0">
                            <span>{{ $t("filter-title") }}</span>
                            <v-combobox
                                v-if="$vuetify.breakpoint.xs"
                                v-model="selectedChips"
                                :items="filteredTags"
                                label="Tags"
                                multiple
                                small-chips
                                chips
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        :key="JSON.stringify(data.item)"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        :disabled="data.disabled"
                                        @click:close="
                                            data.parent.selectItem(data.item)
                                        "
                                    >
                                        <v-avatar
                                            class="primary white--text"
                                            left
                                            v-text="
                                                $t(data.item)
                                                    .slice(0, 1)
                                                    .toUpperCase()
                                            "
                                        ></v-avatar>
                                        {{ $t(data.item) }}
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    {{ $t(item) }}
                                </template>
                            </v-combobox>
                            <v-chip-group
                                v-if="!$vuetify.breakpoint.xs"
                                v-model="selectedChips"
                                column
                                multiple
                            >
                                <v-chip
                                    :color="
                                        selectedChips.indexOf(item) > -1
                                            ? 'green lighten-2'
                                            : ''
                                    "
                                    filter
                                    v-for="item in filteredTags"
                                    :value="item"
                                    :key="item"
                                >
                                    {{ $t(item) }}
                                </v-chip>
                            </v-chip-group>
                            <v-divider
                                v-if="$vuetify.breakpoint.mdAndUp"
                            ></v-divider>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="mt-5">
                <v-col class="mb-12" cols="12">
                    <v-data-iterator
                        ref="gotchasFilter"
                        :items="filtered"
                        :search.sync="searchModel"
                        disable-pagination
                        hide-default-footer
                        :no-results-text="$t('no-results-found')"
                    >
                        <template v-slot:item="{ item: gotcha }">
                            <section :id="gotcha.id" class="mb-12">
                                <v-row no-gutters>
                                    <v-col>
                                        <div v-if="gotcha.q">
                                            <span v-html="gotcha.q"></span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <FaqBtnShare :gotcha="gotcha" />
                                        <v-chip
                                            x-small
                                            v-for="(tag, i) in gotcha.tags"
                                            :key="i"
                                            class="mr-2"
                                        >
                                            {{ $t(tag) }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                                <v-card class="pa-2" outlined>
                                    <Markdown :code="gotcha.d" />
                                    <template v-for="gotchaP in gotcha.p">
                                        <div v-if="gotcha.p" :key="gotchaP.t">
                                            <p v-html="gotchaP.t"></p>
                                            <Markdown
                                                v-if="gotchaP.u"
                                                :code="
                                                    getAsArray('u', gotchaP.u)
                                                "
                                            />
                                        </div>
                                    </template>
                                    <Markdown
                                        v-if="gotcha.o"
                                        :code="gotcha.o"
                                    />
                                    <Markdown
                                        v-if="gotcha.u"
                                        :code="gotcha.u"
                                    />
                                    <Photoswipe bubble>
                                        <v-row
                                            v-if="
                                                gotcha.i &&
                                                getAsArray('i', gotcha.i).i
                                                    .length > 1
                                            "
                                        >
                                            <template
                                                v-for="(gotchaI, i) in gotcha.i"
                                            >
                                                <v-col
                                                    cols="12"
                                                    xs="12"
                                                    :md="
                                                        getAsArray(
                                                            'i',
                                                            gotcha.i
                                                        ).i.length === 2
                                                            ? 6
                                                            : 4
                                                    "
                                                    v-if="gotcha.i"
                                                    :key="i"
                                                >
                                                    <v-img
                                                        v-if="gotchaI.path"
                                                        :src="
                                                            require(`@/assets/${gotchaI.path}`)
                                                        "
                                                        :lazy-src="
                                                            require(`@/assets/${gotchaI.path}`)
                                                        "
                                                        v-pswp="
                                                            require(`@/assets/${gotchaI.path}`)
                                                        "
                                                        class="align-end"
                                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                                                        aspect-ratio="1"
                                                    >
                                                    </v-img>
                                                    <v-img
                                                        v-else
                                                        :src="gotchaI"
                                                        :lazy-src="gotchaI"
                                                        v-pswp="gotchaI"
                                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                                                        max-height="510"
                                                        aspect-ratio="1.7"
                                                    >
                                                    </v-img>
                                                </v-col>
                                            </template>
                                        </v-row>
                                        <template
                                            v-if="
                                                gotcha.i &&
                                                getAsArray('i', gotcha.i).i
                                                    .length === 1
                                            "
                                        >
                                            <v-img
                                                v-if="gotcha.i[0].path"
                                                :src="
                                                    require(`@/assets/${gotcha.i[0].path}`)
                                                "
                                                :lazy-src="
                                                    require(`@/assets/${gotcha.i[0].path}`)
                                                "
                                                v-pswp="
                                                    require(`@/assets/${gotcha.i[0].path}`)
                                                "
                                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                                                max-height="510"
                                                aspect-ratio="1.7"
                                            >
                                            </v-img>
                                            <v-img
                                                v-else
                                                :src="gotcha.i[0]"
                                                :lazy-src="gotcha.i[0]"
                                                v-pswp="gotcha.i[0]"
                                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                                                max-height="510"
                                                aspect-ratio="1.7"
                                            >
                                            </v-img>
                                        </template>
                                    </Photoswipe>
                                </v-card>
                            </section>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <base-alert dense type="error">
                {{ $t("faq-not-available-in-this-language") }}
            </base-alert>
        </template>
    </v-container>
</template>
<script>
import Markdown from "@/components/general/markdown.vue";
import _ from "lodash";
import { Scroll } from "vuetify/lib/directives";
import { GOTCHAS } from "./faq-gotchas.js";
import FaqBtnShare from "./faq-btn-share.vue";
export default {
    name: "FAQ",
    components: {
        Markdown,
        FaqBtnShare
    },

    directives: {
        Scroll
    },

    props: {
        searchIndex: {
            type: String,
            defaul: null
        },
        search: {
            type: String,
            defaul: null
        }
    },

    data() {
        return {
            internalFiltered: [],
            searchModel: "",
            searchVisible: true,
            selectedChips: []
        };
    },

    computed: {
        tags() {
            return this.getTagsFromGotchas(this.gotchas);
        },
        gotchas() {
            let faqs = [];
            //create a faq array to merge the translations
            GOTCHAS.forEach((key) => {
                let obj = {};
                if ("q" in key) {
                    obj.q = this.$t(key.q);
                }
                if ("d" in key) {
                    obj.d = this.$t(key.d);
                }
                if ("s" in key) {
                    obj.s = key.s;
                }
                if ("tags" in key) {
                    obj.tags = key.tags;
                }
                if ("p" in key) {
                    let arrayP = [];
                    key.p.forEach((keyP) => {
                        let objP = {};
                        objP.t = this.$t(keyP.t);
                        if ("u" in keyP) {
                            let arrayPu = [];
                            keyP.u.forEach((keyPu) => {
                                arrayPu.push(this.$t(keyPu));
                            });
                            objP.u = arrayPu;
                        }
                        arrayP.push(objP);
                    });
                    obj.p = arrayP;
                }
                if ("i" in key) {
                    obj.i = key.i;
                }
                if ("o" in key) {
                    let arrayO = [];
                    key.o.forEach((keyO) => {
                        arrayO.push(this.$t(keyO));
                    });
                    obj.o = arrayO;
                }
                if ("u" in key) {
                    let arrayU = [];
                    key.u.forEach((keyU) => {
                        arrayU.push(this.$t(keyU));
                    });
                    obj.u = arrayU;
                }
                faqs.push(obj);
            });
            return Object.keys(faqs).map(function (key) {
                const faq = faqs[key];
                if (faq.o)
                    faq.o = {
                        o: _.toArray(faq.o)
                    };

                if (faq.u)
                    faq.u = {
                        u: _.toArray(faq.u)
                    };

                return Object.assign({}, faq, {
                    id: _.kebabCase(faq.q),
                    m: false
                });
            });
        },
        filtered: {
            get() {
                return this.internalFiltered;
            },
            set() {
                this.internalFiltered = this.filterItems();
            }
        },
        isFilteredSearch() {
            if (this.searchModel || this._searchIndex) return true;
            return false;
        },
        filteredTags() {
            const filteredTags = this.getTagsFromGotchas(this.gotchas);
            if (
                this.isFilteredSearch &&
                this.selectedChips.length !== filteredTags.length + 1
            ) {
                return filteredTags;
            } else {
                return this.tags;
            }
        }
    },

    beforeMount() {
        this.internalFiltered = this.filterItems();
    },

    watch: {
        selectedChips() {
            this.internalFiltered = this.filterItems();
        },
        searchIndex: {
            handler: function (searchIdx) {
                this.reset();
                if (searchIdx) {
                    this.setSearchBySearchIndex(searchIdx);
                }
            },
            immediate: true
        },
        search: {
            handler: function (search) {
                if (search !== null) {
                    const searchLength = search.length;
                    if (searchLength === 0) {
                        this.internalFiltered = this.filterItems();
                    } else {
                        this.internalFiltered = this.filterItems(search);
                    }
                }
            }
        },
        $route: {
            handler: function (newRoute) {
                const querySearch = newRoute?.query?.search;
                const querySearchIndex = newRoute?.query?.searchIndex;
                if (querySearch) {
                    this.searchModel = querySearch;
                    return;
                }
                if (querySearchIndex) {
                    this.setSearchBySearchIndex(querySearchIndex);
                    return;
                }
            },
            immediate: true
        }
    },

    methods: {
        async setSearchBySearchIndex(searchIndex) {
            this._searchIndex = searchIndex;
            const obj = await this.findBySearchIndex(searchIndex);
            if (_.isUndefined(obj)) {
                this.$toast.error(
                    this.$t("search-text-not-found") + "<br>ID: " + searchIndex
                );
                this.searchVisible = true;
            } else {
                this.searchVisible = false;
            }
        },
        getTagsFromGotchas(gotchas) {
            let tags = [];
            gotchas.forEach((gotcha) => {
                if ("tags" in gotcha) {
                    _.forEach(gotcha.tags, (tag) => {
                        if (!_.includes(tags, tag)) {
                            tags.push(tag);
                        }
                    });
                }
            });
            return tags;
        },
        findBySearchIndex(searchIndex) {
            let obj = _.find(
                this.gotchas,
                (gotcha) => gotcha.s === searchIndex
            );
            return obj;
        },
        filterByChips() {
            let me = this;
            return this.gotchas.filter((gotcha) => {
                if ("tags" in gotcha) {
                    if (me.selectedChips.length === 0) {
                        return true;
                    }
                    let found = false;
                    _.forEach(gotcha.tags, (tag) => {
                        if (_.includes(me.selectedChips, tag)) {
                            found = true;
                        }
                    });
                    return found;
                }
            });
        },
        reset() {
            this._searchIndex = null;
            this.searchModel = null;
            this.internalFiltered = [];
            this.searchVisible = true;
            this.selectedChips = [];
        },
        filterItems() {
            const _searchModel = (this.searchModel || "").toLowerCase();
            if (this._searchIndex) {
                let obj = this.findBySearchIndex(this._searchIndex);
                if (_.isUndefined(obj)) {
                    this.searchVisible = true;
                } else {
                    this.searchVisible = false;
                    return [obj];
                }
            }

            return this.filterByChips().filter((gotcha) => {
                const q = gotcha.q ? gotcha.q.toLowerCase() : "";
                const d = gotcha.d ? gotcha.d.toLowerCase() : "";
                let uList = false;
                let oList = false;

                let filterFn = function (array, _searchModel) {
                    return (
                        _.findIndex(array, function (el) {
                            if (el && typeof el === "string") {
                                return (
                                    el.toLowerCase().indexOf(_searchModel) > -1
                                );
                            }
                        }) > -1
                    );
                };

                if (gotcha.o) {
                    oList = filterFn(gotcha.o.o, _searchModel);
                }

                if (gotcha.u) {
                    uList = filterFn(gotcha.u.u, _searchModel);
                }

                return (
                    oList ||
                    uList ||
                    q.indexOf(_searchModel) > -1 ||
                    d.indexOf(_searchModel) > -1
                );
            });
        },
        getAsArray(key, arrayObj) {
            // change key from obj to trasnlation text
            let obj = _.forOwn(arrayObj, (value, key) => {
                if (this.$te(value)) {
                    return (arrayObj[key] = this.$t(value));
                } else {
                    return (arrayObj[key] = value);
                }
            });
            //sample : ('u', ['baa'. foo'])
            let ret = {};
            if (key) {
                ret[key] = _.toArray(obj);
            }

            return ret;
        }
    },
    onScroll(e) {
        this.offsetTop = e.target.scrollTop;
    }
};
</script>
<style src="./faq-content.sass" lang="sass"></style>
