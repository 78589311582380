import './router/componentHooks' // import hooks for components
import Vue from 'vue'
import './plugins/axios'
import "./api/axios-instances";
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/base';
import './plugins/wfields';
import './plugins/app';
import  "@/utils/filters";

require('@/styles/main.styl')

import i18n from "./lang/lang.js";

Vue.config.productionTip = false

import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/de-ch");
moment.locale("de-ch");
Vue.use(VueMoment, { moment });

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

//for DEV
import TreeView from "vue-json-tree-view";
Vue.use(TreeView);

import Photoswipe from "vue-pswipe";
import { VueSvgIcon } from '@yzfe/vue-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
const photoswipeOptions = {};
photoswipeOptions.errorMsg = '<div class="pswp__error-msg">The image could not be loaded.</div>';
Vue.use(Photoswipe, photoswipeOptions);

Vue.component('w-icon', VueSvgIcon)

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import { DisabledIconFocus } from './directives';
Vue.directive('disabled-icon-focus', DisabledIconFocus);

import mixinBase from './mixins/base'
Vue.mixin(mixinBase);

// Filters
import { registerFilters } from "./filters";
registerFilters();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  moment,
  render: h => h(App)
}).$mount('#app')
