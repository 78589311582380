import Vue from "vue";

export const FILTERS = {
    kebabizeLower: function (value) {
        if (!value) return "";
        return value
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/[\s_]+/g, "-")
            .toLowerCase();
    }
};

export const registerFilters = function () {
    Object.keys(FILTERS).forEach((key) => {
        Vue.filter(key, FILTERS[key]);
    });
};
