<template>
    <div
        class="font-weight-medium mb-3"
        :class="computedCls"
        v-bind="$attrs"
        v-on="$listeners"
        style="word-break: break-word"
    >
        <slot />
    </div>
</template>
<script >
export default {
    name: "BaseTitle",
    props: {
        color: {
            type: String,
            default: "regOfficeTitle"
        }
    },
    computed: {
        computedCls() {
            let cls = this.$vuetify.breakpoint.smAndDown
                ? "text-subtitle-1"
                : "text-h6";
            let color = `${this.color}--text`;
            cls += " " + color;
            return cls;
        }
    }
};
</script>
