<template>
    <v-app id="inspire" class="app">
        <div class="w-body-container-backoffice ma-0 pa-0 backgroundOutter">
            <div
                class="w-body-container-backoffice-inner backgroundInner"
                fill-height
            >
                <AppNavigationDrawer
                    v-if="$vuetify.breakpoint.mdAndDown"
                    v-on:navigation-item-click="onNavigationItemClick"
                    :value="drawerRight.show"
                    @input="drawerRight.show = $event"
                >
                    <template v-slot:navigation-content>
                        <NavigationContent
                            v-on:navigation-item-click="onMenuIconClick"
                        />
                    </template>
                </AppNavigationDrawer>
                <AppToolbar
                    ref="appToolbar"
                    @menu-icon-click="onMenuIconClick"
                    :drawer="drawerRight.show"
                    :showMenuBtn="$vuetify.breakpoint.mdAndDown"
                >
                    <template v-slot:app-menu>
                        <app-menu
                            v-if="$vuetify.breakpoint.lgAndUp"
                            :value="menu"
                            @input="menu = $event"
                        >
                            <template v-slot:content>
                                <NavigationContent
                                    @navigation-item-click="menu = false"
                                />
                            </template>
                        </app-menu>
                    </template>
                </AppToolbar>
                <v-main fill-height class="ma-0 pa-0">
                    <v-toolbar dense class="elevation-0">
                        <BreadCrumb />
                        <v-spacer />
                        <div class="mt-2 mr-5">
                            <base-btn
                                icon
                                color="primary"
                                @click="$router.go(-1)"
                            >
                                <v-icon>mdi-arrow-left</v-icon>
                            </base-btn>
                            <base-btn
                                v-if="showReloadBtn"
                                icon
                                @click="onReloadBtnClick"
                            >
                                <v-icon>mdi-reload</v-icon>
                            </base-btn>
                        </div>
                    </v-toolbar>
                    <AppWrapperContainer :contentSize="contentSize">
                        <v-container fluid class="pl-3">
                            <v-row class="pb-1" align="start" v-if="showTitle">
                                <v-col>
                                    <div
                                        :class="
                                            $vuetify.breakpoint.mdAndDown
                                                ? 'text-h4'
                                                : 'text-h3'
                                        "
                                    >
                                        {{ title }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="showTitle && subtitle"
                                class="pb-1"
                                align="start"
                            >
                                <v-col>
                                    <div
                                        :class="
                                            $vuetify.breakpoint.mdAndDown
                                                ? 'subtitle-1'
                                                : 'subtitle-2'
                                        "
                                    >
                                        {{ subtitle }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                        <router-view
                            @change:content-size="
                                (value) => onSetContentSize(value)
                            "
                            @reset:content-size="onResetContentSize"
                            ref="routerView"
                        >
                        </router-view>
                    </AppWrapperContainer>
                    <!-- Go to top -->
                    <btn-scroll-top maxContentSize="1904" />
                </v-main>
            </div>
        </div>
    </v-app>
</template>
<script>
import AppToolbar from "../components/app-toolbar.vue";
import AppNavigationDrawer from "../components/app-navigation-drawer.vue";
import BtnScrollTop from "../components/btn-scroll-top.vue";
import BreadCrumb from "../components/BreadCrumb.vue";
import { mapState } from "vuex";
import AppMenu from "../components/app-menu.vue";
import NavigationContent from "../components/navigation-content.vue";
const DEFAULT_CONTENT_SIZE = "l";
export default {
    name: "AppBackofficeLayoutBase",
    components: {
        AppToolbar,
        AppNavigationDrawer,
        BtnScrollTop,
        BreadCrumb,
        AppMenu,
        NavigationContent
    },
    data() {
        return {
            drawerRight: {
                show: false
            },
            menu: false,
            showTitle: false,
            contentSize: DEFAULT_CONTENT_SIZE,
            showReloadBtn: false
        };
    },
    computed: {
        ...mapState("backoffice", {
            title: "title",
            subtitle: "subtitle"
        })
    },
    methods: {
        onMenuIconClick() {
            this.drawerRight.show = !this.drawerRight.show;
        },
        onNavigationItemClick() {
            this.menu = false;
            this.drawerRight.show = false;
        },
        onResetContentSize() {
            this.contentSize = DEFAULT_CONTENT_SIZE;
        },
        onSetContentSize(newSize) {
            if (newSize) {
                this.contentSize = newSize;
            }
        },
        hasRouterViewChildComponentReload() {
            const componentRef = this.getRefComponentForReload();
            if (componentRef) {
                const routerViewRefMethods = componentRef.$options?.methods;
                const methodsKeys = Object.keys(routerViewRefMethods);
                if (methodsKeys.includes("reload")) {
                    return true;
                }
            }
            return false;
        },
        getRefComponentForReload() {
            const routerViewRef = this.$refs.routerView;
            const routerViewRefChildRef = routerViewRef?.$refs?.routerView;
            let componentRef = null;
            // for refs inside RouterView child (another RouterView as group)
            if (routerViewRefChildRef) {
                componentRef = routerViewRefChildRef;
                // for ref inside RouterView
            } else if (routerViewRef) {
                componentRef = routerViewRef;
            }
            return componentRef;
        },
        onReloadBtnClick() {
            if (!this.hasRouterViewChildComponentReload()) return;
            const refComponentForReload = this.getRefComponentForReload();
            if (refComponentForReload) {
                refComponentForReload.reload();
            }
        }
    },
    watch: {
        $route(to) {
            this.$nextTick(() => {
                this.showReloadBtn = this.hasRouterViewChildComponentReload();
            });
            if (to.meta.isBackoffice && to.name == "backoffice-view") {
                this.showTitle = false;
            } else {
                this.showTitle = true;
            }
        }
    },
    created() {
        if (
            this.$route.meta.isBackoffice &&
            this.$route.name == "backoffice-view"
        ) {
            this.showTitle = false;
        } else {
            this.showTitle = true;
        }
        this.$nextTick(() => {
            this.showReloadBtn = this.hasRouterViewChildComponentReload();
        });
    }
};
</script>
<style src="./base.styl" lang="stylus" scoped></style>
