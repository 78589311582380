<i18n src="./logs-view-i18n.yaml"></i18n>
<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" v-if="logs.length > 0">
                <log-table
                    v-model="selectedLogs"
                    :items="logs"
                    :logsTotalCount="logsTotalCount"
                    :options="tableOptions"
                    :toolbarActions="computedToolbarActions"
                    show-select
                    item-key="docId"
                    @click:row="onRowClick"
                    @click:action="onActionClick"
                    @click:reload="getAllLogs"
                    @click:delete-all="deleteAllLogs"
                    @click:delete-selected="onDeleteSelectedLogs"
                    @update:items-per-page="
                        getAllLogs((page = tableOptions.page), (limit = $event))
                    "
                    @update:page="
                        getAllLogs(
                            (page = $event),
                            (limit = tableOptions.itemsPerPage)
                        )
                    "
                />
            </v-col>
            <v-col v-else cols="12"> Keine Logs vorhanden </v-col>
        </v-row>
        <base-dialog
            v-if="dialog"
            :value="dialog"
            @input="dialog = $event"
            :title="$t('dialog-title')"
            max-width="600"
            icon="mdi-bug"
        >
            <template v-slot:content>
                <v-container v-if="selectedItem" fluid>
                    <div
                        v-for="(item, idx) in selectedItem"
                        :key="idx"
                        class="text-uppercase"
                    >
                        <div class="pt-2">{{ idx }}:</div>
                        <div class="font-weight-bold">{{ item }}</div>
                    </div>
                </v-container>
            </template>
        </base-dialog>
    </v-container>
</template>
<script>
import LogTable from "@/components/devel/log-table.vue";
import { mapGetters } from "vuex";
export default {
    name: "LogsView",
    components: {
        LogTable
    },
    data: () => ({
        logs: [],
        logsTotalCount: 0,
        tableOptions: {
            itemsPerPage: 10,
            page: 1,
            sortBy: "timestamp"
        },
        selectedItem: false,
        dialog: false,
        selectedLogs: []
    }),
    computed: {
        ...mapGetters({
            isUserAdmin: "auth/isUserAdmin"
        }),
        computedToolbarActions() {
            let actions = [];
            if (this.isUserAdmin && this.logs.length > 0) {
                actions.push({
                    id: "deleteAll",
                    emit: "click:delete-all",
                    icon: "mdi-delete",
                    color: "error",
                    title: this.$t("action-delete-all", {
                        counter: this.logsTotalCount
                    })
                });
            }
            if (this.isUserAdmin && this.selectedLogs.length > 0) {
                actions.push({
                    id: "deleteSelected",
                    emit: "click:delete-selected",
                    icon: "mdi-delete",
                    color: "warning",
                    title: this.$t("action-delete-selected", {
                        counter: this.selectedLogs.length
                    })
                });
            }
            return actions;
        }
    },
    methods: {
        onActionClick({ action, item }) {
            switch (action) {
                case "view":
                    this.getLogData(item.docId);
                    break;
                case "delete":
                    this.deleteLog(item.docId);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        onRowClick(item) {
            this.getLogData(item.docId);
        },
        getAllLogs(
            page = this.tableOptions.page,
            limit = this.tableOptions.itemsPerPage
        ) {
            this.tableOptions.itemsPerPage = limit;
            this.tableOptions.page = page;
            this.loader().show();
            const params = {
                limit: limit,
                page: page - 1
            };
            this.$axiosLogsApi
                .get("/", { params })
                .then((response) => {
                    const resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data.data;
                        this.logs = [...resData.logs];
                        this.logsTotalCount = resData.totalCount;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        getLogData(docId) {
            if (!docId) return;
            this.loader().show();
            this.selectedItem = {};
            this.$axiosLogsApi
                .get(`/${docId}`)
                .then((response) => {
                    const resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data;
                        this.selectedItem = resData.data[0];
                        if (this.selectedItem) {
                            this.dialog = true;
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        deleteLog(docId) {
            if (!docId) return;
            this.loader().show();
            this.$axiosLogsApi
                .delete(`/${docId}`)
                .then((response) => {
                    const resStatus = response.status;
                    if (resStatus === 200) {
                        this.logs.splice(
                            this.logs.findIndex((log) => log.docId === docId),
                            1
                        );
                    }
                    this.getAllLogs();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        deleteAllLogs() {
            this.loader().show();
            this.$axiosLogsApi
                .delete("/")
                .then((response) => {
                    const resStatus = response.status;
                    if (resStatus === 200) {
                        this.logs = [];
                    }
                    this.getAllLogs();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        onDeleteSelectedLogs() {
            if (!this.isUserAdmin || this.selectedLogs.length === 0) return;
            this.selectedLogs.forEach((log) => {
                this.deleteLog(log.docId);
            });
            this.selectedLogs = [];
        }
    },
    mounted() {
        this.getAllLogs();
    }
};
</script>
