import { debounce } from "lodash";
import { axiosTasks } from '@/api/axios-instances';
import store from '@/store';

export const  UPDATE_SCROLL_POSITION =  debounce(({ commit }, position ) => {
    commit('UPDATE_REGISTRATION', {field: 'scrollPosition', value: position});
}, 1000);

export const AUTO_SAVE = (_ctx, data) => {
    const taskProcess = data.process;
    if (!taskProcess) return;
    return new Promise((resolve, reject) => {
        axiosTasks.put(`/${taskProcess}`, data)
        .then(response => {
            const resData = response.data.data;
            store.commit('settings/setRegistrationTimestamp', resData.timestamp);
            store.commit('settings/registrationTimestampStatus', 'saved');
            setTimeout(() => store.commit('settings/clearRegistrationTimestampStatus'), 2000);
            resolve(response);
        }).catch(error => {
            store.commit('settings/registrationTimestampStatus', 'error');
            console.log(error);
            reject(error);
        });
    });
};
