<i18n src="./dialog-fullheight-i18n.yaml"></i18n>
<template>
    <v-dialog
        v-model="dialog"
        scrollable
        transition="dialog-bottom-transition"
        content-class="w-fullscreen"
        max-width="1024"
        persistent
        class="my-2"
    >
        <v-card class="my-2">
            <v-app-bar
                tile
                color="cardHeaderGrey"
                flat
                fixed
                height="50"
                class="w-toolbar"
                :elevation="1"
                max-height="50"
            >
                <v-icon v-html="icon" color="regOfficePrimary"></v-icon>
                <v-toolbar-title
                    class="pl-2 regOfficePrimary--text"
                    v-html="title"
                ></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <base-btn
                        icon
                        @click="closeDialog"
                        color="regOfficePrimary"
                    >
                        <v-icon>mdi-close</v-icon>
                    </base-btn>
                </v-toolbar-items>
            </v-app-bar>
            <v-card-text height="100%" width="100%" class="pa-1 pt-0 mt-0">
                <slot name="content"> </slot>
            </v-card-text>
            <v-card-actions>
                <slot name="card-actions"> </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "DialogFullheight",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "My Title"
        },
        icon: {
            type: String,
            default: "mdi-open-in-new"
        }
    },
    data: () => ({
        dialog: false
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
