<template>
    <ol>
        <li v-for="(item, index) in items" :key="index" v-html="item"></li>
    </ol>
</template>
<script>
export default {
    name: "BaseNumericList",
    props: {
        items: {
            type: Array,
            default: () => []
        }
    }
};
</script>
