<template>
    <v-list dense>
        <v-list-item v-for="(item, idx) in data" :key="idx">
            <template v-if="$vuetify.breakpoint.xsOnly">
                <v-list-item-content v-if="'key' in item && 'value' in item">
                    <v-list-item-title class="font-weight-bold text-body-2">
                        <template v-if="item.key.icon">
                            <v-btn
                                v-if="item.key.icon.action"
                                icon
                                @click="
                                    $emit(
                                        'icon-key-click',
                                        item.key.icon.action
                                    )
                                "
                            >
                                <v-icon
                                    small
                                    v-html="item.key.icon.value"
                                ></v-icon>
                            </v-btn>
                            <v-icon
                                v-else
                                :color="
                                    item.key.icon.color
                                        ? item.key.icon.color
                                        : 'primary'
                                "
                                small
                                v-html="item.key.icon.value"
                            >
                            </v-icon>
                        </template>
                        {{ item.key.text }}
                    </v-list-item-title>
                    <div class="text-subtitle-2">
                        <template v-if="item.value.icon">
                            <v-btn
                                v-if="item.value.icon.action"
                                icon
                                @click="
                                    $emit(
                                        'icon-value-click',
                                        item.value.icon.action
                                    )
                                "
                            >
                                <v-icon
                                    small
                                    v-html="item.value.icon.value"
                                ></v-icon>
                            </v-btn>
                            <v-icon
                                v-else
                                :color="
                                    item.value.icon.color
                                        ? item.value.icon.color
                                        : 'primary'
                                "
                                small
                                v-html="item.value.icon.value"
                            >
                            </v-icon>
                        </template>
                        {{ item.value.text }}
                    </div>
                </v-list-item-content>
            </template>
            <template v-else>
                <v-list-item-content
                    v-if="item.key && item.key.text"
                    class="mr-0"
                >
                    <div class="mr-0 pa-0 ma-0">
                        <v-list-item-icon
                            class="mr-0"
                            v-if="item.key && item.key.icon"
                        >
                            <v-btn
                                v-if="item.key.icon.action"
                                icon
                                @click="
                                    $emit(
                                        'icon-key-click',
                                        item.key.icon.action
                                    )
                                "
                            >
                                <v-icon
                                    small
                                    v-html="item.key.icon.value"
                                ></v-icon>
                            </v-btn>
                            <v-icon
                                v-else
                                :color="
                                    item.key.icon.color
                                        ? item.key.icon.color
                                        : 'primary'
                                "
                                small
                                v-html="item.key.icon.value"
                            >
                            </v-icon>
                        </v-list-item-icon>
                        {{ item.key.text }}
                    </div>
                </v-list-item-content>
                <v-list-item-content
                    v-if="item.value && item.value.text"
                    class="align-end mr-0"
                >
                    <div>
                        <v-list-item-icon
                            v-if="item.value && item.value.icon"
                            class="mr-0"
                        >
                            <v-btn
                                v-if="item.value.icon.action"
                                icon
                                @click="
                                    $emit(
                                        'icon-value-click',
                                        item.value.icon.action
                                    )
                                "
                            >
                                <v-icon
                                    small
                                    v-html="item.value.icon.value"
                                ></v-icon>
                            </v-btn>
                            <v-icon
                                v-else
                                :color="
                                    item.value.icon.color
                                        ? item.value.icon.color
                                        : 'primary'
                                "
                                small
                                v-html="item.value.icon.value"
                            >
                            </v-icon>
                        </v-list-item-icon>
                        {{ item.value.text }}
                    </div>
                </v-list-item-content>
            </template>
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    name: "KeyValueList",
    props: {
        /** data need to be an Array of Objects
         * text: Text to be displayed
         * icon: [OPTIONAL] OBJECT
         *  - value: [OPTIONAL] icon to be displayed
         *  - color: [OPTIONAL] icon to be displayed
         * {
         *      key: {
         *          text: String
         *          [OPTIONAL]: icon: Object || {}
         *                      - value: [OPTIONAL] icon to be displayed
         *                      - color: [OPTIONAL] color to be displayed
         *                      - action: [OPTIONAL] action to be emited
         *      },
         *      value: {
         *          text: String
         *          [OPTIONAL]: icon: String || undefined
         *          [OPTIONAL]: iconColor: String || undefined
         *      }
         * }
         */
        data: {
            type: Array,
            default: () => []
        }
    }
};
</script>
