<template>
  <SectionBase id="devel-general-components" title="General Components">
    <SectionBase
      id="devel-general-components-global-overlay"
      title="GlobalOverlay"
    >
      <base-text-field v-model="loadingMsg" label="Loading Msg"></base-text-field>
      <template v-slot:card-actions>
        <base-btn color="warning" @click="showGlobalOverlay(true)">
          <v-icon>mdi-update</v-icon>
          Lauch GlobalOverlay
        </base-btn>
        <v-spacer></v-spacer>
      </template>
    </SectionBase>
    <SectionBase
      id="devel-general-components-vuetify-toast-snackbar"
      title="Vuetify-Toast-Snackbar"
    >
      <template v-slot:card-actions>
        <v-row>
          <v-col
            v-for="btn in toastSnackbarButtons"
            :key="btn.text"
            cols="12"
            xs="12"
            sm="4"
            mdAndUp="3"
          >
            <base-btn
              @click="btn.toast ? $toast[btn.toast](btn.toastText) : $toast(btn.toastText)"
              :type="btn.type"
              :color="btn.color"
              :outlined="btn.outlined"
              >{{ btn.text }}</base-btn
            >
          </v-col>
        </v-row>
      </template>
    </SectionBase>
    <SectionBase id="devel-general-components-w-msg-box" title="W-Msg-Box">
      Result: {{ msgBoxResult }}
      <template v-slot:card-actions>
        <base-btn color="info" @click="showMsgBox('reason')">
          reason
        </base-btn>
        <v-spacer></v-spacer>
        <base-btn color="success" @click="showMsgBox('checkbox')">
          checkbox
        </base-btn>
        <v-spacer></v-spacer>
        <base-btn
          color="warning"
          @click="showMsgBox('confirm')"
          relative
        >
          confirm
        </base-btn>
      </template>
    </SectionBase>
  </SectionBase>
</template>

<script>
export default {
  name: "SectionGeneralComponents",
  components: { SectionBase: () => import("./base.vue") },
  data: () => ({
    loadingMsg: "Default Message",
    msgBoxResult: null,
    toastSnackbarButtons: [
      {
        text: "Toaster: Error",
        toast: "error",
        color: "error",
        toastText: "Vuetify-Toast-Snackbar Error",
      },
      {
        text: "Toaster: Success",
        toast: "success",
        color: "success",
        toastText: "Vuetify-Toast-Snackbar Success",
      },
      {
        text: "Toaster: Info",
        toast: "info",
        color: "info",
        toastText: "Vuetify-Toast-Snackbar Info",
      },
      {
        text: "Toaster: Warning",
        toast: "warning",
        color: "warning",
        toastText: "Vuetify-Toast-Snackbar Warning",
      },
      {
        text: "Toaster: Default",
        toastText: "Vuetify-Toast-Snackbar Default",
      }
    ],
  }),
  methods: {
    showGlobalOverlay(val) {
      if (val) {
        this.loader().show(this.loadingMsg);
      }
      val &&
        setTimeout(() => {
          this.loader().hide();
        }, 3000);
    },
    showMsgBox(type) {
      this.msgBoxResult = null;
      let msgOptions = {
        title: {
          text: "Plase confirm..",
          icon: "mdi-alert",
        },
        message: "Do you confirm this?",
        type: type,
      };
      this.$root.$msgBox.open(msgOptions).then((result) => {
        this.msgBoxResult = result;
      });
    },
  },
};
</script>
