<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./ViewEdit-i18n.yaml"></i18n>
<template>
    <v-row>
        <v-col>
            <v-card v-if="deregistrationData && !isLoading" outlined>
                <v-card-title>
                    {{
                        $t("title", {
                            objectType: $t(
                                `task-object-type-${deregistrationData.objectType}`
                            )
                        })
                    }}</v-card-title
                >
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <base-alert type="warning">
                                <i18n path="intro-msg-warning" tag="span">
                                    <template #fullName>
                                        <span class="font-weight-bold">
                                            {{ registrantFullName }}
                                        </span>
                                    </template>
                                    <template #clientCompleteDate>
                                        <base-date-time
                                            v-if="
                                                deregistrationData.clientCompleteDate
                                            "
                                            class="font-weight-bold"
                                            :timestamp="
                                                deregistrationData.clientCompleteDate
                                            "
                                        />
                                    </template>
                                    <template #role>
                                        <span class="font-weight-bold">
                                            {{
                                                $t(
                                                    `task-role-${deregistrationData.role}`
                                                )
                                            }}
                                        </span>
                                    </template>
                                    <template #objectType>
                                        <span class="font-weight-bold">
                                            {{
                                                $t(
                                                    `task-object-type-${deregistrationData.objectType}`
                                                )
                                            }}
                                        </span>
                                    </template>
                                </i18n>
                            </base-alert>
                        </v-col>
                        <v-col cols="12" dense>
                            <TaskSummary
                                :task="deregistrationData"
                                show-copy-btn
                            />
                        </v-col>
                    </v-row>
                    <app-dev-container class="my-6">
                        <template v-slot:content>
                            <div>isBackoffice: {{ isBackoffice }}</div>
                            <div>isReadonly: {{ isReadonly }}</div>
                            {{ backofficeData }}
                            <tree-view
                                v-if="developerMode"
                                :data="Object.assign({}, deregistrationData)"
                                :options="{ maxDepth: 1 }"
                            ></tree-view>
                        </template>
                    </app-dev-container>
                </v-card-text>
                <v-card-actions>
                    <base-btn
                        v-if="developerMode && !isReadonly"
                        type="devel"
                        hideTextOnMobile
                        @click="onDeleteFromDB"
                        >{{ $t("btn-delete") }}</base-btn
                    >
                    <base-btn
                        v-if="!isReadonly"
                        type="delete"
                        hideTextOnMobile
                        @click="onDeleteTask"
                        >{{ $t("btn-delete") }}</base-btn
                    >
                    <v-spacer />
                    <base-btn
                        v-if="!isReadonly"
                        type="save"
                        color="success"
                        hideTextOnMobile
                        @click="dialogCompleteDeregistration = true"
                        >{{ $t("btn-complete-and-confirm") }}</base-btn
                    >
                </v-card-actions>
                <v-row v-if="dialogCompleteDeregistration">
                    <v-col>
                        <DialogCompleteDeregistration
                            v-if="dialogCompleteDeregistration"
                            :value="dialogCompleteDeregistration"
                            @input="dialogCompleteDeregistration = $event"
                            :title="$t('complete-deregistration-title')"
                            max-width="450"
                            icon="mdi-alert"
                            @field:change="onFieldChange"
                            :taskData="deregistrationData"
                            @complete="onCompleteAndConfirmTask"
                        >
                        </DialogCompleteDeregistration>
                    </v-col>
                </v-row>
            </v-card>
            <v-sheet v-else color="grey lighten-4" class="pa-3">
                <v-skeleton-loader
                    class="mx-auto"
                    type="article, article, article, actions"
                ></v-skeleton-loader>
            </v-sheet>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import DialogCompleteDeregistration from "@/components/backoffice/tasks/deregistration/dialog-complete-deregistration.vue";
import { TaskStatus } from "@/data/globals.js";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
export default {
    name: "BackofficeDeregistrationEditViewEdit",
    components: {
        TaskSummary: () =>
            import("@/components/tasks/common/summary/task-summary.vue"),
        DialogCompleteDeregistration
    },
    props: {
        referenceId: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dialogCompleteDeregistration: false,
        TaskStatus,
        isLoading: true
    }),
    computed: {
        ...mapGetters("backofficeDeregistration", {
            deregistrationData: "deregistration",
            deregistrationDataToSave: "deregistrationToSave"
        }),
        ...mapState("settings", {
            isShowLoader: "showLoader"
        }),
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        ...mapGetters("auth", {
            accountDocId: "accountDocId",
            userFullname: "userFullname"
        }),
        backofficeData() {
            return this.deregistrationData?.backoffice;
        },
        backofficeEmployeeId() {
            return this.backofficeData?.accountId;
        },
        registrantFullName() {
            if (!this.deregistrationData) return;
            const registrant = this.deregistrationData?.registrant;
            if (!registrant || Object.keys(registrant).length === 0)
                return null;
            return `${registrant.firstName} ${registrant.familyName}`;
        },
        taskState() {
            return this.deregistrationData?.state;
        },
        isBackoffice() {
            return this.backofficeEmployeeId == this.accountDocId;
        },
        isReadonly() {
            let isReadonly = true;
            if (
                this.taskState ===
                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS
            ) {
                if (this.isBackoffice) isReadonly = false;
            }
            return isReadonly;
        }
    },
    methods: {
        ...mapActions("backofficeDeregistration", [
            ACTION_TYPES.FETCH_TASK_DATA_BY_REFERENCE_ID
        ]),
        ...mapMutations("backofficeDeregistration", [
            MUTATION_TYPES.UPDATE_DEREGISTRATION
        ]),
        ...mapActions("backoffice", [ACTION_TYPES.BACKOFICCE_TASK_COMPLETE]),
        fetchDeregistrationData() {
            this.loader().show();
            this.isLoading = true;
            this[ACTION_TYPES.FETCH_TASK_DATA_BY_REFERENCE_ID](this.referenceId)
                .then(() => {
                    this.loader().hide();
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.isLoading = false;
                });
        },
        _deleteFromDB(item) {
            this.loader().show();
            this.$store
                .dispatch(
                    "backoffice/deletePendingPoolRegistrationFromDB",
                    item.docId
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("task-deletet-msg-success")
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("task-deletet-msg-error"));
                    this.loader().hide();
                });
        },
        onDeleteFromDB() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-task-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-delete-task-text", {
                    referenceId: this.deregistrationData.referenceId
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    return this._deleteFromDB(this.deregistrationData);
                }
            });
        },
        onDeleteTask() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-task-title"),
                    icon: "mdi-alert"
                },
                type: "reason",
                message: this.$t("msg-box-delete-task-text", {
                    referenceId: this.deregistrationData.referenceId
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result.action === "agree") {
                    this.loader().show();
                    const data = {
                        docId: this.deregistrationData.docId,
                        reason: result.reason
                    };
                    this.$store
                        .dispatch(
                            "backoffice/deletePendingPoolRegistration",
                            data
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                this.$toast.success(
                                    this.$t("task-deletet-msg-success")
                                );
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.error(
                                this.$t("task-deletet-msg-error")
                            );
                            this.loader().hide();
                        });
                }
            });
        },
        onCompleteAndConfirmTask(sapEquipmentNr) {
            if (!sapEquipmentNr) return;
            this.onFieldChange({
                field: "backoffice.sapEquipmentNr",
                value: sapEquipmentNr
            });
            this.loader().show();
            this[ACTION_TYPES.BACKOFICCE_TASK_COMPLETE](
                this.deregistrationDataToSave
            )
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(
                            this.$t("task-complete-msg-success")
                        );
                        this.$router.push({
                            name: "backoffice-home"
                        });
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("task-complete-msg-error"));
                    this.loader().hide();
                });
        },
        onFieldChange({ field, value }) {
            if (!field) return;
            this[MUTATION_TYPES.UPDATE_DEREGISTRATION]({
                field,
                value
            });
        }
    },
    mounted() {
        if (this.referenceId) {
            this.fetchDeregistrationData();
        } else {
            this.$router.push("/404");
        }
    }
};
</script>
