<i18n src="./content-i18n.yaml"></i18n>
<template>
    <base-card>
        <InsurerBaseList
            :items="items"
            :options="options"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            v-model="selectedTasks"
            item-key="docId"
            @reloadDataClick="fetchAprovalRequiredPoolTasks"
        >
        </InsurerBaseList>
    </base-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { TaskActionTypes } from "@/data/task-action-types";
import taskList from "@/mixins/backoffice/taskList";
import InsurerBaseList from "@/components/insurer/base-list.vue";
import * as ACTION_TYPES from "@/store/types/action-types";
export default {
    name: "ViewInsurerTaksListAprovalRequiredPoolTasksContent",
    mixins: [taskList],
    components: {
        InsurerBaseList
    },
    data() {
        return {
            options: {
                sortBy: "registrantCompleteDate"
            },
            actionsItems: [
                TaskActionTypes.VIEW,
                TaskActionTypes.VIEW_CHECK,
                TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP,
                TaskActionTypes.DATA,
                TaskActionTypes.VIEW_HISTORY
            ],
            selectedTasks: []
        };
    },
    computed: {
        ...mapState("insurer", {
            items: "approvalRequiredPoolTasks"
        }),
        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant"),
                    value: "registrant",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false,
                    sort: (a, b) => {
                        const timestampA = this.$moment.utc(a).unix();
                        const timestampB = this.$moment.utc(b).unix();
                        return timestampA - timestampB;
                    }
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        }
    },
    methods: {
        ...mapActions("insurer", [
            ACTION_TYPES.FETCH_APROVAL_REQUIRED_POOL_TASKS
        ]),
        fetchAprovalRequiredPoolTasks() {
            this.loader().show();
            this[ACTION_TYPES.FETCH_APROVAL_REQUIRED_POOL_TASKS]()
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        reload() {
            this.fetchAprovalRequiredPoolTasks();
        }
    },

    created() {
        if (this.isAuth) {
            this.fetchAprovalRequiredPoolTasks();
        }
    }
};
</script>

