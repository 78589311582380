<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./dialog-register-equipment-i18n.yaml"></i18n>
<template>
    <div>
        <base-dialog
            :value="dialog"
            @input="$emit('input', $event)"
            :title="$t('title')"
            max-width="600"
            icon="mdi-information-outline"
        >
            <template v-slot:content>
                <v-container>
                    <v-row dense>
                        <v-col cols="12">
                            <!-- Objekttyp auswählen -->
                            <div
                                class="caption"
                                v-html="$t('object-type-caption')"
                            ></div>
                            <base-select
                                v-model="objectType"
                                :items="objectTypeItems"
                                :error-messages="objectTypeErrors"
                                :label="$t('object-type-label')"
                                :placeholder="$t('object-type-placeholder')"
                                :required="isRequiredField($v.objectType)"
                                dense
                                clearable
                                :item-text="
                                    (item) =>
                                        $t(`task-object-type-${item.value}`)
                                "
                            >
                                <template v-slot:prepend-item>
                                    <div
                                        class="text-body-1 pl-3"
                                        v-html="$t('object-type-label')"
                                    ></div>
                                    <v-divider></v-divider>
                                </template>
                            </base-select>
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="
                                objectType &&
                                objectType !== OBJECT_TYPES.PRESSURE_EQUIPMENT
                            "
                        >
                            <base-alert type="info" dense
                                >{{ $t("download-pdf-msg") }}
                            </base-alert>
                            <app-btn-split
                                color="regOfficeBtnPrimary"
                                :items="downloadPdfBtnItems"
                                @click="
                                    onClickDownloadPdfBtnActionClick(
                                        downloadPdfActions.DOWNLOAD_PDF_DE
                                    )
                                "
                                @list:click="
                                    (item) =>
                                        onClickDownloadPdfBtnActionClick(
                                            item.actionId
                                        )
                                "
                                ><v-icon left>mdi-content-save</v-icon
                                >{{ $t("btn-download-pdf-de") }}</app-btn-split
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template
                v-slot:actions
                v-if="
                    objectType && objectType === OBJECT_TYPES.PRESSURE_EQUIPMENT
                "
            >
                <v-spacer />
                <base-btn
                    @click="createTask"
                    type="primary"
                    btnIcon="'mdi-arrow-right'"
                >
                    {{ $t("btn-continue") }}
                </base-btn>
            </template>
        </base-dialog>
        <DialogCreateTask
            ref="formCreate"
            :value="dialogCreateTask"
            @input="dialogCreateTask = $event"
        >
        </DialogCreateTask>
    </div>
</template>
<script>
import { OBJECT_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
import { required } from "vuelidate/lib/validators";
const downloadPdfActions = {
    DOWNLOAD_PDF_DE: "DOWNLOAD_PDF_DE",
    DOWNLOAD_PDF_FR: "DOWNLOAD_PDF_FR",
    DOWNLOAD_PDF_IT: "DOWNLOAD_PDF_IT"
};
export default {
    name: "DialogRegisterEquipment",
    mixins: [validateMixin],
    components: {
        DialogCreateTask: () =>
            import("@/components/tasks/create/modal-stepper.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    validations: {
        objectType: { required }
    },
    data: () => ({
        dialog: false,
        objectType: null,
        OBJECT_TYPES,
        downloadPdfActions,
        dialogCreateTask: false,
        objectTypeItems: [
            {
                value: OBJECT_TYPES.PRESSURE_EQUIPMENT
            },
            {
                value: OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT
            },
            {
                value: OBJECT_TYPES.PIPES_STEAM_HOT_WATER
            }
        ]
    }),
    computed: {
        objectTypeErrors() {
            const errors = [];
            if (!this.$v.objectType.$dirty) {
                return errors;
            }
            if (!this.$v.objectType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        downloadPdfBtnItems() {
            return [
                {
                    text: this.$t("btn-download-pdf-fr"),
                    actionId: downloadPdfActions.DOWNLOAD_PDF_FR
                },
                {
                    text: this.$t("btn-download-pdf-it"),
                    actionId: downloadPdfActions.DOWNLOAD_PDF_IT
                }
            ];
        }
    },
    methods: {
        reset() {
            this.objectType = null;
        },
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
            this.reset();
        },
        onClickDownloadPdfBtnActionClick(action) {
            switch (action) {
                case downloadPdfActions.DOWNLOAD_PDF_FR:
                    if (
                        this.objectType === OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT
                    ) {
                        this.downloadFile("88282_fr.pdf");
                    } else {
                        this.downloadFile("88268_fr.pdf");
                    }

                    break;
                case downloadPdfActions.DOWNLOAD_PDF_IT:
                    if (
                        this.objectType === OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT
                    ) {
                        this.downloadFile("88282_it.pdf");
                    } else {
                        this.downloadFile("88268_it.pdf");
                    }

                    break;

                default:
                    if (
                        this.objectType === OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT
                    ) {
                        this.downloadFile("88282_de.pdf");
                    } else {
                        this.downloadFile("88268_de.pdf");
                    }
                    break;
            }
        },
        createTask() {
            this.dialogCreateTask = true;
            this.closeDialog();
        },
        downloadFile(filename) {
            const link = document.createElement("a");
            link.href = `/files/${filename}`;
            link.setAttribute("download", filename);
            link.click();
        }
    },

    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
