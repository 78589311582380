<i18n src="./dialog-impressum-i18n.yaml"></i18n>
<template>
    <BaseDialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        :max-width="600"
        icon="mdi-foot-print"
    >
        <template v-slot:content>
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-card outlined flat>
                            <v-card-text class="subtitle-1">
                                <display-address
                                    :address="registrationOfficeData.address"
                                />
                                <div>
                                    {{ $t("info-contact-time") }}:
                                    <div
                                        v-for="(
                                            workingTime, i
                                        ) in registrationOfficeData.workingTime"
                                        :key="i"
                                    >
                                        <div v-if="'days' in workingTime">
                                            <span
                                                v-if="
                                                    workingTime.days.length ===
                                                    1
                                                "
                                                >{{
                                                    $tc(
                                                        "info-contact-day",
                                                        workingTime.days.length,
                                                        {
                                                            day: $moment()
                                                                .days(
                                                                    workingTime
                                                                        .days[0]
                                                                )
                                                                .format("dddd")
                                                        }
                                                    )
                                                }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    $tc(
                                                        "info-contact-day",
                                                        workingTime.days.length,
                                                        {
                                                            day1: $moment()
                                                                .days(
                                                                    workingTime
                                                                        .days[0]
                                                                )
                                                                .format("dddd"),
                                                            day2: $moment()
                                                                .days(
                                                                    workingTime
                                                                        .days[1]
                                                                )
                                                                .format("dddd")
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <template v-if="'times' in workingTime">
                                            <div
                                                v-for="(
                                                    time, idxTime
                                                ) in workingTime.times"
                                                :key="idxTime"
                                            >
                                                &#9679; <b>{{ time }}</b>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="dialogContact">
                        <DialogContact
                            :value="dialogContact"
                            @input="dialogContact = $event"
                        >
                        </DialogContact>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-row no-gutters class="pb-3">
                <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />
                <v-col>
                    <base-btn color="info" block @click="dialogContact = true">
                        <v-icon class="mr-2">mdi-chat</v-icon>
                        {{ $t("btn-contact") }}
                    </base-btn>
                </v-col>
                <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />
            </v-row>
        </template>
    </BaseDialog>
</template>
<script>
import DialogContact from "@/components/general/dialog-contact.vue";
import DisplayAddress from "@/components/general/display-address.vue";
import { mapState } from "vuex";
export default {
    name: "DialogImpressum",
    components: {
        DialogContact,
        DisplayAddress
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        dialogContact: false
    }),
    computed: {
        ...mapState("settings", ["registrationOfficeData"])
    },
    methods: {
        closeDialog() {
            this.$emit("input", false);
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
