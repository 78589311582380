var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card-title-actions',_vm._g(_vm._b({attrs:{"actions":_vm.cardActions,"loading":_vm.isLoading,"title":_vm.$t('title')},on:{"click:reload":_vm.fetchHistoryActivitiesItems},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.activities.length > 0)?_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.activities),function(item,index){return _c('v-timeline-item',{key:index,attrs:{"small":""}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.getIconAndColorByHistoryItem(item).icon))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t( ("category-" + (_vm.FILTERS.kebabizeLower( item.category ))) ))+" ")]),_c('i18n',{staticClass:"ml-1",attrs:{"path":("info-" + (_vm.FILTERS.kebabizeLower(item.code))),"tag":"span"},scopedSlots:_vm._u([{key:"user",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.itemUserFullName(item)))])]},proxy:true},{key:"timestamp",fn:function(){return [_c('base-date-time',{attrs:{"timestamp":item.timestamp,"clsDate":"font-weight-bold","clsTime":"font-weight-bold"}})]},proxy:true},{key:"manufacturerName",fn:function(){return [(
                                    _vm.itemHasExtras(item) &&
                                    item.extras.manufacturer_name
                                )?_c('strong',[_vm._v(_vm._s(item.extras.manufacturer_name))]):_vm._e()]},proxy:true},{key:"reason",fn:function(){return [(
                                    _vm.itemHasExtras(item) &&
                                    item.extras.reason
                                )?_c('strong',[_vm._v(_vm._s(item.extras.reason))]):_vm._e()]},proxy:true},(_vm.itemHasExtras(item))?{key:"taskRefId",fn:function(){return [_c('b',[_vm._v(_vm._s(item.extras.taskRefId))])]},proxy:true}:null,(_vm.itemHasExtras(item))?{key:"taskRefIdCopy",fn:function(){return [_c('b',[_vm._v(_vm._s(item.extras.task_ref_id_copy))])]},proxy:true}:null,(_vm.itemHasExtras(item))?{key:"sapResponse",fn:function(){return [_c('div',[_c('base-alert',{attrs:{"type":"warning","dense":""}},[_c('b',[_vm._v(_vm._s(item.extras.response.detail.message))])])],1)]},proxy:true}:null],null,true)})],1),(item.timestamp)?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock")]),_c('base-date-time',{attrs:{"timestamp":item.timestamp,"clsDate":"font-weight-bold","clsTime":"font-weight-bold"}})],1):_vm._e()])}),1):_c('div',[_vm._v(" "+_vm._s(_vm.$t("msg-no-data"))+" ")])]},proxy:true}])},'app-card-title-actions',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"text-center"},[_c('base-pagination',{attrs:{"length":_vm.totalPages},on:{"input":_vm.fetchHistoryActivitiesItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }