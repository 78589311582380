<i18n src="./dashboard-view-i18n.yaml"></i18n>
<template>
    <div>
        <v-container fill-height fluid v-if="isAuth">
            <v-row align="center" justify="center">
                <v-col md="8">
                    <base-header>
                        {{ $t("title") }}
                    </base-header>
                    <base-subheader>
                        {{ $t("subtitle") }}
                    </base-subheader>
                </v-col>
            </v-row>
            <v-row
                align="center"
                justify="center"
                :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
                no-gutters
                v-if="userFullname"
            >
                <v-col cols="12" md="8">
                    <div class="primary--text center text-subtitle-1">
                        <span>
                            {{ $t(greetingMsg) }}
                        </span>
                        <span class="font-weight-bold">
                            <span v-if="userSalutation">
                                {{ salutation }}
                            </span>
                            <span v-if="userFullname">
                                {{ userFullname }}
                            </span>
                        </span>
                        <v-btn
                            icon
                            x-small
                            color="info"
                            :to="{
                                name: 'account-settings-user',
                                params: { docId: accountDocId }
                            }"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row
                align="center"
                justify="center"
                class="ml-2"
                no-gutters
                v-if="!userFullname"
            >
                <v-col xs="12" md="8">
                    <base-btn
                        color="info"
                        :to="{
                            name: 'account-settings-user',
                            params: { docId: accountDocId }
                        }"
                    >
                        <v-icon class="pr-2">mdi-pencil</v-icon>
                        {{ $t("settings") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-container>
        <!-- WELCOME TEXT CONTAINER -->
        <v-container fill-height fluid v-if="isAuth" class="ma-0 py-0">
            <v-row align="center" justify="center">
                <v-col cols="12" md="8">
                    <div class="primary--text text-subtitle-1">
                        {{ $t("welcome-text") }}
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- SHOW OPEN REGISTRATION -->
        <v-container
            fill-height
            fluid
            v-if="isAuth && Object.keys(openRegistration).length > 0"
            class="mt-5"
        >
            <v-row align="center" justify="center" no-gutters>
                <v-col cols="12" md="8">
                    <base-alert dense type="warning">
                        <span v-html="$t('warning-open-registration')"></span>
                    </base-alert>
                </v-col>
                <v-col cols="12" md="8">
                    <RegistrationCard :registration="openRegistration" />
                </v-col>
                <v-col cols="12" md="8">
                    <base-radio-group
                        v-model="openRegistrationDecision"
                        :error-messages="openRegistrationDecisionErrors"
                        required
                    >
                        <template v-slot:label>
                            <span class="text-subtitle-1">
                                {{ $t("open-registration-title") }}
                            </span>
                        </template>
                        <v-radio
                            color="primary"
                            :label="$t('decision-edit')"
                            name="openRegistrationDecision"
                            :value="1"
                            key="1"
                        >
                        </v-radio>
                        <v-radio
                            color="primary"
                            name="openRegistrationDecision"
                            :label="$t('decision-delete')"
                            :value="2"
                            key="2"
                        >
                        </v-radio>
                    </base-radio-group>
                    <base-btn
                        class="mt-2"
                        type="primary"
                        @click="confirmDecisionRegistration"
                    >
                        <v-icon class="mr-2">mdi-check</v-icon>
                        {{ $t("btn-confirm") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-container>

        <!-- CREATE NEW REGISTRATION -->
        <v-container
            fill-height
            fluid
            v-if="
                isAuth &&
                Object.keys(openRegistration).length === 0 &&
                accountDocId
                && hasSettings
            "
        >
            <v-row align="center" justify="center">
                <v-col md="8">
                    <span class="primary--text center text-subtitle-1">
                        {{ $t("art-registration-title") }}
                    </span>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="8">
                    <base-btn
                        color="info"
                        @click="dialogCreateRegistration = true"
                        height="60"
                        block
                    >
                        <span v-if="$vuetify.breakpoint.smAndDown">
                            {{ $t("register-new-equipment-mobile") }}
                        </span>
                        <span v-else>
                            {{ $t("register-new-equipment") }}
                        </span>
                    </base-btn>
                </v-col>
                <!-- <v-col cols="12" md="8">
                    <base-btn
                        color="info"
                        @click="showDevMsg"
                        height="60"
                        block
                        disabled
                    >
                        <span v-if="$vuetify.breakpoint.smAndDown">
                            {{ $t("registration-change-mobile") }}
                        </span>
                        <span v-else>
                            {{ $t("registration-change") }}
                        </span>
                    </base-btn>
                </v-col> -->
                <v-col cols="12" md="8">
                    <base-btn
                        color="info"
                        @click="dialogRegistrationDeregistration = true"
                        height="60"
                        block
                    >
                        <span v-if="$vuetify.breakpoint.smAndDown">
                            {{ $t("registration-delete-mobile") }}
                        </span>
                        <span v-else>
                            {{ $t("registration-delete") }}
                        </span>
                    </base-btn>
                </v-col>
            </v-row>
        </v-container>
        <DialogRegisterEquipment v-model="dialogCreateRegistration" />
        <v-container
            fill-height
            fluid
            v-if="isAuth && openDialogsRegistrations.length > 0"
            class="mt-5"
        >
            <v-row align="center" justify="center">
                <v-col md="8">
                    <registrations-with-open-dialogs-list
                        :registrations="openDialogsRegistrations"
                    />
                </v-col>
            </v-row>
        </v-container>
        <DialogExternalLink
            v-if="dialogExternalLink && clickedLink"
            :value="dialogExternalLink"
            @input="dialogExternalLink = $event"
            :link="clickedLink"
        >
        </DialogExternalLink>
        <DialogRegistrationDeregistration
            ref="dialogRegistrationDeregistration"
            :value="dialogRegistrationDeregistration"
            @input="dialogRegistrationDeregistration = $event"
        >
        </DialogRegistrationDeregistration>
    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import DialogExternalLink from "@/components/general/dialog-external-link.vue";
import RegistrationsWithOpenDialogsList from "@/components/user-dashboard/registrations-with-open-dialogs-list.vue";
import { PROCESS_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
import RegistrationCard from "@/components/tasks/common/registration-card.vue";
export default {
    name: "DashboardView",
    components: {
        DialogExternalLink,
        RegistrationsWithOpenDialogsList,
        RegistrationCard,
        DialogRegistrationDeregistration: () =>
            import("@/components/tasks/deregistration/modal-stepper.vue"),
        DialogRegisterEquipment: () =>
            import("@/components/tasks/create/dialog-register-equipment.vue")
    },
    mixins: [validateMixin],

    validations: {
        openRegistrationDecision: { required }
    },

    inject: ["theme"],

    data: () => ({
        time: null,
        timeHour: null,
        openRegistrationDecision: null,
        dialogCreateRegistration: false,
        dialogRegistrationDeregistration: false,
        dialogMutation: false,
        dialogCheckout: false,
        dialogExternalLink: false,
        clickedLink: null
    }),

    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        ...mapActions("registrations", [
            "deleteOpen",
            "fetchAllRegistrationsWithOpenDialogs"
        ]),
        deleteRegistrationClick() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-delete-registration-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-delete-registration-text"),
                type: "checkbox"
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.deleteRegistration(result);
                }
            });
        },
        deleteRegistration(reason) {
            this.loader().show();
            let data = {};
            data.token = this.$store.state.auth.token;
            data.accountDocId = this.accountDocId;
            data.docId = this.openRegistration.docId;
            data.reason = reason;

            this.deleteOpen(data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$toast.success(
                            this.$t("registration-delete-success")
                        );
                    }
                    this.loader().hide();
                })
                .catch(function (error) {
                    let errorCode = error.response.data.code;
                    if (this.$te(`delete-error-${errorCode}`)) {
                        //CHECK IF TRANSLATE EXISTS
                        this.$toast.error(
                            this.$t(`delete-error-${errorCode}`, 3000)
                        );
                    } else {
                        //ELSE WILL DISPLAY AN GENERAL ERROR MESSAGE
                        this.$toast.error(this.$t("delete-error-0"));
                    }
                    this.loader().hide();
                });
        },
        editRegistration() {
            let data = this.openRegistration;
            if (data.process === PROCESS_TYPES.REGISTRATION) {
                this.$router.push({
                    name: "task-create",
                    params: { referenceId: data.referenceId }
                });
            }
            if (data.process === PROCESS_TYPES.MUTATION) {
                alert("Mutation not implemented yet");
            }
            if (data.process === PROCESS_TYPES.DEREGISTRATION) {
                alert("Checkout not implemented yet");
            }
        },
        confirmDecisionRegistration() {
            this.$v.$touch();
            if (this.$v.$anyError) {
                return false;
            } else {
                if (this.openRegistrationDecision == 1) {
                    this.editRegistration();
                } else if (this.openRegistrationDecision == 2) {
                    this.deleteRegistrationClick();
                }
            }
        },
        showDevMsg() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-comming-soon-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-comming-soon-text")
            };
            this.$root.$msgBox.open(msgOptions);
        },
        fetchOpenRegistrations() {
            this.loader().show();
            let data = {};
            data.token = this.$store.state.auth.token;
            data.accountDocId = this.$store.state.auth.accountDocId;
            this.$store
                .dispatch("registrations/fetchOpen", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$store.commit("registrations/clearAll");
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error("Error: " + error);
                    this.$store.commit("registrations/clearAll");
                    this.loader().hide();
                });
        },
        initializeTime() {
            this.timeHour = this.$moment().hour();
            this.timeHourInterval = setInterval(() => {
                this.timeHour = this.$moment().hour();
            }, 1000);
        },
        _fetchAllRegistrationsWithOpenDialogs() {
            this.loader().show();
            let data = {};
            data.token = this.$store.state.auth.token;
            data.accountDocId = this.$store.state.auth.accountDocId;
            this.fetchAllRegistrationsWithOpenDialogs()
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error("Error: " + error);
                    this.loader().hide();
                });
        }
    },

    computed: {
        ...mapState("registrations", {
            openRegistration: (state) => state.open,
            openDialogsRegistrations: (state) => state.withOpenDialogs
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated",
            userSalutation: "userSalutation",
            userFullname: "userFullname",
            accountDocId: "accountDocId",
            hasSettings: "hasSettings"
        }),
        ...mapGetters({
            salutations: "attributes/salutations"
        }),
        salutation() {
            if (
                !this.salutations ||
                this.salutations.length === 0 ||
                !this.userSalutation
            )
                return null;
            const locale = this.$i18n.locale;
            const salutaionItem = this.salutations.find(
                (s) => s.key === this.userSalutation
            );
            if (locale && salutaionItem) {
                return salutaionItem.value.title[locale];
            }
            return null;
        },
        greetingMsg() {
            var hrs = this.timeHour;
            var greet = "greeting-";

            if (hrs >= 4 && hrs < 12) greet += "good-morning";
            else if (hrs >= 11 && hrs < 17) greet += "good-afternoon";
            else if (hrs >= 17 && hrs < 23) greet += "good-evening";
            else greet += "hello";

            return greet;
        },
        openRegistrationDecisionErrors() {
            const errors = [];
            if (!this.$v.openRegistrationDecision.$dirty) {
                return errors;
            }
            if (!this.$v.openRegistrationDecision.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    created() {
        this.loader().show();
        let salutationsKey = "salutations";
        this.getStoredAttributesByKey(salutationsKey)
            .then(() => {
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    "Laden der Daten: " + salutationsKey + " war nicht möglich"
                );
            });
    },
    mounted() {
        this.fetchOpenRegistrations();
        this._fetchAllRegistrationsWithOpenDialogs();
        this.initializeTime();
    },
    beforeDestroy() {
        clearInterval(this.timeHourInterval);
    }
};
</script>
