export const SET_REGISTRATION = "SET_REGISTRATION";
export const CLEAR_REGISTRATION = "CLEAR_REGISTRATION";
export const UPDATE_REGISTRATION_FIELD = "UPDATE_REGISTRATION_FIELD";
export const MERGE_REGISTRATION_FIELDS = "MERGE_REGISTRATION_FIELDS";
export const CLEAR_REGISTRATION_ADDRESS = "CLEAR_REGISTRATION_ADDRESS";
export const UPDATE_REGISTRATION_ADDRESS_FIELD =
    "UPDATE_REGISTRATION_ADDRESS_FIELD";
export const UPDATE_REGISTRATION_ADDRESS = "UPDATE_REGISTRATION_ADDRESS";
export const UPDATE_CHAMBERS = "UPDATE_CHAMBERS";
export const UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON =
    "UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON";
export const UPDATE_EQUIPMENT_LOCATION_ADDRESS =
    "UPDATE_EQUIPMENT_LOCATION_ADDRESS";
export const REMOVE_CHAMBER_BY_INDEX = "REMOVE_CHAMBER_BY_INDEX";
export const RESET_EQUIPMENT_LOCATION_ADDRESS =
    "RESET_EQUIPMENT_LOCATION_ADDRESS";
export const RESET_CUSTOMER_SPECIFIC_DESIGNATION =
    "RESET_CUSTOMER_SPECIFIC_DESIGNATION";
export const RESET_EQUIPMENT_LOCATION_CONTACT_PERSON =
    "RESET_EQUIPMENT_LOCATION_CONTACT_PERSON";
export const ENABLE_READONLY_MODUS = "ENABLE_READONLY_MODUS";
export const DISABLE_READONLY_MODUS = "DISABLE_READONLY_MODUS";

// BACKOFFICE
export const CLEAR_REGISTRATION_REVIEW = "CLEAR_REGISTRATION_REVIEW";
export const DELETE_REGISTRATION_REVIEW_DIALOG =
    "DELETE_REGISTRATION_REVIEW_DIALOG";
export const UPDATE_REGISTRATION_REVIEW_STATE =
    "UPDATE_REGISTRATION_REVIEW_STATE";
export const UPDATE_REGISTRATION_REVIEW_DIALOG =
    "UPDATE_REGISTRATION_REVIEW_DIALOG";
export const SET_DIALOG_TO_ACCEPTED = "SET_DIALOG_TO_ACCEPTED";
export const ADD_QUESTION_TO_DIALOG = "ADD_QUESTION_TO_DIALOG";
export const EDIT_MESSAGE_DIALOG = "EDIT_MESSAGE_DIALOG";
export const DELETE_MESSAGES_FROM_DIALOG = "DELETE_MESSAGES_FROM_DIALOG";
export const SET_DEREGISTRATION = "SET_DEREGISTRATION";
export const UPDATE_DEREGISTRATION = "UPDATE_DEREGISTRATION";
export const UPDATE_BACKOFFICE_FIELD = "UPDATE_BACKOFFICE_FIELD";

// INSURER
export const SET_TITLE = "SET_TITLE";
export const SET_SUBTITLE = "SET_SUBTITLE";
export const SET_DEFAULT_TITLE = "SET_DEFAULT_TITLE";
export const SET_APROVAL_REQUIRED_POOL_TASKS = "SET_APROVAL_REQUIRED_POOL_TASKS";
export const SET_COUNTERS = "SET_COUNTERS";
export const REMOVE_TASK_FROM_LIST = "REMOVE_TASK_FROM_LIST";
