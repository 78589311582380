var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pt-2' : 'mb-4',attrs:{"disabled":_vm.disabled}},[(_vm.requireChambers)?_c('v-checkbox',{attrs:{"label":_vm.$t('require-chambers-label')},model:{value:(_vm.chambersRequired),callback:function ($$v) {_vm.chambersRequired=$$v},expression:"chambersRequired"}}):_vm._e(),(_vm.requireAttachments)?_c('v-checkbox',{staticClass:"mr-2 pb-4",attrs:{"label":_vm.$t('require-attachments-label'),"hide-details":""},model:{value:(_vm.attachmentsRequired),callback:function ($$v) {_vm.attachmentsRequired=$$v},expression:"attachmentsRequired"}}):_vm._e(),(_vm.attachmentsRequired)?_c('base-select',{attrs:{"items":_vm.attachmentCategoriesItems,"label":_vm.$t('attachment-categories-label'),"multiple":"","clearable":"","required":_vm.attachmentsRequired},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.requireAttachmentsCategories.length > 0
                                ? 'indigo darken-4'
                                : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[(_vm.requireAttachmentsCategories.length === 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("attachments-select-all-text")))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("attachments-selected-text", { nrOfSelectedCategories: _vm.requireAttachmentsCategories.length })))])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"item",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("attachment-category-" + item)))+" ")]}},{key:"selection",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [(index === 0)?_c('v-chip',[_vm._v(" "+_vm._s(_vm.$t(("attachment-category-" + item)))+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.requireAttachmentsCategories.length - 1)+" andere) ")]):_vm._e()]}}],null,false,1795103487),model:{value:(_vm.requireAttachmentsCategories),callback:function ($$v) {_vm.requireAttachmentsCategories=$$v},expression:"requireAttachmentsCategories"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }