<i18n src="./BreadCrumb-i18n.yaml"></i18n>
<template>
    <v-breadcrumbs :items="breadCrumbs" divider=">">
        <template v-slot:item="props">
            <router-link :to="props.item.href" v-if="!props.item.disabled">
                <v-breadcrumbs-item
                    :class="[props.item.disabled && 'disabled']"
                >
                    {{ $t(props.item.text) }}
                </v-breadcrumbs-item>
            </router-link>
            <v-breadcrumbs-item
                :class="[props.item.disabled && 'disabled']"
                v-else
            >
                {{ $t(props.item.text) }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>
<script>
export default {
    name: "AppBreadCrumb",
    computed: {
        breadCrumbs() {
            //source: https://kinoshita.eti.br/2019/11/09/dynamic-breadcrumbs-with-vue-router-nested-routes-and-vuetify-breadcrumb-component.html
            //source : https://codepen.io/kinow/pen/vYYrWeG?editors=1010
            let pathArray = this.$route.path.split("/");
            pathArray.shift();
            const breadCrumbs = [];
            let breadcrumb = "";
            for (let i = 0; i < pathArray.length; ++i) {
                breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
                if (i === 0) {
                    breadCrumbs.push({
                        href: breadcrumb,
                        disabled:
                            i + 1 === pathArray.length ||
                            pathArray[1] === "home",
                        text: "dashboard"
                    });
                }
                if (
                    this.$route.matched[i] &&
                    Object.hasOwnProperty.call(
                        this.$route.matched[i],
                        "meta"
                    ) &&
                    Object.hasOwnProperty.call(
                        this.$route.matched[i].meta,
                        "breadCrumb"
                    )
                ) {
                    breadCrumbs.push({
                        href: breadcrumb,
                        disabled: i + 1 === pathArray.length,
                        text:
                            this.$route.matched[i].meta.breadCrumb ||
                            pathArray[i]
                    });
                    breadcrumb = "";
                }
            }
            return breadCrumbs;
        }
    }
};
</script>
