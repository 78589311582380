<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./dialog-registration-view-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="title ? title : $t('title')"
        max-width="600"
        :icon="icon"
    >
        <template v-slot:content>
            <v-container v-if="task" fluid>
                <slot name="content-prepend"> </slot>
                <TaskSummary :task="task" />
                <slot name="content-append"> </slot>
            </v-container>
        </template>
    </base-dialog>
</template>
<script>
export default {
    name: "DialogRegistrationView",
    components: {
        TaskSummary: () => import("./summary/task-summary.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        referenceId: {
            type: String,
            default: null,
            required: true
        },
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: "mdi-file-multiple"
        }
    },
    data: () => ({
        dialog: false,
        task: {}
    }),
    methods: {
        reset() {
            this.task = {};
        },
        closeDialog() {
            this.dialog = false;
            this.reset();
            this.$emit("input", this.dialog);
        },
        fetchRegistrationData(referenceId) {
            this.loader().show();
            this.$store
                .dispatch(
                    "registrations/fetchRegistrationDataByReferenceId",
                    referenceId
                )
                .then((response) => {
                    const resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data;
                        this.task = resData.data;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error("Fehler: " + error);
                    this.loader().hide();
                });
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        },
        referenceId: {
            handler: function (newValue) {
                if (newValue) {
                    this.fetchRegistrationData(newValue);
                }
            },
            immediate: true
        }
    }
};
</script>
