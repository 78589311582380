<i18n       src="./tasks-verification-card-i18n.yaml"></i18n>
<template>
    <v-card
        class="overflow-hidden"
        color="light-blue lighten-5"
        flat
        v-if="registrationData && registrationData.state > 30"
        tile
        v-bind="$attrs"
    >
        <v-toolbar
            flat
            color="light-blue lighten-4"
            @click.prevent="expandCard = !expandCard"
        >
            <v-icon :color="toolbarIconColor">{{ toolbarIcon }}</v-icon>
            <v-toolbar-title class="ml-2 font-weight-light">{{
                $t("title")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon>{{
                    expandCard ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
            </v-btn>
        </v-toolbar>
        <v-expand-transition v-if="expandCard">
            <v-card-text>
                <v-checkbox
                    :input-value="reviewVerification.approved"
                    @change="$emit('update-review-verification-state', $event)"
                    :disabled="disableCheckReviewVerification()"
                    :label="$t('step-valid-label')"
                >
                </v-checkbox>
                <v-divider
                    v-if="
                        !reviewVerification.approved ||
                        (reviewVerification.dialog &&
                            'messages' in reviewVerification.dialog &&
                            reviewVerification.dialog.messages.length > 0)
                    "
                />
                <RegistrationDialogContainer
                    :step="step"
                    @delete="onDeleteRegistrationDialog"
                    @create="onCreateRegistrationDialog"
                    :requireChambers="requireChambers"
                    :requireAttachments="requireAttachments"
                    :registrationData="registrationData"
                    :reviewVerification="reviewVerification"
                />
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>
<script>
import RegistrationDialogContainer from "@/components/tasks/dialogs/registration-dialog-container.vue";
import Helpers from "@/utils/helpers";
export default {
    name: "TasksVerificationCard",
    components: {
        RegistrationDialogContainer
    },
    props: {
        reviewVerification: {
            type: Object,
            default: () => {}
        },
        registrationData: {
            type: Object,
            default: () => {}
        },
        step: {
            type: [String, Number],
            default: null
        },
        requireAttachments: {
            type: Boolean,
            default: false
        },
        requireChambers: {
            type: Boolean,
            default: false
        },
        valid: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        modalCreateRegistrationDialog: false,
        expandCard: true
    }),
    computed: {
        toolbarIcon() {
            return this.valid ? "mdi-file-check" : "mdi-file-eye";
        },
        toolbarIconColor() {
            return this.valid ? "green" : undefined;
        }
    },
    methods: {
        onDeleteRegistrationDialog(registrationDialog) {
            this.$emit("delete", registrationDialog);
        },
        onCreateRegistrationDialog(registrationDialog) {
            this.$emit("create", registrationDialog);
        },
        disableCheckReviewVerification() {
            const hasOpenDialogs =
                Helpers.checkIfVerificationStepHasAnOpenDialog(
                    this.reviewVerification
                );
            return hasOpenDialogs;
        },
        openCard() {
            this.expandCard = true;
        },
        closeCard() {
            this.expandCard = false;
        }
    },
    watch: {
        valid: {
            handler: function (newValue) {
                if (newValue == true) {
                    this.closeCard();
                } else {
                    this.openCard();
                }
            },
            immediate: true
        }
    }
};
</script>
