<i18n src="./summary-chambers-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-chambers')" :expandable="expandable">
        <template v-slot:content>
            <v-list-item-subtitle v-if="chambers.length > 0">{{
                $tc("chambers-details", chambers.length, {
                    count: chambers.length || 0,
                    liter: totalChambersVolume || "0.00"
                })
            }}</v-list-item-subtitle>
            <ul class="text-subtitle-2 primary--text">
                <template v-if="chambers.length > 0">
                    <li v-for="(chamber, idx) in chambers" :key="idx">
                        {{ idx + 1 }}:
                        <i18n path="chamber-data" tag="span">
                            <template #description>
                                <span>{{ chamber.descripton }}</span>
                            </template>
                            <template #volume>
                                <span>{{ parseFloat(chamber.volume) }}</span>
                            </template>
                            <template #pressure>
                                <span>{{
                                    parseFloat(
                                        chamber.lastMaxConcessionPressure
                                    ).toFixed(1)
                                }}</span>
                            </template>
                            <template #materialName>
                                <span>{{ chamber.materialName }}</span>
                            </template>
                            <template #coverage>
                                <span v-if="chamber.coverageNotAvailable">{{
                                    $t("without-covarage")
                                }}</span>
                                <span v-else>{{ $t("with-covarage") }}</span>
                            </template>
                        </i18n>
                    </li>
                </template>
                <template v-else>
                    <li>
                        <span class="font-italic">{{
                            $t("not-recorded")
                        }}</span>
                    </li>
                </template>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import mixin from "../mixin";
export default {
    name: "SummaryChambers",
    data() {
      return {
        materialCodes1: {},
        materialCodes2: {},
        materialCodes3: {},
        materialCodes4: {}
      }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
    },
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    computed: {
        chambers() {
            const locale = this.$i18n.locale || 'de';
            const chambers = _.cloneDeep(this.task?.chambers || [])
            const me = this;
            chambers.forEach(function(chamber) {
              const ref = 'materialCodes' + chamber.id;
              const code = me[ref][chamber.materialCode];
              let materialName = '';
              if (code === undefined) {
                  materialName = '(unknown material code: ' + chamber.materialCode + ')'
              } else {
                  materialName = code[locale] || '';
              }
              chamber.materialName = materialName;
            })
            return chambers;
        },
        totalChambersVolume() {
            let volume = 0.0;
            if (this.chambers.length > 0) {
                this.chambers.forEach((value) => {
                    volume = volume + parseFloat(value.volume);
                });
                return parseFloat(volume).toFixed(2);
            } else {
                return 0.0;
            }
        }
    },
    async created() {
        const me = this;
        const toObj = function(attributeName) {
          return new Promise((resolve, reject) => {
              me.getStoredAttributesByKey(attributeName)
                .then((v) => {
                    const r = {}
                    v.forEach((item) => {
                        r[item.key] = item.value;
                    })
                    resolve(r);
                })
                .catch((error) => {
                    reject([error, attributeName]);
                })
            })
        }
        this.loader().show();
        try {
            this.materialCodes1 = await toObj('stoffcode1');
            this.materialCodes2 = await toObj('stoffcode2');
            this.materialCodes3 = await toObj('stoffcode3');
            this.materialCodes4 = await toObj('stoffcode4');
            this.loader().hide();
        } catch (error) {
            console.log(error[0] || error);
            this.loader().hide();
            this.$toast.error(
                this.$t("load-key-error", {
                    keyName: error[1]
                })
            );
        }
    }
};
</script>
