<i18n src="./dialog-company-advanced-search-i18n.yaml"></i18n>
<template>
    <base-dialog
        v-model="dialog"
        :title="$t('title')"
        max-width="800"
        icon="mdi-information-outline"
    >
        <template v-slot:content>
            <CompanyAdvancedSearch
                class="pb-6"
                closeSearchCard
                v-on="$listeners"
            />
        </template>
    </base-dialog>
</template>
<script>
export default {
    name: "DialogCompanyAdvancedSearch",
    components: {
        CompanyAdvancedSearch: () => import("./company-advanced-search.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    }
};
</script>
