<i18n src="./display-device-details-inline-i18n.yaml"></i18n>
<template>
    <div>
        <template v-if="manufacturerName">
            <span>
                {{ manufacturerName }}
                <span v-if="manufacturerDepartment">
                    ({{ manufacturerDepartment }})
                </span>
            </span>
            <CopyToClipboard v-if="showCopyBtn" :value="manufacturerName" />
        </template>
        <template v-if="constructionYear">
            <app-key-value-text-inline
                :keyText="$t('construction-year-short-label')"
                :valueText="constructionYear"
            />
            <CopyToClipboard v-if="showCopyBtn" :value="constructionYear" />
        </template>
        <template v-if="inOperationYear">
            <app-key-value-text-inline
                :keyText="$t('in-operation-year-short-label')"
                :valueText="inOperationYear"
            />
            <CopyToClipboard v-if="showCopyBtn" :value="inOperationYear" />
        </template>
        <template v-if="factoryNumber">
            <app-key-value-text-inline
                :keyText="$t('factory-number-short-label')"
                :valueText="factoryNumber"
            />
            <CopyToClipboard v-if="showCopyBtn" :value="factoryNumber" />
        </template>
    </div>
</template>
<script>
export default {
    name: "DisplayDeviceDetailsInline",
    components: {
        CopyToClipboard: () =>
            import("@/components/general/utlis/copy-to-clipboard.vue")
    },
    props: {
        manufacturerName: {
            type: String,
            default: null
        },
        manufacturerDepartment: {
            type: String,
            default: null
        },
        factoryNumber: {
            type: String,
            default: null
        },
        constructionYear: {
            type: [String, Number],
            default: null
        },
        inOperationYear: {
            type: [String, Number],
            default: null
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        }
    }
};
</script>
