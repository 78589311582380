<i18n       src="./content-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <v-card flat class="pa-2" color="transparent">
            <v-container
                fluid
                fill-height
                v-if="
                    (attachments && Object.keys(attachments).length == 0) ||
                    !attachments
                "
            >
                <base-alert v-if="!readonlyModus" dense type="info">
                    {{ $t("into-text") }}
                </base-alert>
                <v-layout align-center justify-center column fill-height>
                    <v-flex row align-center>
                        <v-icon
                            :size="$vuetify.breakpoint.smAndDown ? 60 : 100"
                        >
                            mdi-image-search
                        </v-icon>
                    </v-flex>
                    <v-flex row align-center class="pt-5">
                        <div
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-h6'
                                    : 'text-h4'
                            "
                        >
                            {{ $t("no-attachments-title") }}
                        </div>
                    </v-flex>
                    <v-flex row align-center class="pt-5">
                        <div
                            v-if="!readonlyModus"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'text-body-1'
                                    : 'text-h4'
                            "
                            class="text-h6"
                        >
                            <span v-if="$vuetify.breakpoint.smAndDown">
                                {{
                                    $t("attachments-max-size-mobile", {
                                        maxFileSize: maxFileSize
                                    })
                                }}
                            </span>
                            <span v-else>
                                {{
                                    $t("attachments-max-size", {
                                        maxFileSize: maxFileSize
                                    })
                                }}
                            </span>
                        </div>
                    </v-flex>
                    <v-flex row align-center class="mt-6">
                        <BtnUploadFiles
                            v-if="!readonlyModus"
                            :disabled="readonlyModus"
                            v-on:click-item="onCategoryItemClick"
                            :categories="categories"
                        >
                        </BtnUploadFiles>
                    </v-flex>
                </v-layout>
            </v-container>
            <div v-else>
                <v-row>
                    <v-col>
                        <BtnUploadFiles
                            v-if="!readonlyModus"
                            :disabled="readonlyModus"
                            v-on:click-item="onCategoryItemClick"
                            :categories="categories"
                        >
                        </BtnUploadFiles>
                    </v-col>
                    <v-spacer />
                    <v-col align="end">
                        <base-btn color="primary" @click="getAllAttachments"
                            ><v-icon left>mdi-reload</v-icon>Neu laden</base-btn
                        >
                    </v-col>
                </v-row>
                <multimedia-viewer
                    :deletable="!readonlyModus"
                    v-if="attachments"
                    :attachments="attachments"
                    thumbnailWidth="100px"
                    thumbnailMaxWidth="100px"
                    thumbnailHeight="100px"
                    v-on:attachment-deleted="deleteAttachment"
                    v-on:delete-multiple-attachments="deleteMultipleAttachment"
                    :docId="taskDocId"
                >
                </multimedia-viewer>
            </div>
            <modal-file-uploader
                v-if="fileCategory"
                :value="showModalFileUploader"
                @input="showModalFileUploader = $event"
                :docId="taskDocId"
                :fileCategory="fileCategory"
                :maxFileSize="maxFileSize"
            >
            </modal-file-uploader>
        </v-card>
    </v-container>
</template>
<script>
import ModalFileUploader from "@/components/general/modal-file-uploader.vue";
import MultimediaViewer from "@/components/general/multimedia-viewer.vue";
import BtnUploadFiles from "@/components/general/btn-upload-files.vue";
import { mapState } from "vuex";
import _ from "lodash";
import { ATTACHMENTS_TYPES } from "@/data/globals.js";
export default {
    name: "StepUploadContent",
    components: {
        ModalFileUploader,
        MultimediaViewer,
        BtnUploadFiles
    },

    props: {
        taskDocId: {
            type: String,
            default: null,
            required: true
        },
        attachmentsStatesRange: {
            type: Array,
            default: () => [],
            required: true
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        attachments: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            attachmentObj: {},
            showModalFileUploader: false,
            fileCategory: "",
            maxFileSize: 150,
            loadingMsg: this.$t("is-loading-msg"),
            categories: [
                { itemId: ATTACHMENTS_TYPES.DRAWING },
                { itemId: ATTACHMENTS_TYPES.ASSEMBLY_CERTIFICATE },
                { itemId: ATTACHMENTS_TYPES.TYPE_EXAMINATION },
                {
                    itemId: ATTACHMENTS_TYPES.DECLARATION_OF_CONFORMITY_AND_CERTIFICATES
                },
                {
                    itemId: ATTACHMENTS_TYPES.OPERATING_AND_MAINTENANCE_INSTRUCTIONS
                },
                { itemId: ATTACHMENTS_TYPES.SAFETY_VALVE_SETTING_CERTIFICATE },
                { itemId: ATTACHMENTS_TYPES.ADDITIONAL }
            ]
        };
    },

    methods: {
        getAllAttachments() {
            this.loader().show(this.$t("is-loading-msg"));
            if (!this.taskDocId) {
                console.log("ATTACHMENTS: DOC_ID NOT FOUND");
                return;
            }
            if (this.attachmentsStatesRange.length != 2) {
                console.log("ATTACHMENTS: INVALID STATE RANGE");
                return;
            }
            let data = {
                docId: this.taskDocId,
                states: _.range(
                    this.attachmentsStatesRange[0],
                    this.attachmentsStatesRange[1]
                )
            };
            this.$axiosBinary
                .post("/getAllTaskAttachmentsByStates", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        const attachments = resData.attachments;
                        this.$emit("update", attachments);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        deleteAttachment(attachment) {
            this.loader().show(this.$t("is-loading-msg-delete"));
            let data = {
                docId: this.taskDocId,
                attachmentId: attachment.id
            };
            this.$axiosBinary
                .delete("/", { data })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.getAllAttachments();
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.error(error);
                    this.loader().hide();
                });
        },
        deleteMultipleAttachment(attachments) {
            this.loader().show(this.$t("is-loading-msg-delete"));
            let axiosPromises = [];
            attachments.forEach((attachment) => {
                let data = {
                    docId: this.taskDocId,
                    attachmentId: attachment.id
                };
                axiosPromises.push(this.$axiosBinary.delete("/", { data }));
            });
            Promise.all(axiosPromises)
                .then(() => {
                    this.getAllAttachments();
                    this.loader().hide();
                    this.loadingMsg = this.$t("is-loading-msg");
                })
                .catch((error) => {
                    console.error(error);
                    this.loader().hide();
                    return;
                });
        },
        onCategoryItemClick(item) {
            this.fileCategory = item;
            this.showModalFileUploader = true;
        },
        onFileUploaderComplete() {
            this.getAllAttachments();
        }
    },

    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        })
    },

    watch: {
        showModalFileUploader: {
            handler: function (newVal, old) {
                if (old === true && newVal === false) {
                    if (this.taskDocId) {
                        this.getAllAttachments();
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
