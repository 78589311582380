<i18n src="./content-i18n.yaml"></i18n>
<template>
    <v-container
        fill-height
        fluid
        no-gutters
        class="pa-2"
        @click="$emit('click:container')"
    >
        <app-dev-container>
            <template v-slot:content>
                @Address:
                <tree-view :data="value" :options="{ maxDepth: 0 }"></tree-view>
            </template>
        </app-dev-container>
        <StepContentTitle :title="$t('title')" />
        <v-row dense>
            <v-col class="pb-4">
                <base-btn
                    class="ml-2"
                    type="secondary"
                    color="primary"
                    btnIcon="mdi-notebook-outline"
                    @click="modalAddressBook = true"
                >
                    {{ $t("title-adressbook") }}
                </base-btn>
            </v-col>
        </v-row>
        <ModalAddressBook
            v-model="modalAddressBook"
            @select:address="onSelectAddressFromAddressBook"
        ></ModalAddressBook>
        <AddressForm
            :readonly="readonlyModus"
            :outlined="false"
            :value="value"
            ref="addressForm"
            @input:field="emitOnFieldInput"
            @change="onChangeAddress"
            @clear="onClear"
        />
    </v-container>
</template>
<script>
import validateMixin from "@/mixins/validate";
export default {
    name: "StepAddressOperatorContent",
    components: {
        StepContentTitle: () =>
            import(
                "@/components/tasks/edit/common/components/step-content-title.vue"
            ),
        AddressForm: () =>
            import("@/components/tasks/edit/common/forms/address/address.vue"),
        ModalAddressBook: () =>
            import("@/components/user-dashboard/modal-address-book.vue")
    },

    mixins: [validateMixin],

    props: {
        value: {
            type: Object,
            default: () => {},
            required: true
        },
        task: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        selectedCompany: null,
        showFormCompanyAddress: false,
        closeSearchCard: false,
        communication: false,
        modalAddressBook: false
    }),

    methods: {
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        validate() {
            this.$refs.addressForm.validate();
        },
        isValid() {
            const isValid = this.$refs.addressForm.isValid();
            return isValid;
        },
        emitOnFieldInput({ field, value }) {
            this.$emit("change:address-field", {
                address: "operatorAddress",
                field: field,
                value: value
            });
        },
        onChangeAddress(address) {
            this.$emit("change:address", address);
        },
        onClear() {
            this.$emit("clear", { field: "operatorAddress" });
        },
        onSelectAddressFromAddressBook(address) {
            this.onClear();
            this.onChangeAddress(address);
            this.modalAddressBook = false;
        },
        onClickCopyAddress(action) {
            this.onChangeAddress(action.address);
        }
    }
};
</script>
