<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./registration-dialog-card-i18n.yaml"></i18n>
<template>
    <v-card tile flat>
        <v-card-title v-if="title" class="text-h6 pl-0">
            {{ title }}
        </v-card-title>
        <v-card-subtitle v-if="subtitle" class="pl-0">
            {{ subtitle }}
        </v-card-subtitle>
        <v-divider v-if="title || subtitle"></v-divider>
        <v-list dense subheader>
            <template v-if="stepSortedDialogs.length > 0">
                <v-list-item
                    v-for="dialogItem in stepSortedDialogs"
                    three-line
                    :disabled="replyDisabled(dialogItem.state)"
                    :key="dialogItem.id"
                    @click="$emit('click', dialogItem)"
                >
                    <v-list-item-avatar tile>
                        <w-icon
                            v-if="
                                dialogItem.state ==
                                TaskDialogStatus.TASK_DIALOG_CREATED
                            "
                            :data="require('@/assets/icons/w-message-star.svg')"
                            :color="getDialogItemColorByState(dialogItem.state)"
                            :width="dialogIconWidth"
                        >
                        </w-icon>
                        <w-icon
                            v-if="
                                dialogItem.state ==
                                TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                            "
                            :data="require('@/assets/icons/w-message-star.svg')"
                            :color="getDialogItemColorByState(dialogItem.state)"
                            :width="dialogIconWidth"
                        >
                        </w-icon>
                        <w-icon
                            v-if="
                                dialogItem.state ==
                                TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
                            "
                            :data="require('@/assets/icons/w-message-eye.svg')"
                            :color="getDialogItemColorByState(dialogItem.state)"
                            :width="dialogIconWidth"
                        >
                        </w-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <v-icon x-small>mdi-bell</v-icon>
                            <v-icon
                                small
                                color="error"
                                v-if="
                                    $moment
                                        .utc(dialogItem.reminder)
                                        .local()
                                        .diff($moment(), 'days') == 2
                                "
                            >
                                mdi-bell-alert</v-icon
                            >
                            {{
                                $t("reminder-item", {
                                    reminderDate: getFormattedDate(
                                        dialogItem.reminder
                                    ),
                                    reminderDateString: getFormattedDateString(
                                        dialogItem.reminder
                                    )
                                })
                            }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            {{
                                $t("dialog-created-caption", {
                                    userName: dialogItem.createdBy,
                                    timestamp: getFormattedDateString(
                                        dialogItem.createdTimestamp
                                    )
                                })
                            }}
                        </v-list-item-subtitle>
                        <v-list-item-title>
                            <div
                                v-if="
                                    dialogItem.messages.slice(-1).pop().reply &&
                                    dialogItem.messages.slice(-1).pop().reply
                                        .text
                                "
                            >
                                {{ $t("registrant") }}:
                                {{
                                    dialogItem.messages.slice(-1).pop().reply
                                        .text
                                }}
                            </div>
                            <div v-else>
                                {{ $t("backoffice") }}:
                                {{
                                    dialogItem.messages.slice(-1).pop().question
                                        .text
                                }}
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <base-btn
                            :tile="$vuetify.breakpoint.mdAndUp"
                            color="info"
                            :disabled="replyDisabled(dialogItem.state)"
                            :fab="$vuetify.breakpoint.smAndDown"
                            :small="$vuetify.breakpoint.smAndDown"
                            @click.stop="$emit('click', dialogItem)"
                        >
                            <div
                                v-if="
                                    dialogItem.state ==
                                        TaskDialogStatus.TASK_DIALOG_CREATED ||
                                    (dialogItem.state ==
                                        TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS &&
                                        registrationData.state ==
                                            TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS)
                                "
                            >
                                <v-icon v-if="$vuetify.breakpoint.smAndDown"
                                    >mdi-message-reply-text</v-icon
                                >
                                <span v-if="$vuetify.breakpoint.mdAndUp">
                                    {{ $t("btn-reply") }}
                                </span>
                            </div>
                            <div v-else>
                                <span
                                    v-if="
                                        registrationData.state ==
                                        TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
                                    "
                                >
                                    <v-icon
                                        v-if="$vuetify.breakpoint.smAndDown"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <span v-if="$vuetify.breakpoint.mdAndUp">
                                        {{ $t("btn-edit") }}
                                    </span>
                                </span>
                                <span v-else>
                                    <v-icon
                                        v-if="$vuetify.breakpoint.smAndDown"
                                    >
                                        mdi-eye
                                    </v-icon>
                                    <span v-if="$vuetify.breakpoint.mdAndUp">
                                        {{ $t("btn-show") }}
                                    </span>
                                </span>
                            </div>
                        </base-btn>
                    </v-list-item-action>
                </v-list-item>
            </template>
            <v-list-item disabled v-if="stepSortedDialogs.length == 0">
                <v-list-item-title class="text-body-1 font-weight-bold py-1"
                    >{{ $t("empty-messages-text") }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
import { getDialogStateColor } from "@/utils/defaults.js";
import Helpers from "@/utils/helpers";
import { TaskDialogStatus, TaskStatus } from "@/data/globals.js";
import _ from "lodash";
export default {
    name: "RegistrationDialogCard",
    props: {
        registrationData: {
            type: Object,
            default: () => {}
        },
        dialogs: {
            tpye: Array,
            default: () => []
        },
        title: {
            tpye: String,
            default: null
        },
        subtitle: {
            tpye: String,
            default: null
        }
    },

    data: () => ({
        TaskDialogStatus: TaskDialogStatus,
        TaskStatus: TaskStatus,
        dialogIconWidth: 30
    }),
    computed: {
        baseIconColor() {
            return this.getColorByKey("grey", "lighten2");
        },
        stepSortedDialogs() {
            return _.sortBy(this.dialogs, ["step"]);
        }
    },
    methods: {
        getDialogStateColor: getDialogStateColor,
        getColorByKey(key, colorization = "base") {
            return Helpers.getColorByKey(key, colorization);
        },
        replyDisabled(state) {
            return (
                this.registrationData.state !=
                    TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS &&
                state == TaskDialogStatus.TASK_DIALOG_CREATED
            );
        },
        getDialogItemColorByState(state) {
            return this.baseIconColor + " " + this.getDialogStateColor(state);
        },
        getFormattedDate(value) {
            if (!value) return null;
            return this.$moment
                .utc(value)
                .local()
                .format("DD.MM.YYYY [um] HH:mm");
        },
        getFormattedDateString(value) {
            if (!value) return null;
            return this.$moment.utc(value).local().from(this.$moment());
        }
    }
};
</script>
