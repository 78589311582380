<template>
    <v-card
        tile
        flat
        :class="cardClass"
        class="pa-0 ma-0"
        :outlined="outlined"
        v-bind="$attrs"
    >
        <v-toolbar
            :color="toolbarColor && !outlined ? toolbarColor : undefined"
            flat
            dense
            :height="outlined ? 35 : undefined"
            class="py-0 my-0"
            @click.prevent="expandCard = !expandCard"
            style="cursor: pointer"
        >
            <v-icon v-if="icon" :color="iconColor" v-html="icon"></v-icon>
            <v-toolbar-title
                class="font-weight-light"
                :class="toolbarTitleClass"
            >
                <slot name="title">
                    {{ title }}
                </slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon>{{
                    expandCard ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 ma-0">
            <v-expand-transition v-if="expandCard">
                <v-container class="pt-0 mt-0">
                    <slot name="content"></slot>
                </v-container>
            </v-expand-transition>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "ExpandableCard",
    props: {
        icon: {
            type: String,
            default: null
        },
        iconColor: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        titleClass: {
            type: String,
            default: null
        },
        titleColor: {
            type: String,
            default: null
        },
        cardClass: {
            type: String,
            default: null
        },
        toolbarColor: {
            type: String,
            default: null
        },
        outlined: {
            type: Boolean,
            default: false
        },
        expand: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        expandCard: false
    }),
    computed: {
        toolbarTitleClass() {
            var cls = "";
            if (this.titleClass) cls += " " + this.titleClass;
            if (this.titleColor) cls += ` ${this.titleColor}--text`;
            return cls;
        }
    },
    watch: {
        expand: {
            handler: function (newValue) {
                this.expandCard = newValue;
            },
            immediate: true
        }
    }
};
</script>
