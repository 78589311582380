var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"readonly":_vm.readonlyModus,"disabled":_vm.disabled}},[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" ChamberId : "+_vm._s(_vm.chamberId)+" "),_c('base-btn',{attrs:{"icon":"","color":"warning"},on:{"click":_vm.setFakeData}},[_c('v-icon',[_vm._v("mdi-bug")])],1),_c('base-btn',{attrs:{"icon":"","color":"yellow"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_vm._v(" Aktiv beheizt : "+_vm._s(_vm.activelyHeated)+" ")]},proxy:true}])}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"sm":"6"}},[_c('base-text-field',{attrs:{"label":_vm.$t('descripton-label'),"hint":_vm.$t('descripton-hint'),"error-messages":_vm.descriptonErrors,"counter":_vm.$v.descripton.$params.maxLength.max,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.descripton)},on:{"click:append":function($event){return _vm.showFaq('chambersDescripton')}},model:{value:(_vm.descripton),callback:function ($$v) {_vm.descripton=$$v},expression:"descripton"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{ref:"volume",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.volume,"value":_vm.volume,"suffix":_vm.$t('label-liter'),"label":_vm.$t('volume-label'),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-liter')
                    }),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.volume.$params.minValue.min,
                        maxValue: _vm.$v.volume.$params.maxValue.max
                    }),"error-messages":_vm.volumeErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.volume)},on:{"input":function($event){_vm.volume = $event},"click:append":function($event){return _vm.showFaq('chambersVolume')}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{ref:"lastMinPressure",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMinPressure,"suffix":_vm.$t('label-bar'),"label":_vm.$t('last-min-pressure-label'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.lastMinPressure.$params.minValue.min,
                        maxValue: _vm.$v.lastMinPressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.lastMinPressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.lastMinPressure)},on:{"input":function($event){_vm.lastMinPressure = $event},"click:append":function($event){return _vm.showFaq('chambersLastMinPressure')}},model:{value:(_vm.lastMinPressure),callback:function ($$v) {_vm.lastMinPressure=$$v},expression:"lastMinPressure"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{ref:"lastMaxPressure",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMaxPressure,"suffix":_vm.$t('label-bar'),"label":_vm.$t('last-max-pressure-label'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.lastMaxPressure.$params.minValue.min,
                        maxValue: _vm.$v.lastMaxPressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.lastMaxPressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.lastMaxPressure)},on:{"input":function($event){_vm.lastMaxPressure = $event},"click:append":function($event){return _vm.showFaq('chambersLastMaxPressure')}},model:{value:(_vm.lastMaxPressure),callback:function ($$v) {_vm.lastMaxPressure=$$v},expression:"lastMaxPressure"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{ref:"lastMinConcessionTemperature",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMinConcessionTemperature,"value":_vm.lastMinConcessionTemperature,"suffix":_vm.$t('label-celsius'),"label":_vm.$t('last-min-concession-temperature-label'),"hint":_vm.$t('hint', {
                        minValue:
                            _vm.$v.lastMinConcessionTemperature.$params.minValue
                                .min,
                        maxValue:
                            _vm.$v.lastMinConcessionTemperature.$params.maxValue
                                .max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-celsius')
                    }),"error-messages":_vm.lastMinConcessionTemperatureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.lastMinConcessionTemperature)},on:{"input":function($event){_vm.lastMinConcessionTemperature = $event},"click:append":function($event){return _vm.showFaq('chambersLastMinConcessionTemperature')}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{ref:"lastMaxConcessionTemperature",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMaxConcessionTemperature,"value":_vm.lastMaxConcessionTemperature,"suffix":_vm.$t('label-celsius'),"label":_vm.$t('last-max-concession-temperature-label'),"hint":_vm.$t('hint', {
                        minValue:
                            _vm.$v.lastMaxConcessionTemperature.$params.minValue
                                .min,
                        maxValue:
                            _vm.$v.lastMaxConcessionTemperature.$params.maxValue
                                .max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-celsius')
                    }),"error-messages":_vm.lastMaxConcessionTemperatureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.lastMaxConcessionTemperature)},on:{"input":function($event){_vm.lastMaxConcessionTemperature = $event},"click:append":function($event){return _vm.showFaq('chambersLastMaxConcessionTemperature')}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('WDecimalField',{ref:"testPressure",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.testPressure,"value":_vm.testPressure,"label":_vm.$t('test-pressure-label'),"suffix":_vm.$t('label-bar'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.testPressure.$params.minValue.min,
                        maxValue: _vm.$v.testPressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.testPressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.testPressure)},on:{"input":function($event){_vm.testPressure = $event},"click:append":function($event){return _vm.showFaq('chambersTestPressure')}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-autocomplete',{attrs:{"items":_vm.sortedTestMediumItems,"label":_vm.$t('test-medium-label'),"item-value":"key","clearable":!_vm.readonlyModus,"append-icon":"mdi-information-outline","filter":_vm.customFilter,"item-text":function (item) { return item.value[_vm.$i18n.locale]
                            ? item.value[_vm.$i18n.locale]
                            : item.value.de; }},on:{"click:append":function($event){return _vm.showFaq('chambersTestMedium')}},model:{value:(_vm.testMedium),callback:function ($$v) {_vm.testMedium=$$v},expression:"testMedium"}})],1)],1),_c('base-autocomplete',{attrs:{"items":_vm.sortedMaterialCodeItems,"label":_vm.$t('material-code-label'),"item-value":"key","error-messages":_vm.materialCodeErrors,"clearable":!_vm.readonlyModus,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.materialCode),"filter":_vm.customFilter},on:{"click:append":function($event){return _vm.showFaq('chambersMaterialCode')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [(item.value[_vm.$i18n.locale])?_c('span',[_vm._v(" "+_vm._s(item.value[_vm.$i18n.locale])+" ")]):_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.value.de)+" (de) ")])]}},{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[(item.value[_vm.$i18n.locale])?_c('span',[_vm._v(" "+_vm._s(item.value[_vm.$i18n.locale])+" ")]):_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.value.de)+" (de) ")])])]}}]),model:{value:(_vm.materialCode),callback:function ($$v) {_vm.materialCode=$$v},expression:"materialCode"}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('coverage-not-available-label'),"color":"primary","append-icon":"mdi-information-outline"},on:{"click:append":function($event){return _vm.showFaq('chambersCoverageNotAvailable')}},model:{value:(_vm.coverageNotAvailable),callback:function ($$v) {_vm.coverageNotAvailable=$$v},expression:"coverageNotAvailable"}})],1),(_vm.coverageNotAvailable)?_c('v-col',{attrs:{"cols":"12"}},[_c('base-textarea',{attrs:{"error-messages":_vm.coverageNotAvailableReasonErrors,"label":_vm.$t('coverage-not-available-reason-label'),"clearable":!_vm.readonlyModus,"counter":_vm.$v.coverageNotAvailableReason.$params.maxLength.max,"required":_vm.isRequiredField(_vm.$v.coverageNotAvailableReason)},model:{value:(_vm.coverageNotAvailableReason),callback:function ($$v) {_vm.coverageNotAvailableReason=$$v},expression:"coverageNotAvailableReason"}})],1):_vm._e()],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.coverageNotAvailable),expression:"!coverageNotAvailable"}],attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('base-autocomplete',{attrs:{"items":_vm.sortedCoverageArtItems,"label":_vm.$t('coverage-art-label'),"item-value":"key","error-messages":_vm.coverageArtErrors,"clearable":!_vm.readonlyModus,"required":_vm.isRequiredField(_vm.$v.coverageArt),"filter":_vm.customFilter,"item-text":function (item) { return item.value[_vm.$i18n.locale]
                            ? item.value[_vm.$i18n.locale]
                            : item.value.de; }},model:{value:(_vm.coverageArt),callback:function ($$v) {_vm.coverageArt=$$v},expression:"coverageArt"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('base-autocomplete',{attrs:{"items":_vm.sortedCoverageLocationItems,"label":_vm.$t('coverage-location-label'),"item-value":"key","error-messages":_vm.coverageLocationErrors,"clearable":!_vm.readonlyModus,"filter":_vm.customFilter,"required":_vm.isRequiredField(_vm.$v.coverageLocation),"item-text":function (item) { return item.value[_vm.$i18n.locale]
                            ? item.value[_vm.$i18n.locale]
                            : item.value.de; }},model:{value:(_vm.coverageLocation),callback:function ($$v) {_vm.coverageLocation=$$v},expression:"coverageLocation"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('WDecimalField',{ref:"coveragePressure",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.coveragePressure,"value":_vm.coveragePressure,"label":_vm.$t('coverage-pressure-label'),"suffix":_vm.$t('label-bar'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.coveragePressure.$params.minValue.min,
                        maxValue: _vm.$v.coveragePressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.coveragePressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.coveragePressure)},on:{"input":function($event){_vm.coveragePressure = $event},"click:append":function($event){return _vm.showFaq('chambersCoveragePressure')}}})],1)],1),_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" @Validation: "),_c('tree-view',{attrs:{"data":_vm.$v,"options":{ maxDepth: 0 }}}),_vm._v(" @GetData: "),_c('tree-view',{attrs:{"data":_vm.getData(),"options":{ maxDepth: 1 }}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }