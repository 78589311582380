var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0"},[(!_vm.isLoading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{attrs:{"expand":"","flat":""}},[_vm._l((_vm.menuItems),function(item){return [(item.items && _vm.isShowAllowed(item.meta))?_c('v-list-group',{key:item.title,attrs:{"group":item.group,"no-action":"","value":item.meta.open},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [(
                                    item.meta &&
                                    item.meta.badge &&
                                    (item.icon || item.svgIcon)
                                )?_c('v-badge',{attrs:{"content":_vm.getBadgeValueById(item.meta.badgeId),"color":"green"}},[(item.icon)?_c('v-icon',{attrs:{"color":"regOfficePrimary"},domProps:{"innerHTML":_vm._s(item.icon)}}):_vm._e(),(item.svgIcon)?_c('w-icon',{attrs:{"data":require(("@/assets/icons/" + (item.svgIcon))),"color":item.svgColor
                                            ? item.svgColor
                                            : 'regOfficePrimary'}}):_vm._e()],1):_c('v-icon',{attrs:{"color":"regOfficePrimary"},domProps:{"innerHTML":_vm._s(item.icon)}})]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)],1)]},proxy:true}],null,true)},[_vm._l((item.items),function(subItem){return [_c('v-list-item',{key:subItem.title,attrs:{"to":_vm.genChildTarget(item, subItem),"disabled":subItem.disabled,"ripple":""},on:{"click":_vm.onNavigationItemClick}},[_c('v-list-item-icon',[(
                                            subItem.meta &&
                                            subItem.meta.badge &&
                                            (subItem.icon ||
                                                subItem.svgIcon)
                                        )?_c('v-badge',{attrs:{"content":_vm.getBadgeValueById(
                                                subItem.meta.badgeId
                                            ),"color":"green"}},[_c('v-icon',{attrs:{"color":"regOfficePrimary"},domProps:{"innerHTML":_vm._s(subItem.icon)}}),(subItem.svgIcon)?_c('w-icon',{attrs:{"data":require(("@/assets/icons/" + (subItem.svgIcon))),"color":subItem.svgColor
                                                    ? subItem.svgColor
                                                    : 'regOfficePrimary',"width":"20","height":"20"}}):_vm._e()],1):(subItem.icon)?_c('v-icon',{attrs:{"color":"regOfficePrimary"},domProps:{"innerHTML":_vm._s(subItem.icon)}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t(subItem.title)))])])],1),(subItem.action)?_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"regOfficePrimary"}},[_vm._v(_vm._s(subItem.action))])],1):_vm._e()],1)]})],2):(
                            (item.header &&
                                item.meta &&
                                _vm.isShowAllowed(item.meta)) ||
                            (item.header && !item.meta)
                        )?_c('v-subheader',{key:item.name},[_vm._v(" "+_vm._s(_vm.$t(item.header))+" ")]):(
                            (item.divider &&
                                item.meta &&
                                _vm.isShowAllowed(item.meta)) ||
                            (item.divider && !item.meta)
                        )?_c('v-divider',{key:item.name}):(item.links)?_c('v-list-group',{key:item.name,attrs:{"no-action":"","value":false,"color":"regOfficePrimary"},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"regOfficePrimary"},domProps:{"innerHTML":_vm._s(item.icon)}})]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)]},proxy:true}],null,true)},_vm._l((item.links),function(link,linkId){return _c('v-list-item',{key:linkId,attrs:{"ripple":"","link":""},on:{"click":function($event){_vm.onOpenLink(link[_vm.currentLang.toUpperCase()])}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"regOfficePrimary"}},[_vm._v("mdi-open-in-new")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(linkId)))])],1)],1)}),1):(
                            item.meta &&
                            item.meta.isDialog &&
                            _vm.isShowAllowed(item.meta)
                        )?_c('v-list-item',{key:item.name,attrs:{"ripple":"","link":"","disabled":item.disabled},on:{"click":function($event){return _vm.onOpenDialog(item.name)}}},[(item.icon)?_c('v-list-item-action',[(
                                    item.meta &&
                                    item.meta.isDeveloperMode &&
                                    _vm.developerMode
                                )?_c('DevBadge',{attrs:{"icon":item.icon}}):_c('v-icon',{attrs:{"color":"regOfficePrimary"}},[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)],1):(
                            (item.meta && _vm.isShowAllowed(item.meta)) ||
                            !item.meta
                        )?_c('v-list-item',{key:item.title,attrs:{"to":!item.href ? { name: item.name } : null,"disabled":item.disabled,"ripple":"","link":""},on:{"click":_vm.onNavigationItemClick}},[(item.icon)?_c('v-list-item-action',[(
                                    item.meta &&
                                    item.meta.isDeveloperMode &&
                                    _vm.developerMode
                                )?_c('DevBadge',{attrs:{"icon":item.icon}}):_c('v-icon',{attrs:{"color":"regOfficePrimary"}},[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1),(item.subAction)?_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"regOfficePrimary"}},[_vm._v(_vm._s(item.subAction))])],1):_vm._e()],1):_vm._e()]})],2)],1)],1):_c('v-row',{attrs:{"no-gutters":""}},_vm._l((5),function(item){return _c('v-col',{key:item,attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-1"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","height":"100","type":"list-item-avatar"}})],1)],1)}),1),_c('DialogExternalLink',{attrs:{"link":_vm.clickedLink},model:{value:(_vm.dialogExternalLink),callback:function ($$v) {_vm.dialogExternalLink=$$v},expression:"dialogExternalLink"}}),_c('DialogAboutApp',{model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),_c('ModalSettings',{model:{value:(_vm.dialogSettings),callback:function ($$v) {_vm.dialogSettings=$$v},expression:"dialogSettings"}}),_c('DialogImpressum',{model:{value:(_vm.dialogImpressum),callback:function ($$v) {_vm.dialogImpressum=$$v},expression:"dialogImpressum"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }