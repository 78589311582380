<i18n src="./modal-address-form-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="value"
        :title="computedTitle"
        max-width="700"
        :icon="icon"
        ref="baseDialog"
        @close="onCloseDialog"
    >
        <template v-slot:content>
            <v-container>
                <app-dev-container>
                    <template v-slot:content>
                        @Data:
                        <tree-view
                            :data="Object.assign({}, addressForm)"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                    </template>
                </app-dev-container>
                <AddressForm
                    :outlined="false"
                    :value="addressForm"
                    ref="addressForm"
                    @input:field="onFieldInput"
                    @change="onChangeAddress"
                    @clear="onClearAddress"
                />
            </v-container>
        </template>
        <template v-slot:actions>
            <slot name="actions">
                <v-spacer></v-spacer>
                <base-btn @click="onClickSave" type="save">
                    {{ $t("btn-save") }}
                </base-btn>
            </slot>
        </template>
    </base-dialog>
</template>

<script>
import _ from "lodash";
export default {
    name: "ModalAddressForm",
    components: {
        AddressForm: () =>
            import("@/components/tasks/edit/common/forms/address/address.vue")
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: "mdi-pencil"
        },
        address: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        addressForm: {}
    }),
    computed: {
        computedTitle() {
            if (this.title) return this.title;
            return this.$t("dialog-title");
        },
        extraScrollOptions() {
            let baseDialogRef = this.$refs.baseDialog;
            let baseDialogCardTextRef = baseDialogRef.$refs.baseDialogCardText;
            return {
                container: baseDialogCardTextRef
            };
        }
    },
    methods: {
        onCloseDialog(e) {
            this.$emit("input", e);
        },
        onClickSave() {
            this.$refs.addressForm.validate();
            if (this.$refs.addressForm.isValid(this.extraScrollOptions)) {
                this.$emit("save", this.addressForm);
            } else {
                this.$toast.error(this.$t("form-has-errors-msg"));
            }
        },
        onFieldInput({ field, value }) {
            const fieldSplitted = field.split(".");
            const fieldSplittedLength = fieldSplitted.length;
            if (fieldSplittedLength > 0) {
                if (fieldSplittedLength === 1) {
                    this.$set(this.addressForm, field, value);
                } else if (fieldSplittedLength === 2) {
                    this.addressForm = _.set(this.addressForm, field, value);
                }
            }
        },
        onChangeAddress(address) {
            this.addressForm = { ...this.addressForm, ...address };
        },
        onClearAddress() {
            this.addressForm = {};
            this.$refs.addressForm.reset();
        }
    },
    watch: {
        value: {
            handler: function (newVal, oldValue) {
                // watch it
                if (newVal) {
                    if (this.address) {
                        this.addressForm = _.cloneDeep(this.address);
                        return;
                    }
                }
                if (oldValue) {
                    this.onClearAddress();
                }
            },
            immediate: true
        }
    }
};
</script>
