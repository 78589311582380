<template>
    <v-select
        v-bind="computedProps"
        v-on="$listeners"
        v-disabled-icon-focus
        :required="required"
        :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
    >
        <!-- Dynamically inherit slots from parent -->
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
        </template>
    </v-select>
</template>
<script>
import mixinWField from "@/mixins/w-field";
export default {
    name: "BaseSelect",
    mixins: [mixinWField]
};
</script>
