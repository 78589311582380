import { TaskDialog } from './TaskDialog';
export class ReviewVerification {
    constructor() {
        this.step0 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step1 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step2 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step3 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step4 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step5 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step6 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step7 = {
            approved : null,
            dialog : new TaskDialog()
        };
        this.step8 = {
            approved : null,
            dialog : new TaskDialog()
        };
    }
}
