<i18n       src="./same-as-operator-i18n.yaml"></i18n>
<template>
    <div>
        <app-dev-container>
            <template v-slot:content>
                @billingAddress:
                <tree-view
                    :data="Object.assign(addressData)"
                    :options="{ maxDepth: 1 }"
                ></tree-view>
                @Address:
                <tree-view
                    :data="Object.assign(address)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
                @operatorAddressIsRecorded: {{ operatorAddressIsRecorded }}
            </template>
        </app-dev-container>
        <StepContentTitle :title="$t('title')" />
        <v-container
            :class="$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pa-0 ma-0'"
            fluid
            v-if="Object.keys(addressData).length > 0 || address"
        >
            <v-form :readonly="readonlyModus" :disabled="disabled">
                <v-row>
                    <v-col>
                        <base-radio-group
                            :label="
                                typeOfAddress
                                    ? $t(
                                          `label-same-address-as-operator-${typeOfAddress}`
                                      )
                                    : undefined
                            "
                            :required="
                                isRequiredField(
                                    $v.address.sameAddressAsOperator
                                )
                            "
                            :class="
                                $vuetify.breakpoint.xsOnly ? undefined : 'pl-2'
                            "
                            v-model="sameAddressAsOperator"
                            :error-messages="sameAddressAsOperatorErrors"
                            @change="onSameAddressAsOperatorChange"
                        >
                            <v-radio
                                v-if="
                                    operatorAddress ||
                                    address.sameAddressAsOperator
                                "
                                :label="$t('same-address-as-operator-true')"
                                :value="true"
                                :disabled="!operatorAddressIsRecorded"
                            >
                            </v-radio>
                            <v-container
                                v-if="
                                    (operatorAddress &&
                                        sameAddressAsOperator === true) ||
                                    address.sameAddressAsOperator
                                "
                                fluid
                                style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.12);
                                "
                                class="pa-0 ma-0 pb-2 ml-12"
                            >
                                <v-card
                                    class="py-0"
                                    tile
                                    flat
                                    max-width="400"
                                    color="transparent"
                                >
                                    <v-card-text class="pt-0 my-0">
                                        <display-address
                                            :address="operatorAddress"
                                        >
                                        </display-address>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                            <v-radio
                                :label="$t('same-address-as-operator-false')"
                                :value="false"
                            ></v-radio>
                        </base-radio-group>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <v-container
            :class="$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pa-0 ma-0'"
            fluid
            v-if="sameAddressAsOperator === false"
        >
            <FormCreateAddress
                v-if="sameAddressAsOperator === false"
                :typeOfAddress="typeOfAddress"
                v-on:field-change="onFieldChange"
                v-on:address-change="onAddressFieldsChange"
                v-on:clear-address="onClearAddress"
                ref="createAddress"
                :formData="address"
                :readonlyModus="readonlyModus"
                :disabled="disabled"
            ></FormCreateAddress>
        </v-container>
        <slot></slot>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import DisplayAddress from "@/components/general/display-address.vue";
import FormCreateAddress from "../forms/form-create-address.vue";
import { Address, CompanyAddress, PrivateAddress } from "@/models/tasks";
import StepContentTitle from "./step-content-title.vue";
import validateMixin from "@/mixins/validate";
import { ADDRESS_TYPES } from "@/data/globals.js";
export default {
    name: "SameAsOperator",
    mixins: [validateMixin],
    components: {
        DisplayAddress,
        FormCreateAddress,
        StepContentTitle
    },
    validations: {
        address: {
            sameAddressAsOperator: { required }
        }
    },
    props: {
        addressData: {
            type: Object,
            default: () => {}
        },
        operatorAddress: {
            type: Object,
            default: () => {}
        },
        typeOfAddress: {
            type: String,
            default: null
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        sameAddressAsOperator: null,
        address: new Address()
    }),
    methods: {
        onAddressFieldsChange(address) {
            this.$emit("address-fields-change", address);
            this.address = address;
            this.address.sameAddressAsOperator = this.sameAddressAsOperator;
        },
        onFieldChange({ field, value }) {
            this.$emit("field-change", { field, value });
        },
        validate() {
            this.$v.$touch();
            if (!this.sameAddressAsOperator) {
                if (this.$refs.createAddress) {
                    this.$refs.createAddress.validate();
                }
            }
        },
        reset() {
            this.$emit("reset");
            this.address = new Address();
        },
        onClearAddress({ field, address }) {
            address.sameAddressAsOperator = this.sameAddressAsOperator;
            this.$emit("clear-address", { field, address });
        },
        isValid() {
            if (this.sameAddressAsOperator) {
                return true;
            }
            if (this.$refs.createAddress) {
                const createAddressValid = this.$refs.createAddress.isValid();
                if (createAddressValid === null) {
                    return null;
                }
                return createAddressValid;
            }
            return null;
        },
        onSameAddressAsOperatorChange(val) {
            if (val) {
                this.onAddressFieldsChange(this.operatorAddress);
            } else {
                this.address = new Address();
                this.onAddressFieldsChange(this.address);
            }
            this.onFieldChange({ field: "sameAddressAsOperator", value: val });
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                if (!this.sameAddressAsOperator) {
                    if (this.$refs.createAddress) {
                        this.$refs.createAddress.scrollToFirstError();
                    }
                }
            });
        },
        isValidAddressCompany(address) {
            return (
                address?.addressType === ADDRESS_TYPES.COMPANY &&
                address?.companyName
            );
        },
        isValidAddressPrivateCompany(address) {
            return (
                address?.addressType === ADDRESS_TYPES.PRIVATE &&
                address?.salutation &&
                address?.firstName &&
                address?.familyName
            );
        },
        isValidAddress(address) {
            return address?.street && address?.zipCode && address?.city;
        },
        setAddressData(address) {
            if (address.addressType === ADDRESS_TYPES.COMPANY) {
                this.address = Object.assign(new CompanyAddress(), address);
            }
            if (address.addressType === ADDRESS_TYPES.PRIVATE) {
                this.address = Object.assign(new PrivateAddress(), address);
            }
        }
    },
    computed: {
        sameAddressAsOperatorErrors() {
            const errors = [];
            if (!this.$v.address.sameAddressAsOperator.$dirty) {
                return errors;
            }
            if (!this.$v.address.sameAddressAsOperator.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        operatorAddressIsRecorded() {
            if (
                this.operatorAddress &&
                this.operatorAddress instanceof Object
            ) {
                if (
                    (this.isValidAddressCompany(this.operatorAddress) ||
                        this.isValidAddressPrivateCompany(
                            this.operatorAddress
                        )) &&
                    this.isValidAddress(this.operatorAddress)
                ) {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        addressData: {
            handler: function (newData) {
                // watch it
                if (newData) {
                    if ("sameAddressAsOperator" in newData) {
                        this.sameAddressAsOperator =
                            newData.sameAddressAsOperator;
                    } else {
                        this.sameAddressAsOperator = null;
                    }
                    this.setAddressData(newData);
                }
            },
            immediate: true
        }
    }
};
</script>
