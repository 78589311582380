<i18n src="./app-text-not-recorded-i18n.yaml"></i18n>
<template>
    <div class="font-italic">{{ $t("text-not-recorded") }}</div>
</template>

<script>
export default {
    name: "AppTextNotRecorded"
};
</script>
