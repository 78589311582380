<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./modal-stepper-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="close"
        :title="$t('dialog-title')"
        width="700"
        ref="modalDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        icon="mdi-file-edit"
    >
        <template v-slot:activator="{ on }">
            <base-btn
                color="info"
                dark
                v-on="on"
                height="60"
                block
                tile
                depressed
            >
                <span v-if="$vuetify.breakpoint.smAndDown">
                    {{ $t("register-new-equipment-mv") }}
                </span>
                <span v-else>
                    {{ $t("register-new-equipment") }}
                </span>
            </base-btn>
        </template>
        <template v-slot:content>
            <v-stepper
                v-model="step"
                :vertical="$vuetify.breakpoint.mobile"
                class="elevation-0"
            >
                <app-dev-container>
                    <template v-slot:content>
                        @Address:
                        <tree-view
                            :data="Object.assign({}, registration)"
                            :options="{ maxDepth: 0 }"
                        ></tree-view>
                        <base-btn
                            tile
                            depressed
                            color="warning"
                            @click="setFakeData"
                        >
                            <v-icon>mdi-bug</v-icon>
                            Set Dummy Data
                        </base-btn>
                    </template>
                </app-dev-container>
                <template v-if="$vuetify.breakpoint.mobile">
                    <v-stepper-step
                        :editable="step > 1"
                        :complete="step > 1"
                        class="px-0"
                        step="1"
                    >
                        {{ $t("equipment-data") }}
                    </v-stepper-step>
                    <v-stepper-content step="1" class="px-0 mx-3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentEquipmentData
                                    ref="contentEquipmentData"
                                    :role="role"
                                    :objectType="objectType"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-step
                        :editable="step > 2"
                        :complete="step > 2"
                        class="px-0"
                        step="2"
                    >
                        {{ $t("working-title") }}
                    </v-stepper-step>

                    <v-stepper-content step="2" class="px-0 mx-3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentWorkingTitle
                                    ref="contentWorkingTitle"
                                    :workingTitle="workingTitle"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :editable="step == 3" class="px-0" step="3">
                        {{ $t("object-details") }}
                    </v-stepper-step>
                    <v-stepper-content step="3" class="px-0 mx-3">
                        <v-card flat>
                            <v-card-text class="pa-0 ma-0">
                                <ContentObjectDetails
                                    ref="contentObjectDetails"
                                    :manufacturer="manufacturer"
                                    :factoryNumber="factoryNumber"
                                    :constructionYear="constructionYear"
                                    :intendedUse="intendedUse"
                                    :inOperationYear="inOperationYear"
                                    @value:change="onFieldValueChange"
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </template>
                <!-- HORIZONTAL -->
                <template v-else>
                    <v-stepper-header>
                        <v-stepper-step
                            :editable="step > 1"
                            :complete="step > 1"
                            step="1"
                            >{{ $t("equipment-data") }}</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step
                            :editable="step > 2"
                            :complete="step > 2"
                            step="2"
                            >{{ $t("working-title") }}</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step step="3">{{
                            $t("object-details")
                        }}</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card flat>
                                <v-card-text class="pa-0 ma-0">
                                    <ContentEquipmentData
                                        ref="contentEquipmentData"
                                        :role="role"
                                        :objectType="objectType"
                                        @value:change="onFieldValueChange"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card flat>
                                <v-card-text class="pa-0 ma-0">
                                    <ContentWorkingTitle
                                        ref="contentWorkingTitle"
                                        :workingTitle="workingTitle"
                                        @value:change="onFieldValueChange"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-card flat>
                                <v-card-text class="pa-0 ma-0">
                                    <ContentObjectDetails
                                        ref="contentObjectDetails"
                                        :manufacturer="manufacturer"
                                        :factoryNumber="factoryNumber"
                                        :constructionYear="constructionYear"
                                        :intendedUse="intendedUse"
                                        :inOperationYear="inOperationYear"
                                        @value:change="onFieldValueChange"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </template>
            </v-stepper>
        </template>
        <template v-slot:actions>
            <base-btn
                v-if="step > 1"
                type="secondary"
                @click="step--"
                :btnIcon="vertical ? 'mdi-arrow-up' : 'mdi-arrow-left'"
            >
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-back") }}
                </span>
            </base-btn>
            <v-spacer />
            <base-btn
                v-if="step !== 3"
                @click="submit"
                type="primary"
                :btnIcon="vertical ? 'mdi-arrow-down' : 'mdi-arrow-right'"
            >
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-continue") }}
                </span>
            </base-btn>
            <base-btn v-if="step === 3" type="save" @click="submit">
                <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("btn-create") }}
                </span>
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
import { BaseRegistration } from "@/models/tasks";
import { ROLE_TYPES, OBJECT_TYPES, PROCESS_TYPES } from "@/data/globals.js";
import ContentEquipmentData from "./content-equipment-data.vue";
import ContentWorkingTitle from "./content-working-title.vue";
import ContentObjectDetails from "./content-object-details.vue";
export default {
    components: {
        ContentEquipmentData,
        ContentWorkingTitle,
        ContentObjectDetails
    },

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            registration: new BaseRegistration(),
            step: 1,
            vertical: false,
            dialog: false
        };
    },
   computed: {
        role: {
            get() {
                return this.registration.role;
            },
            set(value) {
                this.registration.role = value;
            }
        },
        objectType: {
            get() {
                return this.registration.objectType;
            },
            set(value) {
                this.registration.objectType = value;
            }
        },
        workingTitle: {
            get() {
                return this.registration.workingTitle;
            },
            set(value) {
                this.registration.workingTitle = value;
            }
        },
        manufacturer: {
            get() {
                return this.registration.manufacturer;
            },
            set(value) {
                this.registration.manufacturer = value;
            }
        },
        factoryNumber: {
            get() {
                return this.registration.factoryNumber;
            },
            set(value) {
                this.registration.factoryNumber = value;
            }
        },
        constructionYear: {
            get() {
                return this.registration.constructionYear;
            },
            set(value) {
                this.registration.constructionYear = value;
            }
        },
        intendedUse: {
            get() {
                return this.registration.intendedUse;
            },
            set(value) {
                this.registration.intendedUse = value;
            }
        },
        inOperationYear: {
            get() {
                return this.registration.inOperationYear;
            },
            set(value) {
                this.registration.inOperationYear = value;
            }
        }
    },
    methods: {
        close(close) {
            this.$emit("input", close);
        },
        onFieldValueChange({ field, value }) {
            if (!field) return;
            this[field] = value;
        },
        reset() {
            this.step = 1;
            this.clearRegistration();
        },
        clearRegistration() {
            this.registration = new BaseRegistration();
            delete this.registration.referenceId;
            delete this.registration.step;
            this.registration.process = PROCESS_TYPES.REGISTRATION;
            this.registration.objectType = OBJECT_TYPES.PRESSURE_EQUIPMENT;
        },

        setFakeData() {
            this.clearRegistration();
            this.step = 3;
            this.role = ROLE_TYPES.OPERATOR;
            this.workingTitle = "Mein Arbeitstitel";
            this.factoryNumber = "238832424-34234";
            this.constructionYear = 2017;
            this.intendedUse =
                "Curabitur aliquet quam id dui posuere blandit. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.";
            this.inOperationYear = 2018;
        },
        getScrollOptions() {
            let baseDialog = this.$refs.modalDialog;
            let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
            return {
                container: baseDialogCardTextRef
            };
        },
        submit() {
            let scrollOptions = this.getScrollOptions();
            if (this.step == 1) {
                this.$refs.contentEquipmentData.validate();
                if (this.$refs.contentEquipmentData.isValid()) {
                    this.step = 2;
                } else {
                    this.$refs.contentEquipmentData.scrollToFirstError(
                        scrollOptions
                    );
                }
                return;
            }
            if (this.step == 2) {
                this.$refs.contentWorkingTitle.validate();
                if (this.$refs.contentWorkingTitle.isValid()) {
                    this.step = 3;
                } else {
                    this.$refs.contentWorkingTitle.scrollToFirstError(
                        scrollOptions
                    );
                }
                return;
            }
            if (this.step == 3) {
                this.$refs.contentObjectDetails.validate();
                if (this.$refs.contentObjectDetails.isValid()) {
                    this.loader().show();
                    let data = this.registration;
                    this.$store
                        .dispatch("registrations/create", data)
                        .then((response) => {
                            let status = response.status;
                            if (status === 200) {
                                let resData = response.data.data;
                                if (
                                    data.process === PROCESS_TYPES.REGISTRATION
                                ) {
                                    this.$router.push({
                                        name: "task-create",
                                        params: {
                                            referenceId: resData.referenceId
                                        }
                                    });
                                }
                                this.onComplete();
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.error("Fehler: " + error);
                            this.loader().hide();
                        });
                } else {
                    this.$refs.contentObjectDetails.scrollToFirstError(
                        scrollOptions
                    );
                }
            }
        },
        onComplete() {
            this.close();
            this.$nextTick(() => {
                this.reset();
            });
        }
    },
    watch: {
        "$vuetify.breakpoint.mobile"() {
            this.step = 2;
            requestAnimationFrame(() => (this.step = 1)); // Workarounds
        },
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        }
    },
    created() {
        this.clearRegistration();
    }
};
</script>
