import { render, staticRenderFns } from "./summary-new-address.vue?vue&type=template&id=8a64c9d6&scoped=true&"
import script from "./summary-new-address.vue?vue&type=script&lang=js&"
export * from "./summary-new-address.vue?vue&type=script&lang=js&"
import style0 from "./summary-new-address.vue?vue&type=style&index=0&id=8a64c9d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a64c9d6",
  null
  
)

/* custom blocks */
import block0 from "@/data/countries/i18n/all.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fcommon%2Fsummary%2Fderegistration%2Fsummary-new-address.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./summary-new-address-i18n.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fcommon%2Fsummary%2Fderegistration%2Fsummary-new-address.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports