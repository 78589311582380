export const defaultDarkColors = {
    primary: "#FFFFFF",
    backgroundOutter: "#a8a39f",
    backgroundInner: "#303030",
    secondary: "#6a6a6a",
    accent: "#c40c2c",
    success: "#4CAF50",
    info: "#1160cf",
    warning: "#FFC107",
    error: "#F44336"
};
export const defaultLightColors = {
    primary: "#6a6a6a",
    secondary: "#FFFFFF",
    cardHeaderGrey: "#EEEEEE",
    backgroundOutter: "#a8a39f",
    backgroundInner: "#FFFFFF",
    accent: "#c40c2c",
    success: "#4caf50",
    info: "#1160cf",
    warning: "#ff9800",
    error: "#F44336"
};
