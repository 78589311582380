var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseListItem',{attrs:{"title":_vm.$t('title-backoffice'),"expandable":_vm.expandable},scopedSlots:_vm._u([(_vm.backoffice)?{key:"content",fn:function(){return [_c('ul',{staticClass:"text-subtitle-2 primary--text"},[(_vm.backoffice.duplicateDecision)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-duplicate-decision-label'),"valueText":_vm.$translateKebablizerLower(
                            ("task-duplicate-decision-type-" + (_vm.backoffice.duplicateDecision))
                        )}})],1):_vm._e(),(_vm.backoffice.sapEquipmentNr)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-sap-equipment-nr-label'),"valueText":_vm.backoffice.sapEquipmentNr}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.backoffice.sapEquipmentNr}}):_vm._e()],1):_vm._e(),(_vm.backoffice.reportingToSuva !== null)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-reporting-to-suva-label'),"valueText":_vm.backoffice.reportingToSuva
                            ? _vm.$t('yes-label')
                            : _vm.$t('no-label')}})],1):_vm._e(),(_vm.backoffice.objectStatus)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-object-status-label'),"valueText":(_vm.objectStatusTranslatedItem + " (" + (_vm.backoffice.objectStatus) + ")")}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.backoffice.objectStatus}}):_vm._e()],1):_vm._e(),(_vm.sizeClassItem)?_c('li',[(_vm.sizeClassItem)?_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-size-class-label'),"valueText":_vm.sizeClassItem.range.length === 1
                            ? _vm.$t('size-class-text-start', {
                                  value: _vm.backoffice.sizeClass,
                                  range1: _vm.sizeClassItem.range[0]
                              })
                            : (
                                _vm.sizeClassItem.range[0] === 0
                                ? _vm.$t('size-class-text-upto', {
                                      value: _vm.backoffice.sizeClass,
                                      range2: _vm.sizeClassItem.range[1]
                                  })
                                : _vm.$t('size-class-text-range', {
                                      value: _vm.backoffice.sizeClass,
                                      range1: _vm.sizeClassItem.range[0],
                                      range2: _vm.sizeClassItem.range[1]
                                  })
                              )}}):_vm._e(),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.backoffice.sizeClass}}):_vm._e()],1):_vm._e(),(_vm.backoffice.objectCode)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-object-code-label'),"valueText":_vm.objectCodeTranslatedItem}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.objectCodeTranslatedItem}}):_vm._e()],1):_vm._e(),(_vm.backoffice.objectCategory)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-object-category-label'),"valueText":_vm.$translateKebablizerLower(
                            ("task-object-category-" + (_vm.backoffice.objectCategory))
                        )}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm._f("kebabizeLower")(("task-object-category-" + (_vm.backoffice.objectCategory)))}}):_vm._e()],1):_vm._e(),(_vm.backoffice.regionCode)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-region-code-label'),"valueText":_vm.backoffice.regionCode}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.backoffice.regionCode}}):_vm._e()],1):_vm._e(),(_vm.backoffice.subCircleCode)?_c('li',[_c('app-key-value-text-inline',{attrs:{"keyText":_vm.$t('task-sub-circle-code-label'),"valueText":_vm.backoffice.subCircleCode}}),(_vm.showCopyBtn)?_c('CopyToClipboard',{attrs:{"value":_vm.backoffice.subCircleCode}}):_vm._e()],1):_vm._e()])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }