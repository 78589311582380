import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

//set Default Language
const DEFAULT_LANG = "de";

const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    //NOTE: this disable all translate warnings, remove it or set to false
    silentTranslationWarn: true,
    //this set a fallback language if translations was not found
    fallbackLocale: 'de',
});

export default i18n;
