<i18n src="./faq-btn-share-i18n.yaml"></i18n>
<template>
    <span>
        <BtnShare
            v-if="isMobile"
            :title="getTitleForShareFromGotcha(gotcha)"
            :text="getTextForShareFromGotcha(gotcha)"
            :url="getUrlForShareFromGotcha(gotcha)"
            :btnProps="{
                small: true
            }"
        >
        </BtnShare>
        <CopyToClipboard
            v-else
            icon="mdi-share-variant"
            :value="copyUrlShareGotcha"
            :msgSuccess="
                $t('btn-copy-to-clipboard-msg-success', { gotcha: gotcha.q })
            "
        />
    </span>
</template>
<script>
import BtnShare from "@/components/general/btn-share.vue";
import CopyToClipboard from "@/components/general/utlis/copy-to-clipboard.vue";
export default {
    name: "FaqBtnShare",
    components: {
        BtnShare,
        CopyToClipboard
    },
    props: {
        gotcha: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        copyUrlShareGotcha() {
            return this.getUrlForShareFromGotcha(this.gotcha);
        }
    },
    methods: {
        getTextForShareFromGotcha(gotcha) {
            const gotchaKey = gotcha?.q;
            if (!gotchaKey) return "";
            return "";
        },
        getFaqRoutePath() {
            const resolvedRoute = this.$router.resolve({
                name: "faq"
            });
            return resolvedRoute.route.path;
        },
        getUrlForShareFromGotcha(gotcha) {
            const faqRoutePath = this.getFaqRoutePath();
            const gotchaSearchIdx = gotcha?.s;
            const domain = document.location.origin;
            if (!gotchaSearchIdx || !faqRoutePath || !domain) return;
            return `${domain}${faqRoutePath}?searchIndex=${gotchaSearchIdx}`;
        },
        getTitleForShareFromGotcha(gotcha) {
            const gotchaKey = gotcha?.q;
            if (!gotchaKey) return "";
            return gotchaKey;
        },
        getUrlForShareUrlGotcha(gotcha) {
            if (!gotcha?.s) return;
            const hostname = window.location.origin;
            const path = this.$route.path;
            return `${hostname}${path}?searchIndex=${gotcha.s}`;
        }
    }
};
</script>
