import store from "@/store";
import { debounce } from "lodash";
import ProjectData from "@/data/project.json";
import _ from "lodash";
const state = {
    title01: null,
    title02: null,
    registrationTimestamp: null,
    registrationTimestampStatus: null,
    developerMode: null,
    registrationOffice: {
        key: "SVTI",
        language: "de",
        availableLanguages: ["de", "fr", "it"]
    },
    showLoader: false,
    loaderText: null,
    registrationOfficeData: {}
};

const mutations = {
    START_LOADER(state, loaderText) {
        if (loaderText) {
            state.loaderText = loaderText;
        }
        state.showLoader = true;
    },
    FINISH_LOADER(state) {
        state.showLoader = false;
    },
    clearLoaderText() {
        state.loaderText = null;
    },
    setRegistrationOffice(state, value) {
        const registrationOfficesData = ProjectData.registrationOffices || [];
        if (registrationOfficesData.length === 0) return;
        const possbileValues = _.map(registrationOfficesData, "key");
        if (possbileValues.includes(value)) {
            state.registrationOffice.key = value;
        }
    },
    setRegistrationOfficeLanguage(state, language) {
        state.registrationOffice.language = language;
    },
    fetchDeveloperModeFromLocalStorage(state) {
        // Check if the ID exists and parse to boolean
        var developerMode = localStorage.getItem("developerMode") == "true";
        if (developerMode) {
            state.developerMode = localStorage.getItem("developerMode");
        } else {
            state.developerMode = null;
        }
    },
    setDeveloperMode(state, developerMode) {
        localStorage.setItem("developerMode", developerMode);
        state.developerMode = developerMode;
    },
    setTitles(state, data) {
        if (data.title01) {
            state.title01 = data.title01;
        }
        if (data.title02) {
            state.title02 = data.title02;
        }
        if (data.registrationTimestamp) {
            state.registrationTimestamp = data.registrationTimestamp;
        }
        if (data.registrationTimestampStatus) {
            state.registrationTimestampStatus =
                data.registrationTimestampStatus;
        }
    },
    setRegistrationTimestamp(state, timestamp) {
        if (timestamp) {
            state.registrationTimestamp = timestamp;
        }
    },
    registrationTimestampStatus(state, timestampStatus) {
        if (timestampStatus) {
            state.registrationTimestampStatus = timestampStatus;
        }
    },
    setDefaultTitles(state) {
        state.title01 = "default-title";
        state.title02 = "default-subtitle";
        state.registrationTimestamp = {};
        state.registrationTimestampStatus = null;
    },
    clearTitles(state) {
        state.title01 = "default-title";
        state.title02 = "default-subtitle";
    },
    clearRegistrationTimestamp(state) {
        state.registrationTimestamp = null;
    },
    clearRegistrationTimestampStatus(state) {
        state.registrationTimestampStatus = null;
    },
    setRegistrationOfficeData(state, payload) {
        if (payload) {
            state.registrationOfficeData = payload;
        }
    }
};

const getters = {
    developerMode(state) {
        let isUserDevolper = store.getters["auth/isUserDevolper"];
        return isUserDevolper && state.developerMode;
    },
    title01(state) {
        return state.title01;
    },
    title02(state) {
        return state.title02;
    },
    registrationTimestamp(state) {
        return state.registrationTimestamp;
    },
    registrationTimestampStatus(state) {
        return state.registrationTimestampStatus;
    },
    getRegistrationOffice(state) {
        return state.registrationOffice?.key;
    },
    getRegistrationOfficeLogoPath(state) {
        return state.registrationOfficeData.logoPath || "assets/svti-logo.svg";
    },
    supportMail(state) {
        return state.registrationOfficeData.supportMail || null;
    },
    getRegistrationOfficeLanguage(state) {
        return state.registrationOffice?.language;
    },
    getRegistrationOfficeAvailableLanguages(state) {
        return state.registrationOffice?.availableLanguages || [];
    }
};

const actions = {
    SET_FINISH_LOADER: debounce(({ commit }) => {
        commit("FINISH_LOADER");
        commit("clearLoaderText");
    }, 1000)
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
