<i18n src="./calendar-chart-logs-i18n.yaml"></i18n>
<template>
    <ChartData
        :data="formattedData"
        :loading="isLoading"
        :options="computeChartOption"
        :subtitle="computedSubtitle"
        :chart-color="chartColor"
        :total-counter="totalCounter"
        :title-word="$t('title-word')"
        @fetch="onFetchData"
        @click:reload="onClickReload"
    />
</template>
<script>
import MaterialColors from "vuetify/es5/util/colors";
import _ from "lodash";
import Utils from "@/utils";
export default {
    name: "CalendarChartLogs",
    components: {
        ChartData: () =>
            import("@/components/widgets/components/chart-data.vue")
    },
    data: () => ({
        chartData: [],
        params: {
            startDate: null,
            endDate: null,
            groupBy: null
        },
        isLoading: true,
        chartColor: [
            MaterialColors.yellow.base,
            MaterialColors.orange.base,
            MaterialColors.red.base
        ]
    }),
    computed: {
        computedSubtitle() {
            if (this.subtitle) return this.subtitle;
            return this.$t("subtitle-counter-chart", {
                startDate: this.formatDate(this.params?.startDate),
                endDate: this.formatDate(this.params?.endDate)
            });
        },
        formattedData() {
            let logsMonthData = [];
            _.forEach(this.chartData, (item) => {
                let obj = {
                    month: this.getLabelTypeByGroupByAndKey(
                        this.params?.groupBy,
                        item.key
                    )
                };
                delete item.key;
                _.forEach(item, (logValue, logLevel) => {
                    obj[this.$t(`error-level-${Number(logLevel)}`)] = logValue;
                });
                logsMonthData.push(obj);
            });
            return logsMonthData;
        },
        totalCounter() {
            let count = 0;
            _.forEach(this.chartData, (logRow) => {
                _.forEach(logRow, (logCounter) => {
                    count += logCounter;
                });
            });
            return count;
        },
        computeChartOption() {
            let options = [];
            for (let index = 0; index < 3; index++) {
                options.push([`series[${index}].type`, "bar"]);
                options.push([`series[${index}].areaStyle`, {}]);
                options.push([`series[${index}].smooth`, true]);
            }
            return options;
        },
        cardActions() {
            return [
                {
                    id: "showLogs",
                    emit: "click:show-logs",
                    icon: "mdi-eye",
                    title: this.$t("action-show-logs")
                }
            ];
        }
    },
    methods: {
        formatDate: Utils.formatDate,
        fetchChartData(startDate, endDate, groupBy) {
            this.isLoading = true;
            this.params = Object.assign(this.params, {
                startDate,
                endDate,
                groupBy
            });

            let params = {
                startDate: startDate,
                endDate: endDate,
                groupBy: groupBy
            };
            this.$axiosLogsApi
                .get("/chartData/", { params })
                .then((response) => {
                    let resData = response.data;
                    this.chartData = [...resData.data];
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error("error");
                    this.isLoading = false;
                });
        },
        getLabelTypeByGroupByAndKey(groupBy, key) {
            switch (groupBy) {
                case "day":
                    return this.formatDate(key);
                case "month":
                    return this.getLabelByMonthKey(key);
                case "year":
                    return key;
                default:
                    break;
            }
            let month = this.$moment(key).month();
            let year = this.$moment(key).year();
            let label = this.$t(`month-${month + 1}`);
            return `${label} ${year}`;
        },
        getLabelByMonthKey(key) {
            let month = this.$moment(key).month();
            let year = this.$moment(key).year();
            let label = this.$t(`month-${month + 1}`);
            return `${label} ${year}`;
        },
        onClickReload() {
            this.fetchChartData(
                this.params.startDate,
                this.params.endDate,
                this.params.groupBy
            );
        },
        onFetchData(params) {
            this.fetchChartData(
                params.startDate,
                params.endDate,
                params.groupBy
            );
        }
    }
};
</script>
