<template>
    <div
        class="subheading center font-weight-medium font-weight-medium mb-3"
        :class="computedCls"
        v-bind="$attrs"
        v-on="$listeners"
        style="word-break: break-word"
    >
        <slot />
    </div>
</template>
<script >
export default {
    name: "BaseSubheader",

    props: {
        color: {
            type: String,
            default: "regOfficeSubheader"
        }
    },

    computed: {
        computedCls() {
            let cls = this.$vuetify.breakpoint.smAndDown
                ? "text-h6"
                : "text-h5";
            let color = `${this.color}--text`;
            cls += " " + color;
            return cls;
        }
    }
};
</script>
