import store from '@/store';
export default {
    methods: {
        // Global Method
        loader() {
            return {
                show(loaderText = null) {
                    store.commit("settings/START_LOADER", loaderText);
                },
                hide() {
                    store.dispatch("settings/SET_FINISH_LOADER");
                }
            };
        },
        isRequiredField(vModel) {
            if (!vModel) return false;
            if (!("required" in vModel.$params)) return false;
            return !vModel.required;
        },
        isModelValid(vModel) {
            return !vModel.$invalid;
        },
        $translateKebablizerLower(value){
            return this.$t(this.$options.filters.kebabizeLower(value))
        }
    }
};
