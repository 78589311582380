<i18n src="./form-config-i18n.yaml"></i18n>
<template>
    <v-form :disabled="disabled" :readonly="readonly">
        <v-row dense>
            <v-col cols="12">
                <base-subheader>{{ $t("subtitle-reminders") }}</base-subheader>
                <v-divider />
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <WNumericField
                    v-model="form.boDefaultLockDays"
                    :minValue="
                        $v.form.boDefaultLockDays.$params.minimumValue.min
                    "
                    :label="$t('bo-default-lock-days-label')"
                    :error-messages="boDefaultLockDaysErrors"
                    :required="isRequiredField($v.form.boDefaultLockDays)"
                    :suffix="
                        $tc('label-days', Number(form.boDefaultLockDays) || 2)
                    "
                >
                </WNumericField>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <WNumericField
                    v-model="form.boDefaultReminderAssignBoDays"
                    :minValue="
                        $v.form.boDefaultReminderAssignBoDays.$params
                            .minimumValue.min
                    "
                    :label="$t('bo-default-reminder-assign-bo-days-label')"
                    :error-messages="boDefaultReminderAssignBoDaysErrors"
                    :required="
                        isRequiredField($v.form.boDefaultReminderAssignBoDays)
                    "
                    :suffix="
                        $tc(
                            'label-days',
                            Number(form.boDefaultReminderAssignBoDays) || 2
                        )
                    "
                >
                </WNumericField>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <WNumericField
                    v-model="form.boDefaultReminderAssignRegistrantDays"
                    :minValue="
                        $v.form.boDefaultReminderAssignRegistrantDays.$params
                            .minimumValue.min
                    "
                    :label="
                        $t('bo-default-reminder-assign-registrant-days-label')
                    "
                    :error-messages="
                        boDefaultReminderAssignRegistrantDaysErrors
                    "
                    :required="
                        isRequiredField(
                            $v.form.boDefaultReminderAssignRegistrantDays
                        )
                    "
                    :suffix="
                        $tc(
                            'label-days',
                            Number(
                                form.boDefaultReminderAssignRegistrantDays
                            ) || 2
                        )
                    "
                >
                </WNumericField>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <WNumericField
                    v-model="form.boDefaultReminderDays"
                    :minValue="
                        $v.form.boDefaultReminderDays.$params.minimumValue.min
                    "
                    :label="$t('bo-default-reminder-days-label')"
                    :error-messages="boDefaultReminderDaysErrors"
                    :required="isRequiredField($v.form.boDefaultReminderDays)"
                    :suffix="
                        $tc(
                            'label-days',
                            Number(form.boDefaultReminderDays) || 2
                        )
                    "
                >
                </WNumericField>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <base-subheader>{{ $t("subtitle-mails") }}</base-subheader>
                <v-divider />
            </v-col>
            <v-col cols="12" md="6">
                <base-field-label>{{
                    $t("label-text-email-bo-report-new-address")
                }}</base-field-label>
                <app-text-not-recorded
                    v-if="
                        form.mailsBoReportNewAddress &&
                        form.mailsBoReportNewAddress.length == 0 &&
                        (disabled || readonly)
                    "
                />
                <WMultipleTextField
                    v-else
                    v-model="form.mailsBoReportNewAddress"
                    :label="$t('label-email-bo-report-new-address')"
                    :v="$v.form.mailsBoReportNewAddress"
                    :disabled="disabled"
                    :readonly="readonly"
                    :required="isRequiredField($v.form.mailsBoReportNewAddress)"
                    :errorMessagesByField="getMailErrorMessageByField"
                />
            </v-col>
            <v-col cols="12" md="6">
                <base-field-label>{{
                    $t("label-text-email-bo-report-new-address-reply-to")
                }}</base-field-label>
                <app-text-not-recorded
                    v-if="
                        form.mailsBoReportNewAddressReplyTo &&
                        form.mailsBoReportNewAddressReplyTo.length == 0 &&
                        (disabled || readonly)
                    "
                />
                <WMultipleTextField
                    v-else
                    v-model="form.mailsBoReportNewAddressReplyTo"
                    :label="$t('label-email-bo-report-new-address-reply-to')"
                    :v="$v.form.mailsBoReportNewAddressReplyTo"
                    :disabled="disabled"
                    :readonly="readonly"
                    :required="
                        isRequiredField($v.form.mailsBoReportNewAddressReplyTo)
                    "
                    :errorMessagesByField="getMailErrorMessageByField"
                />
            </v-col>
            <v-col cols="12">
                <base-field-label>{{
                    $t("label-text-email-insurer")
                }}</base-field-label>
                <app-text-not-recorded
                    v-if="
                        form.mailsInsurer &&
                        form.mailsInsurer.length == 0 &&
                        (disabled || readonly)
                    "
                />
                <WMultipleTextField
                    v-else
                    v-model="form.mailsInsurer"
                    :label="$t('label-email-insurer')"
                    :v="$v.form.mailsInsurer"
                    :disabled="disabled"
                    :readonly="readonly"
                    :required="isRequiredField($v.form.mailsInsurer)"
                    :errorMessagesByField="getMailErrorMessageByField"
                />
            </v-col>
            <v-col cols="12">
                <base-field-label>{{
                    $t("label-text-email-bo-support")
                }}</base-field-label>
                <app-text-not-recorded
                    v-if="
                        form.mailsBoSupport &&
                        form.mailsBoSupport.length == 0 &&
                        (disabled || readonly)
                    "
                    :errorMessagesByField="getMailErrorMessageByField"
                />
                <WMultipleTextField
                    v-else
                    v-model="form.mailsBoSupport"
                    :label="$t('label-email-bo-support')"
                    :v="$v.form.mailsBoSupport"
                    :disabled="disabled"
                    :readonly="readonly"
                    :required="isRequiredField($v.form.mailsBoSupport)"
                    :errorMessagesByField="getMailErrorMessageByField"
                />
            </v-col>
            <v-col cols="12">
                <base-field-label>{{
                    $t("label-text-email-bo-pool")
                }}</base-field-label>
                <app-text-not-recorded
                    v-if="
                        form.mailsBoPool &&
                        form.mailsBoPool.length == 0 &&
                        (disabled || readonly)
                    "
                />
                <WMultipleTextField
                    v-else
                    v-model="form.mailsBoPool"
                    :label="$t('label-email-bo-pool')"
                    :v="$v.form.mailsBoPool"
                    :disabled="disabled"
                    :readonly="readonly"
                    :required="isRequiredField($v.form.mailsBoPool)"
                    :errorMessagesByField="getMailErrorMessageByField"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import _ from "lodash";
import validateMixin from "@/mixins/validate";
import { required, minLength, minValue, email } from "vuelidate/lib/validators";
export default {
    name: "FormConfig",
    mixins: [validateMixin],
    components: {
        WMultipleTextField: () =>
            import("@/components/fields/WMultipleTextField.vue")
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        formModel: {
            boDefaultLockDays: null,
            boDefaultReminderAssignBoDays: null,
            boDefaultReminderAssignRegistrantDays: null,
            boDefaultReminderDays: null,
            developerModeCode: null,
            mailsBoReportNewAddress: [],
            mailsBoReportNewAddressReplyTo: [],
            mailsInsurer: [],
            mailsBoSupport: [],
            mailsBoPool: []
        },
        form: {
            boDefaultLockDays: null,
            boDefaultReminderAssignBoDays: null,
            boDefaultReminderAssignRegistrantDays: null,
            boDefaultReminderDays: null,
            developerModeCode: null,
            mailsBoReportNewAddress: [],
            mailsBoReportNewAddressReplyTo: [],
            mailsInsurer: [],
            mailsBoSupport: [],
            mailsBoPool: []
        }
    }),
    validations() {
        return {
            form: {
                boDefaultLockDays: {
                    required,
                    minimumValue: minValue(0),
                    minValue: minValue(1)
                },
                boDefaultReminderAssignBoDays: {
                    required,
                    minimumValue: minValue(0),
                    minValue: minValue(1)
                },
                boDefaultReminderAssignRegistrantDays: {
                    required,
                    minimumValue: minValue(0),
                    minValue: minValue(1)
                },
                boDefaultReminderDays: {
                    required,
                    minimumValue: minValue(0),
                    minValue: minValue(1)
                },
                mailsBoReportNewAddress: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        required,
                        email,
                        minLength: minLength(1)
                    }
                },
                mailsBoReportNewAddressReplyTo: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        required,
                        email,
                        minLength: minLength(1)
                    }
                },
                mailsInsurer: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        required,
                        email,
                        minLength: minLength(1)
                    }
                },
                mailsBoSupport: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        required,
                        email,
                        minLength: minLength(1)
                    }
                },
                mailsBoPool: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        required,
                        email,
                        minLength: minLength(1)
                    }
                }
            }
        };
    },
    computed: {
        boDefaultLockDaysErrors() {
            const errors = [];
            if (!this.$v.form.boDefaultLockDays.$dirty) {
                return errors;
            }
            if (!this.$v.form.boDefaultLockDays.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.form.boDefaultLockDays.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.form.boDefaultLockDays.$params.minValue.min
                    })
                );
            }
            return errors;
        },
        boDefaultReminderAssignBoDaysErrors() {
            const errors = [];
            if (!this.$v.form.boDefaultReminderAssignBoDays.$dirty) {
                return errors;
            }
            if (!this.$v.form.boDefaultReminderAssignBoDays.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.form.boDefaultReminderAssignBoDays.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.form.boDefaultReminderAssignBoDays.$params
                                .minValue.min
                    })
                );
            }
            return errors;
        },
        boDefaultReminderAssignRegistrantDaysErrors() {
            const errors = [];
            if (!this.$v.form.boDefaultReminderAssignRegistrantDays.$dirty) {
                return errors;
            }
            if (!this.$v.form.boDefaultReminderAssignRegistrantDays.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.form.boDefaultReminderAssignRegistrantDays.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.form.boDefaultReminderAssignRegistrantDays
                                .$params.minValue.min
                    })
                );
            }
            return errors;
        },
        boDefaultReminderDaysErrors() {
            const errors = [];
            if (!this.$v.form.boDefaultReminderDays.$dirty) {
                return errors;
            }
            if (!this.$v.form.boDefaultReminderDays.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.form.boDefaultReminderDays.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.form.boDefaultReminderDays.$params.minValue
                                .min
                    })
                );
            }
            return errors;
        }
    },
    methods: {
        // this method is used for all the WMultipleTextField
        // because this fiels had all the same validation
        // if validation should be changed for 1 field
        // a method like this need to be implemente for this field
        getMailErrorMessageByField(field) {
            const errors = [];
            if (!field.$dirty) {
                return errors;
            }
            if (!field.required) {
                errors.push(this.$t("field-required"));
            }
            if (!field.minLength) {
                errors.push(
                    this.$t("field-min-length", {
                        minLength: field.$params.minLength.min
                    })
                );
            }
            if (!field.email) {
                errors.push(this.$t("field-invalid-email"));
            }
            return errors;
        }
    },
    watch: {
        data: {
            handler: function (value) {
                if (value) {
                    this.form = _.cloneDeep(value);
                }
            },
            immediate: true
        },
        form: {
            handler: function (value) {
                this.$emit("change", value);
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
