<i18n src="./content-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <v-container fluid>
        <v-container fill-height fluid v-if="!isAuth">
            <v-row align="center" justify="center">
                <v-col md="8">
                    <base-header class="font-weight-bold">
                        {{ $t("default-title") }}
                    </base-header>
                    <base-subheader>
                        {{ $t("default-subtitle") }}
                    </base-subheader>
                </v-col>
            </v-row>
        </v-container>

        <v-container fill-height fluid v-if="!isAuth" pt-0>
            <v-row align="center" justify="center">
                <v-col md="10">
                    <MainFactsList />
                </v-col>
            </v-row>
        </v-container>

        <v-container
            fill-height
            fluid
            :class="$vuetify.breakpoint.smAndDown ? 'px-0 mx-0' : ''"
            v-if="!isAuth"
        >
            <v-row no-gutters align="center" justify="center">
                <v-col md="8" sm="12">
                    <base-title class="pl-4">
                        {{ $t("title-login") }}
                    </base-title>
                    <Login />
                </v-col>
            </v-row>
        </v-container>

        <v-container fill-height fluid v-if="!isAuth" class="py-0 my-0 pt-10">
            <v-row align="center" justify="center">
                <v-col md="8">
                    <ExpansionPanelInfo />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Login from "@/components/auth/login.vue";
import ExpansionPanelInfo from "@/components/home/expansion-panel-info.vue";
import MainFactsList from "./main-facts-list.vue";
export default {
    name: "HomeContent",
    components: {
        Login,
        ExpansionPanelInfo,
        MainFactsList
    },

    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        })
    }
};
</script>
