<template>
    <div>
        <v-menu v-if="type === 'menu'" transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on }">
                <v-btn plain dark v-bind="$attrs" v-on="on">
                    <span
                        class="primary--text font-weight-black text-subtitle-1"
                        >{{ selectedLang | uppercase }}</span
                    >
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(language, l) in languages"
                    :key="l"
                    @click="changeLanguage(language)"
                >
                    <v-list-item-title
                        class="font-weight-black text-subtitle-1"
                        :class="
                            selectedLang === language
                                ? 'regOfficePrimary--text'
                                : 'primary--text'
                        "
                        >{{ language | uppercase }}</v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-menu>
        <languages-list
            v-if="type === 'list'"
            :languages="languages"
            :selectedLang="selectedLang"
            @change="changeLanguage($event)"
        />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import LanguagesList from "@/components/general/lists/languages-list.vue";
export default {
    name: "WLanguageMenu",
    components: {
        LanguagesList
    },
    props: {
        type: {
            type: String,
            default: "menu"
        }
    },
    data: () => ({
        selectedLang: null
    }),
    methods: {
        changeLanguage(lang) {
            if (this.isAuth) {
                this.loader().show();
                let data = {};
                data.lang = lang;
                this.$store
                    .dispatch("auth/setAccountSettings", data)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            this.$store.commit("auth/setUserData", { lang });
                            this.computedLang = lang;
                        }
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader().hide();
                    });
            } else {
                this.computedLang = lang;
            }
        },
        setLocaleLang(lang) {
            if (!this.languages.includes(lang)) {
                lang = "DE";
            }
            this.$root.$i18n.locale = lang.toLowerCase();
            this.$root.$vuetify.lang.current = lang.toLowerCase();
            switch (lang.toLowerCase()) {
                case "de":
                    this.$moment.locale("de-ch");
                    break;
                case "fr":
                    this.$moment.locale("fr-ch");
                    break;
                case "it":
                    this.$moment.locale("it");
                    break;
                default:
                    this.$moment.locale("de-ch");
            }
            this.selectedLang = lang;
        }
    },
    computed: {
        ...mapGetters("auth", ["userLang"]),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        ...mapGetters("settings", {
            registrationOfficeLanguage: "getRegistrationOfficeLanguage",
            registrationOfficeAvailableLanguages:
                "getRegistrationOfficeAvailableLanguages"
        }),
        languages() {
            return this.registrationOfficeAvailableLanguages || [];
        },
        computedLang: {
            //getter
            get: function () {
                let lang = this.userLang;
                if (!lang) {
                    lang = navigator.language || navigator.userLanguage;
                }
                if (!this.languages.includes(lang) || !lang) {
                    lang = "DE";
                }
                this.setLocaleLang(lang);
                return lang;
            },
            // setter
            set: function (lang) {
                if (!lang) {
                    return;
                }
                this.setLocaleLang(lang);
            }
        }
    },
    watch: {
        userLang: {
            handler: function (lang) {
                if (lang) {
                    this.computedLang = lang;
                }
            },
            immediate: true
        },
        registrationOfficeLanguage: {
            handler: function (lang) {
                if (lang) {
                    this.computedLang = lang;
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (!this.isAuth && this.registrationOfficeLanguage) {
            return (this.computedLang = this.registrationOfficeLanguage);
        }
        if (!this.computedLang && this.registrationOfficeLanguage) {
            this.computedLang = this.registrationOfficeLanguage;
        } else {
            this.selectedLang = this.computedLang;
        }
    }
};
</script>
