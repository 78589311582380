var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":"","ma-0":""},on:{"click":function($event){return _vm.$emit('click:container')}}},[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" @overheatingVulnerable: "),_c('tree-view',{attrs:{"data":_vm.registration.overheatingVulnerable,"options":{ maxDepth: 0 }}})]},proxy:true}])}),(_vm.activelyHeated)?_c('div',[_c('base-alert',{attrs:{"width":"100%","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("actively-heated-msg"))+" ")])],1):_c('div',[_c('v-form',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus}},[_c('v-row',{staticClass:"px-4",attrs:{"dense":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.inspectionType === _vm.INSPECTION_TYPES.SVTI
                                ? _vm.$t('inspection-type-svti-label')
                                : _vm.$t('inspection-type-specialist-label'),"error-messages":_vm.inspectionTypeErrors,"append-icon":"mdi-information-outline","color":"primary","true-value":_vm.INSPECTION_TYPES.SVTI,"false-value":_vm.INSPECTION_TYPES.SPECIALIST},on:{"click:append":function($event){return _vm.showFaq('inspectionType')},"input":_vm.onInspectTypeChange},model:{value:(_vm.inspectionType),callback:function ($$v) {_vm.inspectionType=$$v},expression:"inspectionType"}})],1)],1),(_vm.inspectionType === _vm.INSPECTION_TYPES.SPECIALIST)?_c('v-container',{attrs:{"fluid":"","py-0":"","my-0":""}},[_c('base-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("inspection-type-specialist-text"))+" ")]),_c('base-autocomplete',{attrs:{"items":_vm.sortedSpecialistsCompanies,"item-value":"name","search-input":_vm.search,"label":_vm.$t('specialist-company-label'),"placeholder":_vm.$t('specialist-company-placeholder'),"open-on-clear":"","append":"mdi-information-outline","filter":_vm.filter,"error-messages":_vm.specialistCompanyErrors,"menu-props":{
                        closeOnContentClick: true,
                        closeOnClick: false
                    },"return-object":"","required":_vm.isRequiredField(_vm.$v.specialistCompany),"item-text":function (item) { return item.name; }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.specialistCompany),callback:function ($$v) {_vm.specialistCompany=$$v},expression:"specialistCompany"}})],1):_vm._e(),(
                    _vm.inspectionType &&
                    _vm.inspectionType != _vm.INSPECTION_TYPES.SVTI &&
                    _vm.specialistCompany
                )?_c('v-container',{attrs:{"fluid":"","py-0":"","my-0":""}},[(!_vm.readonlyModus)?[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"width":"100%","type":"info","dense":""},domProps:{"innerHTML":_vm._s(_vm.$t('upload-msg'))}})],1),_c('v-col',[(!_vm.readonlyModus)?_c('BtnUploadFiles',{attrs:{"disabled":_vm.readonlyModus,"color":"info","categories":_vm.attachmentCategories},on:{"click-item":_vm.onCategoryItemClick}}):_vm._e()],1)],1)]:_vm._e(),(_vm.computedAttachments)?_c('multimedia-viewer',{attrs:{"deletable":!_vm.readonlyModus,"attachments":_vm.computedAttachments,"thumbnailWidth":"100px","thumbnailMaxWidth":"100px","thumbnailHeight":"100px","readonlyModus":_vm.readonlyModus,"docId":_vm.registration.docId},on:{"attachment-deleted":_vm.deleteAttachment,"delete-multiple-attachments":_vm.deleteMultipleAttachment}}):_vm._e(),(_vm.fileCategory)?_c('modal-file-uploader',{attrs:{"value":_vm.modalFileUploader,"docId":_vm.registration.docId,"fileCategory":_vm.fileCategory,"maxFileSize":_vm.maxFileSize},on:{"input":function($event){_vm.modalFileUploader = $event}}}):_vm._e()],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }