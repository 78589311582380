var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-dialog',{attrs:{"value":_vm.dialog,"title":_vm.$t('title'),"max-width":"600","icon":"mdi-information-outline"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.$t('object-type-caption'))}}),_c('base-select',{attrs:{"items":_vm.objectTypeItems,"error-messages":_vm.objectTypeErrors,"label":_vm.$t('object-type-label'),"placeholder":_vm.$t('object-type-placeholder'),"required":_vm.isRequiredField(_vm.$v.objectType),"dense":"","clearable":"","item-text":function (item) { return _vm.$t(("task-object-type-" + (item.value))); }},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"text-body-1 pl-3",domProps:{"innerHTML":_vm._s(_vm.$t('object-type-label'))}}),_c('v-divider')]},proxy:true}]),model:{value:(_vm.objectType),callback:function ($$v) {_vm.objectType=$$v},expression:"objectType"}})],1),(
                            _vm.objectType &&
                            _vm.objectType !== _vm.OBJECT_TYPES.PRESSURE_EQUIPMENT
                        )?_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"type":"info","dense":""}},[_vm._v(_vm._s(_vm.$t("download-pdf-msg"))+" ")]),_c('app-btn-split',{attrs:{"color":"regOfficeBtnPrimary","items":_vm.downloadPdfBtnItems},on:{"click":function($event){return _vm.onClickDownloadPdfBtnActionClick(
                                    _vm.downloadPdfActions.DOWNLOAD_PDF_DE
                                )},"list:click":function (item) { return _vm.onClickDownloadPdfBtnActionClick(
                                        item.actionId
                                    ); }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(_vm._s(_vm.$t("btn-download-pdf-de")))],1)],1):_vm._e()],1)],1)]},proxy:true},(
                _vm.objectType && _vm.objectType === _vm.OBJECT_TYPES.PRESSURE_EQUIPMENT
            )?{key:"actions",fn:function(){return [_c('v-spacer'),_c('base-btn',{attrs:{"type":"primary","btnIcon":"'mdi-arrow-right'"},on:{"click":_vm.createTask}},[_vm._v(" "+_vm._s(_vm.$t("btn-continue"))+" ")])]},proxy:true}:null],null,true)}),_c('DialogCreateTask',{ref:"formCreate",attrs:{"value":_vm.dialogCreateTask},on:{"input":function($event){_vm.dialogCreateTask = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }