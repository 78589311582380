<i18n src="./registration-icon-i18n.yaml"></i18n>
<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<template>
    <div>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <w-icon
                    v-on="on"
                    :data="itemIcon"
                    :color="itemIconColor"
                    width="35"
                    height="35"
                ></w-icon>
            </template>
            <span v-if="deletedState > 0">
                <span v-if="deletedText">{{ deletedText }} </span>
                <span v-else>
                    {{
                        $translateKebablizerLower(
                            `task-process-type-${process}`
                        )
                    }}: {{ $t(`task-status-${state}`) }} ({{
                        $t("tooltip-deleted")
                    }})
                </span>
            </span>
            <span v-else>
                <span v-if="stateText">{{ stateText }} </span>
                <span v-else>
                    {{
                        $translateKebablizerLower(
                            `task-process-type-${process}`
                        )
                    }}: {{ $t(`task-status-${state}`) }}</span
                >
            </span>
        </v-tooltip>
    </div>
</template>
<script>
import colors from "vuetify/es5/util/colors";
import { getRegistrationStateColor } from "@/utils/defaults.js";
import { OBJECT_TYPES, TaskStatus, PROCESS_TYPES } from "@/data/globals.js";
export default {
    name: "RegistrationIcon",
    props: {
        objectType: {
            type: String,
            default: null,
            required: true
        },
        state: {
            type: Number,
            default: null,
            required: true
        },
        deletedState: {
            type: Number,
            default: null,
            required: true
        },
        process: {
            type: String,
            default: null
        },
        deletedText: {
            type: String,
            default: null
        },
        stateText: {
            type: String,
            default: null
        }
    },
    data: () => ({
        colors: colors,
        OBJECT_TYPES: OBJECT_TYPES,
        PROCESS_TYPES: PROCESS_TYPES,
        TaskStatus: TaskStatus,
        iconTypes: {
            [PROCESS_TYPES.REGISTRATION]: {
                [OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT]: {
                    deleted: "w-registration-bottles-status-deleted.svg",
                    edit: "w-registration-bottles-status-edit.svg",
                    eye: "w-registration-bottles-status-eye.svg",
                    dialog: "w-registration-bottles-status-dialog.svg",
                    check: "w-registration-bottles-status-check.svg",
                    star: "w-registration-bottles-status-star.svg",
                    sync: "w-registration-bottles-status-sync.svg",
                    shield: "w-registration-bottles-status-shield.svg",
                    transferToSystem: "w-task-transfer-to-system.svg",
                    transferToSystemStar: "w-task-transfer-to-system-star.svg",
                    transferToSystemWarning:
                        "w-task-transfer-to-system-warning.svg",
                    transferToSystemCheck: "w-task-transfer-to-system-check.svg"
                },
                [OBJECT_TYPES.PRESSURE_EQUIPMENT]: {
                    deleted: "w-registration-compressor-status-deleted.svg",
                    edit: "w-registration-compressor-status-edit.svg",
                    eye: "w-registration-compressor-status-eye.svg",
                    dialog: "w-registration-compressor-status-dialog.svg",
                    check: "w-registration-compressor-status-check.svg",
                    star: "w-registration-compressor-status-star.svg",
                    sync: "w-registration-compressor-status-sync.svg",
                    shield: "w-registration-compressor-status-shield.svg",
                    transferToSystem: "w-task-transfer-to-system.svg",
                    transferToSystemStar: "w-task-transfer-to-system-star.svg",
                    transferToSystemWarning:
                        "w-task-transfer-to-system-warning.svg",
                    transferToSystemCheck: "w-task-transfer-to-system-check.svg"
                },
                [OBJECT_TYPES.PIPES_STEAM_HOT_WATER]: {
                    deleted: "w-registration-pipes-status-deleted.svg",
                    edit: "w-registration-pipes-status-edit.svg",
                    eye: "w-registration-pipes-status-eye.svg",
                    dialog: "w-registration-pipes-status-dialog.svg",
                    check: "w-registration-pipes-status-check.svg",
                    star: "w-registration-pipes-status-star.svg",
                    sync: "w-registration-pipes-status-sync.svg",
                    shield: "w-registration-pipes-status-shield.svg",
                    transferToSystem: "w-task-transfer-to-system.svg",
                    transferToSystemStar: "w-task-transfer-to-system-star.svg",
                    transferToSystemWarning:
                        "w-task-transfer-to-system-warning.svg",
                    transferToSystemCheck: "w-task-transfer-to-system-check.svg"
                }
            },
            [PROCESS_TYPES.DEREGISTRATION]: {
                [OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT]: {
                    eye: "w-deregistration-bottles-status-eye.svg",
                    check: "w-deregistration-bottles-status-check.svg",
                    star: "w-deregistration-bottles-status-star.svg"
                },
                [OBJECT_TYPES.PRESSURE_EQUIPMENT]: {
                    eye: "w-deregistration-compressor-status-eye.svg",
                    check: "w-deregistration-compressor-status-check.svg",
                    star: "w-deregistration-compressor-status-star.svg"
                },
                [OBJECT_TYPES.PIPES_STEAM_HOT_WATER]: {
                    edit: "w-deregistration-pipes-status-edit.svg",
                    eye: "w-deregistration-pipes-status-eye.svg",
                    check: "w-deregistration-pipes-status-check.svg",
                    star: "w-deregistration-pipes-status-star.svg"
                }
            }
        }
    }),
    computed: {
        itemIcon() {
            if (
                !this.objectType ||
                !this.process ||
                this.state === null ||
                this.deletedState === null
            )
                return;
            let icon = null;
            const iconType = this._getIconTypeByState(
                this.state,
                this.deletedState
            );
            if (this.iconTypes[this.process]) {
                if (this.iconTypes[this.process][this.objectType]) {
                    icon =
                        this.iconTypes[this.process][this.objectType][iconType];
                    if (icon) {
                        return require(`@/assets/icons/${icon}`);
                    }
                }
            }
            //default icon
            return require(`@/assets/icons/configuration.svg`);
        },
        itemIconColor() {
            if (this.state === null || this.deletedState === null) return;
            const stateColor = this.getRegistrationStateColor(this.state);
            if (this.deletedState > 0) {
                return this._getColorByKey("red") + " " + stateColor;
            }
            let color = "grey";
            switch (this.process) {
                case PROCESS_TYPES.REGISTRATION:
                    if (this.state === TaskStatus.TASK_CONFIRMED) {
                        color += " " + "yellow";
                    }
                    if (this.state === TaskStatus.TASK_EXPORT_ERROR) {
                        color += " " + "orange";
                    }
                    if (this.state === TaskStatus.TASK_EXPORTED) {
                        color += " " + "yellow";
                    }
                    color += " " + stateColor;
                    break;
                case PROCESS_TYPES.DEREGISTRATION:
                    color += " red " + stateColor;
                    break;
                default:
                    break;
            }
            return color;
        }
    },
    methods: {
        getRegistrationStateColor: getRegistrationStateColor,
        _getColorByKey(key) {
            if (key) {
                return this.colors[key].base || null;
            }
        },
        _getIconTypeByState(state, deletedState) {
            const editIconStates = [
                TaskStatus.TASK_REGISTRANT_CREATED,
                TaskStatus.TASK_REGISTRANT_IN_PROCESS
            ];
            const boEditIconStates = [
                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS,
                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS_NEW_REPLIES
            ];
            if (deletedState > 0) {
                return "deleted";
            } else if (editIconStates.includes(state)) {
                return "edit";
            } else if (boEditIconStates.includes(state)) {
                return "eye";
            } else if (
                state === TaskStatus.TASK_INSURER_APPROVAL_REQUIRED_POOL
            ) {
                return "shield";
            } else if (
                state === TaskStatus.TASK_VERIFICATION_REGISTRANT_IN_PROCESS
            ) {
                return "dialog";
            } else if (state === TaskStatus.TASK_CONFIRMED) {
                return "transferToSystemStar";
            } else if (state === TaskStatus.TASK_EXPORT_ERROR) {
                return "transferToSystemWarning";
            } else if (state === TaskStatus.TASK_EXPORTED) {
                return "transferToSystemCheck";
            } else if (state > TaskStatus.TASK_COMPLETED) {
                return "check";
            } else {
                return "star";
            }
        }
    }
};
</script>
