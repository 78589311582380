<template>
  <SectionBase
    id="devel-uid-search-api"
    title="UID-Search API"
    subtitle="Test UID-Search API"
  >
    <uid-search
      closeSearchCard
      @confirm-selected-company="uidSearchResult = $event"
      class="pb-6"
    >
    </uid-search>
    <div v-if="uidSearchResult">
      Result:
      <b>{{ uidSearchResult }}</b>
    </div>
  </SectionBase>
</template>

<script>
export default {
  name: "SectionUidSeachApi",
  components: {
    SectionBase: () => import("./base.vue"),
    UidSearch: () =>
      import(
        "@/components/tasks/edit/common/components/uid-search.vue"
      ),
  },
  data: () => ({
    uidSearchResult: null,
  }),
};
</script>
