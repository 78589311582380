<i18n src="./dashboard-view-i18n.yaml"></i18n>
<template>
    <div>
        <v-row>
            <v-col cols="12">
                <base-header>
                    <v-icon>mdi-view-dashboard</v-icon>
                    {{ $t("title") }}
                </base-header>
            </v-col>
        </v-row>
        <PanelGroup :items="items" :isLoading="isLoading" />
    </div>
</template>
<script>
import Helpers from "@/utils/helpers";
import menu from "@/utils/menu.js";
import _ from "lodash";
import { mapState } from "vuex";
export default {
    name: "BackofficeDashboardView",
    components: {
        PanelGroup: () => import("@/components/general/PanelGroup.vue")
    },
    data: () => ({
        menuItems: menu
    }),
    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        registrationsPendingPool() {
            return this.$store.state.backoffice.counters
                .registrationsPendingPool;
        },
        registrationsNewReplies() {
            return this.$store.state.backoffice.counters
                .registrationsNewReplies;
        },
        registrationsOverdueQuestions() {
            return this.$store.state.backoffice.counters
                .registrationsOverdueQuestions;
        },
        registrationsOverdueRegistrations() {
            return this.$store.state.backoffice.counters
                .registrationsOverdueRegistrations;
        },
        registrationsOpenRegistrations() {
            return this.$store.state.backoffice.counters
                .registrationsOpenRegistrations;
        },
        boTasksReadyForSapExport() {
            return this.$store.state.backoffice.counters
                .boTasksReadyForSapExport;
        },
        allTasksReadyForSapExport() {
            return this.$store.state.backoffice.counters
                .allTasksReadyForSapExport;
        },
        tasksTotalCount() {
            return this.$store.state.backoffice.counters.tasksTotalCount;
        },
        myStatsIconColor() {
            return this._baseIconColor;
        },
        allStatsIconColor() {
            return this._baseIconColor;
        },
        _baseIconColor() {
            return this._getColorByKey("grey", "lighten2");
        },
        myStats() {
            const filtered = _.filter(this.menuItems, {
                group: "backofficeListsOwner"
            });
            let items = [];
            _.forEach(filtered[0].items, (item) => {
                let newItem = {
                    color: item.svgColor,
                    svgIcon: item.svgIcon,
                    title: this.$t(item.name),
                    action: {
                        path: { name: item.name }
                    }
                };
                let data;
                switch (item.name) {
                    case "backoffice-tasks-list-newreplies":
                        data = this.registrationsNewReplies;
                        break;
                    case "backoffice-tasks-list-overduequestions":
                        data = this.registrationsOverdueQuestions;
                        break;
                    case "backoffice-tasks-list-bo-ready-for-sap-export":
                        data = this.boTasksReadyForSapExport;
                        break;
                    case "backoffice-tasks-list-opentasks":
                        data = this.registrationsOpenRegistrations;
                        break;
                    default:
                        data = 0;
                        break;
                }
                newItem.data = data;
                items.push(newItem);
            });
            return items;
        },
        allStats() {
            const filtered = _.filter(this.menuItems, {
                group: "backofficeListsAll"
            });
            let items = [];
            _.forEach(filtered[0].items, (item) => {
                let newItem = {
                    color: item.svgColor,
                    svgIcon: item.svgIcon,
                    title: this.$t(item.name),
                    action: {
                        path: { name: item.name }
                    }
                };
                let data;
                switch (item.name) {
                    case "backoffice-tasks-list-pending-pool":
                        data = this.registrationsPendingPool;
                        break;
                    case "backoffice-tasks-list-overduetasks":
                        data = this.registrationsOverdueRegistrations;
                        break;
                    case "backoffice-tasks-list-all-ready-for-sap-export":
                        data = this.allTasksReadyForSapExport;
                        break;
                    case "backoffice-tasks-list-search":
                        data = this.tasksTotalCount;
                        break;
                    default:
                        data = 0;
                        break;
                }
                newItem.data = data;
                items.push(newItem);
            });
            return items;
        },
        items() {
            return [
                {
                    title: this.$t("title-my-tasks"),
                    svgIcon: "w-account.svg",
                    svgIconColor: this.myStatsIconColor,
                    items: this.myStats
                },
                {
                    title: this.$t("title-all-tasks"),
                    svgIcon: "w-account-group.svg",
                    svgIconColor: this.allStatsIconColor,
                    items: this.allStats
                }
            ];
        }
    },
    methods: {
        fetchData() {
            this.loader().show();
            this.$store.dispatch("backoffice/fetchCountersData");
            this.loader().hide();
        },
        _getColorByKey(key, colorization = "base") {
            return Helpers.getColorByKey(key, colorization);
        },
        reload() {
            this.fetchData();
        }
    },
    mounted() {
        this.$store.commit("backoffice/setTitle", this.$t("title"));
    },
    created() {
        this.fetchData();
    }
};
</script>
