import { axiosSpecialistsCompanies } from "@/api/axios-instances";

const state = {
    companies: []
};

const mutations = {
    setSpecialistCompanies(state, companies) {
        state.companies = companies;
    },
    addOrUpdateSpecialistCompany(state, company) {
        const item = state.companies.find(
            (item) => item.docId === company.docId
        );
        if (item) {
            Object.assign(item, company);
        } else {
            state.companies.push(company);
        }
    }
};

const getters = {};

const actions = {
    fetchAllSpecialistCompanies: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
                .get("/")
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        let companies = resData.data;
                        commit("setSpecialistCompanies", companies);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getSpecialistCompany: (_ctx, docId) => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
                .get(`${docId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    addSpecialistCompany: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
            .post("/", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        company.country = company.address.country;
                        if (company.docId){
                            commit("addOrUpdateSpecialistCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    updateSpecialistCompany: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
            .put(`/${data.docId}`, data )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        company.country = company.address.country;
                        if (company.docId){
                            commit("addOrUpdateSpecialistCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deleteSpecialistCompany: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
                .delete(`/${data.docId}`, { data })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data;
                        const company = resData.data;
                        company.country = company.address.country;
                        if (company.docId){
                            commit("addOrUpdateSpecialistCompany", company);
                        }
                    }
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    fetchAllActiveSpecialistCompanies: () => {
        return new Promise((resolve, reject) => {
            axiosSpecialistsCompanies
                .get("/allActive")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
