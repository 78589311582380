<template>
    <v-radio-group
        v-on="$listeners"
        v-bind="computedProps"
        v-disabled-icon-focus
        :required="required"
        :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
    >
        <!-- Dynamically inherit slots from parent -->
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
        </template>
        <!-- make all slots from v-radio-group reusable from parent -->
        <template v-for="(_, slot) in $slots">
            <template :slot="slot">
                <slot :name="slot"></slot>
            </template>
        </template>
    </v-radio-group>
</template>
<script>
import { VRadioGroup } from "vuetify/lib";
import mixinWField from "@/mixins/w-field";
export default {
    name: "BaseRadioGroup",
    mixins: [mixinWField],
    extends: VRadioGroup
};
</script>
