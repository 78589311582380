<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./summary-reason-i18n.yaml"></i18n>
<template>
    <div>
        <BaseListItem
            :title="$t('deregistration-reason-title')"
            :expandable="expandable"
        >
            <template v-slot:content>
                <ul class="text-subtitle-2 primary--text">
                    <li>
                        <app-key-value-text-inline
                            :keyText="$t('deregistration-reason-label')"
                            :valueText="
                                $t(
                                    `task-deregistration-reason-${task.deregistrationReason}`
                                )
                            "
                        />
                    </li>
                    <li>
                        <app-key-value-text-inline
                            :keyText="$t('deregistration-target-date-label')"
                            :valueText="targetDateFormatted"
                        />
                    </li>
                </ul>
            </template>
        </BaseListItem>
        <BaseListItem
            :title="$t('deregistration-registrant-note')"
            :expandable="expandable"
        >
            <template v-slot:content>
                <v-row>
                    <v-col v-if="task.registrantNote" cols="12" md="6">
                        <v-alert
                            color="amber lighten-4"
                            icon="mdi-note"
                            dense
                            class="my-2"
                        >
                            <div>
                                {{ task.registrantNote }}
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </BaseListItem>
    </div>
</template>

<script>
import mixin from "../mixin";
export default {
    name: "SummaryReason",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    computed: {
        targetDateFormatted() {
            if (this.task?.targetDate) {
                let format = null;
                if (this.task.targetDate.length === 7) {
                    format = "MM.YYYY";
                } else {
                    format = "DD.MM.YYYY";
                }
                return this.$moment
                    .utc(this.task.targetDate)
                    .local()
                    .format(`[${this.$t("by")}] ${format}`);
            }
            return null;
        }
    }
};
</script>
