<i18n src="./base-list-i18n.yaml"></i18n>
<template>
    <div>
        <ViewTableList
            :title="computedTitle"
            :subtitle="subtitle"
            :is-loading="items.length === 0 && isLoading"
        >
            <template v-slot:table>
                <TasksTable
                    :items="items"
                    :actions="filteredActions"
                    @click:action="onActionClick"
                    v-on="$listeners"
                    v-bind="$attrs"
                >
                    <!-- Dynamically inherit slots from parent -->
                    <template
                        v-for="(slot, name) in $scopedSlots"
                        v-slot:[name]="item"
                    >
                        <slot :name="name" v-bind="item"></slot>
                    </template>
                </TasksTable>
                <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col md="8">
                            <DialogViewData
                                :docId="selectedTask.docId"
                                :value="dataDialog"
                                @input="dataDialog = $event"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <!-- Dynamically inherit slots from parent -->
            <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
                <slot :name="name" v-bind="item"></slot>
            </template>
            <!-- make all slots from v-autocomplete reusable from parent -->
            <template v-for="(_, slot) in $slots">
                <template :slot="slot">
                    <slot :name="slot"></slot>
                </template>
            </template>
        </ViewTableList>
    </div>
</template>

<script>
import { TaskActionTypes } from "@/data/task-action-types";
import { mapGetters, mapState } from "vuex";
import taskList from "@/mixins/backoffice/taskList";
import Helpers from "@/utils/helpers";

export default {
    name: "BackofficeBaseList",
    mixins: [taskList],
    components: {
        TasksTable: () =>
            import("@/components/backoffice/components/tasks-table.vue"),
        DialogViewData: () =>
            import("@/components/tasks/common/dialog-view-data.vue"),
        ViewTableList: () => import("@/components/general/view-table-list.vue")
    },

    props: {
        actions: {
            type: Array,
            default() {
                return [TaskActionTypes.DATA];
            },
            validator: function (actions) {
                let validTypes = Object.keys(TaskActionTypes);
                actions.forEach((action) => {
                    if (!validTypes.includes(action)) return false;
                });
                return true;
            }
        },
        items: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dataDialog: false,
        selectedTask: {}
    }),

    methods: {
        onActionClick({ action, item }) {
            this.selectedTask = item;
            switch (action) {
                case TaskActionTypes.VIEW:
                    this.onViewRegistration(item.process, item.referenceId);
                    break;
                case TaskActionTypes.VIEW_CHECK:
                    this.onEditViewRegistration(item.process, item.referenceId);
                    break;
                case TaskActionTypes.DATA:
                    this.dataDialog = true;
                    break;
                case TaskActionTypes.LOCK_FOR_REVIEW:
                    this.onTaskLockForReview(item);
                    break;
                case TaskActionTypes.UNLOCK_TASK:
                    this.onTaskUnlock(item);
                    break;
                case TaskActionTypes.FORCE_TAKE_OVER:
                    this.onTaskForceTakeOver(item);
                    break;
                case TaskActionTypes.DELETE:
                    this.onTaskDelete(item);
                    break;
                case TaskActionTypes.REMOVE_FROM_DB:
                    this.onTaskRemoveFromDB(item);
                    break;
                case TaskActionTypes.SEND_REMINDER_MAIL:
                    this.onSendReminderMail(item.referenceId);
                    break;
                case TaskActionTypes.SAP_EXPORT:
                    this.onSapExport(item);
                    break;
                case TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP:
                    this.onDownloadAllAttachmentsAsZip(item);
                    break;
                case TaskActionTypes.VIEW_HISTORY:
                    this.onViewHistory(item);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        onViewHistory(task) {
            this.$root.$historyModal.show(task.docId);
        },
        onTaskUnlock(task) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-task-unlock-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-task-unlock-msg")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("task:unlock", task, result);
                }
            });
        },
        onTaskForceTakeOver(task) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-task-force-take-over-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-task-force-take-over-msg", {
                    employeeName:
                        task.boEmployee.firstName +
                        " " +
                        task.boEmployee.familyName
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("task:force-take-over", task, result);
                }
            });
        },
        onTaskRemoveFromDB(task) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-task-remove-from-db-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-task-remove-from-db-text", {
                    referenceId: task.referenceId
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("task:remove-from-db", task, result);
                }
            });
        },
        onTaskDelete(task) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-task-delete-title"),
                    icon: "mdi-alert"
                },
                type: "reason",
                message: this.$t("msg-box-task-delete-text", {
                    referenceId: task.referenceId
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result.action === "agree") {
                    this.$emit("task:delete", task, result);
                }
            });
        },

        onSendReminderMail(task) {
            this.$emit("action:send-reminder-mail", task);
        },
        onSapExport(task) {
            this.$emit("action:sap-export", task);
        },
        onTaskLockForReview(task) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-task-lock-for-review-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-task-lock-for-review-msg")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$emit("task:lock-for-review", task, result);
                }
            });
        },
        onDownloadAllAttachmentsAsZip(task) {
            const taskDocId = task.docId;
            if (!taskDocId) return;
            this.loader().show();
            this.$axiosBinary
                .get(`/getAllAsZip/${taskDocId}`, {
                    responseType: "blob" // important
                })
                .then((response) => {
                    const downloaded =
                        Helpers.downloadAttachmentsAsZipByResponse(response);
                    if (downloaded) {
                        this.$toast.success(
                            this.$t("download-attachments-as-zip-msg-success")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("download-attachments-as-zip-msg-error")
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("download-attachments-as-zip-msg-error")
                    );
                });
        }
    },

    computed: {
        ...mapState("auth", ["accountDocId"]),
        ...mapGetters("auth", ["isUserAdmin"]),
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        computedTitle() {
            if (!this.title) return null;
            return this.title + " (" + this.items.length + ")";
        },
        filteredActions() {
            return this.actionsItems.filter((action) => {
                let show = true;
                if (action.isDevel && !this.developerMode) {
                    show = false;
                }
                if (action.isAdmin && !this.isUserAdmin) {
                    show = false;
                }
                return show;
            });
        },
        allowedActions() {
            return {
                [TaskActionTypes.VIEW]: {
                    title: this.$t("action-view"),
                    icon: "mdi-eye",
                    action: TaskActionTypes.VIEW
                },
                [TaskActionTypes.VIEW_CHECK]: {
                    title: this.$t("action-view-check"),
                    icon: "mdi-eye",
                    action: TaskActionTypes.VIEW_CHECK
                },
                [TaskActionTypes.DATA]: {
                    title: this.$t("action-data"),
                    icon: "mdi-code-json",
                    action: TaskActionTypes.DATA,
                    isDevel: true
                },
                [TaskActionTypes.UNLOCK_TASK]: {
                    title: this.$t("action-unlock-task"),
                    icon: "mdi-lock-open-variant-outline",
                    action: TaskActionTypes.UNLOCK_TASK
                },
                [TaskActionTypes.FORCE_TAKE_OVER]: {
                    title: this.$t("action-force-take-over"),
                    icon: "mdi-lock-open-variant-outline",
                    action: TaskActionTypes.FORCE_TAKE_OVER
                },
                [TaskActionTypes.LOCK_FOR_REVIEW]: {
                    title: this.$t("action-lock-for-review"),
                    icon: "mdi-lock",
                    action: TaskActionTypes.LOCK_FOR_REVIEW
                },
                [TaskActionTypes.DELETE]: {
                    title: this.$t("action-delete"),
                    icon: "mdi-delete",
                    action: TaskActionTypes.DELETE
                },
                [TaskActionTypes.REMOVE_FROM_DB]: {
                    title: this.$t("action-remove-from-db"),
                    icon: "mdi-delete",
                    action: TaskActionTypes.REMOVE_FROM_DB,
                    isDevel: true
                },
                [TaskActionTypes.SEND_REMINDER_MAIL]: {
                    title: this.$t("action-send-reminder-mail"),
                    icon: "mdi-email",
                    action: TaskActionTypes.SEND_REMINDER_MAIL
                },
                [TaskActionTypes.SAP_EXPORT]: {
                    title: this.$t("action-sap-export"),
                    icon: "mdi-send",
                    action: TaskActionTypes.SAP_EXPORT
                },
                [TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP]: {
                    title: this.$t("action-download-all-attachments-as-zip"),
                    icon: "mdi-folder-zip",
                    action: TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP,
                    disabled: (task) => {
                        return task.attachmentsCounter === 0;
                    }
                },
                [TaskActionTypes.VIEW_HISTORY]: {
                    title: this.$t("action-view-history"),
                    icon: "mdi-history",
                    action: TaskActionTypes.VIEW_HISTORY,
                    isAdmin: true
                }
            };
        },
        actionsItems() {
            let actions = [];
            this.actions.forEach((action) => {
                let _action = this.allowedActions[action];
                if (_action) {
                    actions.push(_action);
                }
            });
            return actions;
        }
    }
};
</script>
