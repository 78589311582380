var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","py-0":"","my-0":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("deregistration-details-info"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('base-select',{attrs:{"items":_vm.deregistrationReasons,"label":_vm.$t('reason-label'),"item-value":"value","item-key":"key","error-messages":_vm.reasonErrors,"required":_vm.isRequiredField(_vm.$v.reason),"clearable":"","dense":"","item-text":function (item) { return _vm.$t(("task-deregistration-reason-" + (item.value))); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("reason-label"))+" ")]},proxy:true}]),model:{value:(_vm.computedReason),callback:function ($$v) {_vm.computedReason=$$v},expression:"computedReason"}})],1),(_vm.computedReason)?[(_vm.needNewAddress)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.computedReason ===
                            _vm.DEREGISTRATION_REASONS.LOCATION_CHANGED
                                ? _vm.$t(
                                      'checkbox-new-address-not-known-location'
                                  )
                                : _vm.$t('checkbox-new-address-not-known-sold'),"dense":""},on:{"change":_vm.onNewAddressNotKnownChange},model:{value:(_vm.newAddressNotKnown),callback:function ($$v) {_vm.newAddressNotKnown=$$v},expression:"newAddressNotKnown"}})],1),(!_vm.newAddressNotKnown)?[_c('v-col',{attrs:{"cols":"12"}},[_c('WCountrySelect',{ref:"country",attrs:{"preferred-countries":[
                                'CH',
                                'LI',
                                'DE',
                                'FR',
                                'IT',
                                'AT'
                            ],"label":_vm.computedReason ===
                                _vm.DEREGISTRATION_REASONS.LOCATION_CHANGED
                                    ? _vm.$t(
                                          'new-address-country-location-changed-label'
                                      )
                                    : _vm.$t('new-address-country-sold-label'),"error-messages":_vm.countryErrors,"required":_vm.isRequiredField(_vm.$v.newAddress.country)},on:{"click:clear":function($event){_vm.computedCountry = null}},model:{value:(_vm.computedCountry),callback:function ($$v) {_vm.computedCountry=$$v},expression:"computedCountry"}})],1),(
                            _vm.computedNewAddress.country &&
                            ['CH', 'LI'].includes(
                                _vm.computedNewAddress.country
                            )
                        )?_c('v-col',{attrs:{"cols":"12"}},[_c('base-alert',{attrs:{"type":"info","dense":""}},[_vm._v(" "+_vm._s(_vm.$t("msg-info-new-address-ch-li"))+" ")])],1):_vm._e()]:_vm._e()]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('base-date-picker',{attrs:{"label":_vm.$t('target-date-label'),"errorMessages":_vm.targetDateErrors,"required":_vm.isRequiredField(_vm.$v.targetDate),"inputReadonly":"","type":_vm.targetDateDayUnknown ? 'month' : 'date'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',[_c('v-checkbox',{staticClass:"ml-2 ma-0 pa-0",attrs:{"hide-details":"","label":_vm.$t('label-target-date-day-unknown')},model:{value:(_vm.targetDateDayUnknown),callback:function ($$v) {_vm.targetDateDayUnknown=$$v},expression:"targetDateDayUnknown"}})],1)]},proxy:true}],null,false,1827076564),model:{value:(_vm.computedTargetDate),callback:function ($$v) {_vm.computedTargetDate=$$v},expression:"computedTargetDate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-textarea',{attrs:{"label":_vm.$t('registrant-note-label'),"error-messages":_vm.registrantNoteErrors,"counter":_vm.$v.registrantNote.$params.maxLength.max,"required":_vm.isRequiredField(_vm.$v.registrantNote),"clearable":"","no-resize":"","rows":"5"},model:{value:(_vm.computedRegistrantNote),callback:function ($$v) {_vm.computedRegistrantNote=$$v},expression:"computedRegistrantNote"}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }