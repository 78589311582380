<i18n src="./app-modal-history-i18n.yaml"></i18n>
<template>
    <base-dialog
        v-show="dialog"
        v-model="dialog"
        :title="$t('title')"
        icon="mdi-history"
    >
        <template v-slot:content>
            <v-container fluid fill-height>
                <v-card flat fluid width="100%">
                    <v-card-text>
                        <app-dev-container>
                            <template v-slot:content>
                                @items:
                                <tree-view
                                    :data="items"
                                    :options="{ maxDepth: 1 }"
                                ></tree-view>
                            </template>
                        </app-dev-container>
                        <v-data-table
                            v-if="items.length > 0"
                            :headers="headers"
                            :items="items"
                            hide-default-footer
                            :loading="isLoading"
                            @click:row="onRowItemClick"
                            :sort-by.sync="dataTableOptions.sortBy"
                            :sort-desc.sync="dataTableOptions.sortDesc"
                            dense
                        >
                            <template v-slot:item.category="{ item }">
                                <div v-if="item.category">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" small>{{
                                                getIconAndColorByHistoryItem(
                                                    item
                                                ).icon
                                            }}</v-icon>
                                        </template>
                                        {{
                                            $t(
                                                `category-${FILTERS.kebabizeLower(
                                                    item.category
                                                )}`
                                            )
                                        }}
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:item.userFirstName="{ item }">
                                <div v-if="item.userFirstName">
                                    <v-icon small>mdi-account</v-icon>
                                    {{ itemUserFullName(item) }}
                                </div>
                                <div v-else>
                                    <v-icon small>mdi-cog</v-icon>
                                    SYSTEM
                                </div>
                            </template>
                            <template v-slot:item.code="{ item }">
                                <div v-if="item.code">
                                    {{ $t(FILTERS.kebabizeLower(item.code)) }}
                                </div>
                            </template>
                            <template v-slot:item.timestamp="{ item }">
                                <div v-if="item.timestamp">
                                    <v-icon small>mdi-clock</v-icon>
                                    <base-date-time
                                        :timestamp="item.timestamp"
                                    />
                                </div>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    small
                                    color="info"
                                    @click="onRowItemClick(item)"
                                >
                                    mdi-eye
                                </v-icon>
                            </template>
                        </v-data-table>
                        <div v-else class="text-h5">
                            <v-icon class="pr-2" size="x-large" color="warning"
                                >mdi-exclamation</v-icon
                            >
                            <span>{{ $t("msg-no-data") }}</span>
                        </div>
                        <div class="text-center">
                            <base-pagination
                                v-model="page"
                                :length="totalPages"
                                @input="onPaginationInput"
                            ></base-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-container>
            <base-dialog
                v-model="infoDialog"
                :title="$t('title-info-dialog')"
                icon="mdi-information"
                max-width="500"
            >
                <template v-slot:content>
                    <v-container v-if="selectedItem">
                        <i18n
                            :path="`info-${FILTERS.kebabizeLower(
                                selectedItem.code
                            )}`"
                            tag="span"
                        >
                            <template #user>
                                <b>{{ itemUserFullName(selectedItem) }}</b>
                            </template>
                            <template #timestamp>
                                <base-date-time
                                    :timestamp="selectedItem.timestamp"
                                    clsDate="font-weight-bold"
                                    clsTime="font-weight-bold"
                                />
                            </template>
                            <template #manufacturerName>
                                <strong
                                    v-if="
                                        itemHasExtras(selectedItem) &&
                                        selectedItem.extras.manufacturer_name
                                    "
                                    >{{
                                        selectedItem.extras.manufacturer_name
                                    }}</strong
                                >
                            </template>
                            <template #reason>
                                <strong
                                    v-if="
                                        itemHasExtras(selectedItem) &&
                                        selectedItem.extras.reason
                                    "
                                    >{{ selectedItem.extras.reason }}</strong
                                >
                            </template>
                            <template
                                #taskRefId
                                v-if="itemHasExtras(selectedItem)"
                            >
                                <b>{{ selectedItem.extras.taskRefId }}</b>
                            </template>
                            <template
                                #taskRefIdCopy
                                v-if="itemHasExtras(selectedItem)"
                            >
                                <b>{{
                                    selectedItem.extras.task_ref_id_copy
                                }}</b>
                            </template>
                            <template
                                #sapResponse
                                v-if="itemHasExtras(selectedItem)"
                            >
                                <div>
                                    <base-alert type="warning" dense>
                                        <b>{{
                                            selectedItem.extras.response.detail
                                                .message
                                        }}</b>
                                    </base-alert>
                                </div>
                            </template>
                        </i18n>
                        <div
                            v-if="itemHasExtras(selectedItem) && developerMode"
                        >
                            <tree-view
                                :data="selectedItem.extras"
                                :options="{ maxDepth: 2 }"
                            ></tree-view>
                        </div>
                    </v-container>
                </template>
            </base-dialog>
        </template>
    </base-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { FILTERS } from "@/filters";
import { HISTORY_CATEGORIES, HISTORY_CODES } from "@/data/globals.js";

export default {
    name: "AppModalHistory",
    data: () => ({
        dialog: false,
        infoDialog: false,
        historyRefId: null,
        dataTableOptions: {
            sortBy: "timestamp",
            rowsPerPage: -1,
            sortDesc: true
        },
        defaultOptions: {},
        items: [],
        FILTERS,
        isLoading: false,
        selectedItem: {},
        HISTORY_CATEGORIES,
        HISTORY_CODES,
        page: 1,
        totalCount: 0,
        pages: 0,
        limit: 5
    }),
    computed: {
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        headers() {
            return [
                {
                    text: this.$t("header-category"),
                    value: "category",
                    sortable: false,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-code"),
                    value: "code",
                    sortable: false,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-user"),
                    value: "userFirstName",
                    sortable: false,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-timestamp"),
                    value: "timestamp",
                    sortable: true,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-actions"),
                    value: "actions",
                    sortable: false,
                    align: "right",
                    isMobile: false
                }
            ];
        },
        totalPages() {
            return this.totalCount > 5
                ? Math.floor(this.totalCount / this.limit)
                : 1;
        }
    },
    methods: {
        onPaginationInput() {
            this.fetchHistoryItems(this.historyRefId);
        },
        onRowItemClick(item) {
            this.selectedItem = item;
            this.infoDialog = true;
        },
        setDefaultOptions() {
            this.defaultOptions = {};
        },
        show(historyRefId, options) {
            if (!historyRefId) {
                return;
            }
            this.loader().show(this.$t("is-loading-msg"));
            this.clear();
            this.historyRefId = historyRefId;
            this.setDefaultOptions();
            this.dialog = true;
            this.options = Object.assign(this.defaultOptions, options);
            this.fetchHistoryItems(historyRefId);
        },
        hide() {
            this.dialog = false;
        },
        clear() {
            this.historyRefId = null;
        },
        fetchHistoryItems(historyRefId) {
            this.isLoading = true;
            const startPage = this.page - 1;
            let params = {
                page: startPage,
                limit: 5
            };
            this.$axiosHistoryApi
                .get(`/${historyRefId}`, { params })
                .then((response) => {
                    let resData = response.data.data;
                    this.items = [...resData.items];
                    this.totalCount = resData.totalCount;
                    this.loader().hide();
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(this.$t("fetch-items-error"));
                    this.isLoading = false;
                });
        },
        itemUserFullName(item) {
            return `${item.userFirstName} ${item.userFamilyName}`;
        },
        itemHasExtras(item) {
            return item && item.extras ? Object.keys(item?.extras).length > 0 : false;
        },
        getIconAndColorByHistoryItem(item) {
            const category = item.category;
            const code = item.code;
            let icon = null;
            let color = null;
            switch (category) {
                case HISTORY_CATEGORIES.CREATE:
                    icon = "mdi-star";
                    color = "success";
                    break;
                case HISTORY_CATEGORIES.UPDATE:
                    icon = "mdi-pencil";
                    color = "info";
                    break;
                case HISTORY_CATEGORIES.DELETE:
                    icon = "mdi-delete";
                    color = "error";
                    break;
                case HISTORY_CATEGORIES.UPLOAD:
                    icon = "mdi-upload";
                    color = "info";
                    break;
                case HISTORY_CATEGORIES.RESTORE_FROM_TRASH:
                    icon = "mdi-delete-restore";
                    color = "error";
                    break;
                case HISTORY_CATEGORIES.SAP:
                    icon = "mdi-delete-restore";
                    color =
                        code === HISTORY_CODES.SAP_EXPORT_RESPONSE_ERROR
                            ? "error"
                            : "success";
                    break;
                case HISTORY_CATEGORIES.MAIL:
                    icon = "mdi-email";
                    color = "info";
                    break;
                default:
                    icon = "mdi-history";
                    break;
            }
            return {
                icon: icon,
                color: color
            };
        }
    },

    created() {
        this.setDefaultOptions();
    }
};
</script>
