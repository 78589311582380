<template>
    <v-list-item class="pa-0 ma-0">
        <v-list-item-content>
            <v-list-item-title class="d-flex">
                <div class="text-decoration-underline">
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>
                <slot name="title-additional" />
                <v-spacer v-if="expandable"></v-spacer>
                <v-btn
                    v-if="expandable"
                    icon
                    small
                    class="pa-0 ma-0"
                    @click="expand = !expand"
                    ><v-icon v-html="expand ? 'mdi-eye-off' : 'mdi-eye'" />
                    <!-- v-html="expand ? 'mdi-menu-down' : 'mdi-menu-up'" -->
                </v-btn>
            </v-list-item-title>
            <slot name="list-item" />
            <v-expand-transition v-if="expand || !expandable">
                <v-container flat class="pa-0 ma-0">
                    <slot name="content" />
                </v-container>
            </v-expand-transition>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "BaseListItem",
    props: {
        title: {
            type: String,
            default: null
        },
        expandable: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        expand: true
    })
};
</script>
