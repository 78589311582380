<i18n src="./company-advanced-search-i18n.yaml"></i18n>
<template>
    <v-container
        fluid
        v-if="!readonlyModus"
        id="container-company-advanced-search"
    >
        <v-row dense>
            <v-col cols="12">
                <v-card-text ref="cardCompanyAdvancedSearch">
                    <base-alert dense type="info">
                        {{ $t("search-info-text") }}
                        <ul>
                            <li v-html="$t('search-info-text-list-1')"></li>
                            <li v-html="$t('search-info-text-list-2')"></li>
                        </ul>
                    </base-alert>

                    <v-row dense>
                        <v-col cols="12">
                            <base-text-field
                                v-model="searchName"
                                :label="$t('search-name-label')"
                                @click:clear="reset"
                                @keydown="onSearchNameInputKeydown"
                                @input="btnSearchDisabled = false"
                                clearable
                                required
                                @blur="
                                    onSearchParamsFieldChange({
                                        field: 'searchName',
                                        value: $event.target.value
                                    })
                                "
                            >
                            </base-text-field>
                        </v-col>
                        <v-col cols="12">
                            <WLocationSearch
                                v-model="locationSearch"
                                country="CH"
                                @reset="onWLocationSearchReset"
                            />
                        </v-col>

                        <v-col cols="12" md="10" class="py-1">
                            <base-text-field
                                v-model="advancedSearchStreet"
                                hide-details
                                :label="$t('street-label')"
                                @input="
                                    onSearchParamsFieldChange({
                                        field: 'advancedSearchStreet',
                                        value: $event
                                    })
                                "
                            ></base-text-field>
                        </v-col>
                        <v-col class="py-1" cols="12" md="2">
                            <base-text-field
                                v-model="advancedSearchHouseNr"
                                hide-details
                                :label="$t('house-nr-label')"
                                @input="
                                    onSearchParamsFieldChange({
                                        field: 'advancedSearchHouseNr',
                                        value: $event
                                    })
                                "
                            ></base-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-5">
                    <base-btn
                        v-if="
                            searchableAddress &&
                            (btnSearchDisabled || searchName == null)
                        "
                        type="secondary"
                        @click="setSearchAddress"
                    >
                        <v-icon left>mdi-database-search</v-icon>
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t("btn-take-address") }}
                        </span>
                    </base-btn>
                    <v-spacer></v-spacer>
                    <base-btn
                        :disabled="
                            (searchName == null && !btnSearchDisabled) ||
                            (btnSearchDisabled && searchName != null)
                        "
                        @click="onBtnFetchDataClick"
                        type="primary"
                    >
                        <v-icon left>mdi-database-search</v-icon>
                        <div>
                            {{ $t("btn-search") }}
                        </div>
                    </base-btn>
                </v-card-actions>
            </v-col>
            <v-col cols="12" v-show="result.length >= 1">
                <v-card-text
                    fill-height
                    ref="containerResults"
                    no-gutters
                    class="pt-5"
                >
                    <base-alert
                        width="100%"
                        v-if="errors != null"
                        tile
                        type="error"
                    >
                        {{ errors }}
                    </base-alert>
                    <base-alert
                        v-if="result.length >= 1"
                        ref="baseAlertSearchResultInfo"
                        id="base-alert-company-advanced-search"
                        width="100%"
                        dense
                        type="info"
                        color="info"
                    >
                        <i18n path="search-result-info" tag="div">
                            <template v-slot:possibleHits>
                                <b>{{
                                    $tc(
                                        "possible-hits",
                                        sortedResultItems.length,
                                        { count: sortedResultItems.length }
                                    )
                                }}</b>
                            </template>
                        </i18n>
                    </base-alert>
                    <v-data-table
                        v-model="selected"
                        :headers="computedHeaders"
                        :items-per-page.sync="itemsPerPage"
                        :items="sortedResultItems"
                        hide-default-footer
                        item-key="name"
                        ref="dataTable"
                    >
                        <template v-slot:body="{ items }">
                            <tbody v-if="$vuetify.breakpoint.smAndUp">
                                <tr
                                    style="cursor: pointer"
                                    v-for="(item, idx) in items"
                                    :key="idx"
                                    @click="onShowInfoClick(item)"
                                >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.street }}</td>
                                    <td>{{ item.houseNr }}</td>
                                    <td>{{ item.zipCode }}</td>
                                    <td>{{ item.city }}</td>
                                    <td>
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <v-list-item-avatar
                                                    size="20"
                                                    v-on="on"
                                                >
                                                    <v-img
                                                        :src="
                                                            require('@/assets/canton/' +
                                                                item.canton.toLowerCase() +
                                                                '.png')
                                                        "
                                                    ></v-img>
                                                </v-list-item-avatar>
                                            </template>
                                            <span>{{ item.canton }}</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                            <div v-else>
                                <v-card
                                    v-for="(item, idx) in items"
                                    :key="idx"
                                    @click="onShowInfoClick(item)"
                                    flat
                                >
                                    <v-card-text class="py-1">
                                        {{ item.name }}
                                        <div>
                                            {{ item.street }} {{ item.houseNr }}
                                        </div>
                                        <div>
                                            {{ item.zipCode }} {{ item.city }}
                                            {{ item.canton }}
                                        </div>
                                        <div>{{ item.uidFormatted }}</div>
                                    </v-card-text>
                                    <v-divider class="pa-0 ma-0"></v-divider>
                                </v-card>
                            </div>
                        </template>
                        <template v-slot:item.canton="{ item }">
                            <template>
                                <v-list-item-avatar>
                                    <v-img
                                        :src="
                                            require('@/assets/canton/' +
                                                item.canton.toLowerCase() +
                                                '.png')
                                        "
                                    ></v-img>
                                </v-list-item-avatar>
                            </template>
                        </template>
                    </v-data-table>
                </v-card-text>

                <base-dialog
                    v-if="showInfoItem != null"
                    :value="dialogInfoItem"
                    :title="showInfoItem.uidFormatted"
                    max-width="400"
                    icon="mdi-information-outline"
                    @input="dialogInfoItem = $event"
                >
                    <template v-slot:content>
                        <v-card flat width="100%">
                            <v-card-title class="text-subtitle-1">
                                {{ $t("dialog-info-item-text") }}
                            </v-card-title>
                            <v-card-text>
                                <div class="justify-center text-body-1">
                                    <div>{{ showInfoItem.name }}</div>
                                    <div>
                                        {{ showInfoItem.street }}
                                        {{ showInfoItem.houseNr }}
                                    </div>
                                    <div>
                                        {{ showInfoItem.canton }}
                                        {{ showInfoItem.zipCode }}
                                        {{ showInfoItem.city }}
                                    </div>
                                    <div>{{ showInfoItem.uidFormatted }}</div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:actions>
                        <v-spacer />
                        <base-btn
                            @click="onConfirmSelectedCompany"
                            type="primary"
                        >
                            {{ $t("btn-confirm-selected-company-confirm") }}
                        </base-btn>
                    </template>
                </base-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "CompanyAdvancedSearch",
    props: {
        closeSearchCard: {
            type: [Boolean, String],
            default: false
        },
        formData: {
            type: Object,
            default: () => {}
        },
        searchParams: {
            type: Object,
            default: () => {}
        },
        searchableAddress: {
            type: Object,
            default: () => {}
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },

    data: (vm) => ({
        selectDialog: false,
        dialogNotFound: false,
        selectCheckbox: false,
        btnSearchDisabled: false,
        searchName: null,
        result: [],
        headers: [
            {
                text: vm.$t("header-name"),
                sortable: false,
                value: "name",
                minWidth: "200",
                isMobile: true
            },
            {
                text: vm.$t("header-street"),
                value: "street",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-house-nr"),
                value: "houseNr",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-zip-code"),
                value: "zipCode",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-city"),
                value: "city",
                align: "left",
                isMobile: true
            },
            {
                text: vm.$t("header-canton"),
                value: "canton",
                align: "left",
                isMobile: true
            }
        ],
        page: 1,
        itemsPerPage: 30,
        selected: [],
        dialogInfoItem: false,
        selectedCompany: {},
        confirmSelectedCompany: false,
        showInfoItem: null,
        errors: null,
        isMobile: false,
        advancedSearchCanton: null,
        advancedSearchZipCode: null,
        advancedSearchCity: null,
        advancedSearchStreet: null,
        advancedSearchHouseNr: null
    }),

    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true
            });
        }
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    computed: {
        computedHeaders() {
            if (this.isMobile) {
                return this.headers.filter((header) => header.isMobile);
            }
            return this.headers;
        },
        sortedResultItems() {
            return this.result.filter((item) => item.status === 1);
        },
        locationSearch: {
            get() {
                let search = {};
                if (this.searchParams) {
                    search.zipCode = this.searchParams.zipCode || null;
                    search.city = this.searchParams.city || null;
                    search.canton = this.searchParams.canton || null;
                }
                return search;
            },
            set(val) {
                if (!val) {
                    this.onWLocationSearchReset();
                    return;
                }
                if (val.zipCode) {
                    this.advancedSearchZipCode = val.zipCode;
                    this.onSearchParamsFieldChange({
                        field: "advancedSearchZipCode",
                        value: val.zipCode
                    });
                }
                if (val.city) {
                    this.advancedSearchCity = val.city;
                    this.onSearchParamsFieldChange({
                        field: "advancedSearchCity",
                        value: val.city
                    });
                }
                if (val.canton) {
                    this.advancedSearchCanton = val.canton;
                    this.onSearchParamsFieldChange({
                        field: "advancedSearchCanton",
                        value: val.canton
                    });
                }
            }
        }
    },
    methods: {
        onSearchParamsFieldChange({ field, value }) {
            if (field) {
                this.$emit("field:change", { field, value });
            }
        },
        onWLocationSearchReset() {
            this.advancedSearchZipCode = null;
            this.advancedSearchCity = null;
            this.advancedSearchCanton = null;
        },
        setData(data) {
            if (data.manualEntry) {
                this.manualEntry = Boolean(data.manualEntry);
            }
            this.manualEntryReason = data.manualEntryReason;
            this.manualEntryReasonDescription =
                data.manualEntryReasonDescription;
        },
        setSearchAddress() {
            if (!this.searchableAddress) {
                return;
            } else {
                this.reset();
                let address = this.searchableAddress;
                this.searchName = address.companyName;
                this.advancedSearchCanton = address.canton;
                this.advancedSearchZipCode = address.zipCode;
                this.advancedSearchCity = address.city;
                this.advancedSearchStreet = address.street;
                this.advancedSearchHouseNr = address.houseNr;
                this.btnSearchDisabled = true;
                this.onBtnFetchDataClick();
            }
        },
        onSearchNameInputKeydown(keyboardEvent) {
            if (!this.searchName) {
                return;
            } else {
                if (keyboardEvent.keyCode === 13) {
                    this.onBtnFetchDataClick();
                }
            }
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
        onBtnFetchDataClick() {
            this.$emit("click:btn-fetch-data");
            this.loader().show();
            let data = {};
            data.name = this.searchName;
            data.advancedSearch = true;
            data.devSearch = this.switchDevSearch;
            data.zipCode = this.advancedSearchZipCode;
            data.street = this.advancedSearchStreet;
            data.houseNr = this.advancedSearchHouseNr;
            data.city = this.advancedSearchCity;

            this.$axiosUidApi
                .post("/", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        let result = response.data.data;
                        this.result = result;
                        if (result.length === 0) {
                            this.$toast.error(this.$t("fetch-uid-api-error"));
                        }
                        this.scrollToSearchResults();
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        setSampleData() {
            this.searchName = "was.ch";
            this.zipCode = "9000";
            this.city = "St. Gallen";
            this.street = "Vonwilstrasse";
            this.houseNr = "23";
        },
        reset() {
            this.searchName = null;
            this.advancedSearchZipCode = null;
            this.advancedSearchCity = null;
            this.advancedSearchStreet = null;
            this.advancedSearchHouseNr = null;
            this.selected = [];
            this.selectedUid = null;
            this.dialog = false;
            this.errors = null;
            this.result = [];
            this.btnSearchDisabled = false;
        },
        onInputSelectChange() {
            this.selectDialog = true;
        },
        onItemSelect(item) {
            this.$emit("change:item-select", item);
            this.selectCheckbox = true;
            this.selectDialog = false;
        },
        onShowInfoClick(item) {
            this.selectedCompany = item;
            this.dialogInfoItem = true;
            this.showInfoItem = item;
        },
        onConfirmSelectedCompany() {
            this.confirmSelectedCompany = true;
            this.dialogInfoItem = false;
            this.$emit("click:confirm-selected-company", this.selectedCompany);
            this.result = [];
        },
        setSearchParamsData(params) {
            this.advancedSearchCanton = params.canton;
            this.advancedSearchZipCode = params.zipCode;
            this.advancedSearchCity = params.city;
            this.advancedSearchStreet = params.street;
            this.advancedSearchHouseNr = params.houseNr;
            this.searchName = params.searchName;
        },
        scrollToSearchResults() {
            this.$nextTick(() => {
                let baseDialog = this.$parent.$parent.$parent.$parent;
                let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
                let options = {
                    duration: 500,
                    easing: "easeInOutCubic",
                    container: baseDialogCardTextRef
                };
                this.$vuetify.goTo(this.$refs.dataTable, options);
            });
        }
    },

    watch: {
        formData: {
            handler: function (newData) {
                if (newData) {
                    this.setData(newData);
                }
            },
            immediate: true
        },
        searchParams: {
            handler: function (newData) {
                if (newData) {
                    this.setSearchParamsData(newData);
                }
            },
            immediate: true
        }
    }
};
</script>
