import { render, staticRenderFns } from "./summary-chambers.vue?vue&type=template&id=29206146&"
import script from "./summary-chambers.vue?vue&type=script&lang=js&"
export * from "./summary-chambers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./summary-chambers-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fweb%2Fsrc%2Fcomponents%2Ftasks%2Fcommon%2Fsummary%2Fregistration%2Fsummary-chambers.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItemSubtitle})
