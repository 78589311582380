<i18n src="./content-i18n.yaml"></i18n>
<template>
    <base-card>
        <BackofficeBaseList
            :items="items"
            :headers="headers"
            :actions="actionsItems"
            :title="$t('title')"
            @reloadDataClick="reload"
            item-key="docId"
            @task:unlock="onTaskUnlock"
        >
        </BackofficeBaseList>
    </base-card>
</template>
<script>
import { mapState } from "vuex";
import taskList from "@/mixins/backoffice/taskList";
import { TaskActionTypes } from "@/data/task-action-types";
import BackofficeBaseList from "@/components/backoffice/components/base-list.vue";

export default {
    name: "BackofficeOpenTasksContent",
    mixins: [taskList],
    components: {
        BackofficeBaseList
    },
    data() {
        return {
            actionsItems: [
                TaskActionTypes.VIEW_CHECK,
                TaskActionTypes.DATA,
                TaskActionTypes.UNLOCK_TASK,
                TaskActionTypes.DOWNLOAD_ALL_ATTACHMENTS_AS_ZIP,
                TaskActionTypes.VIEW_HISTORY
            ]
        };
    },
    computed: {
        ...mapState("backoffice", {
            items: "openTasks"
        }),
        headers() {
            return [
                {
                    text: this.$t("header-state"),
                    align: "top",
                    sortable: false,
                    value: "state",
                    isMobile: true
                },
                {
                    text: this.$t("header-reference-id"),
                    value: "referenceId",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-manufacturer"),
                    value: "manufacturer",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-employee"),
                    value: "boEmployee",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-locked-until"),
                    value: "boLockedUntil",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-bo-reminder"),
                    value: "boReminder",
                    sortable: true,
                    align: "start",
                    isMobile: false
                },
                {
                    text: this.$t("header-registrant-complete-date"),
                    value: "registrantCompleteDate",
                    sortable: true,
                    align: "left",
                    isMobile: false
                },
                {
                    text: this.$t("header-operator"),
                    value: "operator",
                    sortable: true,
                    align: "left",
                    isMobile: true
                }
            ];
        }
    },
    methods: {
        fetchOpenRegistrations() {
            this.loader().show();
            this.$store
                .dispatch("backoffice/fetchOpenRegistrations")
                .then(() => {
                    this.loader().hide();
                })
                .catch(() => {
                    this.loader().hide();
                });
        },
        reload() {
            this.fetchOpenRegistrations();
        },
        onTaskUnlock(task) {
            this.loader().show();
            const taskDocId = task.docId;
            this.$store
                .dispatch("backoffice/registrationUnlockForReview", taskDocId)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-task-unlock-success"));
                        this.$store.commit(
                            "backoffice/removeRegistrationFromList",
                            {
                                list: "openTasks",
                                docId: task.docId
                            }
                        );
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("msg-task-unlock-error"));
                    this.loader().hide();
                });
        }
    },
    created() {
        if (this.isAuth) {
            this.fetchOpenRegistrations();
        }
    }
};
</script>
