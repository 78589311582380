<i18n src="./dialog-chamber-fields-diff-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="
            chamberId === -1
                ? undefined
                : $t('title-diff-chamber', { chamberNr: chamberId })
        "
        :fullscreen="$vuetify.breakpoint.xsOnly || fullscreen"
        maxWidth="1800"
        ref="baseDialog"
        icon="mdi-information-outline"
    >
        <template v-slot:toolbar-items>
            <v-btn
                icon
                @click="fullscreen = !fullscreen"
                v-if="!$vuetify.breakpoint.xsOnly"
            >
                <v-icon
                    v-html="
                        fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
                    "
                ></v-icon>
            </v-btn>
        </template>
        <template v-slot:content>
            <v-container fluid class="pt-1 mt-1">
                <!-- This alert could be used do display an msg to the user
            - if chamber data is not equals to original (registrant) data
            -->
                <!-- <base-alert v-if="diffFields && Object.keys(diffFields).length > 0" type="warning">
                {{ $t('warning-msg-diff-fields')}}
            </base-alert> -->
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <div class="pl-4 py-4 text-h3">
                            {{ $t("view-registrant-title") }}
                        </div>
                        <form-chamber
                            class="pa-2 pt-4"
                            ref="formChamberOriginal"
                            :chamberId="chamberId"
                            :readonlyModus="true"
                            :activelyHeated="originalData.activelyHeated"
                        >
                        </form-chamber>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="pl-4 py-4 text-h3">
                            {{ $t("view-backoffice-title") }}
                        </div>
                        <form-chamber
                            class="pa-2 pt-4"
                            ref="formChamberDiff"
                            :chamberId="chamberId"
                            :readonlyModus="readonlyModus"
                            :activelyHeated="diffData.activelyHeated"
                        >
                        </form-chamber>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <base-btn outlined @click="closeDialog">
                {{ $t("btn-cancel") }}
            </base-btn>
            <base-btn
                v-if="!readonlyModus"
                :disabled="readonlyModus"
                @click="onSaveClick"
            >
                {{ $t("btn-save") }}
            </base-btn>
        </template>
    </base-dialog>
</template>
<script>
import FormChamber from "@/components/tasks/edit/common/forms/form-chamber.vue";
import Helpers from "@/utils/helpers";
export default {
    name: "DialogFieldsDiff",
    components: {
        FormChamber
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        originalData: {
            type: Object,
            default: () => {}
        },
        diffData: {
            type: Object,
            default: () => {}
        },
        chamberId: {
            type: Number,
            default: -1
        }
    },
    data: () => ({
        dialog: false,
        fullscreen: false
    }),
    computed: {
        diffFields() {
            if (!this.originalData || !this.diffData) return {};
            return Helpers.diffsOf2Objects(
                this.originalData.chamber,
                this.diffData.chamber,
                true
            );
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        scrollToFirstErrorWithScrollOptions() {
            let baseDialog = this.$refs.baseDialog;
            let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
            let scrollOptions = {
                container: baseDialogCardTextRef
            };
            this.$refs.formChamberDiff.scrollToFirstError(scrollOptions);
        },
        onSaveClick() {
            if (this.readonlyModus) {
                this.closeDialog();
                return;
            }
            this.$refs.formChamberDiff.validate();
            if (!this.$refs.formChamberDiff.isValid()) {
                this.scrollToFirstErrorWithScrollOptions();
                return;
            }

            let data = this.$refs.formChamberDiff.getData();
            this.$emit("save", data);
            this.closeDialog();
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
                if (this.originalData) {
                    this.$nextTick(() => {
                        this.$refs.formChamberOriginal.reset();
                        this.$refs.formChamberOriginal.setData(
                            this.originalData.chamber
                        );
                    });
                }
                if (this.diffData) {
                    this.$nextTick(() => {
                        this.$refs.formChamberDiff.reset();
                        this.$refs.formChamberDiff.setData(
                            this.diffData.chamber
                        );
                    });
                }
            },
            immediate: true
        }
    }
};
</script>
