<i18n 	  src="./modal-settings-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="600"
        icon="mdi-cog"
    >
        <template v-slot:content>
            <v-container>
                <v-switch
                    v-model="switchDeveloperMode"
                    :value="developerMode"
                    @change="onChangeDeveloperMode"
                    :label="$t('developer-mode-label')"
                ></v-switch>
                <base-dialog
                    :value="dialogDeveloperMode"
                    @input="closePasswordDialog"
                    width="350"
                    title="Activate Developer Mode"
                    icon="mdi-cog"
                >
                    <template v-slot:content>
                        <v-container>
                            <div
                                class="ma-auto position-relative"
                                style="max-width: 300px"
                            >
                                <v-otp-input
                                    v-model="developerModePassword"
                                    color="primary"
                                    :disabled="isLoading"
                                    @finish="activateDeveloperMode"
                                    type="password"
                                    :length="passwordLength"
                                ></v-otp-input>
                                <v-overlay absolute :value="isLoading">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </v-overlay>
                            </div>
                        </v-container>
                    </template>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <base-btn
                            @click="activateDeveloperMode"
                            :disabled="
                                !developerModePassword ||
                                developerModePassword.length != passwordLength
                            "
                        >
                            Activate
                        </base-btn>
                    </template>
                </base-dialog>
            </v-container>
            <v-container>
                <v-layout column v-if="developerMode">
                    <v-flex>
                        <div class="theme-options">
                            <v-subheader class="px-1">
                                {{ $t("themes") }}
                            </v-subheader>
                            <v-divider></v-divider>
                            <div class="my-3">
                                <v-btn-toggle v-model="themeOption">
                                    <base-btn text value="dark">
                                        {{ $t("dark") }}
                                    </base-btn>
                                    <base-btn text value="light">
                                        {{ $t("light") }}
                                    </base-btn>
                                </v-btn-toggle>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex>
                        <v-subheader class="px-1">
                            {{ $t("others") }}
                        </v-subheader>
                        <v-divider></v-divider>
                        Fullscreen
                        <base-btn icon large @click="onFullScreenClick()">
                            <v-icon>mdi-fullscreen</v-icon>
                        </base-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </template>
    </base-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Utils from "@/utils";
export default {
    name: "ModalSettings",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        dialogDeveloperMode: false,
        developerModePassword: null,
        switchDeveloperMode: false,
        isLoading: false,
        passwordLength: 6
    }),
    methods: {
        onChangeDeveloperMode(val) {
            if (val) {
                this.dialogDeveloperMode = true;
            } else {
                this.dialogDeveloperMode = false;
                this.$toast.info(this.$t("developer-mode-disabled-msg"));
                this.$store.commit("settings/setDeveloperMode", false);
            }
        },
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        activateDeveloperMode() {
            this.loader().show();
            this.$axiosMainApi
                .post("/config/developerMode/activate", {
                    code: this.developerModePassword
                })
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        let resData = response.data;
                        if (resData.data === true) {
                            this.$store.commit(
                                "settings/setDeveloperMode",
                                true
                            );

                            this.developerModePassword = null;
                            this.dialogDeveloperMode = false;
                            this.$toast.success(
                                this.$t("developer-mode-enabled-msg")
                            );
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-wrong-code"));
                    this.switchDeveloperMode = false;
                    this.$store.commit("settings/setDeveloperMode", false);
                    this.loader().hide();
                });
        },
        closePasswordDialog() {
            this.dialogDeveloperMode = false;
            this.switchDeveloperMode = false;
            this.developerModePassword = false;
            this.$store.commit("settings/setDeveloperMode", false);
        },
        onFullScreenClick() {
            Utils.toggleFullScreen();
        }
    },
    computed: {
        ...mapGetters("settings", {
            developerMode: "developerMode"
        }),
        appVersion() {
            return process.env.VUE_APP_VERSION;
        },
        themeOption: {
            get: function () {
                if (localStorage.getItem("userThemeDark") == "true") {
                    return "dark";
                } else {
                    return "light";
                }
            },
            // setter
            set: function (val) {
                if (val) {
                    if (val === "dark") {
                        localStorage.setItem("userThemeDark", true);
                        this.$vuetify.theme.dark = true;
                    } else {
                        localStorage.setItem("userThemeDark", false);
                        this.$vuetify.theme.dark = false;
                    }
                } else {
                    return;
                }
            }
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.dialog = newValue;
            },
            immediate: true
        },
        developerMode: {
            handler: function (newValue) {
                this.switchDeveloperMode = newValue;
            },
            immediate: true
        }
    }
};
</script>
<style scoped>
.position-relative {
    position: relative;
}
</style>
