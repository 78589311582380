import { axiosTasks } from '@/api/axios-instances';
import store from '@/store';
import { PROCESS_TYPES } from "@/data/globals";
const state = {
    open : {},
    all  : [],
    withOpenDialogs: []
};

const mutations = {
    setOpen(state, data) {
        if (data) {
            state.open  = data;
        }
    },
    clearOpen(state) {
        state.open = {};
    },
    setWithOpenDialogs(state, data) {
        if (data) {
            state.withOpenDialogs = data;
        }
    },
    clearWithOpenDialogs(state) {
        state.withOpenDialogs = [];
    },
    setAll(state, data) {
        if (data) {
            state.all = data;
        }
    },
    clearAll(state) {
        state.all = [];
    },
    clearStore(state) {
        state.open = {};
        state.all = [];
        state.withOpenDialogs = [];
    }
};

const getters = {
    open(state) {
        return state.open;
    },
    allRegistrations(state) {
        return state.all;
    },
    readonlyModus(state) {
        return state.readonlyModus;
    },
    registration(state) {
        return state.registration;
    }
};

const actions = {
    create: (_ctx, data) => {
        data.registrationOffice = store.getters['settings/getRegistrationOffice'];
        return new Promise((resolve, reject) => {
            axiosTasks.post(`/${PROCESS_TYPES.REGISTRATION}`, data)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    fetchOpen: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            axiosTasks.get('/openTaskRegistration', data)
            .then(response => {
                let status = response.status;
                if (status === 200) {
                    let resData = response.data.data;
                    commit('setOpen', resData);
                }
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    fetchAllRegistrationsWithOpenDialogs: ({commit}) => {
        return new Promise((resolve, reject) => {
            axiosTasks.get('/getAllRegistrantTasksWithOpenDialogs')
            .then(response => {
                let status = response.status;
                if (status === 200) {
                    const resData = response.data.data;
                    commit('setWithOpenDialogs', resData);
                }
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    deleteOpen: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            axiosTasks.delete('/openTaskRegistration', {data})
            .then(response => {
                let status = response.status;
                if (status === 200) {
                    commit('clearOpen');
                }
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    fetchOpenRegistrationDataByDocId: ({commit}, data) => {
        const docId = data.docId
        return new Promise((resolve, reject) => {
            // TODO: `/${PROCESS_TYPES.REGISTRATION}`
            axiosTasks.get(`/doc/${docId}`)
            .then(response => {
                let status = response.status;
                if (status === 200) {
                    let registration = response.data.data;
                    commit('CLEAR_STEPPER_REGISTRATION');
                    //set default steps data on load
                    commit('SET_REGISTRATION', registration);
                }
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    fetchOpenRegistrationDataByReferenceId: ({commit}, data) => {
        const referenceId = data.referenceId
        return new Promise((resolve, reject) => {
            //TODO: `/${PROCESS_TYPES.REGISTRATION}`
            axiosTasks.get(`/ref/${referenceId}`)
            .then(response => {
                let status = response.status;
                if (status === 200) {
                    let registration = response.data.data;
                    commit('CLEAR_STEPPER_REGISTRATION');
                    //set default steps data on load
                    commit('SET_REGISTRATION', registration);
                }
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    complete: (_ctx, data) => {
        return new Promise((resolve, reject) => {
            axiosTasks.put(`/complete/${PROCESS_TYPES.REGISTRATION}`, data)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    fetchRegistrationDataByDocId: (_ctx, docId) => {
        return new Promise((resolve, reject) => {
            //TODO: ${PROCESS_TYPES.REGISTRATION}
            axiosTasks.get(`/doc/${docId}`)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    fetchRegistrationDataByReferenceId: (_ctx, referenceId) => {
        return new Promise((resolve, reject) => {
            axiosTasks.get(`/ref/${referenceId}`)
            //TODO: ${PROCESS_TYPES.REGISTRATION}
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    createCopy: (_ctx, {data, copyReferenceId}) => {
        data.registrationOffice = store.getters['settings/getRegistrationOffice'];
        return new Promise((resolve, reject) => {
            axiosTasks.post(`/copy/${copyReferenceId}`, data)
            .then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
