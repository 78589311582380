var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"sort-by":_vm.computedOptions.sortBy,"sort-desc":_vm.computedOptions.sortDesc,"loading":_vm.isLoading},on:{"update:sortBy":function($event){return _vm.$set(_vm.computedOptions, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.computedOptions, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.computedOptions, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.computedOptions, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._t("state",function(){return [_c('registration-icon',{staticClass:"pt-2",attrs:{"objectType":item.objectType,"state":item.state,"deletedState":item.deletedState ? item.deletedState : 0,"process":item.process}})]},{"item":item})]}},{key:"item.referenceId",fn:function(ref){
var item = ref.item;
return [_vm._t("referenceId",function(){return [(item.referenceId)?_c('div',[_vm._v(" "+_vm._s(item.referenceId)+" "),_c('CopyToClipboard',{attrs:{"value":item.referenceId}})],1):_vm._e()]},{"item":item})]}},{key:"item.manufacturer",fn:function(ref){
var item = ref.item;
return [_vm._t("manufacturer",function(){return [(item.manufacturer)?_c('div',[_vm._v(_vm._s(item.manufacturer))]):_vm._e(),(item.factoryNumber)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("factory-number-short", { number: item.factoryNumber }))+" ")]):_vm._e(),(item.constructionYear)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("construction-year-short", { year: item.constructionYear }))+" ")]):_vm._e(),(item.inOperationYear)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("in-operation-year", { year: item.inOperationYear }))+" ")]):_vm._e()]},{"item":item})]}},{key:"item.registrantCompleteDate",fn:function(ref){
var item = ref.item;
return [_vm._t("registrantCompleteDate",function(){return [(item.registrantCompleteDate)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-check")]),_c('base-date-time',{attrs:{"timestamp":item.registrantCompleteDate}})],1):_vm._e()]},{"item":item})]}},{key:"item.backofficeCompleteDate",fn:function(ref){
var item = ref.item;
return [_vm._t("backofficeCompleteDate",function(){return [(item.backofficeCompleteDate)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-check")]),_c('base-date-time',{attrs:{"timestamp":item.backofficeCompleteDate}})],1):_vm._e()]},{"item":item})]}},{key:"item.reminder",fn:function(ref){
var item = ref.item;
return [_vm._t("reminder",function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$moment.utc(item.reminder).local().from(_vm.$moment()))+" ")])]},{"item":item})]}},{key:"item.boLockedUntil",fn:function(ref){
var item = ref.item;
return [_vm._t("boLockedUntil",function(){return [(item.boLockedUntil)?_c('div',[(
                        _vm.$moment.utc(item.boLockedUntil).local() < _vm.$moment()
                    )?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boLockedUntil) .local() .from(_vm.$moment()))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boLockedUntil) .local() .from(_vm.$moment()))+" ")])]):_vm._e()]},{"item":item})]}},{key:"item.boReminderAssignRegistrant",fn:function(ref){
                    var item = ref.item;
return [_vm._t("boReminderAssignRegistrant",function(){return [(item.boReminderAssignRegistrant)?_c('div',[(
                        _vm.getDiffDaysBeetweenDateAndNow(
                            item.boReminderAssignRegistrant
                        ) === 0 ||
                        _vm.getDiffDaysBeetweenDateAndNow(
                            item.boReminderAssignRegistrant
                        ) === -1
                    )?_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boReminderAssignRegistrant) .local() .from(_vm.$moment()))+" ")]):(
                        _vm.getDiffDaysBeetweenDateAndNow(
                            item.boReminderAssignRegistrant
                        ) > 0
                    )?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boReminderAssignRegistrant) .local() .from(_vm.$moment()))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boReminderAssignRegistrant) .local() .from(_vm.$moment()))+" ")])]):_vm._e()]},{"item":item})]}},{key:"item.boReminder",fn:function(ref){
                    var item = ref.item;
return [_vm._t("boReminder",function(){return [(item.boReminder)?_c('div',[(
                        _vm.getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                            0 ||
                        _vm.getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                            -1
                    )?_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.boReminder).local().from(_vm.$moment()))+" ")]):(
                        _vm.getDiffDaysBeetweenDateAndNow(item.boReminder) > 0
                    )?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.boReminder).local().from(_vm.$moment()))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$moment.utc(item.boReminder).local().from(_vm.$moment()))+" ")])]):_vm._e()]},{"item":item})]}},{key:"item.operator",fn:function(ref){
                    var item = ref.item;
return [_vm._t("operator",function(){return [(item.operator)?_c('div',[(
                        item.operator.addressType &&
                        item.operator.addressType == _vm.ADDRESS_TYPES.COMPANY
                    )?_c('div',[(item.operator.uidFormatted)?_c('div',[_vm._v(" "+_vm._s(item.operator.uidFormatted)+" "),_c('CopyToClipboard',{attrs:{"value":item.operator.uidFormatted}})],1):_vm._e(),(item.operator.companyName)?_c('div',[_vm._v(" "+_vm._s(item.operator.companyName)+" ")]):_vm._e()]):_c('div',[(item.operator.firstName)?_c('span',[_vm._v(" "+_vm._s(item.operator.firstName)+" ")]):_vm._e(),(item.operator.familyName)?_c('span',[_vm._v(" "+_vm._s(item.operator.familyName)+" ")]):_vm._e()]),(item.operator.street && item.operator.houseNr)?_c('div',[(item.operator.street)?_c('span',[_vm._v(" "+_vm._s(item.operator.street)+" ")]):_vm._e(),(item.operator.houseNr)?_c('span',[_vm._v(" "+_vm._s(item.operator.houseNr)+" ")]):_vm._e()]):_vm._e(),(item.operator.city && item.operator.zipCode)?_c('div',[(
                            item.operator.country && item.operator.zipCode
                        )?_c('span',[_vm._v(" "+_vm._s(item.operator.country)+"-"+_vm._s(item.operator.zipCode)+" ")]):(
                            !item.operator.country && item.operator.zipCode
                        )?_c('span',[_vm._v(" "+_vm._s(item.operator.zipCode)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.operator.country)+" ")]),(item.operator.city)?_c('span',[_vm._v(" "+_vm._s(item.operator.city)+" "),(item.operator.zipCode)?_c('span',[_vm._v(" "+_vm._s(item.operator.zipCode)+" ")]):_vm._e()]):_vm._e(),(item.operator.canton)?_c('span',[_vm._v(" ("+_vm._s(item.operator.canton)+") ")]):_vm._e()]):_vm._e()]):_vm._e()]},{"item":item})]}},{key:"item.registrant",fn:function(ref){
                        var item = ref.item;
return [_vm._t("registrant",function(){return [(item.account)?_c('div',[_c('v-icon',[_vm._v("mdi-account")]),(item.account.firstName)?_c('span',[_vm._v(" "+_vm._s(item.account.firstName)+" ")]):_vm._e(),(item.account.familyName)?_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.account.familyName)+" ")]):_vm._e()],1):_vm._e()]},{"item":item})]}},{key:"item.boEmployee",fn:function(ref){
                        var item = ref.item;
return [_vm._t("boEmployee",function(){return [(item.boEmployee)?_c('div',[_c('v-icon',[_vm._v("mdi-account-hard-hat")]),_c('span',[_vm._v(" "+_vm._s(item.boEmployee.firstName))]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.boEmployee.familyName))])],1):_c('div',[_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("not-yet-assigned")))])])]},{"item":item})]}},{key:"item.boDates",fn:function(ref){
                        var item = ref.item;
return [_vm._t("boDates",function(){return [(item.boReminder)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-clock")]),_vm._v(" "+_vm._s(_vm.$t("locked-to"))+": "),(
                        _vm.getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                            0 ||
                        _vm.getDiffDaysBeetweenDateAndNow(item.boReminder) ===
                            -1
                    )?_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.boReminder).local().from(_vm.$moment()))+" ")]):(
                        _vm.getDiffDaysBeetweenDateAndNow(item.boReminder) > 0
                    )?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.boReminder).local().from(_vm.$moment()))+" ")]):_c('span',{staticClass:"info--text"},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.boReminder).local().from(_vm.$moment()))+" ")])],1):_vm._e(),(item.boLockedUntil)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-alert")]),_vm._v(" "+_vm._s(_vm.$t("reminder-at"))+": "),(
                        _vm.$moment.utc(item.boLockedUntil).local() < _vm.$moment()
                    )?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boLockedUntil) .local() .from(_vm.$moment()))+" ")]):_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$moment .utc(item.boLockedUntil) .local() .from(_vm.$moment()))+" ")])],1):_vm._e()]},{"item":item})]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_vm._t("actions",function(){return [(_vm.actions.length > 0)?[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('base-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.deletedState > 0}},'base-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-reorder-horizontal ")])],1)]}}],null,true)},[_c('v-list',[_vm._l((_vm.actions),function(actionItem,i){return [(_vm.funcShowAction(item, actionItem))?_c('v-list-item',{key:i,attrs:{"disabled":_vm.maybeDisableAction(actionItem, item)},on:{"click":function($event){return _vm.onActionClick(actionItem.action, item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(actionItem.icon)}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(actionItem.title))+" ")])],1):_vm._e()]})],2)],1)]:_vm._e()]},{"item":item})]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_c('template',{slot:"no-data"},[_vm._t("no-data",function(){return [_c('span',[_c('p',{staticClass:"pt-2 blue--text subheading"},[_c('v-icon',{staticClass:"blue--text",attrs:{"medium":""}},[_vm._v(" mdi-information ")]),_vm._v(" "+_vm._s(_vm.$t("no-data-msg"))+" ")],1)])]})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }