import { Address } from './Address';
import { Communication } from './Communication';
import { ADDRESS_TYPES } from "@/data/globals.js";

export class PrivateAddress extends Address{
    constructor() {
        super();
        this.salutation = null;
        this.firstName = null;
        this.familyName = null;
        this.communication = new Communication();
        this.addressType = ADDRESS_TYPES.PRIVATE;
    }
}
