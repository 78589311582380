<i18n src="./WLocationSearch-i18n.yaml"></i18n>
<template>
    <v-autocomplete
        v-model="selectedLocation"
        :items="locationsList"
        @click:clear="reset"
        @change="onAutocompleteChange"
        :search-input.sync="search"
        :loading="isLoading"
        return-object
        full-width
        open-on-clear
        no-filter
        filled
        :label="label ? label : $t('label')"
        v-bind="$attrs"
        :clearable="!readonly"
        v-on="$listeners"
        item-value="zipCode"
        v-disabled-icon-focus
        :required="required"
        :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
    >
        <template v-slot:item="{ item }">
            <span class="pr-2">
                {{ item.zipCode }}
            </span>
            <span>
                {{ item.city }}
            </span>
            <span class="pl-2" v-if="item.canton"> ({{ item.canton }}) </span>
        </template>
        <template v-slot:selection="{ item }">
            <span class="pr-2">
                {{ item.zipCode }}
            </span>
            <span>
                {{ item.city }}
            </span>
            <span class="pl-2" v-if="item.canton"> ({{ item.canton }}) </span>
        </template>
        <template v-slot:no-data>
            <v-list-item
                disabled
                v-if="
                    (!search && !isLoading) ||
                    (search && search.length <= searchMinLength)
                "
            >
                <v-icon class="pr-2">mdi-keyboard</v-icon>
                <span>
                    {{
                        $t("no-results-no-search", {
                            minLength: searchMinLength
                        })
                    }}
                </span>
            </v-list-item>
            <v-list-item disabled dense v-else>
                <v-icon class="pr-2">mdi-database-remove</v-icon>
                <span>
                    {{ $t("no-results") }}
                </span>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>
<script>
import _ from "lodash";
import mixinWField from "@/mixins/w-field";
import { AVAILABLE_LOCATION_SEARCH_COUNTRIES } from "@/data/globals";
export default {
    name: "WLocationSearch",
    mixins: [mixinWField],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        label: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        country: {
            type: String,
            default: null,
            validator: (value) => {
                return (
                    AVAILABLE_LOCATION_SEARCH_COUNTRIES.indexOf(value) !== -1
                );
            }
        }
    },
    data() {
        return {
            searchValue: null,
            locationsList: [],
            selectedLocation: null,
            numberOfHits: 0,
            search: null,
            page: 0,
            limit: 25,
            searchMinLength: 2,
            dialogAddManufacturer: false,
            isLoading: false
        };
    },
    created() {
        this.fetchData = _.debounce(function () {
            this.isLoading = true;
            let params = {};
            params.search = this.searchValue;
            params.start = this.page;
            params.limit = this.limit;
            return new Promise((resolve, reject) => {
                this.$axiosLocation
                    .get(`/${this.country}/search`, { params })
                    .then((response) => {
                        let resStatus = response.status;
                        if (resStatus === 200) {
                            let itemsData = response.data;
                            for (let key in itemsData) {
                                this.locationsList.push(itemsData[key]);
                            }
                        }
                        this.isLoading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(this.$t("error-occured"));
                        this.isLoading = false;
                        reject(error);
                    });
            });
        }, 500);
    },
    methods: {
        onAutocompleteChange(val) {
            if (val) {
                this.$emit("change", val);
            }
        },
        reset() {
            this.searchValue = null;
            this.search = null;
            this.resetSearch();
            this.locationsList = [];
            this.$emit("reset");
        },
        resetSearch() {
            this.selectedLocation = null;
            this.locationsList = [];
            this.numberOfHits = 0;
        }
    },
    watch: {
        search(val) {
            if (!val || val.length < this.searchMinLength) {
                if (!this.selectedLocation) {
                    this.resetSearch();
                }
            } else {
                this.resetSearch();
                this.page = 0;
                this.isLoading = true;
                this.searchValue = val.trim();
                this.fetchData();
            }
        },
        value: {
            handler: function (val) {
                if (val && typeof val === "object" && val !== null) {
                    if (!val.zipCode && !val.city) {
                        if (this.selectedLocation) {
                            this.reset();
                        }
                        return;
                    }
                    if (val.zipCode && val.city) {
                        this.locationsList.push(val);
                        this.selectedLocation =
                            this.locationsList[this.locationsList.length - 1];
                    }
                } else {
                    if (!val) {
                        this.reset();
                    }
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
