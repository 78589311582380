<i18n src="./token-invalid-i18n.yaml"></i18n>
<template>
    <AppErrorContainer
        :errorTitle="$t('error-title')"
        :errorMsg="$t('error-msg')"
        :wIconSrc="require('@/assets/icons/pages/token.svg')"
        titleCls="text-h4"
    >
        <template v-slot:additional-content>
            <div class="my-3 headline" v-html="$t('request-token')"></div>
        </template>
    </AppErrorContainer>
</template>
<script>
export default {
    name: "ViewErrorTokenInvalid"
};
</script>
