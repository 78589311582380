var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionBase',{attrs:{"id":"devel-charts","title":"Charts"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pl-2"},[_vm._v("Complex Chart")])],1),_c('v-col',{attrs:{"cols":12,"lg":"4","sm":12}},[_c('v-card',{attrs:{"dark":"","color":"green","tile":""}},[_c('v-card-title',[_c('div',{staticClass:"layout row ma-0"},[_c('div',{staticClass:"subheading"},[_vm._v("Today")]),_c('v-spacer'),_c('div',{staticClass:"caption"},[_c('v-icon',[_vm._v("mdi-trending-up")]),_vm._v("20%")],1)],1)]),_c('v-responsive',{staticClass:"white--text"},[_c('e-chart',{ref:"chart",attrs:{"path-option":[
              ['dataset.source', _vm.dataset.stackData],
              ['grid.bottom', '10%'],
              ['grid.top', '5%'],
              ['xAxis.show', false],
              ['yAxis.show', false],
              ['series[0].type', 'bar'],
              ['series[0].barGap', '-100%'],
              ['series[0].itemStyle.color', 'rgba(0,0,0,0.1)'],
              ['series[0].barWidth', '50%'],
              ['series[1].barWidth', '50%'],
              ['series[1].type', 'bar'],
              ['series[1].itemStyle.color', _vm.color.shades.white] ],"height":"200px","width":"100%"}})],1),_c('v-card-text',{staticClass:"white"},[_c('div',{staticClass:"layout row align-center ma-0"},[_c('div',{staticClass:"grey--text"},[_c('div',{staticClass:"caption"},[_vm._v("MAXIMUM BOUNCE")]),_c('div',{staticClass:"subheading mt-2"},[_vm._v("2500")])]),_c('v-spacer'),_c('div',{},[_c('v-progress-circular',{attrs:{"size":100,"width":15,"rotate":360,"value":10,"color":"blue"}},[_vm._v("10 ")])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":12,"lg":"4","sm":12}},[_c('v-card',{attrs:{"tile":"","dark":"","color":"pink"}},[_c('v-card-title',[_c('div',{staticClass:"layout row ma-0"},[_c('div',{staticClass:"subheading"},[_vm._v("Today")]),_c('v-spacer'),_c('div',{staticClass:"caption"},[_c('v-icon',[_vm._v("mdi-trending-up")]),_vm._v("20%")],1)],1)]),_c('v-responsive',{staticClass:"white--text"},[_c('e-chart',{attrs:{"path-option":[
              ['dataset.source', _vm.dataset.monthVisit],
              ['color', [_vm.color.pink.lighten2]],
              ['grid.left', '0'],
              ['grid.bottom', '0'],
              ['grid.right', '0'],
              ['xAxis.show', false],
              ['yAxis.show', false] ],"height":"200px","width":"100%"}})],1),_c('v-card-text',{staticClass:"white"},[_c('div',{staticClass:"layout row align-center ma-0"},[_c('div',{staticClass:"grey--text"},[_c('div',{staticClass:"caption"},[_vm._v("MAXIMUM BOUNCE")]),_c('div',{staticClass:"subheading mt-2"},[_vm._v("2500")])]),_c('v-spacer'),_c('div',{},[_c('v-progress-circular',{attrs:{"size":100,"width":15,"rotate":360,"value":10,"color":"blue"}},[_vm._v("10 ")])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":12,"lg":"4","sm":12}},[_c('v-card',{attrs:{"tile":"","color":"blue darken-1","dark":""}},[_c('v-card-title',[_c('div',{staticClass:"layout row ma-0"},[_c('div',{staticClass:"subheading"},[_vm._v("Today")]),_c('v-spacer'),_c('div',{staticClass:"caption"},[_c('v-icon',[_vm._v("mdi-trending-up")]),_vm._v("20%")],1)],1)]),_c('v-responsive',[_c('e-chart',{attrs:{"path-option":[
              ['dataset.source', _vm.dataset.monthVisit],
              ['color', [_vm.color.blue.base]],
              ['xAxis.show', false],
              ['xAxis.boundaryGap', false],
              ['grid.left', '0'],
              ['grid.bottom', '0'],
              ['grid.right', '0'],
              ['yAxis.show', false],
              ['series[0].areaStyle', {}],
              ['series[0].smooth', true] ],"height":"200px","width":"100%"}})],1),_c('v-card-text',{staticClass:"white"},[_c('div',{staticClass:"layout row align-center ma-0"},[_c('div',{staticClass:"grey--text"},[_c('div',{staticClass:"caption"},[_vm._v("MAXIMUM BOUNCE")]),_c('div',{staticClass:"subheading mt-2"},[_vm._v("2500")])]),_c('v-spacer'),_c('div',{},[_c('v-progress-circular',{attrs:{"size":100,"width":15,"rotate":360,"value":10,"color":"blue"}},[_vm._v("10 ")])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":12,"lg":"6","sm":12}},[_c('v-card',[_c('v-card-text',[_c('e-chart',{attrs:{"path-option":[
              ['dataset.source', _vm.dataset.campaign],
              [
                'color',
                [
                  _vm.color.amber.base,
                  _vm.color.indigo.base,
                  _vm.color.pink.base,
                  _vm.color.green.base,
                  _vm.color.teal.base,
                  _vm.color.purple.base ] ],
              ['legend.orient', 'horizontal'],
              ['legend.y', 'bottom'],
              ['xAxis.show', false],
              ['yAxis.show', false],
              ['series[0].type', 'pie'],
              ['series[0].avoidLabelOverlap', true],
              ['series[0].radius', ['50%', '70%']] ],"height":"350px","width":"100%"}})],1)],1)],1),_c('v-col',{attrs:{"cols":12,"lg":"6","sm":12}},[_c('v-card',{attrs:{"tile":"","title":"Analysis - Pie"}},[_c('v-card-text',[_c('e-chart',{attrs:{"path-option":[
              ['dataset.source', _vm.dataset.campaign],
              [
                'color',
                [
                  _vm.color.lightBlue.base,
                  _vm.color.indigo.base,
                  _vm.color.pink.base,
                  _vm.color.green.base,
                  _vm.color.cyan.base,
                  _vm.color.teal.base ] ],
              ['legend.orient', 'horizontal'],
              ['legend.y', 'bottom'],
              ['xAxis.show', false],
              ['yAxis.show', false],
              ['series[0].type', 'pie'] ],"height":"350px","width":"100%"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-tabs',{on:{"change":_vm.handleTabChange},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Sales")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Visit")]),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('e-chart',{attrs:{"path-option":[
                  ['color', [_vm.color.lightBlue.base, _vm.color.purple.base]],
                  ['dataset.source', _vm.dataset.monthVisit],
                  ['series[0].type', 'bar'],
                  ['series[1].type', 'bar'] ],"height":"350px","width":"100%"}})],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('e-chart',{attrs:{"path-option":[
                  ['dataset.source', _vm.dataset.monthVisit],
                  ['series[0].type', 'line'] ],"height":"350px","width":"100%"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }