<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./btn-upload-files-i18n.yaml"></i18n>
<template>
    <div>
        <v-menu
            offset-y
            bottom
            origin="center center"
            transition="scale-transition"
            close-on-content-click
        >
            <template v-slot:activator="{ on }">
                <base-btn v-on="on" :color="color" v-bind="$attrs">
                    <v-icon>mdi-upload</v-icon>
                    {{ $t("btn-upload-files") }}
                </base-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in categories"
                    :key="index"
                    @click="$emit('click-item', item.itemId)"
                    ripple
                >
                    <v-list-item-title>{{
                        $t(`attachment-category-${item.itemId}`)
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
export default {
    name: "BtnUploadFiles",
    props: {
        color: {
            type: String,
            default: "info"
        },
        categories: {
            type: Array,
            default: () => []
        }
    }
};
</script>
