var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.readonlyModus)?_c('v-container',{attrs:{"fluid":"","id":"container-company-advanced-search"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',{ref:"cardCompanyAdvancedSearch"},[_c('base-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("search-info-text"))+" "),_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('search-info-text-list-1'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('search-info-text-list-2'))}})])]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-text-field',{attrs:{"label":_vm.$t('search-name-label'),"clearable":"","required":""},on:{"click:clear":_vm.reset,"keydown":_vm.onSearchNameInputKeydown,"input":function($event){_vm.btnSearchDisabled = false},"blur":function($event){return _vm.onSearchParamsFieldChange({
                                    field: 'searchName',
                                    value: $event.target.value
                                })}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('WLocationSearch',{attrs:{"country":"CH"},on:{"reset":_vm.onWLocationSearchReset},model:{value:(_vm.locationSearch),callback:function ($$v) {_vm.locationSearch=$$v},expression:"locationSearch"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"10"}},[_c('base-text-field',{attrs:{"hide-details":"","label":_vm.$t('street-label')},on:{"input":function($event){return _vm.onSearchParamsFieldChange({
                                    field: 'advancedSearchStreet',
                                    value: $event
                                })}},model:{value:(_vm.advancedSearchStreet),callback:function ($$v) {_vm.advancedSearchStreet=$$v},expression:"advancedSearchStreet"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"2"}},[_c('base-text-field',{attrs:{"hide-details":"","label":_vm.$t('house-nr-label')},on:{"input":function($event){return _vm.onSearchParamsFieldChange({
                                    field: 'advancedSearchHouseNr',
                                    value: $event
                                })}},model:{value:(_vm.advancedSearchHouseNr),callback:function ($$v) {_vm.advancedSearchHouseNr=$$v},expression:"advancedSearchHouseNr"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-5"},[(
                        _vm.searchableAddress &&
                        (_vm.btnSearchDisabled || _vm.searchName == null)
                    )?_c('base-btn',{attrs:{"type":"secondary"},on:{"click":_vm.setSearchAddress}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database-search")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-take-address"))+" ")]):_vm._e()],1):_vm._e(),_c('v-spacer'),_c('base-btn',{attrs:{"disabled":(_vm.searchName == null && !_vm.btnSearchDisabled) ||
                        (_vm.btnSearchDisabled && _vm.searchName != null),"type":"primary"},on:{"click":_vm.onBtnFetchDataClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database-search")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("btn-search"))+" ")])],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.result.length >= 1),expression:"result.length >= 1"}],attrs:{"cols":"12"}},[_c('v-card-text',{ref:"containerResults",staticClass:"pt-5",attrs:{"fill-height":"","no-gutters":""}},[(_vm.errors != null)?_c('base-alert',{attrs:{"width":"100%","tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errors)+" ")]):_vm._e(),(_vm.result.length >= 1)?_c('base-alert',{ref:"baseAlertSearchResultInfo",attrs:{"id":"base-alert-company-advanced-search","width":"100%","dense":"","type":"info","color":"info"}},[_c('i18n',{attrs:{"path":"search-result-info","tag":"div"},scopedSlots:_vm._u([{key:"possibleHits",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.$tc( "possible-hits", _vm.sortedResultItems.length, { count: _vm.sortedResultItems.length } )))])]},proxy:true}],null,false,1279362085)})],1):_vm._e(),_c('v-data-table',{ref:"dataTable",attrs:{"headers":_vm.computedHeaders,"items-per-page":_vm.itemsPerPage,"items":_vm.sortedResultItems,"hide-default-footer":"","item-key":"name"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                        var items = ref.items;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('tbody',_vm._l((items),function(item,idx){return _c('tr',{key:idx,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onShowInfoClick(item)}}},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.street))]),_c('td',[_vm._v(_vm._s(item.houseNr))]),_c('td',[_vm._v(_vm._s(item.zipCode))]),_c('td',[_vm._v(_vm._s(item.city))]),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-list-item-avatar',_vm._g({attrs:{"size":"20"}},on),[_c('v-img',{attrs:{"src":require('@/assets/canton/' +
                                                            item.canton.toLowerCase() +
                                                            '.png')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.canton))])])],1)])}),0):_c('div',_vm._l((items),function(item,idx){return _c('v-card',{key:idx,attrs:{"flat":""},on:{"click":function($event){return _vm.onShowInfoClick(item)}}},[_c('v-card-text',{staticClass:"py-1"},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',[_vm._v(" "+_vm._s(item.street)+" "+_vm._s(item.houseNr)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.zipCode)+" "+_vm._s(item.city)+" "+_vm._s(item.canton)+" ")]),_c('div',[_vm._v(_vm._s(item.uidFormatted))])]),_c('v-divider',{staticClass:"pa-0 ma-0"})],1)}),1)]}},{key:"item.canton",fn:function(ref){
                                                            var item = ref.item;
return [[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require('@/assets/canton/' +
                                            item.canton.toLowerCase() +
                                            '.png')}})],1)]]}}],null,false,2243059332),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.showInfoItem != null)?_c('base-dialog',{attrs:{"value":_vm.dialogInfoItem,"title":_vm.showInfoItem.uidFormatted,"max-width":"400","icon":"mdi-information-outline"},on:{"input":function($event){_vm.dialogInfoItem = $event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"flat":"","width":"100%"}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("dialog-info-item-text"))+" ")]),_c('v-card-text',[_c('div',{staticClass:"justify-center text-body-1"},[_c('div',[_vm._v(_vm._s(_vm.showInfoItem.name))]),_c('div',[_vm._v(" "+_vm._s(_vm.showInfoItem.street)+" "+_vm._s(_vm.showInfoItem.houseNr)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.showInfoItem.canton)+" "+_vm._s(_vm.showInfoItem.zipCode)+" "+_vm._s(_vm.showInfoItem.city)+" ")]),_c('div',[_vm._v(_vm._s(_vm.showInfoItem.uidFormatted))])])])],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('base-btn',{attrs:{"type":"primary"},on:{"click":_vm.onConfirmSelectedCompany}},[_vm._v(" "+_vm._s(_vm.$t("btn-confirm-selected-company-confirm"))+" ")])]},proxy:true}],null,false,3195419511)}):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }