<template>
    <v-card-title>
        <span class="primary--text center font-weight-medium title">
            <slot name="title" />
        </span>
        <slot />
    </v-card-title>
</template>
<script>
export default {
    name: "SettingsTitle"
};
</script>
