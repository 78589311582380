export const AUTO_SAVE = "AUTO_SAVE";
export const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION";
export const UPDATE_REGISTRATION = "UPDATE_REGISTRATION";
export const AUTO_SAVE_REGISTRATION = "AUTO_SAVE_REGISTRATION";
export const DEBOUNCE_AUTO_SAVE_REGISTRATION =
    "DEBOUNCE_AUTO_SAVE_REGISTRATION";
export const FETCH_OPEN_REGISTRATION_DATA_BY_REFERENCE_ID =
    "FETCH_OPEN_REGISTRATION_DATA_BY_REFERENCE_ID";
export const COMPLETE = "COMPLETE";

//BACKOFFICE
export const BACKOFICCE_TASK_COMPLETE = "BACKOFICCE_TASK_COMPLETE";
export const FETCH_REGISTRATION_AND_DIALOGS_DATA =
    "FETCH_REGISTRATION_AND_DIALOGS_DATA";
export const SET_REGISTRATION_REGISTRANT_AND_BO_DATA =
    "SET_REGISTRATION_REGISTRANT_AND_BO_DATA";
export const UPDATE_REGISTRATION_REVIEW_DIALOG =
    "UPDATE_REGISTRATION_REVIEW_DIALOG";
export const FETCH_REGISTRATION_DATA = "FETCH_REGISTRATION_DATA";
export const FETCH_TASK_DATA_BY_REFERENCE_ID = "FETCH_TASK_DATA_BY_REFERENCE_ID";

// INSURER
export const FETCH_APROVAL_REQUIRED_POOL_TASKS = "FETCH_APROVAL_REQUIRED_POOL_TASKS";
export const FETCH_DASHBOARD_COUNTERS_DATA = "FETCH_DASHBOARD_COUNTERS_DATA";
export const SET_APPROVAL_REQUIRED_STATE = "SET_APPROVAL_REQUIRED_STATE";
