<i18n src="./form-address-communication-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                @Data:
                <tree-view
                    :data="Object.assign({}, computedCommunication)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                @validate:
                <tree-view
                    :data="Object.assign({}, $v)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <base-btn icon color="warning" @click="validate">
                    <v-icon>mdi-times</v-icon>
                </base-btn>
            </template>
        </app-dev-container>
        <v-row dense>
            <v-col cols="12" md="6">
                <!-- Telefon -->
                <WTelNumberField
                    ref="phone"
                    :label="$t('phone-label')"
                    @input="onInputPhone"
                    :value="phone.number"
                    :required="
                        fieldsRequired || requiredFields.includes('phone')
                    "
                >
                </WTelNumberField>
            </v-col>
            <v-col cols="12" md="6">
                <!-- Telefax -->
                <WTelNumberField
                    ref="fax"
                    :label="$t('fax-label')"
                    @input="onInputFax"
                    :value="fax.number"
                    :required="fieldsRequired || requiredFields.includes('fax')"
                >
                </WTelNumberField>
            </v-col>
            <v-col cols="12" md="6">
                <!-- E-Mail -->
                <base-text-field
                    v-model.trim="email"
                    @input="onInputEmail"
                    :label="$t('email-label')"
                    :error-messages="emailErrors"
                    :required="isRequiredField($v.computedCommunication.email)"
                ></base-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import validateMixin from "@/mixins/validate";
import { maxLength, email, requiredIf } from "vuelidate/lib/validators";
import { Communication } from "@/models/tasks";
import mixin from "./mixin";
export default {
    name: "FormAddressCommunication",
    mixins: [validateMixin, mixin],
    components: {
        WTelNumberField: () => import("@/components/fields/WTelNumberField.vue")
    },

    props: {
        value: {
            type: Object,
            default: () => new Communication()
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        requiredFields: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        }
    },

    validations() {
        return {
            computedCommunication: {
                email: {
                    required: requiredIf(function () {
                        if (
                            this.fieldsRequired ||
                            this.requiredFields.includes("email")
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    email,
                    maxLength: maxLength(50)
                }
            }
        };
    },

    data: () => ({
        email: null,
        phone: {
            number: "",
            valid: false,
            country: "CH"
        },
        fax: {
            number: "",
            valid: false,
            country: "CH"
        }
    }),
    computed: {
        computedCommunication: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        fieldsAreEmpty() {
            const data = {
                email: this.email,
                phone: this.phone.number,
                fax: this.fax.number
            };
            return this.checkIfAllFieldsAreEmpty(data);
        },
        computedTitle() {
            if (this.title) {
                return this.title;
            }
            return this.$t("title");
        },
        fieldsRequired() {
            if (this.required) {
                return this.fieldsAreEmpty;
            } else {
                return false;
            }
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.computedCommunication.email.$dirty) {
                return errors;
            }
            if (!this.$v.computedCommunication.email.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.computedCommunication.email.email) {
                errors.push(this.$t("field-invalid-email"));
            }
            if (!this.$v.computedCommunication.email.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.computedCommunication.email.$params
                                .maxLength.max
                    })
                );
            }
            return errors;
        }
    },
    methods: {
        onFieldChange(field, value) {
            if (!field) return;
            this.emitOnFieldInput(field, value);
        },
        onInputPhone(newPhone) {
            if (newPhone) {
                if ("valid" in newPhone) {
                    this.phone.valid = newPhone.valid;
                }
                if ("country" in newPhone) {
                    this.phone.country = newPhone.country;
                }
                if ("number" in newPhone) {
                    this.phone.number = newPhone.number;
                    this.onFieldChange("phone", newPhone.number);
                }
            }
        },
        onInputFax(newFax) {
            if (newFax) {
                if ("valid" in newFax) {
                    this.fax.valid = newFax.valid;
                }
                if ("country" in newFax) {
                    this.fax.country = newFax.country;
                }
                if ("number" in newFax) {
                    this.fax.number = newFax.number;
                    this.onFieldChange("fax", newFax.number);
                }
            }
        },
        onInputEmail(newEmail) {
            this.onFieldChange("email", newEmail);
        },
        isValid() {
            let isValid = true;
            if (!this.$v.$dirty) {
                return null;
            } else {
                if (this.fieldsAreEmpty) return false;
                if (!this.$v.$anyError) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            }
            if (
                this.requiredFields.includes("phone") ||
                (this.phone.number && this.phone.number.length > 0)
            ) {
                if (!this.$refs.phone.isValid()) {
                    isValid = false;
                }
            }
            if (
                this.requiredFields.includes("fax") ||
                (this.fax.number && this.fax.number.length > 0)
            ) {
                if (!this.$refs.fax.isValid()) {
                    isValid = false;
                }
            }
            return isValid;
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        validate() {
            this.$v.$touch();
            this.$refs.phone.validate();
            this.$refs.fax.validate();
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                this.scrollToFirstErrorIfInvalidFieldsFound(this.scrollOptions);
                this.$refs.phone.scrollToFirstError();
                this.$refs.fax.scrollToFirstError();
            });
        },
        clear() {
            this.phone = {
                number: "",
                valid: false,
                country: "CH"
            };
            this.fax = {
                number: "",
                valid: false,
                country: "CH"
            };

            this.email = null;
            this.onFieldChange("phone", null);
            this.onFieldChange("fax", null);
            this.onFieldChange("email", null);
        },
        reset() {
            this.$v.$reset();
            this.$refs.phone.reset();
            this.$refs.fax.reset();
        },
        getData() {
            let ret = {};
            ret.phone = this.phone.number;
            ret.fax = this.fax.number;
            ret.email = this.email;

            return ret;
        },
        setFakeData() {
            const data = {
                phone: "+41 79 563 88 00",
                fax: "+41 79 563 88 11",
                email: "info@was.ch"
            };
            this.setData(data);
        },
        setData(data) {
            if (!data) return;
            const faxNr = data.fax || null;
            const phoneNr = data.phone || null;
            const email = data.email || null;
            if (phoneNr != null) {
                this.phone.number = data.phone;
            } else {
                this.phone.number = "";
            }
            if (faxNr != null) {
                this.fax.number = data.fax;
            } else {
                this.fax.number = "";
            }
            if (email) {
                this.email = email;
            }
        },
        checkIfAllFieldsAreEmpty(data) {
            let empty = false;
            if (!data) return;
            const faxNr = data.fax || null;
            const phoneNr = data.phone || null;
            const email = data.email || null;
            if (
                !email &&
                (phoneNr === "" || !phoneNr) &&
                (faxNr === "" || !faxNr)
            ) {
                empty = true;
            }
            return empty;
        }
    },

    watch: {
        value: {
            handler: function (newData) {
                if (newData) {
                    this.setData(newData);
                }
            },
            immediate: true
        }
    }
};
</script>
